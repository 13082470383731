import '../../css/mobile/MOrderSection.css';
import {useTranslation} from "react-i18next";

const MOrderSection = (props) => {
    const {indexNm, children, topVisibility = true, onClickAdd, onClickClone, onClickDelete} = props;
    const {t} = useTranslation();

    return (
        <div className={'m-a-order-section-container'}>
            <div className={'m-a-order-box-content'}>
                {topVisibility &&
                    <div className={'m-a-order-box-options'}>
                        <div className={'m-a-order-box-option-left'}>{indexNm}</div>
                        <div className={'m-a-order-box-option-right'}>
                            <div className={'m-a-order-box-option-item'}
                                 onClick={onClickAdd ? onClickAdd : null}> {t(`modAddBt1`)}
                            </div>
                            <div className={'m-a-order-box-option-item'}
                                 onClick={onClickClone ? onClickClone : null}> {t(`modAddBt2`)}
                            </div>
                            <div className={'m-a-order-box-option-item'}
                                 onClick={onClickDelete ? onClickDelete : null}> {t(`modAddBt3`)}
                            </div>
                        </div>
                    </div>
                }
                {children}

            </div>
        </div>
    )
}

export default MOrderSection;
