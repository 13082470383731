import '../../css/mobile/MOrderPg.css';
import MHeader from "../../components/mobile/MHeader";
import React, {createRef, useEffect, useMemo, useRef, useState} from "react";
import PaypalLogo from "../../assets/img/orderPaypal.png";
import MOrderSection from "../../components/mobile/MOrderSection";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import * as XLSX from "xlsx";
import excel from "exceljs";
import axios from "axios";
import Expand from "../../assets/img/commonBottom.png";
import mainReceived from "../../assets/img/mainReceived.png";
import detailOrder from "../../assets/img/detailOrder.png";
import pwHide from "../../assets/img/pwHide.png";
import pwView from "../../assets/img/pwView.png";
import detailCart from "../../assets/img/detailCart.png";
import detailOffline from "../../assets/img/detailOffline.png";
import detailTransfer from "../../assets/img/detailTransfer.png";
import detailDomestic from "../../assets/img/detailDomestic.png";
import detailDelivery from "../../assets/img/detailDelivery.png";
import invoiceFormHeader from "../../assets/img/invoiceFormHeader.png";
import reqFormHeader from "../../assets/img/reqFormHeader.png";
import payFormHeader from "../../assets/img/payFormHeader.png";
import WechatpayLogo from "../../assets/img/WechatPayLogo.png";
import AlipayLogo from "../../assets/img/AlipayLogo.png";
import NoBank from "../../assets/img/noBank.png";
import DepositPay from "../../assets/img/depositPay.png";
import PointPay from "../../assets/img/pointPay.png";
import AliQrCode from "../../assets/img/aliqrcode.png";
import wechatQrCode from "../../assets/img/wechatqrcode.png";
import termsView from "../../assets/img/termsView.png";
import btoragePay from "../../assets/img/BtorageLogo.png";
import creditCard from "../../assets/img/payFormHeader.png";
import stripe from "../../assets/img/StripeLogo.png";
import CouponUse from "../../components/web/modal/CouponUse";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import MAddrBookPg from "./MAddrBookPg";
import MCommonTermsPopup from "../../components/mobile/MCommonTermsPopup";
import {useWebSocket} from "../../services/WebsocketContext";
import ReadyingPopup from "../../components/web/modal/ReadyingPopup";
import MPaypalModal from "../../components/mobile/MPaypalModal";
import withAuth from "../../auth/UserRouteAuth";
import 'flag-icons/css/flag-icons.css';
import {BiCaretDown} from "react-icons/bi";

const MOrderPg = () => {
    const [productOption, setProductOption] = useState(0);
    const [deliveryAddress, setDeliveryAddress] = useState(1);
    const [value, setValue] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const MOrderInput = (title, value, type, maxLength, ref, onChange, isPw, placeholder, children) => {
        return (
            <div className={'m-a-order-input-box'}>
                {value.length > 0 && <div className={'m-a-order-input-title'}>{title}</div>}
                <input className={'m-a-order-input'} type={type} value={value} maxLength={maxLength}
                       ref={ref}
                       placeholder={placeholder ? title + ' : ' + placeholder : `${value.length === 0 ? title : ''}`}
                       onChange={onChange}/>
                {isPw &&
                    <div className={'m-a-order-input-password'}>
                        {children}
                    </div>}
            </div>
        )
    }

    const MOrderPaymentRow = (title, value) => {
        return (
            <div className={'m-a-order-section-payment-row'}>
                <div className={'m-a-order-section-payment-row-text'}>{title}</div>
                <div className={'m-a-order-section-payment-row-text'}>{value}</div>
            </div>
        )
    }

    const {t} = useTranslation();

    // 나중에 URL로 입력하여 들어와도 로그인 안되어있으면 로그인창으로 보내기
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const [isSaveBtLoading, setIsSaveBtLoading] = useState(false);
    const [isSaveBtDisabled, setIsSaveBtDisabled] = useState(false);
    const [isPayBtLoading, setIsPayBtLoading] = useState(false);
    const [isPayBtDisabled, setIsPayBtDisabled] = useState(false);

    const [recieverInfoAccordion, setRecieverInfoAccordion] = useState(true);
    const [cartOrderAccordion, setCartOrderAccordion] = useState(false);
    const [orderAccordion, setOrderAccordion] = useState(false);
    const [offlineAccordion, setOfflineAccordion] = useState(false);
    const [transferAccordion, setTransferAccordion] = useState(false);
    const [domesticAccordion, setDomesticAccordion] = useState(false);
    const [deliveryAccordion, setDeliveryAccordion] = useState(false);
    const [invoiceAccordion, setInvoiceAccordion] = useState(true);
    const [requestConfigAccordion, setRequestConfigAccordion] = useState(true);

    // const [selectedOrderChoiceOption, setSelectedOrderChoiceOption] = useState('');

    const [useType, setUseType] = useState("gift");

    const [defaultAddr, setDefaultAddr] = useState(true);
    const [newAddr, setNewAddr] = useState(false);

    const [deliveryChoice, setDeliveryChoice] = useState([]);
    const [selectedDeliveryChoiceOption, setSelectedDeliveryChoiceOption] = useState(t('dvSelect'));
    const [domesticDeliveryChoice, setDomesticDeliveryChoice] = useState([]);

    const [country, setCountry] = useState('국가');
    // const [countryLabel, setCountryLabel] = useState('');
    const [taxid, setTaxid] = useState("");
    const [recname, setRecname] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [telnum, setTelnum] = useState("");

    const selectedDeliveryChoiceOptionRef = useRef(null);
    const taxidRef = useRef(null);
    const recnameRef = useRef(null);
    const address1Ref = useRef(null);
    const cityRef = useRef(null);
    const zipcodeRef = useRef(null);
    const telnumRef = useRef(null);

    const [tagRemove, setTagRemove] = useState(false);
    const [shoesBoxRemove, setShoesBoxRemove] = useState(false);
    const [hangerRemove, setHangerRemove] = useState(false);
    const [compressionPack, setCompressionPack] = useState(false);
    const [productBoxRemove, setProductBoxRemove] = useState(false);
    const [packingHold, setPackingHold] = useState(false);
    const [deliveryDepositPay, setDeliveryDepositPay] = useState(false);
    const [deliveryMessengerGuide, setDeliveryMessengerGuide] = useState(false);
    const [detailRequest, setDetailRequest] = useState("");

    const [orderChoiceError, setOrderChoiceError] = useState(false);
    const [deliveryChoiceError, setDeliveryChoiceError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [taxidError, setTaxidError] = useState(false);
    const [recnameError, setRecnameError] = useState(false);
    const [address1Error, setAddress1Error] = useState(false);
    const [address2Error, setAddress2Error] = useState(false);
    const [address3Error, setAddress3Error] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [zipcodeError, setZipcodeError] = useState(false);
    const [telnumError, setTelnumError] = useState(false);
    const [payMethodError, setPayMethodError] = useState(false);
    const [pointPayError, setPointPayError] = useState(false);
    const [depositSelectErr, setDepositSelectErr] = useState(false);
    const [pointSelectErr, setPointSelectErr] = useState(false);
    const [etcPayError, setEtcPayError] = useState(false);
    const [discountError, setDiscountError] = useState(false);

    const [orderAddrBookSt, setOrderAddrBookSt] = useState(false);
    const [getDefaultAddrList, setGetDefaultAddrList] = useState("");

    const [nameInputCount, setNameInputCount] = useState(0);
    const [telnumInputCount, setTelnumInputCount] = useState(0);
    const [zipcodeInputCount, setZipcodeInputCount] = useState(0);
    const [address1InputCount, setAddress1InputCount] = useState(0);
    const [address2InputCount, setAddress2InputCount] = useState(0);
    const [address3InputCount, setAddress3InputCount] = useState(0);
    const [cityInputCount, setCityInputCount] = useState(0);

    const [cartProductHandle, setCartProductHandle] = useState([{
        name: "",
        option: "",
        quantity: "",
        price: "",
        url: ""
    }]);
    const [orderProductHandle, setOrderProductHandle] = useState([{
        name: "",
        option: "",
        quantity: "",
        price: "",
        mallShipPrice: "",
        url: ""
    }]);
    const [offlineProductHandle, setOfflineProductHandle] = useState([{name: "", quantity: "", price: "", fee: ""}]);
    const [transferProductHandle, setTransferProductHandle] = useState([{name: "", price: "", fee: ""}]);
    const [domesticProductHandle, setDomesticProductHandle] = useState([{
        name: "",
        option: "",
        quantity: "",
        price: "",
        mallShipPrice: "",
        fee: "",
        url: ""
    }]);
    const [deliveryProductHandle, setDeliveryProductHandle] = useState([{
        domShipco: "",
        inWaynum: "",
        name: "",
        quantity: ""
    }]);

    const excelFileSelectRefCart = useRef(null);
    const excelFileSelectRefOrder = useRef(null);
    const excelFileSelectRefOffline = useRef(null);
    const excelFileSelectRefTransfer = useRef(null);
    const excelFileSelectRefDomestic = useRef(null);
    const excelFileSelectRefDelivery = useRef(null);

    const [invoiceFile, setInvoiceFile] = useState(null);
    const [fileName, setFileName] = useState(t('modFileBt'));

    const [payWay, setPayWay] = useState('');
    const [readyingPopupSt, setReadyingPopupSt] = useState(false);
    const dollarList = ['USD', 'CAD', 'AUD', 'NZD', 'EUR', 'JPY', 'SGD']
    const [currency, setCurrency] = useState('USD');
    const [lastForPrice, setLastForPrice] = useState(0);

    const [dbFormTotalPrices, setDbFormTotalPrices] = useState(0);
    const [dbFormTotalMallShipPrice, setDbFormTotalMallShipPrice] = useState(0);
    const [dbFormTotalFee, setDbFormTotalFee] = useState(0);
    const [dbFormTotalQuantity, setDbFormTotalQuantity] = useState(0);
    const [dbFormTotalOverseasShippingExpense, setDbFormTotalOverseasShippingExpense] = useState(0);
    const [dbFormTotalEtcExpense, setDbFormTotalEtcExpense] = useState(0);

    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalPrices, setTotalPrices] = useState(0);
    const [totalMallShipPrice, setTotalMallShipPrice] = useState(0);
    const [totalFee, setTotalFee] = useState(0);
    const [totalOverseasShippingExpense, setTotalOverseasShippingExpense] = useState(0);
    const [totalEtcExpense, setTotalEtcExpense] = useState(0);
    const [pointSale, setPointSale] = useState('');
    const [depositSale, setDepositSale] = useState('');
    const [couponSale, setCouponSale] = useState('');
    const finalPayExpense = (totalPrices + totalMallShipPrice + totalFee + totalOverseasShippingExpense + totalEtcExpense - (Number(pointSale) + Number(depositSale) + Number(couponSale))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    const [allAgreeCheck, setAllAgreeCheck] = useState(false);
    // const [infoCollectionAgreeCheck, setInfoCollectionAgreeCheck] = useState(0);
    const [cartServiceAgreeCheck, setCartServiceAgreeCheck] = useState(false);
    const [orderServiceAgreeCheck, setOrderServiceAgreeCheck] = useState(false);
    const [offlineServiceAgreeCheck, setOfflineServiceAgreeCheck] = useState(false);
    const [transferServiceAgreeCheck, setTransferServiceAgreeCheck] = useState(false);
    const [domesticServiceAgreeCheck, setDomesticServiceAgreeCheck] = useState(false);
    const [deliveryServiceAgreeCheck, setDeliveryServiceAgreeCheck] = useState(false);

    const [agreeCheckBoxError, setAgreeCheckBoxError] = useState(false);

    const location = useLocation();
    const cartState = location.state?.cartState || false;
    const orderState = location.state?.orderState || false;
    const offlineState = location.state?.offlineState || false;
    const transferState = location.state?.transferState || false;
    const domesticState = location.state?.domesticState || false;
    const deliveryState = location.state?.deliveryState || false;

    const scrollToFirstEmptyField = () => {
        let isValid = true;
        const inputs = [
            {
                value: selectedDeliveryChoiceOption === t('dvSelect') ? '' : selectedDeliveryChoiceOption,
                ref: selectedDeliveryChoiceOptionRef
            },
            {value: country, ref: countryRef},
            {value: recname, ref: recnameRef},
            {value: telnum, ref: telnumRef},
            {value: zipcode, ref: zipcodeRef},
            {value: address1, ref: address1Ref},
            {value: city, ref: cityRef},
            // 두 조건 충족시에만 미입력필드 스크롤 기능 추가
            ...(country === "ID" ? [{value: taxid, ref: taxidRef}] : []),
        ];
        for (const input of inputs) {
            if (!input.value) {
                input.ref.current.scrollIntoView({behavior: 'smooth', block: 'center'});
                input.ref.current.focus();
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const addCartProduct = () => {
        setCartProductHandle([...cartProductHandle, {}]);
        setCartOrderAccordion(true);
    };
    const deleteCartProduct = (index) => {
        setCartProductHandle((prevState) => {
            const updatedState = prevState.filter((_, i) => i !== index);
            if (updatedState.length === 0) {
                setCartOrderAccordion(false);
                setCartServiceAgreeCheck(false);
            }
            return updatedState;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        addToTotalQuantity();
    };

    const copyCartProduct = (index) => {
        setCartProductHandle((prevState) => {
            const copiedProduct = {...prevState[index]};
            return [...prevState, copiedProduct];
        });
        addToTotalQuantity();
    };

    const handleCartProductInput = (index, field, value) => {
        const updatedProducts = [...cartProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setCartProductHandle(updatedProducts);
        addToTotalQuantity();
    };

    const cartQuantityReplaceInput = (cartSeqnum, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleCartQuantityChange(cartSeqnum, onlyNumbers);
    };
    const handleCartQuantityChange = (index, newValue) => {
        const updatedCartProductHandle = cartProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setCartProductHandle(updatedCartProductHandle);
    };

    const cartNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleCartPriceChange = (index, newValue) => {
        const updatedCartProductHandle = cartProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setCartProductHandle(updatedCartProductHandle);
    };

    const cartPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = cartNumberWithComma(onlyNumbers);
        handleCartPriceChange(index, formattedValue);
    };

    const cartUploadInputChange = (index, key, value) => {
        const updatedRows = [...cartProductHandle];
        updatedRows[index][key] = value;
        setCartProductHandle(updatedRows);
    };

    const cartQuantityNumberInput = (index, field, value) => {
        cartUploadInputChange(index, field, value);
        handleCartProductInput(index, field, value);
        cartQuantityReplaceInput(index, value);
    }

    const cartPriceNumberInput = (index, field, value) => {
        cartUploadInputChange(index, field, value);
        handleCartProductInput(index, field, value);
        cartPriceReplaceInput(index, value);
    }

    const useCartItemsRefs = (cartProductHandle) => {
        const cartItemsRefs = useRef(
            cartProductHandle.map(() => ({
                name: null,
                option: null,
                quantity: null,
                price: null,
                url: null,
            }))
        );

        const initializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                option: null,
                quantity: null,
                price: null,
                url: null,
            }));
        };

        useMemo(() => {
            cartItemsRefs.current = initializeRefs(cartProductHandle.length);
        }, [cartProductHandle.length]);

        return cartItemsRefs;
    };
    const cartItemsRefs = useCartItemsRefs(cartProductHandle);

    const scrollToEmptyFieldCart = () => {
        let isValid = true;
        for (let i = 0; i < cartProductHandle.length; i++) {
            if (!cartProductHandle[i].name || !cartProductHandle[i].option || !cartProductHandle[i].quantity || !cartProductHandle[i].price || !cartProductHandle[i].url) {
                const emptyField = cartItemsRefs.current[i];
                if (!cartProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!cartProductHandle[i].option) {
                    emptyField.option.scrollIntoView({behavior: 'smooth'});
                    emptyField.option.focus();
                } else if (!cartProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!cartProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.url.scrollIntoView({behavior: 'smooth'});
                    emptyField.url.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const addOrderProduct = () => {
        setOrderProductHandle([...orderProductHandle, {}]);
        setOrderAccordion(true);
    };
    const deleteOrderProduct = (index) => {
        setOrderProductHandle(orderProductHandle.filter((_, i) => i !== index));
        if (orderProductHandle.length === 1) {
            setOrderAccordion(false);
            setOrderServiceAgreeCheck(false);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
    }
    const handleOrderProductInput = (index, field, value) => {
        const updatedProducts = [...orderProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setOrderProductHandle(updatedProducts);
    };
    const copyOrderProduct = (index) => {
        const copiedProduct = {...orderProductHandle[index]};
        setOrderProductHandle([...orderProductHandle, copiedProduct]);
    };

    const orderQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleOrderQuantityChange(index, onlyNumbers);
    };
    const handleOrderQuantityChange = (index, newValue) => {
        const updatedOrderProductHandle = orderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setOrderProductHandle(updatedOrderProductHandle);
    };

    const orderNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleOrderPriceChange = (index, newValue) => {
        const updatedOrderProductHandle = orderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOrderProductHandle(updatedOrderProductHandle);
    };

    const handleOrderMallpriceChange = (index, newValue) => {
        const updatedOrderProductHandle = orderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, mallShipPrice: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOrderProductHandle(updatedOrderProductHandle);
    };

    const orderPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = orderNumberWithComma(onlyNumbers);
        handleOrderPriceChange(index, formattedValue);
    };

    const orderMallpriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = orderNumberWithComma(onlyNumbers);
        handleOrderMallpriceChange(index, formattedValue);
    };

    const orderUploadInputChange = (index, key, value) => {
        const updatedRows = [...orderProductHandle];
        updatedRows[index][key] = value;
        setOrderProductHandle(updatedRows);
    };

    const orderQuantityNumberInput = (index, field, value) => {
        orderUploadInputChange(index, field, value);
        handleOrderProductInput(index, field, value);
        orderQuantityReplaceInput(index, value);
    }

    const orderPriceNumberInput = (index, field, value) => {
        orderUploadInputChange(index, field, value);
        handleOrderProductInput(index, field, value);
        orderPriceReplaceInput(index, value);
    }

    const orderMallpricericeNumberInput = (index, field, value) => {
        orderUploadInputChange(index, field, value);
        handleOrderProductInput(index, field, value);
        orderMallpriceReplaceInput(index, value);
    }

    const useOrderItemsRefs = (orderProductHandle) => {
        const orderItemsRefs = useRef(
            orderProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                url: null,
            }))
        );

        const initializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                url: null,
            }));
        };
        useMemo(() => {
            orderItemsRefs.current = initializeRefs(orderProductHandle.length);
        }, [orderProductHandle.length]);

        return orderItemsRefs;
    };
    const orderItemsRefs = useOrderItemsRefs(orderProductHandle);

    const scrollToEmptyFieldOrder = () => {
        let isValid = true;
        for (let i = 0; i < orderProductHandle.length; i++) {
            if (!orderProductHandle[i].name || !orderProductHandle[i].quantity || !orderProductHandle[i].price || !orderProductHandle[i].url) {
                const emptyField = orderItemsRefs.current[i];
                if (!orderProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!orderProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!orderProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.url.scrollIntoView({behavior: 'smooth'});
                    emptyField.url.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const addOfflineProduct = () => {
        setOfflineProductHandle([...offlineProductHandle, {}]);
        setOfflineAccordion(true);
    };

    const deleteOfflineProduct = (index) => {
        setOfflineProductHandle(offlineProductHandle.filter((_, i) => i !== index));
        if (offlineProductHandle.length === 1) {
            setOfflineAccordion(false);
            setOfflineServiceAgreeCheck(false);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
    }

    const handleOfflineProductInput = (index, field, value) => {
        const updatedProducts = [...offlineProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setOfflineProductHandle(updatedProducts);
    };

    const copyOfflineProduct = (index) => {
        const copiedProduct = {...offlineProductHandle[index]};
        setOfflineProductHandle([...offlineProductHandle, copiedProduct]);
    };

    const offlineQuantityReplaceInput = (index, e) => {
        let onlyNumbers;
        onlyNumbers = e.replace(/\D/g, '');
        handleOfflineQuantityChange(index, onlyNumbers);
    };

    const handleOfflineQuantityChange = (index, newValue) => {
        const updatedOfflineProductHandle = offlineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setOfflineProductHandle(updatedOfflineProductHandle);
    };

    const offlineNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleOfflinePriceChange = (index, newValue) => {
        const updatedOfflineProductHandle = offlineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOfflineProductHandle(updatedOfflineProductHandle);
    };

    const offlinePriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/\D/g, '');
        const formattedValue = offlineNumberWithComma(onlyNumbers);
        handleOfflinePriceChange(index, formattedValue);
    };

    const handleOfflineFeeChange = (index, newValue) => {
        const updatedOfflineProductHandle = offlineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOfflineProductHandle(updatedOfflineProductHandle);
    };

    const offlineFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/\D/g, '');
        const formattedValue = offlineNumberWithComma(onlyNumbers);
        handleOfflineFeeChange(index, formattedValue);
    };

    const offlineUploadInputChange = (index, key, value) => {
        const updatedRows = [...offlineProductHandle];
        updatedRows[index][key] = value;
        setOfflineProductHandle(updatedRows);
    };

    const offlineQuantityNumberInput = (index, field, value) => {
        handleOfflineProductInput(index, field, value);
        offlineQuantityReplaceInput(index, value);
        offlineUploadInputChange(index, field, value);
    }

    const offlinePriceNumberInput = (index, field, value) => {
        offlineUploadInputChange(index, field, value);
        handleOfflineProductInput(index, field, value);
        offlinePriceReplaceInput(index, value);
    }
    const offlineFeeNumberInput = (index, field, value) => {
        offlineUploadInputChange(index, field, value);
        handleOfflineProductInput(index, field, value);
        offlineFeeReplaceInput(index, value);
    }

    const useOfflineItemsRefs = (offlineProductHandle) => {
        const offlineItemsRefs = useRef(
            offlineProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
            }))
        );

        const initializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
            }));
        };
        useMemo(() => {
            offlineItemsRefs.current = initializeRefs(offlineProductHandle.length);
        }, [offlineProductHandle.length]);

        return offlineItemsRefs;
    };
    const offlineItemsRefs = useOfflineItemsRefs(offlineProductHandle);

    const scrollToEmptyFieldOffline = () => {
        let isValid = true;
        for (let i = 0; i < offlineProductHandle.length; i++) {
            if (!offlineProductHandle[i].name || !offlineProductHandle[i].quantity || !offlineProductHandle[i].price || !offlineProductHandle[i].fee) {
                const emptyField = offlineItemsRefs.current[i];
                if (!offlineProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!offlineProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!offlineProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const addTransferProduct = () => {
        setTransferProductHandle([...transferProductHandle, {}]);
        setTransferAccordion(true);
    };

    const deleteTransferProduct = (index) => {
        setTransferProductHandle(transferProductHandle.filter((_, i) => i !== index));
        if (transferProductHandle.length === 1) {
            setTransferAccordion(false);
            setTransferServiceAgreeCheck(false);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
    }

    const handleTransferProductInput = (index, field, value) => {
        const updatedProducts = [...transferProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setTransferProductHandle(updatedProducts);
    };

    const copyTransferProduct = (index) => {
        const copiedProduct = {...transferProductHandle[index]};
        setTransferProductHandle([...transferProductHandle, copiedProduct]);
    };

    const transferNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number;
    };

    const handleTransferPriceChange = (index, newValue) => {
        const updatedTransferProductHandle = transferProductHandle.map((item, idx) => {
            if (idx === index) {
                const rawCommission = newValue * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭
                return {
                    ...item,
                    price: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    fee: (roundedCommission).toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setTransferProductHandle(updatedTransferProductHandle);
    };

    const transferPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = transferNumberWithComma(onlyNumbers);
        handleTransferPriceChange(index, formattedValue);
    };

    const handleTransferFeeChange = (index, newValue) => {
        const updatedTransferProductHandle = transferProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setTransferProductHandle(updatedTransferProductHandle);
    };

    const transferFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = transferNumberWithComma(onlyNumbers);
        handleTransferFeeChange(index, formattedValue);
    };

    const transferUploadInputChange = (index, key, value) => {
        const updatedRows = [...transferProductHandle];
        updatedRows[index][key] = value;
        setTransferProductHandle(updatedRows);
    };

    const transferPriceNumberInput = (index, field, value) => {
        transferUploadInputChange(index, field, value);
        handleTransferProductInput(index, field, value);
        transferPriceReplaceInput(index, value);
    }

    const transferFeeNumberInput = (index, field, value) => {
        transferUploadInputChange(index, field, value);
        handleTransferProductInput(index, field, value);
        transferFeeReplaceInput(index, value);
    }

    const useTransferItemsRefs = (transferProductHandle) => {
        const transferItemsRefs = useRef(
            transferProductHandle.map(() => ({
                name: null,
                price: null,
                fee: null,
            }))
        );

        const initializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                price: null,
                fee: null,
            }));
        };
        useMemo(() => {
            transferItemsRefs.current = initializeRefs(transferProductHandle.length);
        }, [transferProductHandle.length]);

        return transferItemsRefs;
    };
    const transferItemsRefs = useTransferItemsRefs(transferProductHandle);

    const scrollToEmptyFieldTransfer = () => {
        let isValid = true;
        for (let i = 0; i < transferProductHandle.length; i++) {
            if (!transferProductHandle[i].name || !transferProductHandle[i].price || !transferProductHandle[i].fee) {
                const emptyField = transferItemsRefs.current[i];
                if (!transferProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!transferProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const addDomesticProduct = () => {
        setDomesticProductHandle([...domesticProductHandle, {}]);
        setDomesticAccordion(true);
    };

    const deleteDomesticProduct = (index) => {
        setDomesticProductHandle(domesticProductHandle.filter((_, i) => i !== index));
        if (domesticProductHandle.length === 1) {
            setDomesticAccordion(false);
            setDomesticServiceAgreeCheck(false);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
    }
    const handleDomesticProductInput = (index, field, value) => {
        const updatedProducts = [...domesticProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDomesticProductHandle(updatedProducts);
    };

    const copyDomesticProduct = (index) => {
        const copiedProduct = {...domesticProductHandle[index]};
        setDomesticProductHandle([...domesticProductHandle, copiedProduct]);
    };

    const domesticQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDomesticQuantityChange(index, onlyNumbers);
    };

    const handleDomesticQuantityChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const domesticNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number;
    };

    const handleDomesticPriceChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                const price = parseInt(newValue) || 0;
                const mallShipPrice = parseInt((item.mallShipPrice || "").toString().replace(/,/g, '')) || 0;
                const total = parseInt(mallShipPrice) + price;

                const rawCommission = total * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                return {
                    ...item,
                    price: (newValue || "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    newProd: true,
                    fee: roundedCommission.toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const handleDomesticMallpriceChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                const mallShipPrice = parseInt(newValue) || 0;
                const priceStr = item.price && item.price.replace(/,/g, '');
                const price = parseInt(priceStr) || 0;
                const total = price + mallShipPrice;

                const rawCommission = total * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                return {
                    ...item,
                    mallShipPrice: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    newProd: true,
                    fee: roundedCommission.toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const handleDomesticFeeChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const domesticPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDomesticPriceChange(index, formattedValue);
    };

    const domesticMallpriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDomesticMallpriceChange(index, formattedValue);
    };

    const domesticFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDomesticFeeChange(index, formattedValue);
    };

    const domesticUploadInputChange = (index, key, value) => {
        const updatedRows = [...domesticProductHandle];
        updatedRows[index][key] = value;
        setDomesticProductHandle(updatedRows);
    };

    const domesticQuantityNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticQuantityReplaceInput(index, value);
    }

    const domesticPriceNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticPriceReplaceInput(index, value);
    }

    const domesticMallpriceNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticMallpriceReplaceInput(index, value);
    }

    const domesticFeeNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticFeeReplaceInput(index, value);
    }

    const useDomesticItemsRefs = (domesticProductHandle) => {
        const domesticItemsRefs = useRef(
            domesticProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
                url: null,
            }))
        );
        const initializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
                url: null,
            }));
        };
        useMemo(() => {
            domesticItemsRefs.current = initializeRefs(domesticProductHandle.length);
        }, [domesticProductHandle.length]);

        return domesticItemsRefs;
    };
    const domesticItemsRefs = useDomesticItemsRefs(domesticProductHandle);

    const scrollToEmptyFieldDomestic = () => {
        let isValid = true;
        for (let i = 0; i < domesticProductHandle.length; i++) {
            if (!domesticProductHandle[i].name || !domesticProductHandle[i].quantity || !domesticProductHandle[i].price || !domesticProductHandle[i].fee || !domesticProductHandle[i].url) {
                const emptyField = domesticItemsRefs.current[i];
                if (!domesticProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!domesticProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!domesticProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else if (!domesticProductHandle[i].fee) {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                } else {
                    emptyField.url.scrollIntoView({behavior: 'smooth'});
                    emptyField.url.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };


    const addDeliveryProduct = () => {
        setDeliveryProductHandle([...deliveryProductHandle, {
            domShipco: t(`dv`),
            inWaynum: "",
            name: "",
            option: "",
            quantity: ""
        }]);
        setDeliveryAccordion(true);
    };

    const deleteDeliveryProduct = (index) => {
        setDeliveryProductHandle(deliveryProductHandle.filter((_, i) => i !== index));
        if (deliveryProductHandle.length === 1) {
            setDeliveryAccordion(false);
            setDeliveryServiceAgreeCheck(false);
        }
    }

    const handleDeliveryProductInput = (index, field, value) => {
        const updatedProducts = [...deliveryProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDeliveryProductHandle(updatedProducts);
    };

    const copyDeliveryProduct = (index) => {
        const copiedProduct = {...deliveryProductHandle[index]};
        setDeliveryProductHandle([...deliveryProductHandle, copiedProduct]);
    };

    const deliveryQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDeliveryQuantityChange(index, onlyNumbers);
    };

    const handleDeliveryQuantityChange = (index, newValue) => {
        const updatedDeliveryProductHandle = deliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const deliveryWaynumReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDeliveryWaynumChange(index, onlyNumbers);
    };

    const handleDeliveryWaynumChange = (index, newValue) => {
        const updatedDeliveryProductHandle = deliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, inWaynum: newValue};
            }
            return item;
        });
        setDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const handleDeliveryDomesticChange = (index, newValue) => {
        const updatedDeliveryProductHandle = deliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, domShipco: newValue};
            }
            return item;
        });
        setDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const deliveryUploadInputChange = (index, key, value) => {
        const updatedRows = [...deliveryProductHandle];
        updatedRows[index][key] = value;
        setDeliveryProductHandle(updatedRows);
    };

    const deliveryQuantityNumberInput = (index, field, value) => {
        if (value === '0') return;
        deliveryUploadInputChange(index, field, value);
        handleDeliveryProductInput(index, field, value);
        deliveryQuantityReplaceInput(index, value);
    }

    const deliveryWaynumNumberInput = (index, field, value) => {
        deliveryUploadInputChange(index, field, value);
        handleDeliveryProductInput(index, field, value);
        deliveryWaynumReplaceInput(index, value);
    }

    const deliveryDomesticSelect = (index, field, value) => {
        handleDeliveryProductInput(index, field, value);
        handleDeliveryDomesticChange(index, value);
    }

    const useDeliveryItemsRefs = (deliveryProductHandle) => {
        const deliveryItemsRefs = useRef(
            deliveryProductHandle.map(() => ({
                name: null,
                quantity: null,
            }))
        );

        const initializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
            }));
        };

        useMemo(() => {
            deliveryItemsRefs.current = initializeRefs(deliveryProductHandle.length);
        }, [deliveryProductHandle.length]);

        return deliveryItemsRefs;
    };

    const deliveryItemsRefs = useDeliveryItemsRefs(deliveryProductHandle);

    const scrollToEmptyFieldDelivery = () => {
        let isValid = true;
        for (let i = 0; i < deliveryProductHandle.length; i++) {
            if (!deliveryProductHandle[i].name || !deliveryProductHandle[i].quantity) {
                const emptyField = deliveryItemsRefs.current[i];
                if (!deliveryProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };


    // 국내배송 셀렉트함수
    const [domesticShow, setDomesticShow] = useState(
        new Array(deliveryProductHandle.length).fill(false)
    );

    const deliveryProductHandleRef = useRef(deliveryProductHandle);
    deliveryProductHandleRef.current = deliveryProductHandle;

    const domesticRefs = useRef([]);
    const toggleDomesticShow = (index) => {
        // Toggle the corresponding show state for the specific form
        const updatedShowStates = [...domesticShow];
        updatedShowStates[index] = !updatedShowStates[index];
        setDomesticShow(updatedShowStates);
    };

    const handleDomesticChange = (index, domestic) => {
        const updatedHandles = [...deliveryProductHandle];
        updatedHandles[index].domShipco = domestic;
        setDeliveryProductHandle(updatedHandles);

        // Close the dropdown for the specific form after selecting
        const updatedShowStates = [...domesticShow];
        updatedShowStates[index] = false;
        setDomesticShow(updatedShowStates);
    };

    // useEffect(() => {
    //     if (deliveryProductHandleRef.current.length > domesticRefs.current.length) {
    //         domesticRefs.current = [
    //             ...domesticRefs.current,
    //             ...new Array(deliveryProductHandleRef.current.length - domesticRefs.current.length).fill().map(() => createRef())
    //         ];
    //     } else if (deliveryProductHandleRef.current.length < domesticRefs.current.length) {
    //         domesticRefs.current = domesticRefs.current.slice(0, deliveryProductHandleRef.current.length);
    //     }
    // }, [deliveryProductHandle]);

    // useEffect(() => {
    //     // Function to check if clicked outside of any dropdown
    //     const handleClickOutside = (event) => {
    //         domesticRefs.current.forEach((ref, index) => {
    //             if (ref && !ref.contains(event.target)) {
    //                 setDomesticShow(prevShow => {
    //                     const updatedShow = [...prevShow];
    //                     updatedShow[index] = false;
    //                     return updatedShow;
    //                 });
    //             }
    //         });
    //     };
    //
    //     // Bind the event listener
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         // Unbind the event listener on clean up
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [domesticRefs, domesticShow]); // Add domesticShow as a dependency

// Update refs setup in useEffect
    useEffect(() => {
        domesticRefs.current = domesticRefs.current.slice(0, deliveryProductHandle.length);
        deliveryProductHandle.forEach((_, index) => {
            if (!domesticRefs.current[index]) {
                domesticRefs.current[index] = createRef();
            }
        });
    }, [deliveryProductHandle]);


    const [formUploadErrSt, setFormUploadErrSt] = useState(false);
    const closeFormUploadErrSt = (() => {
        setFormUploadErrSt(false);
    })

    // 장바구니 업로드
    const readExcelCart = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((cartSheet) => {
                            const ws = workbook.Sheets[cartSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {
                                if (!row["MallId"] || !row["MallPw"] || !row["Quantity"] || !row["CartPrice"] || !row["MallURL"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        name: String(row["MallId"]),
                                        option: row["MallPw"],
                                        quantity: row["Quantity"],
                                        price: row["CartPrice"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        url: row["MallURL"],
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setCartProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const cartExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelCart(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 구매대행 업로드
    const readExcelOrder = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((orderSheet) => {
                            const ws = workbook.Sheets[orderSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {
                                if (!row["ProductName"] || !row["Price"] || !row["ShipPrice"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        name: String(row["ProductName"]),
                                        option: row["Option"],
                                        quantity: row["Quantity"],
                                        price: row["Price"]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        mallShipPrice: row["ShipPrice"]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        url: row["ProductURL"],
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setOrderProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const orderExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelOrder(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 오프라인 업로드
    const readExcelOffline = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((offlineSheet) => {
                            const ws = workbook.Sheets[offlineSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {
                                if (!row["ProductName"] || !row["Quantity"] || !row["TotalPrice"] || !row["Fee"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        name: String(row["ProductName"]),
                                        quantity: row["Quantity"],
                                        price: row["TotalPrice"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        fee: row["Fee"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setOfflineProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const offlineExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelOffline(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 이체대행 업로드
    const readExcelTransfer = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((transferSheet) => {
                            const ws = workbook.Sheets[transferSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {

                                if (!row["TransferAccountNumber"] || !row["TransferPrice"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    const transferPrice = parseFloat(row["TransferPrice"]);
                                    const fee = transferPrice * 0.07; // 7% 수수료
                                    const roundedCommission = Math.floor(fee / 10) * 10; // 10원 단위 절삭

                                    return {
                                        name: String(row["TransferAccountNumber"]),
                                        price: row["TransferPrice"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        fee: roundedCommission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setTransferProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const transferExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelTransfer(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 국내배송 업로드
    const readExcelDomestic = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((domesticSheet) => {
                            const ws = workbook.Sheets[domesticSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {

                                if (!row["ProductName"] || !row["Quantity"] || !row["Price"] || !row["ProductURL"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    const price = parseFloat(row["Price"]);
                                    const mallShipPrice = parseFloat(row["ShipPrice"]);
                                    const fee = price + mallShipPrice;
                                    const rawCommission = fee * 0.07; // 7% 수수료
                                    const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                                    return {
                                        name: String(row["ProductName"]),
                                        option: row["Option"],
                                        quantity: row["Quantity"],
                                        price: price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        mallShipPrice: mallShipPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        fee: roundedCommission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        url: row["ProductURL"],
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setDomesticProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const domesticExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelDomestic(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 배송대행 업로드
    const readExcelDelivery = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((deliverySheet) => {
                            const ws = workbook.Sheets[deliverySheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {
                                if (!row["PackageName"] || !row["TotalQuantity"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        domShipco: t('dvSelect'),
                                        inWaynum: row["WaybillNumber"],
                                        option: row["Option"],
                                        name: String(row["PackageName"]),
                                        quantity: row["TotalQuantity"],
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setDeliveryProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };


    const deliveryExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelDelivery(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 여기서부터 엑셀다운 함수
    const cartExcelDown = () => {

        const workbook = new excel.Workbook();
        workbook.addWorksheet('cartSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('cartSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'MallId', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'MallPw', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'cartPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'MallURL', width: 70, style: {alignment: {horizontal: 'center'}}},
        ];
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `cartForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const orderExcelDown = () => {

        const workbook = new excel.Workbook();
        workbook.addWorksheet('orderSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('orderSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'ProductName', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Option', width: 40, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 10, style: {alignment: {horizontal: 'center'}}},
            {header: 'Price', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'ShipPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'ProductURL', width: 70, style: {alignment: {horizontal: 'center'}}},
        ];
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `orderForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const offlineExcelDown = () => {

        const workbook = new excel.Workbook();
        workbook.addWorksheet('offlineSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('offlineSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'ProductName', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 10, style: {alignment: {horizontal: 'center'}}},
            {header: 'TotalPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'Fee', width: 20, style: {alignment: {horizontal: 'center'}}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `offlineForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const transferExcelDown = () => {

        const workbook = new excel.Workbook();
        workbook.addWorksheet('transferSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('transferSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'TransferAccountNumber', width: 40, style: {alignment: {horizontal: 'center'}}},
            {header: 'TransferPrice', width: 30, style: {alignment: {horizontal: 'center'}}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `transferForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const domesticExcelDown = () => {

        const workbook = new excel.Workbook();
        workbook.addWorksheet('domesticSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('domesticSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'ProductName', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Option', width: 40, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 10, style: {alignment: {horizontal: 'center'}}},
            {header: 'Price', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'ShipPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'ProductURL', width: 70, style: {alignment: {horizontal: 'center'}}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `domesticForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const deliveryExcelDown = () => {

        const workbook = new excel.Workbook();
        workbook.addWorksheet('deliverySheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('deliverySheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'WaybillNumber', width: 50, style: {alignment: {horizontal: 'center'}}},
            {header: 'PackageName', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'TotalQuantity', width: 20, style: {alignment: {horizontal: 'center'}}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `deliveryForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const address1Length = selectedDeliveryChoiceOption === "EMS" ? 200 :
        selectedDeliveryChoiceOption === "UPS" ? 200 :
            selectedDeliveryChoiceOption === "Small-Packet" ? 200 :
                selectedDeliveryChoiceOption === "K-Packet" ? 200 :
                    selectedDeliveryChoiceOption === "DHL" ? 35 :
                        selectedDeliveryChoiceOption === "FedEx" ? 35 :
                            selectedDeliveryChoiceOption === "SHIP" ? 200 :
                                selectedDeliveryChoiceOption === "국내배송" ? 100 : 35;
    const address2Length = selectedDeliveryChoiceOption === "EMS" ? 50 :
        selectedDeliveryChoiceOption === "UPS" ? 50 :
            selectedDeliveryChoiceOption === "Small-Packet" ? 50 :
                selectedDeliveryChoiceOption === "K-Packet" ? 50 :
                    selectedDeliveryChoiceOption === "DHL" ? 35 :
                        selectedDeliveryChoiceOption === "FedEx" ? 35 :
                            selectedDeliveryChoiceOption === "SHIP" ? 50 :
                                selectedDeliveryChoiceOption === "국내배송" ? 100 : 35;
    const address3Length = selectedDeliveryChoiceOption === "EMS" ? 50 :
        selectedDeliveryChoiceOption === "UPS" ? 50 :
            selectedDeliveryChoiceOption === "Small-Packet" ? 50 :
                selectedDeliveryChoiceOption === "K-Packet" ? 50 :
                    selectedDeliveryChoiceOption === "DHL" ? 35 :
                        selectedDeliveryChoiceOption === "FedEx" ? 35 :
                            selectedDeliveryChoiceOption === "SHIP" ? 50 :
                                selectedDeliveryChoiceOption === "국내배송" ? 100 : 35;
    const cityLength = selectedDeliveryChoiceOption === "EMS" ? 50 :
        selectedDeliveryChoiceOption === "UPS" ? 50 :
            selectedDeliveryChoiceOption === "Small-Packet" ? 50 :
                selectedDeliveryChoiceOption === "K-Packet" ? 50 :
                    selectedDeliveryChoiceOption === "DHL" ? 35 :
                        selectedDeliveryChoiceOption === "FedEx" ? 35 :
                            selectedDeliveryChoiceOption === "SHIP" ? 50 :
                                selectedDeliveryChoiceOption === "국내배송" ? 100 : 35;

    const validTextTaxid = ((e) => {
        const taxidRegex = /^[a-zA-Z0-9\s\-.,]{2,50}$/; /*2~50자*/
        if (!e.target.value || taxidRegex.test(e.target.value)) setTaxidError(false);
        else setTaxidError(true);
        setTaxid(e.target.value);
    })
    const validTextRecname = ((e) => {
        const recnameRegex = /^[a-zA-Z ]{2,25}/; /*영문 2~25자*/
        if (!e.target.value || recnameRegex.test(e.target.value)) setRecnameError(false);
        else setRecnameError(true);
        setRecname(e.target.value);
    })
    const validTextAddr1 = ((e) => {
        const Addr1Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address1Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr1Regex.test(e.target.value)) setAddress1Error(false);
        else setAddress1Error(true);
        setAddress1(e.target.value);
    })
    const validTextAddr2 = ((e) => {
        const Addr2Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address2Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr2Regex.test(e.target.value)) setAddress2Error(false);
        else setAddress2Error(true);
        setAddress2(e.target.value);
    })
    const validTextAddr3 = ((e) => {
        const Addr3Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address3Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr3Regex.test(e.target.value)) setAddress3Error(false);
        else setAddress3Error(true);
        setAddress3(e.target.value);
    })
    const validTextCity = ((e) => {
        const cityRegex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${cityLength}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || cityRegex.test(e.target.value)) setCityError(false);
        else setCityError(true);
        setCity(e.target.value);
    })
    const validTextZipcode = ((e) => {
        const zipcodeRegex = /^[a-zA-Z\d- ]{3,10}$/; // 암호화 // 영문 또는 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || zipcodeRegex.test(e.target.value)) setZipcodeError(false);
        else setZipcodeError(true);
        setZipcode(e.target.value);
    })
    const validTextTelnum = ((e) => {
        const telnumRegex = /^[\d-]{5,15}$/; // 암호화 // 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || telnumRegex.test(e.target.value)) setTelnumError(false);
        else setTelnumError(true);
        setTelnum(e.target.value);
    })

    const [paypalPopupSt, setPaypalPopupSt] = useState(false);
    const paypalPopupClose = (() => {
        setIsPayBtLoading(false);
        setPaypalPopupSt(false);
    })

    const parseNumber = (value) => {
        if (typeof value === 'string') {
            return parseFloat(value.replace(/,/g, ''));
        }
        return value;
    };

    // 결제수단 출력조건
    const isOrderPay = (
        cartProductHandle.some((item) => parseNumber(item.price) > 0) ||
        orderProductHandle.some((item) => parseNumber(item.price) > 0 || parseNumber(item.mallShipPrice) > 0) ||
        offlineProductHandle.some((item) => parseNumber(item.price) > 0 || parseNumber(item.fee) > 0) ||
        transferProductHandle.some((item) => parseNumber(item.price) > 0 || parseNumber(item.fee) > 0) ||
        domesticProductHandle.some((item) => parseNumber(item.price) > 0 || parseNumber(item.mallShipPrice) > 0 || parseNumber(item.fee) > 0)
    );

    // 이용약관 유효성검사
    const agreeValidation =
        (!cartProductHandle.some(cart => cart.name && cart.name.length > 0) || cartServiceAgreeCheck) &&
        (!orderProductHandle.some(order => order.name && order.name.length > 0) || orderServiceAgreeCheck) &&
        (!offlineProductHandle.some(offline => offline.name && offline.name.length > 0) || offlineServiceAgreeCheck) &&
        (!transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0) || transferServiceAgreeCheck) &&
        (!domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0) || domesticServiceAgreeCheck) &&
        (!deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0) || deliveryServiceAgreeCheck);

    // 이용약관 에러메시지 출력조건
    const agreeErrorMsgCondition = cartProductHandle.some(cart => cart.name && cart.name.length > 0) ||
        orderProductHandle.some(order => order.name && order.name.length > 0) ||
        offlineProductHandle.some(offline => offline.name && offline.name.length > 0) ||
        transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0) ||
        domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0) ||
        deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0)

    const validation = (saveFl) => {

        if (selectedDeliveryChoiceOption === t('dvSelect')) {
            setDeliveryChoiceError(true);
            return false;
        }
        if (selectedCountry.value === "ID" && !taxid) {
            setTaxidError(true);
            return false;
        }
        if (selectedCountry.value === "" || !selectedCountry.value) {
            setCountryError(true);
            return false;
        }
        if (!recname) {
            setRecnameError(true);
            return false;
        }
        if (!address1) {
            setAddress1Error(true);
            return false;
        }
        if (selectedCountry.value !== "KR" && !city) {
            setCityError(true);
            return false;
        }
        if (selectedCountry.value !== "KR" && !zipcode) {
            setZipcodeError(true);
            return false;
        }
        if (!telnum) {
            setTelnumError(true);
            return false;
        }
        // 상품 인풋양식들 검증
        if ((cartOrderAccordion && !scrollToEmptyFieldCart()) || (orderAccordion && !scrollToEmptyFieldOrder()) || (offlineAccordion && !scrollToEmptyFieldOffline()) ||
            (transferAccordion && !scrollToEmptyFieldTransfer()) || (domesticAccordion && !scrollToEmptyFieldDomestic()) || (deliveryAccordion && !scrollToEmptyFieldDelivery())) {
            return false;
        }
        // 각 폼마다 입력했고 저장이 아닌 결제일 경우 동의란에 체크하지 않았으면 Error
        if (!agreeValidation) {
            setAgreeCheckBoxError(true);
            return false;
        }
        // isOrderPay(결제수단)이 true(출력)인데 결제수단을 선택하지 않았을 경우 Error
        if (isOrderPay && payWay === '' && !saveFl) {
            setPayMethodError(true);
            return false;
        }
        //  || 예치금이 아닌 다른 결제수단으로 시도할 경우 Error
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) === 0 && (payWay !== 'PM04' && payWay !== 'PM05') && !saveFl) {
            setPointPayError(true);
            return false;
        }
        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원이고 결제수단이 예치금결제인데 예치금을 사용하지 않았을 경우
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) === 0 && payWay === "PM05" && depositSale === '' && !saveFl) {
            setPointSelectErr(true);
            return false;
        }
        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원이고 결제수단이 포인트결제인데 포인트나 쿠폰을 사용하지 않았을 경우
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) === 0 && payWay === "PM04" && (pointSale === '' && couponSale === '') && !saveFl) {
            setDepositSelectErr(true);
            return false;
        }
        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원이고 포인트 사용금액이나 쿠폰 사용금액이 0보다 큰데 결제수단이 포인트결제나 예치금결제가 아닐 경우
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) === 0 && (pointSale !== '' && depositSale !== '') && couponSale > 0 && (payWay !== 'PM04' && payWay !== 'PM05') && !saveFl) {
            setDepositSelectErr(true);
            return false;
        }
        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원보다 큰데 포인트 || 예치금으로 결제시도할 경우 Error
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) > 0 && (payWay === "PM04" || payWay === "PM05") && !saveFl) {
            setEtcPayError(true);
            return false;
        }
        if (Number(finalPayExpense.replace(/,/g, '')) < 0) {
            setDiscountError(true);
            return false;
        }

        return true;
    };


    const handleSubmit = (btType) => {
        if (isSaveBtDisabled || isPayBtDisabled) return;
        let saveFl;
        if (btType === "save") {
            saveFl = true;
            setIsSaveBtLoading(true);
            setIsSaveBtDisabled(true);
        } else if (btType === "pay") {
            saveFl = false;
            setIsPayBtLoading(true);
            setIsPayBtDisabled(true);
        }
        try {
            const cartProductFormValid = cartProductHandle.every(
                (item) => item.name !== ""
            );
            const orderProductFormValid = orderProductHandle.every(
                (item) => item.name !== ""
            );
            const offlineProductFormValid = offlineProductHandle.every(
                (item) => item.name !== ""
            );
            const transferProductFormValid = transferProductHandle.every(
                (item) => item.name !== ""
            );
            const domesticProductFormValid = domesticProductHandle.every(
                (item) => item.name !== ""
            );
            const deliveryProductFormValid = deliveryProductHandle.every(
                (item) => item.name !== ""
            );

            const orderInputCheck = (cartProductFormValid && cartProductHandle.length > 0) ||
                (orderProductFormValid && orderProductHandle.length > 0) ||
                (offlineProductFormValid && offlineProductHandle.length > 0) ||
                (transferProductFormValid && transferProductHandle.length > 0) ||
                (domesticProductFormValid && domesticProductHandle.length > 0) ||
                (deliveryProductFormValid && deliveryProductHandle.length > 0)

            if (validation(saveFl) && orderInputCheck) {
                // 결제수단이 페이팔이 아니면서 save 버튼을 클릭했을 때
                if (payWay !== "PM01" && saveFl) {
                    orderDataSave(true);
                    // 결제수단이 페이팔이 아니면서 Pay Now 버튼을 클릭했을 때
                } else if (payWay !== "PM01" && !saveFl) {
                    orderDataSave(false);
                    // 결제수단이 페이팔이면서 Pay Now 버튼을 클릭했을 때
                } else if (payWay === "PM01" && !saveFl){
                    setPaypalPopupSt(true);
                }
            } else {
                scrollToFirstEmptyField();
                scrollToEmptyFieldCart();
                scrollToEmptyFieldOrder();
                scrollToEmptyFieldOffline();
                scrollToEmptyFieldTransfer();
                scrollToEmptyFieldDomestic();
                scrollToEmptyFieldDelivery();
                throw new Error("유효성검사 실패");
            }
        } catch (error) {
            if (saveFl) {
                setIsSaveBtLoading(false);
            } else {
                setIsPayBtLoading(false);
            }
        } finally {
            if (saveFl) {
                setIsSaveBtDisabled(false);
            } else {
                setIsPayBtDisabled(false);
            }
        }
    };

    const handleUseType = (event) => {
        setUseType(event.target.value);
    };

    const addrBookTrueFn = (() => {
        setOrderAddrBookSt(true);
    })

    const addrBookCloseFn = (() => {
        setOrderAddrBookSt(false);
    })

    const bringToShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + "1",
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
    })
        .then((response) => {
            const filteredData = response.data.filter(shipCo => shipCo !== "DHL");
            setDeliveryChoice(filteredData);
        })
        .catch((error) => {
            console.log(error);
        });

    const DomesticShipCode = "0"
    const bringToDomesticShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + DomesticShipCode,
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
    })
        .then((response) => {
            setDomesticDeliveryChoice(response.data)
        })

    const handleChangeDeliveryChoice = (event) => {
        setSelectedDeliveryChoiceOption(event.target.value);
        setDeliveryChoiceError(false);
    };

    const recieverInfoAccordionFn = () => {
        setRecieverInfoAccordion(!recieverInfoAccordion);
    }

    const cartOrderAccordionFn = () => {
        setCartOrderAccordion(!cartOrderAccordion)
    }

    const orderAccordionFn = () => {
        setOrderAccordion(!orderAccordion)
    }

    const offlineAccordionFn = () => {
        setOfflineAccordion(!offlineAccordion)
    }

    const transferAccordionFn = () => {
        setTransferAccordion(!transferAccordion)
    }

    const domesticAccordionFn = () => {
        setDomesticAccordion(!domesticAccordion)
    }

    const deliveryAccordionFn = () => {
        setDeliveryAccordion(!deliveryAccordion)
    }

    const invoiceAccordionFn = () => {
        setInvoiceAccordion(!invoiceAccordion)
    }

    const requestConfigAccordionFn = () => {
        setRequestConfigAccordion(!requestConfigAccordion)
    }


    const addrTypeDefault = () => {
        getDefaultAddrInfo();
    }

    const addrTypeNew = () => {
        setSelectedCountry({value: '', label: t(`ordCrLabel`)})
        setTaxid("")
        setRecname("")
        setTelnum("")
        setZipcode("")
        setAddress1("")
        setAddress2("")
        setAddress3("")
        setCity("")
        setNameInputCount(0)
        setTelnumInputCount(0)
        setZipcodeInputCount(0)
        setAddress1InputCount(0)
        setAddress2InputCount(0)
        setAddress3InputCount(0)
        setCityInputCount(0)
    }

    const defaultAddrRadioChoice = () => {
        setDefaultAddr(true);
        setNewAddr(false);
    }
    const newAddrRadioChoice = () => {
        setDefaultAddr(false);
        setNewAddr(true);
    }

    const defaultAddrRadioHandle = async () => {
        defaultAddrRadioChoice();
        await addrTypeDefault();
    }

    const newAddrRadioHandle = async () => {
        newAddrRadioChoice();
        await addrTypeNew();
    }

    const [shipcoSelectedOption, setShipcoSelectedOption] = useState(t(`ordOsDvLabel`))
    const [shipcoIsOpen, setShipcoIsOpen] = useState(false);
    const [shipcoFocusedIndex, setShipcoFocusedIndex] = useState(0);
    const shipcoDropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (shipcoDropdownRef.current && !shipcoDropdownRef.current.contains(e.target)) {
                setShipcoIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const shipcoHandleSelect = (option, index) => {
        setShipcoSelectedOption(option);
        setShipcoFocusedIndex(index);
        setShipcoIsOpen(false);
        setSelectedDeliveryChoiceOption(option);
        if (option !== t(`ordOsDvLabel`)) {
            setDeliveryChoiceError(false);
        }
        if (option === "국내배송") {
            setSelectedCountry({value: 'KR', label: '대한민국(Republic of Korea)'});
        }
        // resetRecInfo();
    };

    const resetRecInfo = (() => {
        setRecname('');
        setTelnum('');
        setZipcode('');
        setAddress1('');
        setAddress2('');
        setAddress3('');
        setCity('');
    })

    const shipcoHandleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setShipcoFocusedIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % deliveryChoice.length;
                setShipcoSelectedOption(deliveryChoice[newIndex]);
                setSelectedDeliveryChoiceOption(deliveryChoice[newIndex]);
                if (e !== t(`ordOsDvLabel`)) {
                    setDeliveryChoiceError(false);
                }
                return newIndex;
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setShipcoFocusedIndex((prevIndex) => {
                const newIndex = (prevIndex - 1 + deliveryChoice.length) % deliveryChoice.length;
                setShipcoSelectedOption(deliveryChoice[newIndex]);
                setSelectedDeliveryChoiceOption(deliveryChoice[newIndex]);
                if (e !== t(`ordOsDvLabel`)) {
                    setDeliveryChoiceError(false);
                }
                return newIndex;
            });
        } else if (e.key === 'Enter') {
            e.preventDefault();
            shipcoHandleSelect(deliveryChoice[shipcoFocusedIndex], shipcoFocusedIndex);
            if (e !== t(`ordOsDvLabel`)) {
                setDeliveryChoiceError(false);
            }
        } else if (e.key === 'Escape') {
            if (e !== t(`ordOsDvLabel`)) {
                setDeliveryChoiceError(false);
            }
            setShipcoIsOpen(false);
        }
    };

    const shipcoHandleOnFocus = () => {
        if (shipcoFocusedIndex === -1) {
            setShipcoFocusedIndex(deliveryChoice.findIndex((option) => option.value === shipcoSelectedOption.value));
        }
    };

    // 여기서부터 국가 커스텀셀렉트
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const [filteredCountryList, setFilteredCountryList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(prev => !prev);
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setCountryShow(true);
        setSearchKeyword('');
    };

    const countryNameMapping = {
        'USA': 'United States of America',
    };

    const handleCountrySearch = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);

        const mappedKeyword = countryNameMapping[keyword.toUpperCase()] || keyword;

        const filteredCountries = countryList.filter(country =>
            country.label.toLowerCase().includes(mappedKeyword.toLowerCase())
        );

        setFilteredCountryList(filteredCountries);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectAllCountry = () => {
        axios.get('/order/selectCountry')
            .then(response => {
                const countries = response.data.map(country => ({
                    value: country.countryCode,
                    label: `${country.countryNm}(${country.countryEngNm})`
                })).sort((a, b) => a.label.localeCompare(b.label));

                setCountryList(countries);
                setFilteredCountryList(countries);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        selectAllCountry();
    }, [])

    // 여기까지 커스텀 셀렉트

    const nameInputLengthCheck = (e) => {
        setNameInputCount(e.target.value.length);
    };

    const telnumInputLengthCheck = (e) => {
        setTelnumInputCount(e.target.value.length);
    };

    const zipcodeInputLengthCheck = (e) => {
        setZipcodeInputCount(e.target.value.length);
    };

    const address1InputLengthCheck = (e) => {
        setAddress1InputCount(e.target.value.length);
    };

    const address2InputLengthCheck = (e) => {
        setAddress2InputCount(e.target.value.length);
    };

    const address3InputLengthCheck = (e) => {
        setAddress3InputCount(e.target.value.length);
    };

    const cityInputLengthCheck = (e) => {
        setCityInputCount(e.target.value.length);
    };

    const nameInputRTCheck = (e) => {
        setNameInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const telnumInputRTCheck = (e) => {
        setTelnumInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const zipcodeInputRTCheck = (e) => {
        setZipcodeInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address1InputRTCheck = (e) => {
        setAddress1InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address2InputRTCheck = (e) => {
        setAddress2InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address3InputRTCheck = (e) => {
        setAddress3InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const cityInputRTCheck = (e) => {
        setCityInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const nameValid = (e) => {
        validTextRecname(e)
        nameInputLengthCheck(e);
        nameInputRTCheck(e)
    }

    const telnumValid = (e) => {
        validTextTelnum(e)
        telnumInputLengthCheck(e);
        telnumInputRTCheck(e)
    }

    const zipcodeValid = (e) => {
        validTextZipcode(e)
        zipcodeInputLengthCheck(e);
        zipcodeInputRTCheck(e)
    }

    const address1Valid = (e) => {
        validTextAddr1(e)
        address1InputLengthCheck(e);
        address1InputRTCheck(e)
    }

    const address2Valid = (e) => {
        validTextAddr2(e)
        address2InputLengthCheck(e);
        address2InputRTCheck(e)
    }

    const address3Valid = (e) => {
        validTextAddr3(e)
        address3InputLengthCheck(e);
        address3InputRTCheck(e)
    }

    const cityValid = (e) => {
        validTextCity(e)
        cityInputLengthCheck(e);
        cityInputRTCheck(e)
    }

    const [inputMallPwEncryption, setInputMallPwEncryption] = useState({});
    const toggleMallPassword = (index) => {
        setInputMallPwEncryption(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const [fileSizeOverSt, setFileSizeOverSt] = useState(false);
    const closeFileSizeOverSt = (() => {
        setFileSizeOverSt(false);
    })
    const photoChangeHandler = (e) => {
        const file = e.target.files[0];
        const maxSize = 128 * 1024 * 1024; // 최대파일크기 128MB(백엔드에 맞추기)
        if (file.size > maxSize) {
            setFileSizeOverSt(true);
            return;
        }
        setInvoiceFile(file);
        setFileName(file.name)
    };

    const orderdtData = {
        useType: useType,
        orderStatus: "OS02",
        shipco: selectedDeliveryChoiceOption,
        cartAgree: cartServiceAgreeCheck,
        orderAgree: orderServiceAgreeCheck,
        offlineAgree: offlineServiceAgreeCheck,
        transferAgree: transferServiceAgreeCheck,
        domesticAgree: domesticServiceAgreeCheck,
        shipAgree: deliveryServiceAgreeCheck,
    }

    const orderRecinfo = {
        country: selectedCountry.value,
        taxid: selectedCountry.value === "ID" ? taxid : null,
        recname: recname,
        telnum: telnum,
        zipcode: zipcode,
        address1: address1,
        address2: address2,
        address3: address3,
        city: city
    }

    const beforeBackendRemoveComma = (str) => {
        return str.replace(/,/g, '');
    }

    const orderProductDataList = () => {
        const orderProductDTO = {};

        const formatPrice = (productList) => {
            return productList.map(product => {
                if (product.price) product.price = beforeBackendRemoveComma(product.price);
                if (product.mallShipPrice) product.mallShipPrice = beforeBackendRemoveComma(product.mallShipPrice);
                if (product.fee) product.fee = beforeBackendRemoveComma(product.fee);
                return product;
            });
        };

        if (cartProductHandle.some(item => item.name !== "")) {
            orderProductDTO.cartProductHandle = formatPrice(cartProductHandle);
        }
        if (orderProductHandle.some(item => item.name !== "")) {
            orderProductDTO.orderProductHandle = formatPrice(orderProductHandle);
        }
        if (offlineProductHandle.some(item => item.name !== "")) {
            orderProductDTO.offlineProductHandle = formatPrice(offlineProductHandle);
        }
        if (transferProductHandle.some(item => item.name !== "")) {
            orderProductDTO.transferProductHandle = formatPrice(transferProductHandle);
        }
        if (domesticProductHandle.some(item => item.name !== "")) {
            orderProductDTO.domesticProductHandle = formatPrice(domesticProductHandle);
        }
        if (deliveryProductHandle.some(item => item.name !== "")) {
            orderProductDTO.deliveryProductHandle = formatPrice(deliveryProductHandle);
        }

        return orderProductDTO;
    };

    const orderRequest = {
        tagRemove: tagRemove,
        shoesBoxRemove: shoesBoxRemove,
        hangerRemove: hangerRemove,
        compressionPack: compressionPack,
        prodBoxRemove: productBoxRemove,
        packingHold: packingHold,
        shipPriceDepositpay: deliveryDepositPay,
        shipPriceMessenger: deliveryMessengerGuide,
        detailReq: detailRequest,
    }

    const handlePayWayChange = (method) => {
        const initPaypal = method === "PM01";
        // 준비중(준비되면 initPaypal과 통합)
        const initDollar = method === "PM06" || method === "PM07" || method === "PM08";
        const initYuan = method === "PM02" || method === "PM03";
        if (initPaypal) {
            setCurrency("USD")
        } else if (initDollar) {
            setReadyingPopupSt(true);
            return;
        } else if (initYuan) {
            setCurrency("CNY")
        } else {
            setCurrency("")
        }
        setPayWay(method);
        setPayMethodError(false);
        setPointPayError(false);
        setDepositSelectErr(false);
        setPointSelectErr(false);
        setEtcPayError(false);
    }

    const closeReadyingPopup = (() => {
        setReadyingPopupSt(false);
    })

    const [paypalPayFl, setPaypalPayFl] = useState(false);
    const [couponSeqnum, setCouponSeqnum] = useState('')
    const orderFinalPayInfo = {
        ttProdPrice: totalPrices,
        ttMallShipPrice: totalMallShipPrice,
        etcFee: totalFee,
        ttEtcFee: totalFee,
        ttProdExpense: (totalPrices + totalMallShipPrice),
        pointUsePrice: pointSale ? pointSale : 0,
        depUsePrice: depositSale ? depositSale : 0,
        couponUseNum: couponSeqnum,
        couponUsePrice: couponSale ? couponSale : 0,
        payStatus: "PS1",
        payWay: payWay,
        payClass: "0",
        lastPrice: finalPayExpense,
        lastForPrice: lastForPrice,
        forCurrency: currency,
    }


    const convertedOrderFinalPrice = Object.entries(orderFinalPayInfo).reduce((acc, [key, value]) => {
        if (key === 'payStatus' || key === 'payClass' || key === 'payWay' || key === 'forCurrency') {
            acc[key] = value;
        } else if (typeof value === 'number' && !Number.isInteger(value)) {
            acc[key] = parseFloat(String(value).replace(',', ''));
        } else {
            acc[key] = parseInt(String(value).replace(',', ''));
        }
        return acc;
    }, {});


    const orderDataSave = async (data) => {
        if (data === "COMPLETED") {
            setPaypalPayFl(true);
        } else if (data) {
            await sendOrderDataToServer(true);
        } else {
            await sendOrderDataToServer(false);
        }
    };

    useEffect(() => {
        if (paypalPayFl) {
            sendOrderDataToServer(false);
        }
    }, [paypalPayFl]);

    const sendOrderDataToServer = async (saveFl) => {

        if (saveFl) {
            convertedOrderFinalPrice.pointUsePrice = 0;
            convertedOrderFinalPrice.depUsePrice = 0;
            convertedOrderFinalPrice.couponUseNum = '';
            convertedOrderFinalPrice.couponUsePrice = 0;
            convertedOrderFinalPrice.lastPrice = totalPrices + totalMallShipPrice + totalFee + totalOverseasShippingExpense + totalEtcExpense;
        }

        const orderProductListSave = orderProductDataList();
        const formData = new FormData();
        if (invoiceFile) {
            formData.append('file', invoiceFile);
        }
        formData.append('orderdtData', JSON.stringify(orderdtData));
        formData.append('orderRecinfo', JSON.stringify(orderRecinfo));
        formData.append('orderRequest', JSON.stringify(orderRequest));
        formData.append('orderFinalPayInfo', JSON.stringify(convertedOrderFinalPrice));
        formData.append('orderProductListSave', JSON.stringify(orderProductListSave));
        formData.append('couponSeqnum', couponSeqnum);

        await axios({
            method: 'post',
            url: '/order/orderSave',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                paypalPayFl: paypalPayFl,
                orderSaveFl: saveFl
            }
        })
            .then(async (response) => {
                const data = response.data;
                // 관리자메인에 오더알림업뎃
                saveOrderAlarmData(data);

                if ((data.payWay === "PM01" || data.payWay === "PM04" || data.payWay === "PM05") && !saveFl) {
                    navigate("/UserPayComplPg", {state: {data: data}})
                } else if ((data.payWay === "PM02" || data.payWay === "PM03" || data.payWay === "PM00") && !saveFl) {
                    navigate("/UserPayWaitPg", {state: {data: data}})
                } else {
                    navigate("/UserMyPg")
                }
            })
            .catch(() => {
                console.log('order fail');
            })
    };

    const saveOrderAlarmData = ((orderData) => {
        axios({
            method: 'post',
            url: '/order/saveOrderData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT,
                'Content-Type': 'application/json',
            },
            data: orderData
        })
            .then(async () => {
                await stompClient.send("/app/sendOrderAlarm", {}, JSON.stringify({sendFl: true}));
            })
            .catch((error) => {
                console.log("알림데이터 저장실패 : ", error)
            })
    })


    const [dollarShow, setDollarShow] = useState(false);
    const dollarRef = useRef(null);

    const toggleDollarShow = () => {
        setDollarShow(!dollarShow);
    };

    const selectChangeDollar = (e) => {
        setCurrency(e);
        setDollarShow(false);
        // handleLastForPrice(e);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (dollarRef.current && !dollarRef.current.contains(event.target)) {
                setDollarShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const paypalOrderProcess = (() => {
        paypalPopupClose();
        orderDataSave("COMPLETED");
    })

    const tagRemoveCheck = ((e) => {
        if (e.target.checked) {
            setTagRemove(true);
        } else setTagRemove(false);
    })

    const shoesBoxRemoveCheck = ((e) => {
        if (e.target.checked) {
            setShoesBoxRemove(true);
        } else setShoesBoxRemove(false);
    })

    const hangerRemoveCheck = ((e) => {
        if (e.target.checked) {
            setHangerRemove(true);
        } else setHangerRemove(false);
    })
    const compressionCheck = ((e) => {
        if (e.target.checked) {
            setCompressionPack(true);
        } else setCompressionPack(false);
    })

    const prodBoxRemoveCheck = ((e) => {
        if (e.target.checked) {
            setProductBoxRemove(true);
        } else setProductBoxRemove(false);
    })

    const packingHoldCheck = ((e) => {
        if (e.target.checked) {
            setPackingHold(true);
        } else setPackingHold(false);
    })

    const deliDepositPayCheck = ((e) => {
        if (e.target.checked) {
            setDeliveryDepositPay(true);
        } else setDeliveryDepositPay(false);
    })

    const deliMsgGuideCheck = ((e) => {
        if (e.target.checked) {
            setDeliveryMessengerGuide(true);
        } else setDeliveryMessengerGuide(false);
    })

    const detailRequestInput = ((e) => {
        setDetailRequest(e.target.value)
    })

    // 여기서부터는 결제정보 기능들
    useEffect(() => {
        addToTotalQuantity();
    })
    const addToTotalQuantity = () => {
        let totalPrices = 0;
        let totalMallShipPrice = 0;
        let totalFee = 0;
        let totalQty = 0;

        cartProductHandle.forEach((product) => {
            if (product.price) {
                totalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.quantity) {
                totalQty += parseInt(product.quantity);
            }
        });
        orderProductHandle.forEach((product) => {
            if (product.price) {
                totalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.mallShipPrice) {
                totalMallShipPrice += (parseInt(product.mallShipPrice.replace(/,/g, '')));
            }
            if (product.quantity) {
                totalQty += parseInt(product.quantity);
            }
        });
        offlineProductHandle.forEach((product) => {
            if (product.price) {
                totalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.quantity) {
                totalQty += parseInt(product.quantity);
            }
            if (product.fee) {
                totalFee += (parseInt(product.fee.replace(/,/g, '')));
            }
        });
        transferProductHandle.forEach((product) => {
            if (product.price) {
                totalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.fee) {
                totalFee += (parseInt(product.fee.replace(/,/g, '')))
            }
        });
        domesticProductHandle.forEach((product) => {
            if (product.price) {
                totalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.mallShipPrice) {
                totalMallShipPrice += (parseInt(product.mallShipPrice.replace(/,/g, '')));
            }
            if (product.fee) {
                totalFee += (parseInt(product.fee.replace(/,/g, '')));
            }
            if (product.quantity) {
                totalQty += parseInt(product.quantity);
            }
        });

        setTotalPrices(totalPrices);
        setTotalMallShipPrice(totalMallShipPrice)
        setTotalFee(totalFee);
        setTotalQuantity(totalQty);
    };

    const addrChoiceData = ((addrSeqnum) => {
        axios({
            method: 'get',
            url: '/member/choiceAddrInfo',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                seqnum: addrSeqnum
            }
        })
            .then((response) => {
                const countryData = {
                    value: response.data.countryDTO.countryCode,
                    label: response.data.countryDTO.countryNm + '(' + response.data.countryDTO.countryEngNm + ')'
                };
                setSelectedCountry(countryData)
                setTaxid(response.data.taxid)
                setRecname(response.data.recname)
                setAddress1(response.data.address1)
                setAddress2(response.data.address2)
                setAddress3(response.data.address3)
                setCity(response.data.city)
                setZipcode(response.data.zipcode)
                setTelnum(response.data.telnum)
                setNameInputCount(response.data.recname.length)
                setAddress1InputCount(response.data.address1.length)
                setAddress2InputCount(response.data.address2.length)
                setAddress3InputCount(response.data.address3.length)
                setCityInputCount(response.data.city.length)
                setZipcodeInputCount(response.data.zipcode.length)
                setTelnumInputCount(response.data.telnum.length)
                addrBookCloseFn();
            })
    })

    const getDefaultAddrInfo = (() => {
        axios({
            method: 'get',
            url: '/member/defaultAddrInfo',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                if (response.data) {
                    const countryData = {
                        value: response.data.countryDTO.countryCode,
                        label: response.data.countryDTO.countryNm + '(' + response.data.countryDTO.countryEngNm + ')'
                    };
                    setGetDefaultAddrList(response.data)
                    setSelectedCountry(countryData)
                    setTaxid(response.data.taxid)
                    setRecname(response.data.recname)
                    setTelnum(response.data.telnum)
                    setZipcode(response.data.zipcode)
                    setAddress1(response.data.address1)
                    setAddress2(response.data.address2)
                    setAddress3(response.data.address3)
                    setCity(response.data.city)
                    setNameInputCount(response.data.recname.length)
                    setTelnumInputCount(response.data.telnum.length)
                    setZipcodeInputCount(response.data.zipcode.length)
                    setAddress1InputCount(response.data.address1.length)
                    setAddress2InputCount(response.data.address2.length)
                    setAddress3InputCount(response.data.address3.length)
                    setCityInputCount(response.data.city.length)

                }
            })
    })

    useEffect(() => {
        const timer = setTimeout(() => {
            if (LCAT || SSAT) {
                bringToShipCo();
                bringToDomesticShipCo();
            }
            if (defaultAddr) {
                getDefaultAddrInfo();
            }
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])

    useEffect(() => {
        if (cartState === true) {
            setCartOrderAccordion(true);
            setOrderAccordion(false);
            setOfflineAccordion(false);
            setTransferAccordion(false);
            setDomesticAccordion(false);
            setDeliveryAccordion(false);
        } else if (orderState === true) {
            setOrderAccordion(true);
            setCartOrderAccordion(false);
            setOfflineAccordion(false);
            setTransferAccordion(false);
            setDomesticAccordion(false);
            setDeliveryAccordion(false);
        } else if (offlineState === true) {
            setOfflineAccordion(true);
            setOrderAccordion(false);
            setCartOrderAccordion(false);
            setTransferAccordion(false);
            setDomesticAccordion(false);
            setDeliveryAccordion(false);
        } else if (transferState === true) {
            setTransferAccordion(true);
            setOfflineAccordion(false);
            setOrderAccordion(false);
            setCartOrderAccordion(false);
            setDomesticAccordion(false);
            setDeliveryAccordion(false);
        } else if (domesticState === true) {
            setDomesticAccordion(true);
            setTransferAccordion(false);
            setOfflineAccordion(false);
            setOrderAccordion(false);
            setCartOrderAccordion(false);
            setDeliveryAccordion(false);
        } else if (deliveryState === true) {
            setDeliveryAccordion(true);
            setDomesticAccordion(false);
            setTransferAccordion(false);
            setOfflineAccordion(false);
            setOrderAccordion(false);
            setCartOrderAccordion(false);
        }
    }, [cartState, orderState, offlineState, transferState, domesticState, deliveryState])

    const allAgreeHandle = (e) => {
        const isChecked = e.target.checked;

        setAllAgreeCheck(isChecked);
        setAgreeCheckBoxError(false);

        if (cartProductHandle.some(cart => cart.name && cart.name.length > 0)) {
            setCartServiceAgreeCheck(isChecked);
        }
        if (orderProductHandle.some(order => order.name && order.name.length > 0)) {
            setOrderServiceAgreeCheck(isChecked);
        }
        if (offlineProductHandle.some(offline => offline.name && offline.name.length > 0)) {
            setOfflineServiceAgreeCheck(isChecked);
        }
        if (transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0)) {
            setTransferServiceAgreeCheck(isChecked);
        }
        if (domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0)) {
            setDomesticServiceAgreeCheck(isChecked);
        }
        if (deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0)) {
            setDeliveryServiceAgreeCheck(isChecked);
        }
    };

    useEffect(() => {
        const visibleConditions = [
            cartProductHandle.some(cart => cart.name && cart.name.length > 0),
            orderProductHandle.some(order => order.name && order.name.length > 0),
            offlineProductHandle.some(offline => offline.name && offline.name.length > 0),
            transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0),
            domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0),
            deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0)
        ];

        const checkedConditions = [
            cartServiceAgreeCheck,
            orderServiceAgreeCheck,
            offlineServiceAgreeCheck,
            transferServiceAgreeCheck,
            domesticServiceAgreeCheck,
            deliveryServiceAgreeCheck
        ];

        const visibleCheckedCount = visibleConditions.map((condition, index) => {
            if (condition) {
                return checkedConditions[index];
            }
            return null;
        }).filter(checked => checked !== null).length;

        const checkedCount = checkedConditions.filter(checked => checked).length;

        if (visibleCheckedCount === checkedCount) {
            setAllAgreeCheck(true);
        } else {
            setAllAgreeCheck(false);
        }
    }, [cartServiceAgreeCheck, orderServiceAgreeCheck, offlineServiceAgreeCheck, transferServiceAgreeCheck, domesticServiceAgreeCheck, deliveryServiceAgreeCheck, cartProductHandle, orderProductHandle, offlineProductHandle, transferProductHandle, domesticProductHandle, deliveryProductHandle]);

    const handleAgreeChange = (checkboxSetter) => (e) => {
        checkboxSetter(e.target.checked);
        setAgreeCheckBoxError(false);
    };

    const [memberInfo, setMemberInfo] = useState([{}]);

    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!memberInfo[0].mbNum || isSubscribedRef.current) return;
            if (!stompClient && !isConnected) {
                await websocketConnect(memberInfo[0].mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [memberInfo[0].mbNum, isConnected, stompClient]);

    const selectMemberInfo = () => axios({
        method: 'get',
        url: '/member/modiInfo/',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
    })
        .then((response) => {
            setMemberInfo(response.data)
        })

    useEffect(() => {
        const timer = setTimeout(() => {
            selectMemberInfo();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])

    const [useCouponSt, setUseCouponSt] = useState(false);
    const [dbCoupon, setDbCoupon] = useState('');
    const [biggerCpPriceSt, setBiggerCpPriceSt] = useState(false);
    const openBiggerCpPriceSt = (() => {
        setBiggerCpPriceSt(true);
    })
    const closeBiggerCpPriceSt = (() => {
        setBiggerCpPriceSt(false);
    })

    const showUseCoupon = (() => {
        if (Number(finalPayExpense.replace(/,/g, '')) === 0) {
            openBiggerCpPriceSt();
        } else {
            setUseCouponSt(true);
        }
    })

    const closeUseCoupon = (() => {
        setUseCouponSt(false);
        setCouponSale('');
        if (couponSale !== '') {
            setDbCoupon(dbCoupon + 1);
            setCouponSeqnum('');
        }
    })

    const couponUseFn = (coupon) => {
        if (coupon && coupon.couponMstDTO) {
            const couponPrice = coupon.couponMstDTO.couponPrice;
            const finalExpense = Number(finalPayExpense.replace(/,/g, ''));

            if (couponPrice > finalExpense) {
                setUseCouponSt(false);
                openBiggerCpPriceSt();
            } else {
                setCouponSale(couponPrice);
                setUseCouponSt(false);
                setDbCoupon(dbCoupon - 1);
                setCouponSeqnum(coupon.seqNum);
            }
        }
    };

    const [dbPoint, setDbPoint] = useState('');
    useEffect(() => {
        setDbPoint(memberInfo[0] && memberInfo[0].point);
        setDbDeposit(memberInfo[0] && memberInfo[0].deposit);
        const activeCouponCount = memberInfo[0] && memberInfo[0].coupons && memberInfo[0].coupons.filter(coupon => coupon && coupon.activeFl === true).length;
        setDbCoupon(activeCouponCount || 0);
    }, [memberInfo]);

    const handlePointSale = (e) => {
        let value = Number(e.replace(/[^0-9]/g, ''));
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            totalOverseasShippingExpense + totalEtcExpense - (Number(depositSale) + Number(couponSale)));
        // 입력값이 최종결제금액보다 크면 최종결제금액으로 제한
        if (value > finalExpense) {
            value = finalExpense;
        }

        // 입력값이 적립금잔액보다 크면 input에 잔액(맥시멈)으로 업뎃하고 잔액은 0으로 업뎃
        if (value > memberInfo[0].point) {
            setPointSale(memberInfo[0].point);
            setDbPoint('');
            // 입력값을 지우면 잔액은 되돌리고 input값은 0으로 업뎃
        } else if (value === 0 || value === '') {
            setPointSale('');
            setDbPoint(memberInfo[0].point);
        } else {
            // 이 외에는 input에 사용자입력값, 잔액은 입력값만큼만 차감
            setPointSale(value);
            setDbPoint(memberInfo[0].point - value);
        }
    };

    const allPointSale = () => {
        let maxPoint = memberInfo[0] ? memberInfo[0].point : '';
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            totalOverseasShippingExpense + totalEtcExpense - (Number(depositSale) + Number(couponSale)));
        if (maxPoint > finalExpense) {
            maxPoint = finalExpense;
        }

        setPointSale(maxPoint);
        setDbPoint(memberInfo[0].point - maxPoint);
    };

    const [dbDeposit, setDbDeposit] = useState('');

    const handleDepositSale = (e) => {
        let value = Number(e.replace(/[^0-9]/g, ''));
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            totalOverseasShippingExpense + totalEtcExpense - (Number(pointSale) + Number(couponSale)));

        if (value > finalExpense) {
            value = finalExpense;
        }

        if (value > memberInfo[0].deposit) {
            setDepositSale(memberInfo[0].deposit);
            setDbDeposit('');
        } else if (value === 0 || value === '') {
            setDepositSale('');
            setDbDeposit(memberInfo[0].deposit);
        } else {
            setDepositSale(value);
            setDbDeposit(memberInfo[0].deposit - value);
        }
    };

    const allDepositSale = () => {
        let maxDeposit = memberInfo[0] ? memberInfo[0].deposit : '';
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            totalOverseasShippingExpense + totalEtcExpense - (Number(pointSale) + Number(couponSale)));
        if (maxDeposit > finalExpense) {
            maxDeposit = finalExpense;
        }

        setDepositSale(maxDeposit);
        setDbDeposit(memberInfo[0].deposit - maxDeposit);
    };

    // const [showPayNow, setShowPayNow] = useState(false);
    //
    // useEffect(() => {
    //     const checkIfPayNowShouldBeShown = () => {
    //         // 폼마다 금액이 1원이라도 있을 경우 PayNow버튼 비활
    //         const shouldShow = [
    //             ...cartProductHandle,
    //             ...orderProductHandle,
    //             ...offlineProductHandle,
    //             ...transferProductHandle,
    //             ...domesticProductHandle
    //         ].some(form =>
    //             parseFloat(form.price || 0) > 0 ||
    //             parseFloat(form.mallShipPrice || 0) > 0 ||
    //             parseFloat(form.fee || 0) > 0
    //         );
    //         setShowPayNow(shouldShow);
    //     };
    //
    //     checkIfPayNowShouldBeShown();
    // }, [cartProductHandle, orderProductHandle, offlineProductHandle, transferProductHandle, domesticProductHandle]);

    const [usdExrInfo, setUsdExrInfo] = useState([]);

    const selectedAllExrInfo = (() => {
        axios({
            method: 'get',
            url: '/paypal/selectedAllExrInfo/',
        })
            .then((response) => {
                setUsdExrInfo(response.data)
            })
            .catch(() => {
                console.log("환율조회실패")
            })
    })

    useEffect(() => {
        selectedAllExrInfo();
    }, [])

    const calculateLastForPrice = () => {
        if ((payWay === "PM01" || payWay === "PM02" || payWay === "PM03") && currency && usdExrInfo.length > 0) {
            const usdExInfo = usdExrInfo.find(info => info.curPrice === currency);
            const numericFinalPayExpense = parseFloat(finalPayExpense.replace(/,/g, ''));

            // 금액(50,000) / (환율(1333.14) - 요율(9.1%)) = 41.26usd
            if (!isNaN(numericFinalPayExpense) && usdExInfo) {
                const payRateAsRatio = usdExInfo.payRate / 100;
                const newLastForPrice = numericFinalPayExpense / (usdExInfo.payExr - (usdExInfo.payExr * payRateAsRatio));

                console.log("usdExInfo : ", usdExInfo)
                console.log("usdExInfo.payExr : ", usdExInfo.payExr)
                console.log("payRateAsRatio : ", payRateAsRatio)
                console.log("numericFinalPayExpense : ", numericFinalPayExpense)

                setLastForPrice(Number(newLastForPrice.toFixed(2)));
            }
        } else {
            setLastForPrice(null);
        }
    }

    useEffect(() => {
        calculateLastForPrice();
    }, [payWay, currency, usdExrInfo, finalPayExpense]);


    const [cartTermsPopup, setCartTermsPopup] = useState(false);
    const [orderTermsPopup, setOrderTermsPopup] = useState(false);
    const [offlineTermsPopup, setOfflineTermsPopup] = useState(false);
    const [transferTermsPopup, setTransferTermsPopup] = useState(false);
    const [domesticTermsPopup, setDomesticTermsPopup] = useState(false);
    const [deliveryTermsPopup, setDeliveryTermsPopup] = useState(false);

    const closeCartTermsPopup = (() => {
        setCartTermsPopup(false);
    })
    const closeOrderTermsPopup = (() => {
        setOrderTermsPopup(false);
    })
    const closeOfflineTermsPopup = (() => {
        setOfflineTermsPopup(false);
    })
    const closeTransferTermsPopup = (() => {
        setTransferTermsPopup(false);
    })
    const closeDomesticTermsPopup = (() => {
        setDomesticTermsPopup(false);
    })
    const closeDeliveryTermsPopup = (() => {
        setDeliveryTermsPopup(false);
    })

    return (
        <div className="m-a-order-pg-container font-t-cont">
            <div className="m-a-order-pg-bottom-navigation-bar">
                <div className="m-a-order-detail-pg-bottom-navigation-bar-button-save"
                     style={{pointerEvents: isSaveBtDisabled ? 'none' : 'auto'}}
                     onClick={() => handleSubmit("save")}>
                    {isSaveBtLoading ? (
                        <div className="spinner" style={{margin: "0 auto"}}></div>
                    ) : (
                        t(`modPiBt2`)
                    )}
                </div>
                <button className={`admin-btn-C wd-100pc ht-45 font-s-16 m-a-order-pg-bottom-navigation-bar-button-pay ${!isOrderPay && 'disabled'}`}
                     style={{pointerEvents: isPayBtDisabled ? 'none' : 'auto'}}
                     onClick={() => handleSubmit("pay")}>
                    {isPayBtLoading ? (
                        <div className="spinner" style={{margin: "0 auto"}}></div>
                    ) : (
                        <div>{t(`modPiBt1`)} ({finalPayExpense}{t('won')})</div>
                    )}</button>
            </div>
            <MHeader title={`${t(`myOrdPcHeader1`)}/${t(`dcPayBt`)}`} url={'/'}/>
            <div className='m-a-order-sub-title' onClick={recieverInfoAccordionFn}>
                <img className='m-a-order-sub-title-icon' src={mainReceived} alt=''/>
                <div className='m-a-order-sub-title-text'>{t(`modRefInfo`)} </div>
                <i className={`accordion-icon m-a-order-sub-title-expand ${recieverInfoAccordion && 'expanded'}`}></i>
            </div>
            <div className='m-a-order-divider'/>
            {recieverInfoAccordion && (
                <>
                    <div className={'m-a-order-select-box'}>
                        <div className={'m-a-order-select-option'}>
                            <input className="m-a-order-select-option-icon" type="radio" name="purposeDelivery"
                                   defaultChecked="gift" value="gift" onChange={handleUseType}/>
                            <div className={'m-a-order-select-option-text'}>{t(`modCont1`)}</div>
                        </div>
                        <div className={'m-a-order-select-option'}>
                            <input className="m-a-order-select-option-icon" type="radio" name="purposeDelivery"
                                   defaultChecked="gift" value="gift" onChange={handleUseType}/>
                            <div className={'m-a-order-select-option-text'}>{t(`modCont2`)}</div>
                        </div>
                        <div className={'m-a-order-select-option'}>
                            <input className="m-a-order-select-option-icon" type="radio" name="purposeDelivery"
                                   defaultChecked="gift" value="gift" onChange={handleUseType}/>
                            <div className={'m-a-order-select-option-text'}>{t(`modCont3`)}</div>
                        </div>
                    </div>
                    <div className={'m-a-order-select-box'}>
                        <div className={'m-a-order-select-option'} onClick={() => setDeliveryAddress(0)}>
                            <input className="radioButton" type="radio" name="deliveryAddr" value={defaultAddr}
                                   defaultChecked onChange={defaultAddrRadioHandle}/>
                            <div className={'m-a-order-select-option-text'}>{t(`ordDfAddr`)}</div>
                        </div>
                        <div className={'m-a-order-select-option'} onClick={() => setDeliveryAddress(1)}>
                            <input className="radioButton" type="radio" name="deliveryAddr" value={newAddr}
                                   onChange={newAddrRadioHandle}/>
                            <div className={'m-a-order-select-option-text'}>{t(`ordNewAddr`)}</div>
                        </div>
                        <div className={'m-a-order-delivery-address'}
                             onClick={addrBookTrueFn}>{t(`ordDvAddrBook`)}
                        </div>
                    </div>
                    <div className={'m-a-order-delivery-frame'}>
                        <div className="ht-45 wd-100pc position-relative" tabIndex="0" onKeyDown={shipcoHandleKeyDown} onFocus={shipcoHandleOnFocus}
                             ref={(el) => {
                                 shipcoDropdownRef.current = el;
                                 selectedDeliveryChoiceOptionRef.current = el;
                             }}
                        >
                            <button className="selectParentBt wd-100pc ht-100pc" onClick={() => setShipcoIsOpen(!shipcoIsOpen)}>
                                <p className="font-s-14 font-c-default">{selectedDeliveryChoiceOption}</p>
                                <span className="position-absolute ht-100pc wd-10pc" style={{right: "0", top: "0"}}>
                                                    <i className="display-flex just-cont-center align-center ht-100pc font-s-20" style={{color: "darkgray"}}>
                                                        <BiCaretDown/>
                                                    </i>
                                                </span>
                            </button>
                            {shipcoIsOpen ?
                                <div className="wd-100pc position-absolute m-t-5" style={{
                                    maxHeight: "220px",
                                    overflowY: "auto",
                                    border: "solid 1px lightgray",
                                    top: "100%",
                                    zIndex: 1,
                                }}>
                                    {deliveryChoice.map((option, index) => (
                                        <div className="selectChildBt display-flex justify-content-center align-center c-point font-s-14"
                                             key={index} onClick={() => shipcoHandleSelect(option, index)} style={{padding: "10px", zIndex: 99}}>
                                            <p className="font-s-14">{option}</p>
                                        </div>
                                    ))}
                                </div>
                                :
                                null
                            }
                        </div>
                        {deliveryChoiceError &&
                            <div className={'m-a-order-error-message-box'}> {t(`recNoSelectOsdvErr`)}</div>}
                            {/*국가 셀렉트new*/}
                            <div className="ht-45 wd-100pc position-relative" ref={countryRef} onClick={toggleCountryShow}>
                                <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                                    {selectedCountry.value ? (
                                        <div className="wd-15pc text-center p-l-5 p-t-10">
                                            <div className={`m-l-6 font-s-20 fi fi-${selectedCountry.value.toLowerCase()}`} />
                                        </div>
                                    ) : (
                                        <div className="wd-15pc text-center p-l-5 p-t-10">
                                            <div className="wd-29 ht-22 text-center font-s-12 m-0-a country-select-flag">?</div>
                                        </div>
                                    )}
                                    <input className="country-select-in-input wd-75pc font-s-14 p-l-43"
                                           type="text" placeholder={t(`modSmallMenu3-1`)}
                                           value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '')}
                                           onChange={handleCountrySearch} />
                                    <i className="wd-10pc text-center align-center-c font-s-20 p-b-2 font-c-darkgrey">
                                        <BiCaretDown />
                                    </i>
                                </div>
                                {countryShow && (
                                    <div className="country-select-data wd-100pc position-absolute m-t-5">
                                        {filteredCountryList.map((country, index) => (
                                            <div className="selectChildBt display-flex p-t-10 p-b-10 p-l-20" key={index}
                                                 onClick={() => handleCountryChange(country)} style={{zIndex: 99 }}>
                                                <div className={`font-s-17 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                                <p className="font-s-15">{country.label}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        {countryError &&
                            <div className={'m-a-order-error-message-box'}> {t(`recCrErr`)}</div>}
                        {selectedCountry.value === "ID" &&
                            <div className={'m-a-order-delivery-input-box position-relative'}>
                                <div className="m-a-order-delivery-input-text p-l-4 position-absolute" style={{left: "0", top: "50%", transform: "translateY(-50%)"}}>
                                    <div className="m-a-order-delivery-input highlight">*</div>
                                    Tax ID
                                </div>
                                <input className={'m-a-order-delivery-input wd-100pc'} style={{paddingLeft: "26.8%"}}
                                       maxLength={25} value={taxid} onChange={validTextTaxid}
                                       ref={taxidRef}/>
                            </div>
                        }
                        {taxidError && <div className={'m-a-order-error-message-box'}> {t(`recTaxIdErr`)}</div>}
                        <div className="m-a-order-delivery-input-box position-relative">
                            <div className="m-a-order-delivery-input-text p-l-4 position-absolute" style={{left: "0", top: "50%", transform: "translateY(-50%)"}}>
                                <div className="m-a-order-delivery-input highlight">*</div>
                                {t(`modSmallMenu4-1`)} {/*수취인명*/}
                            </div>
                            <input className={'m-a-order-delivery-input wd-100pc'} style={{paddingLeft: "26.8%"}}
                                   maxLength={25} value={recname} onChange={nameValid}
                                   ref={recnameRef}/>
                            {selectedCountry.value !== "KR" && (
                                <div className="m-a-order-delivery-input-text text-right p-r-10 position-absolute" style={{right: "0", top: "50%", transform: "translateY(-50%)"}}>
                                    <div className="m-a-order-delivery-input highlight">{nameInputCount}</div>
                                    / 25
                                </div>
                            )}
                        </div>
                        {recnameError && selectedCountry.value !== "KR" && <div className={'m-a-order-error-message-box'}> {t(`recNmErr`)}</div>}
                        <div className="m-a-order-delivery-input-box position-relative">
                            <div className="m-a-order-delivery-input-text p-l-4 position-absolute" style={{left: "0", top: "50%", transform: "translateY(-50%)"}}>
                                <div className={'m-a-order-delivery-input highlight'}>*</div>
                                {t(`modSmallMenu5`)} {/*연락처*/}
                            </div>
                            <input className={'m-a-order-delivery-input wd-100pc'} style={{paddingLeft: "26.8%"}}
                                   maxLength={15} value={telnum} onChange={telnumValid} ref={telnumRef}/>
                            {selectedCountry.value !== "KR" && (
                                <div className="m-a-order-delivery-input-text text-right p-r-10 position-absolute" style={{right: "0", top: "50%", transform: "translateY(-50%)"}}>
                                    <div className={'m-a-order-delivery-input highlight'}>{telnumInputCount}</div>
                                    / 15
                                </div>
                            )}
                        </div>
                        {telnumError && selectedCountry.value !== "KR" && <div className={'m-a-order-error-message-box'}> {t(`recTellErr`)}</div>}
                        {selectedCountry.value !== "KR" && (
                            <div className="m-a-order-delivery-input-box position-relative">
                                <div className="m-a-order-delivery-input-text p-l-4 position-absolute" style={{left: "0", top: "50%", transform: "translateY(-50%)"}}>
                                    <div className={'m-a-order-delivery-input highlight'}>*</div>
                                    {t(`modSmallMenu6`)}
                                </div> {/*우편번호*/}
                                <input className={'m-a-order-delivery-input wd-100pc'} style={{paddingLeft: "26.8%"}}
                                       maxLength={10} value={zipcode} onChange={zipcodeValid} ref={zipcodeRef}/>
                                {selectedCountry.value !== "KR" && (
                                    <div className="m-a-order-delivery-input-text text-right p-r-10 position-absolute" style={{right: "0", top: "50%", transform: "translateY(-50%)"}}>
                                        <div className={'m-a-order-delivery-input highlight'}>{zipcodeInputCount}</div>
                                        / 15
                                    </div>
                                )}
                            </div>
                        )}
                        {selectedCountry.value !== "KR" && zipcodeError && <div className={'m-a-order-error-message-box'}> {t(`recTellErr`)}</div>}
                        <div className="m-a-order-delivery-input-box position-relative">
                            <div className="display-inline font-s-11 p-l-4 position-absolute" style={{left: "0", top: "50%", transform: "translateY(-50%)"}}>
                                <div className={'m-a-order-delivery-input highlight'}>*</div>
                                {selectedDeliveryChoiceOption === "국내배송" ? t(`modSmallMenu14`) : t(`modSmallMenu7`)}
                            </div> {/*주소1(주/도)*/}
                            <input className={'m-a-order-delivery-input wd-100pc'} style={{paddingLeft: "26.8%"}}
                                   maxLength={address1Length} value={address1} onChange={address1Valid} ref={address1Ref}/>
                            {selectedCountry.value !== "KR" && (
                                <div className="m-a-order-delivery-input-text text-right p-r-10 position-absolute" style={{right: "0", top: "50%", transform: "translateY(-50%)"}}>
                                    <div className={'m-a-order-delivery-input highlight'}>{address1InputCount}</div>
                                    {`/${address1Length}`}
                                </div>
                            )}
                        </div>

                        {/*<div className="display-flex flex-row ht-45 border-rd4 wd-100pc border-bt">*/}
                        {/*    <div className="display-inline font-s-11 p-l-4 position-absolute" style={{left: "0", top: "50%", transform: "translateY(-50%)"}}>*/}
                        {/*        <div className={'m-a-order-delivery-input highlight'}>*</div>*/}
                        {/*        {selectedDeliveryChoiceOption === "국내배송" ? t(`modSmallMenu14`) : t(`modSmallMenu7`)}*/}
                        {/*    </div> /!*주소1(주/도)*!/*/}
                        {/*        <input className="wd-100pc ht-100pc" maxLength={address1Length} value={address1} onChange={address1Valid} ref={address1Ref} placeholder={t(`modSmallMenu7`)}/>*/}
                        {/*    <div>*/}
                        {/*        {selectedCountry.value !== "KR" && (*/}
                        {/*            <p>{address1InputCount}{`/${address1Length}`}</p>*/}
                        {/*        )}*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {selectedCountry.value !== "KR" && address1Error &&
                            <div className={'m-a-order-error-message-box'}>{`${t("recAddrErr1")} 2~${address1Length}${t("recAddrErr2")}`}</div>
                        }
                        {selectedCountry.value !== "KR" && (
                            <div className="m-a-order-delivery-input-box position-relative">
                                <div className="display-inline font-s-11 p-l-4 position-absolute" style={{left: "0", top: "50%", transform: "translateY(-50%)"}}>
                                    <div className={'m-a-order-delivery-input highlight'}>&nbsp;</div>
                                    {t(`modSmallMenu8`)}
                                </div>
                                <input className={'m-a-order-delivery-input wd-100pc'} style={{paddingLeft: "26.8%"}}
                                       maxLength={address2Length} value={address2} onChange={address2Valid}/>
                                {selectedCountry.value !== "KR" && (
                                    <div className="m-a-order-delivery-input-text text-right p-r-10 position-absolute" style={{right: "0", top: "50%", transform: "translateY(-50%)"}}>
                                        <div className={'m-a-order-delivery-input highlight'}>{address2InputCount}</div>
                                        {`/${address2Length}`}
                                    </div>
                                )}
                            </div>
                        )}
                        {selectedCountry.value !== "KR" && address2Error &&
                            <div className={'m-a-order-error-message-box'}>{`${t("recAddrErr1")} 2~${address2Length}${t("recAddrErr2")}`}</div>
                        }
                        {selectedCountry.value !== "KR" && (
                            <div className="m-a-order-delivery-input-box position-relative">
                                <div className="display-inline font-s-11 p-l-4 position-absolute" style={{left: "0", top: "50%", transform: "translateY(-50%)"}}>
                                    <div className={'m-a-order-delivery-input highlight'}>&nbsp;</div>
                                    {t(`modSmallMenu9`)}
                                </div>
                                <input className={'m-a-order-delivery-input wd-100pc'} style={{paddingLeft: "26.8%"}}
                                       maxLength={address3Length} value={address3} onChange={address3Valid}/>
                                {selectedCountry.value !== "KR" && (
                                    <div className="m-a-order-delivery-input-text text-right p-r-10 position-absolute" style={{right: "0", top: "50%", transform: "translateY(-50%)"}}>
                                        <div className={'m-a-order-delivery-input highlight'}> {address3InputCount}</div>
                                        {`/${address3Length}`}
                                    </div>
                                )}
                            </div>
                        )}
                        {selectedCountry.value !== "KR" && address3Error &&
                            <div className={'m-a-order-error-message-box'}>{`${t("recAddrErr1")} 2~${address3Length}${t("recAddrErr2")}`}</div>
                        }
                        {selectedCountry.value !== "KR" && (
                            <div className="m-a-order-delivery-input-box position-relative">
                                <div className="m-a-order-delivery-input-text p-l-4 position-absolute" style={{left: "0", top: "50%", transform: "translateY(-50%)"}}>
                                    <div className={'m-a-order-delivery-input highlight'}>*</div>
                                    {t(`modSmallMenu10`)}
                                </div>
                                <input className={'m-a-order-delivery-input wd-100pc'} style={{paddingLeft: "26.8%"}}
                                       maxLength={cityLength} value={city} onChange={cityValid} ref={cityRef}/>
                                {selectedCountry.value !== "KR" && (
                                    <div className="m-a-order-delivery-input-text text-right p-r-10 position-absolute" style={{right: "0", top: "50%", transform: "translateY(-50%)"}}>
                                        <div className={'m-a-order-delivery-input highlight'}> {cityInputCount}</div>
                                        {`/${cityLength}`}
                                    </div>
                                )}
                            </div>
                        )}
                        {selectedCountry.value !== "KR" && cityError &&
                            <div className={'m-a-order-error-message-box'}>{`${t("recAddrErr1")} 2~${cityLength}${t("recAddrErr2")}`}</div>}
                    </div>
                </>
            )}
            {/*장바구니*/}
            <div className='m-a-order-sub-title m-t-7' onClick={cartOrderAccordionFn}>
                <img className='m-a-order-sub-title-icon' src={detailCart} alt=''/>
                <div className='m-a-order-sub-title-text'>{t(`cartTitle`)} </div>
                {cartProductHandle.length > 0 && (
                    <i className={`accordion-icon m-a-order-sub-title-expand ${cartOrderAccordion && cartProductHandle.length > 0 && 'expanded'}`}/>
                )}
            </div>
            <div className='m-a-order-divider'/>
            {cartOrderAccordion && cartProductHandle.length > 0 && (
                <div className={'m-a-order-section-frame'}>
                    <div className={'m-a-order-section-title'}>{t(`cartTitle`)}</div>
                    {cartProductHandle.map((cart, index) => (
                        <MOrderSection key={index}
                                       indexNm={index + 1}
                                       onClickAdd={() => addCartProduct(index)}
                                       onClickClone={() => copyCartProduct(index)}
                                       onClickDelete={() => deleteCartProduct(index)}>
                            <div className={'m-a-order-section-frame-box'}>
                                {MOrderInput(t(`mallId`), cart.name || '', "text",
                                    200,
                                    (ref) => {
                                        if (cartItemsRefs.current[index]) {
                                            cartItemsRefs.current[index].name = ref;
                                        }
                                    },
                                    (e) => handleCartProductInput(index, 'name', e.target.value))}
                                <div className={'m-a-order-input-box'}>
                                    {cart.option && cart.option.length > 0 &&
                                        <div className={'m-a-order-input-title'}>{t(`mallPw`)}</div>}
                                    <input className={'m-a-order-input'}
                                           type={inputMallPwEncryption[index] ? "text" : "password"}
                                           value={cart.option || ''}
                                           ref={(ref) => {
                                               if (cartItemsRefs.current[index]) {
                                                   cartItemsRefs.current[index].option = ref;
                                               }
                                           }} placeholder={t(`mallPw`)} maxLength={50}
                                           onChange={(e) => handleCartProductInput(index, 'option', e.target.value)}/>
                                    <div className={'m-a-order-input-password'}
                                         onClick={() => toggleMallPassword(index)}>
                                        {inputMallPwEncryption[index] ? (
                                            <img className={'m-a-user-order-input-pw-icon'} src={pwHide}
                                                 alt={'pwHide'}/>
                                        ) : (
                                            <img className={'m-a-user-order-input-pw-icon'} src={pwView}
                                                 alt={'pwView'}/>
                                        )}
                                    </div>
                                </div>
                                {MOrderInput(t(`cartQy`), cart.quantity || '', "text",
                                    5,
                                    (ref) => {
                                        if (cartItemsRefs.current[index]) {
                                            cartItemsRefs.current[index].quantity = ref;
                                        }
                                    },
                                    (e) => cartQuantityNumberInput(index, 'quantity', e.target.value))}
                                {MOrderInput(t(`cartPr`),
                                    (cart.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                    "text",
                                    12,
                                    (ref) => {
                                        if (cartItemsRefs.current[index]) {
                                            cartItemsRefs.current[index].price = ref;
                                        }
                                    },
                                    (e) => cartPriceNumberInput(index, 'price', e.target.value))}
                                {MOrderInput(t(`cartUrl`),
                                    cart.url || '',
                                    "text",
                                    2083,
                                    (ref) => {
                                        if (cartItemsRefs.current[index]) {
                                            cartItemsRefs.current[index].url = ref;
                                        }
                                    },
                                    (e) => handleCartProductInput(index, 'url', e.target.value))}
                            </div>
                        </MOrderSection>
                    ))}
                </div>
            )}
            {!cartProductHandle[0] &&
                <div className={'m-a-order-button-empty'} onClick={addCartProduct}>
                    {t(`modAddBt1`)}</div>
            }
            {/*구매대행*/}
            <div className='m-a-order-sub-title m-t-7' onClick={orderAccordionFn}>
                <img className='m-a-order-sub-title-icon' src={detailOrder} alt=''/>
                <div className='m-a-order-sub-title-text'>{t(`modHeader2`)} </div>
                {orderProductHandle.length > 0 && (
                    <i className={`accordion-icon m-a-order-sub-title-expand 
                ${orderAccordion && orderProductHandle.length > 0 && 'expanded'}`}/>
                )}
            </div>
            <div className='m-a-order-divider'/>
            {orderAccordion && orderProductHandle.length > 0 && (
                <div className={'m-a-order-section-frame'}>
                    <div className={'m-a-order-section-title'}>{t(`orderTitle`)}</div>
                    {orderProductHandle.map((order, index) => (
                        <MOrderSection key={index}
                                       indexNm={index + 1}
                                       onClickAdd={addOrderProduct}
                                       onClickClone={() => copyOrderProduct(index)}
                                       onClickDelete={() => deleteOrderProduct(index)}>
                            <div className={'m-a-order-section-frame-box'}>
                                {MOrderInput(t(`prodNm`), order.name || '', "text",
                                    200,
                                    (ref) => {
                                        if (orderItemsRefs.current[index]) {
                                            orderItemsRefs.current[index].name = ref;
                                        }
                                    },
                                    (e) => handleOrderProductInput(index, 'name', e.target.value)
                                )}
                                {MOrderInput(t(`prodOp`), order.option || '', "text",
                                    50,
                                    null,
                                    (e) => handleOrderProductInput(index, 'option', e.target.value)
                                )}
                                {MOrderInput(t(`prodQy`), order.quantity || '', "text",
                                    5,
                                    (ref) => {
                                        if (orderItemsRefs.current[index]) {
                                            orderItemsRefs.current[index].quantity = ref;
                                        }
                                    },
                                    (e) => orderQuantityNumberInput(index, 'quantity', e.target.value)
                                )}
                                {MOrderInput(t(`prodPr`),
                                    (order.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                    "text", 12,
                                    (ref) => {
                                        if (orderItemsRefs.current[index]) {
                                            orderItemsRefs.current[index].price = ref;
                                        }
                                    },
                                    (e) => orderPriceNumberInput(index, 'price', e.target.value)
                                )}
                                {MOrderInput(t(`prodMallPr`),
                                    (order.mallShipPrice)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                    "text", 6,
                                    null,
                                    (e) => orderMallpricericeNumberInput(index, 'mallShipPrice', e.target.value)
                                )}
                                {MOrderInput(t(`prodUrl`),
                                    order.url || '',
                                    "text", 2083,
                                    (ref) => {
                                        if (orderItemsRefs.current[index]) {
                                            orderItemsRefs.current[index].url = ref;
                                        }
                                    },
                                    (e) => handleOrderProductInput(index, 'url', e.target.value)
                                )}
                            </div>
                        </MOrderSection>
                    ))}
                </div>
            )}
            {!orderProductHandle[0] &&
                <div className={'m-a-order-button-empty'} onClick={addOrderProduct}>
                    {t(`modAddBt1`)}</div>
            }
            {/*오프라인*/}
            <div className='m-a-order-sub-title m-t-7' onClick={offlineAccordionFn}>
                <img className='m-a-order-sub-title-icon' src={detailOffline} alt=''/>
                <div className='m-a-order-sub-title-text'>{t(`modHeader3`)} </div>
                {offlineProductHandle.length > 0 && (
                    <i className={`accordion-icon m-a-order-sub-title-expand 
                ${offlineAccordion && offlineProductHandle.length > 0 && 'expanded'}`}/>
                )}
            </div>
            <div className='m-a-order-divider'/>
            {offlineAccordion && offlineProductHandle.length > 0 && (
                <div className={'m-a-order-section-frame'}>
                    <div className={'m-a-order-section-title'}>{t(`offlineTitle`)}</div>
                    {offlineProductHandle.map((offline, index) => (
                        <MOrderSection key={index} indexNm={index + 1}
                                       onClickAdd={addOfflineProduct}
                                       onClickClone={() => copyOfflineProduct(index)}
                                       onClickDelete={() => deleteOfflineProduct(index)}>
                            <div className={'m-a-order-section-frame-box'}>
                                {MOrderInput(t(`prodNm`), offline.name || '', "text",
                                    200,
                                    (ref) => {
                                        if (offlineItemsRefs.current[index]) {
                                            offlineItemsRefs.current[index].name = ref;
                                        }
                                    },
                                    (e) => handleOfflineProductInput(index, 'name', e.target.value)
                                )}
                                {MOrderInput(t(`prodQy`), offline.quantity || '', "text",
                                    5,
                                    (ref) => {
                                        if (offlineItemsRefs.current[index]) {
                                            offlineItemsRefs.current[index].quantity = ref;
                                        }
                                    },
                                    (e) => offlineQuantityNumberInput(index, 'quantity', e.target.value)
                                )}
                                {MOrderInput(t(`prodPr`),
                                    (offline.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '', "text",
                                    12,
                                    (ref) => {
                                        if (offlineItemsRefs.current[index]) {
                                            offlineItemsRefs.current[index].price = ref;
                                        }
                                    },
                                    (e) => offlinePriceNumberInput(index, 'price', e.target.value)
                                )}
                                {MOrderInput(t(`prodFee`),
                                    (offline.fee)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '', "text",
                                    6,
                                    (ref) => {
                                        if (offlineItemsRefs.current[index]) {
                                            offlineItemsRefs.current[index].fee = ref;
                                        }
                                    },
                                    (e) => offlineFeeNumberInput(index, 'fee', e.target.value),
                                    false, t(`modOffFeeTx`)
                                )}
                            </div>
                        </MOrderSection>
                    ))}
                </div>
            )}
            {!offlineProductHandle[0] &&
                <div className={'m-a-order-button-empty'} onClick={addOfflineProduct}>
                    {t(`modAddBt1`)}
                </div>
            }
            {/*이체대행*/}
            <div className='m-a-order-sub-title m-t-7' onClick={transferAccordionFn}>
                <img className='m-a-order-sub-title-icon' src={detailTransfer} alt=''/>
                <div className='m-a-order-sub-title-text'>{t(`modHeader4`)} </div>
                {transferProductHandle.length > 0 && (
                    <i className={`accordion-icon m-a-order-sub-title-expand 
                ${transferAccordion && transferProductHandle.length > 0 && 'expanded'}`}/>)}
            </div>
            <div className='m-a-order-divider'/>
            {transferAccordion && transferProductHandle.length > 0 && (
                <div className={'m-a-order-section-frame'}>
                    <div className={'m-a-order-section-title'}>{t(`transferTitle`)}</div>
                    {transferProductHandle.map((transfer, index) => (
                        <MOrderSection key={index} indexNm={index + 1}
                                       onClickAdd={addTransferProduct}
                                       onClickClone={() => copyTransferProduct(index)}
                                       onClickDelete={() => deleteTransferProduct(index)}>
                            <div className={'m-a-order-section-frame-box'}>
                                {MOrderInput(t(`prodAcct`), transfer.name || '', "text",
                                    200,
                                    (ref) => {
                                        if (transferItemsRefs.current[index]) {
                                            transferItemsRefs.current[index].name = ref;
                                        }
                                    },
                                    (e) => handleTransferProductInput(index, 'name', e.target.value)
                                )}
                            </div>
                            {MOrderInput(t(`prodTransPr`),
                                (transfer.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '', "text",
                                12,
                                (ref) => {
                                    if (transferItemsRefs.current[index]) {
                                        transferItemsRefs.current[index].price = ref;
                                    }
                                },
                                (e) => transferPriceNumberInput(index, 'price', e.target.value)
                            )}
                            {MOrderInput(t(`prodFee`) + '(7%)',
                                (transfer.fee)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '', "text",
                                12,
                                (ref) => {
                                    if (transferItemsRefs.current[index]) {
                                        transferItemsRefs.current[index].fee = ref;
                                    }
                                },
                                (e) => transferFeeNumberInput(index, 'fee', e.target.value)
                            )}
                        </MOrderSection>
                    ))}
                </div>
            )}
            {!transferProductHandle[0] &&
                <div className={'m-a-order-button-empty'} onClick={addTransferProduct}>
                    {t(`modAddBt1`)}
                </div>
            }
            {/*국내배송(구매대행) 폼*/}
            <div className='m-a-order-sub-title m-t-7' onClick={domesticAccordionFn}>
                <img className='m-a-order-sub-title-icon' src={detailDomestic} alt=''/>
                <div className='m-a-order-sub-title-text'>{t(`modHeader5`)} </div>
                {domesticProductHandle.length > 0 && (
                    <i className={`accordion-icon m-a-order-sub-title-expand 
                ${domesticAccordion && domesticProductHandle.length > 0 && 'expanded'}`}/>)}
            </div>
            <div className='m-a-order-divider'/>
            {domesticAccordion && domesticProductHandle.length > 0 && (
                <div className={'m-a-order-section-frame'}>
                    <div className={'m-a-order-section-title'}>{t(`domesticTitle`)}</div>
                    {domesticProductHandle.map((domestic, index) => (
                        <MOrderSection key={index} indexNm={index + 1}
                                       onClickAdd={addDomesticProduct}
                                       onClickClone={() => copyDomesticProduct(index)}
                                       onClickDelete={() => deleteDomesticProduct(index)}>
                            {MOrderInput(t(`prodNm`), domestic.name || '', "text",
                                200,
                                (ref) => {
                                    if (domesticItemsRefs.current[index]) {
                                        domesticItemsRefs.current[index].name = ref;
                                    }
                                },
                                (e) => handleDomesticProductInput(index, 'name', e.target.value)
                            )}
                            {MOrderInput(t(`prodOp`), domestic.option || '', "text",
                                50,
                                null,
                                (e) => handleDomesticProductInput(index, 'option', e.target.value)
                            )}
                            {MOrderInput(t(`prodQy`), domestic.quantity || '', "text",
                                5,
                                (ref) => {
                                    if (domesticItemsRefs.current[index]) {
                                        domesticItemsRefs.current[index].quantity = ref;
                                    }
                                },
                                (e) => domesticQuantityNumberInput(index, 'quantity', e.target.value)
                            )}
                            {MOrderInput(t(`prodPr`),
                                (domestic.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                "text", 12,
                                (ref) => {
                                    if (domesticItemsRefs.current[index]) {
                                        domesticItemsRefs.current[index].price = ref;
                                    }
                                },
                                (e) => domesticPriceNumberInput(index, 'price', e.target.value)
                            )}
                            {MOrderInput(t(`prodMallPr`),
                                (domestic.mallShipPrice)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                "text", 6,
                                null,
                                (e) => domesticMallpriceNumberInput(index, 'mallShipPrice', e.target.value)
                            )}
                            {MOrderInput(t(`prodFee`) + '(7%)',
                                (domestic.fee)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                "text", 12,
                                (ref) => {
                                    if (domesticItemsRefs.current[index]) {
                                        domesticItemsRefs.current[index].fee = ref;
                                    }
                                },
                                (e) => domesticFeeNumberInput(index, 'fee', e.target.value)
                            )}
                            {MOrderInput(t(`prodUrl`),
                                domestic.url || '',
                                "text", 2083,
                                (ref) => {
                                    if (domesticItemsRefs.current[index]) {
                                        domesticItemsRefs.current[index].url = ref;
                                    }
                                },
                                (e) => handleDomesticProductInput(index, 'url', e.target.value)
                            )}
                        </MOrderSection>
                    ))}
                </div>
            )}
            {!domesticProductHandle[0] &&
                <div className={'m-a-order-button-empty'} onClick={addDomesticProduct}>
                    {t(`modAddBt1`)}
                </div>
            }
            {/*배송대행 폼*/}
            <div className='m-a-order-sub-title m-t-7' onClick={deliveryAccordionFn}>
                <img className='m-a-order-sub-title-icon' src={detailDelivery} alt=''/>
                <div className='m-a-order-sub-title-text'>{t(`modHeader6`)} </div>
                {deliveryProductHandle.length > 0 && (
                    <i className={`accordion-icon m-a-order-sub-title-expand 
                ${deliveryAccordion && deliveryProductHandle.length > 0 && 'expanded'}`}/>)}
            </div>
            <div className='m-a-order-divider'/>
            {deliveryAccordion && deliveryProductHandle.length > 0 && (
                <div className="m-a-order-section-frame">
                    <div className="m-a-order-section-title">{t(`deliveryTitle`)}</div> {/*배송대행*/}
                    {deliveryProductHandle.map((delivery, index) => (
                        <MOrderSection key={index} indexNm={index + 1}
                                       onClickAdd={addDeliveryProduct}
                                       onClickClone={() => copyDeliveryProduct(index)}
                                       onClickDelete={() => deleteDeliveryProduct(index)}>
                            <div className={'m-a-order-delivery-option'}
                                 ref={(el) => domesticRefs.current[index] = el}
                                 onClick={() => toggleDomesticShow(index)}>
                                {delivery.domShipco === '' ? t(`dv`) : delivery.domShipco}
                                <img className={'m-a-order-expand-icon'} src={Expand} alt={''}/>
                                {domesticShow[index] ? (
                                    <div className={'m-a-order-expanded-list'}>
                                        {domesticDeliveryChoice.map((domestic, i) => (
                                            <div className={'m-a-order-expanded-item'}
                                                 value={domestic} key={i}
                                                 onClick={() => handleDomesticChange(index, domestic)}>
                                                {domestic}
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                            {MOrderInput(t(`prodTrNum`),
                                delivery.inWaynum || '',
                                "text", 22,
                                null,
                                (e) => deliveryWaynumNumberInput(index, 'inWaynum', e.target.value)
                            )}
                            {MOrderInput(t(`prodNm`),
                                delivery.name || '',
                                "text", 200,
                                (ref) => {
                                    if (deliveryItemsRefs.current[index]) {
                                        deliveryItemsRefs.current[index].name = ref;
                                    }
                                },
                                (e) => handleDeliveryProductInput(index, 'name', e.target.value)
                            )}
                            {MOrderInput(t(`prodOp`),
                                delivery.option || '',
                                "text", 200,
                                null,
                                (e) => handleDeliveryProductInput(index, 'option', e.target.value)
                            )}
                            {MOrderInput(t(`prodTtQy`),
                                delivery.quantity || '',
                                "text", 5,
                                (ref) => {
                                    if (deliveryItemsRefs.current[index]) {
                                        deliveryItemsRefs.current[index].quantity = ref;
                                    }
                                },
                                (e) => deliveryQuantityNumberInput(index, 'quantity', e.target.value)
                            )}
                        </MOrderSection>
                    ))}
                </div>
            )}
            {!deliveryProductHandle[0] &&
                <div className={'m-a-order-button-empty'} onClick={addDeliveryProduct}>
                    {t(`modAddBt1`)}
                </div>
            }
            {/*배송대행 인보이스*/}
            <div className='m-a-order-sub-title m-t-7' onClick={invoiceAccordionFn}>
                <img className='m-a-order-sub-title-icon' src={invoiceFormHeader} alt=''/>
                <div className='m-a-order-sub-title-text'>{t(`modHeader7`)} </div>
                <i className={`accordion-icon m-a-order-sub-title-expand 
                ${invoiceAccordion && 'expanded'}`}/>

            </div>
            <div className='m-a-order-divider'/>
            {invoiceAccordion && (
                <div className="m-a-order-section-invoice-attachment" title={t(`modIvAccd`)}>
                    <div className="m-a-order-section-title">{t(`modIvAccd`)}</div> {/*인보이스*/}
                    <div className="m-a-order-invoice-attachment">
                        <input className="m-a-order-invoice-attachment-file" value={fileName} onClick={() => document.getElementById('deliveryInvoice').click()} readOnly/>
                        <label className="m-a-order-invoice-attachment-button text-center">{t(`modFileBt`)}
                            <input type="file" onChange={photoChangeHandler} id="deliveryInvoice" className={'m-a-order-invoice-attachment-button-input'}/>
                        </label> {/*파일선택*/}
                    </div>
                </div>
            )}
            {/*요청사항*/}
            <div className='m-a-order-sub-title m-t-7' onClick={requestConfigAccordionFn}>
                <img className='m-a-order-sub-title-icon' src={reqFormHeader} alt=''/>
                <div className='m-a-order-sub-title-text'>{t(`modHeader8`)} </div>
                <i className={`accordion-icon m-a-order-sub-title-expand 
                ${requestConfigAccordion && 'expanded'}`}/>
            </div>
            <div className='m-a-order-divider'/>
            {requestConfigAccordion && (
                <div className={'m-a-order-section-frame'}>
                    <div className={'m-a-order-section-title'}>{t(`modSmallMenu12`)}</div>
                    <MOrderSection title={'요청사항양식'} topVisibility={false}>
                        <div className={'m-a-order-section-checklist-box'}>
                            <div className={'m-a-order-section-checklist'}>
                                <input id="tagRemove" type="checkbox" className={'m-a-order-section-checklist-icon wd-20 ht-20 m-t-1'}
                                       checked={tagRemove} onChange={tagRemoveCheck}/>
                                <div className={'m-a-order-section-checklist-text'}>{t(`modReqCont1`)}</div>
                            </div>
                            {tagRemove && <div className="m-a-order-error-message-box margin-left">
                                {t(`modReqWarn1`)}</div>}
                            <div className={'m-a-order-section-checklist'}>
                                <input id="shoesBoxRemove" type="checkbox"
                                       className={'m-a-order-section-checklist-icon wd-20 ht-20 m-t-1'}
                                       checked={shoesBoxRemove} onChange={shoesBoxRemoveCheck}/>
                                <div className={'m-a-order-section-checklist-text'}>{t(`modReqCont2`)}</div>
                            </div>
                            <div className={'m-a-order-section-checklist'}>
                                <input id="hangerRemove" type="checkbox"
                                       className={'m-a-order-section-checklist-icon wd-20 ht-20 m-t-1'}
                                       checked={hangerRemove} onChange={hangerRemoveCheck}/>
                                <div className={'m-a-order-section-checklist-text'}>{t(`modReqCont3`)}</div>
                            </div>
                            <div className={'m-a-order-section-checklist'}>
                                <input id="compressionPack" type="checkbox"
                                       className={'m-a-order-section-checklist-icon wd-20 ht-20 m-t-2'}
                                       checked={compressionPack} onChange={compressionCheck}/>
                                <div className={'m-a-order-section-checklist-text'}>{t(`modReqCont4`)}</div>
                            </div>
                            {compressionPack && <div className="m-a-order-error-message-box margin-left">
                                {t(`modReqWarn2`)}</div>}
                            <div className={'m-a-order-section-checklist'}>
                                <input id="productBoxRemove" type="checkbox"
                                       className={'m-a-order-section-checklist-icon wd-20 ht-20 m-t-2'}
                                       checked={productBoxRemove} onChange={prodBoxRemoveCheck}/>
                                <div className={'m-a-order-section-checklist-text'}>{t(`modReqCont5`)}</div>
                            </div>
                            {productBoxRemove && <div className="m-a-order-error-message-box margin-left">
                                {t(`modReqWarn3`)}</div>}
                            <div className={'m-a-order-section-checklist'}>
                                <input id="packingHold" type="checkbox"
                                       className={'m-a-order-section-checklist-icon wd-20 ht-20 m-t-2'}
                                       checked={packingHold} onChange={packingHoldCheck}/>
                                <div className={'m-a-order-section-checklist-text'}>{t(`modReqCont6`)}</div>
                            </div>
                            {packingHold && <div className="m-a-order-error-message-box margin-left">
                                {t(`modReqWarn4`)}</div>}
                            <div className={'m-a-order-section-checklist'}>
                                <input id="deliveryDepositPay" type="checkbox"
                                       className={'m-a-order-section-checklist-icon wd-20 ht-20 m-t-2'}
                                       checked={deliveryDepositPay} onChange={deliDepositPayCheck}/>
                                <div className={'m-a-order-section-checklist-text'}>{t(`modReqCont7`)}</div>
                            </div>
                            {deliveryDepositPay && <div className="m-a-order-error-message-box margin-left">
                                {t(`modReqWarn5`)}</div>}
                            <div className={'m-a-order-section-checklist'}>
                                <input id="deliveryMessengerGuide" type="checkbox"
                                       className={'m-a-order-section-checklist-icon wd-20 ht-20 m-t-2'}
                                       checked={deliveryMessengerGuide} onChange={deliMsgGuideCheck}/>
                                <div className={'m-a-order-section-checklist-text'}>{t(`modReqCont8`)}</div>
                            </div>
                            {deliveryMessengerGuide && <div className="m-a-order-error-message-box margin-left">
                                {t(`modReqCont9`)}</div>}
                            {MOrderInput(t(`modSmallMenu13`),
                                detailRequest,
                                "text", 300,
                                null,
                                detailRequestInput
                            )}
                        </div>
                    </MOrderSection>
                </div>
            )}
            {/*결제정보*/}
            <div className='m-a-order-sub-title m-t-7'>
                <img className='m-a-order-sub-title-icon' src={payFormHeader} alt=''/>
                <div className='m-a-order-sub-title-text'>{t(`modHeader10`)}</div>
            </div>
            <div className={'m-a-order-divider'}/>
            <div className={'m-a-order-section-frame'}>
                <div className={'m-a-order-section-title'}>{t(`modHeader10`)}</div>
                <MOrderSection topVisibility={false}>
                    <div className={'m-a-order-section-payment-title'}>{t(`modSHeader1`)}</div>
                    {MOrderPaymentRow(t(`modSHeader2`), `${totalQuantity}${t(`ea`)}`)}
                    {MOrderPaymentRow(t(`modSHeader0`),
                        `${totalPrices.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${t(`won`)}`)}
                    {MOrderPaymentRow(t(`modSHeader4`),
                        `${totalMallShipPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${t(`won`)}`)}
                    {MOrderPaymentRow(t(`modSHeader5`),
                        `${totalFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${t(`won`)}`)}

                    <div className={'m-a-order-section-payment-title m-t-15'}>{t(`modSHeader6`)}</div>
                    {MOrderPaymentRow(t(`modSHeader7`),
                        `${totalOverseasShippingExpense}${t(`won`)}`)}

                    <div className={'m-a-order-section-payment-title m-t-15'}>{t(`modSHeader8`)}</div>
                    {MOrderPaymentRow(t(`modSHeader9`),
                        `${totalEtcExpense}${t(`won`)}`)}

                    <div className={'m-a-order-section-payment-title m-t-15'}>{t(`modSHeader10`)}</div>
                    <div className={'m-a-order-section-payment-discount'}>
                        <div className={'m-a-order-section-payment-discount-label'}>
                            {t(`modSHeader11`)}(
                            {memberInfo[0] && memberInfo[0].point > 0 ?
                                <div className={'m-a-order-section highlight'}>
                                    {dbPoint ? dbPoint.toLocaleString('en-US') : 0}</div>
                                : <div className={'m-a-order-section'}>0</div>
                            })
                        </div>
                        <div className={'m-a-order-section-payment-discount-right'}>
                            <input className={'m-a-order-section-payment-discount-input'} type="text"
                                   value={pointSale.toLocaleString('en-US')} onChange={(e)=> handlePointSale(e.target.value)}/>
                            <button className={'m-a-order-section-payment-discount-button'}
                                 onClick={allPointSale}>{t(`modDpUseBt`)}
                            </button> {/*전액사용*/}
                        </div>
                    </div>
                    <div className={'m-a-order-section-payment-discount'}>
                        <div className={'m-a-order-section-payment-discount-label'}>
                            {t(`modSHeader12`)}(
                            {memberInfo[0] && memberInfo[0].deposit > 0 ?
                                <div className={'m-a-order-section highlight'}>
                                    {dbDeposit ? dbDeposit.toLocaleString('en-US') : 0}</div>
                                : <div className={'m-a-order-section'}>0</div>
                            })
                        </div>
                        <div className={'m-a-order-section-payment-discount-right'}>
                            <input className={'m-a-order-section-payment-discount-input'} type="text"
                                   value={depositSale.toLocaleString('en-US')} onChange={(e)=> handleDepositSale(e.target.value)}/>
                            <button className={'m-a-order-section-payment-discount-button'} onClick={allDepositSale}>{t(`modDpUseBt`)}</button>
                        </div>
                    </div>
                    <div className={'m-a-order-section-payment-discount'}>
                        <div className={'m-a-order-section-payment-discount-label'}>
                            {t(`modSHeader13`)}(
                            {dbCoupon && dbCoupon > 0 ?
                                <div className={'m-a-order-section highlight'}>
                                    {dbCoupon}</div>
                                : <div className={'m-a-order-section'}>
                                    0</div>
                            })
                        </div>
                        <div className={'m-a-order-section-payment-discount-right'}>
                            <input className={'m-a-order-section-payment-discount-input'} value={couponSale.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                   onChange={(e)=> handleDepositSale(e.target.value)}/>
                            {couponSale === '' ?
                                <button className={'m-a-order-section-payment-discount-button'} onClick={showUseCoupon}>{t(`modCUseBt`)}</button>
                                :
                                <button className={'m-a-order-section-payment-discount-button'} onClick={closeUseCoupon}>{t(`st3`)}</button>
                            }
                        </div>
                    </div>
                    <div className={'m-a-order-divider'}/>
                    <div className={'m-a-order-section-payment-title'}>
                        {t(`modFinalPay`)}
                        <div className={'m-a-order-section-payment-title-right'}>
                            <div className={'m-a-order-section-payment-title-highlight'}>{finalPayExpense}</div>
                            {t(`won`)}
                        </div>
                    </div>
                    {isOrderPay ? (
                        <>
                            <div className={'m-a-order-section-payment-title'}>{t(`modPty`)}</div> {/*결제수단*/}
                            <div className={'m-a-order-section-payment-method-grid'}>
                                <div className={`m-a-order-section-payment-method ${payWay === "PM06" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM06")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "90%", height: "70%"}} src={btoragePay} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-section-payment-method-text'}>BtoragePay</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-section-payment-method ${payWay === "PM07" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM07")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "90%", height: "70%"}} src={creditCard} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-section-payment-method-text'}>CreditCard</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-section-payment-method ${payWay === "PM08" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM08")}>
                                    <div className="ht-70pc user-center" style={{paddingTop:"10px"}}>
                                        <img style={{width: "80%", height: "60%"}} src={stripe} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-section-payment-method-text'}>Stripe</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-section-payment-method ${payWay === "PM01" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM01")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "90%", height: "70%"}} src={PaypalLogo} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-section-payment-method-text'}>Paypal</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-section-payment-method ${payWay === "PM02" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM02")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "90%", height: "70%"}} src={WechatpayLogo} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-section-payment-method-text'}>WechatPay</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-section-payment-method ${payWay === "PM03" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM03")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "100%", height: "60%"}} src={AlipayLogo} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-section-payment-method-text'}>Alipay</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-section-payment-method ${payWay === "PM00" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM00")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "100%", height: "70%"}} src={NoBank} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-section-payment-method-text'}>{t(`modNoBank`)}</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-section-payment-method ${payWay === "PM05" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM05")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "90%", height: "60%"}} src={DepositPay} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-section-payment-method-text'}>{t(`modDep`)}</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-section-payment-method ${payWay === "PM04" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM04")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "90%", height: "60%"}} src={PointPay} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-section-payment-method-text'}>{t(`modPt`)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'m-a-order-divider'}/>
                            {payWay === "PM06" ?
                                <div className={'m-a-order-payment-detail-box'}>
                                    <div className={'m-a-order-payment-detail-box-row'}>
                                        <div className={'m-a-order-payment-detail-box-left'}>{t(`modPpExr`)}</div>
                                        <div className={'m-a-order-payment-detail-box-right'}>
                                            {lastForPrice}
                                            <div ref={dollarRef} onClick={toggleDollarShow}
                                                 className={'m-a-order-payment-detail-box-dropdown'}>
                                                {currency}
                                                {dollarShow && (
                                                    <div className={'m-a-order-payment-detail-dropdown-list'}>
                                                        {dollarList.map((dollar, index) => (
                                                            <div key={index} className={'m-a-order-payment-detail-dropdown-item'}
                                                                 onClick={() => selectChangeDollar(dollar)}>
                                                                {dollar}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : payWay === "PM07" ?
                                    <div className={'m-a-order-payment-detail-box'}>
                                        <div className={'m-a-order-payment-detail-box-row'}>
                                            <div className={'m-a-order-payment-detail-box-left'}>{t(`modPpExr`)}</div>
                                            <div className={'m-a-order-payment-detail-box-right'}>
                                                {lastForPrice}
                                                <div ref={dollarRef} onClick={toggleDollarShow}
                                                     className={'m-a-order-payment-detail-box-dropdown'}>
                                                    {currency}
                                                    {dollarShow && (
                                                        <div className={'m-a-order-payment-detail-dropdown-list'}>
                                                            {dollarList.map((dollar, index) => (
                                                                <div key={index} className={'m-a-order-payment-detail-dropdown-item'}
                                                                     onClick={() => selectChangeDollar(dollar)}>
                                                                    {dollar}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : payWay === "PM08" ?
                                        <div className={'m-a-order-payment-detail-box'}>
                                            <div className={'m-a-order-payment-detail-box-row'}>
                                                <div className={'m-a-order-payment-detail-box-left'}>{t(`modPpExr`)}</div>
                                                <div className={'m-a-order-payment-detail-box-right'}>
                                                    {lastForPrice}
                                                    <div ref={dollarRef} onClick={toggleDollarShow}
                                                         className={'m-a-order-payment-detail-box-dropdown'}>
                                                        {currency}
                                                        {dollarShow && (
                                                            <div className={'m-a-order-payment-detail-dropdown-list'}>
                                                                {dollarList.map((dollar, index) => (
                                                                    <div key={index} className={'m-a-order-payment-detail-dropdown-item'}
                                                                         onClick={() => selectChangeDollar(dollar)}>
                                                                        {dollar}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : payWay === "PM01" ?
                                            <div className={'m-a-order-payment-detail-box'}>
                                                <div className={'m-a-order-payment-detail-box-row'}>
                                                    <div className={'m-a-order-payment-detail-box-left'}>{t(`modPpExr`)}</div>
                                                    <div className={'m-a-order-payment-detail-box-right'}>
                                                        {lastForPrice}
                                                        <div ref={dollarRef} onClick={toggleDollarShow}
                                                             className={'m-a-order-payment-detail-box-dropdown'}>
                                                            {currency}
                                                            {dollarShow && (
                                                                <div className={'m-a-order-payment-detail-dropdown-list'}>
                                                                    {dollarList.map((dollar, index) => (
                                                                        <div key={index}
                                                                            className={'m-a-order-payment-detail-dropdown-item'}
                                                                            onClick={() => selectChangeDollar(dollar)}>
                                                                            {dollar}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : payWay === "PM03" ?
                                                <div className={'m-a-order-payment-detail-box'}>
                                                    <div className={'m-a-order-payment-detail-box-row'}>
                                                        <div className={'m-a-order-payment-detail-box-left'}>{t(`modExr`)}</div>
                                                        <div className={'m-a-order-payment-detail-box-right'}>
                                                            {lastForPrice}
                                                            <div className={'m-a-order-payment-detail-box-dropdown no-border'}>
                                                                {currency}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'m-a-order-divider'}/>
                                                    <div className={'m-a-order-payment-detail-box-bottom'}>
                                                        <div className={'m-a-order-payment-detail-box-desc'}>
                                                            - {t(`modAliAcct`)} : 13165072023
                                                        </div>
                                                        <div className={'m-a-order-payment-detail-box-desc'}>
                                                            - {t(`modAcctNm`)} : IMEUNWOO
                                                        </div>
                                                        <img className={'m-a-order-payment-detail-box-img'}
                                                             src={AliQrCode} alt={''}/>
                                                    </div>
                                                </div>
                                                : payWay === "PM02" ?
                                                    <div className={'m-a-order-payment-detail-box'}>
                                                        <div className={'m-a-order-payment-detail-box-row'}>
                                                            <div className={'m-a-order-payment-detail-box-left'}>{t(`modExr`)}</div>
                                                            <div className={'m-a-order-payment-detail-box-right'}>
                                                                {lastForPrice}
                                                                <div className={'m-a-order-payment-detail-box-dropdown no-border'}>
                                                                    {currency}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={'m-a-order-divider'}/>
                                                        <div className={'m-a-order-payment-detail-box-bottom'}>
                                                            <div className={'m-a-order-payment-detail-box-desc'}>
                                                                Weixin ID : lfamily-help
                                                            </div>
                                                            <div className={'m-a-order-payment-detail-box-desc'}>
                                                                - {t(`modAcctNm`)} : IMEUNWOO
                                                            </div>
                                                            <img className={'m-a-order-payment-detail-box-img'}
                                                                 src={wechatQrCode} alt={''}/>
                                                        </div>
                                                    </div>
                                                    : payWay === "PM00" ?
                                                        <div className={'m-a-order-payment-detail-box'}>
                                                            <div className={'m-a-order-payment-detail-box-bottom'}>
                                                                <div className={'m-a-order-payment-detail-box-desc'}>
                                                                    <div className={'bold'}>● {t(`modNbInfo1`)} </div>
                                                                    - {t(`modAcctInfo`)}<br/>
                                                                    <div className={'bold'}>● {t(`welcome`)}</div>
                                                                    - {t(`modAcctNm`)} : Btorage corp<br/>
                                                                    - {t(`modNbInfo4`)} ShinhanBank 140-014-047039<br/>
                                                                    - {t(`modNbInfo5`)} : 82-10-3338-9354<br/>
                                                                    - {t(`modNbInfo6`)} : help@btorage.com<br/>
                                                                    - {t(`modNbInfo7`)} : 1F, 32-15, Yeongok-gil
                                                                    42beon-gil, Gonjiam-eup, Gwangju-si, Gyeonggi-do
                                                                    <br/>
                                                                    - {t(`modNbInfo8`)} : 12722<br/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                            }
                            {payMethodError &&
                                <div className={'m-a-order-error-message-box'}> {t(`modPiErr1`)}</div>}
                            {pointPayError &&
                                <div className={'m-a-order-error-message-box'}> {t(`modPiErr2`)}</div>}
                            {depositSelectErr &&
                                <div className={'m-a-order-error-message-box'}> {t(`depositErr`)}</div>}
                            {pointSelectErr &&
                                <div className={'m-a-order-error-message-box'}> {t(`pointErr`)}</div>}
                            {etcPayError &&
                                <div className={'m-a-order-error-message-box'}> {t(`modPiErr3`)}</div>}
                            {discountError &&
                                <div className={'m-a-order-error-message-box'}> {t(`finalPriceErr`)}</div>}
                        </>
                    ) : null}
                    {
                        cartProductHandle.some(cart => cart.name && cart.name.length > 0) ||
                        orderProductHandle.some(order => order.name && order.name.length > 0) ||
                        offlineProductHandle.some(offline => offline.name && offline.name.length > 0) ||
                        transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0) ||
                        domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0) ||
                        deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0) ?
                            (
                                <div className={'m-a-order-agreement-box'}>
                                    <div className={'m-a-order-agreement-row-left'}>
                                        <input id="AllAgreeCheckBox" type="checkbox"
                                               style={{width: "18px", height: "18px", cursor: "pointer"}}
                                               checked={allAgreeCheck} onChange={allAgreeHandle}/>
                                        <div className={'m-a-order-agreement-text strong'}>{t(`joinAgAll`)}</div> {/*전체동의*/}
                                    </div>
                                    <div className={'m-a-order-divider'}/>
                                    {cartProductHandle.some(cart => cart.name && cart.name.length > 0) ?
                                        (
                                            <div className={'m-a-order-agreement-row'}>
                                                <div className={'m-a-order-agreement-row-left'}>
                                                    <input id="cartOrderAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={cartServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setCartServiceAgreeCheck)}/>
                                                    <div className={'m-a-order-agreement-text'}>{t(`modPiAgCart`)}
                                                        <div className={'m-a-order-agreement-text primary'}>
                                                            ({t(`essential`)})
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className={'m-a-order-agreement-row-right'} src={termsView}
                                                     onClick={() => setCartTermsPopup(true)} alt={''}/>
                                            </div>
                                        ) : null}
                                    {orderProductHandle.some(order => order.name && order.name.length > 0) ?
                                        (
                                            <div className={'m-a-order-agreement-row'}>
                                                <div className={'m-a-order-agreement-row-left'}>
                                                    <input id="orderUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={orderServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setOrderServiceAgreeCheck)}/>
                                                    <div className={'m-a-order-agreement-text'}>{t(`modPiAgOrder`)}
                                                        <div className={'m-a-order-agreement-text primary'}>
                                                            ({t(`essential`)})
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className={'m-a-order-agreement-row-right'} src={termsView}
                                                     onClick={() => setOrderTermsPopup(true)} alt={''}/>
                                            </div>
                                        ) : null}
                                    {offlineProductHandle.some(offline => offline.name && offline.name.length > 0) ?
                                        (
                                            <div className={'m-a-order-agreement-row'}>
                                                <div className={'m-a-order-agreement-row-left'}>
                                                    <input id="offlineUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={offlineServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setOfflineServiceAgreeCheck)}/>
                                                    <div className={'m-a-order-agreement-text'}>{t(`modPiAgOff`)}
                                                        <div className={'m-a-order-agreement-text primary'}>
                                                            ({t(`essential`)})
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className={'m-a-order-agreement-row-right'} src={termsView}
                                                     onClick={() => setOfflineTermsPopup(true)} alt={''}/>
                                            </div>
                                        ) : null}
                                    {transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0) ?
                                        (
                                            <div className={'m-a-order-agreement-row'}>
                                                <div className={'m-a-order-agreement-row-left'}>
                                                    <input id="transferUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={transferServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setTransferServiceAgreeCheck)}/>
                                                    <div className={'m-a-order-agreement-text'}>{t(`modPiAgTr`)}
                                                        <div className={'m-a-order-agreement-text primary'}>
                                                            ({t(`essential`)})
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className={'m-a-order-agreement-row-right'} src={termsView}
                                                     onClick={() => setTransferTermsPopup(true)} alt={''}/>
                                            </div>
                                        ) : null}
                                    {domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0) ?
                                        (
                                            <div className={'m-a-order-agreement-row'}>
                                                <div className={'m-a-order-agreement-row-left'}>
                                                    <input id="domesticUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={domesticServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setDomesticServiceAgreeCheck)}/>
                                                    <div className={'m-a-order-agreement-text'}>{t(`modPiAgDm`)}
                                                        <div className={'m-a-order-agreement-text primary'}>
                                                            ({t(`essential`)})
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className={'m-a-order-agreement-row-right'} src={termsView}
                                                     onClick={() => setDomesticTermsPopup(true)} alt={''}/>
                                            </div>
                                        ) : null}
                                    {deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0) ?
                                        (
                                            <div className={'m-a-order-agreement-row'}>
                                                <div className={'m-a-order-agreement-row-left'}>
                                                    <input id="deliveryUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={deliveryServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setDeliveryServiceAgreeCheck)}/>
                                                    <div className={'m-a-order-agreement-text'}>{t(`modPiAgDv`)}
                                                        <div className={'m-a-order-agreement-text primary'}>
                                                            ({t(`essential`)})
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className={'m-a-order-agreement-row-right'} src={termsView}
                                                     onClick={() => setDeliveryTermsPopup(true)} alt={''}/>
                                            </div>
                                        ) : null}
                                </div>
                            ) : null
                    }
                    {agreeCheckBoxError && agreeErrorMsgCondition &&
                        <div className={'m-a-order-error-message-box'}> {t(`modPiErr4`)}</div>
                    }
                </MOrderSection>
            </div>
            {
                orderAddrBookSt === true ?
                    <MAddrBookPg addrBookCloseFn={addrBookCloseFn} orderAddrBookSt={orderAddrBookSt}
                                 addrChoiceData={addrChoiceData} selectedShip={selectedDeliveryChoiceOption}/>
                    :
                    useCouponSt ?
                        <CouponUse useCouponSt={useCouponSt} closeUseCoupon={closeUseCoupon} couponUseFn={couponUseFn}/>
                        :
                        paypalPopupSt ?
                            <MPaypalModal paypalPopupSt={paypalPopupSt} paypalPopupClose={paypalPopupClose}
                                         currency={currency}
                                         finalPayExpense={finalPayExpense} lastForPrice={lastForPrice}
                                         paypalOrderProcess={paypalOrderProcess}/>
                            :
                            cartTermsPopup ?
                                <MCommonTermsPopup cartTermsPopup={cartTermsPopup}
                                                  closeCartTermsPopup={closeCartTermsPopup}/>
                                :
                                orderTermsPopup ?
                                    <MCommonTermsPopup orderTermsPopup={orderTermsPopup}
                                                      closeOrderTermsPopup={closeOrderTermsPopup}/>
                                    :
                                    offlineTermsPopup ?
                                        <MCommonTermsPopup offlineTermsPopup={offlineTermsPopup}
                                                          closeOfflineTermsPopup={closeOfflineTermsPopup}/>
                                        :
                                        transferTermsPopup ?
                                            <MCommonTermsPopup transferTermsPopup={transferTermsPopup}
                                                              closeTransferTermsPopup={closeTransferTermsPopup}/>
                                            :
                                            domesticTermsPopup ?
                                                <MCommonTermsPopup domesticTermsPopup={domesticTermsPopup}
                                                                  closeDomesticTermsPopup={closeDomesticTermsPopup}/>
                                                :
                                                deliveryTermsPopup ?
                                                    <MCommonTermsPopup deliveryTermsPopup={deliveryTermsPopup}
                                                                      closeDeliveryTermsPopup={closeDeliveryTermsPopup}/>
                                                    :
                                                    biggerCpPriceSt ?
                                                        <MsgCheckModal biggerCpPriceSt={biggerCpPriceSt}
                                                                       closeBiggerCpPriceSt={closeBiggerCpPriceSt}/>
                                                        :
                                                        fileSizeOverSt ?
                                                            <MsgCheckModal fileSizeOverSt={fileSizeOverSt}
                                                                           closeFileSizeOverSt={closeFileSizeOverSt}/>
                                                            :
                                                            readyingPopupSt ?
                                                                <ReadyingPopup readyingPopupSt={readyingPopupSt} closeReadyingPopup={closeReadyingPopup}/>
                                                                :
                                                                formUploadErrSt ?
                                                                    <MsgCheckModal
                                                                        formUploadErrSt={formUploadErrSt}
                                                                        closeFormUploadErrSt={closeFormUploadErrSt}/>
                                                                    :
                                                            null
            }
        </div>
    )
}

export default withAuth(MOrderPg);
