import '../../css/mobile/MDepChargHistoryPg.css';
import MHeader from "../../components/mobile/MHeader";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import axios from "axios";
import commonHeader from "../../assets/img/commonHeader.png";
import commonClose from "../../assets/img/commonClose.png";
import MSelectPopup from "../../components/mobile/MSelectPopup";
import withAuth from "../../auth/UserRouteAuth";


const MDepChargHistoryPg = () => {
    const {t} = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [memberInfo, setMemberInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                setMemberInfo(response.data)
            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT || SSAT) {
            findMember();
        }
    }, [LCAT, SSAT])

    const [depHistoryList, setDepHistoryList] = useState([]);
    const selectedDepHistory = (() => {
        axios({
            method: 'get',
            url: '/member/selectedDepHistory',
            params: {
                id: memberInfo.id
            }
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setDepHistoryList(sortedData)
            })
            .catch(() => {
                console.log("예치금 신청내역 조회실패")
            })
    })

    useEffect(() => {
        if (memberInfo.id) {
            selectedDepHistory();
        }
    }, [memberInfo])

    const cancelDepHistoryMsg1 = t(`dchMdMsg1`);
    const cancelDepHistoryMsg2 = t(`dchMdMsg2`);

    const [cancelDepHistorySeqNum, setCancelDepHistorySeqNum] = useState(null);
    const [cancelMsgShow, setCancelMsgShow] = useState(false);
    const openCancelMsgShow = ((seqNum) => {
        setCancelDepHistorySeqNum(seqNum);
        setCancelMsgShow(true);
    })
    const closeCancelMsgShow = (() => {
        setCancelMsgShow(false);
    })

    const cancelDepHistory = ((seqNum) => {
        axios({
            method: 'get',
            url: '/member/cancelDepHistory',
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                setDepHistoryList((currentList) => {
                    return currentList.map(item => {
                        if (item.seqNum === seqNum) {
                            return response.data;
                        } else {
                            return item;
                        }
                    });
                });
                closeCancelMsgShow();
            })
            .catch(() => {
                console.log("예치금신청 취소실패")
            })
    })

    const formatDate = (dateStr) => {
        if (dateStr.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    return (
        <div className='m-a-dep-charg-history-pg-container'>
            <MHeader title={t(`dchHeader`)} url={"/UserDepChargPg"}/>
            <div className='m-a-dep-charg-history-sub-title'>
                <img className='m-a-dep-charg-history-sub-title-icon' src={commonHeader} alt=''/>
                <div className='m-a-dep-charg-history-sub-title-text'>{t(`dchHeader`)}</div>
            </div>
            <div className='m-a-dep-charg-history-divider'/>
            <div className='m-a-dep-charg-history-table-container'>
                <div className='m-a-dep-charg-history-table label'>
                    <div className='m-a-dep-charg-history-table-item sm'/>
                    <div className='m-a-dep-charg-history-table-item'>{t(`dchDate`)}</div>
                    <div className='m-a-dep-charg-history-table-item'>{t(`dchSmallMenu1`)}</div>
                    <div className='m-a-dep-charg-history-table-item lg'>{t(`dchSmallMenu2`)}</div>
                    <div className='m-a-dep-charg-history-table-item'>{t(`dchSmallMenu3`)}</div>
                    <div className='m-a-dep-charg-history-table-item sm'>{t(`dchSmallMenu4`)}</div>
                    <div className='m-a-dep-charg-history-table-item sm'>{t(`dchSmallMenu5`)}</div>
                    <div className='m-a-dep-charg-history-table-item sm'/>
                </div>
                {depHistoryList.map((dep, i) => (
                    <div className='m-a-dep-charg-history-table' key={i}>
                        <div className='m-a-dep-charg-history-table-item sm'> {i + 1}</div>
                        <div className='m-a-dep-charg-history-table-item'>{formatDate(dep.regDate)}</div>
                        <div className='m-a-dep-charg-history-table-item'>{dep.name}</div>
                        <div className='m-a-dep-charg-history-table-item lg'> {dep.account}</div>
                        <div className='m-a-dep-charg-history-table-item'>
                            {dep && dep?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{t(`dchPrice`)}
                            {dep.currency &&
                                <p>
                                    -{dep.dollarPrice}{dep.currency}
                                </p>
                            }
                        </div>
                        <div className='m-a-dep-charg-history-table-item sm'>
                            {dep.chargFl ? t(`st8`) : t(`st9`)}
                        </div>
                        <div className='m-a-dep-charg-history-table-item sm'>
                            {dep.status === '0' ? t(`st1`)
                                : dep.status === '1' ? t(`st2`)
                                    : t(`st3`)}
                        </div>
                        <div className='m-a-dep-charg-history-table-item sm'>
                            {dep.status === '0' && (
                                <div className='m-a-dep-charg-history-table-item-button'
                                     onClick={() => openCancelMsgShow(dep.seqNum)}>
                                    <img className='m-a-dep-charg-history-table-item-button-icon' src={commonClose}
                                         alt=''/>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {cancelMsgShow ?
                <MSelectPopup cancelMsgShow={cancelMsgShow} cancelDepHistoryMsg1={cancelDepHistoryMsg1}
                              cancelDepHistoryMsg2={cancelDepHistoryMsg2}
                              closeCancelMsgShow={closeCancelMsgShow} cancelDepHistory={cancelDepHistory}
                              cancelDepHistorySeqNum={cancelDepHistorySeqNum}/>
                :
                null
            }
        </div>
    )
}

export default withAuth(MDepChargHistoryPg);
