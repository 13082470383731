import React, {useEffect, useRef, useState} from "react";
import commonClose from "../../../assets/img/commonClose.png";
import {BiCaretDown} from "react-icons/bi";
import '../../../css/web/AdminCommonSetModal.css';

function AdminDelAddModal({ closeAddShow, saveData, PrimaryCode }) {

    const [shipName, setShipName] = useState('');
    const [shipCode, setShipCode] = useState(PrimaryCode);

    const [shipNameErrorMsg, setShipNameErrorMsg] = useState(false);
    const [shipTypeErrorMsg, setShipTypeErrorMsg] = useState(false);
    const [shipApiFlErrorMsg, setShipApiFlErrorMsg] = useState(false);

    const handleShipName = ((e) => {
        setShipName(e);
        if (e.length > 0) {
            setShipTypeErrorMsg(false);
        } else {
            setShipTypeErrorMsg(true);
        }
    })

    const [shipTypeShow, setShipTypeShow] = useState(false);
    const [shipTypeList, setShipTypeList] = useState(["해외", "국내"]);
    const [selectedShipType, setSelectedShipType] = useState('');
    const shipTypeRef = useRef(null);

    const toggleShipTypeShow = () => {
        setShipTypeShow(!shipTypeShow);
    };

    const handleShipTypeChange = (shipTy) => {
        setSelectedShipType(shipTy);
        setShipTypeShow(false);
        setShipTypeErrorMsg(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (shipTypeRef.current && !shipTypeRef.current.contains(event.target)) {
                setShipTypeShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [shipApiShow, setShipApiShow] = useState(false);
    const [shipApiList, setShipApiList] = useState(["연동", "미연동"]);
    const [selectedShipApi, setSelectedShipApi] = useState('');
    const shipApiRef = useRef(null);

    const toggleShipApiShow = () => {
        setShipApiShow(!shipApiShow);
    };

    const handleShipApiChange = (shipApiFl) => {
        setSelectedShipApi(shipApiFl);
        setShipApiShow(false);
        setShipApiFlErrorMsg(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (shipApiRef.current && !shipApiRef.current.contains(event.target)) {
                setShipApiShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const validation = (shipName, shipCode, selectedShipType, selectedShipApi) => {
        let isValid = true;

        if (!shipName || shipName.trim() === "") {
            setShipNameErrorMsg(true);
            isValid = false;
        } else {
            setShipNameErrorMsg(false);
        }

        if (!selectedShipType || selectedShipType.trim() === "" || selectedShipType === "작업구분선택") {
            setShipTypeErrorMsg(true);
            isValid = false;
        } else {
            setShipTypeErrorMsg(false);
        }

        if (!selectedShipApi || selectedShipApi.trim() === "" || selectedShipApi === "연동구분선택") {
            setShipApiFlErrorMsg(true);
            isValid = false;
        } else {
            setShipApiFlErrorMsg(false);
        }

        if (isValid) {
            saveData(shipName, shipCode, selectedShipType, selectedShipApi);
        }
    }

    return(
        <div className="a-common-set-modal font-t-cont c-def">
            <div className="a-c-s-m-frame">
                <div className="display-flex flex-column just-cont-sb ht-100pc">
                    <div className="ht-55 display-flex flex-row just-cont-sb align-center border-c-b">
                        <div></div>
                        <div className="m-l-45 p-t-4"><p className="font-t-title font-s-17">배송사 등록</p></div>
                        <div className="text-right m-r-7 m-t-1 c-point">
                            <img className="wd-32 ht-32 m-a-opc-07" onClick={closeAddShow} alt="closeImage" src={commonClose}/>
                        </div>
                    </div>
                    <div className="sm-f-body ht-100pc p-t-40 p-l-40 p-r-40">
                        <div className="display-flex flex-column gap20">
                            <input className="admin-input-line wd-100pc ht-45 p-l-7 font-s-14" placeholder="배송사명 입력" maxLength="20" value={shipName}
                                   onChange={(e)=> handleShipName(e.target.value)}
                                   onKeyDown={(e) => {if(e.key === 'Enter')
                                   {validation(selectedShipType, shipName);}}}
                            />
                            { shipNameErrorMsg &&
                                <div className="font-c-cancel font-s-13" style={{marginTop: "-17px"}}>배송사명을 입력해주세요.</div>
                            }
                            <input className="admin-input-line wd-100pc ht-45 p-l-7 font-s-14 font-b" value={`배송사코드: ${shipCode}`} disabled />
                            <div className="wd-100pc ht-45">
                                <div ref={shipTypeRef} className="wd-100pc ht-100pc position-relative">
                                    <button className="selectParentBt wd-100pc ht-100pc" onClick={toggleShipTypeShow}>
                                        <p>{selectedShipType || "작업구분선택"}</p>
                                        <span className="position-absolute ht-100pc wd-20pc" style={{left: "80%", top: "0"}}>
                                            <i className="display-flex just-cont-center align-center text-center ht-100pc"><BiCaretDown/></i>
                                        </span>
                                    </button>
                                    {shipTypeShow ?
                                        <div className="wd-100pc border-c m-t-2 position-absolute" style={{zIndex:1}}>
                                            {shipTypeList.map((ty, index) => (
                                                <div className="selectChildBt display-flex just-cont-center align-center c-point font-s-15 p-t-10 p-b-10"
                                                     key={index} value={ty} onClick={() => handleShipTypeChange(ty)}>
                                                    {ty}
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            { shipTypeErrorMsg &&
                                <div className="font-c-cancel font-s-13" style={{marginTop: "-17px"}}>배송작업구분을 선택하세요.</div>
                            }
                            <div className="wd-100pc ht-45">
                                <div ref={shipApiRef} className="wd-100pc ht-100pc position-relative">
                                    <button className="selectParentBt wd-100pc ht-100pc" onClick={toggleShipApiShow}>
                                        <p>{selectedShipApi || "연동구분선택"}</p>
                                        <span className="position-absolute ht-100pc wd-20pc" style={{left: "80%", top: "0"}}>
                                            <i className="display-flex just-cont-center align-center text-center ht-100pc"><BiCaretDown/></i>
                                        </span>
                                    </button>
                                    {shipApiShow ?
                                        <div className="wd-100pc border-c m-t-2 position-absolute" style={{zIndex:1}}>
                                            {shipApiList.map((apiFl, index) => (
                                                <div className="selectChildBt display-flex just-cont-center align-center c-point font-s-15 p-t-10 p-b-10"
                                                     key={index} value={apiFl} onClick={() => handleShipApiChange(apiFl)}>
                                                    {apiFl}
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            { shipApiFlErrorMsg &&
                                <div className="font-c-cancel font-s-13" style={{marginTop: "-17px"}}>연동여부를 선택하세요.</div>
                            }
                        </div>
                    </div>
                    <div className="wd-100pc ht-80 text-center align-center">
                        <button className="admin-new-btn-color wd-100 ht-40 font-s-15 m-t-10" onClick={()=> validation(shipName, shipCode, selectedShipType, selectedShipApi)}>저장</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDelAddModal
