import React, {useEffect, useState} from 'react';
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";
import {MdKeyboardArrowDown} from "react-icons/md";
import axios from "axios";
import maCateImg from "../../assets/img/m-a-cate.png";
import ApexCharts from "react-apexcharts";

function AdminUserStatusPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion */

    /** region Function */

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (data.length > displayLimit && displayLimit < 30) {
            setDisplayLimit(prevLimit => prevLimit + perPg);
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        // setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (id) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxChange = (e) => {
        let visibleData = data.slice(0, displayLimit);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setChecked(visibleIds);
        } else {
            setChecked([]);
        }
    };

    //endregion

    const [chartData, setChartData] = useState({ series: [], options: {} });

    useEffect(() => {
        const selectOutsalesMember = async () => {
            try {
                const today = new Date();
                const oneYearAgo = new Date(today);
                oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

                const formatDateString = (date) => date.toISOString().split('T')[0];
                const formattedStartDate = formatDateString(oneYearAgo);
                const formattedEndDate = formatDateString(today);

                const response = await axios.get('/outsales/selectOutsalesMember', {
                    params: { startDate: formattedStartDate.replace(/-/g, ''), endDate: formattedEndDate.replace(/-/g, '') }
                });

                const processedData = response.data.map(item => ({
                    ...item,
                    // totalAmount: item.cartPrice + item.ordPrice + item.offPrice + item.trPrice + item.domPrice +
                    //     item.ttEtcFee + item.ttEtcPrice + item.ttEmsPrice + item.ttFedexPrice + item.ttSeaPrice +
                    //     item.ttDomPrice + item.useDeposit + item.usePoint + item.useCoupon + item.finalPrice
                    totalAmount: item.finalPrice
                }));

                const sortedData = processedData.sort((a, b) => b.totalAmount - a.totalAmount);
                const top10 = sortedData.slice(0, 10);

                const seriesData = top10.map(item => item.totalAmount);
                const categories = top10.map(item => item.memberDTO.userName);

                setData(top10);
                setChartData({
                    series: [{ name: '총 금액', data: seriesData }],
                    options: {
                        chart: { type: 'bar' },
                        plotOptions: {
                            bar: { borderRadius: 0, horizontal: true, barHeight: '50%' }
                        },
                        dataLabels: {
                            enabled: true,
                            formatter: (val, opt) => {
                                return `${opt.w.globals.labels[opt.dataPointIndex]}:  ${val.toLocaleString()}`;
                            }
                        },
                        title: { text: 'TOP 10', align: 'middle' },
                        tooltip: {
                            enabled: true,
                            theme: 'dark',
                            y: {
                                formatter: (val) => val.toLocaleString()
                            }
                        },
                        xaxis: {
                            categories: categories,
                            labels: {
                                formatter: (val) => val.toLocaleString()
                            }
                        },
                        colors: ['#ff9345'],
                        legend: { show: false },
                    }
                });
            } catch (error) {
                console.log('데이터 조회 실패:', error);
            }
        };

        selectOutsalesMember();
    }, []);

    /** endregion */

    /** region Event */

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {
        setStartDate(e.target.value);
    };
    const endHandle = (e) => {
        setEndDate(e.target.value);
    };

    useEffect(() => {
        const today = new Date();
        // const oneMonthAgo = new Date(today);
        // oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        const oneYearAgo = new Date(today);
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

        const formatDateString = (date) => {
            return date.toISOString().split('T')[0];
        };
        setStartDate(formatDateString(oneYearAgo));
        setEndDate(formatDateString(today));
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            selectOutsalesMember();
        }
    }, [startDate, endDate])

    const selectOutsalesMember = (() => {
        const formattedStartDate = startDate.replace(/-/g, '');
        const formattedEndDate = endDate.replace(/-/g, '');

        axios({
            method: 'get',
            url: '/outsales/selectOutsalesMember',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.finalPrice - a.finalPrice);
                setData(sortedData);
            })
            .catch((error) => {
                console.log("고객별 매출데이터 조회실패 : ", error)
            })
    })

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    const excelDown = () => {
        try {
            const selectedRows = data.filter((item) => checked.includes(item.seqNum));
            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function mapDataToExcelRow(item, index) {
                return {
                    '번호': index + 1,
                    '고객명': item.memberDTO.regNm,
                    '장바구니': item.cartPrice,
                    '오프라인': item.offPrice,
                    '이체대행': item.trPrice,
                    '국내(구매)': item.domPrice,
                    '수수료': item.ttEtcPrice,
                    '기타': item.ttEtcFee,
                    'EMS': item.ttEmsPrice,
                    'FedEx': item.ttFedexPrice,
                    'UPS': 0,
                    '선편': item.ttSeaPrice,
                    'Small-Packet': 0,
                    'K-Pack': 0,
                    '국내': item.ttDomPrice,
                    '예치금': item.useDeposit,
                    '적립금': item.usePoint,
                    '쿠폰': item.useCoupon,
                    '전체(예적쿠제외)': item.finalPrice
                };
            }

            const sheetConfigs = {
                '고객별매출현황': {
                    name: '고객별매출현황',
                    columns: [
                        {header: '번호', key: '번호', hidden: false, width: 6, style: { alignment: { horizontal: 'center' }}},
                        {header: '고객명', key: '고객명', width: 22, style: { alignment: { horizontal: 'center' }}},
                        {header: '장바구니', key: '장바구니', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: '오프라인', key: '오프라인', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: '이체대행', key: '이체대행', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: '국내(구매)', key: '국내(구매)', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: '수수료', key: '수수료', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: '기타', key: '기타', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: 'FedEx', key: 'FedEx', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: 'UPS', key: 'UPS', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: 'EMS', key: 'EMS', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: '선편', key: '선편', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: '항공소형', key: 'Small-Packet', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: 'K-Packet', key: 'K-Pack', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: '국내', key: '국내', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: '예치금', key: '예치금', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: '적립금', key: '적립금', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: '쿠폰', key: '쿠폰', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: '전체(예적쿠제외)', key: '전체(예적쿠제외)', width: 15, style: { alignment: { horizontal: 'center' }}},
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });
            };

            const applyStylesToHeader = (sheet, height) => {
                const invisibleRow = sheet.getRow();
                invisibleRow.height = 1;

                const headerRow = sheet.getRow(1);
                headerRow.font = { name: '맑은 고딕', bold: true, size: 10 };
                headerRow.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                headerRow.height = height;

                headerRow.eachCell(() => {});
                applyBordersToRow(headerRow, borderHeaderStyle);
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                applyStylesToHeader(sheet, 23);
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item) => {
                    const row = sheet.addRow(item);
                    for (let i = 1; i <= sheet.columns.length; i++) {
                        row.getCell(i).font = { name: '맑은 고딕', size: 10 };
                    }
                    applyBordersToRow(row, border);
                });
            };

            let transformedSelectedRows;
            transformedSelectedRows = selectedRows.map(mapDataToExcelRow);

            if (sheetConfigs) {
                const sheet = createSheet(sheetConfigs['고객별매출현황'].name, sheetConfigs['고객별매출현황'].columns);
                applyStylesToHeader(sheet, 23);
                addRowsToSheet(sheet, transformedSelectedRows, borderHeaderStyle);
            }

            let fileName = `고객별매출현황_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error(error);
        }
    };

    // endregion

    /** endregion */

    return (
        <div className="AdminUserStatusPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">고객별이용현황</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card m-b-40">
                    <div className="wd-100pc ht-auto ht-350">
                        <ApexCharts options={chartData.options} series={chartData.series} type="bar" width={"100%"} height={"100%"}/>
                    </div>
                    <div className="text-center p-t-5"><p className="font-b font-s-12" style={{color: "#bebebe"}}>(단위:&#8361;)</p></div>
                </div>
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                            <AdminYmdEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                            <AdminSearchEvent/>
                        </div>
                        <div className="admin-event-wrap-btn gap10">
                            <button onClick={excelDown} className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event">EXCEL</button>
                        </div>
                    </div>
                    <div className="m-b-10"><p className="font-s-12">(단위:만원)</p></div>
                    <div className="div-tb m-b-10">
                        <div className="div-tb-head ht-60">
                            <div style={{width: "40px"}}>
                                <input className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === data.length} onChange={allcheckBoxChange}/>
                            </div>
                            <div style={{width: "140px"}}><p>고객명</p></div>
                            <div style={{width: "100px"}}><p>장바구니</p></div>
                            <div style={{width: "100px"}}><p>구매대행</p></div>
                            <div style={{width: "100px"}}><p>오프라인</p></div>
                            <div style={{width: "100px"}}><p>이체대행</p></div>
                            <div style={{width: "100px"}}><p>국내(구매)</p></div>
                            <div style={{width: "100px"}}><p>수수료</p></div>
                            <div style={{width: "100px"}}><p>기타</p></div>
                            <div style={{width: "100px"}}><p>FedEx</p></div>
                            <div style={{width: "100px"}}><p>UPS</p></div>
                            <div style={{width: "100px"}}><p>EMS</p></div>
                            <div style={{width: "100px"}}><p>Kpack</p></div>
                            <div style={{width: "100px"}}><p>항공소형</p></div>
                            <div style={{width: "100px"}}><p>선편</p></div>
                            <div style={{width: "100px"}}><p>국내</p></div>
                            <div style={{width: "100px"}}><p>예치금</p></div>
                            <div style={{width: "100px"}}><p>적립금</p></div>
                            <div style={{width: "100px"}}><p>쿠폰</p></div>
                            <div style={{width: "120px"}}>
                                <p>전체</p>
                                <p>(예적쿠제외)</p>
                            </div>
                        </div>
                        {data.slice(0, displayLimit).map((cData, i) => (
                            <div key={i} className="div-tb-body ht-70">
                                <div style={{width: "40px"}}>
                                    <input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.seqNum} checked={checked.includes(cData.seqNum)}
                                           onChange={() => checkBoxChange(cData.seqNum)}/>
                                </div>
                                <div style={{width: "140px"}}><p className="font-b" style={{fontSize: "13px"}}>{cData.memberDTO.userName}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.cartPrice / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.ordPrice / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.offPrice / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.trPrice / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.domPrice / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.ttEtcFee / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.ttEtcPrice / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.ttFedexPrice / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.ttUpsPrice / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.ttEmsPrice / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.ttKPackPrice / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.ttAirSPrice / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.ttSeaPrice / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.ttDomPrice / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.useDeposit / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.usePoint / 10000).toFixed(1)}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{(cData.useCoupon / 10000).toFixed(1)}</p></div>
                                <div style={{width: "120px"}}><p className="font-b">{(cData.finalPrice / 10000).toFixed(1)}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AdminUserStatusPg
