import React from "react";
import commonClose from "../../../assets/img/commonClose.png";

function SaveMsgModal({message}) {
    return(
        <div style={{position:"fixed", zIndex:9, top:"0", left:"0", bottom:"0", right:"0", background:"rgba(70,70,70,0.25)"}}>
            <div className="wd-140 ht-65 border-rd8 position-absolute align-center font-c-fff" style={{top: "calc(25vh - -15%)", left: "calc(50vw - 4%)", backgroundColor:"#707070",}}>
                <div className="user-center font-c-fff ht-100pc">
                    <p className="font-t-cont font-s-18" style={{color: "#e3e3e3"}}>{message}</p>
                </div>
            </div>
        </div>
    )
}

export default SaveMsgModal
