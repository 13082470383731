import React, {useEffect, useRef, useState} from "react";
import commonClose from "../../../assets/img/commonClose.png";
import {BiCaretDown} from "react-icons/bi";

function AdminPaypalExAdd({ paypalExAddShow, closePaypalExAddShow, saveData }) {

    const [payExr, setPayExr] = useState('');
    const [payRate, setPayRate] = useState('');

    const [curPriceErrorMsg, setCurPriceErrorMsg] = useState(false);
    const [payExrErrorMsg, setPayExrErrorMsg] = useState(false);
    const [payRateErrorMsg, setPayRateErrorMsg] = useState(false);

    const handlePayRate = ((e) => {
        setPayRate(e);
        if (e.length > 0) {
            setPayRateErrorMsg(false);
        } else {
            setPayRateErrorMsg(true);
        }
    })

    const handlePayExr = ((e) => {
        setPayExr(e);
        if (e.length > 0) {
            setPayExrErrorMsg(false);
        } else {
            setPayExrErrorMsg(true);
        }
    })


    // 여기서부터 커스텀 셀렉트
    const [curPriceShow, setCurPriceShow] = useState(false);
    const [curPriceList, setCurPriceList] = useState(["USD", "CAD", "AUD", "JPY", "EUR", "SGD", "CNY", "NZD"]);
    const [selectedCurPrice, setSelectedCurPrice] = useState('');
    const curPriceRef = useRef(null);

    const toggleCurPriceShow = () => {
        setCurPriceShow(!curPriceShow);
    };

    const handleCurPriceChange = (curPrice) => {
        setSelectedCurPrice(curPrice);
        setCurPriceShow(false);
        setCurPriceErrorMsg(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (curPriceRef.current && !curPriceRef.current.contains(event.target)) {
                setCurPriceShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const validation = (selectedCurPrice, payExr, payRate) => {
        let isValid = true;

        if (!selectedCurPrice || selectedCurPrice.trim() === "" || selectedCurPrice === "통화선택") {
            setCurPriceErrorMsg(true);
            isValid = false;
        } else {
            setCurPriceErrorMsg(false);
        }

        if (!payExr || payExr.trim() === "") {
            setPayExrErrorMsg(true);
            isValid = false;
        } else {
            setPayExrErrorMsg(false);
        }

        if (!payRate || payRate.trim() === "") {
            setPayRateErrorMsg(true);
            isValid = false;
        } else {
            setPayRateErrorMsg(false);
        }

        if (isValid) {
            saveData(selectedCurPrice, payExr, payRate);
        }
    }



    return(
        <div className="a-common-set-modal font-t-cont c-def">
            <div className="s-c-s-m-frame">
                <div className="display-flex flex-column just-cont-sb ht-100pc">
                    <div className="ht-55 display-flex flex-row just-cont-sb align-center border-c-b m-b-40">
                        <div></div>
                        <div className="m-l-45 p-t-4"><p className="font-t-title font-s-17">환율 등록</p></div>
                        <div className="text-right m-r-7 m-t-1 c-point">
                            <img className="wd-32 ht-32 m-a-opc-07" onClick={closePaypalExAddShow} alt="closeImage" src={commonClose}/>
                        </div>
                    </div>
                    <div className="sm-f-body ht-100pc p-l-40 p-r-40">
                        <div className="display-flex flex-column gap20">
                            <div className="wd-100pc ht-45">
                                <div ref={curPriceRef} className="wd-100pc ht-100pc position-relative">
                                    <button className="selectParentBt wd-100pc ht-100pc" onClick={toggleCurPriceShow}>
                                        <p>{selectedCurPrice || "통화"}</p>
                                        <span className="position-absolute ht-100pc wd-20pc" style={{left: "80%", top: "0"}}>
                                            <i className="display-flex just-cont-center align-center text-center ht-100pc"><BiCaretDown/></i>
                                        </span>
                                    </button>
                                    {curPriceShow ?
                                        <div className="wd-100pc border-c m-t-2 position-absolute" style={{zIndex:1}}>
                                            {curPriceList.map((cur, index) => (
                                                <div className="selectChildBt display-flex just-cont-center align-center c-point font-s-15 p-t-10 p-b-10"
                                                     key={index} value={cur} onClick={() => handleCurPriceChange(cur)}>
                                                    {cur}
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            { curPriceErrorMsg &&
                                <div className="font-c-cancel font-s-13" style={{marginTop: "-17px"}}>통화를 선택해주세요</div>
                            }
                            <input className="admin-input-line wd-100pc ht-45 p-l-7 font-s-14" placeholder="환율" maxLength="8" value={payExr}
                                   onChange={(e)=> handlePayExr(e.target.value)} onKeyDown={(e) => {if(e.key === 'Enter') {
                                saveData();}}}
                            />
                            { payExrErrorMsg &&
                                <div className="font-c-cancel font-s-13" style={{marginTop: "-17px"}}>환율을 입력해주세요</div>
                            }
                            <input className="admin-input-line wd-100pc ht-45 p-l-7 font-s-14" placeholder="요율(%)" maxLength="6" value={payRate}
                                   onChange={(e)=> handlePayRate(e.target.value)}
                                   onKeyDown={(e) => {if(e.key === 'Enter')
                                   {validation(selectedCurPrice, payExr, payRate);}}}
                            />
                            { payRateErrorMsg &&
                                <div className="font-c-cancel font-s-13" style={{marginTop: "-17px"}}>요율을 입력해주세요</div>
                            }
                        </div>
                    </div>
                    <div className="wd-100pc ht-80 text-center align-center">
                        <button className="admin-new-btn-color wd-100 ht-45 font-s-15" onClick={()=> validation(selectedCurPrice, payExr, payRate)}>저장</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminPaypalExAdd
