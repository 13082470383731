import React, {useCallback, useEffect, useRef, useState} from 'react';
import axios from "axios";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";
import {BsCaretDown, BsCaretUp} from "react-icons/bs";
import {MdKeyboardArrowDown} from "react-icons/md";
import commonClose from "../../assets/img/commonClose.png";
import commonCheck from "../../assets/img/commonCheck.png";
import {connect} from "../../services/WebsocketService";
import IntegDetailPopup from "../../components/web/modal/IntegDetailPopup";
import maCateImg from "../../assets/img/m-a-cate.png";
import {useWebSocket} from "../../services/WebsocketContext";

function AdminPaymentsListPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion*/

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('');
    const [userNm, setUserNm] = useState('');

    const [adminInfo, setAdminInfo] = useState([]);
    const selectAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data[0])
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        selectAdminInfo();
    }, [])

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!adminInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(adminInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [adminInfo.mbNum, isConnected, stompClient]);

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (data.length > displayLimit && displayLimit < 30) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        // setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region Alert

    const [alertShow, setAlertShow] = useState(false);
    const [alertShow2, setAlertShow2] = useState(false);
    const msgConfirmData1 = "확인 하시겠습니까?";
    const msgConfirmData2 = "결제승인이 완료됩니다.";
    const msgConfirmData3 = "정말 취소하시겠습니까?";
    const msgConfirmData4 = "결제가 취소처리 됩니다.";
    const modalCancel = useCallback (() => {setAlertShow(false);})
    const modalCancel2 = useCallback(() => {setAlertShow2(false);})
    const alert = (()=> {setAlertShow(true)})
    const alert2 = (() => {setAlertShow2(true)})

    //endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (id) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxChange = (e) => {
        let visibleData = data.slice(0, displayLimit);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setChecked(visibleIds);
        } else {
            setChecked([]);
        }
    };

    //endregion

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchData = data.filter((cData) => {
        if (cData.orderdtDTO && cData.orderdtDTO.memberDTO && cData.orderdtDTO.memberDTO.userName) {
            return (
                cData.orderdtDTO.memberDTO.userName.toLowerCase().includes(searchInput.toLowerCase()) ||
                (cData.orderdtDTO.orderNumber && cData.orderdtDTO.orderNumber.toString().toLowerCase().includes(searchInput.toLowerCase()))
            );
        }
        return false;
    });


    //endregion

    //region Sort

    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

    let sortedData = searchData.map(item => {
        // Date 객체 생성을 위해 regTime과 modiTime 확인
        const regDate = item.regTime ? new Date(item.regTime) : new Date(0);
        const modiDate = item.modiTime ? new Date(item.modiTime) : new Date(0);

        return {
            ...item,
            mostRecentDate: new Date(Math.max(regDate, modiDate)) // 가장최신날짜
        };
    });

    // 조건에 따라 정렬
    sortedData.sort((a, b) => b.mostRecentDate - a.mostRecentDate);
    if (sortColumn) {
        sortedData.sort((a, b) => {
            const aValue = a.orderdtDTO.orderNumber;
            const bValue = b.orderdtDTO.orderNumber;

            if (aValue < bValue) {
                return sortOrder === "asc" ? -1 : 1;
            } else if (aValue > bValue) {
                return sortOrder === "asc" ? 1 : -1;
            } else {
                // // If the values are equal, use secondary sorting column
                // const secondarySortColumn = "payStatus"; // Replace with the actual data field name for the secondary column
                const cValue = a.orderdtDTO.memberDTO.userName;
                const dValue = b.orderdtDTO.memberDTO.userName;

                if (cValue < dValue) {
                    return sortOrder === "asc" ? -1 : 1;
                } else if (cValue > dValue) {
                    return sortOrder === "asc" ? 1 : -1;
                } else {
                    return 0;
                }
            }
        });
    }

    //endregion

    /** endregion Function */

    /** region Event */

    useEffect(() => {
        select();
    }, [])

    const select = () => {
        axios({
            method: 'get',
            url: '/order/selectPayinfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                const paySt = response.data.filter(cData => cData.payWay !== null && cData.payWay !== '');
                paySt.sort((a, b) => new Date(b.regTime) - new Date(a.regTime));

                if (response.data[0] && response.data[0].orderdtDTO && response.data[0].orderdtDTO.memberDTO) {
                    setUserNm(response.data[0].orderdtDTO.memberDTO.userName);
                }

                setData(paySt);
                setChecked([]);

            })
            .catch(() => {});
    };

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;
    const excelDown = () => {
        try {
            const selectedRows = searchData.filter((item) => checked.includes(item.seqNum));

            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function mapDataToExcelRow(item, index) {
                const paymentStatus = item.payStatus === 'PS1' ? '결제중' : item.payStatus === 'PS0' ? '결제대기' : '결제완료';
                const paymentType = item.payType === 'PT0' ? '상품' : item.payType === 'PT1' ? '배송료' : item.payType;
                const paymentMethod = {
                    'PM00': '무통장입금',
                    'PM01': '페이팔',
                    'PM02': '위챗페이',
                    'PM03': '알리페이',
                    'PM04': '포인트결제',
                    'PM05': '예치금결제'
                }[item.payWay] || item.payWay;

                return {
                    '번호': index + 1,
                    '이름': item.orderdtDTO.memberDTO.userName,
                    '주문번호': item.orderdtDTO.orderNumber,
                    '결제타입': paymentType,
                    '결제수단': paymentMethod,
                    '총금액': item.ttProdPrice,
                    '최종금액': item.lastPrice,
                    '예치금사용': item.depUsePrice,
                    '적립금사용': item.pointUsePrice,
                    '쿠폰사용': item.couponUsePrice,
                    '결제상태': paymentStatus,
                    '결제일시': item.regTime,
                };
            }

            const sheetConfigs = {
                '결제내역': {
                    name: '결제내역',
                    columns: [
                        {header: '번호', key: '번호', hidden: false, width: 6, style: { alignment: { horizontal: 'center' }}},
                        {header: '이름', key: '이름', width: 18, style: { alignment: { horizontal: 'center' }}},
                        {header: '주문번호', key: '주문번호', width: 23, style: { alignment: { horizontal: 'center' }}},
                        {header: '결제타입', key: '결제타입', width: 10, style: { alignment: { horizontal: 'center' }}},
                        {header: '결제수단', key: '결제수단', width: 15, style: { alignment: { horizontal: 'center' }}},
                        {header: '총금액(\\)', key: '총금액', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '최종금액(\\)', key: '최종금액', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '예치금사용', key: '예치금사용', width: 10, style: { alignment: { horizontal: 'center' }}},
                        {header: '적립금사용', key: '적립금사용', width: 10, style: { alignment: { horizontal: 'center' }}},
                        {header: '쿠폰사용', key: '쿠폰사용', width: 10, style: { alignment: { horizontal: 'center' }}},
                        {header: '결제상태', key: '결제상태', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '결제일시', key: '결제일시', width: 18, style: { alignment: { horizontal: 'center' }}},
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });
            };

            const applyStylesToHeader = (sheet, height) => {
                const invisibleRow = sheet.getRow();
                invisibleRow.height = 1;

                const headerRow = sheet.getRow(1);
                headerRow.font = { name: '맑은 고딕', bold: true, size: 10 };
                headerRow.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                headerRow.height = height;

                headerRow.eachCell(() => {});
                applyBordersToRow(headerRow, borderHeaderStyle);
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                applyStylesToHeader(sheet, 23);
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item) => {
                    const row = sheet.addRow(item);
                    for (let i = 1; i <= sheet.columns.length; i++) {
                        row.getCell(i).font = { name: '맑은 고딕', size: 10 };
                    }
                    applyBordersToRow(row, border);
                });
            };

            let transformedSelectedRows;
            transformedSelectedRows = selectedRows.map(mapDataToExcelRow);

            if (sheetConfigs) {
                const sheet = createSheet(sheetConfigs['결제내역'].name, sheetConfigs['결제내역'].columns);
                applyStylesToHeader(sheet, 23);
                addRowsToSheet(sheet, transformedSelectedRows, borderHeaderStyle);
            }

            let fileName = `결제내역_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error(error);
        }
    };

    // endregion

    const [confirmPaySt, setConfirmPaySt] = useState(false);
    const confirmPayMsg1 = "입금확인을 진행하시겠습니까?";
    const confirmPayMsg2 = "진행 시 결제완료로 처리됩니다";

    const [cancelPaySt, setCancelPaySt] = useState(false);
    const cancelPayMsg1 = "입금취소를 진행하시겠습니까?";
    const cancelPayMsg2 = "진행 시 결제대기로 처리됩니다";

    const [selectedSeqNum, setSelectedSeqNum] = useState(null);

    const openConfirmPaySt = ((seqNum) => {
        setSelectedSeqNum(seqNum);
        setConfirmPaySt(true);
    })
    const closeConfirmPaySt = (() => {
        setSelectedSeqNum(null);
        setConfirmPaySt(false);
    })

    const openCancelPaySt = ((seqNum) => {
        setSelectedSeqNum(seqNum);
        setCancelPaySt(true);
    })
    const closeCancelPaySt = (() => {
        setSelectedSeqNum(null);
        setCancelPaySt(false);
    })

    const payDataSucc = (seqNum) => {
        axios({
            method: 'post',
            url: '/order/adminpayconfirm',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum,
            }
        })
            .then(async (response) => {
                const mbNum = response.data
                await stompClient.send("/app/updateOrderList", {}, JSON.stringify({userMbNum: mbNum}));
                closeConfirmPaySt()
                select();
            })
            .catch((error) => {
                console.log("입금확인 실패 : ", error)
            });
    };

    const payDataCancel = (seqNum) => {
        axios({
            method: 'post',
            url: '/order/adminpaycancel',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then(async (response) => {
                const mbNum = response.data
                await stompClient.send("/app/updateOrderList", {}, JSON.stringify({userMbNum: mbNum}));
                closeCancelPaySt();
                select();
            })
            .catch((error) => {
                console.log("입금취소 실패 : ", error)
            });
    };

    const [integPayDetailPopupSt, setIntegPayDetailPopupSt] = useState(false);
    const [integPaySeqnum, setIntegPaySeqnum] = useState(null);
    const integPayDetail = ((seqnum) => {
        setIntegPaySeqnum(seqnum);
        setIntegPayDetailPopupSt(true)
    })
    const integDetailPopupClose = (() => {
        setIntegPayDetailPopupSt(false);
    })
    useEffect(() => {
        if (integPaySeqnum !== null) {
            setIntegPayDetailPopupSt(true);
        }
    }, [integPaySeqnum]);

    const convertToShipco = ((dvType, defalutShipco) => {
        if (dvType === "D00") {
            return "국내배송"
        } else if (dvType === "D01") {
            return "FedEx"
        } else if (dvType === "D02") {
            return "EMS"
        } else if (dvType === "D03") {
            return "SHIP"
        } else if (dvType === "D04") {
            return "DHL"
        } else if (dvType === "D11") {
            return "UPS"
        } else if (dvType === "D13") {
            return "K-Packet"
        } else if (dvType === "D14") {
            return "Small-Packet"
        } else {
            return defalutShipco;
        }
    })

    /** endregion Event */

    return (
        <div className="AdminPaymentsListPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">결제내역</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                            <div style={{marginRight: "15px"}}>
                                <select className="admin-combo wd-110 ht-30 p-l-7 font-s-14 font-t-cont" onChange={(e) => {
                                    // PS3&PS4를 선택했을 때의 로직을 여기에 추가
                                    if(e.target.value === "PS2&PS3&PS4") {
                                        // 예시: PS3과 PS4가 선택되었음을 처리
                                        setSelectedPaymentStatus(["PS2", "PS3", "PS4"]);
                                    } else {
                                        // 그 외의 경우는 기존처럼 단일 값을 처리
                                        setSelectedPaymentStatus(e.target.value);
                                    }
                                }}>
                                    <option value="">결제상태</option>
                                    <option value="PS1">결제중</option>
                                    <option value="PS2&PS3&PS4">결제완료</option>
                                    <option value="PS5">취소</option>
                                </select>
                            </div>
                            <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                        </div>
                        <div className="admin-event-wrap-btn">
                            <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={excelDown}>EXCEL</button>
                        </div>
                    </div>
                    <div className="div-tb m-b-10">
                        <div className="div-tb-head ht-60">
                            <div style={{width: "40px"}}>
                                <input className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === data.length}
                                       onChange={allcheckBoxChange}/>
                            </div>
                            <div style={{width: "40px"}}><p>번호</p></div>
                            <div style={{width: "150px"}}>
                                <p>이름</p>
                                {sortColumn === "orderdtDTO.memberDTO.userName" && sortOrder === "asc" && (
                                    <BsCaretUp className="dark-font c-point m-t-5" style={{ display: "inline-block", float: "right" }} />
                                )}
                                {sortColumn === "orderdtDTO.memberDTO.userName" && sortOrder === "desc" && (
                                    <BsCaretDown className="dark-font c-point m-t-5" style={{ display: "inline-block", float: "right" }} />
                                )}
                            </div>
                            <div style={{width: "200px"}}>
                                <p>주문번호</p>
                                {sortColumn === "orderdtDTO.orderNumber" && sortOrder === "asc" && (
                                    <BsCaretUp className="dark-font c-point m-t-5" style={{ display: "inline-block", float: "right" }} />
                                )}
                                {sortColumn === "orderdtDTO.orderNumber" && sortOrder === "desc" && (
                                    <BsCaretDown className="dark-font c-point m-t-5" style={{ display: "inline-block", float: "right" }} />
                                )}
                            </div>
                            <div style={{width: "100px"}}><p>결제타입</p></div>
                            <div style={{width: "100px"}}><p>결제수단</p></div>
                            <div style={{width: "140px"}}><p>총금액(&#8361;)</p></div>
                            <div style={{width: "140px"}}><p>최종금액(&#8361;)</p></div>
                            <div style={{width: "80px"}}><p>예치금사용</p></div>
                            <div style={{width: "80px"}}><p>적립금사용</p></div>
                            <div style={{width: "80px"}}><p>쿠폰사용</p></div>
                            <div style={{width: "80px"}}><p>결제상태</p></div>
                            <div style={{width: "140px"}}><p>등록 및 결제일시</p></div>
                            <div style={{width: "40px"}}></div>
                        </div>
                        {sortedData.filter((cData) => !cData.childFl && cData.payStatus !== "PS0" && selectedPaymentStatus === '' || (Array.isArray(selectedPaymentStatus) ?
                            selectedPaymentStatus.includes(cData.payStatus) : cData.payStatus === selectedPaymentStatus)).slice(0, displayLimit).map((cData, i) => (
                            <div key={i} className="div-tb-body ht-70">
                                <div style={{width: "40px"}}>
                                    <input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.seqNum} checked={checked.includes(cData.seqNum)}
                                           onChange={() => checkBoxChange(cData.seqNum)}/>
                                </div>
                                <div style={{width: "40px"}}><p>{i + 1}</p></div>
                                <div style={{width: "150px"}}><p>{cData.orderdtDTO.memberDTO.userName}</p></div>
                                <div style={{width: "200px"}}><p>{cData.orderdtDTO.orderNumber}</p></div>
                                <div className="user-center" style={{width: "100px"}}>
                                    <p>{cData.payType === "PT0" ? "상품" : cData.payType === "PT1" ? "배송료" : cData.payType === "PT2" ? "기타" : "통합"}</p>
                                    { cData.payType === "PT3" ?
                                    <button onClick={()=> integPayDetail(cData.seqNum)} className="m-t-2 admin-btn-B wd-50 ht-27 font-s-12 btn-click-event">내역</button> : null
                                    }
                                </div>
                                <div style={{width: "100px"}}>
                                    <p>{cData.payWay  === "PM00" ? "무통장입금" : cData.payWay === "PM01" ? "Paypal" : cData.payWay === "PM02" ? "Wechat" :
                                        cData.payWay === "PM03" ? "Alipay" : cData.payWay === "PM05" ? "예치금결제" : "적립금결제"}</p>
                                </div>
                                <div style={{width: "140px"}}>
                                    { cData.payType === "PT0" ?
                                        <p>{(cData?.ttProdExpense + cData?.ttEtcFee).toLocaleString()}</p>
                                        :
                                        cData.payType === "PT1" ?
                                            <p>
                                                {
                                                    convertToShipco(cData.dvType) === "FedEx" ? cData.ttFedexPrice.toLocaleString()
                                                        :
                                                        // convertToShipco(cData.dvType) === "DHL" ? cData.ttDhlPrice.toLocaleString()
                                                        //     :
                                                        convertToShipco(cData.dvType) === "EMS" ? cData.ttEmsPrice.toLocaleString()
                                                            :
                                                            convertToShipco(cData.dvType) === "UPS" ? cData.ttUpsPrice.toLocaleString()
                                                                :
                                                                convertToShipco(cData.dvType) === "K-Packet" ? cData.ttKPackPrice.toLocaleString()
                                                                    :
                                                                    convertToShipco(cData.dvType) === "Small-Packet" ? cData.ttAirSPrice.toLocaleString()
                                                                        :
                                                            convertToShipco(cData.dvType) === "SHIP" ? cData.ttSeaPrice.toLocaleString()
                                                                :
                                                                convertToShipco(cData.dvType) === "국내배송" ? cData.ttDomPrice.toLocaleString()
                                                                :
                                                            null
                                                }
                                            </p>
                                            :
                                            cData.payType === "PT2" ?
                                                <p>
                                                    {
                                                        cData.ttEtcFee > 0 ? cData.ttEtcFee.toLocaleString()
                                                            :
                                                            cData.ttEtcPrice > 0 ? cData.ttEtcPrice.toLocaleString()
                                                                :
                                                                null
                                                    }
                                                </p>
                                                :
                                                <p>{(cData?.lastPrice + cData?.depUsePrice + cData?.pointUsePrice + cData?.couponUsePrice).toLocaleString() || 0}</p>
                                    }
                                </div>
                                <div className="user-center" style={{width: "140px", display:"flex", alignItems:"center"}}>
                                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                    <p>{cData.lastPrice.toLocaleString() || 0}</p>
                                    {cData.lastForPrice > 0 ?
                                        <div className="user-center" style={{flexDirection:"row", marginLeft:"3px"}}>
                                            <p style={{fontSize:"11px", marginLeft:"3px"}}>-</p>
                                            <p style={{fontSize:"13px", color:"darkorange", marginLeft:"3px"}}>{cData.lastForPrice}</p>
                                            <p style={{fontSize:"11px", marginLeft:"3px"}}>{cData.forCurrency}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    </div>
                                </div>
                                <div style={{width: "80px"}}><p>{cData?.depUsePrice?.toLocaleString() || 0}</p></div>
                                <div style={{width: "80px"}}><p>{cData?.pointUsePrice?.toLocaleString() || 0}</p></div>
                                <div style={{width: "80px"}}><p>{cData?.couponUsePrice?.toLocaleString() || 0}</p></div>
                                <div style={{width: "80px"}}>
                                    <p style={{
                                        color: cData.payStatus === 'PS1' ? "#7C9EDC" : cData.payStatus === 'PS5' ? "#EB939D" : "#FFB378"
                                    }}>
                                        {cData.payStatus === 'PS1' ? "결제중" : cData.payStatus === 'PS5' ? "취소" : "결제완료"}
                                    </p>
                                </div>
                                <div className="wd-140">
                                    <p>
                                        {cData.modiTime && new Date(cData.modiTime) > new Date(cData.regTime)
                                            ? cData.modiTime
                                            : cData.regTime}
                                    </p>
                                </div>
                                <div className="user-center" style={{width: "40px"}}>
                                    {cData.payStatus === 'PS1' && (
                                        <img className="c-point" style={{ width: "25px", height: "25px", opacity: "0.6" }} alt="closeImage" src={commonCheck}
                                             onClick={() => openConfirmPaySt(cData.seqNum)}/>)}
                                    {(cData.payStatus === 'PS1' || cData.payStatus === 'PS2' ) && (
                                        <img className="c-point" style={{ width: "25px", height: "25px", opacity: "0.7" }} alt="closeImage" src={commonClose}
                                             onClick={() => openCancelPaySt(cData.seqNum)}/>)}
                                </div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={payDataSucc} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow2 === true ? <MsgSelectModal alertShow={alertShow2} eventHandle1={modalCancel2} eventHandle2={payDataCancel} msgData1={msgConfirmData3} msgData2={msgConfirmData4}/> : null}
            { confirmPaySt ?
            <MsgSelectModal confirmPaySt={confirmPaySt} confirmPayMsg1={confirmPayMsg1} confirmPayMsg2={confirmPayMsg2}
                            closeConfirmPaySt={closeConfirmPaySt} selectedSeqNum={selectedSeqNum} payDataSucc={payDataSucc}/>
                :
                cancelPaySt ?
                    <MsgSelectModal cancelPaySt={cancelPaySt} cancelPayMsg1={cancelPayMsg1} cancelPayMsg2={cancelPayMsg2}
                                    closeCancelPaySt={closeCancelPaySt} selectedSeqNum={selectedSeqNum} payDataCancel={payDataCancel}/>
                    :
                    integPayDetailPopupSt ?
                        <IntegDetailPopup integDetailPopupClose={integDetailPopupClose} integPaySeqnum={integPaySeqnum}/>
                        :
                    null
            }
        </div>
    );
}

export default AdminPaymentsListPg
