import React from 'react';
import {Route, Routes} from "react-router-dom";
import UserMailSendComplPg from "../web/UserMailSendComplPg";
import ErrorPathPg from "../web/ErrorPathPg";
import MSectionMain from "./MSectionMain";
import MUsageGuidePg from "../../components/mobile/MUsageGuidePg";
import MUserMailInquiryPg from "./MUserMailInquiryPg";
import MUserReviewPg from "./MUserReviewPg";
import MUserReviewDetailPg from "./MUserReviewDetailPg";
import MUserReviewWritePg from "./MUserReviewWritePg";
import MCustomInfoPg from "./MCustomInfoPg";
import MCompareShipPg from "./MCompareShipPg";
import MUserMyPg from "./MUserMyPg";
import MDepChargHistoryPg from "./MDepChargHistoryPg";
import MDepChargPg from "./MDepChargPg";
import MFaqDtPg from "./MFaqDtPg";
import MNoticeDtPg from "./MNoticeDtPg";
import MNonUserChatPg from "./MNonUserChatPg";
import MOrderPg from "./MOrderPg";
import MUserOrderDetailPg from "./MUserOrderDetailPg";
import MUserFaqPg from "./MUserFaqPg";
import MUserNoticePg from "./MUserNoticePg";
import MUserChatAlarmPg from "./MUserChatAlarmPg";
import {Helmet} from "react-helmet-async";

function MUserMainPg() {
    return (
        <>
            <Helmet>
                <meta name="color-scheme" content="light only"/>
                <meta name="supported_color-scheme" content="light"/>
            </Helmet>
            <div className="m-user">
                <div className="m-user-layout">
                    <div className="m-user-body m-b-50">
                        <Routes>
                            <Route path="/UserOrderPg" element={<MOrderPg/>}/> {/*장바구니 신*/}
                            <Route path="/UserMyPg" element={<MUserMyPg/>}/> {/*마이페이지*/}
                            <Route path="/UserMyOrderPg/:ordNum" element={<MUserOrderDetailPg/>}/> {/*마이 주문서페이지*/}
                            <Route path="/UserReviewPg" element={<MUserReviewPg/>}/> {/*이용후기*/}
                            <Route path="/UserReviewWritePg" element={<MUserReviewWritePg/>}/> {/*이용후기 작성페이지*/}
                            <Route path="/UserReviewDetailPg/:seqNum" element={<MUserReviewDetailPg/>}/> {/*이용후기 작성완료 페이지*/}
                            <Route path="/UserCompareShipPg" element={<MCompareShipPg/>}/> {/*배송료비교 페이지*/}
                            <Route path="/UserUsageGuidePg" element={<MUsageGuidePg/>}/> {/*이용가이드 페이지*/}
                            <Route path="/UserCustomInfoPg" element={<MCustomInfoPg/>}/> {/*국가별통관정보 페이지*/}
                            <Route path="/UserDepChargPg" element={<MDepChargPg/>}/> {/*국가별통관정보 페이지*/}
                            <Route path="/UserDepChargHistoryPg" element={<MDepChargHistoryPg/>}/> {/*국가별통관정보 페이지*/}
                            <Route path="/UserNoticePg" element={<MUserNoticePg/>}/> {/*공지사항 페이지*/}
                            <Route path="/UserNoticeDtPg/:seqNum" element={<MNoticeDtPg/>}/> {/*공지사항 상세페이지*/}
                            <Route path="/UserFaqPg" element={<MUserFaqPg/>}/> {/*FAQ 페이지*/}
                            <Route path="/UserFaqDtPg/:seqNum" element={<MFaqDtPg/>}/> {/*FAQ 상세페이지*/}
                            <Route path="/UserMailInquiryPg" element={<MUserMailInquiryPg/>}/> {/*메일문의 페이지*/}
                            <Route path="/UserMailSendComplPg" element={<UserMailSendComplPg/>}/> {/*메일문의 성공페이지*/}
                            <Route path={'/NonUserChatPg'} element={<MNonUserChatPg/>}/>
                            <Route path={'/UserChatAlarmPg'} element={<MUserChatAlarmPg/>}/>
                            <Route exact path="/" element={<MSectionMain/>}/> {/*Main*/}
                            <Route path="*" element={<ErrorPathPg/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MUserMainPg
