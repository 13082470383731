import {Stomp} from "@stomp/stompjs";
import SockJS from 'sockjs-client';

export const socketUrl = 'https://btorage.com/api/websocket';

export const createClient = () => {
    const socketFactory = () => new SockJS(socketUrl);
    return Stomp.over(socketFactory);
};

export const connect = (key, onConnected, onError = () => {}) => {
    const stompClient = createClient();
    stompClient.heartbeat.outgoing = 50000; // 50초마다 ping
    stompClient.heartbeat.incoming = 50000; // 50초마다 pong 수신

    stompClient.connect({ key }, () => {
        onConnected(stompClient);
    }, onError);

    return stompClient;
};
