import React from 'react';
import '../../css/web/AdminLoginPg.css';
import '../../css/Common.css';
import {FiUser} from 'react-icons/fi'
import {AiOutlineLock} from "react-icons/ai";
import {Link, useNavigate} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {useState} from "react"
import axios from "axios";
import MsgConfirmModal from "../../components/web/modal/MsgConfirmModal";
import useTheme from "../../components/web/layoutscommon/DarkModeStorage";
import {useDispatch} from "react-redux";
import { setUserInfo } from '../../store/UserSlice';
import imgLogo from "../../assets/img/btorage_only_img_logo.png";

function AdminLoginPg() {

    //region 다크모드

    const [theme] = useTheme();

    //endregion

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [loginErrorMsg, setLoginErrorMsg] = useState(false);
    const [lgWaitingSt, setLgWaitingSt] = useState(false);
    const lgRegiWaitingMsg = "가입승인이 완료되지 않은 계정입니다."
    const [isLoading, setIsLoading] = useState(false);

    const handleChange_email = (e) => {
        setId(e.target.value);
    }
    const handleChange_password = (e) => {
        setPassword(e.target.value);
    }

    const confirmModalCloseFn = (() => {
        setLgWaitingSt(false);
    })

    const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
            loginCheck().then();
        }
    };

    const loginCheck = async () => {
        setIsLoading(true);
        if (!id || !password) {
            setLoginErrorMsg(true);
            setIsLoading(false);
        }
        try {
            const joinVerResponse = await axios.get("/member/joinVerFl/" + id);
            if (joinVerResponse.data === "N") {
                setLgWaitingSt(true);
            } else if (joinVerResponse.data === "Y") {
                const loginResponse = await axios.post("/member/login", {
                    id: id,
                    password: password,
                }, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                const userInformation = {
                    ...loginResponse.data,
                    token: loginResponse.headers['authorization'],
                    id: loginResponse.data.id,
                    userType: loginResponse.data.userType,
                };

                localStorage.setItem('adminToken', loginResponse.headers.get('authorization'));
                localStorage.setItem('adminLastLoginTime', new Date().toISOString());

                dispatch(setUserInfo(userInformation));
                navigate("/btcorpad");
                setIsLoading(false);
            }
        } catch (error) {
            setLoginErrorMsg(true);
            setIsLoading(false);
        }
    };

    return (
        <div className="admin-login" data-theme={theme}>
            <div className="login-main font-t-cont">
                <div className="login-sub">
                    <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
                        <form className="login100-form validate-form">
                            <div className="login100-form-title c-def">
                                <img alt="mainLogo" style={{width:"28px", height:"45px", marginRight: "10px", marginBottom: "5px"}} src={imgLogo}/>
                                <strong className="font-s-33">Administrator Login</strong>
                            </div>
                            <div className="wrap-input100 validate-input m-b-23" data-validate="Username is reauired">
                                <input className="input100 user-input" type="text" name="username" placeholder="아이디를 입력하세요"
                                       onChange={handleChange_email} onKeyDown={handleOnKeyPress}/>
                                <span className="focus-input100">
                                    <i><FiUser className="ad-join-i-con"/></i>
                                </span>
                            </div>
                            <div className="wrap-input100 validate-input" data-validate="Password is required">
                                <input className="input100 user-input" type="password" name="pass" placeholder="비밀번호를 입력하세요"
                                       onChange={handleChange_password} onKeyDown={handleOnKeyPress}/>
                                <span className="focus-input100">
                                    <i><AiOutlineLock className="ad-join-i-con"/></i>
                                </span>
                            </div>
                            <div className="JoinErrorMsg" style={{height: "50px"}}>
                                {loginErrorMsg && <p className="JoinErrMsg">&nbsp;아이디 및 비밀번호를 확인해주세요</p>}
                            </div>
                            <div className="m-a-join-findPw-container">
                                <div onClick={()=> navigate("/btcorpad-join")} className="m-a-join-text font-t-cont text-active c-point">
                                    <p className="font-t-cont">가입하기</p>
                                </div>
                                <div className="m-a-vertical-solid"></div>
                                <div onClick={()=> navigate("/btcorpad-pwFind")} className="m-a-findPw-text font-t-cont text-active c-point">
                                    <p className="font-t-cont">비밀번호찾기</p>
                                </div>
                            </div>
                            <div className="login-sub-form-btn font-t-cont">
                                {theme === "light" ?
                                    // <div className="JoinButton acc-submit light wd-100pc">
                                    //     <style>
                                    //         {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                                    //     </style>
                                    //     <button variant="primary" id="accSubmitBt" className="border-rd4 font-c-fff" onClick={loginCheck}>
                                    //         {isLoading ? (
                                    //             <div className="user-center">
                                    //                 <div className="spinner"></div>
                                    //             </div>
                                    //         ) : (
                                    //             "Login"
                                    //         )}
                                    //     </button>
                                    // </div>
                                    // :
                                    // <div className="JoinButton acc-submit light wd-100pc">
                                    //     <style>
                                    //         {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                                    //     </style>
                                    //     <button id="accSubmitBt" className="border-rd4 font-c-fff" onClick={loginCheck}>
                                    //         {isLoading ? (
                                    //             <div className="user-center">
                                    //                 <div className="spinner"></div>
                                    //             </div>
                                    //         ) : (
                                    //             "Login"
                                    //         )}
                                    //     </button>
                                    // </div>
                                    <div className="JoinButton acc-submit light wd-100pc">
                                        <style>
                                            {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                                        </style>
                                            <Button variant="primary" id="accSubmitBt" onClick={handleOnKeyPress} className="border-rd4 font-c-fff">
                                                {isLoading ? (
                                                    <div className="user-center">
                                                        <div className="spinner"></div>
                                                    </div>
                                                ) : (
                                                    "Login"
                                                )}
                                            </Button>
                                    </div>
                                    :
                                    <div className="wrap-login100-form-btn dark">
                                        <style>
                                            {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                                        </style>
                                            <Button className="ad-login-btn border-rd4" onClick={handleOnKeyPress} >
                                                {isLoading ? (
                                                    <div className="user-center">
                                                        <div className="spinner"></div>
                                                    </div>
                                                ) : (
                                                    "Login"
                                                )}
                                            </Button>
                                    </div>
                                }
                            </div>
                            {/*<div className="join-reset">*/}
                            {/*    <div className="reset">*/}
                            {/*        <Link className="btorage-hover ad-join-bt" to={"/btcorpad-join"}>가입하기</Link>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </form>
                    </div>
                </div>
                {lgWaitingSt ? <MsgConfirmModal lgWaitingSt={lgWaitingSt} confirmModalCloseFn={confirmModalCloseFn} lgRegiWaitingMsg={lgRegiWaitingMsg}/> : null}
            </div>
        </div>
    );
}

export default AdminLoginPg
