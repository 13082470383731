import React, {useEffect, useState} from 'react'
import '../../../css/web/UserAddrBook.css';
import {GrClose} from "react-icons/gr";
import UserAddrBookModi from "./UserAddrBookModi";
import axios from "axios";
import MsgConfirmModal from "./MsgConfirmModal";
import MsgSelectModal from "./MsgSelectModal";
import UserOrderPg from "../../../page/web/UserOrderPg";
import {useTranslation} from "react-i18next";
import commonClose from '../../../assets/img/commonClose.png'

function UserAddrBook({addrBookCloseFn, orderAddrBookSt, addrChoiceData, selectedShip,
                          myordBookSt, myordBookFalseFn, deliAddrBookSt, deliveryDetailAddrSt, deliveryDetailAddrFalseFn}) {
    const { t } = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [data, setData] = useState([]);
    const [seqnumKeySt, setSeqnumKeySt] = useState(null);

    const [addrBookModifySt, setAddrBookModifySt] = useState(false);
    const [addrBookAddSt, setAddrBookAddSt] = useState(false);
    const [deleteSelectSt, setDeleteSelectSt] = useState(false);
    const [deleteConfirmSt, setDeleteConfirmSt] = useState(false);
    const [addrBookChoiceSt, setAddrBookChoiceSt] = useState(false);

    const addrBookModifyExcutionFn = (() => {
        setAddrBookModifySt(true);
    })

    const addrBookModifyBackFn = (() => {
        setAddrBookModifySt(false);
    })

    const addrBookModifyBackFn2 = (() => {
        // data 렌더링 해야 함
        setAddrBookAddSt(false);
    })

    const addrBookAddFn = (() => {
        setAddrBookAddSt(true);
    })

    const deleteBookExcutionFn = ((key) => {
        setDeleteSelectSt(true);
        setSeqnumKeySt(key)
    })

    const deleteBookCancelFn = (() => {
        setDeleteSelectSt(false);
    })

    const getBookListFn = (() => {
        axios({
            method: 'get',
            url: '/member/addrBookList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => {
                    return (b.defaultAddrFl === true) - (a.defaultAddrFl === true);
                });
                setData(sortedData);
            })
    })

    // 수정창일 때만 주소록 가져오기
    useEffect(() => {
        const timer = setTimeout(() => {
            if (addrBookModifySt === false && addrBookAddSt === false) {
                getBookListFn();
            }
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])


    const deleteAddrBook = ((seqnum) => {
        axios({
            method: 'post',
            url: '/member/addrBookDelete',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                seqnum: seqnum
            }
        })
            .then(() => {
                getBookListFn()
            })
    })

    const deleteHandleFn = () => {
        deleteAddrBook(seqnumKeySt); // axios로 해당 주소록 삭제
        setDeleteSelectSt(false);
        // 컨펌창 상태 true로 만들어서 뜨게 하기
        setDeleteConfirmSt(true);
        // getBookListFn(); // getList 업데이트
    }

    const deleteSelectMsg1 = t(`abMdMsg1`)
    const deleteSelectMsg2 = t(`abMdMsg2`)
    const deleteConfirmMsg = t(`abMdMsg3`)

    const deleteConfirmHandleFn = () => {
        getBookListFn();
        setDeleteConfirmSt(false);
    }

    const keyAndModify = (key) => {
        addrBookModifyExcutionFn();
        setSeqnumKeySt(key);
    }

    const selectedData = data.find((item) => item.seqnum === seqnumKeySt);

    return (
        <div className="bookContainer">
            <div className="bookFrame" >
                <div className="bookFrameHeader">
                    <div className="headerText font-t-title">{t(`abHeader`)}</div> {/*배송주소록*/}
                    {orderAddrBookSt ?
                        <div className="headerClose" onClick={addrBookCloseFn}>
                            <img style={{width:"30px", height:"30px", opacity:"0.6"}} src={commonClose} alt="close"/>
                        </div>
                        :
                        myordBookSt ?
                            <div className="headerClose" onClick={myordBookFalseFn}>
                                <img style={{width:"30px", height:"30px", opacity:"0.6"}} src={commonClose} alt="close"/>
                            </div>
                        :
                            deliAddrBookSt ?
                                <div className="headerClose" onClick={addrBookCloseFn}>
                                    <img style={{width:"30px", height:"30px", opacity:"0.6"}} src={commonClose} alt="close"/>
                                </div>
                                :
                                deliveryDetailAddrSt ?
                                    <div className="headerClose" onClick={deliveryDetailAddrFalseFn}>
                                        <img style={{width:"30px", height:"30px", opacity:"0.6"}} src={commonClose} alt="close"/>
                                    </div>
                                 :
                                null
                    }
                </div>
                <div className="bookFrameBody font-t-cont">
                    {data.map(book => (
                        <div className="bookContentOuterCard" key={book.seqnum}>
                            <div className="bookContentInnerCard m-b-15">
                                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                    <div className="display-flex cardName font-s-20 font-b">{book.recname}</div>
                                    {book.defaultAddrFl === true ?
                                        <p className="font-c-progress-b font-s-13 font-b c-def">{t(`ordDfAddr`)}</p> : null
                                    }
                                </div>
                                <div className="cardRecinfo m-t-10 m-b-10">
                                    <p className="font-s-16 font-b" style={{color: "#2a2a2a"}}>{book.countryDTO.countryNm}[{book.countryDTO.countryEngNm}]</p>
                                </div>
                                <div className="cardRecinfo font-s-16">{book.address1}</div>
                                {book.address2 ?
                                    <div className="cardRecinfo font-s-16">{book.address2}</div> : null
                                }
                                {book.address3 ?
                                    <div className="cardRecinfo font-s-16">{book.address3}</div> : null
                                }
                                {book.city ?
                                    <div className="cardRecinfo font-s-16">{book.city}</div> : null
                                }
                                <div className="cardZipAndTelnum m-t-10 m-b-10 font-s-16">
                                    {book.zipcode ?
                                        <div className="cardZip">{book.zipcode}</div> : null
                                    }
                                    <div className="cardTelnum">{book.telnum}</div>
                                </div>
                                { book.crCode === "ID" ?
                                    <div className="cardRecinfo font-s-16">Tax ID : {book.taxid}</div> : null
                                }
                                <div className="cardButtonFrame just-cont-sb">
                                    {/*{ !myordBookSt ?*/}
                                        <div>
                                            <button className="m-a-colorBt-B wd-70 ht-40 m-r-8" onClick={() => deleteBookExcutionFn(book.seqnum)}>{t(`st4`)}</button>
                                            <button className="m-a-colorBt-B wd-70 ht-40" onClick={() => keyAndModify(book.seqnum)}>{t(`modRegModiBt`)}</button> {/*수정*/}
                                        </div>
                                    {/*    :*/}
                                    {/*    null*/}
                                    {/*}*/}
                                    {/*{orderAddrBookSt || myordBookSt || deliveryDetailAddrSt ?*/}
                                        <button className="m-a-colorBt-B wd-70 ht-40" onClick={()=> addrChoiceData(book.seqnum)}>{t(`select`)}</button>
                                    {/*: null*/}
                                    {/*}*/}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="bookFrameFooter font-t-cont" style={{display: "flex", justifyContent: "center", alignItems:"center", height:"65px", padding: "15px"}}>
                    <button className="colorFullBt" style={{width: "100%", height: "45px", textAlign: "center", fontSize: "15px"}}
                            onClick={addrBookAddFn}>{t(`abAddBt`)}
                    </button> {/*배송지추가*/}
                </div>
            </div>
            {
                addrBookAddSt === true ?
                    <UserAddrBookModi addrBookModifyBackFn2={addrBookModifyBackFn2} addrBookAddSt={addrBookAddSt}
                                      getBookListFn={getBookListFn} selectedShip={selectedShip}/>
                    :
                    addrBookModifySt === true ?
                        <UserAddrBookModi addrBookModifyBackFn={addrBookModifyBackFn}
                                          addrBookModifySt={addrBookModifySt} book={selectedData}
                                          getBookListFn={getBookListFn} selectedShip={selectedShip}/>
                        :
                        deleteSelectSt === true ?
                            <MsgSelectModal deleteSelectSt={deleteSelectSt}
                                            deleteHandleFn={() => deleteHandleFn()}
                                            deleteBookCancelFn={deleteBookCancelFn}
                                            deleteSelectMsg1={deleteSelectMsg1}
                                            deleteSelectMsg2={deleteSelectMsg2}
                            />
                            :
                            deleteConfirmSt === true ?
                                <MsgConfirmModal deleteConfirmSt={deleteConfirmSt}
                                                 deleteConfirmMsg={deleteConfirmMsg}
                                                 deleteConfirmHandleFn={deleteConfirmHandleFn}/>
                                :
                                addrBookChoiceSt === true ?
                                    <UserOrderPg book={selectedData} addrBookChoiceSt={addrBookChoiceSt}/>
                                    :
                                    null
            }
        </div>
    )
}

export default UserAddrBook
