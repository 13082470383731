import React, {useEffect} from 'react';
import '../../../css/web/MsgSelectModal.css';
import alert from "../../../assets/img/alert.png"

function MsgSelectModal({
                            alertShow,
                            eventHandle1,
                            eventHandle2,
                            msgData1,
                            msgData2,
                            photoDeleteCancelFn,
                            photoDeleteExecution,
                            msgData7,
                            photoDeleteSelectSt,
                            deleteSelectSt,
                            deleteHandleFn,
                            deleteSelectMsg1,
                            deleteSelectMsg2,
                            deleteBookCancelFn,
                            deleteOrderModalShowSt,
                            deleteOrderFalseFn,
                            deleteOrder,
                            deleteOrderMsg1,
                            deleteOrderMsg2,
                            deletePaySt,
                            deletePayMsgFalseFn,
                            deletePayMsg,
                            deletePay,
                            chatDeleteSt,
                            chatDeleteFalseFn,
                            chatDeleteMsg1,
                            chatDeleteMsg2,
                            messageToModify,
                            deleteChat,
                            nonChatDeleteSt,
                            nonChatDeleteFalseFn,
                            nonMessageToModify,
                            deleteNonChat,
                            deliveryDetailPayMove,
                            setDeliveryDetailPayMove,
                            moveDeliveryPay,
                            deliveryDetailPayMsg1,
                            deliveryDetailPayMsg2,
                            integModalSt,
                            integModalMsg1,
                            integModalMsg2,
                            closeIntegModalShow,
                            orderMerge,
                            divideModalSt,
                            divideModalMsg1,
                            divideModalMsg2,
                            closeDivideModalShow,
                            orderDivide,
                            deleteCpShowSt,
                            deleteCpMsg1,
                            deleteCpMsg2,
                            deleteCoupon,
                            closeDeleteCpShow,
                            minusBatchCpShowSt,
                            minusBatchCpMsg1,
                            minusBatchCpMsg2,
                            closeMinusBatchCpShow,
                            saveBatchCp,
                            plusBatchCpShowSt,
                            plusBatchCpMsg1,
                            plusBatchCpMsg2,
                            closePlusBatchCpShow,
                            cancelMsgShow,
                            cancelDepHistoryMsg1,
                            cancelDepHistoryMsg2,
                            closeCancelMsgShow,
                            cancelDepHistory,
                            cancelDepHistorySeqNum,
                            deleteReviewShow,
                            deleteReviewShowMsg1,
                            deleteReviewShowMsg2,
                            closeDeleteReviewShow,
                            deleteReview,
                            deleteReviewCommentSeqNum,
                            deleteReviewCommentShow,
                            deleteReviewCommentShowMsg1,
                            deleteReviewCommentShowMsg2,
                            closeDeleteReviewCommentShow,
                            deleteReviewComment,
                            blockCommentShow,
                            blockCommentShowMsg1,
                            blockCommentShowMsg2,
                            closeBlockCommentShow,
                            blockComment,
                            deleteNoticeMsgShow,
                            deleteNoticeMsg1,
                            deleteNoticeMsg2,
                            closeDeleteNoticeMsgShow,
                            deleteNotice,
                            mstDeleteMsgShow,
                            mstDeleteMsg1,
                            mstDeleteMsg2,
                            closeMstDeleteMsg,
                            mstDeleteSeqNum,
                            deleteFaqMst,
                            mstDeleteContent,
                            deleteFaqDtMsgShow,
                            deleteFaqDtMsg1,
                            deleteFaqDtMsg2,
                            deleteFaqDt,
                            closeDeleteFaqDtMsgShow,
                            depConfirmComplShow,
                            depConfirmComplMsg1,
                            depConfirmComplMsg2,
                            closeDepConfirmCompl,
                            modifyDepConfirmCompl,
                            paypalExDeleteShow,
                            paypalExDeleteMsg1,
                            paypalExDeleteMsg2,
                            paypalExDeleteChecked,
                            closePaypalExDeleteShow,
                            deleteData,
                            allProdStChangeMsg1,
                            allProdStChangeMsg2,
                            modifyProdSt,
                            selectedItems,
                            selectedFormTy,
                            selectedCdataIndex,
                            allCartProdStChangeShow,
                            closeAllCartProdStChangeShow,
                            selectedAllCartProdSt,
                            allOrderProdStChangeShow,
                            closeAllOrderProdStChangeShow,
                            selectedAllOrderProdSt,
                            allOfflineProdStChangeShow,
                            closeAllOfflineProdStChangeShow,
                            selectedAllOfflineProdSt,
                            allTransferProdStChangeShow,
                            closeAllTransferProdStChangeShow,
                            selectedAllTransferProdSt,
                            allDomesticProdStChangeShow,
                            closeAllDomesticProdStChangeShow,
                            selectedAllDomesticProdSt,
                            allDeliveryProdStChangeShow,
                            closeAllDeliveryProdStChangeShow,
                            selectedAllDeliveryProdSt,
                            orderdtDepositPaySt,
                            closeOrderdtDepositPaySt,
                            orderdtDepositPayMsg1,
                            orderdtDepositPayMsg2,
                            orderdtAdminDpPay,
                            orderdtPointPaySt,
                            closeOrderdtPointPaySt,
                            orderdtPointPayMsg1,
                            orderdtPointPayMsg2,
                            payinfoDepositPaySt,
                            closePayinfoDepositPaySt,
                            payinfoDepositPayMsg1,
                            payinfoDepositPayMsg2,
                            payinfoAdminDpPay,
                            payinfoPointPaySt,
                            closePayinfoPointPaySt,
                            payinfoPointPayMsg1,
                            payinfoPointPayMsg2,
                            adminOsReturnSt,
                            closeOsReturnSt,
                            osReturnMsg1,
                            osReturnMsg2,
                            deliveryReturn,
                            adminDsReturnSt,
                            closeDsReturnSt,
                            dsReturnMsg1,
                            dsReturnMsg2,
                            confirmPaySt,
                            confirmPayMsg1,
                            confirmPayMsg2,
                            closeConfirmPaySt,
                            payDataSucc,
                            selectedSeqNum,
                            cancelPaySt,
                            cancelPayMsg1,
                            cancelPayMsg2,
                            closeCancelPaySt,
                            payDataCancel,
                            allUpdateProdSt,
                            allUpdateProdStMsg1,
                            allUpdateProdStMsg2,
                            closeAllUpdateProdSt,
                            updateProdArrival,
                            checkedOrd,
                            updateProdSt,
                            updateProdStMsg1,
                            updateProdStMsg2,
                            closeUpdateProdSt,
                            deleteBannerMsgShow,
                            deleteBannerMsg1,
                            deleteBannerMsg2,
                            deleteBanner,
                            closeDeleteBannerMsgShow,
                            deleteBannerSeqNum,
                            adminDeliveryCancelSt,
                            closeDeliveryCancelSt,
                            dvCancelMsg1,
                            dvCancelMsg2,
                            deliveryCancel
                        }) {


    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                event.preventDefault();
                eventHandle1();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    useEffect(() => {
        const handleEnterKey = (event) => {
            if (event.key === 'Enter') {
                if (alertShow) {
                    eventHandle2();
                } else if (photoDeleteSelectSt) {
                    photoDeleteExecution();
                } else if (deleteSelectSt) {
                    deleteHandleFn();
                } else if (deleteOrderModalShowSt) {
                    deleteOrder();
                } else if (deletePaySt) {
                    deletePay();
                } else if (chatDeleteSt) {
                    deleteChat(messageToModify);
                } else if (nonChatDeleteSt) {
                    deleteNonChat(nonMessageToModify);
                } else if (integModalSt) {
                    orderMerge();
                } else if (divideModalSt) {
                    orderDivide();
                } else if (deleteCpShowSt) {
                    deleteCoupon();
                } else if (minusBatchCpShowSt) {
                    saveBatchCp(false);
                } else if (plusBatchCpShowSt) {
                    saveBatchCp(true);
                } else if (cancelMsgShow) {
                    cancelDepHistory(cancelDepHistorySeqNum);
                } else if (deleteReviewShow) {
                    deleteReview();
                } else if (deleteReviewCommentShow) {
                    deleteReviewComment(deleteReviewCommentSeqNum);
                } else if (blockCommentShow) {
                    blockComment();
                } else if (deleteNoticeMsgShow) {
                    deleteNotice();
                } else if (mstDeleteMsgShow) {
                    deleteFaqMst(mstDeleteSeqNum, mstDeleteContent);
                } else if (deleteFaqDtMsgShow) {
                    deleteFaqDt();
                } else if (depConfirmComplShow) {
                    modifyDepConfirmCompl();
                } else if (paypalExDeleteShow) {
                    deleteData(paypalExDeleteChecked);
                } else if (allCartProdStChangeShow) {
                    handleAdminAllProdStChange();
                } else if (allOrderProdStChangeShow) {
                    handleAdminAllProdStChange();
                } else if (allOfflineProdStChangeShow) {
                    handleAdminAllProdStChange();
                } else if (allTransferProdStChangeShow) {
                    handleAdminAllProdStChange();
                } else if (allDomesticProdStChangeShow) {
                    handleAdminAllProdStChange();
                } else if (allDeliveryProdStChangeShow) {
                    handleAdminAllProdStChange();
                } else if (orderdtDepositPaySt) {
                    orderdtAdminDpPay("deposit");
                } else if (orderdtPointPaySt) {
                    orderdtAdminDpPay("point");
                } else if (payinfoDepositPaySt) {
                    payinfoAdminDpPay("deposit");
                } else if (payinfoPointPaySt) {
                    payinfoAdminDpPay("point");
                } else if (adminOsReturnSt) {
                    deliveryReturn("overseas");
                } else if (adminDsReturnSt) {
                    deliveryReturn("domestic");
                } else if (confirmPaySt) {
                    payDataSucc(selectedSeqNum);
                } else if (cancelPaySt) {
                    payDataCancel(selectedSeqNum);
                } else if (allUpdateProdSt) {
                    updateProdArrival(checkedOrd);
                } else if (updateProdSt) {
                    updateProdArrival(checkedOrd);
                } else if (deleteBannerMsgShow) {
                    deleteBanner(deleteBannerSeqNum)
                } else if (adminDeliveryCancelSt) {
                    deliveryCancel()
                }
            }
        };
        document.addEventListener('keydown', handleEnterKey);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('keydown', handleEnterKey);
        };
    }, []);


    const handleAdminAllProdStChange = () => {
        let selectedProdSt = null;
        if (selectedFormTy === 'cart') {
            selectedProdSt = selectedAllCartProdSt[selectedCdataIndex];
        } else if (selectedFormTy === 'order') {
            selectedProdSt = selectedAllOrderProdSt[selectedCdataIndex];
        } else if (selectedFormTy === 'offline') {
            selectedProdSt = selectedAllOfflineProdSt[selectedCdataIndex];
        } else if (selectedFormTy === 'transfer') {
            selectedProdSt = selectedAllTransferProdSt[selectedCdataIndex];
        } else if (selectedFormTy === 'domestic') {
            selectedProdSt = selectedAllDomesticProdSt[selectedCdataIndex];
        } else if (selectedFormTy === 'delivery') {
            selectedProdSt = selectedAllDeliveryProdSt[selectedCdataIndex];
        }
        const seqNums = selectedItems[selectedFormTy][selectedCdataIndex];
        if (seqNums && seqNums.length > 0) {
            modifyProdSt(selectedProdSt, seqNums);
            if (selectedFormTy === 'cart') {
                closeAllCartProdStChangeShow();
            } else if (selectedFormTy === 'order') {
                closeAllOrderProdStChangeShow();
            } else if (selectedFormTy === 'offline') {
                closeAllOfflineProdStChangeShow();
            } else if (selectedFormTy === 'transfer') {
                closeAllTransferProdStChangeShow();
            } else if (selectedFormTy === 'domestic') {
                closeAllDomesticProdStChangeShow();
            } else if (selectedFormTy === 'delivery') {
                closeAllDeliveryProdStChangeShow();
            }
        }
    };

    return (
        <div className="blurContainer font-t-cont">
            <div className="alertFrame">
                <div className="alertImgCon">
                    <div className="alertImgBox">
                        <img alt="" src={alert}/>
                    </div>
                </div>
                <div className="alertMessage" style={{textAlign: "center", height: "100px"}}>
                    {alertShow === true ?
                        <div>
                            <h5 className="c-def dark-font">{msgData1}</h5>
                            <span className="c-def dark-font">{msgData2}</span>
                        </div>
                        :
                        photoDeleteSelectSt ?
                            <h5 className="c-def dark-font">{msgData7}</h5>
                            :
                            deleteSelectSt ?
                                <div>
                                    <h5 className="c-def dark-font">{deleteSelectMsg1}</h5>
                                    <span className="c-def dark-font">{deleteSelectMsg2}</span>
                                </div>
                                :
                                deleteOrderModalShowSt ?
                                    <div>
                                        <h5 className="c-def dark-font">{deleteOrderMsg1}</h5>
                                        <span className="c-def dark-font">{deleteOrderMsg2}</span>
                                    </div>
                                    :
                                    deletePaySt ?
                                        <div>
                                            <h5 className="c-def dark-font">{deletePayMsg}</h5>
                                        </div>
                                        :
                                        chatDeleteSt ?
                                            <div>
                                                <h5 className="c-def dark-font">{chatDeleteMsg1}</h5>
                                                <span className="c-def dark-font">{chatDeleteMsg2}</span>
                                            </div>
                                            :
                                            nonChatDeleteSt ?
                                                <div>
                                                    <h5 className="c-def dark-font">{chatDeleteMsg1}</h5>
                                                    <span className="c-def dark-font">{chatDeleteMsg2}</span>
                                                </div>
                                                :
                                                deliveryDetailPayMove ?
                                                    <div>
                                                        <h5 className="c-def dark-font">{deliveryDetailPayMsg1}</h5>
                                                        <span className="c-def dark-font">{deliveryDetailPayMsg2}</span>
                                                    </div>
                                                    :
                                                    integModalSt ?
                                                        <div>
                                                            <h5 className="c-def dark-font">{integModalMsg1}</h5>
                                                            <span className="c-def dark-font">{integModalMsg2}</span>
                                                        </div>
                                                        :
                                                        divideModalSt ?
                                                            <div>
                                                                <h5 className="c-def dark-font">{divideModalMsg1}</h5>
                                                                <span
                                                                    className="c-def dark-font">{divideModalMsg2}</span>
                                                            </div>
                                                            :
                                                            deleteCpShowSt ?
                                                                <div>
                                                                    <h5 className="c-def dark-font">{deleteCpMsg1}</h5>
                                                                    <span
                                                                        className="c-def dark-font">{deleteCpMsg2}</span>
                                                                </div>
                                                                :
                                                                minusBatchCpShowSt ?
                                                                    <div>
                                                                        <h5 className="c-def dark-font">{minusBatchCpMsg1}</h5>
                                                                        <span
                                                                            className="c-def dark-font">{minusBatchCpMsg2}</span>
                                                                    </div>
                                                                    :
                                                                    plusBatchCpShowSt ?
                                                                        <div>
                                                                            <h5 className="c-def dark-font">{plusBatchCpMsg1}</h5>
                                                                            <span
                                                                                className="c-def dark-font">{plusBatchCpMsg2}</span>
                                                                        </div>
                                                                        :
                                                                        cancelMsgShow ?
                                                                            <div>
                                                                                <h5 className="c-def dark-font">{cancelDepHistoryMsg1}</h5>
                                                                                <span
                                                                                    className="c-def dark-font">{cancelDepHistoryMsg2}</span>
                                                                            </div>
                                                                            :
                                                                            deleteReviewShow ?
                                                                                <div>
                                                                                    <h5 className="c-def dark-font">{deleteReviewShowMsg1}</h5>
                                                                                    <span
                                                                                        className="c-def dark-font">{deleteReviewShowMsg2}</span>
                                                                                </div>
                                                                                :
                                                                                deleteReviewCommentShow ?
                                                                                    <div>
                                                                                        <h5 className="c-def dark-font">{deleteReviewCommentShowMsg1}</h5>
                                                                                        <span
                                                                                            className="c-def dark-font">{deleteReviewCommentShowMsg2}</span>
                                                                                    </div>
                                                                                    :
                                                                                    blockCommentShow ?
                                                                                        <div>
                                                                                            <h5 className="c-def dark-font">{blockCommentShowMsg1}</h5>
                                                                                            <span
                                                                                                className="c-def dark-font">{blockCommentShowMsg2}</span>
                                                                                        </div>
                                                                                        :
                                                                                        deleteNoticeMsgShow ?
                                                                                            <div>
                                                                                                <h5 className="c-def dark-font">{deleteNoticeMsg1}</h5>
                                                                                                <span
                                                                                                    className="c-def dark-font">{deleteNoticeMsg2}</span>
                                                                                            </div>
                                                                                            :
                                                                                            mstDeleteMsgShow ?
                                                                                                <div>
                                                                                                    <h5 className="c-def dark-font">{mstDeleteMsg1}</h5>
                                                                                                    <span
                                                                                                        className="c-def dark-font">{mstDeleteMsg2}</span>
                                                                                                </div>
                                                                                                :
                                                                                                deleteFaqDtMsgShow ?
                                                                                                    <div>
                                                                                                        <h5 className="c-def dark-font">{deleteFaqDtMsg1}</h5>
                                                                                                        <span
                                                                                                            className="c-def dark-font">{deleteFaqDtMsg2}</span>
                                                                                                    </div>
                                                                                                    :
                                                                                                    depConfirmComplShow ?
                                                                                                        <div>
                                                                                                            <h5 className="c-def dark-font">{depConfirmComplMsg1}</h5>
                                                                                                            <span
                                                                                                                className="c-def dark-font">{depConfirmComplMsg2}</span>
                                                                                                        </div>
                                                                                                        :
                                                                                                        paypalExDeleteShow ?
                                                                                                            <div>
                                                                                                                <h5 className="c-def dark-font">{paypalExDeleteMsg1}</h5>
                                                                                                                <span
                                                                                                                    className="c-def dark-font">{paypalExDeleteMsg2}</span>
                                                                                                            </div>
                                                                                                            :
                                                                                                            (allCartProdStChangeShow || allOrderProdStChangeShow || allOfflineProdStChangeShow ||
                                                                                                                allTransferProdStChangeShow || allDomesticProdStChangeShow || allDeliveryProdStChangeShow) ?
                                                                                                                <div>
                                                                                                                    <h5 className="c-def dark-font">{allProdStChangeMsg1}</h5>
                                                                                                                    <span
                                                                                                                        className="c-def dark-font">{allProdStChangeMsg2}</span>
                                                                                                                </div>
                                                                                                                :
                                                                                                                orderdtDepositPaySt ?
                                                                                                                    <div>
                                                                                                                        <h5 className="c-def dark-font">{orderdtDepositPayMsg1}</h5>
                                                                                                                        <span
                                                                                                                            className="c-def dark-font">{orderdtDepositPayMsg2}</span>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    orderdtPointPaySt ?
                                                                                                                        <div>
                                                                                                                            <h5 className="c-def dark-font">{orderdtPointPayMsg1}</h5>
                                                                                                                            <span
                                                                                                                                className="c-def dark-font">{orderdtPointPayMsg2}</span>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        payinfoDepositPaySt ?
                                                                                                                            <div>
                                                                                                                                <h5 className="c-def dark-font">{payinfoDepositPayMsg1}</h5>
                                                                                                                                <span
                                                                                                                                    className="c-def dark-font">{payinfoDepositPayMsg2}</span>
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            payinfoPointPaySt ?
                                                                                                                                <div>
                                                                                                                                    <h5 className="c-def dark-font">{payinfoPointPayMsg1}</h5>
                                                                                                                                    <span
                                                                                                                                        className="c-def dark-font">{payinfoPointPayMsg2}</span>
                                                                                                                                </div>
                                                                                                                                :
                                                                                                                                adminOsReturnSt ?
                                                                                                                                    <div>
                                                                                                                                        <h5 className="c-def dark-font">{osReturnMsg1}</h5>
                                                                                                                                        <span
                                                                                                                                            className="c-def dark-font">{osReturnMsg2}</span>
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    adminDsReturnSt ?
                                                                                                                                        <div>
                                                                                                                                            <h5 className="c-def dark-font">{dsReturnMsg1}</h5>
                                                                                                                                            <span
                                                                                                                                                className="c-def dark-font">{dsReturnMsg2}</span>
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        confirmPaySt ?
                                                                                                                                            <div>
                                                                                                                                                <h5 className="c-def dark-font">{confirmPayMsg1}</h5>
                                                                                                                                                <span
                                                                                                                                                    className="c-def dark-font">{confirmPayMsg2}</span>
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            cancelPaySt ?
                                                                                                                                                <div>
                                                                                                                                                    <h5 className="c-def dark-font">{cancelPayMsg1}</h5>
                                                                                                                                                    <span
                                                                                                                                                        className="c-def dark-font">{cancelPayMsg2}</span>
                                                                                                                                                </div>
                                                                                                                                                :
                                                                                                                                                allUpdateProdSt ?
                                                                                                                                                    <div>
                                                                                                                                                        <h5 className="c-def dark-font">{allUpdateProdStMsg1}</h5>
                                                                                                                                                        <span
                                                                                                                                                            className="c-def dark-font">{allUpdateProdStMsg2}</span>
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    updateProdSt ?
                                                                                                                                                        <div>
                                                                                                                                                            <h5 className="c-def dark-font">{updateProdStMsg1}</h5>
                                                                                                                                                            <span
                                                                                                                                                                className="c-def dark-font">{updateProdStMsg2}</span>
                                                                                                                                                        </div>
                                                                                                                                                        :
                                                                                                                                                        deleteBannerMsgShow ?
                                                                                                                                                            <div>
                                                                                                                                                                <h5 className="c-def dark-font">{deleteBannerMsg1}</h5>
                                                                                                                                                                <span
                                                                                                                                                                    className="c-def dark-font">{deleteBannerMsg2}</span>
                                                                                                                                                            </div>
                                                                                                                                                            :
                                                                                                                                                            adminDeliveryCancelSt ?
                                                                                                                                                                <div>
                                                                                                                                                                    <h5 className="c-def dark-font">{dvCancelMsg1}</h5>
                                                                                                                                                                    <span
                                                                                                                                                                        className="c-def dark-font">{dvCancelMsg2}</span>
                                                                                                                                                                </div>
                                                                                                                                                                :
                                                                                                                                                        null
                    }
                </div>
                <div className="alertBottonFrame" style={{textAlign: "center", height: "80px"}}>
                    {alertShow === true ?
                        <div>
                            <button className="MainReviewButton1 btn-click-event" style={{width: "110px"}}
                                    onClick={eventHandle1}>Cancel
                            </button>
                            <button className="MainReviewButton1 btn-click-event" style={{width: "110px"}}
                                    onClick={eventHandle2}>Ok
                            </button>
                        </div>
                        :
                        photoDeleteSelectSt ?
                            <div>
                                <button className="MainReviewButton1 btn-click-event" style={{width: "110px"}}
                                        onClick={photoDeleteCancelFn}>Cancel
                                </button>
                                <button className="MainReviewButton1 btn-click-event" style={{width: "110px"}}
                                        onClick={photoDeleteExecution}>Ok
                                </button>
                            </div>
                            :
                            deleteSelectSt ?
                                <div>
                                    <button className="MainReviewButton1 btn-click-event" style={{width: "110px"}}
                                            onClick={deleteBookCancelFn}>Cancel
                                    </button>
                                    <button className="MainReviewButton1 btn-click-event" style={{width: "110px"}}
                                            onClick={deleteHandleFn}>Ok
                                    </button>
                                </div>
                                :
                                deleteOrderModalShowSt ?
                                    <div>
                                        <button className="MainReviewButton1 btn-click-event" style={{width: "110px"}}
                                                onClick={deleteOrderFalseFn}>Cancel
                                        </button>
                                        <button className="MainReviewButton1 btn-click-event" style={{width: "110px"}}
                                                onClick={deleteOrder}>Ok
                                        </button>
                                    </div>
                                    :
                                    deletePaySt ?
                                        <div>
                                            <button className="MainReviewButton1 btn-click-event"
                                                    style={{width: "110px"}} onClick={deletePayMsgFalseFn}>Cancel
                                            </button>
                                            <button className="MainReviewButton1 btn-click-event"
                                                    style={{width: "110px"}} onClick={deletePay}>Ok
                                            </button>
                                        </div>
                                        :
                                        chatDeleteSt ?
                                            <div>
                                                <button className="MainReviewButton1 btn-click-event"
                                                        style={{width: "110px"}} onClick={chatDeleteFalseFn}>Cancel
                                                </button>
                                                <button className="MainReviewButton1 btn-click-event"
                                                        style={{width: "110px"}}
                                                        onClick={() => deleteChat(messageToModify)}>Ok
                                                </button>
                                            </div>
                                            :
                                            nonChatDeleteSt ?
                                                <div>
                                                    <button className="MainReviewButton1 btn-click-event"
                                                            style={{width: "110px"}}
                                                            onClick={nonChatDeleteFalseFn}>Cancel
                                                    </button>
                                                    <button className="MainReviewButton1 btn-click-event"
                                                            style={{width: "110px"}}
                                                            onClick={() => deleteNonChat(nonMessageToModify)}>Ok
                                                    </button>
                                                </div>
                                                :
                                                deliveryDetailPayMove ?
                                                    <div>
                                                        <button className="MainReviewButton1 btn-click-event"
                                                                style={{width: "110px"}}
                                                                onClick={() => setDeliveryDetailPayMove(false)}>Cancel
                                                        </button>
                                                        <button className="MainReviewButton1 btn-click-event"
                                                                style={{width: "110px"}} onClick={moveDeliveryPay}>Ok
                                                        </button>
                                                    </div>
                                                    :
                                                    integModalSt ?
                                                        <div>
                                                            <button className="MainReviewButton1 btn-click-event"
                                                                    style={{width: "110px"}}
                                                                    onClick={closeIntegModalShow}>Cancel
                                                            </button>
                                                            <button className="MainReviewButton1 btn-click-event"
                                                                    style={{width: "110px"}} onClick={orderMerge}>Ok
                                                            </button>
                                                        </div>
                                                        :
                                                        divideModalSt ?
                                                            <div>
                                                                <button className="MainReviewButton1 btn-click-event"
                                                                        style={{width: "110px"}}
                                                                        onClick={closeDivideModalShow}>Cancel
                                                                </button>
                                                                <button className="MainReviewButton1 btn-click-event"
                                                                        style={{width: "110px"}}
                                                                        onClick={orderDivide}>Ok
                                                                </button>
                                                            </div>
                                                            :
                                                            deleteCpShowSt ?
                                                                <div>
                                                                    <button
                                                                        className="MainReviewButton1 btn-click-event"
                                                                        style={{width: "110px"}}
                                                                        onClick={closeDeleteCpShow}>Cancel
                                                                    </button>
                                                                    <button
                                                                        className="MainReviewButton1 btn-click-event"
                                                                        style={{width: "110px"}}
                                                                        onClick={deleteCoupon}>Ok
                                                                    </button>
                                                                </div>
                                                                :
                                                                minusBatchCpShowSt ?
                                                                    <div>
                                                                        <button
                                                                            className="MainReviewButton1 btn-click-event"
                                                                            style={{width: "110px"}}
                                                                            onClick={closeMinusBatchCpShow}>Cancel
                                                                        </button>
                                                                        <button
                                                                            className="MainReviewButton1 btn-click-event"
                                                                            style={{width: "110px"}}
                                                                            onClick={() => saveBatchCp(false)}>Ok
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    plusBatchCpShowSt ?
                                                                        <div>
                                                                            <button
                                                                                className="MainReviewButton1 btn-click-event"
                                                                                style={{width: "110px"}}
                                                                                onClick={closePlusBatchCpShow}>Cancel
                                                                            </button>
                                                                            <button
                                                                                className="MainReviewButton1 btn-click-event"
                                                                                style={{width: "110px"}}
                                                                                onClick={() => saveBatchCp(true)}>Ok
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        cancelMsgShow ?
                                                                            <div>
                                                                                <button
                                                                                    className="MainReviewButton1 btn-click-event"
                                                                                    style={{width: "110px"}}
                                                                                    onClick={closeCancelMsgShow}>Cancel
                                                                                </button>
                                                                                <button
                                                                                    className="MainReviewButton1 btn-click-event"
                                                                                    style={{width: "110px"}}
                                                                                    onClick={() => cancelDepHistory(cancelDepHistorySeqNum)}>Ok
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            deleteReviewShow ?
                                                                                <div>
                                                                                    <button
                                                                                        className="MainReviewButton1 btn-click-event"
                                                                                        style={{width: "110px"}}
                                                                                        onClick={closeDeleteReviewShow}>Cancel
                                                                                    </button>
                                                                                    <button
                                                                                        className="MainReviewButton1 btn-click-event"
                                                                                        style={{width: "110px"}}
                                                                                        onClick={deleteReview}>Ok
                                                                                    </button>
                                                                                </div>
                                                                                :
                                                                                deleteReviewCommentShow ?
                                                                                    <div>
                                                                                        <button
                                                                                            className="MainReviewButton1 btn-click-event"
                                                                                            style={{width: "110px"}}
                                                                                            onClick={closeDeleteReviewCommentShow}>Cancel
                                                                                        </button>
                                                                                        <button
                                                                                            className="MainReviewButton1 btn-click-event"
                                                                                            style={{width: "110px"}}
                                                                                            onClick={() => deleteReviewComment(deleteReviewCommentSeqNum)}>Ok
                                                                                        </button>
                                                                                    </div>
                                                                                    :
                                                                                    blockCommentShow ?
                                                                                        <div>
                                                                                            <button
                                                                                                className="MainReviewButton1 btn-click-event"
                                                                                                style={{width: "110px"}}
                                                                                                onClick={closeBlockCommentShow}>Cancel
                                                                                            </button>
                                                                                            <button
                                                                                                className="MainReviewButton1 btn-click-event"
                                                                                                style={{width: "110px"}}
                                                                                                onClick={blockComment}>Ok
                                                                                            </button>
                                                                                        </div>
                                                                                        :
                                                                                        deleteNoticeMsgShow ?
                                                                                            <div>
                                                                                                <button
                                                                                                    className="MainReviewButton1 btn-click-event"
                                                                                                    style={{width: "110px"}}
                                                                                                    onClick={closeDeleteNoticeMsgShow}>Cancel
                                                                                                </button>
                                                                                                <button
                                                                                                    className="MainReviewButton1 btn-click-event"
                                                                                                    style={{width: "110px"}}
                                                                                                    onClick={deleteNotice}>Ok
                                                                                                </button>
                                                                                            </div>
                                                                                            :
                                                                                            mstDeleteMsgShow ?
                                                                                                <div>
                                                                                                    <button
                                                                                                        className="MainReviewButton1 btn-click-event"
                                                                                                        style={{width: "110px"}}
                                                                                                        onClick={closeMstDeleteMsg}>Cancel
                                                                                                    </button>
                                                                                                    <button
                                                                                                        className="MainReviewButton1 btn-click-event"
                                                                                                        style={{width: "110px"}}
                                                                                                        onClick={() => deleteFaqMst(mstDeleteSeqNum, mstDeleteContent)}>Ok
                                                                                                    </button>
                                                                                                </div>
                                                                                                :
                                                                                                deleteFaqDtMsgShow ?
                                                                                                    <div>
                                                                                                        <button
                                                                                                            className="MainReviewButton1 btn-click-event"
                                                                                                            style={{width: "110px"}}
                                                                                                            onClick={closeDeleteFaqDtMsgShow}>Cancel
                                                                                                        </button>
                                                                                                        <button
                                                                                                            className="MainReviewButton1 btn-click-event"
                                                                                                            style={{width: "110px"}}
                                                                                                            onClick={deleteFaqDt}>Ok
                                                                                                        </button>
                                                                                                    </div>
                                                                                                    :
                                                                                                    depConfirmComplShow ?
                                                                                                        <div>
                                                                                                            <button
                                                                                                                className="MainReviewButton1 btn-click-event"
                                                                                                                style={{width: "110px"}}
                                                                                                                onClick={closeDepConfirmCompl}>Cancel
                                                                                                            </button>
                                                                                                            <button
                                                                                                                className="MainReviewButton1 btn-click-event"
                                                                                                                style={{width: "110px"}}
                                                                                                                onClick={modifyDepConfirmCompl}>Ok
                                                                                                            </button>
                                                                                                        </div>
                                                                                                        :
                                                                                                        paypalExDeleteShow ?
                                                                                                            <div>
                                                                                                                <button
                                                                                                                    className="MainReviewButton1 btn-click-event"
                                                                                                                    style={{width: "110px"}}
                                                                                                                    onClick={closePaypalExDeleteShow}>Cancel
                                                                                                                </button>
                                                                                                                <button
                                                                                                                    className="MainReviewButton1 btn-click-event"
                                                                                                                    style={{width: "110px"}}
                                                                                                                    onClick={() => deleteData(paypalExDeleteChecked)}>Ok
                                                                                                                </button>
                                                                                                            </div>
                                                                                                            :
                                                                                                            allCartProdStChangeShow ?
                                                                                                                <div>
                                                                                                                    <button
                                                                                                                        className="MainReviewButton1 btn-click-event"
                                                                                                                        style={{width: "110px"}}
                                                                                                                        onClick={closeAllCartProdStChangeShow}>Cancel
                                                                                                                    </button>
                                                                                                                    <button
                                                                                                                        className="MainReviewButton1 btn-click-event"
                                                                                                                        style={{width: "110px"}}
                                                                                                                        onClick={handleAdminAllProdStChange}>Ok
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                                :
                                                                                                                allOrderProdStChangeShow ?
                                                                                                                    <div>
                                                                                                                        <button
                                                                                                                            className="MainReviewButton1 btn-click-event"
                                                                                                                            style={{width: "110px"}}
                                                                                                                            onClick={closeAllOrderProdStChangeShow}>Cancel
                                                                                                                        </button>
                                                                                                                        <button
                                                                                                                            className="MainReviewButton1 btn-click-event"
                                                                                                                            style={{width: "110px"}}
                                                                                                                            onClick={handleAdminAllProdStChange}>Ok
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    allOfflineProdStChangeShow ?
                                                                                                                        <div>
                                                                                                                            <button
                                                                                                                                className="MainReviewButton1 btn-click-event"
                                                                                                                                style={{width: "110px"}}
                                                                                                                                onClick={closeAllOfflineProdStChangeShow}>Cancel
                                                                                                                            </button>
                                                                                                                            <button
                                                                                                                                className="MainReviewButton1 btn-click-event"
                                                                                                                                style={{width: "110px"}}
                                                                                                                                onClick={handleAdminAllProdStChange}>Ok
                                                                                                                            </button>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        allTransferProdStChangeShow ?
                                                                                                                            <div>
                                                                                                                                <button
                                                                                                                                    className="MainReviewButton1 btn-click-event"
                                                                                                                                    style={{width: "110px"}}
                                                                                                                                    onClick={closeAllTransferProdStChangeShow}>Cancel
                                                                                                                                </button>
                                                                                                                                <button
                                                                                                                                    className="MainReviewButton1 btn-click-event"
                                                                                                                                    style={{width: "110px"}}
                                                                                                                                    onClick={handleAdminAllProdStChange}>Ok
                                                                                                                                </button>
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            allDomesticProdStChangeShow ?
                                                                                                                                <div>
                                                                                                                                    <button
                                                                                                                                        className="MainReviewButton1 btn-click-event"
                                                                                                                                        style={{width: "110px"}}
                                                                                                                                        onClick={closeAllDomesticProdStChangeShow}>Cancel
                                                                                                                                    </button>
                                                                                                                                    <button
                                                                                                                                        className="MainReviewButton1 btn-click-event"
                                                                                                                                        style={{width: "110px"}}
                                                                                                                                        onClick={handleAdminAllProdStChange}>Ok
                                                                                                                                    </button>
                                                                                                                                </div>
                                                                                                                                :
                                                                                                                                allDeliveryProdStChangeShow ?
                                                                                                                                    <div>
                                                                                                                                        <button
                                                                                                                                            className="MainReviewButton1 btn-click-event"
                                                                                                                                            style={{width: "110px"}}
                                                                                                                                            onClick={closeAllDeliveryProdStChangeShow}>Cancel
                                                                                                                                        </button>
                                                                                                                                        <button
                                                                                                                                            className="MainReviewButton1 btn-click-event"
                                                                                                                                            style={{width: "110px"}}
                                                                                                                                            onClick={handleAdminAllProdStChange}>Ok
                                                                                                                                        </button>
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    orderdtDepositPaySt ?
                                                                                                                                        <div>
                                                                                                                                            <button
                                                                                                                                                className="MainReviewButton1 btn-click-event"
                                                                                                                                                style={{width: "110px"}}
                                                                                                                                                onClick={closeOrderdtDepositPaySt}>Cancel
                                                                                                                                            </button>
                                                                                                                                            <button
                                                                                                                                                className="MainReviewButton1 btn-click-event"
                                                                                                                                                style={{width: "110px"}}
                                                                                                                                                onClick={() => orderdtAdminDpPay("deposit")}>Ok
                                                                                                                                            </button>
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        orderdtPointPaySt ?
                                                                                                                                            <div>
                                                                                                                                                <button
                                                                                                                                                    className="MainReviewButton1 btn-click-event"
                                                                                                                                                    style={{width: "110px"}}
                                                                                                                                                    onClick={closeOrderdtPointPaySt}>Cancel
                                                                                                                                                </button>
                                                                                                                                                <button
                                                                                                                                                    className="MainReviewButton1 btn-click-event"
                                                                                                                                                    style={{width: "110px"}}
                                                                                                                                                    onClick={() => orderdtAdminDpPay("point")}>Ok
                                                                                                                                                </button>
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            payinfoDepositPaySt ?
                                                                                                                                                <div>
                                                                                                                                                    <button
                                                                                                                                                        className="MainReviewButton1 btn-click-event"
                                                                                                                                                        style={{width: "110px"}}
                                                                                                                                                        onClick={closePayinfoDepositPaySt}>Cancel
                                                                                                                                                    </button>
                                                                                                                                                    <button
                                                                                                                                                        className="MainReviewButton1 btn-click-event"
                                                                                                                                                        style={{width: "110px"}}
                                                                                                                                                        onClick={() => payinfoAdminDpPay("deposit")}>Ok
                                                                                                                                                    </button>
                                                                                                                                                </div>
                                                                                                                                                :
                                                                                                                                                payinfoPointPaySt ?
                                                                                                                                                    <div>
                                                                                                                                                        <button
                                                                                                                                                            className="MainReviewButton1 btn-click-event"
                                                                                                                                                            style={{width: "110px"}}
                                                                                                                                                            onClick={closePayinfoPointPaySt}>Cancel
                                                                                                                                                        </button>
                                                                                                                                                        <button
                                                                                                                                                            className="MainReviewButton1 btn-click-event"
                                                                                                                                                            style={{width: "110px"}}
                                                                                                                                                            onClick={() => payinfoAdminDpPay("point")}>Ok
                                                                                                                                                        </button>
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    adminOsReturnSt ?
                                                                                                                                                        <div>
                                                                                                                                                            <button
                                                                                                                                                                className="MainReviewButton1 btn-click-event"
                                                                                                                                                                style={{width: "110px"}}
                                                                                                                                                                onClick={closeOsReturnSt}>Cancel
                                                                                                                                                            </button>
                                                                                                                                                            <button
                                                                                                                                                                className="MainReviewButton1 btn-click-event"
                                                                                                                                                                style={{width: "110px"}}
                                                                                                                                                                onClick={() => deliveryReturn("overseas")}>Ok
                                                                                                                                                            </button>
                                                                                                                                                        </div>
                                                                                                                                                        :
                                                                                                                                                        adminDsReturnSt ?
                                                                                                                                                            <div>
                                                                                                                                                                <button
                                                                                                                                                                    className="MainReviewButton1 btn-click-event"
                                                                                                                                                                    style={{width: "110px"}}
                                                                                                                                                                    onClick={closeDsReturnSt}>Cancel
                                                                                                                                                                </button>
                                                                                                                                                                <button
                                                                                                                                                                    className="MainReviewButton1 btn-click-event"
                                                                                                                                                                    style={{width: "110px"}}
                                                                                                                                                                    onClick={() => deliveryReturn("domestic")}>Ok
                                                                                                                                                                </button>
                                                                                                                                                            </div>
                                                                                                                                                            :
                                                                                                                                                            confirmPaySt ?
                                                                                                                                                                <div>
                                                                                                                                                                    <button
                                                                                                                                                                        className="MainReviewButton1 btn-click-event"
                                                                                                                                                                        style={{width: "110px"}}
                                                                                                                                                                        onClick={closeConfirmPaySt}>Cancel
                                                                                                                                                                    </button>
                                                                                                                                                                    <button
                                                                                                                                                                        className="MainReviewButton1 btn-click-event"
                                                                                                                                                                        style={{width: "110px"}}
                                                                                                                                                                        onClick={() => payDataSucc(selectedSeqNum)}>Ok
                                                                                                                                                                    </button>
                                                                                                                                                                </div>
                                                                                                                                                                :
                                                                                                                                                                cancelPaySt ?
                                                                                                                                                                    <div>
                                                                                                                                                                        <button
                                                                                                                                                                            className="MainReviewButton1 btn-click-event"
                                                                                                                                                                            style={{width: "110px"}}
                                                                                                                                                                            onClick={closeCancelPaySt}>Cancel
                                                                                                                                                                        </button>
                                                                                                                                                                        <button
                                                                                                                                                                            className="MainReviewButton1 btn-click-event"
                                                                                                                                                                            style={{width: "110px"}}
                                                                                                                                                                            onClick={() => payDataCancel(selectedSeqNum)}>Ok
                                                                                                                                                                        </button>
                                                                                                                                                                    </div>
                                                                                                                                                                    :
                                                                                                                                                                    allUpdateProdSt ?
                                                                                                                                                                        <div>
                                                                                                                                                                            <button
                                                                                                                                                                                className="MainReviewButton1 btn-click-event"
                                                                                                                                                                                style={{width: "110px"}}
                                                                                                                                                                                onClick={closeAllUpdateProdSt}>Cancel
                                                                                                                                                                            </button>
                                                                                                                                                                            <button
                                                                                                                                                                                className="MainReviewButton1 btn-click-event"
                                                                                                                                                                                style={{width: "110px"}}
                                                                                                                                                                                onClick={() => updateProdArrival(checkedOrd)}>Ok
                                                                                                                                                                            </button>
                                                                                                                                                                        </div>
                                                                                                                                                                        :
                                                                                                                                                                        updateProdSt ?
                                                                                                                                                                            <div>
                                                                                                                                                                                <button
                                                                                                                                                                                    className="MainReviewButton1 btn-click-event"
                                                                                                                                                                                    style={{width: "110px"}}
                                                                                                                                                                                    onClick={closeUpdateProdSt}>Cancel
                                                                                                                                                                                </button>
                                                                                                                                                                                <button
                                                                                                                                                                                    className="MainReviewButton1 btn-click-event"
                                                                                                                                                                                    style={{width: "110px"}}
                                                                                                                                                                                    onClick={() => updateProdArrival(checkedOrd)}>Ok
                                                                                                                                                                                </button>
                                                                                                                                                                            </div>
                                                                                                                                                                            :
                                                                                                                                                                            deleteBannerMsgShow ?
                                                                                                                                                                                <div>
                                                                                                                                                                                    <button
                                                                                                                                                                                        className="MainReviewButton1 btn-click-event"
                                                                                                                                                                                        style={{width: "110px"}}
                                                                                                                                                                                        onClick={closeDeleteBannerMsgShow}>Cancel
                                                                                                                                                                                    </button>
                                                                                                                                                                                    <button
                                                                                                                                                                                        className="MainReviewButton1 btn-click-event"
                                                                                                                                                                                        style={{width: "110px"}}
                                                                                                                                                                                        onClick={() => deleteBanner(deleteBannerSeqNum)}>Ok
                                                                                                                                                                                    </button>
                                                                                                                                                                                </div>
                                                                                                                                                                                :
                                                                                                                                                                                adminDeliveryCancelSt ?
                                                                                                                                                                                    <div>
                                                                                                                                                                                        <button
                                                                                                                                                                                            className="MainReviewButton1 btn-click-event"
                                                                                                                                                                                            style={{width: "110px"}}
                                                                                                                                                                                            onClick={closeDeliveryCancelSt}>No
                                                                                                                                                                                        </button>
                                                                                                                                                                                        <button
                                                                                                                                                                                            className="MainReviewButton1 btn-click-event"
                                                                                                                                                                                            style={{width: "110px"}}
                                                                                                                                                                                            onClick={() => deliveryCancel()}>Yes
                                                                                                                                                                                        </button>
                                                                                                                                                                                    </div>
                                                                                                                                                                                    :
                                                                                                                                                                            null
                    }
                </div>
            </div>
        </div>
    );
}

export default MsgSelectModal
