import React from 'react';
import {IoSearchOutline} from "react-icons/io5";

function AdminSearchEvent({ searchInput, handleSearchInputChange }) {
    return (
        <div className="m-r-15">
            <span className="admin-search ht-30" style={{display: "inline-block", borderRadius: "4px"}}>
                <input className="admin-search-input font-s-14 p-l-5 ht-28 m-l-1"
                       type="text"
                       placeholder="Search"
                       value={searchInput}
                       onChange={handleSearchInputChange}
                       maxLength="20"
                       size="15"
                       style={{outline: "none", border: "none"}}/>
                <button style={{display: "inline-block", border: "none"}}>
                    <IoSearchOutline className="dark-icon m-r-5 m-b-2"/>
                </button>
            </span>
        </div>
    );
}

export default AdminSearchEvent
