import React, {useEffect, useState} from "react";
import commonHeader from "../../assets/img/commonHeader.png";
import {useNavigate} from "react-router-dom";

import testze from '../../assets/img/mainFaq.png'
import axios from "axios";
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";

function UserNoticePg() {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const moveToDetail = ((seqNum) => {
        navigate(`/UserNoticeDtPg/${seqNum}`)
    })

    const [noticeList, setNoticeList] = useState([]);
    const selectAllNoticeList = (lang) => axios({
        method: 'get',
        url: '/notice/selectAllNoticeList/',
        params: {
            lang: lang
        }
    })
        .then((response) => {
            const data = response.data.sort((a, b) => b.seqNum - a.seqNum);
            setNoticeList(data);
        })

    const [currentLang, setCurrentLang] = useState(null);
    const currentLangUpdate = (() => {
        setCurrentLang(i18n.language || 'ko')
    })
    useEffect(() => {
        currentLangUpdate();
    }, [i18n.language]);

        useEffect(() => {
            const selectAllNoticeList = (lang) => {
                switch (lang) {
                    case 'ko':
                        lang = '0';
                        break;
                    case 'en':
                        lang = '1';
                        break;
                    case 'ja':
                        lang = '2';
                        break;
                    case 'zh':
                        lang = '3';
                        break;
                    case 'id':
                        lang = '4';
                        break;
                    default:
                        lang = '0';
                }
                selectAllNoticeList(lang);
                i18n.on('languageChanged', selectAllNoticeList);
                return () => {
                    i18n.off('languageChanged', selectAllNoticeList);
                };
            }
        }, []);

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    return(
        <div className="font-t-cont">
            <div style={{
                height: "50px",
                display:"flex",
                alignItems:"center",
                marginBottom:"10px"
            }}>
                <img style={{width:"25px", height:"25px"}} alt="HeatherImage" src={commonHeader}/>
                <p style={{margin:"0", fontSize:"18px", marginLeft:"5px"}}>{t(`NtHeader`)}</p>
            </div>
            <div style={{minHeight:"500px", border:"solid", borderWidth:"thin 1px", borderColor:"#EAEAEA"}}>
                <div className="user-center" style={{borderBottom:"solid", borderWidth:"thin 2px", borderColor:"lightgray", height:"50px", display:"flex"}}>
                    <div className="user-center" style={{width:"15%"}}><p style={{margin:"0", fontSize:"15px"}}>{t(`fSmallMenu1`)}</p></div>
                    <div className="user-center" style={{width:"15%"}}><p style={{margin:"0", fontSize:"15px"}}>{t(`fSmallMenu5`)}</p></div>
                    <div className="user-center" style={{width:"55%"}}><p style={{margin:"0", fontSize:"15px"}}>{t(`fSmallMenu3`)}</p></div>
                    <div className="user-center" style={{width:"15%"}}><p style={{margin:"0", fontSize:"15px"}}>{t(`fSmallMenu4`)}</p></div>
                </div>
                <div style={{padding:"10px 0"}}>
                <div>
                    {/*고정*/}
                    {noticeList.filter(data => data.fixFl === true).map((notice, i) => (
                        <div key={i} onClick={()=> moveToDetail(notice.seqNum)} className="user-center" style={{height:"40px", display:"flex", cursor:"pointer"}}>
                        <div className="user-center" style={{width:"15%"}}><p style={{margin:"0", fontSize:"14px", color:"darkorange"}}>{t(`fdFix`)}</p></div>
                            <div className="user-center" style={{width:"15%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black"}}>{formatDate(notice?.regDate)}</p></div>
                            <div className="user-center" style={{width:"55%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black"}}>{notice?.title}</p></div>
                        <div className="user-center" style={{width:"15%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black"}}>{notice?.regNm}</p></div>
                    </div>
                        ))}
                </div>
                <div>
                    {noticeList.filter(data => data.fixFl === false).map((notice, i) => (
                        <div key={i} onClick={()=> moveToDetail(notice.seqNum)} className="user-center" style={{height:"40px", display:"flex", cursor:"pointer"}}>
                        <div className="user-center" style={{width:"15%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black"}}>{i+1}</p></div>
                            <div className="user-center" style={{width:"15%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black"}}>{formatDate(notice?.regDate)}</p></div>
                            <div className="user-center" style={{width:"55%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black"}}>{notice?.title}</p></div>
                        <div className="user-center" style={{width:"15%"}}><p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black"}}>{notice?.regNm}</p></div>
                    </div>
                        ))}
                </div>
                </div>
            </div>
        </div>
    )
}

export default UserNoticePg
