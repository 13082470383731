import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import notice from '../../assets/img/adminNotice.png'
import axios from "axios";
import profileImage from "../../assets/img/profileDefaultImg.png";
import DOMPurify from "dompurify";
import {BiArrowBack} from "react-icons/bi";
import reviewComment from "../../assets/img/reviewComment.png";
import defaultImg from "../../assets/img/profileDefaultImg.png";
import warnIcon from "../../assets/img/warnIcon.png";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import maCateImg from "../../assets/img/m-a-cate.png";


function AdminNoticeDtPg() {

    const navigate = useNavigate();
    const {seqNum} = useParams();
    const LCAT = localStorage.getItem('adminToken');

    const moveToModifyPg = (() => {
        navigate(`/btcorpad/adminnotice-modify/${seqNum}`)
    })

    const [adminInfo, setAdminInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT) {
            findMember();
        }
    }, [LCAT])

    const [noticeData, setNoticeData] = useState([]);
    const [photoList, setPhotoList] = useState([]);


    const selectNoticeData = () =>
        axios({
            method: 'get',
            url: '/notice/selectNoticeData',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                console.log("노티스데이터 : ", response.data)
                setNoticeData(response.data)
                setPhotoList(response.data.noticePhotoDTO)
            })

    useEffect(() => {
        if (seqNum) {
            selectNoticeData();
        }
    }, [seqNum])


    const useSanitizedHTML = ((rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    })
    const sanitizedContent = useSanitizedHTML(noticeData?.content);

    const deleteNoticeMsg1 = "공지사항을 삭제하시겠습니까?"
    const deleteNoticeMsg2 = "삭제 후 복구가 불가합니다"
    const [deleteNoticeMsgShow, setDeleteNoticeMsgShow] = useState(false);
    const closeDeleteNoticeMsgShow = (() => {
        setDeleteNoticeMsgShow(false);
    })

    const deleteNotice = (() => {
        axios({
            method: 'post',
            url: '/notice/deleteNotice',
            params: {
                noticeSeqNum: seqNum,
            },
        })
            .then(() => {
                closeDeleteNoticeMsgShow();
                navigate("/btcorpad/adminnotice");
            })
            .catch(() => {
                console.log("공지사항 삭제실패")
            })
    })

    return(
        <div className="AdminNoticePg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">공지사항상세</p>
            </div>
            <div className="admin-wrap font-t-cont">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div style={{
                            border: "solid",
                            width: "65%",
                            margin: "auto",
                            borderWidth: "thin 1px",
                            borderColor: "#EEEEEE",
                            borderRadius: "4px",
                            padding: "20px"
                        }}>
                            <div style={{height: "80px", display: "flex", marginBottom:"-20px"}}>
                                <div style={{width: "10%"}}>
                                    <div style={{borderRadius: "100%", height: "100%", width: "90%"}}><img alt="profileImg" style={{
                                        width:"90%",
                                        height:"90%",
                                        opacity:"0.6"
                                    }} src={notice}/></div>
                                </div>
                                <div style={{width: "90%", height: "100%"}}>
                                    <div style={{height: "40%", display: "flex", color: "dimgrey", alignItems:"center"}}><span
                                        style={{paddingRight: "10px", fontSize: "15px"}}>{noticeData?.regNm}</span>
                                        <span style={{
                                            paddingRight: "10px",
                                            color: "dimgrey",
                                            fontSize: "14px",
                                            marginLeft:"10px",
                                        }}>조회수 {noticeData?.view}</span> <span
                                            style={{marginLeft: "auto"}}>
                                            <Link to={"/btcorpad/adminnotice"}>
                                                <i style={{display:"flex", justifyContent:"center", alignItems:"center", fontSize:"25px", cursor:"pointer"}}><BiArrowBack/></i>
                                            </Link>
                                        </span>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", height:"45px"}}>
                                        <p className="font-s-15 font-b">{noticeData?.title}</p>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                        <p onClick={moveToModifyPg} style={{fontSize:"15px", color:"#7C9EDC", cursor:"pointer"}}>수정</p>
                                        <p onClick={()=> setDeleteNoticeMsgShow(true)} style={{fontSize:"15px", marginLeft:"10px", color:"#EB939D", cursor:"pointer"}}>삭제</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{borderTop: "solid", marginTop:"30px", borderWidth:"thin 1px", borderColor:"lightgray"}}>
                                <div style={{minHeight:"80px", display:"flex", alignItems:"center", padding:"20px 0"}}>
                                    <p style={{margin:"0", fontSize:"16px", height:"100%", fontWeight:"lighter"}} dangerouslySetInnerHTML={{ __html: sanitizedContent }}></p>
                                </div>
                                <div style={{display:"flex", flexWrap:"wrap"}}>
                                    {photoList && photoList.map((photo, index) => (
                                        <div key={index} style={{width: "100%", boxSizing: "border-box", padding:"5px"}}>
                                            <img src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/writePhoto/" + photo.fileNm} alt="photoImg"
                                                 style={{width:"100%", height:"100%", borderRadius:"4px"}}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { deleteNoticeMsgShow ?
            <MsgSelectModal deleteNoticeMsgShow={deleteNoticeMsgShow} deleteNoticeMsg1={deleteNoticeMsg1} deleteNoticeMsg2={deleteNoticeMsg2}
                            closeDeleteNoticeMsgShow={closeDeleteNoticeMsgShow} deleteNotice={deleteNotice}/>
                :
                null
            }
        </div>
    )
}

export default AdminNoticeDtPg
