import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import commonHeader from "../../assets/img/commonHeader.png";
import notice from "../../assets/img/notice.png";
import commonBack from "../../assets/img/commonBack.png";
import axios from "axios";
import DOMPurify from "dompurify";
import {useTranslation} from "react-i18next";
import Linkify from "react-linkify";



function UserNoticeDtPg() {

    const { t } = useTranslation();
    const {seqNum} = useParams();
    const navigate = useNavigate();

    const [noticeDtData, setNoticeDtData] = useState([]);
    const [photoList, setPhotoList] = useState([]);

    const selectDtData = () => axios({
        method: 'get',
        url: '/notice/selectNoticeData/',
        params: {
            seqNum: seqNum
        }
    })
        .then((response) => {
            setNoticeDtData(response.data);
            setPhotoList(response.data.noticePhotoDTO)
        })

    useState(() => {
        if (seqNum) {
            selectDtData();
        }
    }, [])

    const sanitizeHTML = (rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    };

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    return(
        <div className="font-t-cont">
            <div style={{
                height: "50px",
                display:"flex",
                alignItems:"center",
                marginBottom:"10px",
                justifyContent:"space-between"
            }}>
                <div style={{display:"flex", alignItems:"center"}}>
                <img style={{width:"25px", height:"25px"}} alt="HeatherImage" src={commonHeader}/>
                <p style={{margin:"0", fontSize:"18px", marginLeft:"5px"}}>{t(`NtdtHeader`)}</p>
                </div>
                <div onClick={()=> navigate("/UserNoticePg")} style={{display:"flex", alignItems:"center", cursor:"pointer"}}>
                    <img style={{width:"25px", height:"25px", opacity:"0.6"}} alt="backImage" src={commonBack}/>
                </div>
            </div>
            <div style={{border:"solid", borderWidth:"thin 1px", borderColor:"#EAEAEA", padding:"20px 50px"}}>
                <div style={{height:"100%"}}>
                    <div style={{height:"60px", borderBottom:"solid", borderWidth:"thin 1px", borderColor:"#EAEAEA",
                        display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                        <div style={{display:"flex", alignItems:"center", height:"100%"}}>
                            <img style={{width:"30px", height:"30px"}} alt="noticeTitle" src={notice}/>
                        <p className="font-t-head" style={{fontSize:"17px", marginLeft:"15px"}}>{noticeDtData?.title}</p>
                        </div>
                        <div style={{display:"flex", alignItems:"center", height:"100%"}}>
                            <p style={{margin:"0", fontSize:"13px", fontWeight:"lighter", color:"black"}}>{noticeDtData?.regNm}</p>
                            <p style={{margin:"0", fontSize:"13px", marginLeft:"20px", fontWeight:"lighter", color:"black"}}>{formatDate(noticeDtData?.regDate)}</p>
                        </div>
                    </div>
                    <div style={{minHeight:"100px", padding:"30px 0"}}>
                        <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a style={{color: "orangered"}}
                                   target="blank"
                                   href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}
                        >
                            <p className="font-t-cont" style={{
                                wordBreak: "break-word",
                                whiteSpace: "pre-wrap",
                                margin:"0",
                                fontSize:"15px",
                                color:"black",
                                alignSelf: "flex-start"
                            }}>{DOMPurify.sanitize(noticeDtData?.content)}</p>
                        </Linkify>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                        {photoList && photoList.map((photo, index) => (
                            <div key={index} style={{width: "100%", boxSizing: "border-box", padding:"5px"}}>
                                <img src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/writePhoto/" + photo.fileNm} alt="photoImg"
                                     style={{width:"100%", height:"100%", borderRadius:"4px"}}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserNoticeDtPg
