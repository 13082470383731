import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import commonClose from '../../assets/img/commonClose.png'
import {useTranslation} from "react-i18next";
import "../../css/mobile/MDeliveryPriceDetail.css";
import MsgSelectModal from "../web/modal/MsgSelectModal";
import MAddrBookPg from "../../page/mobile/MAddrBookPg";
import commonHether from "../../assets/img/commonHether.png";
import FedexLogo from "../../assets/img/FedexLogo.png";
import dhlLogo from "../../assets/img/DhlLogo.png";
import EmsLogo from "../../assets/img/EmsLogo.png";
import upsLogo from "../../assets/img/UpsLogo.png";
import kPacket from "../../assets/img/Kpacket.png";
import smallPacket from "../../assets/img/commonSmallPacket.png";
import SeaShipLogo from "../../assets/img/SeaShipLogo.png";
import CommonLoading from "../web/modal/CommonLoading";

function MDeliveryPriceDetail({closeDetail, paySeqnum, updatePayinfoList, deliveryDetailSt, deliveryDetailFalseFn, updateAppRecinfo, pathComponent, selectedShip}) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const adminToken = localStorage.getItem('adminToken');

    const [isLoading, setIsLoading] = useState(false);
    const [isBtDisabled, setIsBtDisabled] = useState(false);
    const [isRecinfoBtLoading, setIsRecinfoBtLoading] = useState(false);
    const [isCustomBtLoading, setIsCustomBtLoading] = useState(false);

    const [contentsNameCount, setContentsNameCount] = useState(0);
    const [contentsSeqnum, setContentsSeqnum] = useState("");
    const [contentsName, setContentsName] = useState("");
    const [totalQuantity, setTotalQuantity] = useState("");
    const [contentsDollar, setContentsDollar] = useState('');
    const [contentsWon, setContentsWon] = useState('');

    const [contentsNameError, setContentsNameError] = useState(false);
    const [totalQuantityError, setTotalQuantityError] = useState(false);
    const [contentsDollarError, setContentsDollarError] = useState(false);
    const [contentsWonError, setContentsWonError] = useState(false);

    const [ctSaveDoneMessage, setCtSaveDoneMessage] = useState(false);
    const [ctSaveFailMessage, setCtSaveFailMessage] = useState(false);

    const contentsNameLengthCheck = (e) => {
        setContentsNameCount(e.target.value.length);
    };
    const contentsNameInputRTCheck = (e) => {
        setContentsNameCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const validContentsName = ((e) => {
        const contentsNmRegex = /^[a-zA-Z ]{2,35}/; /*영문 2~25자*/
        if (!e.target.value || contentsNmRegex.test(e.target.value)) setContentsNameError(false);
        else setContentsNameError(true);
        setContentsName(e.target.value);
    })

    const validTotalQuantity = ((e) => {
        const totalQuantityRegex = /^\d{1,8}$/;
        const inputValue = e.target.value.replace(/,/g, '');

        if (!inputValue) {
            setTotalQuantityError(true);
            setTotalQuantity('');
        } else if (totalQuantityRegex.test(inputValue)) {
            setTotalQuantityError(false);
            setTotalQuantity(inputValue);
        } else {
            setTotalQuantityError(true);
        }
    })

    const validContentsDollar = ((e) => {
        const contentsDollarRegex = /^\d{1,8}$/;
        const inputValue = e.target.value.replace(/,/g, '');

        if (!inputValue) {
            setContentsDollarError(true);
            setContentsDollar('');
        } else if (contentsDollarRegex.test(inputValue)) {
            setContentsDollarError(false);
            setContentsDollar(inputValue);
        } else {
            setContentsDollarError(true);
        }
    })

    const validContentsWon = ((e) => {
        const contentsWonRegex = /^\d{1,8}$/;
        const inputValue = e.target.value.replace(/,/g, '');
        if (!inputValue) {
            setContentsWonError(true);
            setContentsWon('');
        } else if (contentsWonRegex.test(inputValue)) {
            setContentsWonError(false);
            setContentsWon(inputValue);
        } else {
            setContentsWonError(true);
        }
    })

    const insrReset = (() => {
        setContentsWon('');
        setContentsWonError(false);
    })

    const contentsNameValidCheck = (e) => {
        validContentsName(e)
        contentsNameLengthCheck(e);
        contentsNameInputRTCheck(e)
    }

    const [insuranceStatus, setInsuranceStatus] = useState(false);
    const [memberInfo, setMemberInfo] = useState([]);

    const [payList, setPayList] = useState([{}]);
    const [appReqList, setAppReqList] = useState({});
    const [prodList, setProdList] = useState([]);
    const [packList, setPackList] = useState([]);
    const [shipInfoList, setShipInfoList] = useState({});
    const [orderSeqnum, setOrderSeqnum] = useState([]);
    const [appRecSeqnum, setAppRecSeqnum] = useState(0);

    const totalFedexExpense = payList[0]?.ttFedexPrice || 0
    const totalEmsExpense = payList[0]?.ttEmsPrice || 0
    const totalSeaExpense = payList[0]?.ttSeaPrice || 0
    const totalDomExpense = payList[0]?.ttDomPrice || 0
    const totalDhlExpense = payList[0]?.ttDhlPrice || 0
    const totalUpsExpense = payList[0]?.ttUpsPrice || 0
    const totalKpackExpense = payList[0]?.ttKPackPrice || 0
    const totalAirSExpense = payList[0]?.ttAirSPrice || 0

    const totalShipExpense = (
        totalFedexExpense !== 0 ? totalFedexExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
            totalEmsExpense !== 0 ? totalEmsExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                totalSeaExpense !== 0 ? totalSeaExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                    totalDomExpense !== 0 ? totalDomExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                        totalDhlExpense !== 0 ? totalDhlExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                            totalUpsExpense !== 0 ? totalUpsExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                totalKpackExpense !== 0 ? totalKpackExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                    totalAirSExpense !== 0 ? totalAirSExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                        0)

    const [taxid, setTaxid] = useState("");
    const [recname, setRecname] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [telnum, setTelnum] = useState("");

    const [deliveryChoiceError, setDeliveryChoiceError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [taxidError, setTaxidError] = useState("");
    const [recnameError, setRecnameError] = useState("");
    const [address1Error, setAddress1Error] = useState("");
    const [address2Error, setAddress2Error] = useState("");
    const [address3Error, setAddress3Error] = useState("");
    const [cityError, setCityError] = useState("");
    const [zipcodeError, setZipcodeError] = useState("");
    const [telnumError, setTelnumError] = useState("");

    const [recSaveDoneMessage, setRecSaveDoneMessage] = useState(false);
    const [recSaveFailMessage, setRecSaveFailMessage] = useState(false);

    const [nameInputCount, setNameInputCount] = useState(0);
    const [telnumInputCount, setTelnumInputCount] = useState(0);
    const [zipcodeInputCount, setZipcodeInputCount] = useState(0);
    const [address1InputCount, setAddress1InputCount] = useState(0);
    const [address2InputCount, setAddress2InputCount] = useState(0);
    const [address3InputCount, setAddress3InputCount] = useState(0);
    const [cityInputCount, setCityInputCount] = useState(0);

    const selectPayinfoDetail = (async () => {
        setIsLoading(true);
        try {
            await axios({
                method: 'get',
                url: '/order/selectTotalPayInfo/',
                headers: {
                    Authorization: LCAT ? LCAT : SSAT
                },
                params: {
                    seqNum: paySeqnum
                },
            })
                .then((response) => {
                    setPayList(response.data)
                    setAppReqList(response.data[0].appReqDTO)
                    setProdList(response.data[0].orderdtDTO.productsDTO)
                    setMemberInfo(response.data[0].orderdtDTO.memberDTO)
                    setPackList(response.data[0].packListDTO?.productsDTO)
                    setShipInfoList(response.data[0].shipInfoDTO)

                    setContentsNameCount(response.data[0].customsDTO.custContent.length)
                    setContentsSeqnum(response.data[0].customsDTO.seqNum)
                    setContentsName(response.data[0].customsDTO.custContent)
                    setTotalQuantity(response.data[0].customsDTO.custQty)
                    setContentsDollar(response.data[0].customsDTO.custPrice)
                    setContentsWon(response.data[0].customsDTO.insrPrice)
                    setInsuranceStatus(response.data[0].customsDTO.insrFl)

                    setAppRecSeqnum(response.data[0].appRecDTO.seqnum)
                    const countryData = {
                        value: response.data[0].appRecDTO.countryDTO.countryCode,
                        label: response.data[0].appRecDTO.countryDTO.countryNm + '(' + response.data[0].appRecDTO.countryDTO.countryEngNm + ')'
                    };
                    setSelectedCountry(countryData)
                    setTaxid(response.data[0].appRecDTO.taxid);
                    setRecname(response.data[0].appRecDTO.recname)
                    setNameInputCount(response.data[0].appRecDTO.recname.length)
                    setTelnum(response.data[0].appRecDTO.telnum)
                    setTelnumInputCount(response.data[0].appRecDTO.telnum.length)
                    setZipcode(response.data[0].appRecDTO.zipcode)
                    setZipcodeInputCount(response.data[0].appRecDTO.zipcode.length)
                    setAddress1(response.data[0].appRecDTO.address1)
                    setAddress1InputCount(response.data[0].appRecDTO.address1.length)
                    setAddress2(response.data[0].appRecDTO.address2)
                    setAddress2InputCount(response.data[0].appRecDTO.address2.length)
                    setAddress3(response.data[0].appRecDTO.address3)
                    setAddress3InputCount(response.data[0].appRecDTO.address3.length)
                    setCity(response.data[0].appRecDTO.city)
                    setCityInputCount(response.data[0].appRecDTO.city.length)

                    setOrderSeqnum(response.data[0].orderdtDTO.seqnum)
                })
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    })

    useEffect(() => {
        selectPayinfoDetail();
        selectAllCountry();
    }, [])

    const validation = () => {
        if (!contentsName) setContentsNameError(true);
        if (!totalQuantity) setTotalQuantityError(true);
        if (!contentsDollar) setContentsDollarError(true);
        if (insuranceStatus && !contentsWon) setContentsWonError(true);
        return !!(contentsName && totalQuantity && contentsDollar && (!insuranceStatus || (insuranceStatus && contentsWon)));
    }

    const modifyCustoms = (async () => {
        if (isBtDisabled) return;
        setIsBtDisabled(true);
        setIsCustomBtLoading(true);

        try {
            await axios({
                method: 'post',
                url: '/order/modifyCustoms/',
                data: {
                    seqNum: contentsSeqnum,
                    custContent: contentsName,
                    custQty: totalQuantity,
                    custPrice: contentsDollar,
                    insrFl: insuranceStatus,
                    insrPrice: contentsWon === '' ? 0 : contentsWon
                },
                headers: {
                    Authorization: LCAT ? LCAT : SSAT
                },
                params: {
                    paySeqnum: paySeqnum,
                }
            })
                .then((response)=> {
                    setCtSaveDoneMessage(true);
                    setTimeout(() => setCtSaveDoneMessage(false), 1500);
                    updatePayinfoList(response.data[0]);
                    setPayList(response.data)
                    setIsBtDisabled(false);
                    setIsCustomBtLoading(false);
                })
                .catch((error)=> {
                    console.error(error)
                    setCtSaveFailMessage(true);
                    setTimeout(() => setCtSaveFailMessage(false), 1500);
                    setIsBtDisabled(false);
                    setIsCustomBtLoading(false);
                })
        } catch (error) {
            console.error(error)
            setCtSaveFailMessage(true);
            setTimeout(() => setCtSaveFailMessage(false), 1500);
            setIsBtDisabled(false);
            setIsCustomBtLoading(false);
        } finally {
            setIsBtDisabled(false);
            setIsCustomBtLoading(false);
        }
    })

    const valiAndCustomSave = async (e) => {
        validation();
        if (validation()) {
            await modifyCustoms(e);
        }
    }

    // 여기서부터 국가 커스텀셀렉트
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(!countryShow);
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setCountryShow(false);
        setCountryError(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const selectAllCountry = (() => {
        axios({
            method: 'get',
            url: '/order/selectCountry',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                // const countries = response.data.map(country => ({
                //     value: country.countryCode,
                //     label: country.countryNm + '(' + country.countryEngNm + ')'
                // }));
                // setCountryList(countries);
                const countries = response.data
                    .map(country => ({
                        value: country.countryCode,
                        label: `${country.countryNm}(${country.countryEngNm})`,
                        engName: country.countryEngNm
                    }))
                    .sort((a, b) => a.engName.localeCompare(b.engName));

                setCountryList(countries.map(country => ({
                    value: country.value,
                    label: country.label
                })));
            })
            .catch((error) => {
                console.log(error)
            })
    })

    const address1Length = selectedShip === "EMS" ? 200 :
        selectedShip === "UPS" ? 200 :
            selectedShip === "Small-Packet" ? 200 :
                selectedShip === "K-Packet" ? 200 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 200 :
                                selectedShip === "국내배송" ? 100 : 35;
    const address2Length = selectedShip === "EMS" ? 50 :
        selectedShip === "UPS" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;
    const address3Length = selectedShip === "EMS" ? 50 :
        selectedShip === "UPS" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;
    const cityLength = selectedShip === "EMS" ? 50 :
        selectedShip === "UPS" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" ? 35 :
                            selectedShip === "SHIP" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;

    const validTextTaxid = ((e) => {
        let taxidRegex;
        taxidRegex = /^[a-zA-Z0-9\s\-.,]{2,50}$/; /*2~50자*/
        if (!e.target.value || taxidRegex.test(e.target.value)) setTaxidError(false);
        else setTaxidError(true);
        setTaxid(e.target.value);
    })
    const validTextRecname = ((e) => {
        const recnameRegex = /^[a-zA-Z ]{2,25}/; /*영문 2~25자*/
        if (!e.target.value || recnameRegex.test(e.target.value)) setRecnameError(false);
        else setRecnameError(true);
        setRecname(e.target.value);
    })
    const validTextAddr1 = ((e) => {
        const Addr1Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address1Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr1Regex.test(e.target.value)) setAddress1Error(false);
        else setAddress1Error(true);
        setAddress1(e.target.value);
    })
    const validTextAddr2 = ((e) => {
        const Addr2Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address2Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr2Regex.test(e.target.value)) setAddress2Error(false);
        else setAddress2Error(true);
        setAddress2(e.target.value);
    })
    const validTextAddr3 = ((e) => {
        const Addr3Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address3Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr3Regex.test(e.target.value)) setAddress3Error(false);
        else setAddress3Error(true);
        setAddress3(e.target.value);
    })
    const validTextCity = ((e) => {
        const cityRegex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${cityLength}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || cityRegex.test(e.target.value)) setCityError(false);
        else setCityError(true);
        setCity(e.target.value);
    })
    const validTextZipcode = ((e) => {
        const zipcodeRegex = /^[a-zA-Z\d- ]{3,10}$/; // 암호화 // 영문 또는 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || zipcodeRegex.test(e.target.value)) setZipcodeError(false);
        else setZipcodeError(true);
        setZipcode(e.target.value);
    })
    const validTextTelnum = ((e) => {
        const telnumRegex = /^[\d-]{5,15}$/; // 암호화 // 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || telnumRegex.test(e.target.value)) setTelnumError(false);
        else setTelnumError(true);
        setTelnum(e.target.value);
    })

    const nameInputLengthCheck = (e) => {
        setNameInputCount(e.target.value.length);
    };

    const telnumInputLengthCheck = (e) => {
        setTelnumInputCount(e.target.value.length);
    };

    const zipcodeInputLengthCheck = (e) => {
        setZipcodeInputCount(e.target.value.length);
    };

    const address1InputLengthCheck = (e) => {
        setAddress1InputCount(e.target.value.length);
    };

    const address2InputLengthCheck = (e) => {
        setAddress2InputCount(e.target.value.length);
    };

    const address3InputLengthCheck = (e) => {
        setAddress3InputCount(e.target.value.length);
    };

    const cityInputLengthCheck = (e) => {
        setCityInputCount(e.target.value.length);
    };

    const nameInputRTCheck = (e) => {
        setNameInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const telnumInputRTCheck = (e) => {
        setTelnumInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const zipcodeInputRTCheck = (e) => {
        setZipcodeInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address1InputRTCheck = (e) => {
        setAddress1InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address2InputRTCheck = (e) => {
        setAddress2InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address3InputRTCheck = (e) => {
        setAddress3InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const cityInputRTCheck = (e) => {
        setCityInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const nameValid = (e) => {
        validTextRecname(e)
        nameInputLengthCheck(e);
        nameInputRTCheck(e)
    }

    const telnumValid = (e) => {
        validTextTelnum(e)
        telnumInputLengthCheck(e);
        telnumInputRTCheck(e)
    }

    const zipcodeValid = (e) => {
        validTextZipcode(e)
        zipcodeInputLengthCheck(e);
        zipcodeInputRTCheck(e)
    }

    const address1Valid = (e) => {
        validTextAddr1(e)
        address1InputLengthCheck(e);
        address1InputRTCheck(e)
    }

    const address2Valid = (e) => {
        validTextAddr2(e)
        address2InputLengthCheck(e);
        address2InputRTCheck(e)
    }

    const address3Valid = (e) => {
        validTextAddr3(e)
        address3InputLengthCheck(e);
        address3InputRTCheck(e)
    }

    const cityValid = (e) => {
        validTextCity(e)
        cityInputLengthCheck(e);
        cityInputRTCheck(e)
    }

    const recValidation = () => {
        let isValid = true;

        setTaxidError(false);
        setRecnameError(false);
        setAddress1Error(false);
        setCityError(false);
        setZipcodeError(false);
        setTelnumError(false);
        setCountryError(false);

        if (selectedCountry.value === "ID" && !taxid) {
            setTaxidError(true);
            isValid = false;
        }
        if (selectedCountry.value === t(`ordCrLabel`) || !selectedCountry) {
            setCountryError(true);
            isValid = false;
        }
        if (!recname) {
            setRecnameError(true);
            isValid = false;
        }
        if (!address1) {
            setAddress1Error(true);
            isValid = false;
        }
        if (!city) {
            setCityError(true);
            isValid = false;
        }
        if (!zipcode) {
            setZipcodeError(true);
            isValid = false;
        }
        if (!telnum) {
            setTelnumError(true);
            isValid = false;
        }
        return isValid;
    };

    const valiAndApprecSave = async (e) => {
        const isValid = recValidation();
        if (isValid) {
            await modifyApprec(e);
        }
    };

    const modifyApprec = (async () => {
        if (isBtDisabled) return;
        setIsBtDisabled(true);
        setIsRecinfoBtLoading(true);
        try {
            await axios({
                method: 'post',
                url: '/order/modifyApprec/',
                data: {
                    seqnum: appRecSeqnum,
                    crCode: selectedCountry.value,
                    taxid: taxid,
                    recname: recname,
                    telnum: telnum,
                    zipcode: zipcode,
                    address1: address1,
                    address2: address2,
                    address3: address3,
                    city: city,
                },
                headers: {
                    Authorization: LCAT ? LCAT : SSAT
                },
                params: {
                    mbNum: memberInfo.mbNum
                }
            })
                .then((response)=> {
                    if (pathComponent === "/UserMyPg" || pathComponent === "/btcorpad") {
                        updateAppRecinfo(response.data);
                    }
                    setRecSaveDoneMessage(true);
                    setTimeout(() => setRecSaveDoneMessage(false), 1500);
                    setIsBtDisabled(false);
                    setIsRecinfoBtLoading(false);
                })
                .catch((error)=> {
                    console.log("수취인정보 에러 : ", error)
                    setRecSaveFailMessage(true);
                    setTimeout(() => setRecSaveFailMessage(false), 1500);
                    setIsBtDisabled(false);
                    setIsRecinfoBtLoading(false);
                })
        } catch (error) {
            console.log("수취인정보 에러 : ", error)
            setRecSaveFailMessage(true);
            setTimeout(() => setRecSaveFailMessage(false), 1500);
            setIsBtDisabled(false);
            setIsRecinfoBtLoading(false);
        } finally {
            setIsBtDisabled(false);
            setIsRecinfoBtLoading(false);
        }
    })


    const [deliveryDetailAddrSt, setDeliveryDetailAddrSt] = useState(false);

    const deliveryDetailAddrFalseFn = (() => {
        setDeliveryDetailAddrSt(false);
    })

    const addrChoiceData = ((addrSeqnum) => {
        axios({
            method: 'get',
            url: '/member/choiceAddrInfo',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                seqnum: addrSeqnum
            }
        })
            .then((response) => {
                const countryData = {
                    value: response.data.countryDTO.countryCode,
                    label: response.data.countryDTO.countryNm + '(' + response.data.countryDTO.countryEngNm + ')'
                };
                setSelectedCountry(countryData)
                setTaxid(response.data.taxid);
                setRecname(response.data.recname)
                setAddress1(response.data.address1)
                setAddress2(response.data.address2)
                setAddress3(response.data.address3)
                setCity(response.data.city)
                setZipcode(response.data.zipcode)
                setTelnum(response.data.telnum)
                setNameInputCount(response.data.recname.length)
                setAddress1InputCount(response.data.address1.length)
                setAddress2InputCount(response.data.address2.length)
                setAddress3InputCount(response.data.address3.length)
                setCityInputCount(response.data.city.length)
                setZipcodeInputCount(response.data.zipcode.length)
                setTelnumInputCount(response.data.telnum.length)
                deliveryDetailAddrFalseFn();
            })
    })

    const [deliveryDetailPayMove, setDeliveryDetailPayMove] = useState(false);
    const deliveryDetailPayMsg1 = t(`dpdMdMsg1`);
    const deliveryDetailPayMsg2 = t(`dpdMdMsg2`);

    const moveDeliveryPay = (() => {
        if (payList.length > 0) {
            navigate(`/UserMyOrderPg/${payList[0].orderdtDTO.orderNumber}`);
        }
    })

    const MAddrAddInput = (isEssential,
                           title,
                           value,
                           maxLength,
                           ref,
                           onChange,
                           showLength,
                           length,
                           lengthLimit,
                           disabled) => {
        return (
            <div className="m-a-delivery-price-input-box">
                {value.length > 0 &&
                    <div className={'m-a-delivery-price-input-title'}>
                        {isEssential &&
                            <div className={'m-a-delivery-price-input-essential'}>* &nbsp;</div>
                        }
                        {title}
                    </div>
                }
                {value.length === 0 && isEssential &&
                    <div className={'m-a-delivery-price-input-essential'}>* &nbsp;</div>}
                        <input className={'m-a-delivery-price-input wd-83pc'} type="text"
                               value={value} maxLength={maxLength}
                               ref={ref}
                               placeholder={value.length === 0 ? title : ''}
                               onChange={onChange}
                               disabled={disabled}/>
                {showLength &&
                    <div className={'m-a-delivery-price-input-text wd-17pc p-t-11'}>
                        <div className={'m-a-delivery-price-input-text highlight'}>{length}</div>
                        / {lengthLimit}
                    </div>
                }
            </div>
        )
    }

    return (
        <div className="m-a-delivery-price-detail-background font-t-cont">
            <div className="m-a-delivery-price-detail-container">
                <div className={'m-a-delivery-price-detail-header'}>
                    <div className={'m-a-delivery-price-detail-header-title font-t-title'}>
                        {deliveryDetailSt ? t(`dpdHeader1`) : t(`dpdHeader2`)}
                    </div> {/*배송비상세*/}
                    <img className="m-a-delivery-price-detail-header-icon" onClick={deliveryDetailSt ? deliveryDetailFalseFn : closeDetail} src={commonClose} alt={''}/>
                </div>
                <div className="m-a-delivery-price-detail-body">
                    <div className={'m-a-delivery-price-detail-table-title'}>
                        <img className={'m-a-delivery-price-detail-table-icon'} src={commonHether} alt={''}/>
                        {t(`modRefInfo`)} {/*수취인정보*/}
                        <span className={'dt-price-primary'}>({t(`essential`)})</span> {/*필수*/}
                    </div>
                    <div className={'m-a-delivery-price-add-content'}>
                        <div className={'m-a-delivery-price-add-country-select-box'}>
                            <div className={'m-a-delivery-price-add-country-select'} ref={countryRef} onClick={toggleCountryShow}>
                                {selectedCountry.label}
                                {countryShow && (
                                    <button className={'m-a-delivery-price-add-country-list'} disabled={!(payList[0].payStatus === "PS0" || adminToken)}>
                                        {countryList.map((country, index) => (
                                            <div className={'m-a-delivery-price-add-country-item'} key={index} onClick={() => handleCountryChange(country)}>
                                                {country.label}
                                            </div>
                                        ))}
                                    </button>
                                )}
                            </div>
                            <button className={'m-a-delivery-price-add-country-button'} onClick={() => setDeliveryDetailAddrSt(true)}
                                    disabled={!(payList[0].payStatus === "PS0" || adminToken)}>
                                {t(`rmmBookBt`)}
                            </button> {/*주소록*/}
                        </div>
                        {countryError &&
                            <div className="m-a-delivery-price-add-error">{t(`recCrErr`)}</div>
                        }
                        {selectedCountry.value === "ID" ?
                            <>
                                {MAddrAddInput(true,
                                    'Tax ID',
                                    taxid,
                                    5,
                                    null,
                                    validTextTaxid,
                                    false, 0, 0)}
                            </>
                            : null}
                        {taxidError &&
                            <div className="m-a-delivery-price-add-error" >{t(`recTaxIdErr`)}</div>
                        }
                        {MAddrAddInput(true,
                            t(`joinLabel5`),
                            recname,
                            25,
                            null,
                            nameValid,
                            selectedCountry.value !== "KR", nameInputCount, 25)}
                        {selectedCountry.value !== "KR" && recnameError &&
                            <div className="m-a-delivery-price-add-error">{t(`recNmErr`)}</div>
                        }
                        {MAddrAddInput(true,
                            selectedCountry.value === "KR" ? t(`modSmallMenu14`) : t(`modSmallMenu7`),
                            address1,
                            selectedCountry.value !== "KR" ? address1Length : null,
                            null,
                            address1Valid,
                            selectedCountry.value !== "KR", address1InputCount, address1Length)}
                        {selectedCountry.value !== "KR" && address1Error &&
                            <div className="m-a-delivery-price-add-error">
                                {`${t("recAddrErr1")} 2~${address1Length}${t("recAddrErr2")}`}
                            </div>
                        }
                        {selectedCountry.value !== "KR" && (
                            <>
                                {/*주소2*/}
                                {MAddrAddInput(false,
                                    t(`modSmallMenu8`),
                                    address2,
                                    address2Length,
                                    null,
                                    address2Valid,
                                    true, address2InputCount, address2Length)}
                                {address2Error &&
                                    <div className="m-a-delivery-price-add-error">
                                        {`${t("recAddrErr1")} 2~${address2Length}${t("recAddrErr2")}`}
                                    </div>
                                }
                                {/*주소3*/}
                                {MAddrAddInput(false,
                                    t(`modSmallMenu9`),
                                    address3,
                                    address3Length,
                                    null,
                                    address3Valid,
                                    true, address3InputCount, address3Length)}
                                {address3Error &&
                                    <div className="m-a-delivery-price-add-error">
                                        {`${t("recAddrErr1")} 2~${address3Length}${t("recAddrErr2")}`}
                                    </div>
                                }
                                {/*도시명*/}
                                {MAddrAddInput(true,
                                    t(`modSmallMenu10`),
                                    city,
                                    cityLength,
                                    null,
                                    cityValid,
                                    true, cityInputCount, cityLength)}
                                {cityError &&
                                    <div className="m-a-delivery-price-add-error">
                                        {`${t("recAddrErr1")} 2~${cityLength}${t("recAddrErr2")}`}
                                    </div>
                                }
                                {/*우편번호*/}
                                {MAddrAddInput(true,
                                    t(`modNbInfo8`),
                                    zipcode,
                                    10,
                                    null,
                                    zipcodeValid,
                                    true, zipcodeInputCount, 10)}
                                {zipcodeError &&
                                    <div className="m-a-delivery-price-add-error">
                                        {t("recZipErr")}
                                    </div>
                                }
                            </>
                        )}
                        {/*연락처*/}
                        {MAddrAddInput(true,
                            t(`modNbInfo5`),
                            telnum,
                            15,
                            null,
                            telnumValid,
                            selectedCountry.value !== "KR", telnumInputCount, 15)}
                        {selectedCountry.value !== "KR" && telnumError &&
                            <div className="m-a-delivery-price-add-error">
                                {t("recTellErr")}
                            </div>
                        }
                        <style>
                            {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                        </style>
                        <button className="admin-btn-A wd-80 ht-35 font-s-12 m-t-10"
                                onClick={valiAndApprecSave}
                                disabled={isBtDisabled || (!adminToken && payList[0].payStatus !== "PS0")}>
                            {isRecinfoBtLoading ? (
                                <div className="smallSpinner m-0-a"></div>
                            ) : (
                                <div>{t(`saveBt`)}</div>
                            )}
                        </button>
                    </div>
                </div>
                {!payList[0].ttDomPrice > 0 &&
                    <div className={'m-a-delivery-price-detail-body'}>
                        <div className={'m-a-delivery-price-detail-table-title'}>
                            <img className={'m-a-delivery-price-detail-table-icon'} src={commonHether} alt={''}/>
                            {t(`dpdHeader3`)} {/*세관신고 & 보험*/}
                            <span className="dt-price-primary">({t(`essential`)})</span>
                        </div>
                        <div className={'m-a-delivery-price-add-content'}>
                            {MAddrAddInput(true,
                                t(`dpdHeader4`),
                                contentsName,
                                50,
                                null,
                                contentsNameValidCheck,
                                true, contentsNameCount, 35,
                                !(payList[0].payStatus === "PS0" || adminToken)
                            )}
                            {contentsNameError &&
                                <div className="m-a-delivery-price-add-error">{t(`dpdErr1`)}</div>
                            }
                            {MAddrAddInput(true,
                                t(`dpdHeader5`),
                                totalQuantity,
                                5,
                                null,
                                validTotalQuantity,
                                false, 0, 0,
                                !(payList[0].payStatus === "PS0" || adminToken))}
                            {totalQuantityError &&
                                <div className="m-a-delivery-price-add-error">{t(`dpdErr2`)}</div>
                            }
                            {MAddrAddInput(true,
                                t(`dpdHeader6`) + '(USD)',
                                contentsDollar ? parseInt(contentsDollar).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                                4,
                                null,
                                validContentsDollar,
                                false, 0, 0,
                                !(payList[0].payStatus === "PS0" || adminToken))}
                            {contentsDollarError &&
                                <div className="m-a-delivery-price-add-error">{t(`dpdErr3`)}</div>
                            }
                            <div className="m-a-delivery-price-divider"/>
                            <div className={'m-a-delivery-price-sub-title'}>{t(`dpdHeader7`)}</div>
                            <div className={'m-a-delivery-price-row'}>
                                <div className={'m-a-delivery-price-row-item'}>
                                    <input className="radioButton" type="radio" name="insurance"
                                           checked={!insuranceStatus}
                                           onChange={() => setInsuranceStatus(false)}
                                           onClick={insrReset}
                                           style={{height: "15px"}}
                                           disabled={!(payList[0].payStatus === "PS0" || adminToken)}/>
                                    {t(`dpdLabel1`)}
                                </div>
                                <div className={'m-a-delivery-price-row-item'}>
                                    <input className="radioButton" type="radio" name="insurance"
                                           checked={insuranceStatus}
                                           onChange={() => setInsuranceStatus(true)}
                                           style={{height: "15px"}}
                                           disabled={!(payList[0].payStatus === "PS0" || adminToken)}/>
                                    {t(`dpdLabel2`)}
                                </div>
                            </div>
                            {MAddrAddInput(false,
                                t(`dpdHeader8`) + '(KRW)',
                                contentsWon ? parseInt(contentsWon).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                                8,
                                null,
                                validContentsWon,
                                false, 0, 0,
                                !insuranceStatus || !(payList[0].payStatus === "PS0" || adminToken))}
                            {contentsWonError &&
                                <div className="m-a-delivery-price-add-error">{t(`dpdErr4`)}</div>
                            }
                            {ctSaveDoneMessage &&
                                <div className="m-a-delivery-price-add-error">{t(`dpdSvMsg1`)}</div>
                            }
                            {ctSaveFailMessage &&
                                <div className="m-a-delivery-price-add-error">{t(`dpdSvMsg2`)}</div>
                            }
                            <style>
                                {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                            </style>
                            <button className="admin-btn-A wd-80 ht-35 font-s-12 m-t-10" onClick={valiAndCustomSave} disabled={isBtDisabled || (!adminToken && payList[0].payStatus !== "PS0")}>
                                {isCustomBtLoading ? (
                                    <div className="smallSpinner m-0-a"></div>
                                ) : (
                                    <div>{t(`saveBt`)}</div>
                                )}
                            </button> {/*저장*/}
                        </div>
                    </div>
                }
                <div className={'m-a-delivery-price-detail-body'}>
                    <div className={'m-a-delivery-price-detail-table-title'}>
                        <img className={'m-a-delivery-price-detail-table-icon'} src={commonHether} alt={''}/>
                        {t(`dpdHeader9`)} {/*무게정보*/}
                    </div>
                    <div className={'m-a-delivery-price-add-content'}>
                        <div className={'m-a-delivery-price-row-space-between'}>
                            <div className={'m-a-delivery-price-row-space-between-item'}>{t(`realWt`)}</div>
                            <div className={'m-a-delivery-price-row-space-between-item'}>{shipInfoList.weight}kg</div>
                        </div>
                        <div className={'m-a-delivery-price-row-space-between'}>
                            <div className={'m-a-delivery-price-row-space-between-item'}>
                                {t(`volWt`)}[{shipInfoList.wid}cm*{shipInfoList.ver}cm*{shipInfoList.hei}cm]
                            </div>
                            <div className={'m-a-delivery-price-row-space-between-item'}>
                                {payList[0]?.orderdtDTO?.shipco === "FedEx" || payList[0]?.orderdtDTO?.shipco === "DHL" ?
                                    shipInfoList.fedexWeight : shipInfoList.emsWeight}kg
                            </div>
                        </div>
                        <div className={'m-a-delivery-price-row-space-between'}>
                            <div className={'m-a-delivery-price-row-space-between-item'}>{t(`appWt`)}</div>
                            <div className={'m-a-delivery-price-row-space-between-item primary'}>
                                {payList[0]?.orderdtDTO?.shipco === "FedEx" || payList[0]?.orderdtDTO?.shipco === "DHL" ?
                                    shipInfoList.fedexApyWeight : shipInfoList.emsApyWeight}kg
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'m-a-delivery-price-divider'}/>
                <div className={'m-a-delivery-price-detail-body'}>
                    <div className={'m-a-delivery-price-detail-table-title'}>
                        <img className={'m-a-delivery-price-detail-table-icon'} src={commonHether} alt={''}/>
                        {t(`modPlHeader3`)} {/*해외배송비*/}
                    </div>
                    <div className={'m-a-delivery-price-add-content'}>
                        {/*FedEx*/}
                        {payList[0]?.ttFedexPrice > 0 && (
                            <div className={'m-a-delivery-price-add-content flex-start'}>
                                <div className='m-a-delivery-price-delivery-agency-frame'>
                                    <img className='m-a-delivery-price-delivery-agency' src={FedexLogo} alt={''}/>
                                </div>
                                <div className={'m-a-delivery-price-divider'}/>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`modPlHeader3`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.fedexPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {t(`fuelScg`)} {/*유류할증료*/}
                                    </div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.fedexFuelSurch?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`deliveryFee`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.fedexAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`etcArea`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.fedexOutOfArea?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`insrFee`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.fedexInsrFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`modSHeader7`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {totalFedexExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`prodTrNum`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item font-c-progress font-b'}>
                                        {shipInfoList.outWayNum || ''}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`deliveryFee`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item primary'}>
                                        {payList[0]?.fedexAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/*UPS*/}
                        {payList[0]?.ttUpsPrice > 0 && (
                            <div className={'m-a-delivery-price-add-content flex-start'}>
                                <div className='m-a-delivery-price-delivery-agency-frame' style={{padding: "3px"}}>
                                    <img className='m-a-delivery-price-delivery-agency' src={upsLogo} alt={''}/>
                                </div>
                                <div className={'m-a-delivery-price-divider'}/>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div
                                        className={'m-a-delivery-price-row-space-between-item'}>{t(`modPlHeader3`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.upsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {t(`fuelScg`)}
                                    </div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.upsFuelSurch?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {t(`deliveryFee`)}
                                    </div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.upsAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`etcArea`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.upsOutOfArea?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`insrFee`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item '}>
                                        {payList[0]?.upsInsrFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div
                                        className={'m-a-delivery-price-row-space-between-item'}>{t(`modSHeader7`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item primary'}>
                                        {totalUpsExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`prodTrNum`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item font-c-progress font-b'}>
                                        {shipInfoList.outWayNum || ''}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/*DHL*/}
                        {payList[0]?.ttDhlPrice > 0 && (
                            <div className={'m-a-delivery-price-add-content flex-start'}>
                                <div className='m-a-delivery-price-delivery-agency-frame'>
                                    <img className='m-a-delivery-price-delivery-agency'
                                         style={{width: "60px", height: "40px"}} src={dhlLogo} alt={''}/>
                                </div>
                                <div className={'m-a-delivery-price-divider'}/>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`modPlHeader3`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.dhlPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {t(`deliveryFee`)}
                                    </div>
                                    <div className={'m-a-delivery-price-rowv-item'}>
                                        {payList[0]?.dhlAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`insrFee`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item '}>
                                        {payList[0]?.dhlInsrFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`modSHeader7`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item primary'}>
                                        {totalDhlExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`prodTrNum`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item font-c-progress font-b'}>
                                        {shipInfoList.outWayNum || ''}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/*EMS*/}
                        {payList[0]?.ttEmsPrice > 0 && (
                            <div className={'m-a-delivery-price-add-content flex-start'}>
                                <div className='m-a-delivery-price-delivery-agency-frame'>
                                    <img className='m-a-delivery-price-delivery-agency' src={EmsLogo} alt={''}/>
                                </div>
                                <div className={'m-a-delivery-price-divider'}/>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`modPlHeader3`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.emsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {t(`deliveryFee`)}
                                    </div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.emsAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`insrFee`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item '}>
                                        {payList[0]?.emsInsrFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`modSHeader7`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item primary'}>
                                        {totalEmsExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`prodTrNum`)}</div>
                                    <div className="m-a-delivery-price-row-space-between-item font-c-progress font-b">
                                        {shipInfoList.outWayNum || ''}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/*K-Packet*/}
                        {payList[0]?.ttKPackPrice > 0 && (
                            <div className={'m-a-delivery-price-add-content flex-start'}>
                                <div className='m-a-delivery-price-delivery-agency-frame'>
                                    <img className='m-a-delivery-price-delivery-agency'
                                         style={{width: "70px", height: "35px"}} src={kPacket} alt={''}/>
                                </div>
                                <div className={'m-a-delivery-price-divider'}/>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`modPlHeader3`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.kPackPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {t(`deliveryFee`)}
                                    </div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.kPackAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`insrFee`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item '}>
                                        {payList[0]?.kPackInsrFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`modSHeader7`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item primary'}>
                                        {totalKpackExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`prodTrNum`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item font-c-progress font-b'}>
                                        {shipInfoList.outWayNum || ''}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/*항공소형포장물*/}
                        {payList[0]?.ttAirSPrice > 0 && (
                            <div className={'m-a-delivery-price-add-content flex-start'}>
                                <div className='m-a-delivery-price-delivery-agency-frame' style={{padding:"3px"}}>
                                    <img className='m-a-delivery-price-delivery-agency' src={smallPacket} alt={''}/>
                                </div>
                                <div className={'m-a-delivery-price-divider'}/>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`modPlHeader3`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.airSPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {t(`deliveryFee`)}
                                    </div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.airSAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`insrFee`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item '}>
                                        {payList[0]?.airSInsrFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`modSHeader7`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item primary'}>
                                        {totalAirSExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`prodTrNum`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item font-c-progress font-b'}>
                                        {shipInfoList.outWayNum || ''}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/*선편*/}
                        {payList[0]?.ttSeaPrice > 0 && (
                            <div className={'m-a-delivery-price-add-content flex-start'}>
                                <div className='m-a-delivery-price-delivery-agency-frame' style={{padding:"3px"}}>
                                    <img className='m-a-delivery-price-delivery-agency' src={SeaShipLogo} alt={''}/>
                                </div>
                                <div className={'m-a-delivery-price-divider'}/>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`modSHeader6`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.seaPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {t(`deliveryFee`)}
                                    </div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {payList[0]?.seaAddFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`insrFee`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item '}>
                                        {payList[0]?.seaInsrFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`modSHeader7`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item primary'}>
                                        {totalSeaExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`prodTrNum`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item font-c-progress font-b'}>
                                        {shipInfoList.outWayNum || ''}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/*국내배송*/}
                        {totalDomExpense > 0 && (
                            <div className={'m-a-delivery-price-add-content dt-price-flex-start'}>
                                <div className={'m-a-delivery-price-delivery-agency full'}>{t(`domesticTitle`)}</div>
                                <div className={'m-a-delivery-price-divider'}/>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>{t(`ttDmShipPr`)}</div>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {totalDomExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                                <div className={'m-a-delivery-price-row-space-between'}>
                                    <div className={'m-a-delivery-price-row-space-between-item'}>
                                        {t(`prodTrNum`)}
                                    </div>
                                    <div className={'m-a-delivery-price-row-space-between-item font-c-progress font-b'}>
                                        {shipInfoList.outWayNum || ''}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={'m-a-delivery-price-add-pay-frame'}>
                        <div className={'m-a-delivery-price-delivery-sub-title'}>{t(`modHeader10`)}</div>
                        <div className={'m-a-delivery-price-divider'}/>
                        <div className={'m-a-delivery-price-row-space-between'}>
                            <div className={'m-a-delivery-price-row-space-between-item'}>
                                {t(`myUseDep`)}
                            </div>
                            <div className={'m-a-delivery-price-row-space-between-item'}>
                                {payList[0]?.depUsePrice !== 0 ?
                                    <span>- {payList[0]?.depUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : 0}
                            </div>
                        </div>
                        <div className={'m-a-delivery-price-row-space-between'}>
                            <div className={'m-a-delivery-price-row-space-between-item'}>
                                {t(`myUsePt`)}
                            </div>
                            <div className={'m-a-delivery-price-row-space-between-item'}>
                                {payList[0]?.pointUsePrice !== 0 ?
                                    <span>- {payList[0]?.pointUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : 0}
                            </div>
                        </div>
                        <div className={'m-a-delivery-price-row-space-between'}>
                            <div className={'m-a-delivery-price-row-space-between-item'}>
                                {t(`dpdCpNm`)}
                            </div>
                            <div className={'m-a-delivery-price-row-space-between-item'}>
                                {payList[0]?.couponUsePrice !== 0 ?
                                    <span>- {payList[0]?.couponUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : 0}
                            </div>
                        </div>
                        <div className={'m-a-delivery-price-divider'}/>
                        <div className={'m-a-delivery-price-row-space-between'}>
                            <div className={'m-a-delivery-price-delivery-sub-title'}>{t(`modFinalPay`)}</div> {/*최종결제금액*/}
                            <div className={'m-a-delivery-price-delivery-sub-title dt-price-primary'}>
                                {/*{payList[0]?.payStatus === "PS0" ? 0 : payList[0]?.lastPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*/}
                                {payList[0]?.payStatus === "PS0"
                                    ? 0
                                    : [
                                        payList[0]?.depUsePrice || 0,
                                        payList[0]?.pointUsePrice || 0,
                                        payList[0]?.couponUsePrice || 0
                                    ]
                                        .reduce((sum, value) => sum + value, 0)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="m-a-delivery-price-detail-body">
                    <div className={'m-a-delivery-price-detail-table-title'}>
                        <img className={'m-a-delivery-price-detail-table-icon'} src={commonHether} alt={''}/>
                        {t(`appReq`)} {/*적용요청사항*/}
                    </div>
                    <div className="wd-100pc display-flex flex-row ht-auto p-b-5">
                        <div className="m-a-delivery-price-req-label">{t(`reqOp`)}</div> {/*요청옵션*/}
                        <div className="m-a-delivery-price-req-value">
                            {appReqList.tagRemove &&
                                <span style={{display: "flex"}}>- {t(`modReqCont1`)}</span>
                            }
                            {appReqList.shoesBoxRemove &&
                                <span style={{display: "flex"}}>- {t(`modReqCont2`)}</span>
                            }
                            {appReqList.hangerRemove &&
                                <span style={{display: "flex"}}>- {t(`modReqCont3`)}</span>
                            }
                            {appReqList.compressionPack &&
                                <span style={{display: "flex"}}>- {t(`modReqCont4`)}</span>
                            }
                            {appReqList.prodBoxRemove &&
                                <span style={{display: "flex"}}>- {t(`modReqCont5`)}</span>
                            }
                            {appReqList.packingHold &&
                                <span style={{display: "flex"}}>- {t(`modReqCont6`)}</span>
                            }
                            {appReqList.shipPriceDeposipay &&
                                <span style={{display: "flex"}}>- {t(`modReqCont7`)}</span>
                            }
                            {appReqList.shipPriceMessenger &&
                                <span style={{display: "flex"}}>- {t(`modReqCont8`)}</span>
                            }
                        </div>
                    </div>
                    <div className="wd-100pc display-flex flex-row ht-auto">
                        <div className="m-a-delivery-price-req-label border-top-bt">{t(`modSmallMenu13`)}</div> {/*상세요청*/}
                        <div className="m-a-delivery-price-req-value border-top-bt">{appReqList.detailReq}</div>
                    </div>
                </div>
                <div className="m-a-delivery-price-detail-body">
                    <div className={'m-a-delivery-price-detail-table-title'}>
                        <img className={'m-a-delivery-price-detail-table-icon'} src={commonHether} alt={''}/>
                        {t(`packList`)} {/*패킹리스트*/}
                    </div>
                    <div className="m-a-delivery-price-add-packing-table p-b-5">
                        <div className="m-a-delivery-price-add-packing-table-row">
                            <div className={"m-a-delivery-price-add-packing-table-row-item sm"}/>
                            <div className={'m-a-delivery-price-add-packing-table-row-item lg label'}>
                                {t(`prodNm`)}</div>
                            <div className={'m-a-delivery-price-add-packing-table-row-item label'}>
                                {t(`prodOp`)}</div>
                            <div className={'m-a-delivery-price-add-packing-table-row-item label'}>
                                {t(`prodQy`)}</div>
                            <div className={'m-a-delivery-price-add-packing-table-row-item label'}>
                                {t(`prodPr`)}</div>
                        </div>
                        {packList && packList.map((pack, index) => (
                            <div className="m-a-delivery-price-add-packing-table-row" key={index}>
                                <div className={"m-a-delivery-price-add-packing-table-row-item sm"}>
                                    {index + 1}</div>
                                <div className={'m-a-delivery-price-add-packing-table-row-item lg'}>
                                    {pack.name}</div>
                                <div className={'m-a-delivery-price-add-packing-table-row-item'}>
                                    {pack.option}</div>
                                <div className={'m-a-delivery-price-add-packing-table-row-item'}>
                                    {pack.quantity?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                <div className={'m-a-delivery-price-add-packing-table-row-item'}>
                                    {pack.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="pd12 wd-100pc">
                    {/*결제하기 버튼*/}
                    {pathComponent === "/UserMyPg" && (
                        <button className="admin-btn-C wd-100pc ht-45 font-s-13" onClick={() => setDeliveryDetailPayMove(true)}>{t(`payment`)}</button>
                    )}
                </div>
            </div>
            {
                deliveryDetailAddrSt ?
                    <MAddrBookPg
                        deliveryDetailAddrSt={deliveryDetailAddrSt}
                        deliveryDetailAddrFalseFn={deliveryDetailAddrFalseFn}
                        addrChoiceData={addrChoiceData}/>
                    :
                    deliveryDetailPayMove ?
                        <MsgSelectModal deliveryDetailPayMove={deliveryDetailPayMove}
                                        setDeliveryDetailPayMove={setDeliveryDetailPayMove}
                                        moveDeliveryPay={moveDeliveryPay}
                                        deliveryDetailPayMsg1={deliveryDetailPayMsg1}
                                        deliveryDetailPayMsg2={deliveryDetailPayMsg2}/>
                        :
                        isLoading ?
                            <CommonLoading/>
                            :
                            null
            }
        </div>
    )
}

export default MDeliveryPriceDetail;
