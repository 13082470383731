import React, {useState} from 'react';
import '../../css/web/UserMainPg.css';
import {Route, Routes} from "react-router-dom";
import SectionMain from "../../components/web/layoutsuser/SectionMain";
import SideQuickMenu from "../../components/web/layoutsuser/SideQuickMenu";
import UserFooterMain from "../../components/web/layoutsuser/UserFooterMain";
import HeaderMain from "../../components/web/layoutsuser/HeaderMain";
import UserOrderPg from "./UserOrderPg";
import UserReviewPg from "./UserReviewPg";
import UserMyPg from "./UserMyPg";
import UserMyOrderPg from "./UserMyOrderPg";
import UserReviewWritePg from "./UserReviewWritePg";
import UserReviewDetailPg from "./UserReviewDetailPg";
import UserCompareShipPg from "./UserCompareShipPg";
import UserCustomInfoPg from "./UserCustomInfoPg";
import UserDepChargPg from "./UserDepChargPg";
import UserNoticePg from "./UserNoticePg";
import UserNoticeDtPg from "./UserNoticeDtPg";
import UserFaqPg from "./UserFaqPg";
import UserFaqDtPg from "./UserFaqDtPg";
import ErrorPathPg from "./ErrorPathPg";
import UserDepChargHistoryPg from "./UserDepChargHistoryPg";
import UserUsageGuidePg from "./UserUsageGuidePg";
import UserMailInquiryPg from "./UserMailInquiryPg";
import UserMailSendComplPg from "./UserMailSendComplPg";
import CommonTermsPopup from "../../components/web/modal/CommonTermsPopup";
import {Helmet} from "react-helmet-async";

function UserMainPg() {
    const [termsOfUsePopup, setTermsOfUsePopup] = useState(false);
    const [privacyPolicyPopup, setPrivacyPolicyPopup] = useState(false);

    const closeTermsOfUsePopup = () => setTermsOfUsePopup(false);
    const closePrivacyPolicyPopup = () => setPrivacyPolicyPopup(false);

    return (
        <>
        <Helmet>
            <link rel="icon" href={`${process.env.PUBLIC_URL}/bt_color_favicon.ico`} />
            <title>가장 저렴하고 빠른 해외배송 비토리지[Btorage]에서 다양한 혜택을 받으세요 | 구매대행 | 배송대행 | Btorage</title>
            <meta name="description" content="Btorage" />
            <meta name="color-scheme" content="light only"/>
        </Helmet>
        <div className="user">
            <div className="user-layout">
                <div className="user-header m-b-110">
                    <HeaderMain/>
                </div>
                <div className="user-body m-b-50">
                    <Routes>
                        <Route exact path="/" element={<SectionMain/>} /> {/*Main*/}
                        <Route path="/UserOrderPg" element={<UserOrderPg/>} /> {/*주문서 작성*/}
                        <Route path="/UserMyPg" element={<UserMyPg/>} /> {/*마이페이지*/}
                        <Route path="/UserMyOrderPg/:ordNum" element={<UserMyOrderPg/>} /> {/*마이 주문서페이지*/}
                        <Route path="/UserReviewPg" element={<UserReviewPg />} /> {/*이용후기*/}
                        <Route path="/UserReviewWritePg" element={<UserReviewWritePg />} /> {/*이용후기 작성페이지*/}
                        <Route path="/UserReviewDetailPg/:seqNum" element={<UserReviewDetailPg/>} /> {/*이용후기 작성완료 페이지*/}
                        <Route path="/UserCompareShipPg" element={<UserCompareShipPg />} /> {/*배송료비교 페이지*/}
                        <Route path="/UserUsageGuidePg" element={<UserUsageGuidePg />} /> {/*이용가이드 페이지*/}
                        <Route path="/UserCustomInfoPg" element={<UserCustomInfoPg />} /> {/*국가별통관정보 페이지*/}
                        <Route path="/UserDepChargPg" element={<UserDepChargPg />} /> {/*국가별통관정보 페이지*/}
                        <Route path="/UserDepChargHistoryPg" element={<UserDepChargHistoryPg />} /> {/*국가별통관정보 페이지*/}
                        <Route path="/UserNoticePg" element={<UserNoticePg />} /> {/*공지사항 페이지*/}
                        <Route path="/UserNoticeDtPg/:seqNum" element={<UserNoticeDtPg />} /> {/*공지사항 상세페이지*/}
                        <Route path="/UserFaqPg" element={<UserFaqPg />} /> {/*FAQ 페이지*/}
                        <Route path="/UserFaqDtPg/:seqNum" element={<UserFaqDtPg />} /> {/*FAQ 상세페이지*/}
                        <Route path="/UserMailInquiryPg" element={<UserMailInquiryPg />} /> {/*메일문의 페이지*/}
                        <Route path="/UserMailSendComplPg" element={<UserMailSendComplPg />} /> {/*메일문의 성공페이지*/}
                        <Route path="*" element={<ErrorPathPg/>}/>
                    </Routes>
                </div>
                <div className="user-quick-menu">
                    <SideQuickMenu/>
                </div>
            </div>
            <div className="user-footer">
                <UserFooterMain
                    openTermsPopup={() => setTermsOfUsePopup(true)}
                    openPrivacyPopup={() => setPrivacyPolicyPopup(true)}
                />
            </div>
            {termsOfUsePopup && (
                <CommonTermsPopup termsOfUsePopup={termsOfUsePopup} closeTermsOfUsePopup={closeTermsOfUsePopup}/>
            )}
            {privacyPolicyPopup && (
                <CommonTermsPopup privacyPolicyPopup={privacyPolicyPopup} closePrivacyPolicyPopup={closePrivacyPolicyPopup}/>
            )}
        </div>
        </>
    );
}

export default UserMainPg
