import '../../css/mobile/MUserOrderDetailPg.css';
import '../../css/mobile/MAdminOrderDetailPg.css';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {createRef, useCallback, useEffect, useMemo, useRef, useState} from "react";
import * as XLSX from "xlsx";
import excel from "exceljs";
import axios from "axios";
import {orderStatusUpdate} from "../../services/AutoChangeOrderSt";
import boxLogo from "../../assets/img/boxLogo.png";
import MHeader from "../../components/mobile/MHeader";
import detailpgOrdNum from "../../assets/img/detailpgOrdNum.png";
import detailCart from "../../assets/img/detailCart.png";
import MOrderSection from "../../components/mobile/MOrderSection";
import pwHide from "../../assets/img/pwHide.png";
import pwView from "../../assets/img/pwView.png";
import detailOrder from "../../assets/img/detailOrder.png";
import detailOffline from "../../assets/img/detailOffline.png";
import detailTransfer from "../../assets/img/detailTransfer.png";
import detailDomestic from "../../assets/img/detailDomestic.png";
import detailDelivery from "../../assets/img/detailDelivery.png";
import invoiceFormHeader from "../../assets/img/invoiceFormHeader.png";
import reqFormHeader from "../../assets/img/reqFormHeader.png";
import payFormHeader from "../../assets/img/payFormHeader.png";
import FedexLogo from "../../assets/img/FedexLogo.png";
import EmsLogo from "../../assets/img/EmsLogo.png";
import SeaShipLogo from "../../assets/img/SeaShipLogo.png";
import domLogo from "../../assets/img/detailDomestic.png";
import RecmodiModal from "../../components/web/modal/RecmodiModal";
import MsgConfirmModal from "../../components/web/modal/MsgConfirmModal";
import ProdPriceAdminAddDetail from "../../components/web/modal/ProdPriceAdminAddDetail";
import CommonLoading from "../../components/web/modal/CommonLoading";
import ChatFileDown from "../../components/web/modal/ChatFileDown";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import MProdPriceDetail from "../../components/mobile/MProdPriceDetail";
import MEtcPriceDetail from "../../components/mobile/MEtcPriceDetail";
import PaypalLogo from "../../assets/img/orderPaypal.png";
import WechatpayLogo from "../../assets/img/WechatPayLogo.png";
import AlipayLogo from "../../assets/img/AlipayLogo.png";
import NoBank from "../../assets/img/noBank.png";
import DepositPay from "../../assets/img/depositPay.png";
import PointPay from "../../assets/img/pointPay.png";
import AliQrCode from "../../assets/img/aliqrcode.png";
import wechatQrCode from "../../assets/img/wechatqrcode.png";
import MCouponUse from "../../components/mobile/MCouponUse";
import MPaypalModal from "../../components/mobile/MPaypalModal";
import footerCenterLogo from "../../assets/img/footerCenterLogo.png";
import MDeliveryPriceDetail from "../../components/mobile/MDeliveryPriceDetail";
import trash from "../../assets/img/mainChatTrash.png";
import chatFileImg from "../../assets/img/chatFolderImg.png";
import Linkify from "react-linkify";
import DOMPurify from "dompurify";
import mainChatEmoji from "../../assets/img/mainChatEmoji.png";
import Picker from "@emoji-mart/react";
import mainChatUpload from "../../assets/img/mainChatUpload.png";
import mainChatSend from "../../assets/img/mainChatSend.png";
import MMsgCheckModal from "../../components/mobile/MMsgCheckModal";
import termsView from "../../assets/img/termsView.png";
import BtorageLogo from "../../assets/img/BtorageLogo.png";
import MCommonTermsPopup from "../../components/mobile/MCommonTermsPopup";
import MMsgSelectModal from "../../components/mobile/MMSgSelectModal";
import {useWebSocket} from "../../services/WebsocketContext";
import MChatFileUpload from "../../components/mobile/MChatFileUpload";
import ReadyingPopup from "../../components/web/modal/ReadyingPopup";
import btoragePay from "../../assets/img/BtorageLogo.png";
import creditCard from "../../assets/img/payFormHeader.png";
import stripe from "../../assets/img/StripeLogo.png";
import upsLogo from '../../assets/img/UpsLogo.png';
import kPacketLogo from '../../assets/img/Kpacket.png';
import smallPacketLogo from '../../assets/img/commonSmallPacket.png';
import withAuth from "../../auth/UserRouteAuth";

const MUserOrderDetailPg = () => {
    const {t} = useTranslation();
    const {ordNum} = useParams();
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveBtLoading, setIsSaveBtLoading] = useState(false);
    const [isSaveBtDisabled, setIsSaveBtDisabled] = useState(false);
    const [isPayBtLoading, setIsPayBtLoading] = useState(false);
    const [isPayBtDisabled, setIsPayBtDisabled] = useState(false);

    const [recieverInfoAccordion, setRecieverInfoAccordion] = useState(true);
    const [cartOrderAccordion, setCartOrderAccordion] = useState(false);
    const [orderAccordion, setOrderAccordion] = useState(false);
    const [offlineAccordion, setOfflineAccordion] = useState(false);
    const [transferAccordion, setTransferAccordion] = useState(false);
    const [domesticAccordion, setDomesticAccordion] = useState(false);
    const [deliveryAccordion, setDeliveryAccordion] = useState(false);
    const [invoiceAccordion, setInvoiceAccordion] = useState(true);
    const [requestConfigAccordion, setRequestConfigAccordion] = useState(true);
    const [payListAccordion, setPayListAccordion] = useState(true);

    const [deliveryChoice, setDeliveryChoice] = useState([]);
    const [domesticDeliveryChoice, setDomesticDeliveryChoice] = useState([]);

    const [reqSeqnum, setReqSeqnum] = useState("");
    const [tagRemove, setTagRemove] = useState(false);
    const [shoesBoxRemove, setShoesBoxRemove] = useState(false);
    const [hangerRemove, setHangerRemove] = useState(false);
    const [compressionPack, setCompressionPack] = useState(false);
    const [productBoxRemove, setProductBoxRemove] = useState(false);
    const [packingHold, setPackingHold] = useState(false);
    const [deliveryDepositPay, setDeliveryDepositPay] = useState(false);
    const [deliveryMessengerGuide, setDeliveryMessengerGuide] = useState(false);
    const [detailRequest, setDetailRequest] = useState("");


    const [payMethodError, setPayMethodError] = useState(false);
    const [pointPayError, setPointPayError] = useState(false);
    const [depositSelectErr, setDepositSelectErr] = useState(false);
    const [pointSelectErr, setPointSelectErr] = useState(false);
    const [etcPayError, setEtcPayError] = useState(false);
    const [discountError, setDiscountError] = useState(false);

    const [orderAddrBookSt, setOrderAddrBookSt] = useState(false);

    // new product bt show fl
    const [newCartBtShow, setNewCartBtShow] = useState(true);
    const [newOrderBtShow, setNewOrderBtShow] = useState(true);
    const [newOfflineBtShow, setNewOfflineBtShow] = useState(true);
    const [newTransferBtShow, setNewTransferBtShow] = useState(true);
    const [newDomesticBtShow, setNewDomesticBtShow] = useState(true);
    const [newDeliveryBtShow, setNewDeliveryBtShow] = useState(true);

    //  add product form state
    const [newCartFormSt, setNewCartFormSt] = useState(false);
    const [newOrderFormSt, setNewOrderFormSt] = useState(false);
    const [newOfflineFormSt, setNewOfflineFormSt] = useState(false);
    const [newTransferFormSt, setNewTransferFormSt] = useState(false);
    const [newDomesticFormSt, setNewDomesticFormSt] = useState(false);
    const [newDeliveryFormSt, setNewDeliveryFormSt] = useState(false);

    // database product form
    const [dbCartProductHandle, setDbCartProductHandle] = useState([]);
    const [dbOrderProductHandle, setDbOrderProductHandle] = useState([]);
    const [dbOfflineProductHandle, setDbOfflineProductHandle] = useState([]);
    const [dbTransferProductHandle, setDbTransferProductHandle] = useState([]);
    const [dbDomesticProductHandle, setDbDomesticProductHandle] = useState([]);
    const [dbDeliveryProductHandle, setDbDeliveryProductHandle] = useState([]);

    // add product form
    const [cartProductHandle, setCartProductHandle] = useState([]);
    const [orderProductHandle, setOrderProductHandle] = useState([]);
    const [offlineProductHandle, setOfflineProductHandle] = useState([]);
    const [transferProductHandle, setTransferProductHandle] = useState([]);
    const [domesticProductHandle, setDomesticProductHandle] = useState([]);
    const [deliveryProductHandle, setDeliveryProductHandle] = useState([]);

    // order addForm & dbForm merged
    const mergedCartProducts = [...dbCartProductHandle, ...cartProductHandle];
    const mergedOrderProducts = [...dbOrderProductHandle, ...orderProductHandle];
    const mergedOfflineProducts = [...dbOfflineProductHandle, ...offlineProductHandle];
    const mergedTransferProducts = [...dbTransferProductHandle, ...transferProductHandle];
    const mergedDomesticProducts = [...dbDomesticProductHandle, ...domesticProductHandle];
    const mergedDeliveryProducts = [...dbDeliveryProductHandle, ...deliveryProductHandle];

    const excelFileSelectRefCart = useRef(null);
    const excelFileSelectRefOrder = useRef(null);
    const excelFileSelectRefOffline = useRef(null);
    const excelFileSelectRefTransfer = useRef(null);
    const excelFileSelectRefDomestic = useRef(null);
    const excelFileSelectRefDelivery = useRef(null);

    const [invoiceFile, setInvoiceFile] = useState(null);
    const [fileName, setFileName] = useState("파일선택");
    const [fileUrl, setFileUrl] = useState(null);

    const [payWay, setPayWay] = useState('');
    const [readyingPopupSt, setReadyingPopupSt] = useState(false);
    const dollarList = ['USD', 'CAD', 'AUD', 'NZD', 'EUR', 'JPY', 'SGD']
    const [currency, setCurrency] = useState('USD');
    const [lastForPrice, setLastForPrice] = useState(0);

    const [dbFormTotalPrices, setDbFormTotalPrices] = useState(0);
    const [dbFormTotalMallShipPrice, setDbFormTotalMallShipPrice] = useState(0);
    const [dbFormTotalFee, setDbFormTotalFee] = useState(0);
    const [dbFormTotalQuantity, setDbFormTotalQuantity] = useState(0);
    const [dbFormTotalOverseasShippingExpense, setDbFormTotalOverseasShippingExpense] = useState(0);
    const [dbFormTotalEtcExpense, setDbFormTotalEtcExpense] = useState(0);

    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalPrices, setTotalPrices] = useState(0);
    const [totalAdminProdPrice, setTotalAdminProdPrice] = useState(0);
    const [totalMallShipPrice, setTotalMallShipPrice] = useState(0);
    const [totalFee, setTotalFee] = useState(0);
    const [totalOverseasShippingExpense, setTotalOverseasShippingExpense] = useState(0);
    const [totalEtcExpense, setTotalEtcExpense] = useState(0);
    const [pointSale, setPointSale] = useState('');
    const [depositSale, setDepositSale] = useState('');
    const [couponSale, setCouponSale] = useState('');

    const finalQuantity = (totalQuantity + dbFormTotalQuantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const finalProdExpense = (totalPrices + dbFormTotalPrices + totalAdminProdPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const finalMallShipPrice = (totalMallShipPrice + dbFormTotalMallShipPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const finalFee = (totalFee + dbFormTotalFee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const finalOverseasShipExpense = (totalOverseasShippingExpense + dbFormTotalOverseasShippingExpense).toLocaleString('en-US');
    const finalEtcExpense = (totalEtcExpense + dbFormTotalEtcExpense);

    const finalPayExpense = (totalPrices + dbFormTotalPrices + totalAdminProdPrice + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
        totalOverseasShippingExpense + finalEtcExpense - (Number(pointSale) + Number(depositSale) + Number(couponSale))).toLocaleString('en-US');

    const [allAgreeCheck, setAllAgreeCheck] = useState(false);
    // const [infoCollectionAgreeCheck, setInfoCollectionAgreeCheck] = useState(0);
    const [cartServiceAgreeCheck, setCartServiceAgreeCheck] = useState(false);
    const [orderServiceAgreeCheck, setOrderServiceAgreeCheck] = useState(false);
    const [offlineServiceAgreeCheck, setOfflineServiceAgreeCheck] = useState(false);
    const [transferServiceAgreeCheck, setTransferServiceAgreeCheck] = useState(false);
    const [domesticServiceAgreeCheck, setDomesticServiceAgreeCheck] = useState(false);
    const [deliveryServiceAgreeCheck, setDeliveryServiceAgreeCheck] = useState(false);

    const [agreeCheckBoxError, setAgreeCheckBoxError] = useState(false);

    // new product handle
    const newCartForm = () => {
        setNewCartFormSt(true);
        addCartProduct();
        if (cartProductHandle.length > 0) {
            setNewCartBtShow(false);
        }
    };

    const addCartProduct = () => {
        setCartProductHandle([...cartProductHandle, {}]);
        setCartOrderAccordion(true);
    };

    const deleteCartProduct = (index) => {
        setCartProductHandle(cartProductHandle.filter((_, i) => i !== index));
        if (cartProductHandle.length === 0 && dbCartProductHandle.length === 0) {
            setCartOrderAccordion(false);
            setCartServiceAgreeCheck(false);
        }
        if (cartProductHandle.length === 0) {
            setNewCartBtShow(true);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        updatePayinfoAddform();
    }

    const copyCartProduct = (index) => {
        setCartProductHandle((prevState) => {
            const copiedProduct = {...prevState[index]};
            return [...prevState, copiedProduct];
        });
        updatePayinfoAddform();
    };

    const handleCartProductInput = (index, field, value) => {
        const updatedProducts = [...cartProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value, newProd: true};
        setCartProductHandle(updatedProducts);
        updatePayinfoAddform();
    };

    const cartQuantityReplaceInput = (cartSeqnum, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleCartQuantityChange(cartSeqnum, onlyNumbers);
    };
    const handleCartQuantityChange = (index, newValue) => {
        const updatedCartProductHandle = cartProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue, newProd: true};
            }
            return item;
        });
        setCartProductHandle(updatedCartProductHandle);
    };

    const cartNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleCartPriceChange = (index, newValue) => {
        const updatedCartProductHandle = cartProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue, newProd: true};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setCartProductHandle(updatedCartProductHandle);
    };

    const cartPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = cartNumberWithComma(onlyNumbers);
        handleCartPriceChange(index, formattedValue);
    };

    const cartUploadInputChange = (index, key, value) => {
        const updatedRows = [...cartProductHandle];
        updatedRows[index][key] = value;
        setCartProductHandle(updatedRows);
    };

    const cartQuantityNumberInput = (index, field, value) => {
        cartUploadInputChange(index, field, value);
        handleCartProductInput(index, field, value);
        cartQuantityReplaceInput(index, value);
    }

    const cartPriceNumberInput = (index, field, value) => {
        cartUploadInputChange(index, field, value);
        handleCartProductInput(index, field, value);
        cartPriceReplaceInput(index, value);
    }

    const useCartItemsRefs = (cartProductHandle) => {
        const cartItemsRefs = useRef(
            cartProductHandle.map(() => ({
                name: null,
                option: null,
                quantity: null,
                price: null,
                url: null,
            }))
        );

        const initializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                option: null,
                quantity: null,
                price: null,
                url: null,
            }));
        };

        useMemo(() => {
            cartItemsRefs.current = initializeRefs(cartProductHandle.length);
        }, [cartProductHandle.length]);

        return cartItemsRefs;
    };
    const cartItemsRefs = useCartItemsRefs(cartProductHandle);

    const scrollToEmptyFieldCart = () => {
        let isValid = true;
        for (let i = 0; i < cartProductHandle.length; i++) {
            if (!cartProductHandle[i].name || !cartProductHandle[i].option || !cartProductHandle[i].quantity || !cartProductHandle[i].price || !cartProductHandle[i].url) {
                const emptyField = cartItemsRefs.current[i];
                if (!cartProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!cartProductHandle[i].option) {
                    emptyField.option.scrollIntoView({behavior: 'smooth'});
                    emptyField.option.focus();
                } else if (!cartProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!cartProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.url.scrollIntoView({behavior: 'smooth'});
                    emptyField.url.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    // database cart product handle
    const deleteDbCartProduct = (index) => {
        setDbCartProductHandle((prevState) => {
            const updatedState = prevState.filter((product, i) => {
                if (i === index) {
                    setDeletedProductsData((prevDeleted) => ({
                        ...prevDeleted,
                        deletedDbCartProducts: [...prevDeleted.deletedDbCartProducts, product],
                    }));
                }
                return i !== index;
            });
            if (updatedState.length === 0 && dbCartProductHandle.length === 0) {
                setCartOrderAccordion(false);
                setCartServiceAgreeCheck(false);
            }
            return updatedState;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        updatePayinfoAddform();
    };

    const handleDbCartProductInput = (index, field, value) => {
        const updatedProducts = [...dbCartProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDbCartProductHandle(updatedProducts);
        updatePayinfoDbform();
    };
    const dbCartQuantityReplaceInput = (cartSeqnum, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDbCartQuantityChange(cartSeqnum, onlyNumbers);
    };
    const handleDbCartQuantityChange = (index, newValue) => {
        const updatedCartProductHandle = dbCartProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setDbCartProductHandle(updatedCartProductHandle);
    };
    const handleDbCartPriceChange = (index, newValue) => {
        const updatedCartProductHandle = dbCartProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbCartProductHandle(updatedCartProductHandle);
    };

    const dbCartPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = cartNumberWithComma(onlyNumbers);
        handleDbCartPriceChange(index, formattedValue);
    };

    const dbCartQuantityNumberInput = (index, field, value) => {
        handleDbCartProductInput(index, field, value);
        dbCartQuantityReplaceInput(index, value);
    }

    const dbCartPriceNumberInput = (index, field, value) => {
        handleDbCartProductInput(index, field, value);
        dbCartPriceReplaceInput(index, value);
    }

    const useDbCartItemsRefs = (dbCartProductHandle) => {
        const dbCartItemsRefs = useRef(
            dbCartProductHandle.map(() => ({
                name: null,
                option: null,
                quantity: null,
                price: null,
                url: null,
            }))
        );

        const dbCartInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                option: null,
                quantity: null,
                price: null,
                url: null,
            }));
        };

        useMemo(() => {
            dbCartItemsRefs.current = dbCartInitializeRefs(cartProductHandle.length);
        }, [dbCartProductHandle.length]);

        return cartItemsRefs;
    };
    const dbCartItemsRefs = useDbCartItemsRefs(dbCartProductHandle);

    const scrollToEmptyFieldDbCart = () => {
        let isValid = true;
        if (dbCartProductHandle.length > 0) {
            for (let i = 0; i < dbCartProductHandle.length; i++) {
                if (!dbCartProductHandle[i].name || !dbCartProductHandle[i].option || !dbCartProductHandle[i].quantity || !dbCartProductHandle[i].price || !dbCartProductHandle[i].url) {
                    const emptyField = dbCartItemsRefs.current[i];
                    if (!dbCartProductHandle[i].name) {
                        emptyField.name.scrollIntoView({behavior: 'smooth'});
                        emptyField.name.focus();
                    } else if (!dbCartProductHandle[i].option) {
                        emptyField.option.scrollIntoView({behavior: 'smooth'});
                        emptyField.option.focus();
                    } else if (!dbCartProductHandle[i].quantity) {
                        emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                        emptyField.quantity.focus();
                    } else if (!dbCartProductHandle[i].price) {
                        emptyField.price.scrollIntoView({behavior: 'smooth'});
                        emptyField.price.focus();
                    } else {
                        emptyField.url.scrollIntoView({behavior: 'smooth'});
                        emptyField.url.focus();
                    }
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    };

    // new product handle
    const newOrderForm = () => {
        setNewOrderFormSt(true);
        addOrderProduct();
        if (orderProductHandle.length > 0) {
            setNewOrderBtShow(false);
        }
    };

    const addOrderProduct = () => {
        setOrderProductHandle([...orderProductHandle, {}]);
        setOrderAccordion(true);
    };
    const deleteOrderProduct = (index) => {
        setOrderProductHandle(orderProductHandle.filter((_, i) => i !== index));
        if (orderProductHandle.length === 0 && dbOrderProductHandle.length === 0) {
            setOrderAccordion(false);
            setOrderServiceAgreeCheck(false);
        }
        if (orderProductHandle.length === 0) {
            setNewOrderBtShow(true);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        updatePayinfoAddform();
    }
    const handleOrderProductInput = (index, field, value) => {
        const updatedProducts = [...orderProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value, newProd: true};
        setOrderProductHandle(updatedProducts);
        updatePayinfoAddform();
    };
    const copyOrderProduct = (index) => {
        const copiedProduct = {...orderProductHandle[index]};
        setOrderProductHandle([...orderProductHandle, copiedProduct]);
        updatePayinfoAddform();
    };

    const orderQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleOrderQuantityChange(index, onlyNumbers);
    };
    const handleOrderQuantityChange = (index, newValue) => {
        const updatedOrderProductHandle = orderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue, newProd: true};
            }
            return item;
        });
        setOrderProductHandle(updatedOrderProductHandle);
    };

    const orderNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleOrderPriceChange = (index, newValue) => {
        const updatedOrderProductHandle = orderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue, newProd: true};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOrderProductHandle(updatedOrderProductHandle);
    };
    const handleOrderMallpriceChange = (index, newValue) => {
        const updatedOrderProductHandle = orderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, mallShipPrice: newValue, newProd: true};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOrderProductHandle(updatedOrderProductHandle);
    };

    const orderPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = orderNumberWithComma(onlyNumbers);
        handleOrderPriceChange(index, formattedValue);
    };
    const orderMallpriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = orderNumberWithComma(onlyNumbers);
        handleOrderMallpriceChange(index, formattedValue);
    };

    const orderUploadInputChange = (index, key, value) => {
        const updatedRows = [...orderProductHandle];
        updatedRows[index][key] = value;
        setOrderProductHandle(updatedRows);
    };

    const orderQuantityNumberInput = (index, field, value) => {
        orderUploadInputChange(index, field, value);
        handleOrderProductInput(index, field, value);
        orderQuantityReplaceInput(index, value);
    }

    const orderPriceNumberInput = (index, field, value) => {
        orderUploadInputChange(index, field, value);
        handleOrderProductInput(index, field, value);
        orderPriceReplaceInput(index, value);
    }
    const orderMallpricericeNumberInput = (index, field, value) => {
        orderUploadInputChange(index, field, value);
        handleOrderProductInput(index, field, value);
        orderMallpriceReplaceInput(index, value);
    }

    const useOrderItemsRefs = (orderProductHandle) => {
        const orderItemsRefs = useRef(
            orderProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                url: null,
            }))
        );

        const orderInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                url: null,
            }));
        };

        useMemo(() => {
            orderItemsRefs.current = orderInitializeRefs(orderProductHandle.length);
        }, [orderProductHandle.length]);

        return orderItemsRefs;
    };
    const orderItemsRefs = useOrderItemsRefs(orderProductHandle);

    const scrollToEmptyFieldOrder = () => {
        let isValid = true;
        for (let i = 0; i < orderProductHandle.length; i++) {
            if (!orderProductHandle[i].name || !orderProductHandle[i].quantity || !orderProductHandle[i].price || !orderProductHandle[i].url) {
                const emptyField = orderItemsRefs.current[i];
                if (!orderProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!orderProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!orderProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.url.scrollIntoView({behavior: 'smooth'});
                    emptyField.url.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };


    // database order product handle
    const deleteDbOrderProduct = (index) => {
        setDbOrderProductHandle((prevState) => {
            const updatedState = prevState.filter((product, i) => {
                if (i === index) {
                    setDeletedProductsData((prevDeleted) => ({
                        ...prevDeleted,
                        deletedDbOrderProducts: [...prevDeleted.deletedDbOrderProducts, product],
                    }));
                }
                return i !== index;
            });
            if (updatedState.length === 0 && dbOrderProductHandle.length === 0) {
                setOrderAccordion(false);
                setOrderServiceAgreeCheck(false);
            }
            return updatedState;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        updatePayinfoAddform();
    };

    const handleDbOrderProductInput = (index, field, value) => {
        const updatedProducts = [...dbOrderProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDbOrderProductHandle(updatedProducts);
        updatePayinfoDbform();
    };
    const dbOrderQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDbOrderQuantityChange(index, onlyNumbers);
    };
    const handleDbOrderQuantityChange = (index, newValue) => {
        const updatedOrderProductHandle = dbOrderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setDbOrderProductHandle(updatedOrderProductHandle);
    };
    const handleDbOrderPriceChange = (index, newValue) => {
        const updatedOrderProductHandle = dbOrderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbOrderProductHandle(updatedOrderProductHandle);
    };
    const handleDbOrderMallpriceChange = (index, newValue) => {
        const updatedOrderProductHandle = dbOrderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, mallShipPrice: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbOrderProductHandle(updatedOrderProductHandle);
    };

    const dbOrderPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = orderNumberWithComma(onlyNumbers);
        handleDbOrderPriceChange(index, formattedValue);
    };
    const dbOrderMallpriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = orderNumberWithComma(onlyNumbers);
        handleDbOrderMallpriceChange(index, formattedValue);
    };
    const dbOrderQuantityNumberInput = (index, field, value) => {
        handleDbOrderProductInput(index, field, value);
        dbOrderQuantityReplaceInput(index, value);
    }

    const dbOrderPriceNumberInput = (index, field, value) => {
        handleDbOrderProductInput(index, field, value);
        dbOrderPriceReplaceInput(index, value);
    }
    const dbOrderMallpricericeNumberInput = (index, field, value) => {
        handleDbOrderProductInput(index, field, value);
        dbOrderMallpriceReplaceInput(index, value);
    }

    const useDbOrderItemsRefs = (dbOrderProductHandle) => {
        const dbOrderItemsRefs = useRef(
            dbOrderProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                url: null,
            }))
        );

        const dbOrderInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                url: null,
            }));
        };

        useMemo(() => {
            dbOrderItemsRefs.current = dbOrderInitializeRefs(dbOrderProductHandle.length);
        }, [dbOrderProductHandle.length]);

        return dbOrderItemsRefs;
    };
    const dbOrderItemsRefs = useDbOrderItemsRefs(dbOrderProductHandle);

    const scrollToEmptyFieldDbOrder = () => {
        let isValid = true;
        for (let i = 0; i < dbOrderProductHandle.length; i++) {
            if (!dbOrderProductHandle[i].name || !dbOrderProductHandle[i].quantity || !dbOrderProductHandle[i].price || !dbOrderProductHandle[i].url) {
                const emptyField = dbOrderItemsRefs.current[i];
                if (!dbOrderProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!dbOrderProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!dbOrderProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.url.scrollIntoView({behavior: 'smooth'});
                    emptyField.url.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };


    // new product handle
    const newOfflineForm = () => {
        setNewOfflineFormSt(true);
        addOfflineProduct();
        if (offlineProductHandle.length > 0) {
            setNewOfflineBtShow(false);
        }
    };

    const addOfflineProduct = () => {
        setOfflineProductHandle([...offlineProductHandle, {}]);
        setOfflineAccordion(true);
    };
    const deleteOfflineProduct = (index) => {
        setOfflineProductHandle(offlineProductHandle.filter((_, i) => i !== index));
        if (offlineProductHandle.length === 0 && dbOfflineProductHandle.length === 0) {
            setOfflineAccordion(false);
            setOfflineServiceAgreeCheck(false);
        }
        if (offlineProductHandle.length === 0) {
            setNewOfflineBtShow(true);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
    }
    const handleOfflineProductInput = (index, field, value) => {
        const updatedProducts = [...offlineProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value, newProd: true};
        setOfflineProductHandle(updatedProducts);
    };
    const copyOfflineProduct = (index) => {
        const copiedProduct = {...offlineProductHandle[index]};
        setOfflineProductHandle([...offlineProductHandle, copiedProduct]);
    };

    const offlineQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleOfflineQuantityChange(index, onlyNumbers);
    };
    const handleOfflineQuantityChange = (index, newValue) => {
        const updatedOfflineProductHandle = offlineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue, newProd: true};
            }
            return item;
        });
        setOfflineProductHandle(updatedOfflineProductHandle);
    };

    const offlineNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleOfflinePriceChange = (index, newValue) => {
        const updatedOfflineProductHandle = offlineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue, newProd: true};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOfflineProductHandle(updatedOfflineProductHandle);
    };

    const offlinePriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = offlineNumberWithComma(onlyNumbers);
        handleOfflinePriceChange(index, formattedValue);
    };

    const handleOfflineFeeChange = (index, newValue) => {
        const updatedOfflineProductHandle = offlineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue, newProd: true};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOfflineProductHandle(updatedOfflineProductHandle);
    };

    const offlineFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = offlineNumberWithComma(onlyNumbers);
        handleOfflineFeeChange(index, formattedValue);
    };

    const offlineUploadInputChange = (index, key, value) => {
        const updatedRows = [...offlineProductHandle];
        updatedRows[index][key] = value;
        setOfflineProductHandle(updatedRows);
    };

    const offlineQuantityNumberInput = (index, field, value) => {
        offlineUploadInputChange(index, field, value);
        handleOfflineProductInput(index, field, value);
        offlineQuantityReplaceInput(index, value);
    }

    const offlinePriceNumberInput = (index, field, value) => {
        offlineUploadInputChange(index, field, value);
        handleOfflineProductInput(index, field, value);
        offlinePriceReplaceInput(index, value);
    }
    const offlineFeeNumberInput = (index, field, value) => {
        offlineUploadInputChange(index, field, value);
        handleOfflineProductInput(index, field, value);
        offlineFeeReplaceInput(index, value);
    }

    const useOfflineItemsRefs = (offlineProductHandle) => {
        const offlineItemsRefs = useRef(
            offlineProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
            }))
        );

        const offlineInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
            }));
        };

        useMemo(() => {
            offlineItemsRefs.current = offlineInitializeRefs(offlineProductHandle.length);
        }, [offlineProductHandle.length]);

        return offlineItemsRefs;
    };
    const offlineItemsRefs = useOfflineItemsRefs(offlineProductHandle);

    const scrollToEmptyFieldOffline = () => {
        let isValid = true;
        for (let i = 0; i < offlineProductHandle.length; i++) {
            if (!offlineProductHandle[i].name || !offlineProductHandle[i].quantity || !offlineProductHandle[i].price || !offlineProductHandle[i].fee) {
                const emptyField = offlineItemsRefs.current[i];
                if (!offlineProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!offlineProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!offlineProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };


    // database offline product handle
    const deleteDbOfflineProduct = (index) => {
        setDbOfflineProductHandle((prevState) => {
            const updatedState = prevState.filter((product, i) => {
                if (i === index) {
                    setDeletedProductsData((prevDeleted) => ({
                        ...prevDeleted,
                        deletedDbOfflineProducts: [...prevDeleted.deletedDbOfflineProducts, product],
                    }));
                }
                return i !== index;
            });
            if (updatedState.length === 0 && dbOfflineProductHandle.length === 0) {
                setOfflineAccordion(false);
                setOfflineServiceAgreeCheck(false);
            }
            return updatedState;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        updatePayinfoAddform();
    };

    const handleDbOfflineProductInput = (index, field, value) => {
        const updatedProducts = [...dbOfflineProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDbOfflineProductHandle(updatedProducts);
    };
    const dbOfflineQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDbOfflineQuantityChange(index, onlyNumbers);
    };
    const handleDbOfflineQuantityChange = (index, newValue) => {
        const updatedOfflineProductHandle = dbOfflineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setDbOfflineProductHandle(updatedOfflineProductHandle);
    };
    const handleDbOfflinePriceChange = (index, newValue) => {
        const updatedOfflineProductHandle = dbOfflineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbOfflineProductHandle(updatedOfflineProductHandle);
    };

    const dbOfflinePriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = offlineNumberWithComma(onlyNumbers);
        handleDbOfflinePriceChange(index, formattedValue);
    };

    const handleDbOfflineFeeChange = (index, newValue) => {
        const updatedOfflineProductHandle = dbOfflineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbOfflineProductHandle(updatedOfflineProductHandle);
    };

    const dbOfflineFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = offlineNumberWithComma(onlyNumbers);
        handleDbOfflineFeeChange(index, formattedValue);
    };
    const dbOfflineQuantityNumberInput = (index, field, value) => {
        handleDbOfflineProductInput(index, field, value);
        dbOfflineQuantityReplaceInput(index, value);
    }

    const dbOfflinePriceNumberInput = (index, field, value) => {
        handleDbOfflineProductInput(index, field, value);
        dbOfflinePriceReplaceInput(index, value);
    }
    const dbOfflineFeeNumberInput = (index, field, value) => {
        handleDbOfflineProductInput(index, field, value);
        dbOfflineFeeReplaceInput(index, value);
    }

    const useDbOfflineItemsRefs = (dbOfflineProductHandle) => {
        const dbOfflineItemsRefs = useRef(
            dbOfflineProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
            }))
        );

        const dbOfflineInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
            }));
        };

        useMemo(() => {
            dbOfflineItemsRefs.current = dbOfflineInitializeRefs(dbOfflineProductHandle.length);
        }, [dbOfflineProductHandle.length]);

        return dbOfflineItemsRefs;
    };
    const dbOfflineItemsRefs = useDbOfflineItemsRefs(dbOfflineProductHandle);

    const scrollToEmptyFieldDbOffline = () => {
        let isValid = true;
        for (let i = 0; i < dbOfflineProductHandle.length; i++) {
            if (!dbOfflineProductHandle[i].name || !dbOfflineProductHandle[i].quantity || !dbOfflineProductHandle[i].price || !dbOfflineProductHandle[i].fee) {
                const emptyField = dbOfflineItemsRefs.current[i];
                if (!dbOfflineProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!dbOfflineProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!dbOfflineProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };


    // new product handle
    const newTransferForm = () => {
        setNewTransferFormSt(true);
        addTransferProduct();
        if (transferProductHandle.length > 0) {
            setNewTransferBtShow(false);
        }
    };

    const addTransferProduct = () => {
        setTransferProductHandle([...transferProductHandle, {}]);
        setTransferAccordion(true);
    };
    const deleteTransferProduct = (index) => {
        setTransferProductHandle(transferProductHandle.filter((_, i) => i !== index));
        if (transferProductHandle.length === 0 && dbTransferProductHandle.length === 0) {
            setTransferAccordion(false);
            setTransferServiceAgreeCheck(false);
        }
        if (transferProductHandle.length === 0) {
            setNewTransferBtShow(true);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
    }
    const handleTransferProductInput = (index, field, value) => {
        const updatedProducts = [...transferProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value, newProd: true};
        setTransferProductHandle(updatedProducts);
    };
    const copyTransferProduct = (index) => {
        const copiedProduct = {...transferProductHandle[index]};
        setTransferProductHandle([...transferProductHandle, copiedProduct]);
    };

    const transferNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number;
    };

    const handleTransferPriceChange = (index, newValue) => {
        const updatedTransferProductHandle = transferProductHandle.map((item, idx) => {
            if (idx === index) {
                const rawCommission = newValue * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭
                return {
                    ...item,
                    price: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    newProd: true,
                    fee: (roundedCommission).toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setTransferProductHandle(updatedTransferProductHandle);
    };

    const transferPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = transferNumberWithComma(onlyNumbers);
        handleTransferPriceChange(index, formattedValue);
    };

    const handleTransferFeeChange = (index, newValue) => {
        const updatedTransferProductHandle = transferProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, newProd: true, fee: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setTransferProductHandle(updatedTransferProductHandle);
    };

    const transferFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = transferNumberWithComma(onlyNumbers);
        handleTransferFeeChange(index, formattedValue);
    };

    const transferUploadInputChange = (index, key, value) => {
        const updatedRows = [...transferProductHandle];
        updatedRows[index][key] = value;
        setTransferProductHandle(updatedRows);
    };

    const transferPriceNumberInput = (index, field, value) => {
        transferUploadInputChange(index, field, value);
        handleTransferProductInput(index, field, value);
        transferPriceReplaceInput(index, value);
    }

    const transferFeeNumberInput = (index, field, value) => {
        transferUploadInputChange(index, field, value);
        handleTransferProductInput(index, field, value);
        transferFeeReplaceInput(index, value);
    }

    const useTransferItemsRefs = (transferProductHandle) => {
        const transferItemsRefs = useRef(
            transferProductHandle.map(() => ({
                name: null,
                price: null,
                fee: null,
            }))
        );

        const transferInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                price: null,
                fee: null,
            }));
        };

        useMemo(() => {
            transferItemsRefs.current = transferInitializeRefs(transferProductHandle.length);
        }, [transferProductHandle.length]);

        return transferItemsRefs;
    };
    const transferItemsRefs = useTransferItemsRefs(transferProductHandle);

    const scrollToEmptyFieldTransfer = () => {
        let isValid = true;
        for (let i = 0; i < transferProductHandle.length; i++) {
            if (!transferProductHandle[i].name || !transferProductHandle[i].price || !transferProductHandle[i].fee) {
                const emptyField = transferItemsRefs.current[i];
                if (!transferProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!transferProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    // database transfer product handle
    const deleteDbTransferProduct = (index) => {
        setDbTransferProductHandle((prevState) => {
            const updatedState = prevState.filter((product, i) => {
                if (i === index) {
                    setDeletedProductsData((prevDeleted) => ({
                        ...prevDeleted,
                        deletedDbTransferProducts: [...prevDeleted.deletedDbTransferProducts, product],
                    }));
                }
                return i !== index;
            });
            if (updatedState.length === 0 && dbTransferProductHandle.length === 0) {
                setTransferAccordion(false);
                setTransferServiceAgreeCheck(false);
            }
            return updatedState;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        updatePayinfoAddform();
    };

    const handleDbTransferProductInput = (index, field, value) => {
        const updatedProducts = [...dbTransferProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDbTransferProductHandle(updatedProducts);
    };
    const handleDbTransferPriceChange = (index, newValue) => {
        const updatedTransferProductHandle = dbTransferProductHandle.map((item, idx) => {
            if (idx === index) {
                const rawCommission = newValue * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭
                return {
                    ...item,
                    price: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    fee: (roundedCommission).toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbTransferProductHandle(updatedTransferProductHandle);
    };

    const dbTransferPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = transferNumberWithComma(onlyNumbers);
        handleDbTransferPriceChange(index, formattedValue);
    };

    const handleDbTransferFeeChange = (index, newValue) => {
        const updatedTransferProductHandle = dbTransferProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbTransferProductHandle(updatedTransferProductHandle);
    };

    const dbTransferFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = transferNumberWithComma(onlyNumbers);
        handleDbTransferFeeChange(index, formattedValue);
    };
    const dbTransferPriceNumberInput = (index, field, value) => {
        handleDbTransferProductInput(index, field, value);
        dbTransferPriceReplaceInput(index, value);
    }

    const dbTransferFeeNumberInput = (index, field, value) => {
        handleDbTransferProductInput(index, field, value);
        dbTransferFeeReplaceInput(index, value);
    }

    const useDbTransferItemsRefs = (dbTransferProductHandle) => {
        const dbTransferItemsRefs = useRef(
            dbTransferProductHandle.map(() => ({
                name: null,
                price: null,
                fee: null,
            }))
        );

        const dbTransferInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                price: null,
                fee: null,
            }));
        };

        useMemo(() => {
            dbTransferItemsRefs.current = dbTransferInitializeRefs(dbTransferProductHandle.length);
        }, [transferProductHandle.length]);

        return dbTransferItemsRefs;
    };
    const dbTransferItemsRefs = useDbTransferItemsRefs(dbTransferProductHandle);

    const scrollToEmptyFieldDbTransfer = () => {
        let isValid = true;
        for (let i = 0; i < dbTransferProductHandle.length; i++) {
            if (!dbTransferProductHandle[i].name || !dbTransferProductHandle[i].price || !dbTransferProductHandle[i].fee) {
                const emptyField = transferItemsRefs.current[i];
                if (!dbTransferProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!dbTransferProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    // new product handle
    const newDomesticForm = () => {
        setNewDomesticFormSt(true);
        addDomesticProduct();
        if (domesticProductHandle.length > 0) {
            setNewDomesticBtShow(false);
        }
    };

    const addDomesticProduct = () => {
        setDomesticProductHandle([...domesticProductHandle, {}]);
        setDomesticAccordion(true);
    };
    const deleteDomesticProduct = (index) => {
        setDomesticProductHandle(domesticProductHandle.filter((_, i) => i !== index));
        if (domesticProductHandle.length === 0 && dbDomesticProductHandle.length === 0) {
            setDomesticAccordion(false);
            setDomesticServiceAgreeCheck(false);
        }
        if (domesticProductHandle.length === 0) {
            setNewDomesticBtShow(true);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
    }
    const handleDomesticProductInput = (index, field, value) => {
        const updatedProducts = [...domesticProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value, newProd: true};
        setDomesticProductHandle(updatedProducts);
    };
    const copyDomesticProduct = (index) => {
        const copiedProduct = {...domesticProductHandle[index]};
        setDomesticProductHandle([...domesticProductHandle, copiedProduct]);
    };

    const domesticQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDomesticQuantityChange(index, onlyNumbers);
    };
    const handleDomesticQuantityChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue, newProd: true};
            }
            return item;
        });
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const domesticNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number;
    };

    const handleDomesticPriceChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                const price = parseInt(newValue) || 0;
                const mallShipPrice = parseInt((item.mallShipPrice || "").toString().replace(/,/g, '')) || 0;
                const total = parseInt(mallShipPrice) + price;

                const rawCommission = total * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                return {
                    ...item,
                    price: (newValue || "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    newProd: true,
                    fee: roundedCommission.toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const handleDomesticMallpriceChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                const mallShipPrice = parseInt(newValue) || 0;
                const priceStr = item.price && item.price.replace(/,/g, '');
                const price = parseInt(priceStr) || 0;
                const total = price + mallShipPrice;

                const rawCommission = total * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                return {
                    ...item,
                    mallShipPrice: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    newProd: true,
                    fee: roundedCommission.toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const handleDomesticFeeChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), newProd: true};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const domesticPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDomesticPriceChange(index, formattedValue);
    };

    const domesticMallpriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDomesticMallpriceChange(index, formattedValue);
    };

    const domesticFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDomesticFeeChange(index, formattedValue);
    };

    const domesticUploadInputChange = (index, key, value) => {
        const updatedRows = [...domesticProductHandle];
        updatedRows[index][key] = value;
        setDomesticProductHandle(updatedRows);
    };

    const domesticQuantityNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticQuantityReplaceInput(index, value);
    }

    const domesticPriceNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticPriceReplaceInput(index, value);
    }

    const domesticMallpriceNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticMallpriceReplaceInput(index, value);
    }

    const domesticFeeNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticFeeReplaceInput(index, value);
    }

    const useDomesticItemsRefs = (domesticProductHandle) => {
        const domesticItemsRefs = useRef(
            domesticProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
                url: null,
            }))
        );

        const domesticInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
                url: null,
            }));
        };

        useMemo(() => {
            domesticItemsRefs.current = domesticInitializeRefs(domesticProductHandle.length);
        }, [domesticProductHandle.length]);

        return domesticItemsRefs;
    };
    const domesticItemsRefs = useDomesticItemsRefs(domesticProductHandle);

    const scrollToEmptyFieldDomestic = () => {
        let isValid = true;
        for (let i = 0; i < domesticProductHandle.length; i++) {
            if (!domesticProductHandle[i].name || !domesticProductHandle[i].quantity || !domesticProductHandle[i].price || !domesticProductHandle[i].fee || !domesticProductHandle[i].url) {
                const emptyField = domesticItemsRefs.current[i];
                if (!domesticProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!domesticProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!domesticProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else if (!domesticProductHandle[i].fee) {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                } else {
                    emptyField.url.scrollIntoView({behavior: 'smooth'});
                    emptyField.url.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    // database domestic product handle
    const deleteDbDomesticProduct = (index) => {
        setDbDomesticProductHandle((prevState) => {
            const updatedState = prevState.filter((product, i) => {
                if (i === index) {
                    setDeletedProductsData((prevDeleted) => ({
                        ...prevDeleted,
                        deletedDbDomesticProducts: [...prevDeleted.deletedDbDomesticProducts, product],
                    }));
                }
                return i !== index;
            });
            if (updatedState.length === 0 && dbDomesticProductHandle.length === 0) {
                setDomesticAccordion(false);
                setDomesticServiceAgreeCheck(false);
            }
            return updatedState;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        updatePayinfoAddform();
    };

    const handleDbDomesticProductInput = (index, field, value) => {
        const updatedProducts = [...dbDomesticProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDbDomesticProductHandle(updatedProducts);
    };
    const dbDomesticQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDbDomesticQuantityChange(index, onlyNumbers);
    };
    const handleDbDomesticQuantityChange = (index, newValue) => {
        const updatedDomesticProductHandle = dbDomesticProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setDbDomesticProductHandle(updatedDomesticProductHandle);
    };

    const handleDbDomesticPriceChange = (index, newValue) => {
        const updatedDomesticProductHandle = dbDomesticProductHandle.map((item, idx) => {
            if (idx === index) {
                const price = parseInt(newValue) || 0;
                const mallShipPrice = parseInt(item.mallShipPrice.toString().replace(/,/g, '')) || 0;
                const total = mallShipPrice + price;

                const rawCommission = total * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                return {
                    ...item,
                    price: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    fee: roundedCommission.toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbDomesticProductHandle(updatedDomesticProductHandle);
    };

    const handleDbDomesticMallpriceChange = (index, newValue) => {
        const updatedDomesticProductHandle = dbDomesticProductHandle.map((item, idx) => {
            if (idx === index) {
                const mallShipPrice = parseInt(newValue) || 0;
                const price = parseInt(item.price.toString().replace(/,/g, '')) || 0;
                const total = price + mallShipPrice;

                const rawCommission = total * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                return {
                    ...item,
                    mallShipPrice: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    fee: roundedCommission.toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbDomesticProductHandle(updatedDomesticProductHandle);
    };

    const handleDbDomesticFeeChange = (index, newValue) => {
        const updatedDomesticProductHandle = dbDomesticProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbDomesticProductHandle(updatedDomesticProductHandle);
    };

    const dbDomesticPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDbDomesticPriceChange(index, formattedValue);
    };

    const dbDomesticMallpriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDbDomesticMallpriceChange(index, formattedValue);
    };

    const dbDomesticFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDbDomesticFeeChange(index, formattedValue);
    };
    const dbDomesticQuantityNumberInput = (index, field, value) => {
        handleDbDomesticProductInput(index, field, value);
        dbDomesticQuantityReplaceInput(index, value);
    }

    const dbDomesticPriceNumberInput = (index, field, value) => {
        handleDbDomesticProductInput(index, field, value);
        dbDomesticPriceReplaceInput(index, value);
    }

    const dbDomesticMallpriceNumberInput = (index, field, value) => {
        handleDbDomesticProductInput(index, field, value);
        dbDomesticMallpriceReplaceInput(index, value);
    }

    const dbDomesticFeeNumberInput = (index, field, value) => {
        handleDbDomesticProductInput(index, field, value);
        dbDomesticFeeReplaceInput(index, value);
    }

    const useDbDomesticItemsRefs = (dbDomesticProductHandle) => {
        const dbDomesticItemsRefs = useRef(
            dbDomesticProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
                url: null,
            }))
        );

        const dbDomesticInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
                url: null,
            }));
        };

        useMemo(() => {
            dbDomesticItemsRefs.current = dbDomesticInitializeRefs(dbDomesticProductHandle.length);
        }, [dbDomesticProductHandle.length]);

        return dbDomesticItemsRefs;
    };
    const dbDomesticItemsRefs = useDbDomesticItemsRefs(dbDomesticProductHandle);

    const scrollToEmptyFieldDbDomestic = () => {
        let isValid = true;
        for (let i = 0; i < dbDomesticProductHandle.length; i++) {
            if (!dbDomesticProductHandle[i].name || !dbDomesticProductHandle[i].quantity || !dbDomesticProductHandle[i].price || !dbDomesticProductHandle[i].fee || !dbDomesticProductHandle[i].url) {
                const emptyField = dbDomesticItemsRefs.current[i];
                if (!dbDomesticProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!dbDomesticProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!dbDomesticProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else if (!dbDomesticProductHandle[i].fee) {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                } else {
                    emptyField.url.scrollIntoView({behavior: 'smooth'});
                    emptyField.url.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    // new product handle
    const newDeliveryForm = () => {
        setNewDeliveryFormSt(true);
        addDeliveryProduct();
        if (deliveryProductHandle.length > 0) {
            setNewDeliveryBtShow(false);
        }
    };

    const addDeliveryProduct = () => {
        setDeliveryProductHandle([...deliveryProductHandle, {
            domShipco: t('dv'),
            inWaynum: "",
            name: "",
            quantity: ""
        }]);
        setDeliveryAccordion(true);
    };
    const deleteDeliveryProduct = (index) => {
        setDeliveryProductHandle(deliveryProductHandle.filter((_, i) => i !== index));
        if (deliveryProductHandle.length === 0 && dbDeliveryProductHandle.length === 0) {
            setDeliveryAccordion(false);
            setDeliveryServiceAgreeCheck(false);
        }
        if (deliveryProductHandle.length === 0) {
            setNewDeliveryBtShow(true);
        }
        if (deliveryProductHandle.length === 0 || dbDeliveryProductHandle.length === 0) {
            setDeliveryAccordion(true);
        }
    }
    const handleDeliveryProductInput = (index, field, value) => {
        const updatedProducts = [...deliveryProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value, newProd: true};
        setDeliveryProductHandle(updatedProducts);
    };
    const copyDeliveryProduct = (index) => {
        const copiedProduct = {...deliveryProductHandle[index]};
        setDeliveryProductHandle([...deliveryProductHandle, copiedProduct]);
    };

    const deliveryQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDeliveryQuantityChange(index, onlyNumbers);
    };

    const handleDeliveryQuantityChange = (index, newValue) => {
        const updatedDeliveryProductHandle = deliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue, newProd: true};
            }
            return item;
        });
        setDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const deliveryWaynumReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDeliveryWaynumChange(index, onlyNumbers);
    };

    const handleDeliveryWaynumChange = (index, newValue) => {
        const updatedDeliveryProductHandle = deliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, inWaynum: newValue, newProd: true};
            }
            return item;
        });
        setDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const handleDeliveryDomesticChange = (index, newValue) => {
        const updatedDeliveryProductHandle = deliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, domShipco: newValue, newProd: true};
            }
            return item;
        });
        setDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const deliveryUploadInputChange = (index, key, value) => {
        const updatedRows = [...deliveryProductHandle];
        updatedRows[index][key] = value;
        setDeliveryProductHandle(updatedRows);
    };

    const deliveryQuantityNumberInput = (index, field, value) => {
        if (value === '0') return;
        deliveryUploadInputChange(index, field, value);
        handleDeliveryProductInput(index, field, value);
        deliveryQuantityReplaceInput(index, value);
    }

    const deliveryWaynumNumberInput = (index, field, value) => {
        deliveryUploadInputChange(index, field, value);
        handleDeliveryProductInput(index, field, value);
        deliveryWaynumReplaceInput(index, value);
    }

    const deliveryDomesticSelect = (index, field, value) => {
        handleDeliveryProductInput(index, field, value);
        handleDeliveryDomesticChange(index, value);
    }

    const useDeliveryItemsRefs = (deliveryProductHandle) => {
        const deliveryItemsRefs = useRef(
            deliveryProductHandle.map(() => ({
                name: null,
                quantity: null,
            }))
        );

        const deliveryinitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
            }));
        };

        useMemo(() => {
            deliveryItemsRefs.current = deliveryinitializeRefs(deliveryProductHandle.length);
        }, [deliveryProductHandle.length]);

        return deliveryItemsRefs;
    };
    const deliveryItemsRefs = useDeliveryItemsRefs(deliveryProductHandle);

    const scrollToEmptyFieldDelivery = () => {
        let isValid = true;
        if (deliveryProductHandle.length === 0) {
            return;
        }
        for (let i = 0; i < deliveryProductHandle.length; i++) {
            if (!deliveryProductHandle[i].name || !deliveryProductHandle[i].quantity) {
                const emptyField = deliveryItemsRefs.current[i];
                if (!deliveryProductHandle[i].name && emptyField.name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (emptyField.quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    // newForm 국내배송 셀렉트함수
    const [domesticShow, setDomesticShow] = useState(
        new Array(deliveryProductHandle.length).fill(false)
    );

    const deliveryProductHandleRef = useRef(deliveryProductHandle);
    deliveryProductHandleRef.current = deliveryProductHandle;

    const domesticRefs = useRef([]);
    const toggleDomesticShow = (index) => {
        const updatedShowStates = [...domesticShow];
        updatedShowStates[index] = !updatedShowStates[index];
        setDomesticShow(updatedShowStates);
    };

    const handleDomesticChange = (index, domestic) => {
        const updatedHandles = [...deliveryProductHandle];
        updatedHandles[index].domShipco = domestic;
        setDeliveryProductHandle(updatedHandles);

        const updatedShowStates = [...domesticShow];
        updatedShowStates[index] = false;
        setDomesticShow(updatedShowStates);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            domesticRefs.current.forEach((ref, index) => {
                if (ref && !ref.contains(event.target)) {
                    setDomesticShow(prevShow => {
                        const updatedShow = [...prevShow];
                        updatedShow[index] = false;
                        return updatedShow;
                    });
                }
            });
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [domesticRefs, domesticShow]);

    useEffect(() => {
        domesticRefs.current = domesticRefs.current.slice(0, deliveryProductHandle.length);
        deliveryProductHandle.forEach((_, index) => {
            if (!domesticRefs.current[index]) {
                domesticRefs.current[index] = createRef();
            }
        });
    }, [deliveryProductHandle]);


    // database delivery product handle
    const deleteDbDeliveryProduct = (index) => {
        setDbDeliveryProductHandle((prevState) => {
            const updatedState = prevState.filter((product, i) => {
                if (i === index) {
                    setDeletedProductsData((prevDeleted) => ({
                        ...prevDeleted,
                        deletedDbDeliveryProducts: [...prevDeleted.deletedDbDeliveryProducts, product],
                    }));
                }
                return i !== index;
            });
            if (updatedState.length === 0 && dbDeliveryProductHandle.length === 0) {
                setDeliveryAccordion(false);
                setDeliveryServiceAgreeCheck(false);
            }
            return updatedState;
        });
        updatePayinfoAddform();
    };

    const handleDbDeliveryProductInput = (index, field, value) => {
        const updatedProducts = [...dbDeliveryProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDbDeliveryProductHandle(updatedProducts);
    };

    const dbDeliveryQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDbDeliveryQuantityChange(index, onlyNumbers);
    };

    const handleDbDeliveryQuantityChange = (index, newValue) => {
        const updatedDeliveryProductHandle = dbDeliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setDbDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const dbDeliveryWaynumReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDbDeliveryWaynumChange(index, onlyNumbers);
    };

    const handleDbDeliveryWaynumChange = (index, newValue) => {
        const updatedDeliveryProductHandle = dbDeliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, inWaynum: newValue};
            }
            return item;
        });
        setDbDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const handleDbDeliveryDomesticChange = (index, newValue) => {
        const updatedDeliveryProductHandle = dbDeliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, domShipco: newValue};
            }
            return item;
        });
        const updatedShowStates = [...dbDomesticShow];
        updatedShowStates[index] = false;
        setDbDomesticShow(updatedShowStates);
        setDbDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const dbDeliveryQuantityNumberInput = (index, field, value) => {
        handleDbDeliveryProductInput(index, field, value);
        dbDeliveryQuantityReplaceInput(index, value);
    }

    const dbDeliveryWaynumNumberInput = (index, field, value) => {
        handleDbDeliveryProductInput(index, field, value);
        dbDeliveryWaynumReplaceInput(index, value);
    }

    const [dontTrackingShipcoSt, setDontTrackingShipcoSt] = useState(false);
    const closeDontTrackingShipco = (() => {
        setDontTrackingShipcoSt(false);
    })

    const dbSelectedDeliveryTracking = ((selectedDomestic, dsTrackNum) => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 1200;
        const height = 800;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);

        if (selectedDomestic === "CJ대한통운") {
            const url = `https://trace.cjlogistics.com/next/tracking.html?wblNo=${dsTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "로젠택배") {
            const url = `https://www.ilogen.com/web/personal/trace/${dsTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "우체국") {
            const url = `https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=${dsTrackNum}&displayHeader=N`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "한진택배") {
            const url = `https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "롯데택배") {
            const url = `https://www.lotteglogis.com/open/tracking?invno=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "경동택배") {
            const url = `https://kdexp.com/newDeliverySearch.kd?barcode=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else {
            setDontTrackingShipcoSt(true);
        }
    })

    const useDbDeliveryItemsRefs = (dbDeliveryProductHandle) => {
        const dbDeliveryItemsRefs = useRef(
            dbDeliveryProductHandle.map(() => ({
                name: null,
                quantity: null,
            }))
        );

        const dbDeliveryinitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
            }));
        };

        useMemo(() => {
            dbDeliveryItemsRefs.current = dbDeliveryinitializeRefs(dbDeliveryProductHandle.length);
        }, [dbDeliveryProductHandle.length]);

        return dbDeliveryItemsRefs;
    };
    const dbDeliveryItemsRefs = useDbDeliveryItemsRefs(dbDeliveryProductHandle);

    const scrollToEmptyFieldDbDelivery = () => {
        let isValid = true;
        if (dbDeliveryProductHandle.length === 0) {
            return;
        }
        for (let i = 0; i < dbDeliveryProductHandle.length; i++) {
            if (!dbDeliveryProductHandle[i].name || !dbDeliveryProductHandle[i].quantity) {
                const emptyField = dbDeliveryItemsRefs.current[i];
                if (!dbDeliveryProductHandle[i].name && emptyField.name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (emptyField.quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    // dbForm 국내배송 셀렉트함수
    const [dbDomesticShow, setDbDomesticShow] = useState(
        new Array(dbDeliveryProductHandle.length).fill(false)
    );

    const dbDeliveryProductHandleRef = useRef(dbDeliveryProductHandle);
    dbDeliveryProductHandleRef.current = dbDeliveryProductHandle;

    const dbDomesticRefs = useRef([]);
    const toggleDbDomesticShow = (index) => {
        const updatedShowStates = [...dbDomesticShow];
        updatedShowStates[index] = !updatedShowStates[index];
        setDbDomesticShow(updatedShowStates);
    };

    const handleDbDomesticChange = (index, domestic) => {
        const updatedHandles = [...dbDeliveryProductHandle];
        updatedHandles[index].domShipco = domestic;
        setDbDeliveryProductHandle(updatedHandles);

        const updatedShowStates = [...dbDomesticShow];
        updatedShowStates[index] = false;
        setDbDomesticShow(updatedShowStates);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            dbDomesticRefs.current.forEach((ref, index) => {
                if (ref && !ref.contains(event.target)) {
                    setDbDomesticShow(prevShow => {
                        const updatedShow = [...prevShow];
                        updatedShow[index] = false;
                        return updatedShow;
                    });
                }
            });
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dbDomesticRefs, dbDomesticShow]);

    useEffect(() => {
        dbDomesticRefs.current = dbDomesticRefs.current.slice(0, dbDeliveryProductHandle.length);
        dbDeliveryProductHandle.forEach((_, index) => {
            if (!dbDomesticRefs.current[index]) {
                dbDomesticRefs.current[index] = createRef();
            }
        });
    }, [dbDeliveryProductHandle]);


    const [formUploadErrSt, setFormUploadErrSt] = useState(false);
    const closeFormUploadErrSt = (() => {
        setFormUploadErrSt(false);
    })

    // 장바구니 업로드
    const readExcelCart = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((cartSheet) => {
                            const ws = workbook.Sheets[cartSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {
                                if (!row["MallId"] || !row["MallPw"] || !row["Quantity"] || !row["CartPrice"] || !row["MallURL"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        name: String(row["MallId"]),
                                        option: row["MallPw"],
                                        quantity: row["Quantity"],
                                        price: row["CartPrice"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        url: row["MallURL"],
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setCartProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const cartExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelCart(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 구매대행 업로드
    const readExcelOrder = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((orderSheet) => {
                            const ws = workbook.Sheets[orderSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {
                                if (!row["ProductName"] || !row["Price"] || !row["ShipPrice"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        name: String(row["ProductName"]),
                                        option: row["Option"],
                                        quantity: row["Quantity"],
                                        price: row["Price"]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        mallShipPrice: row["ShipPrice"]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        url: row["ProductURL"],
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setOrderProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const orderExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelOrder(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 오프라인 업로드
    const readExcelOffline = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((offlineSheet) => {
                            const ws = workbook.Sheets[offlineSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {
                                if (!row["ProductName"] || !row["Quantity"] || !row["TotalPrice"] || !row["Fee"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        name: String(row["ProductName"]),
                                        quantity: row["Quantity"],
                                        price: row["TotalPrice"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        fee: row["Fee"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setOfflineProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const offlineExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelOffline(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 이체대행 업로드
    const readExcelTransfer = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((transferSheet) => {
                            const ws = workbook.Sheets[transferSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {

                                if (!row["TransferAccountNumber"] || !row["TransferPrice"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    const transferPrice = parseFloat(row["TransferPrice"]);
                                    const fee = transferPrice * 0.07; // 7% 수수료
                                    const roundedCommission = Math.floor(fee / 10) * 10; // 10원 단위 절삭

                                    return {
                                        name: String(row["TransferAccountNumber"]),
                                        price: row["TransferPrice"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        fee: roundedCommission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setTransferProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const transferExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelTransfer(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 국내배송 업로드
    const readExcelDomestic = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((domesticSheet) => {
                            const ws = workbook.Sheets[domesticSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {

                                if (!row["ProductName"] || !row["Quantity"] || !row["Price"] || !row["ProductURL"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    const price = parseFloat(row["Price"]);
                                    const mallShipPrice = parseFloat(row["ShipPrice"]);
                                    const fee = price + mallShipPrice;
                                    const rawCommission = fee * 0.07; // 7% 수수료
                                    const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                                    return {
                                        name: String(row["ProductName"]),
                                        option: row["Option"],
                                        quantity: row["Quantity"],
                                        price: price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        mallShipPrice: mallShipPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        fee: roundedCommission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        url: row["ProductURL"],
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setDomesticProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const domesticExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelDomestic(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 배송대행 업로드
    const readExcelDelivery = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((deliverySheet) => {
                            const ws = workbook.Sheets[deliverySheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {
                                if (!row["PackageName"] || !row["TotalQuantity"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        domShipco: t('dv'),
                                        inWaynum: row["WaybillNumber"],
                                        option: row["Option"],
                                        name: String(row["PackageName"]),
                                        quantity: row["TotalQuantity"],
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setDeliveryProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };


    const deliveryExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelDelivery(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 여기서부터 엑셀다운 함수
    const cartExcelDown = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('cartSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('cartSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'MallId', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'MallPw', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'cartPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'MallURL', width: 70, style: {alignment: {horizontal: 'center'}}},
        ];
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `cartForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const orderExcelDown = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('orderSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('orderSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'ProductName', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Option', width: 40, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 10, style: {alignment: {horizontal: 'center'}}},
            {header: 'Price', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'ShipPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'ProductURL', width: 70, style: {alignment: {horizontal: 'center'}}},
        ];
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `orderForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const offlineExcelDown = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('offlineSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('offlineSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'ProductName', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 10, style: {alignment: {horizontal: 'center'}}},
            {header: 'TotalPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'Fee', width: 20, style: {alignment: {horizontal: 'center'}}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `offlineForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const transferExcelDown = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('transferSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('transferSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'TransferAccountNumber', width: 40, style: {alignment: {horizontal: 'center'}}},
            {header: 'TransferPrice', width: 30, style: {alignment: {horizontal: 'center'}}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `transferForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const domesticExcelDown = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('domesticSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('domesticSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'ProductName', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Option', width: 40, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 10, style: {alignment: {horizontal: 'center'}}},
            {header: 'Price', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'ShipPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'ProductURL', width: 70, style: {alignment: {horizontal: 'center'}}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `domesticForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const deliveryExcelDown = () => {

        const workbook = new excel.Workbook();
        workbook.addWorksheet('deliverySheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('deliverySheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'WaybillNumber', width: 50, style: {alignment: {horizontal: 'center'}}},
            {header: 'PackageName', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'TotalQuantity', width: 20, style: {alignment: {horizontal: 'center'}}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `deliveryForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const [orderdtList, setOrderdtList] = useState([{}]);
    const [ordermstList, setOrdermstList] = useState([{}]);
    const [payinfoList, setPayinfoList] = useState([{}]);
    const [recinfoList, setRecinfoList] = useState([]);
    const [reqinfoList, setReqinfoList] = useState([{}]);
    const [memberInfo, setMemberInfo] = useState([{}]);

    const hasPT1OrPT3 = payinfoList.some(pay => pay.payType === "PT1" || pay.payType === "PT3");

    const [runOrderProcessFl, setRunOrderProcessFl] = useState(false);
    const selectOrdnumData = (() => {
        setIsLoading(true);
        setRunOrderProcessFl(true);
        axios({
            method: 'get',
            url: '/order/selectOrdnumData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                ordNum: ordNum
            },
        })
            .then((response) => {
                const data = response.data[0];
                setOrderdtList(data);

                const origFileName = data.invoiceFileOrinm || '';
                setFileName(origFileName);

                const ProdList = data.productsDTO || [];
                updateProductHandleStatesBatch(ProdList);

                const ordermstList = data.ordermstDTO || [];
                setOrdermstList(ordermstList);

                const payList = data.payInfoDTO || [];
                const filterPayList = payList.filter((pay) => pay.payType !== 'PT3');
                setPayinfoList(filterPayList);

                const recInfo = data.recinfoDTO || [];
                setRecinfoList(recInfo);

                const reqInfo = data.reqmnDTO || [];
                setReqinfoList(reqInfo);

                const memberInfo = data.memberDTO || [];
                setMemberInfo(memberInfo);

                if (data.invoiceFileName) {
                    loadInvoiceFromS3(data.invoiceFileName, origFileName);
                }

                setTagRemove(reqInfo.tagRemove);
                setShoesBoxRemove(reqInfo.shoesBoxRemove);
                setHangerRemove(reqInfo.hangerRemove);
                setCompressionPack(reqInfo.compressionPack);
                setProductBoxRemove(reqInfo.prodBoxRemove);
                setPackingHold(reqInfo.packingHold);
                setDeliveryDepositPay(reqInfo.shipPriceDepositpay);
                setDeliveryMessengerGuide(reqInfo.shipPriceMessenger);
                setDetailRequest(reqInfo.detailReq);
            })
            .catch(() => {
                console.log("주문서 데이터조회 실패")
            })
            .finally(() => {
                setIsLoading(false);
            })
    })


    useEffect(() => {
        if (ordNum && !runOrderProcessFl) {
            selectOrdnumData();
        }
    }, [ordNum, runOrderProcessFl]);

    const chatRef = useRef(null);
    const sortedPayinfoList = [...payinfoList].sort((a, b) => a.seqNum - b.seqNum);

    const [paypalPopupSt, setPaypalPopupSt] = useState(false);
    const paypalPopupClose = (() => {
        setIsPayBtLoading(false);
        setPaypalPopupSt(false);
    })

    const parseNumber = (value) => {
        if (typeof value === 'string') {
            return parseFloat(value.replace(/,/g, ''));
        }
        return value;
    };

    // 결제수단 출력조건
    // 뉴폼 또는 DB폼의 상품양식마다 결제대기 상태의 상품금액값이 하나라도 있거나 || 결제리스트(selectedPayList)마다 결제대기 && 상품타입 외 데이터가 하나라도 있을 경우 결제수단 보이게
    const [isOrderPay, setIsOrderPay] = useState(false);
    useEffect(() => {
        const isPay =
            sortedPayinfoList.some(info => info.payStatus === "PS0") ||
            (
                cartProductHandle.some(item => parseNumber(item.price) > 0) ||
                dbCartProductHandle.some(item => item.prodSt === 'IS01' || item.prodSt === 'IS07') ||
                orderProductHandle.some(item => parseNumber(item.price) > 0 || parseNumber(item.mallShipPrice) > 0) ||
                dbOrderProductHandle.some(item => item.prodSt === 'IS01' || item.prodSt === 'IS07') ||
                offlineProductHandle.some(item => parseNumber(item.price) > 0 || parseNumber(item.fee) > 0) ||
                dbOfflineProductHandle.some(item => item.prodSt === 'IS01' || item.prodSt === 'IS07') ||
                transferProductHandle.some(item => parseNumber(item.price) > 0 || parseNumber(item.fee) > 0) ||
                dbTransferProductHandle.some(item => item.prodSt === 'IS01' || item.prodSt === 'IS07') ||
                domesticProductHandle.some(item => parseNumber(item.price) > 0 || parseNumber(item.mallShipPrice) > 0 || parseNumber(item.fee) > 0) ||
                dbDomesticProductHandle.some(item => item.prodSt === 'IS01' || item.prodSt === 'IS07')
            );

        setIsOrderPay(isPay);
    }, [cartProductHandle, dbCartProductHandle, orderProductHandle, dbOrderProductHandle, offlineProductHandle, dbOfflineProductHandle, transferProductHandle, dbTransferProductHandle, domesticProductHandle, dbDomesticProductHandle, sortedPayinfoList]);

    // 이용약관 에러메시지 출력조건
    const agreeErrorMsgCondition =
        (!cartProductHandle.some(cart => cart.name && cart.name.length > 0) || cartServiceAgreeCheck) &&
        (!orderProductHandle.some(order => order.name && order.name.length > 0) || orderServiceAgreeCheck) &&
        (!offlineProductHandle.some(offline => offline.name && offline.name.length > 0) || offlineServiceAgreeCheck) &&
        (!transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0) || transferServiceAgreeCheck) &&
        (!domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0) || domesticServiceAgreeCheck) &&
        (!deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0) || deliveryServiceAgreeCheck);

    const hasCartProduct = cartProductHandle.some(cart => cart.name && cart.name.length > 0);
    const hasBothCartAndDbCart = hasCartProduct && dbCartProductHandle.length > 0 && dbCartProductHandle.some(cart => cart.name && cart.name.length > 0);

    const hasOrderProduct = orderProductHandle.some(order => order.name && order.name.length > 0);
    const hasBothOrderAndDbOrder = hasOrderProduct && dbOrderProductHandle.length > 0 && dbOrderProductHandle.some(order => order.name && order.name.length > 0);

    const hasOfflineProduct = offlineProductHandle.some(offline => offline.name && offline.name.length > 0);
    const hasBothOfflineAndDbOffline = hasOfflineProduct && dbOfflineProductHandle.length > 0 && dbOfflineProductHandle.some(offline => offline.name && offline.name.length > 0);

    const hasTransferProduct = transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0);
    const hasBothTransferAndDbTransfer = hasTransferProduct && dbTransferProductHandle.length > 0 && dbTransferProductHandle.some(transfer => transfer.name && transfer.name.length > 0);

    const hasDomesticProduct = domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0);
    const hasBothDomesticAndDbDomestic = hasDomesticProduct && dbDomesticProductHandle.length > 0 && dbDomesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0);

    const hasDeliveryProduct = deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0);
    const hasBothDeliveryAndDbDelivery = hasDeliveryProduct && dbDeliveryProductHandle.length > 0 && dbDeliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0);

    const [commonError, setCommonError] = useState(false);
    const closeCommonError = (() => {
        setCommonError(false);
    })
    const validation = (saveFl) => {
        if (recinfoList?.countryDTO?.countryCode === "ID" && !recinfoList.taxid) {
            setCommonError(true);
            setIsSaveBtLoading(false);
            setIsPayBtLoading(false);
            return false;
        }
        if (!orderdtList.shipco) {
            setCommonError(true);
            return false;
        }
        if (!recinfoList.recname) {
            setCommonError(true);
            return false;
        }
        if (!recinfoList.address1) {
            setCommonError(true);
            return false;
        }
        if (recinfoList.countryDTO.countryCode !== "KR" && !recinfoList.city) {
            setCommonError(true);
            return false;
        }
        if (recinfoList.countryDTO.countryCode !== "KR" && !recinfoList.zipcode) {
            setCommonError(true);
            return false;
        }
        if (!recinfoList.telnum) {
            setCommonError(true);
            return false;
        }
        if (!recinfoList.country) {
            setCommonError(true);
            return false;
        }

        // 상품 인풋양식들 검증
        if ((cartProductHandle.length > 0 && !scrollToEmptyFieldCart()) || (orderProductHandle.length > 0 && !scrollToEmptyFieldOrder()) ||
            (offlineProductHandle.length > 0 && !scrollToEmptyFieldOffline()) || (transferProductHandle.length > 0 && !scrollToEmptyFieldTransfer()) ||
            (domesticProductHandle.length > 0 && !scrollToEmptyFieldDomestic()) || (deliveryProductHandle.length > 0 && !scrollToEmptyFieldDelivery()) ||
            (dbCartProductHandle.length > 0 && !scrollToEmptyFieldDbCart()) || (dbOrderProductHandle.length > 0 && !scrollToEmptyFieldDbOrder()) ||
            (dbOfflineProductHandle.length > 0 && !scrollToEmptyFieldDbOffline()) || (dbTransferProductHandle.length > 0 && !scrollToEmptyFieldDbTransfer()) ||
            (dbDomesticProductHandle.length > 0 && !scrollToEmptyFieldDbDomestic()) || (dbDeliveryProductHandle.length > 0 && !scrollToEmptyFieldDbDelivery())) {
            return false;
        }

        // 각 폼마다 입력했으면 저장이 아닌 결제일 때 동의란에 체크하지 않았을 경우 Error
        if (hasCartProduct && (!hasBothCartAndDbCart || !cartServiceAgreeCheck)) {
            setAgreeCheckBoxError(true);
            return false;
        }

        if (hasOrderProduct && (!hasBothOrderAndDbOrder || !orderServiceAgreeCheck)) {
            setAgreeCheckBoxError(true);
            return false;
        }

        if (hasOfflineProduct && (!hasBothOfflineAndDbOffline || !offlineServiceAgreeCheck)) {
            setAgreeCheckBoxError(true);
            return false;
        }

        if (hasTransferProduct && (!hasBothTransferAndDbTransfer || !transferServiceAgreeCheck)) {
            setAgreeCheckBoxError(true);
            return false;
        }

        if (hasDomesticProduct && (!hasBothDomesticAndDbDomestic || !domesticServiceAgreeCheck)) {
            setAgreeCheckBoxError(true);
            return false;
        }

        if (hasDeliveryProduct && (!hasBothDeliveryAndDbDelivery || !deliveryServiceAgreeCheck)) {
            setAgreeCheckBoxError(true);
            return false;
        }

        // isOrderPay(결제수단)이 true(출력)인데 결제수단을 선택하지 않았을 경우 Error
        if (isOrderPay && payWay === '' && !saveFl) {
            setPayMethodError(true);
            return false;
        }
        //  || 예치금이 아닌 다른 결제수단으로 시도할 경우 Error
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) === 0 && (payWay !== 'PM04' && payWay !== 'PM05') && !saveFl) {
            setPointPayError(true);
            return false;
        }
        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원이고 결제수단이 예치금결제인데 예치금을 사용하지 않았을 경우
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) === 0 && payWay === "PM05" && depositSale === '' && !saveFl) {
            setPointSelectErr(true);
            return false;
        }
        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원이고 결제수단이 포인트결제인데 포인트나 쿠폰을 사용하지 않았을 경우
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) === 0 && payWay === "PM04" && (pointSale === '' && couponSale === '') && !saveFl) {
            setDepositSelectErr(true);
            return false;
        }
        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원이고 포인트 사용금액이나 쿠폰 사용금액이 0보다 큰데 결제수단이 포인트결제나 예치금결제가 아닐 경우
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) === 0 && (pointSale !== '' && depositSale !== '') && couponSale > 0 && (payWay !== 'PM04' && payWay !== 'PM05') && !saveFl) {
            setDepositSelectErr(true);
            return false;
        }
        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원보다 큰데 포인트 || 예치금으로 결제시도할 경우 Error
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) > 0 && (payWay === "PM04" || payWay === "PM05") && !saveFl) {
            setEtcPayError(true);
            return false;
        }
        if (Number(finalPayExpense.replace(/,/g, '')) < 0) {
            setDiscountError(true);
            return false;
        }

        return true;
    };


    const handleSubmit = (btType) => {
        if (isSaveBtDisabled || isPayBtDisabled) return;
        let saveFl;
        if (btType === "save") {
            saveFl = true;
            setIsSaveBtLoading(true);
            setIsSaveBtDisabled(true);
        } else if (btType === "pay") {
            saveFl = false;
            setIsPayBtLoading(true);
            setIsPayBtDisabled(true);
        }
        try {
            const cartProductFormValid = cartProductHandle.every((item) => item.name !== '') || dbCartProductHandle.every((item) => item.name !== '');
            const orderProductFormValid = orderProductHandle.every((item) => item.name !== '') || dbOrderProductHandle.every((item) => item.name !== '');
            const offlineProductFormValid = offlineProductHandle.every((item) => item.name !== '') || dbOfflineProductHandle.every((item) => item.name !== '');
            const transferProductFormValid = transferProductHandle.every((item) => item.name !== '') || dbTransferProductHandle.every((item) => item.name !== '');
            const domesticProductFormValid = domesticProductHandle.every((item) => item.name !== '') || dbDomesticProductHandle.every((item) => item.name !== '');
            const deliveryProductFormValid = deliveryProductHandle.every((item) => item.name !== '') || dbDeliveryProductHandle.every((item) => item.name !== '');

            const orderInputCheck = (cartProductFormValid && (cartProductHandle.length > 0 || dbCartProductHandle.length > 0)) ||
                (orderProductFormValid && (orderProductHandle.length > 0 || dbOrderProductHandle.length > 0)) ||
                (offlineProductFormValid && (offlineProductHandle.length > 0 || dbOfflineProductHandle.length > 0)) ||
                (transferProductFormValid && (transferProductHandle.length > 0 || dbTransferProductHandle.length > 0)) ||
                (domesticProductFormValid && (domesticProductHandle.length > 0 || dbDomesticProductHandle.length > 0)) ||
                (deliveryProductFormValid && (deliveryProductHandle.length > 0 || dbDeliveryProductHandle.length > 0));

            if (validation(saveFl) && orderInputCheck) {
                // 결제수단이 페이팔이 아니면서 save 버튼을 클릭했을 때
                if (payWay !== "PM01" && saveFl) {
                    orderDataSave(true);
                    // 결제수단이 페이팔이 아니면서 Pay Now 버튼을 클릭했을 때
                } else if (payWay !== "PM01" && !saveFl) {
                    orderDataSave(false);
                    // 결제수단이 페이팔이면서 Pay Now 버튼을 클릭했을 때
                } else if (payWay === "PM01" && !saveFl){
                    setPaypalPopupSt(true);
                }
            } else {
                scrollToEmptyFieldCart();
                scrollToEmptyFieldOrder();
                scrollToEmptyFieldOffline();
                scrollToEmptyFieldTransfer();
                scrollToEmptyFieldDomestic();
                scrollToEmptyFieldDelivery();
                scrollToEmptyFieldDbCart();
                scrollToEmptyFieldDbOrder();
                scrollToEmptyFieldDbOffline();
                scrollToEmptyFieldDbTransfer();
                scrollToEmptyFieldDbDomestic();
                scrollToEmptyFieldDbDelivery();
                throw new Error("유효성검사 실패");
            }
        } catch {
            if (saveFl) {
                setIsSaveBtLoading(false);
            } else {
                setIsPayBtLoading(false);
            }
        } finally {
            if (saveFl) {
                setIsSaveBtDisabled(false);
            } else {
                setIsPayBtDisabled(false);
            }
        }
    };


    const addrBookCloseFn = (() => {
        setOrderAddrBookSt(false);
    })

    const shipCode = "1"
    const bringToShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + shipCode,
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
    })
        .then((response) => {
            setDeliveryChoice(response.data)
        })

    const DomesticShipCode = "0"
    const bringToDomesticShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + DomesticShipCode,
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
    })
        .then((response) => {
            setDomesticDeliveryChoice(response.data)
        })

    const recieverInfoAccordionFn = () => {
        setRecieverInfoAccordion(!recieverInfoAccordion);
    }
    const cartOrderAccordionFn = () => {
        setCartOrderAccordion(!cartOrderAccordion)
    }
    const orderAccordionFn = () => {
        setOrderAccordion(!orderAccordion)
    }
    const offlineAccordionFn = () => {
        setOfflineAccordion(!offlineAccordion)
    }
    const transferAccordionFn = () => {
        setTransferAccordion(!transferAccordion)
    }
    const domesticAccordionFn = () => {
        setDomesticAccordion(!domesticAccordion)
    }
    const deliveryAccordionFn = () => {
        setDeliveryAccordion(!deliveryAccordion)
    }
    const invoiceAccordionFn = () => {
        setInvoiceAccordion(!invoiceAccordion)
    }
    const requestConfigAccordionFn = () => {
        setRequestConfigAccordion(!requestConfigAccordion)
    }
    const payListAccordionFn = () => {
        setPayListAccordion(!payListAccordion)
    }

    const [inputMallPwEncryption, setInputMallPwEncryption] = useState({});
    const toggleMallPassword = (index) => {
        setInputMallPwEncryption(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const [inputMallDbPwEncryption, setInputMallDbPwEncryption] = useState({});
    const toggleMallDbPassword = (index) => {
        setInputMallDbPwEncryption(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const tagRemoveCheck = ((e) => {
        if (e.target.checked) {
            setTagRemove(true);
        } else setTagRemove(false);
    })
    const shoesBoxRemoveCheck = ((e) => {
        if (e.target.checked) {
            setShoesBoxRemove(true);
        } else setShoesBoxRemove(false);
    })
    const hangerRemoveCheck = ((e) => {
        if (e.target.checked) {
            setHangerRemove(true);
        } else setHangerRemove(false);
    })
    const compressionCheck = ((e) => {
        if (e.target.checked) {
            setCompressionPack(true);
        } else setCompressionPack(false);
    })
    const prodBoxRemoveCheck = ((e) => {
        if (e.target.checked) {
            setProductBoxRemove(true);
        } else setProductBoxRemove(false);
    })
    const packingHoldCheck = ((e) => {
        if (e.target.checked) {
            setPackingHold(true);
        } else setPackingHold(false);
    })
    const deliDepositPayCheck = ((e) => {
        if (e.target.checked) {
            setDeliveryDepositPay(true);
        } else setDeliveryDepositPay(false);
    })
    const deliMsgGuideCheck = ((e) => {
        if (e.target.checked) {
            setDeliveryMessengerGuide(true);
        } else setDeliveryMessengerGuide(false);
    })
    const detailRequestInput = ((e) => {
        setDetailRequest(e.target.value)
    })

    // add form payinfo update
    const updatePayinfoAddform = useCallback(() => {
        let addFormTotalPrices = 0;
        let addFormTotalMallShipPrice = 0;
        let addFormTotalFee = 0;
        let addFormTotalQty = 0;

        cartProductHandle.forEach((product) => {
            if (product.price) {
                addFormTotalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.quantity) {
                addFormTotalQty += parseInt(product.quantity);
            }
        });
        orderProductHandle.forEach((product) => {
            if (product.price) {
                addFormTotalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.mallShipPrice) {
                addFormTotalMallShipPrice += (parseInt(product.mallShipPrice.replace(/,/g, '')));
            }
            if (product.quantity) {
                addFormTotalQty += parseInt(product.quantity);
            }
        });
        offlineProductHandle.forEach((product) => {
            if (product.price) {
                addFormTotalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.quantity) {
                addFormTotalQty += parseInt(product.quantity);
            }
            if (product.fee) {
                addFormTotalFee += (parseInt(product.fee.replace(/,/g, '')));
            }
        });
        transferProductHandle.forEach((product) => {
            if (product.price) {
                addFormTotalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.fee) {
                addFormTotalFee += (parseInt(product.fee.replace(/,/g, '')))
            }
        });
        domesticProductHandle.forEach((product) => {
            if (product.price) {
                addFormTotalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.mallShipPrice) {
                addFormTotalMallShipPrice += (parseInt(product.mallShipPrice.replace(/,/g, '')));
            }
            if (product.fee) {
                addFormTotalFee += (parseInt(product.fee.replace(/,/g, '')));
            }
            if (product.quantity) {
                addFormTotalQty += parseInt(product.quantity);
            }
        });
        setTotalPrices(addFormTotalPrices);
        setTotalMallShipPrice(addFormTotalMallShipPrice);
        setTotalFee(addFormTotalFee);
        setTotalQuantity(addFormTotalQty);
    }, [cartProductHandle, orderProductHandle, offlineProductHandle, transferProductHandle, domesticProductHandle]);


    // database form payinfo update
    const updatePayinfoDbform = useCallback(() => {
        let dbFormTotalPrices = 0;
        let dbFormTotalMallShipPrice = 0;
        let dbFormTotalFee = 0;
        let dbFormTotalQty = 0;

        dbCartProductHandle.forEach((product) => {
            if (product.prodSt === "IS01") {
                if (product.price) {
                    const price = product.price.toString().replace(/,/g, '');
                    dbFormTotalPrices += parseInt(price);
                }
                if (product.quantity) {
                    dbFormTotalQty += parseInt(product.quantity);
                }
            }
        });

        dbOrderProductHandle.forEach((product) => {
            if (product.prodSt === "IS01") {
                if (product.price) {
                    const price = product.price.toString().replace(/,/g, '');
                    dbFormTotalPrices += parseInt(price);
                }
                if (product.mallShipPrice) {
                    const mallShipPrice = product.mallShipPrice.toString().replace(/,/g, '');
                    dbFormTotalMallShipPrice += parseInt(mallShipPrice);
                }
                if (product.quantity) {
                    dbFormTotalQty += parseInt(product.quantity);
                }
            }
        });

        dbOfflineProductHandle.forEach((product) => {
            if (product.prodSt === "IS01") {
                if (product.price) {
                    const price = product.price.toString().replace(/,/g, '');
                    dbFormTotalPrices += parseInt(price);
                }
                if (product.quantity) {
                    dbFormTotalQty += parseInt(product.quantity);
                }
                if (product.fee) {
                    const fee = product.fee.toString().replace(/,/g, '');
                    dbFormTotalFee += parseInt(fee);
                }
            }
        });

        dbTransferProductHandle.forEach((product) => {
            if (product.prodSt === "IS01") {
                if (product.price) {
                    const price = product.price.toString().replace(/,/g, '');
                    dbFormTotalPrices += parseInt(price);
                }
                if (product.fee) {
                    const fee = product.fee.toString().replace(/,/g, '');
                    dbFormTotalFee += parseInt(fee);
                }
            }
        });

        dbDomesticProductHandle.forEach((product) => {
            if (product.prodSt === "IS01") {
                if (product.price) {
                    const price = product.price.toString().replace(/,/g, '');
                    dbFormTotalPrices += parseInt(price);
                }
                if (product.mallShipPrice) {
                    const mallShipPrice = product.mallShipPrice.toString().replace(/,/g, '');
                    dbFormTotalMallShipPrice += parseInt(mallShipPrice);
                }
                if (product.fee) {
                    const fee = product.fee.toString().replace(/,/g, '');
                    dbFormTotalFee += parseInt(fee);
                }
                if (product.quantity) {
                    dbFormTotalQty += parseInt(product.quantity);
                }
            }
        });

        setDbFormTotalPrices(dbFormTotalPrices);
        setDbFormTotalMallShipPrice(dbFormTotalMallShipPrice);
        setDbFormTotalFee(dbFormTotalFee);
        setDbFormTotalQuantity(dbFormTotalQty);
    }, [dbCartProductHandle, dbOrderProductHandle, dbOfflineProductHandle, dbTransferProductHandle, dbDomesticProductHandle]);


    useEffect(() => {
        const timer = setTimeout(() => {
            bringToShipCo();
            bringToDomesticShipCo();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])


    const allAgreeHandle = (e) => {
        const isChecked = e.target.checked;

        setAllAgreeCheck(isChecked);
        setAgreeCheckBoxError(false);

        if (cartProductHandle.some(cart => cart.name && cart.name.length > 0)) {
            setCartServiceAgreeCheck(isChecked);
        }
        if (orderProductHandle.some(order => order.name && order.name.length > 0)) {
            setOrderServiceAgreeCheck(isChecked);
        }
        if (offlineProductHandle.some(offline => offline.name && offline.name.length > 0)) {
            setOfflineServiceAgreeCheck(isChecked);
        }
        if (transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0)) {
            setTransferServiceAgreeCheck(isChecked);
        }
        if (domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0)) {
            setDomesticServiceAgreeCheck(isChecked);
        }
        if (deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0)) {
            setDeliveryServiceAgreeCheck(isChecked);
        }
    };

    useEffect(() => {
        const visibleConditions = [
            cartProductHandle.some(cart => cart.name && cart.name.length > 0),
            orderProductHandle.some(order => order.name && order.name.length > 0),
            offlineProductHandle.some(offline => offline.name && offline.name.length > 0),
            transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0),
            domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0),
            deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0)
        ];

        const checkedConditions = [
            cartServiceAgreeCheck,
            orderServiceAgreeCheck,
            offlineServiceAgreeCheck,
            transferServiceAgreeCheck,
            domesticServiceAgreeCheck,
            deliveryServiceAgreeCheck
        ];

        const visibleCheckedCount = visibleConditions.map((condition, index) => {
            if (condition) {
                return checkedConditions[index];
            }
            return null;
        }).filter(checked => checked !== null).length;

        const checkedCount = checkedConditions.filter(checked => checked).length;

        if (visibleCheckedCount === checkedCount) {
            setAllAgreeCheck(true);
        } else {
            setAllAgreeCheck(false);
        }
    }, [cartServiceAgreeCheck, orderServiceAgreeCheck, offlineServiceAgreeCheck, transferServiceAgreeCheck, domesticServiceAgreeCheck, deliveryServiceAgreeCheck, cartProductHandle, orderProductHandle, offlineProductHandle, transferProductHandle, domesticProductHandle, deliveryProductHandle]);

    const handleAgreeChange = (checkboxSetter) => (e) => {
        checkboxSetter(e.target.checked);
        setAgreeCheckBoxError(false);
    };


    const [printFileDownIcon, setPrintFileDownIcon] = useState(false);
    const [fileSizeOverSt, setFileSizeOverSt] = useState(false);
    const closeFileSizeOverSt = (() => {
        setFileSizeOverSt(false);
    })
    const photoChangeHandler = (e) => {
        const file = e.target.files[0];
        const maxSize = 128 * 1024 * 1024; // 최대파일크기 128MB(백엔드에 맞추기)
        if (file.size > maxSize) {
            setFileSizeOverSt(true);
            return;
        }
        setInvoiceFile(file);
        setFileName(file.name)

        const newFileUrl = URL.createObjectURL(file);
        setFileUrl(newFileUrl);
    };

    const invoiceDownload = () => {
        const link = document.createElement('a');
        link.href = fileUrl; // Assuming fileUrl is the URL created from the S3 blob
        link.download = fileName; // This ensures the file is downloaded with the correct filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const loadInvoiceFromS3 = (invoiceFileName, oriFileNm) => {
        axios({
            method: "get",
            url: "/order/getInvoiceFile",
            responseType: "blob",
            headers: {
                Authorization: LCAT ? LCAT : SSAT,
            },
            params: {
                fileName: invoiceFileName,
            },
        }).then((response) => {
            // 다운아이콘 보이게
            setPrintFileDownIcon(true);
            // 파일의 원본 생성 날짜를 설정하기 위해 사용할 예시 날짜 (테스트용으로 현재 날짜를 사용)
            const lastModified = Date.now();
            // Blob 대신 File 객체 생성
            const file = new File([response.data], oriFileNm, {
                type: response.data.type,
                lastModified: lastModified
            });

            const url = URL.createObjectURL(file);
            setInvoiceFile(file); // File 객체를 상태로 설정
            setFileUrl(url); // URL도 업데이트
        });
    };

    const beforeBackendRemoveComma = (str) => {
        return String(str).replace(/,/g, '');
    }

    // 컨트롤러에 결제대기나 뉴폼만 전송
    const filterModifiedFields = (productList) => {
        return productList.filter((product) => product.prodSt === "IS01" || product.prodSt === "IS00" || product.newProd === true);
    };

    const orderProductDataList = () => {
        const orderProductDTO = {};

        const formatPrice = (productList) => {
            return productList.map(product => {
                if (product.price) product.price = beforeBackendRemoveComma(product.price);
                if (product.mallShipPrice) product.mallShipPrice = beforeBackendRemoveComma(product.mallShipPrice);
                if (product.fee) product.fee = beforeBackendRemoveComma(product.fee);
                return product;
            });
        };

        if (mergedCartProducts.length > 0) {
            orderProductDTO.cartProductHandle = formatPrice(filterModifiedFields(mergedCartProducts));
        }
        if (mergedOrderProducts.length > 0) {
            orderProductDTO.orderProductHandle = formatPrice(filterModifiedFields(mergedOrderProducts));
        }
        if (mergedOfflineProducts.length > 0) {
            orderProductDTO.offlineProductHandle = formatPrice(filterModifiedFields(mergedOfflineProducts));
        }
        if (mergedTransferProducts.length > 0) {
            orderProductDTO.transferProductHandle = formatPrice(filterModifiedFields(mergedTransferProducts));
        }
        if (mergedDomesticProducts.length > 0) {
            orderProductDTO.domesticProductHandle = formatPrice(filterModifiedFields(mergedDomesticProducts));
        }
        if (mergedDeliveryProducts.length > 0) {
            orderProductDTO.deliveryProductHandle = formatPrice(filterModifiedFields(mergedDeliveryProducts));
        }

        return orderProductDTO;
    };

    const orderRequest = {
        seqnum: reqinfoList.seqnum,
        tagRemove: tagRemove,
        shoesBoxRemove: shoesBoxRemove,
        hangerRemove: hangerRemove,
        compressionPack: compressionPack,
        prodBoxRemove: productBoxRemove,
        packingHold: packingHold,
        shipPriceDepositpay: deliveryDepositPay,
        shipPriceMessenger: deliveryMessengerGuide,
        detailReq: detailRequest
    }


    const handlePayWayChange = (method) => {
        const initPaypal = method === "PM01";
        // 준비중(준비되면 initPaypal과 통합)
        const initDollar = method === "PM06" || method === "PM07" || method === "PM08";
        const initYuan = method === "PM02" || method === "PM03";
        if (initPaypal) {
            setCurrency("USD")
        } else if (initDollar) {
            setReadyingPopupSt(true);
            return;
        } else if (initYuan) {
            setCurrency("CNY")
        } else {
            setCurrency("")
        }
        setPayWay(method);
        setPayMethodError(false);
        setPointPayError(false);
        setDepositSelectErr(false);
        setPointSelectErr(false);
        setEtcPayError(false);
    }

    const closeReadyingPopup = (() => {
        setReadyingPopupSt(false);
    })

    const [paypalPayFl, setPaypalPayFl] = useState(false);
    const [couponSeqnum, setCouponSeqnum] = useState('')
    const removeCommas = (str) => {
        return str.replace(/,/g, '');
    }

    const orderFinalPayInfo = {
        pointUsePrice: pointSale ? pointSale : 0,
        depUsePrice: depositSale ? depositSale : 0,
        couponUseNum: couponSeqnum,
        couponUsePrice: couponSale ? couponSale : 0,
        lastPrice: finalPayExpense,
        lastForPrice: lastForPrice,
        forCurrency: currency,
        ttProdPrice: finalProdExpense,
        ttMallShipPrice: finalMallShipPrice,
        etcFee: finalFee,
        ttEtcFee: finalFee,
        ttProdExpense: (Number(removeCommas(finalProdExpense)) + Number(removeCommas(finalMallShipPrice))),
        ttEtcPrice: finalEtcExpense,
        ttShipExpense: finalOverseasShipExpense,
        payStatus: "PS1",
        payWay: payWay,
        payClass: '0',
    }

    const ordermstData = {
        seqnum: ordermstList.seqnum,
        oriOrdNum: ordermstList.oriOrdNum,
    }

    const orderdtData = {
        seqnum: orderdtList.seqnum,
        orderStatus: orderdtList.orderStatus,
        orderNumber: orderdtList.orderNumber,
        useType: orderdtList.useType,
        shipco: orderdtList.shipco,
        cartAgree: !!(mergedCartProducts.some(cart => cart.name && cart.name.length > 0) && cartServiceAgreeCheck || orderdtList.cartAgree),
        orderAgree: !!(mergedOrderProducts.some(order => order.name && order.name.length > 0) && orderServiceAgreeCheck || orderdtList.orderAgree),
        offlineAgree: !!(mergedOfflineProducts.some(offline => offline.name && offline.name.length > 0) && offlineServiceAgreeCheck || orderdtList.offlineAgree),
        transferAgree: !!(mergedTransferProducts.some(transfer => transfer.name && transfer.name.length > 0) && transferServiceAgreeCheck || orderdtList.transferAgree),
        domesticAgree: !!(mergedDomesticProducts.some(domestic => domestic.name && domestic.name.length > 0) && domesticServiceAgreeCheck || orderdtList.domesticAgree),
        shipAgree: !!(mergedDeliveryProducts.some(delivery => delivery.name && delivery.name.length > 0) && deliveryServiceAgreeCheck || orderdtList.deliveryAgree),
    }

    const convertedOrderFinalPrice = Object.entries(orderFinalPayInfo).reduce((acc, [key, value]) => {
        if (key === 'payStatus' || key === 'payClass' || key === 'payWay' || key === 'forCurrency') {
            acc[key] = value;
        } else if (typeof value === 'number' && !Number.isInteger(value)) {
            acc[key] = parseFloat(String(value).replace(',', ''));
        } else {
            acc[key] = parseInt(String(value).replace(',', ''));
        }
        return acc;
    }, {});

    const [deletedProductsData, setDeletedProductsData] = useState({
        deletedDbCartProducts: [],
        deletedDbOrderProducts: [],
        deletedDbOfflineProducts: [],
        deletedDbTransferProducts: [],
        deletedDbDomesticProducts: [],
        deletedDbDeliveryProducts: [],
    });


    const orderDataSave = async (data) => {
        if (data === "COMPLETED") {
            setPaypalPayFl(true);
        } else if (data) {
            await sendOrderDataToServer(true);
        } else {
            await sendOrderDataToServer(false);
        }
    };

    useEffect(() => {
        if (paypalPayFl) {
            sendOrderDataToServer(false);
        }
    }, [paypalPayFl]);

    const sendOrderDataToServer = (saveFl) => {

        if (saveFl) {
            convertedOrderFinalPrice.pointUsePrice = 0;
            convertedOrderFinalPrice.depUsePrice = 0;
            convertedOrderFinalPrice.couponUseNum = '';
            convertedOrderFinalPrice.couponUsePrice = 0;
            convertedOrderFinalPrice.lastPrice = totalPrices + totalMallShipPrice + totalFee + totalOverseasShippingExpense + totalEtcExpense;
        }

        const orderProductListSave = orderProductDataList();
        const selectedPayinfos = getSelectedPayinfos();

        const formData = new FormData();
        if (invoiceFile) {
            formData.append('file', invoiceFile);
        }

        formData.append('orderdtData', JSON.stringify(orderdtData));
        formData.append('ordermstData', JSON.stringify(ordermstData));
        formData.append('orderRequest', JSON.stringify(orderRequest));
        formData.append('orderFinalPayInfo', JSON.stringify(convertedOrderFinalPrice));
        formData.append('orderProductListSave', JSON.stringify(orderProductListSave));
        formData.append("deletedProductsData", JSON.stringify(deletedProductsData));
        formData.append('selectedPayinfos', JSON.stringify(selectedPayinfos));
        formData.append('couponSeqnum', couponSeqnum);

        selectedPayinfos.forEach((payInfo, index) => {
            formData.append(`selectedPayinfos[${payInfo.seqNum}]`, JSON.stringify(payInfo));
            if (payInfo.packListDTO && payInfo.packListDTO.productsDTO) {
                formData.append(`selectedPayinfos[${payInfo.seqNum}].packListDTO.productsDTO`, JSON.stringify(payInfo.packListDTO.productsDTO));
            }
        })

        axios({
            method: 'post',
            url: '/order/orderModify',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                paypalPayFl: paypalPayFl,
                orderSaveFl: saveFl
            }
        })
            .then(async (response) => {
                const {allProducts, ...filterData} = response.data;
                const data = response.data;
                // 관리자메인에 오더알림업뎃
                saveOrderAlarmData(filterData);
                // 수정 후 전체 하위상품상태에 따라 orderSt 변경
                orderStatusUpdate(stompClient, allProducts, response.data.orderdtSeqNum);

                if ((data.payWay === "PM01" || data.payWay === "PM04" || data.payWay === "PM05") && !saveFl) {
                    navigate("/UserPayComplPg", {state: {data: data}})
                } else if ((data.payWay === "PM02" || data.payWay === "PM03" || data.payWay === "PM00") && !saveFl) {
                    navigate("/UserPayWaitPg", {state: {data: data}})
                } else {
                    navigate("/UserMyPg")
                }
            })
            .catch((error) => {
                console.log('order fail : ', error);
            })
            .finally(() => {
                setIsSaveBtLoading(false);
                setIsPayBtLoading(false);
            })
    };

    const saveOrderAlarmData = ((orderData) => {
        axios({
            method: 'post',
            url: '/order/saveOrderData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT,
                'Content-Type': 'application/json',
            },
            data: orderData
        })
            .then(async () => {
                await stompClient.send("/app/sendOrderAlarm", {}, JSON.stringify({sendFl: true}));
            })
            .catch((error) => {
                console.log("알림데이터 저장실패 : ", error)
            })
    })


    const [dollarShow, setDollarShow] = useState(false);
    const dollarRef = useRef(null);

    const toggleDollarShow = () => {
        setDollarShow(!dollarShow);
    };

    const selectChangeDollar = (e) => {
        setCurrency(e);
        setDollarShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (dollarRef.current && !dollarRef.current.contains(event.target)) {
                setDollarShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const paypalOrderProcess = (() => {
        paypalPopupClose();
        orderDataSave("COMPLETED");
    })


    const updateProductHandleStatesBatch = useCallback((products) => {
        const cartProducts = [];
        const orderProducts = [];
        const offlineProducts = [];
        const transferProducts = [];
        const domesticProducts = [];
        const deliveryProducts = [];

        products.forEach(product => {
            const productData = {...product};
            switch (product.prodTy) {
                case 'P00':
                    cartProducts.push(productData);
                    break;
                case 'P01':
                    orderProducts.push(productData);
                    break;
                case 'P02':
                    offlineProducts.push(productData);
                    break;
                case 'P03':
                    transferProducts.push(productData);
                    break;
                case 'P04':
                    domesticProducts.push(productData);
                    break;
                case 'P05':
                    deliveryProducts.push(productData);
                    break;
            }
        });

        setDbCartProductHandle(cartProducts);
        setDbOrderProductHandle(orderProducts);
        setDbOfflineProductHandle(offlineProducts);
        setDbTransferProductHandle(transferProducts);
        setDbDomesticProductHandle(domesticProducts);
        setDbDeliveryProductHandle(deliveryProducts);
    }, []);

    const checkProductTypes = () => {
        const allProducts = [
            ...dbCartProductHandle,
            ...dbOrderProductHandle,
            ...dbOfflineProductHandle,
            ...dbTransferProductHandle,
            ...dbDomesticProductHandle,
            ...dbDeliveryProductHandle,
        ];

        return allProducts.every(product => product.prodSt === "IS00" || product.prodSt === "IS01" || product.prodSt === "IS12");
    }

    const [deleteOrderModalShowSt, setDeleteOrderModalShowSt] = useState(false);
    const deleteOrderMsg1 = t(`modModal1`);
    const deleteOrderMsg2 = t(`modModal2`);
    const deleteOrderFalseFn = (() => {
        setDeleteOrderModalShowSt(false);
    })
    const deleteOrderTrueFn = (() => {
        setDeleteOrderModalShowSt(true);
    })

    const deleteOrder = (() => {
        axios({
            method: 'post',
            url: '/order/deleteOrder',
            data: {
                orderdtSeqnum: orderdtList.seqnum,
                ordermstSeqnum: ordermstList.seqnum,
                orderRequestSeqnum: reqinfoList.seqnum,
                orderRecinfoSeqnum: recinfoList.seqnum,
                invoiceFileName: orderdtList.invoiceFileName
                // 여기에 왜 PayInfoVO는 없음?
                // 백엔드에서 orderdtSeqnum으로 여러 payinfo 삭제중
            },
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then(() => {
                setDeleteOrderModalShowSt(false);
                navigate("/UserMyPg")
            })
            .catch(() => {
                console.log("삭제실패")
            })
    })

    const [recmodiSt, setRecmodiSt] = useState(false);
    const [addressUpdated, setAddressUpdated] = useState(false);
    const orderRecmodiTrueFn = (() => {
        setRecmodiSt(true);
    })
    const recmodiFalseFn = (() => {
        setRecmodiSt(false);
        setConfirmModalShowSt(true);
        setAddressUpdated(!addressUpdated);
    })

    const recmodiCloseFn = (() => {
        setRecmodiSt(false);
    })

    const [confirmModalShowSt, setConfirmModalShowSt] = useState(false);
    const confirmModalFalseFn = (() => {
        setConfirmModalShowSt(false);
    })
    const recmodiModalMsg = t(`modModal3`);


    const [updatedRecipientInfo, setUpdatedRecipientInfo] = useState(null);

    useEffect(() => {
        if (updatedRecipientInfo) {
            setRecinfoList((prevState) => {
                if (!Array.isArray(prevState)) {
                    return { ...prevState, ...updatedRecipientInfo };
                } else {
                    return [
                        {
                            ...prevState[0],
                            ...updatedRecipientInfo,
                        },
                        ...prevState.slice(1),
                    ];
                }
            });
        }
    }, [updatedRecipientInfo, ordNum]);

    const location = useLocation();
    // 상품폼 중복맵핑방지
    useEffect(() => {
        setDbCartProductHandle([]);
        setDbOrderProductHandle([]);
        setDbOfflineProductHandle([]);
        setDbTransferProductHandle([]);
        setDbDomesticProductHandle([]);
        setDbDeliveryProductHandle([]);
    }, [location.key]);

    useEffect(() => {
        setCartOrderAccordion(dbCartProductHandle.length > 0);
        setOrderAccordion(dbOrderProductHandle.length > 0);
        setOfflineAccordion(dbOfflineProductHandle.length > 0);
        setTransferAccordion(dbTransferProductHandle.length > 0);
        setDomesticAccordion(dbDomesticProductHandle.length > 0);
        setDeliveryAccordion(dbDeliveryProductHandle.length > 0);
    }, [dbCartProductHandle, dbOrderProductHandle, dbOfflineProductHandle, dbTransferProductHandle, dbDomesticProductHandle, dbDeliveryProductHandle]);

    // 채팅메시지 입력
    const [message, setMessage] = useState('');
    const [chatMessages, setChatMessages] = useState([]);

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!memberInfo.mbNum || isSubscribedRef.current) return;
            if (!stompClient && !isConnected) {
                await websocketConnect(memberInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                subscribe(`/user/${memberInfo.mbNum}/queue/adminMessage`, onMessageReceived);
                // 관리자읽음처리
                subscribe(`/user/${memberInfo.mbNum}/queue/adminRead`, onReadMessageReceived);
                // 관리자수정
                subscribe(`/user/${memberInfo.mbNum}/queue/adminChatUpdate`, onChatAdminUpdate);
                // 관리자 prodSt 업뎃
                subscribe(`/user/${memberInfo.mbNum}/queue/adminProdStUpdate`, receivedProdStChange);
                // 관리자 order 분리/통합
                subscribe(`/user/${memberInfo.mbNum}/queue/adminOrderDivideMerge`, receivedOrderDivideMerge);
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                unsubscribe(`/user/${memberInfo.mbNum}/queue/adminMessage`)
                unsubscribe(`/user/${memberInfo.mbNum}/queue/adminRead`)
                unsubscribe(`/user/${memberInfo.mbNum}/queue/adminChatUpdate`)
                unsubscribe(`/user/${memberInfo.mbNum}/queue/adminProdStUpdate`)
                unsubscribe(`/user/${memberInfo.mbNum}/queue/adminOrderDivideMerge`)
                isSubscribedRef.current = false;
            }
        };

    }, [memberInfo.mbNum, isConnected, stompClient]);







    const receivedProdStChange = (payload) => {
        let updateDataList = JSON.parse(payload.body);
        const updateFunctions = {
            "P00": setDbCartProductHandle,
            "P01": setDbOrderProductHandle,
            "P02": setDbOfflineProductHandle,
            "P03": setDbTransferProductHandle,
            "P04": setDbDomesticProductHandle,
            "P05": setDbDeliveryProductHandle
        };

        // 관리자가 변경한 updateDataList.prodTy에 따라 변경된 prodSt로 실시간업뎃
        updateDataList.forEach(updateData => {
            const updateFunction = updateFunctions[updateData.prodTy];
            if (updateFunction) {
                updateFunction(prevState => prevState.map(product => {
                    return product.seqnum === updateData.seqnum ? {...product, prodSt: updateData.prodSt} : product;
                }));
            }
        });
    }


    const [orderDivideMergeSt, setOrderDivideMergeSt] = useState(false);
    const moveToMyPg = (() => {
        navigate("/UserMyPg");
    })
    const receivedOrderDivideMerge = ((payload) => {
        let data = JSON.parse(payload.body);
        // 팝업띄우고 확인 클릭하면 마이페이지
        setOrderDivideMergeSt(true);
    })


    const [unreadCount, setUnreadCount] = useState(null);
    const [lastChatKey, setLastChatKey] = useState(null);
    const [lastFileKey, setLastFileKey] = useState(null);
    const [chatShow, setChatShow] = useState(false);

    const orderListRef = useRef(orderdtList.orderNumber);
    const chatShowRef = useRef(chatShow);

    // 함수 내 비동기로 인해 미리 값 세팅
    useEffect(() => {
        orderListRef.current = orderdtList.orderNumber;
        chatShowRef.current = chatShow;
    }, [orderdtList, chatShow]);

    const onMessageReceived = (payload) => {
        let newMessage = JSON.parse(payload.body);
        if (newMessage.chatKey.startsWith('chatFile')) {
            const filename = newMessage.chatKey.split('/').pop();
            if (isVideo(filename)) {
                newMessage.isVideo = true;
            } else if (isImage(filename)) {
                newMessage.isImage = true;
            } else {
                newMessage.isFile = true;
            }
        }

        setChatMessages(chatMessages => {
            const newChatMessages = [...chatMessages, newMessage];
            newChatMessages.sort((a, b) => b.seqnum - a.seqnum);
            return newChatMessages;
        });

        fetchChatMessages(newMessage.chatOrdNum)

        // 새로 등록된 메시지의 chatOrdNum과 내 주문번호와 동일 && chatStarted가 true 두 조건 모두 충족할 경우 자동읽음처리
        if (newMessage.chatOrdNum === orderListRef.current && chatShowRef.current) {
            resetUnreadChat(newMessage);
            stompClientRead(newMessage.chatOrdNum, newMessage.mstUserNm, newMessage.mstMbNum);
        }
    };


    const isImage = (fileName) => {
        const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp"];
        const fileExtension = fileName.split('.').pop().toLowerCase();
        return imageExtensions.includes(fileExtension);
    }

    const sendMessage = async (printFilename, originalFilename) => {
        if (stompClient && printFilename.trim() !== '') {
            const chatMessage = {
                chatKey: printFilename,
                fileNm: originalFilename,
                chatOrdNum: Number(orderdtList.orderNumber),
                mbNum: memberInfo.mbNum,
                mstMbNum: memberInfo.mbNum,
                mstUserNm: memberInfo.userName,
                email: memberInfo.id,
                sendMailFl: false,
                autoChatFl: false,
            };
            await stompClient.send("/app/chat", {}, JSON.stringify(chatMessage));
            setChatShow(true);
            chatInputRef.current.style.height = '35px'

            await new Promise(resolve => setTimeout(resolve, 500));
            fetchChatMessages(chatMessage.chatOrdNum);

            await stompClient.send("/app/chat/totalCount", {}, JSON.stringify({sendFl: true}));
        } else {
            console.log("메시지 전송실패");
        }
    };

    const fetchChatMessages = async (chatOrdNum) => {
        if (!chatOrdNum) {
            return;
        }
        try {
            const response = await axios({
                method: 'get',
                url: '/order/chatFetch',
                headers: {
                    Authorization: LCAT ? LCAT : SSAT
                },
                params: {
                    chatOrdNum: chatOrdNum
                }
            });
            if (!response || response.data === false) {
                console.log('데이터없음');
                return;
            }
            const data = response.data;
            data.sort((a, b) => a.seqnum - b.seqnum);

            for (let message of data) {
                if (message.chatKey.startsWith('chatFile')) {
                    const filename = message.chatKey.split('/').pop();
                    if (isVideo(filename)) {
                        message.fileUrl = 'https://toeloperfile.s3.ap-northeast-2.amazonaws.com/ordChatMessage/' + message.chatKey;
                        message.isVideo = true;
                    } else if (isImage(filename)) {
                        message.fileUrl = 'https://toeloperfile.s3.ap-northeast-2.amazonaws.com/ordChatMessage/' + message.chatKey;
                        message.isImage = true;
                    } else {
                        message.fileUrl = 'file';
                    }
                }

                if (message &&
                    message.memberDTO &&
                    message.memberDTO.profileDTO &&
                    message.memberDTO.profileDTO.filename &&
                    message.memberDTO.role === '1') {
                    setChatProfileImg('https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/' + message.memberDTO.profileDTO.filename);
                    break;
                }
            }
            setChatMessages(data);


            if (data && data.length > 0) {
                if (data[0].memberDTO.role === '1') {
                    setUnreadCount(data[0].unreadCount);
                }
                setLastChatKey(data[0].chatKey);
                setLastFileKey(data[0].fileNm);
            }

            return data;

        } catch (error) {
            console.error('Error fetching chat messages:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (orderdtList) {
            fetchChatMessages(orderdtList && orderdtList.orderNumber)
                .then(data => setChatMessages(data))
                .catch(err => console.error(err));
        }
    }, [orderdtList]);

    const onReadMessageReceived = (payload) => {
        let adminReadData = JSON.parse(payload.body);
        if (adminReadData.readerRole === "ADMIN") {
            setChatMessages(currentChatMessages => {
                const updatedMessages = [...currentChatMessages];
                let hasUpdates = false;
                for (let message of updatedMessages) {
                    if (message.chatOrdNum === String(adminReadData.chatOrdNum) && message.unreadCount > 0 && message.memberDTO.role === '0') {
                        message.unreadCount = 0;
                        hasUpdates = true;
                    }
                }
                return updatedMessages;
            })
        }
    };

    const onChatAdminUpdate = ((payload) => {
        let adminUpdateData = JSON.parse(payload.body);
        if (adminUpdateData !== null) {
            fetchChatMessages(adminUpdateData);
        }
    })

    const messagesEndRef = useRef(null);
    const scrollToBottom = (() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTo({
                top: messagesEndRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    })
    useEffect(scrollToBottom, [chatMessages]);

    const getDayOfWeek = (date) => {
        const dayOfWeek = new Date(date).getDay(); // 일요일은 0, 월요일은 1, ... 토요일은 6
        return [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat'),][dayOfWeek];
    };

    const formatDate = (dateString) => {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);

        // 요일 계산
        const date = new Date(`${year}/${month}/${day}`);
        const dayOfWeek = getDayOfWeek(date);

        return `${year}/${month}/${day} ${dayOfWeek}`;
    };

    function isSameDay(date1, date2) {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }

    function isFirstMessageOfDay(current, previous) {
        if (!previous) {
            return true; // 이전 메시지가 없다면 현재 메시지는 당연히 첫 번째 메시지입니다.
        }
        // 날짜 문자열을 분리하여 Date 객체를 생성합니다.
        const currentYear = current.regDate.substring(0, 4);
        const currentMonth = current.regDate.substring(4, 6);
        const currentDay = current.regDate.substring(6, 8);
        const currentDate = new Date(`${currentYear}-${currentMonth}-${currentDay}`);

        const previousYear = previous.regDate.substring(0, 4);
        const previousMonth = previous.regDate.substring(4, 6);
        const previousDay = previous.regDate.substring(6, 8);
        const previousDate = new Date(`${previousYear}-${previousMonth}-${previousDay}`);

        return !isSameDay(currentDate, previousDate);
    }

    const formatTime = (timeString) => {
        const parts = timeString.split(/[- :]/);
        const date = new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
        return date.toTimeString().split(' ')[0];
    };

    const [chatProfileImg, setChatProfileImg] = useState(boxLogo);

    const chatFrameHandle = (() => {
        setChatShow(true);
        resetUnreadChat(chatMessages[0]);
    })


    const resetUnreadChat = ((newMessage) => {
        axios({
            method: 'post',
            url: `/order/chat/unread/`,
            params: {
                chatOrdNum: newMessage.chatOrdNum,
                userFl: true
            },
            headers: {
                Authorization: LCAT
            }
        })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error("HTTP status " + response.status);
                } else {
                    setUnreadCount(0);
                    stompClientRead(newMessage.chatOrdNum, newMessage.mstUserNm, newMessage.mstMbNum);
                }
            })
            .catch(error => console.error(error));
    })

    const stompClientRead = ((chatOrdNum, mstUserNm, mstMbNum) => {
        if (!stompClient) {
            console.log("읽음전송실패")
            return;
        }
        const readChat = {
            chatOrdNum: chatOrdNum,
            mstMbNum: mstMbNum,
            mstUserNm: mstUserNm,
            readerRole: "USER"
        };
        stompClient.send("/app/chat/read", {}, JSON.stringify(readChat));
    })


    const [inputChatValue, setInputChatValue] = useState('');
    const [showEmoji, setShowEmoji] = useState(false);
    const [showChatFile, setShowChatFile] = useState(false);

    const [selectedFileUrl, setSelectedFileUrl] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleEmojiClick = (emoji) => {
        const newMessage = message + emoji.native;
        setMessage(newMessage);
        setShowEmoji(false);
    };

    const fileInputRef = useRef();
    const handleFileUpload = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedFile(file);
            setSelectedFileUrl(URL.createObjectURL(file));
        }
    };


    const saveChatFile = async () => {
        if (!selectedFile || !selectedFile.size) {
            setShowChatFile(false);
            return;
        }
        const MAX_FILE_SIZE = 128 * 1024 * 1024; // 최대파일크기 128MB(백엔드에 맞추기)
        if (selectedFile.size > MAX_FILE_SIZE) {
            setShowChatFile(false);
            resetFileInput();
            setFileSizeOverSt(true);
            return;
        }
        const formData = new FormData();
        formData.append('file', selectedFile);
        axios({
            method: 'post',
            url: '/order/upload',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            data: formData
        })
            .then((response) => {
                const fileKeys = response.data;
                sendMessage(fileKeys.printFilename, fileKeys.originalFilename);
                setChatShow(true);
                setShowChatFile(false);
                resetFileInput();
            })
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
        setShowChatFile(true);
    };

    const showChatFileClose = (() => {
        setShowChatFile(false);
        resetFileInput();
    })

    const resetFileInput = () => {
        fileInputRef.current.value = "";
        setSelectedFile(null);
        setSelectedFileUrl(null);
    };

    const downloadFile = async (filename, originalFilename, type) => {
        const response = await axios({
            method: 'get',
            url: `/order/chatFileDownload/${filename}`,
            params: {
                type: type
            },
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', originalFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setSelectedClickSt(false);
        openDownloadFileCompl();
    };
    const [downloadFileComplSt, setDownloadFileComplSt] = useState(false);
    const openDownloadFileCompl = (() => {
        setDownloadFileComplSt(true);
    })
    const closeDownloadFileCompl = (() => {
        setDownloadFileComplSt(false);
    })

    const isVideo = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        return ['mp4', 'webm', 'ogg'].includes(extension);
    };

    const oneMonthFromNow = (deadline) => {
        return deadline.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    }

    // 챗메시지 인풋조절
    const chatInputRef = React.useRef(null);
    const ordChatMaxTextAreaHeight = 400; // 최대 높이

    // const resizeTextArea = () => {
    //     const textArea = chatInputRef.current;
    //     // 최소 높이 설정 후 스크롤 높이에 맞게 늘어나도록 함
    //     textArea.style.height = '35px';
    //     textArea.style.height = textArea.scrollHeight + "px";
    // };

    const resizeTextArea = () => {
        const textArea = chatInputRef.current;
        const chatBody = messagesEndRef.current;

        // 초기 높이 설정
        const initialChatBodyHeight = 510;
        const initialTextAreaHeight = 35;

        textArea.style.height = `${initialTextAreaHeight}px`; // 초기 높이로 설정
        const newHeight = Math.min(textArea.scrollHeight, ordChatMaxTextAreaHeight); // 최대 높이 제한

        textArea.style.height = `${newHeight}px`; // 높이 설정

        // 높이 계산
        chatBody.style.height = `calc(${initialChatBodyHeight} - ${newHeight - initialTextAreaHeight}px)`;
        scrollToBottom();
    };

    const handleChatInput = (e) => {
        setMessage(e.target.value);
        resizeTextArea();
    };


    const [detailState, setDetailState] = useState({
        prod: false,
        delivery: false,
        etc: false,
        adminProd: false,
        paySeqnum: null,
    });

    const showDetail = ((type, paySeqnum) => {
        // set the state of the selected type to true and others to false
        setDetailState(prevState => ({
            ...prevState,
            prod: type === 'prod',
            delivery: type === 'delivery',
            etc: type === 'etc',
            adminProd: type === 'adminProd',
            paySeqnum: paySeqnum,
        }));
    })

    const closeDetail = (() => {
        setDetailState({prod: false, delivery: false, etc: false, adminProd: false});
    })

    const [selectedPayInfos, setSelectedPayInfos] = useState({});
    const [allSelectedPaylist, setAllSelectedPaylist] = useState(false);
    const [selectedOverseasShippingCost, setSelectedOverseasShippingCost] = useState({});
    const [lastSelectedOverseasShippingCost, setLastSelectedOverseasShippingCost] = useState({});

    const handlePaylistCheckboxChange = (seqNum, isChecked) => {
        setSelectedPayInfos(prevState => {
            return {...prevState, [seqNum]: isChecked};
        });

        if (isChecked && lastSelectedOverseasShippingCost[seqNum]) {
            setSelectedOverseasShippingCost(prevState => {
                return {...prevState, [seqNum]: lastSelectedOverseasShippingCost[seqNum]};
            });
        } else if (!isChecked) {
            setLastSelectedOverseasShippingCost(prevState => {
                return {...prevState, [seqNum]: selectedOverseasShippingCost[seqNum]};
            });
            setSelectedOverseasShippingCost(prevState => {
                return {...prevState, [seqNum]: ''};
            });
        }
    };
    const handleAllPaylistCheckboxChange = (isChecked) => {
        setAllSelectedPaylist(isChecked);
        const newSelectedPayInfos = {};
        const newSelectedOverseasShippingCost = {};
        payinfoList.forEach((payInfo, index) => {
            newSelectedPayInfos[payInfo.seqNum] = isChecked;
            // 선택된 해외배송료[array]는 payInfo에 합산
            if (isChecked && lastSelectedOverseasShippingCost[payInfo.seqNum]) {
                newSelectedOverseasShippingCost[payInfo.seqNum] = lastSelectedOverseasShippingCost[payInfo.seqNum];
            } else if (!isChecked) {
                lastSelectedOverseasShippingCost[payInfo.seqNum] = selectedOverseasShippingCost[payInfo.seqNum];
                newSelectedOverseasShippingCost[payInfo.seqNum] = '';
            }
        });
        setSelectedPayInfos(newSelectedPayInfos);
        setSelectedOverseasShippingCost(newSelectedOverseasShippingCost);
    };

    useEffect(() => {
        const allItemsChecked = Object.values(selectedPayInfos).every(val => val === true);
        setAllSelectedPaylist(allItemsChecked);
    }, [selectedPayInfos]);

    const handleRadioChange = (seqNum, value) => {
        setSelectedOverseasShippingCost(prevState => {
            return {...prevState, [seqNum]: value};
        });
        setLastSelectedOverseasShippingCost(prevState => {
            return {...prevState, [seqNum]: value};
        });
    };

    // 결제리스트에서 선택한 해외배송 payinfo 데이터들
    const getSelectedPayinfos = () => {
        return payinfoList.filter(payInfo => {
            const id = payInfo.seqNum;
            if (payInfo.payStatus !== "PS0") {
                return false;
            }
            if (payInfo.payType === "PT1") {
                return selectedPayInfos[id] === true;
            }
            if (payInfo.payType !== "PT1") {
                return true;
            }
        }).map(payInfo => {
            const id = payInfo.seqNum;
            let newPayInfo = {...payInfo};

            if (selectedOverseasShippingCost[id] === 'FedEx') {
                newPayInfo.fedexPrice = payInfo.fedexPrice
                newPayInfo.fedexFuelSurch = payInfo.fedexFuelSurch
                newPayInfo.fedexAddFee = payInfo.fedexAddFee
                newPayInfo.fedexInsrFee = payInfo.fedexInsrFee
                newPayInfo.fedexOutOfArea = payInfo.fedexOutOfArea
                newPayInfo.ttFedexPrice = payInfo.ttFedexPrice

                newPayInfo.emsPrice = 0;
                newPayInfo.emsAddFee = 0;
                newPayInfo.emsInsrFee = 0;
                newPayInfo.ttEmsPrice = 0;

                newPayInfo.seaPrice = 0;
                newPayInfo.seaAddFee = 0;
                newPayInfo.seaInsrFee = 0;
                newPayInfo.ttSeaPrice = 0;

                newPayInfo.ttDomPrice = 0;

                newPayInfo.dhlPrice = 0;
                newPayInfo.dhlAddFee = 0;
                newPayInfo.dhlInsrFee = 0;
                newPayInfo.ttDhlPrice = 0;

                newPayInfo.upsPrice = 0;
                newPayInfo.upsFuelSurch = 0;
                newPayInfo.upsAddFee = 0;
                newPayInfo.upsInsrFee = 0;
                newPayInfo.upsOutOfArea = 0;
                newPayInfo.ttUpsPrice = 0;

                newPayInfo.kPackPrice = 0;
                newPayInfo.kPackAddFee = 0;
                newPayInfo.kPackInsrFee = 0;
                newPayInfo.ttKPackPrice = 0;

                newPayInfo.airSPrice = 0;
                newPayInfo.airSAddFee = 0;
                newPayInfo.airSInsrFee = 0;
                newPayInfo.ttAirSPrice = 0;

            } else if (selectedOverseasShippingCost[id] === 'EMS') {

                newPayInfo.fedexPrice = 0;
                newPayInfo.fedexFuelSurch = 0;
                newPayInfo.fedexAddFee = 0;
                newPayInfo.fedexInsrFee = 0;
                newPayInfo.fedexOutOfArea = 0;
                newPayInfo.ttFedexPrice = 0;

                newPayInfo.emsPrice = payInfo.emsPrice
                newPayInfo.emsAddFee = payInfo.emsAddFee
                newPayInfo.emsInsrFee = payInfo.emsInsrFee
                newPayInfo.ttEmsPrice = payInfo.ttEmsPrice

                newPayInfo.seaPrice = 0;
                newPayInfo.seaAddFee = 0;
                newPayInfo.seaInsrFee = 0;
                newPayInfo.ttSeaPrice = 0;

                newPayInfo.ttDomPrice = 0;

                newPayInfo.dhlPrice = 0;
                newPayInfo.dhlAddFee = 0;
                newPayInfo.dhlInsrFee = 0;
                newPayInfo.ttDhlPrice = 0;

                newPayInfo.upsPrice = 0;
                newPayInfo.upsFuelSurch = 0;
                newPayInfo.upsAddFee = 0;
                newPayInfo.upsInsrFee = 0;
                newPayInfo.upsOutOfArea = 0;
                newPayInfo.ttUpsPrice = 0;

                newPayInfo.kPackPrice = 0;
                newPayInfo.kPackAddFee = 0;
                newPayInfo.kPackInsrFee = 0;
                newPayInfo.ttKPackPrice = 0;

                newPayInfo.airSPrice = 0;
                newPayInfo.airSAddFee = 0;
                newPayInfo.airSInsrFee = 0;
                newPayInfo.ttAirSPrice = 0;

            } else if (selectedOverseasShippingCost[id] === 'Sea') {

                newPayInfo.fedexPrice = 0;
                newPayInfo.fedexFuelSurch = 0;
                newPayInfo.fedexAddFee = 0;
                newPayInfo.fedexInsrFee = 0;
                newPayInfo.fedexOutOfArea = 0;
                newPayInfo.ttFedexPrice = 0;

                newPayInfo.emsPrice = 0;
                newPayInfo.emsAddFee = 0;
                newPayInfo.emsInsrFee = 0;
                newPayInfo.ttEmsPrice = 0;

                newPayInfo.seaPrice = payInfo.seaPrice
                newPayInfo.seaAddFee = payInfo.seaAddFee
                newPayInfo.seaInsrFee = payInfo.seaInsrFee
                newPayInfo.ttSeaPrice = payInfo.ttSeaPrice

                newPayInfo.ttDomPrice = 0;

                newPayInfo.dhlPrice = 0;
                newPayInfo.dhlAddFee = 0;
                newPayInfo.dhlInsrFee = 0;
                newPayInfo.ttDhlPrice = 0;

                newPayInfo.upsPrice = 0;
                newPayInfo.upsFuelSurch = 0;
                newPayInfo.upsAddFee = 0;
                newPayInfo.upsInsrFee = 0;
                newPayInfo.upsOutOfArea = 0;
                newPayInfo.ttUpsPrice = 0;

                newPayInfo.kPackPrice = 0;
                newPayInfo.kPackAddFee = 0;
                newPayInfo.kPackInsrFee = 0;
                newPayInfo.ttKPackPrice = 0;

                newPayInfo.airSPrice = 0;
                newPayInfo.airSAddFee = 0;
                newPayInfo.airSInsrFee = 0;
                newPayInfo.ttAirSPrice = 0;

            } else if (selectedOverseasShippingCost[id] === 'Dom'){
                newPayInfo.fedexPrice = 0;
                newPayInfo.fedexFuelSurch = 0;
                newPayInfo.fedexAddFee = 0;
                newPayInfo.fedexInsrFee = 0;
                newPayInfo.fedexOutOfArea = 0;
                newPayInfo.ttFedexPrice = 0;

                newPayInfo.emsPrice = 0;
                newPayInfo.emsAddFee = 0;
                newPayInfo.emsInsrFee = 0;
                newPayInfo.ttEmsPrice = 0;

                newPayInfo.seaPrice = 0;
                newPayInfo.seaInsrFee = 0;
                newPayInfo.ttSeaPrice = 0;

                newPayInfo.dhlPrice = 0;
                newPayInfo.dhlAddFee = 0;
                newPayInfo.dhlInsrFee = 0;
                newPayInfo.ttDhlPrice = 0;

                newPayInfo.upsPrice = 0;
                newPayInfo.upsFuelSurch = 0;
                newPayInfo.upsAddFee = 0;
                newPayInfo.upsInsrFee = 0;
                newPayInfo.upsOutOfArea = 0;
                newPayInfo.ttUpsPrice = 0;

                newPayInfo.kPackPrice = 0;
                newPayInfo.kPackAddFee = 0;
                newPayInfo.kPackInsrFee = 0;
                newPayInfo.ttKPackPrice = 0;

                newPayInfo.airSPrice = 0;
                newPayInfo.airSAddFee = 0;
                newPayInfo.airSInsrFee = 0;
                newPayInfo.ttAirSPrice = 0;

                newPayInfo.ttDomPrice = payInfo.ttDomPrice
            } else if (selectedOverseasShippingCost[id] === 'DHL'){
                newPayInfo.fedexPrice = 0;
                newPayInfo.fedexFuelSurch = 0;
                newPayInfo.fedexAddFee = 0;
                newPayInfo.fedexInsrFee = 0;
                newPayInfo.fedexOutOfArea = 0;
                newPayInfo.ttFedexPrice = 0;

                newPayInfo.emsPrice = 0;
                newPayInfo.emsAddFee = 0;
                newPayInfo.emsInsrFee = 0;
                newPayInfo.ttEmsPrice = 0;

                newPayInfo.seaPrice = 0;
                newPayInfo.seaInsrFee = 0;
                newPayInfo.ttSeaPrice = 0;

                newPayInfo.dhlPrice = payInfo.dhlPrice
                newPayInfo.dhlAddFee = payInfo.dhlAddFee
                newPayInfo.dhlInsrFee = payInfo.dhlInsrFee
                newPayInfo.ttDhlPrice = payInfo.ttDhlPrice

                newPayInfo.upsPrice = 0;
                newPayInfo.upsFuelSurch = 0;
                newPayInfo.upsAddFee = 0;
                newPayInfo.upsInsrFee = 0;
                newPayInfo.upsOutOfArea = 0;
                newPayInfo.ttUpsPrice = 0;

                newPayInfo.kPackPrice = 0;
                newPayInfo.kPackAddFee = 0;
                newPayInfo.kPackInsrFee = 0;
                newPayInfo.ttKPackPrice = 0;

                newPayInfo.airSPrice = 0;
                newPayInfo.airSAddFee = 0;
                newPayInfo.airSInsrFee = 0;
                newPayInfo.ttAirSPrice = 0;

                newPayInfo.ttDomPrice = 0;
            } else if (selectedOverseasShippingCost[id] === 'UPS'){
                newPayInfo.fedexPrice = 0;
                newPayInfo.fedexFuelSurch = 0;
                newPayInfo.fedexAddFee = 0;
                newPayInfo.fedexInsrFee = 0;
                newPayInfo.fedexOutOfArea = 0;
                newPayInfo.ttFedexPrice = 0;

                newPayInfo.emsPrice = 0;
                newPayInfo.emsAddFee = 0;
                newPayInfo.emsInsrFee = 0;
                newPayInfo.ttEmsPrice = 0;

                newPayInfo.seaPrice = 0;
                newPayInfo.seaInsrFee = 0;
                newPayInfo.ttSeaPrice = 0;

                newPayInfo.dhlPrice = 0;
                newPayInfo.dhlAddFee = 0;
                newPayInfo.dhlInsrFee = 0;
                newPayInfo.ttDhlPrice = 0;

                newPayInfo.upsPrice = payInfo.upsPrice;
                newPayInfo.upsFuelSurch = payInfo.upsFuelSurch;
                newPayInfo.upsAddFee = payInfo.upsAddFee;
                newPayInfo.upsInsrFee = payInfo.upsInsrFee;
                newPayInfo.upsOutOfArea = payInfo.upsOutOfArea;
                newPayInfo.ttUpsPrice = payInfo.ttUpsPrice;

                newPayInfo.kPackPrice = 0;
                newPayInfo.kPackAddFee = 0;
                newPayInfo.kPackInsrFee = 0;
                newPayInfo.ttKPackPrice = 0;

                newPayInfo.airSPrice = 0;
                newPayInfo.airSAddFee = 0;
                newPayInfo.airSInsrFee = 0;
                newPayInfo.ttAirSPrice = 0;

                newPayInfo.ttDomPrice = 0;
            } else if (selectedOverseasShippingCost[id] === 'K-Packet'){
                newPayInfo.fedexPrice = 0;
                newPayInfo.fedexFuelSurch = 0;
                newPayInfo.fedexAddFee = 0;
                newPayInfo.fedexInsrFee = 0;
                newPayInfo.fedexOutOfArea = 0;
                newPayInfo.ttFedexPrice = 0;

                newPayInfo.emsPrice = 0;
                newPayInfo.emsAddFee = 0;
                newPayInfo.emsInsrFee = 0;
                newPayInfo.ttEmsPrice = 0;

                newPayInfo.seaPrice = 0;
                newPayInfo.seaInsrFee = 0;
                newPayInfo.ttSeaPrice = 0;

                newPayInfo.dhlPrice = 0;
                newPayInfo.dhlAddFee = 0;
                newPayInfo.dhlInsrFee = 0;
                newPayInfo.ttDhlPrice = 0;

                newPayInfo.upsPrice = 0;
                newPayInfo.upsFuelSurch = 0;
                newPayInfo.upsAddFee = 0;
                newPayInfo.upsInsrFee = 0;
                newPayInfo.upsOutOfArea = 0;
                newPayInfo.ttUpsPrice = 0;

                newPayInfo.kPackPrice = payInfo.kPackPrice
                newPayInfo.kPackAddFee = payInfo.kPackAddFee
                newPayInfo.kPackInsrFee = payInfo.kPackInsrFee
                newPayInfo.ttKPackPrice = payInfo.ttKPackPrice

                newPayInfo.airSPrice = 0;
                newPayInfo.airSAddFee = 0;
                newPayInfo.airSInsrFee = 0;
                newPayInfo.ttAirSPrice = 0;

                newPayInfo.ttDomPrice = 0;
            } else if (selectedOverseasShippingCost[id] === 'Small-Packet'){
                newPayInfo.fedexPrice = 0;
                newPayInfo.fedexFuelSurch = 0;
                newPayInfo.fedexAddFee = 0;
                newPayInfo.fedexInsrFee = 0;
                newPayInfo.fedexOutOfArea = 0;
                newPayInfo.ttFedexPrice = 0;

                newPayInfo.emsPrice = 0;
                newPayInfo.emsAddFee = 0;
                newPayInfo.emsInsrFee = 0;
                newPayInfo.ttEmsPrice = 0;

                newPayInfo.seaPrice = 0;
                newPayInfo.seaInsrFee = 0;
                newPayInfo.ttSeaPrice = 0;

                newPayInfo.dhlPrice = 0;
                newPayInfo.dhlAddFee = 0;
                newPayInfo.dhlInsrFee = 0;
                newPayInfo.ttDhlPrice = 0;

                newPayInfo.upsPrice = 0;
                newPayInfo.upsFuelSurch = 0;
                newPayInfo.upsAddFee = 0;
                newPayInfo.upsInsrFee = 0;
                newPayInfo.upsOutOfArea = 0;
                newPayInfo.ttUpsPrice = 0;

                newPayInfo.kPackPrice = 0;
                newPayInfo.kPackAddFee = 0;
                newPayInfo.kPackInsrFee = 0;
                newPayInfo.ttKPackPrice = 0;

                newPayInfo.airSPrice = payInfo.airSPrice
                newPayInfo.airSAddFee = payInfo.airSAddFee
                newPayInfo.airSInsrFee = payInfo.airSInsrFee
                newPayInfo.ttAirSPrice = payInfo.ttAirSPrice

                newPayInfo.ttDomPrice = 0;
            }

            if (payInfo.packListDTO && payInfo.packListDTO.productsDTO !== null) {
                if (!newPayInfo.packListDTO) {
                    newPayInfo.packListDTO = {};
                }
                newPayInfo.packListDTO.productsDTO = payInfo.packListDTO.productsDTO;
            }
            return newPayInfo;
        });
    };


    useEffect(() => {
        if (allSelectedPaylist) {
            handleAllPaylistCheckboxChange(true);
        }
    }, [allSelectedPaylist]);

    useEffect(() => {
        if (payinfoList && payinfoList.length > 0) {
            const initialSelectedPayInfos = {};
            const initialSelectedOverseasShippingCost = {};
            payinfoList.forEach((payinfo, index) => {
                // payinfo의 payStatus가 "PS0"인 경우에만 true로 설정, 그렇지 않으면 false로 설정
                initialSelectedPayInfos[payinfo.seqNum] = payinfo.payStatus === "PS0";
                initialSelectedOverseasShippingCost[payinfo.seqNum] = orderdtList.shipco;
            });
            setSelectedPayInfos(initialSelectedPayInfos);
            setSelectedOverseasShippingCost(initialSelectedOverseasShippingCost);
        }
    }, [payinfoList, orderdtList]);


    // 실시간 결제정보합산
    useEffect(() => {
        updatePayinfoAddform();
        updatePayinfoDbform();

        let totalEtcFee = 0;
        let totalEtcPrice = 0;
        payinfoList.forEach((payInfo) => {

            if (payInfo.payStatus === 'PS0') {
                totalEtcPrice += payInfo.ttEtcPrice;
                if (payInfo.payClass === '1') {
                    totalEtcFee += payInfo.ttEtcFee;
                }
            }
        });
        setDbFormTotalEtcExpense(totalEtcFee);
        setTotalEtcExpense(totalEtcPrice);

        let totalAdminProdPrice = 0;
        payinfoList.forEach((payInfo) => {
            if (payInfo.payStatus === 'PS0' && payInfo.payClass === '1') {
                totalAdminProdPrice += payInfo.ttProdExpense;
            }
        });
        setTotalAdminProdPrice(totalAdminProdPrice);

        let totalOverseasShippingExpense = 0;
        payinfoList.forEach((payInfo, index) => {
            if (
                selectedPayInfos[payInfo.seqNum] &&
                payInfo.payType === "PT1" &&
                payInfo.payStatus === "PS0"
            ) {
                switch (selectedOverseasShippingCost[payInfo.seqNum]) {
                    case 'FedEx':
                        totalOverseasShippingExpense += payInfo.ttFedexPrice;
                        break;
                    case 'EMS':
                        totalOverseasShippingExpense += payInfo.ttEmsPrice;
                        break;
                    case 'Sea':
                        totalOverseasShippingExpense += payInfo.ttSeaPrice;
                        break;
                    case 'Dom':
                        totalOverseasShippingExpense += payInfo.ttDomPrice;
                        break;
                    case 'DHL':
                        totalOverseasShippingExpense += payInfo.ttDhlPrice;
                        break;
                    case 'UPS':
                        totalOverseasShippingExpense += payInfo.ttUpsPrice;
                        break;
                    case 'K-Packet':
                        totalOverseasShippingExpense += payInfo.ttKPackPrice;
                        break;
                    case 'Small-Packet':
                        totalOverseasShippingExpense += payInfo.ttAirSPrice;
                        break;
                }
            }
        });
        setTotalOverseasShippingExpense(totalOverseasShippingExpense);
    }, [updatePayinfoAddform, updatePayinfoDbform, payinfoList, selectedPayInfos, selectedOverseasShippingCost]);

    const updatePayinfoList = (updatedPayinfo) => {
        setPayinfoList((prevPayinfoList) => {
            return prevPayinfoList.map((payinfo) =>
                payinfo.seqNum === updatedPayinfo.seqNum ? updatedPayinfo : payinfo,
            );
        });
    };


    const [useCouponSt, setUseCouponSt] = useState(false);
    const [dbCoupon, setDbCoupon] = useState('');
    const [biggerCpPriceSt, setBiggerCpPriceSt] = useState(false);

    const openBiggerCpPriceSt = (() => {
        setBiggerCpPriceSt(true);
    })
    const closeBiggerCpPriceSt = (() => {
        setBiggerCpPriceSt(false);
    })

    const showUseCoupon = (() => {
        if (Number(finalPayExpense.replace(/,/g, '')) === 0) {
            openBiggerCpPriceSt();
        } else {
            setUseCouponSt(true);
        }
    })

    const closeUseCoupon = (() => {
        setUseCouponSt(false);
        setCouponSale('');
        if (couponSale !== '') {
            setDbCoupon(dbCoupon + 1);
            setCouponSeqnum('');
        }
    })

    const couponUseFn = (coupon) => {
        if (coupon && coupon.couponMstDTO) {
            const couponPrice = coupon.couponMstDTO.couponPrice;
            const finalExpense = Number(finalPayExpense.replace(/,/g, ''));

            if (couponPrice > finalExpense) {
                setUseCouponSt(false);
                openBiggerCpPriceSt();
            } else {
                setCouponSale(couponPrice);
                setUseCouponSt(false);
                if (!couponSale && coupon) {
                    setDbCoupon(dbCoupon - 1);
                }
                setCouponSeqnum(coupon.seqNum);
            }
        }
    };

    const [dbPoint, setDbPoint] = useState('');
    useEffect(() => {
        setDbPoint(memberInfo && memberInfo.point);
        setDbDeposit(memberInfo && memberInfo.deposit);
        const activeCouponCount = memberInfo && memberInfo.coupons && memberInfo.coupons.filter(coupon => coupon && coupon.activeFl === true).length;
        setDbCoupon(activeCouponCount || 0);
    }, [memberInfo]);


    const handlePointSale = (e) => {
        let value = Number(e.replace(/[^0-9]/g, ''));
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            dbFormTotalEtcExpense + totalOverseasShippingExpense + totalEtcExpense - (Number(depositSale) + Number(couponSale)));
        // 입력값이 최종결제금액보다 크면 최종결제금액으로 제한
        if (value > finalExpense) {
            value = finalExpense;
        }

        // 입력값이 적립금잔액보다 크면 input에 잔액(맥시멈)으로 업뎃하고 잔액은 0으로 업뎃
        if (value > memberInfo.point) {
            setPointSale(memberInfo.point);
            setDbPoint('');
            // 입력값을 지우면 잔액은 되돌리고 input값은 0으로 업뎃
        } else if (value === 0 || value === '') {
            setPointSale('');
            setDbPoint(memberInfo.point);
        } else {
            // 이 외에는 input에 사용자입력값, 잔액은 입력값만큼만 차감
            setPointSale(value);
            setDbPoint(memberInfo.point - value);
        }
    };

    const allPointSale = () => {
        let maxPoint = memberInfo ? memberInfo.point : '';
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            dbFormTotalEtcExpense + totalOverseasShippingExpense + totalEtcExpense - (Number(depositSale) + Number(couponSale)));
        if (maxPoint > finalExpense) {
            maxPoint = finalExpense;
        }

        setPointSale(maxPoint);
        setDbPoint(memberInfo.point - maxPoint);
    };

    const [dbDeposit, setDbDeposit] = useState('');

    const handleDepositSale = (e) => {
        let value = Number(e.replace(/[^0-9]/g, ''));
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            dbFormTotalEtcExpense + totalOverseasShippingExpense + totalEtcExpense - (Number(pointSale) + Number(couponSale)));

        if (value > finalExpense) {
            value = finalExpense;
        }

        if (value > memberInfo.deposit) {
            setDepositSale(memberInfo.deposit);
            setDbDeposit('');
        } else if (value === 0 || value === '') {
            setDepositSale('');
            setDbDeposit(memberInfo.deposit);
        } else {
            setDepositSale(value);
            setDbDeposit(memberInfo.deposit - value);
        }
    };

    const allDepositSale = () => {
        let maxDeposit = memberInfo ? memberInfo.deposit : '';
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            dbFormTotalEtcExpense + totalOverseasShippingExpense + totalEtcExpense - (Number(pointSale) + Number(couponSale)));
        if (maxDeposit > finalExpense) {
            maxDeposit = finalExpense;
        }

        setDepositSale(maxDeposit);
        setDbDeposit(memberInfo.deposit - maxDeposit);
    };


    const [selectedClickFile, setSelectedClickFile] = useState(null);
    const [selectedClickSt, setSelectedClickSt] = useState(false);

    const clickFileDetail = ((file) => {
        setSelectedClickFile(file);
        setSelectedClickSt(true);
    })
    const clickFileClose = (() => {
        setSelectedClickSt(false);
    })


    // const [showPayNow, setShowPayNow] = useState(false);
    // useEffect(() => {
    //     const checkIfPayNowShouldBeShown = () => {
    //         // 데이터베이스 폼들 확인
    //         const dbFormsCheck = [
    //             ...dbCartProductHandle, ...dbOrderProductHandle, ...dbOfflineProductHandle,
    //             ...dbTransferProductHandle, ...dbDomesticProductHandle
    //         ].some(form =>
    //             form.prodSt === "IS01" &&
    //             (parseFloat(form.price || 0) > 0 ||
    //                 parseFloat(form.mallShipPrice || 0) > 0 ||
    //                 parseFloat(form.fee || 0) > 0)
    //         );
    //
    //         // 새로 추가된 폼들 확인
    //         const newFormsCheck = [
    //             ...cartProductHandle, ...orderProductHandle, ...offlineProductHandle,
    //             ...transferProductHandle, ...domesticProductHandle
    //         ].some(form =>
    //             parseFloat(form.price || 0) > 0 ||
    //             parseFloat(form.mallShipPrice || 0) > 0 ||
    //             parseFloat(form.fee || 0) > 0
    //         );
    //
    //         const payListCheck = [
    //             ...sortedPayinfoList
    //         ].some(pay =>
    //             pay.payStatus === "PS0" && (pay.lastPrice || 0) > 0
    //         ) && Object.values(selectedPayInfos).some(value => value === true);
    //
    //         // 상태 업데이트
    //         setShowPayNow(dbFormsCheck || newFormsCheck || payListCheck);
    //     };
    //
    //     checkIfPayNowShouldBeShown();
    // }, [
    //     dbCartProductHandle, dbOrderProductHandle, dbOfflineProductHandle, dbTransferProductHandle, dbDomesticProductHandle,
    //     cartProductHandle, orderProductHandle, offlineProductHandle, transferProductHandle, domesticProductHandle, sortedPayinfoList
    // ]);


    const [usdExrInfo, setUsdExrInfo] = useState([]);

    const selectedAllExrInfo = (() => {
        axios({
            method: 'get',
            url: '/paypal/selectedAllExrInfo/',
        })
            .then((response) => {
                setUsdExrInfo(response.data)
            })
            .catch(() => {
                console.log("환율조회실패")
            })
    })

    useEffect(() => {
        selectedAllExrInfo();
    }, [])

    const calculateLastForPrice = () => {
        if ((payWay === "PM01" || payWay === "PM02" || payWay === "PM03") && currency && usdExrInfo.length > 0) {
            const usdExInfo = usdExrInfo.find(info => info.curPrice === currency);
            const numericFinalPayExpense = parseFloat(finalPayExpense.replace(/,/g, ''));

            // 금액(50,000) / (환율(1333.14) - 요율(9.1%)) = 41.26usd
            if (!isNaN(numericFinalPayExpense) && usdExInfo) {
                const payRateAsRatio = usdExInfo.payRate / 100;
                const newLastForPrice = numericFinalPayExpense / (usdExInfo.payExr - (usdExInfo.payExr * payRateAsRatio));

                // console.log("usdExInfo : ", usdExInfo)
                // console.log("usdExInfo.payExr : ", usdExInfo.payExr)
                // console.log("payRateAsRatio : ", payRateAsRatio)
                // console.log("numericFinalPayExpense : ", numericFinalPayExpense)

                setLastForPrice(Number(newLastForPrice.toFixed(2)));
            }
        } else {
            setLastForPrice(null);
        }
    }

    useEffect(() => {
        calculateLastForPrice();
    }, [payWay, currency, usdExrInfo, finalPayExpense]);


    const prodStPrint = (prodSt) => {
        const prodStStyles = {
            IS00: {color: "#98B0D4", label: t(`prodStLabel1`)},
            IS01: {color: "#98B0D4", label: t(`prodStLabel2`)},
            IS02: {color: "#98B0D4", label: t(`prodStLabel3`)},
            IS03: {color: "#FFB378", label: t(`prodStLabel4`)},
            IS04: {color: "#FF8600", label: t(`prodStLabel5`)},
            IS05: {color: "#6EDA60", label: t(`prodStLabel6`)},
            IS06: {color: "#98B0D4", label: t(`prodStLabel7`)},
            IS07: {color: "#98B0D4", label: t(`prodStLabel8`)},
            IS08: {color: "#98B0D4", label: t(`prodStLabel9`)},
            IS09: {color: "#FFB378", label: t(`prodStLabel10`)},
            IS10: {color: "#979696", label: t(`prodStLabel11`)},
            IS11: {color: "#FF8F62", label: t(`prodStLabel12`)},
            IS12: {color: "#FF8F62", label: t(`prodStLabel13`)},
            IS13: {color: "#98B0D4", label: t(`prodStLabel14`)},
            IS14: {color: "#98B0D4", label: t(`prodStLabel15`)}
        };

        const style = prodStStyles[prodSt] || {color: "black", label: null};
        return <p style={{margin: "0", color: style.color, fontSize: "12px"}}>{style.label}</p>;
    };

    const [cartTermsPopup, setCartTermsPopup] = useState(false);
    const [orderTermsPopup, setOrderTermsPopup] = useState(false);
    const [offlineTermsPopup, setOfflineTermsPopup] = useState(false);
    const [transferTermsPopup, setTransferTermsPopup] = useState(false);
    const [domesticTermsPopup, setDomesticTermsPopup] = useState(false);
    const [deliveryTermsPopup, setDeliveryTermsPopup] = useState(false);

    const closeCartTermsPopup = (() => {
        setCartTermsPopup(false);
    })
    const closeOrderTermsPopup = (() => {
        setOrderTermsPopup(false);
    })
    const closeOfflineTermsPopup = (() => {
        setOfflineTermsPopup(false);
    })
    const closeTransferTermsPopup = (() => {
        setTransferTermsPopup(false);
    })
    const closeDomesticTermsPopup = (() => {
        setDomesticTermsPopup(false);
    })
    const closeDeliveryTermsPopup = (() => {
        setDeliveryTermsPopup(false);
    })

    const convertToLastChatDate = ((regDate) => {
        const regTime = new Date(regDate);
        const currentTime = new Date();
        const timeDifference = currentTime - regTime;

        // 날짜 차이를 분으로 변환
        const minutesDiff = Math.floor(timeDifference / 60000);
        const hoursDiff = Math.floor(minutesDiff / 60);
        const daysDiff = Math.floor(hoursDiff / 24);

        // 날짜 차이가 1일 미만일 때
        if (daysDiff < 1) {
            if (minutesDiff < 1) return t('now');
            if (minutesDiff < 60) return `${minutesDiff}${t('min')}`;
            return `${hoursDiff}${t('hour')}`;
        } else if (daysDiff === 1) {
            return t('yesterday');
        } else if (daysDiff === 2) {
            return t('2daysAgo');
        } else if (daysDiff < 7) {
            return `${daysDiff}${t('daysAgo')}`;
        } else {
            // 7일 이상 차이날 때는 월-일 형식으로 반환
            return `${(regTime.getMonth() + 1).toString().padStart(2, '0')}-${regTime.getDate().toString().padStart(2, '0')}`;
        }
    })

    const MOrderInput = (title, value, disabled, maxLength, ref, onChange, isPw, placeholder, children) => {
        return (
            <div className={'m-a-order-detail-input-box'} >
                {value.toString().length > 0 && <div className={'m-a-order-detail-input-title'}>{title}</div>}
                <input className={'m-a-order-detail-input m-b-5'} type={"text"} value={value} maxLength={maxLength}
                       ref={ref} disabled={disabled}
                       placeholder={placeholder ? title + ' : ' + placeholder : `${value.length === 0 ? title : ''}`}
                       onChange={onChange}/>
                {isPw &&
                    <div className={'m-a-order-detail-input-password'}>
                        {children}
                    </div>}
            </div>
        )
    }

    const MOrderPaymentRow = (title, value) => {
        return (
            <div className={'m-a-order-detail-section-payment-row'}>
                <div className={'m-a-order-detail-section-payment-row-text'}>{title}</div>
                <div className={'m-a-order-detail-section-payment-row-text'}>{value}</div>
            </div>
        )
    }

    return (
        <div className="m-a-order-detail-container font-t-cont">
            <MHeader title={`${t(`modRegModiBt`)}/${t(`dcPayBt`)}`} url={'/UserMyPg'}/>
            {orderdtList && (
                <div className="m-a-order-detail-ordNum-container">
                    <div className="m-a-order-detail-ordNum-frame flex-row just-cont-sb wd-100pc">
                        <div className="display-flex flex-row">
                            <img className="m-a-order-detail-ordNum-icon" src={detailpgOrdNum} alt=''/>
                            {/*<div className='font-t-title'>{t(`modNum`)} {orderdtList.orderNumber} </div>*/}
                            <div className="font-t-title font-s-18 p-t-3">{orderdtList.orderNumber}</div>
                        </div>
                        {
                            checkProductTypes() ?
                                <button onClick={deleteOrderTrueFn} className="admin-btn-B wd-70 ht-30 font-s-12">{t(`modPiBt3`)}</button>
                                :
                                null
                        }
                    </div>
                </div>
            )}
            <div className={'m-a-order-detail-divider'}/>
            {/*수취인정보*/}
            {recieverInfoAccordion && (
                <div className={'m-a-order-detail-table-container'}>
                    <div className={'m-a-order-detail-table'}>
                        {/*배송용도*/}
                        <div className={'m-a-order-detail-table-row'}>
                            <div className={'m-a-order-detail-table-row-label'}>{t(`modSmallMenu1`)}</div>
                            <div className={'m-a-order-detail-table-row-value'}>
                                {orderdtList.useType === "U0" ? t(`modCont1`) : orderdtList.useType === "U1" ? t(`modCont2`) : t(`modCont3`)}
                            </div>
                        </div>
                        {/*배송사선택*/}
                        <div className={'m-a-order-detail-table-row'}>
                            <div className={'m-a-order-detail-table-row-label'}>{t(`modSmallMenu2`)}</div>
                            <div className={'m-a-order-detail-table-row-value'}>{orderdtList?.shipco}</div>
                        </div>
                        {/*국가*/}
                        <div className={'m-a-order-detail-table-row'}>
                            <div className={'m-a-order-detail-table-row-label'}>{t(`modSmallMenu3`)}</div>
                            <div
                                className={'m-a-order-detail-table-row-value'}>{recinfoList?.countryDTO?.countryEngNm}</div>
                        </div>
                        {recinfoList?.countryDTO?.countryCode === "ID" &&
                            <div className={'m-a-order-detail-table-row'}>
                                <div className={'m-a-order-detail-table-row-label'}>Tax ID</div>
                                <div className={'m-a-order-detail-table-row-value'}>{recinfoList?.taxid}</div>
                            </div>
                        }
                        {/*성함*/}
                        <div className={'m-a-order-detail-table-row'}>
                            <div className={'m-a-order-detail-table-row-label'}>{t(`modSmallMenu4-1`)}</div>
                            <div className={'m-a-order-detail-table-row-value'}>{recinfoList?.recname}</div>
                        </div>
                        {/*연락처*/}
                        <div className={'m-a-order-detail-table-row'}>
                            <div className={'m-a-order-detail-table-row-label'}>{t(`modSmallMenu5`)}</div>
                            <div className={'m-a-order-detail-table-row-value'}>{recinfoList?.telnum}</div>
                        </div>
                        {/*우편번호*/}
                        {recinfoList?.countryDTO?.countryCode !== "KR" &&
                            <div className={'m-a-order-detail-table-row'}>
                                <div className={'m-a-order-detail-table-row-label'}>{t(`modSmallMenu6`)}</div>
                                <div className={'m-a-order-detail-table-row-value'}>{recinfoList?.zipcode}</div>
                            </div>
                        }
                        {/*주소1*/}
                        <div className="m-a-order-detail-table-row">
                            <div className="m-a-order-detail-table-row-label">{t(`modSmallMenu7`)}</div>
                            <div className="m-a-order-detail-table-row-value admin-tb-text-cut">{recinfoList?.address1}</div>
                        </div>
                        {/*주소2*/}
                        {recinfoList?.countryDTO?.countryCode !== "KR" &&
                            <div className={'m-a-order-detail-table-row'}>
                                <div className={'m-a-order-detail-table-row-label'}>{t(`modSmallMenu8`)}</div>
                                <div className={'m-a-order-detail-table-row-value admin-tb-text-cut'}>{recinfoList?.address2}</div>
                            </div>
                        }
                        {/*주소3*/}
                        {recinfoList?.countryDTO?.countryCode !== "KR" &&
                            <div className={'m-a-order-detail-table-row'}>
                                <div className={'m-a-order-detail-table-row-label'}>{t(`modSmallMenu9`)}</div>
                                <div className={'m-a-order-detail-table-row-value admin-tb-text-cut'}>{recinfoList?.address3}</div>
                            </div>
                        }
                        {/*도시명*/}
                        {recinfoList?.countryDTO?.countryCode !== "KR" &&
                            <div className={'m-a-order-detail-table-row'}>
                                <div className={'m-a-order-detail-table-row-label'}>{t(`modSmallMenu10`)}</div>
                                <div className={'m-a-order-detail-table-row-value admin-tb-text-cut'}>{recinfoList?.city}</div>
                            </div>
                        }
                    </div>
                    <div className={'m-a-order-detail-button-sm'} onClick={orderRecmodiTrueFn}>{t(`modRegModiBt`)}</div>
                </div>
            )}
            <div className={'m-a-order-detail-divider bold'}/>
            {/*장바구니 폼*/}
            <div className='m-a-order-detail-sub-title' onClick={cartOrderAccordionFn}>
            <img className='m-a-order-detail-sub-title-icon' src={detailCart} alt=''/>
                <div className='m-a-order-detail-sub-title-text'>{t(`cartTitle`)} </div>
                <i className={`accordion-icon m-a-order-detail-sub-title-expand 
                ${cartOrderAccordion && 'expanded'}`}/>
            </div>
            <div className='m-a-order-divider'/>
            {cartOrderAccordion && (
                <div className={'m-a-order-detail-section-frame'}>
                    <div className={'m-a-order-detail-section-title'}>{t(`cartTitle`)}</div>
                    {dbCartProductHandle.sort((a, b) => a.seqnum - b.seqnum).map((cart, index) => (
                        <MOrderSection key={index + 1} indexNm={index + 1}topVisibility={false}
                                       onClickAdd={() => addCartProduct(index)}
                                       onClickClone={() => copyCartProduct(index)}
                                       onClickDelete={() => deleteCartProduct(index)}>
                            <div className={'m-a-order-detail-order-box-top'}>
                                <div className={'m-a-order-detail-order-box-img-box'}>
                                    <img className={'m-a-order-detail-order-box-img'} src={detailCart} alt={''}/>
                                </div>
                                <div className="display-flex flex-column">
                                    <div className={'m-a-order-detail-order-box-title'}>{t(`cartTitle`)} {index + 1}</div>
                                    <div className="m-t-20">
                                        {cart.prodSt === "IS01" ?
                                            <button className="OrderPgBt2" onClick={() => deleteDbCartProduct(index)}>{t(`modAddBt3`)}</button>
                                            // <img className="wd-22 ht-22 m-a-opc-06 c-point" onClick={() => deleteDbCartProduct(index)} alt="delete" src={commonClose}/>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={'m-a-order-detail-order-table'}>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`mallId`)}
                                    </div>
                                    <input type="text" className={'m-a-order-detail-order-table-value'}
                                           value={cart.name || ''}
                                           onChange={(e) => handleDbCartProductInput(index, 'name', e.target.value)}
                                           maxLength={200}
                                           disabled={cart.prodSt !== "IS01"}
                                           ref={(ref) => {
                                               if (dbCartItemsRefs.current[index]) {
                                                   dbCartItemsRefs.current[index].name = ref;
                                               }
                                           }}/>
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'} style={{position:"relative"}}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`mallPw`)}
                                    </div>
                                        <input type={inputMallDbPwEncryption[index] ? "text" : "password"} className="m-a-order-detail-order-table-value"
                                               value={cart.option || ''}
                                               onChange={(e) => handleDbCartProductInput(index, 'option', e.target.value)}
                                               maxLength={50}
                                               disabled={cart.prodSt !== "IS01"} ref={(ref) => {
                                            if (dbCartItemsRefs.current[index]) {
                                                dbCartItemsRefs.current[index].option = ref;
                                            }
                                        }}/>

                                    {inputMallDbPwEncryption[index] ? (
                                        <img style={{width:"20px", height:"20px", position:"absolute", top:"20%", right:"10px"}} src={pwHide}
                                             onClick={() => toggleMallDbPassword(index)} alt={'pwHide'}/>
                                    ) : (
                                        <img style={{width:"20px", height:"20px", position:"absolute", top:"20%", right:"10px"}} src={pwView}
                                             onClick={() => toggleMallDbPassword(index)} alt={'pwView'}/>
                                    )}
                                    </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`cartQy`)}
                                    </div>
                                    <input type="text" className="m-a-order-detail-order-table-value"
                                           value={cart.quantity || ''}
                                           onChange={(e) => dbCartQuantityNumberInput(index, 'quantity', e.target.value)}
                                           maxLength={5}
                                           disabled={cart.prodSt !== "IS01"} ref={(ref) => {
                                        if (dbCartItemsRefs.current[index]) {
                                            dbCartItemsRefs.current[index].quantity = ref;
                                        }
                                    }}/>
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`cartPr`)}
                                    </div>
                                    <input type="text" className="m-a-order-detail-order-table-value"
                                           value={cart.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                           onChange={(e) => dbCartPriceNumberInput(index, 'price', e.target.value)}
                                           maxLength={12}
                                           disabled={cart.prodSt !== "IS01"} ref={(ref) => {
                                        if (dbCartItemsRefs.current[index]) {
                                            dbCartItemsRefs.current[index].price = ref;
                                        }
                                    }}/>
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`prodUrl`)}
                                    </div>
                                    <input type="text" className="m-a-order-detail-order-table-value"
                                           value={cart.url}
                                           onChange={(e) => handleDbCartProductInput(index, 'url', e.target.value)}
                                           maxLength={2083}
                                           disabled={cart.prodSt !== "IS01"} ref={(ref) => {
                                        if (dbCartItemsRefs.current[index]) {
                                            dbCartItemsRefs.current[index].url = ref;
                                        }
                                    }}/>
                                </div>
                                <div className="display-flex flex-row wd-100pc align-center ht-40 gap8">
                                    <div className="m-a-order-detail-order-table-label-new">
                                        {t(`dcPayBt`)}{t(`dchSmallMenu5`)}
                                    </div> {/*결제상태 장바구니DB폼*/}
                                    <div className="m-a-order-detail-order-table-value-st">
                                        <p>{prodStPrint(cart?.prodSt)}</p>
                                    </div>
                                </div>
                            </div>
                        </MOrderSection>
                    ))}
                    {newCartFormSt && cartProductHandle.map((cart, index) => (
                        <MOrderSection key={index + 1} indexNm={index + 1}
                                       onClickAdd={() => addCartProduct(index)}
                                       onClickClone={() => copyCartProduct(index)}
                                       onClickDelete={() => deleteCartProduct(index)}>
                            <div className={'m-a-order-detail-section-frame-box'}>
                                {MOrderInput(t(`mallId`), cart.name || '',
                                    false,
                                    200,
                                    (ref) => {
                                        if (cartItemsRefs.current[index]) {
                                            cartItemsRefs.current[index].name = ref;
                                        }
                                    },
                                    (e) => handleCartProductInput(index, 'name', e.target.value))}
                                <div className={'m-a-order-detail-input-box'}>
                                    {cart.option && cart.option.length > 0 &&
                                        <div className={'m-a-order-detail-input-title'}>{t(`mallPw`)}</div>}
                                    <input className={'m-a-order-detail-input'}
                                           type={inputMallPwEncryption[index] ? "text" : "password"}
                                           value={cart.option || ''}
                                           ref={(ref) => {
                                               if (cartItemsRefs.current[index]) {
                                                   cartItemsRefs.current[index].option = ref;
                                               }
                                           }} placeholder={t(`mallPw`)} maxLength={50}
                                           onChange={(e) => handleCartProductInput(index, 'option', e.target.value)}/>
                                    <div className={'m-a-order-detail-input-password'}
                                         onClick={() => toggleMallPassword(index)}>
                                        {inputMallPwEncryption[index] ? (
                                            <img className={'m-a-user-order-input-pw-icon'} src={pwHide}
                                                 alt={'pwHide'}/>
                                        ) : (
                                            <img className={'m-a-user-order-input-pw-icon'} src={pwView}
                                                 alt={'pwView'}/>
                                        )}
                                    </div>
                                </div>
                                {MOrderInput(t(`cartQy`), cart.quantity || '', false,
                                    5,
                                    (ref) => {
                                        if (cartItemsRefs.current[index]) {
                                            cartItemsRefs.current[index].quantity = ref;
                                        }
                                    },
                                    (e) => cartQuantityNumberInput(index, 'quantity', e.target.value))}
                                {MOrderInput(t(`cartPr`),
                                    (cart.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                    false,
                                    12,
                                    (ref) => {
                                        if (cartItemsRefs.current[index]) {
                                            cartItemsRefs.current[index].price = ref;
                                        }
                                    },
                                    (e) => cartPriceNumberInput(index, 'price', e.target.value))}
                                {MOrderInput(t(`prodUrl`),
                                    cart.url || '',
                                    false,
                                    2083,
                                    (ref) => {
                                        if (cartItemsRefs.current[index]) {
                                            cartItemsRefs.current[index].url = ref;
                                        }
                                    },
                                    (e) => handleCartProductInput(index, 'url', e.target.value))}
                                {prodStPrint(cart?.prodSt)}
                            </div>
                        </MOrderSection>
                    ))}
                </div>
            )}
            {newCartBtShow && cartOrderAccordion && cartProductHandle.length === 0 && (
                <div className={'m-a-order-detail-button-sm'} onClick={newCartForm}>
                    {t(`modAddBt1`)}
                </div>
            )}
            <div className={'m-a-order-detail-divider bold'}/>
            {/*구매대행 폼*/}
            <div className='m-a-order-detail-sub-title' onClick={orderAccordionFn}>
                <img className='m-a-order-detail-sub-title-icon' src={detailOrder} alt=''/>
                <div className='m-a-order-detail-sub-title-text'>{t(`modHeader2`)} </div> {/*구매대행서비스*/}
                <i className={`accordion-icon m-a-order-detail-sub-title-expand ${orderAccordion && 'expanded'}`}/>
            </div>
            <div className='m-a-order-detail-divider'/>
            {orderAccordion && (
                <div className={'m-a-order-detail-order-frame'}>
                    <div className={'m-a-order-detail-section-title'}>{t(`orderTitle`)}</div> {/*구매대행*/}
                    {dbOrderProductHandle.sort((a, b) => a.seqnum - b.seqnum).map((order, index) => (
                        <div className={'m-a-order-detail-order-box'} key={index}>
                            <div className={'m-a-order-detail-order-box-top'}>
                                <div className={'m-a-order-detail-order-box-img-box'}>
                                    <img className={'m-a-order-detail-order-box-img'} src={detailOrder} alt={''}/>
                                </div>
                                <div className="display-flex flex-column">
                                    <div className={'m-a-order-detail-order-box-title'}>{t(`orderTitle`)} {index + 1}</div>
                                    <div className="m-t-20">
                                        {order.prodSt === "IS01" ?
                                            <button className="OrderPgBt2" onClick={() => deleteDbOrderProduct(index)}>{t(`modAddBt3`)}</button>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={'m-a-order-detail-order-table'}>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>{t(`prodNm`)}</div> {/*상품명*/}
                                    <input className={'m-a-order-detail-order-table-value'}
                                           type="text" value={order.name || ''}
                                           onChange={(e) => handleDbOrderProductInput(index, 'name', e.target.value)}
                                           maxLength={200}
                                           disabled={order.prodSt !== "IS01"}
                                           ref={(ref) => {
                                               if (dbOrderItemsRefs.current[index]) {
                                                   dbOrderItemsRefs.current[index].name = ref;
                                               }
                                           }}
                                    />
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>{t(`prodOp`)}</div> {/*옵션*/}
                                    <input className={'m-a-order-detail-order-table-value'}
                                           type="text" value={order.option || ''}
                                           onChange={(e) => handleDbOrderProductInput(index, 'option', e.target.value)}
                                           maxLength={50}
                                           disabled={order.prodSt !== "IS01"}
                                    />
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>{t(`prodQy`)}</div>
                                    <input className={'m-a-order-detail-order-table-value'}
                                           type="text" value={order.quantity || ''}
                                           onChange={(e) => dbOrderQuantityNumberInput(index, 'quantity', e.target.value)}
                                           maxLength={5}
                                           disabled={order.prodSt !== "IS01"}
                                           ref={(ref) => {
                                               if (dbOrderItemsRefs.current[index]) {
                                                   dbOrderItemsRefs.current[index].quantity = ref;
                                               }
                                           }}
                                    />
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>{t(`prodPr`)}</div> {/*상품금액*/}
                                    <input className={'m-a-order-detail-order-table-value'}
                                           type="text"
                                           value={order.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                           onChange={(e) => dbOrderPriceNumberInput(index, 'price', e.target.value)}
                                           maxLength={12}
                                           disabled={order.prodSt !== "IS01"}
                                           ref={(ref) => {
                                               if (dbOrderItemsRefs.current[index]) {
                                                   dbOrderItemsRefs.current[index].price = ref;
                                               }
                                           }}
                                    />
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>{t(`prodMallPr`)}</div> {/*쇼핑몰배송비*/}
                                    <input className={'m-a-order-detail-order-table-value'}
                                           type="text"
                                           value={order.mallShipPrice ? order.mallShipPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}
                                           onChange={(e) => dbOrderMallpricericeNumberInput(index, 'mallShipPrice', e.target.value)}
                                           maxLength={12}
                                           disabled={order.prodSt !== "IS01"}
                                    />
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>{t(`prodUrl`)}</div> {/*상품URL*/}
                                    <input className={'m-a-order-detail-order-table-value'}
                                           type="text"
                                           value={order.url || ''}
                                           onChange={(e) => handleDbOrderProductInput(index, 'url', e.target.value)}
                                           maxLength={2083}
                                           disabled={order.prodSt !== "IS01"}
                                           ref={(ref) => {
                                               if (dbOrderItemsRefs.current[index]) {
                                                   dbOrderItemsRefs.current[index].url = ref;
                                               }
                                           }}
                                    />
                                </div>
                                <div className="display-flex flex-row wd-100pc align-center ht-40 gap8">
                                    <div className="m-a-order-detail-order-table-label-new">
                                        {t(`dcPayBt`)}{t(`dchSmallMenu5`)}
                                    </div> {/*결제상태 구매대행DB폼*/}
                                    <div className="m-a-order-detail-order-table-value-st">
                                        <p>{prodStPrint(order?.prodSt)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {newOrderFormSt && orderProductHandle.map((order, index) => (
                        <MOrderSection key={index + 1} indexNm={index + 1}
                                       onClickAdd={addOrderProduct}
                                       onClickClone={() => copyOrderProduct(index)}
                                       onClickDelete={() => deleteOrderProduct(index)}>
                            <div className={'m-a-order-section-frame-box'}>
                                {MOrderInput(t(`prodNm`),
                                    order.name || '', false,
                                    200,
                                    (ref) => {
                                        if (orderItemsRefs.current[index]) {
                                            orderItemsRefs.current[index].name = ref;
                                        }
                                    },
                                    (e) => handleOrderProductInput(index, 'name', e.target.value)
                                )}
                                {MOrderInput(t(`prodOp`), order.option || '', false,
                                    50,
                                    null,
                                    (e) => handleOrderProductInput(index, 'option', e.target.value)
                                )}
                                {MOrderInput(t(`prodQy`), order.quantity || '', false,
                                    5,
                                    (ref) => {
                                        if (orderItemsRefs.current[index]) {
                                            orderItemsRefs.current[index].quantity = ref;
                                        }
                                    },
                                    (e) => orderQuantityNumberInput(index, 'quantity', e.target.value)
                                )}
                                {MOrderInput(t(`prodPr`),
                                    (order.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                    false, 12,
                                    (ref) => {
                                        if (orderItemsRefs.current[index]) {
                                            orderItemsRefs.current[index].price = ref;
                                        }
                                    },
                                    (e) => orderPriceNumberInput(index, 'price', e.target.value)
                                )}
                                {MOrderInput(t(`prodMallPr`),
                                    (order.mallShipPrice)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                    false, 6,
                                    null,
                                    (e) => orderMallpricericeNumberInput(index, 'mallShipPrice', e.target.value)
                                )}
                                {MOrderInput(t(`prodUrl`),
                                    order.url || '',
                                    false, 2083,
                                    (ref) => {
                                        if (orderItemsRefs.current[index]) {
                                            orderItemsRefs.current[index].url = ref;
                                        }
                                    },
                                    (e) => handleOrderProductInput(index, 'url', e.target.value)
                                )}
                            </div>
                        </MOrderSection>
                    ))}
                    {newOrderBtShow && orderAccordion && orderProductHandle.length === 0 &&
                        <div className={'m-a-order-detail-button-sm m-t-20'} onClick={newOrderForm}>
                            {t(`modAddBt1`)}
                        </div>
                    }
                </div>
            )}
            <div className={'m-a-order-detail-divider bold'}/>
            {/*오프라인 구매대행 폼*/}
            <div className='m-a-order-detail-sub-title' onClick={offlineAccordionFn}>
                <img className='m-a-order-detail-sub-title-icon' src={detailOffline} alt=''/>
                <div className='m-a-order-detail-sub-title-text'>{t(`modHeader3`)} </div>
                <i className={`accordion-icon m-a-order-detail-sub-title-expand 
                ${offlineAccordion && 'expanded'}`}/>
            </div>
            <div className='m-a-order-detail-divider'/>
            {offlineAccordion && (
                <div className={'m-a-order-detail-section-frame'}>
                    <div className={'m-a-order-detail-section-title'}>{t(`offlineTitle`)}</div>
                    {dbOfflineProductHandle.sort((a, b) => a.seqnum - b.seqnum).map((offline, index) => (
                        <MOrderSection key={index + 1} topVisibility={false}
                                       onClickAdd={null}
                                       onClickClone={null}
                                       onClickDelete={null}>
                            <div className={'m-a-order-detail-order-box-top'}>
                                <div className={'m-a-order-detail-order-box-img-box'}>
                                    <img className={'m-a-order-detail-order-box-img'} src={detailOffline} alt={''}/>
                                </div>
                                <div className="display-flex flex-column">
                                    <div className={'m-a-order-detail-order-box-title'}>{t(`offlineTitle`)} {index + 1}</div>
                                    <div className="m-t-20">
                                        {offline.prodSt === "IS01" ?
                                            <button className="OrderPgBt2" onClick={() => deleteDbOfflineProduct(index)}>{t(`modAddBt3`)}</button>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={'m-a-order-detail-order-table'}>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`prodNm`)}
                                    </div>
                                    <input type="text" className={'m-a-order-detail-order-table-value'}
                                           value={offline.name || ''}
                                           onChange={(e) => handleDbOfflineProductInput(index, 'name', e.target.value)}
                                           maxLength={200}
                                           disabled={offline.prodSt !== "IS01"} ref={(ref) => {
                                        if (dbOfflineItemsRefs.current[index]) {
                                            dbOfflineItemsRefs.current[index].name = ref;
                                        }
                                    }}/>
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`prodQy`)}
                                    </div>
                                    <input type="text" className={'m-a-order-detail-order-table-value'}
                                           value={offline.quantity || ''}
                                           onChange={(e) => dbOfflineQuantityNumberInput(index, 'quantity', e.target.value)}
                                           maxLength={5}
                                           disabled={offline.prodSt !== "IS01"} ref={(ref) => {
                                        if (dbOfflineItemsRefs.current[index]) {
                                            dbOfflineItemsRefs.current[index].quantity = ref;
                                        }
                                    }}/>
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`prodPr`)}
                                    </div>
                                    <input type="text" className={'m-a-order-detail-order-table-value'}
                                           value={offline.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                           onChange={(e) => dbOfflinePriceNumberInput(index, 'price', e.target.value)}
                                           maxLength={12}
                                           disabled={offline.prodSt !== "IS01"} ref={(ref) => {
                                        if (dbOfflineItemsRefs.current[index]) {
                                            dbOfflineItemsRefs.current[index].price = ref;
                                        }
                                    }}/>
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`prodFee`)}
                                    </div>
                                    <input type="text" className={'m-a-order-detail-order-table-value'}
                                           value={offline.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                           onChange={(e) => dbOfflineFeeNumberInput(index, 'fee', e.target.value)}
                                           maxLength={6}
                                           disabled={offline.prodSt !== "IS01"} ref={(ref) => {
                                        if (dbOfflineItemsRefs.current[index]) {
                                            dbOfflineItemsRefs.current[index].fee = ref;
                                        }
                                    }}/>
                                </div>
                                <div className="display-flex flex-row wd-100pc align-center ht-40 gap8">
                                    <div className="m-a-order-detail-order-table-label-new">
                                        {t(`dcPayBt`)}{t(`dchSmallMenu5`)}
                                    </div> {/*결제상태 오프라인DB폼*/}
                                    <div className="m-a-order-detail-order-table-value-st">
                                        <p>{prodStPrint(offline?.prodSt)}</p>
                                    </div>
                                </div>
                            </div>
                        </MOrderSection>
                    ))}
                    {newOfflineFormSt && offlineProductHandle.map((offline, index) => (
                        <MOrderSection key={index + 1} indexNm={index + 1}
                                       onClickAdd={addOfflineProduct}
                                       onClickClone={() => copyOfflineProduct(index)}
                                       onClickDelete={() => deleteOfflineProduct(index)}>
                            <div className={'m-a-order-detail-section-frame-box'}>
                                {MOrderInput(t(`prodNm`), offline.name || '',
                                    false,
                                    200,
                                    (ref) => {
                                        if (offlineItemsRefs.current[index]) {
                                            offlineItemsRefs.current[index].name = ref;
                                        }
                                    },
                                    (e) => handleOfflineProductInput(index, 'name', e.target.value)
                                )}
                                {MOrderInput(t(`prodQy`), offline.quantity || '', false,
                                    5,
                                    (ref) => {
                                        if (offlineItemsRefs.current[index]) {
                                            offlineItemsRefs.current[index].quantity = ref;
                                        }
                                    },
                                    (e) => offlineQuantityNumberInput(index, 'quantity', e.target.value)
                                )}
                                {MOrderInput(t(`prodPr`),
                                    (offline.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                    false,
                                    12,
                                    (ref) => {
                                        if (offlineItemsRefs.current[index]) {
                                            offlineItemsRefs.current[index].price = ref;
                                        }
                                    },
                                    (e) => offlinePriceNumberInput(index, 'price', e.target.value)
                                )}
                                {MOrderInput(t(`prodFee`),
                                    (offline.fee)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                    false,
                                    6,
                                    (ref) => {
                                        if (offlineItemsRefs.current[index]) {
                                            offlineItemsRefs.current[index].fee = ref;
                                        }
                                    },
                                    (e) => offlineFeeNumberInput(index, 'fee', e.target.value)
                                )}
                            </div>
                        </MOrderSection>
                    ))}
                </div>
            )}
            {newOfflineBtShow && offlineAccordion && offlineProductHandle.length === 0 && (
                <div className={'m-a-order-detail-button-sm'} onClick={newOfflineForm}>
                    {t(`modAddBt1`)}
                </div>
            )}
            <div className={'m-a-order-detail-divider bold'}/>
            {/*이체대행 폼*/}
            <div className='m-a-order-detail-sub-title' onClick={transferAccordionFn}>
                <img className='m-a-order-detail-sub-title-icon' src={detailTransfer} alt=''/>
                <div className='m-a-order-detail-sub-title-text'>{t(`modHeader4`)} </div>
                <i className={`accordion-icon m-a-order-detail-sub-title-expand 
                ${transferAccordion && 'expanded'}`}/>
            </div>
            <div className='m-a-order-detail-divider'/>
            {transferAccordion && (
                <div className={'m-a-order-detail-section-frame'}>
                    <div className={'m-a-order-detail-section-title'}>{t(`transferTitle`)}</div>
                    {dbTransferProductHandle.sort((a, b) => a.seqnum - b.seqnum).map((transfer, index) => (
                        <MOrderSection key={index + 1} topVisibility={false}
                                       onClickAdd={null}
                                       onClickClone={null}
                                       onClickDelete={null}>
                            <div className={'m-a-order-detail-order-box-top'}>
                                <div className={'m-a-order-detail-order-box-img-box'}>
                                    <img className={'m-a-order-detail-order-box-img'} src={detailTransfer} alt={''}/>
                                </div>
                                <div className="display-flex flex-column">
                                    <div className={'m-a-order-detail-order-box-title'}>{t(`transferTitle`)} {index + 1}</div>
                                    <div className="m-t-20">
                                        {transfer.prodSt === "IS01" ?
                                            <button className="OrderPgBt2" onClick={() => deleteDbTransferProduct(index)}>{t(`modAddBt3`)}</button>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={'m-a-order-detail-order-table'}>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`prodAcct`)}
                                    </div>
                                    <input type="text" className={'m-a-order-detail-order-table-value'}
                                           value={transfer.name || ''}
                                           onChange={(e) => handleDbTransferProductInput(index, 'name', e.target.value)}
                                           maxLength={200}
                                           disabled={transfer.prodSt !== "IS01"} ref={(ref) => {
                                        if (dbTransferItemsRefs.current[index]) {
                                            dbTransferItemsRefs.current[index].name = ref;
                                        }
                                    }}/>
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`prodTransPr`)}
                                    </div>
                                    <input type="text" className={'m-a-order-detail-order-table-value'}
                                           value={transfer.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                           onChange={(e) => dbTransferPriceNumberInput(index, 'price', e.target.value)}
                                           maxLength={12}
                                           disabled={transfer.prodSt !== "IS01"} ref={(ref) => {
                                        if (dbTransferItemsRefs.current[index]) {
                                            dbTransferItemsRefs.current[index].price = ref;
                                        }
                                    }}/>
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`prodFee`) + '(7%)'}
                                    </div>
                                    <input type="text" className={'m-a-order-detail-order-table-value'}
                                           value={transfer.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                           onChange={(e) => dbTransferFeeNumberInput(index, 'fee', e.target.value)}
                                           maxLength={12}
                                           disabled={transfer.prodSt !== "IS01"} ref={(ref) => {
                                        if (dbTransferItemsRefs.current[index]) {
                                            dbTransferItemsRefs.current[index].fee = ref;
                                        }
                                    }}/>
                                </div>
                                <div className="display-flex flex-row wd-100pc align-center ht-40 gap8">
                                    <div className="m-a-order-detail-order-table-label-new">
                                        {t(`dcPayBt`)}{t(`dchSmallMenu5`)}
                                    </div> {/*결제상태 이체대행DB폼*/}
                                    <div className="m-a-order-detail-order-table-value-st">
                                        <p>{prodStPrint(transfer?.prodSt)}</p>
                                    </div>
                                </div>
                            </div>
                        </MOrderSection>
                    ))}
                    {newTransferFormSt && transferProductHandle.map((transfer, index) => (
                        <MOrderSection key={index + 1} indexNm={index + 1}
                                       onClickAdd={addTransferProduct}
                                       onClickClone={() => copyTransferProduct(index)}
                                       onClickDelete={() => deleteTransferProduct(index)}>
                            <div className={'m-a-order-detail-section-frame-box'}>
                                {MOrderInput(t(`prodAcct`), transfer.name || '',
                                    false,
                                    200,
                                    (ref) => {
                                        if (transferItemsRefs.current[index]) {
                                            transferItemsRefs.current[index].name = ref;
                                        }
                                    },
                                    (e) => handleTransferProductInput(index, 'name', e.target.value)
                                )}
                                {MOrderInput(t(`prodTransPr`), (transfer.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                    false,
                                    12,
                                    (ref) => {
                                        if (transferItemsRefs.current[index]) {
                                            transferItemsRefs.current[index].price = ref;
                                        }
                                    },
                                    (e) => transferPriceNumberInput(index, 'price', e.target.value)
                                )}
                                {MOrderInput(t(`prodFee`) + '(7%)',
                                    (transfer.fee)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                    false,
                                    12,
                                    (ref) => {
                                        if (transferItemsRefs.current[index]) {
                                            transferItemsRefs.current[index].fee = ref;
                                        }
                                    },
                                    (e) => transferFeeNumberInput(index, 'fee', e.target.value)
                                )}
                            </div>
                        </MOrderSection>
                    ))}
                </div>
            )}
            {newTransferBtShow && transferAccordion && transferProductHandle.length === 0 && (
                <div className={'m-a-order-detail-button-sm'} onClick={newTransferForm}>
                    {t(`modAddBt1`)}
                </div>
            )}
            <div className={'m-a-order-detail-divider bold'}/>
            {/*국내배송(구매대행) 폼*/}
            <div className='m-a-order-detail-sub-title' onClick={domesticAccordionFn}>
                <img className='m-a-order-detail-sub-title-icon' src={detailDomestic} alt=''/>
                <div className='m-a-order-detail-sub-title-text'>{t(`modHeader5`)} </div>
                <i className={`accordion-icon m-a-order-detail-sub-title-expand 
                ${domesticAccordion && 'expanded'}`}/>
            </div>
            <div className='m-a-order-detail-divider'/>
            {domesticAccordion && (
                <div className={'m-a-order-detail-section-frame'}>
                    <div className={'m-a-order-detail-section-title'}>{t(`domesticTitle`)}</div>
                    {dbDomesticProductHandle.sort((a, b) => a.seqnum - b.seqnum).map((domestic, index) => (
                        <MOrderSection key={index + 1} topVisibility={false}
                                       onClickAdd={null}
                                       onClickClone={null}
                                       onClickDelete={null}>
                            <div className={'m-a-order-detail-order-box-top'}>
                                <div className={'m-a-order-detail-order-box-img-box'}>
                                    <img className={'m-a-order-detail-order-box-img'} src={detailDomestic} alt={''}/>
                                </div>
                                <div className="display-flex flex-column">
                                    <div className={'m-a-order-detail-order-box-title'}>{t(`domesticTitle`)} {index + 1}</div>
                                    <div className="m-t-20">
                                        {domestic.prodSt === "IS01" ?
                                            <button className="OrderPgBt2" onClick={() => deleteDbDomesticProduct(index)}>{t(`modAddBt3`)}</button>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={'m-a-order-detail-order-table'}>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`prodNm`)}
                                    </div>
                                    <input type="text" className={'m-a-order-detail-order-table-value'}
                                           value={domestic.option || ''}
                                           onChange={(e) => handleDbDomesticProductInput(index, 'option', e.target.value)}
                                           maxLength={50}
                                           disabled={domestic.prodSt !== "IS01"}/>
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`prodQy`)}
                                    </div>
                                    <input type="text" className={'m-a-order-detail-order-table-value'}
                                           value={domestic.quantity || ''}
                                           onChange={(e) => dbDomesticQuantityNumberInput(index, 'quantity', e.target.value)}
                                           maxLength={5}
                                           disabled={domestic.prodSt !== "IS01"} ref={(ref) => {
                                        if (dbDomesticItemsRefs.current[index]) {
                                            dbDomesticItemsRefs.current[index].quantity = ref;
                                        }
                                    }}/>
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`prodPr`)}
                                    </div>
                                    <input type="text" className={'m-a-order-detail-order-table-value'}
                                           value={domestic.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                           onChange={(e) => dbDomesticPriceNumberInput(index, 'price', e.target.value)}
                                           maxLength={12}
                                           disabled={domestic.prodSt !== "IS01"} ref={(ref) => {
                                        if (dbDomesticItemsRefs.current[index]) {
                                            dbDomesticItemsRefs.current[index].price = ref;
                                        }
                                    }}/>
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`prodMallPr`)}
                                    </div>
                                    <input type="text" className={'m-a-order-detail-order-table-value'}
                                           value={domestic.mallShipPrice ? domestic.mallShipPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}
                                           onChange={(e) => dbDomesticMallpriceNumberInput(index, 'mallShipPrice', e.target.value)}
                                           maxLength={6}
                                           disabled={domestic.prodSt !== "IS01"}/>
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`prodFee`) + '(7%)'}
                                    </div>
                                    <input type="text" className={'m-a-order-detail-order-table-value'}
                                           value={domestic.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                           onChange={(e) => dbDomesticFeeNumberInput(index, 'mallShipPrice', e.target.value)}
                                           maxLength={12}
                                           disabled={domestic.prodSt !== "IS01"} ref={(ref) => {
                                        if (dbDomesticItemsRefs.current[index]) {
                                            dbDomesticItemsRefs.current[index].fee = ref;
                                        }
                                    }}/>
                                </div>
                                <div className={'m-a-order-detail-order-table-row-new'}>
                                    <div className={'m-a-order-detail-order-table-label-new'}>
                                        {t(`prodUrl`)}
                                    </div>
                                    <input type="text" className={'m-a-order-detail-order-table-value'}
                                           value={domestic.url}
                                           onChange={(e) => handleDbDomesticProductInput(index, 'url', e.target.value)}
                                           maxLength={2083}
                                           disabled={domestic.prodSt !== "IS01"} ref={(ref) => {
                                        if (dbDomesticItemsRefs.current[index]) {
                                            dbDomesticItemsRefs.current[index].url = ref;
                                        }
                                    }}/>
                                </div>
                                <div className="display-flex flex-row wd-100pc align-center ht-40 gap8">
                                    <div className="m-a-order-detail-order-table-label-new">
                                        {t(`dcPayBt`)}{t(`dchSmallMenu5`)}
                                    </div> {/*결제상태 국내배송DB폼*/}
                                    <div className="m-a-order-detail-order-table-value-st">
                                        <p>{prodStPrint(domestic?.prodSt)}</p>
                                    </div>
                                </div>
                            </div>
                        </MOrderSection>
                    ))}
                    {newDomesticFormSt && domesticProductHandle.map((domestic, index) => (
                        <MOrderSection key={index + 1} indexNm={index + 1}
                                       onClickAdd={addDomesticProduct}
                                       onClickClone={() => copyDomesticProduct(index)}
                                       onClickDelete={() => deleteDomesticProduct(index)}>
                            <div className={'m-a-order-detail-section-frame-box'}>
                                {MOrderInput(t(`prodNm`), domestic.name || '',
                                    false,
                                    200,
                                    (ref) => {
                                        if (domesticItemsRefs.current[index]) {
                                            domesticItemsRefs.current[index].name = ref;
                                        }
                                    },
                                    (e) => handleDomesticProductInput(index, 'name', e.target.value)
                                )}
                                {MOrderInput(t(`prodOp`),
                                    domestic.option || '',
                                    false,
                                    50,
                                    null,
                                    (e) => handleDomesticProductInput(index, 'option', e.target.value)
                                )}
                                {MOrderInput(t(`prodQy`),
                                    domestic.quantity || '',
                                    false,
                                    5,
                                    (ref) => {
                                        if (domesticItemsRefs.current[index]) {
                                            domesticItemsRefs.current[index].quantity = ref;
                                        }
                                    },
                                    (e) => domesticQuantityNumberInput(index, 'quantity', e.target.value)
                                )}
                                {MOrderInput(t(`prodPr`),
                                    (domestic.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                    false,
                                    12,
                                    (ref) => {
                                        if (domesticItemsRefs.current[index]) {
                                            domesticItemsRefs.current[index].price = ref;
                                        }
                                    },
                                    (e) => domesticPriceNumberInput(index, 'price', e.target.value)
                                )}
                                {MOrderInput(t(`prodMallPr`),
                                    (domestic.mallShipPrice)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                    false,
                                    6,
                                    null,
                                    (e) => domesticMallpriceNumberInput(index, 'mallShipPrice', e.target.value)
                                )}
                                {MOrderInput(t(`prodPr`) + '(7%)',
                                    (domestic.fee)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '',
                                    false,
                                    12,
                                    (ref) => {
                                        if (domesticItemsRefs.current[index]) {
                                            domesticItemsRefs.current[index].fee = ref;
                                        }
                                    },
                                    (e) => domesticFeeNumberInput(index, 'fee', e.target.value)
                                )}
                                {MOrderInput(t(`prodUrl`),
                                    domestic.url || '',
                                    false,
                                    2083,
                                    (ref) => {
                                        if (domesticItemsRefs.current[index]) {
                                            domesticItemsRefs.current[index].url = ref;
                                        }
                                    },
                                    (e) => handleDomesticProductInput(index, 'url', e.target.value)
                                )}
                            </div>
                        </MOrderSection>
                    ))}
                </div>
            )}
            {newDomesticBtShow && domesticAccordion && domesticProductHandle.length === 0 && (
                <div className={'m-a-order-detail-button-sm'} onClick={newDomesticForm}>
                    {t(`modAddBt1`)}
                </div>
            )}
            <div className={'m-a-order-detail-divider bold'}/>
            {/*배송대행 폼*/}
            <div className='m-a-order-detail-sub-title' onClick={deliveryAccordionFn}>
                <img className='m-a-order-detail-sub-title-icon' src={detailDelivery} alt=''/>
                <div className='m-a-order-detail-sub-title-text'>{t(`modHeader6`)} </div>
                <i className={`accordion-icon m-a-order-detail-sub-title-expand 
                ${deliveryAccordion && 'expanded'}`}/>
            </div>
            <div className='m-a-order-detail-divider'/>
            {deliveryAccordion && (
                <div className={'m-a-order-detail-section-frame'}>
                    <div className="m-a-order-detail-section-title">{t(`deliveryTitle`)}</div> {/*배송대행*/}
                    {dbDeliveryProductHandle.sort((a, b) => a.seqnum - b.seqnum).map((delivery, index) => (
                        <MOrderSection key={index + 1} topVisibility={false}
                                       onClickAdd={null}
                                       onClickClone={null}
                                       onClickDelete={null}>
                            <div className={'m-a-order-detail-section-frame-box'}>
                                <div className={'m-a-order-detail-order-box-top'}>
                                    <div className={'m-a-order-detail-order-box-img-box'}>
                                        <img className={'m-a-order-detail-order-box-img'} src={detailDelivery}
                                             alt={''}/>
                                    </div>
                                    <div className="display-flex flex-column">
                                        <div className={'m-a-order-detail-order-box-title'}>{t(`deliveryTitle`)} {index + 1}</div>
                                        <div className="m-t-20">
                                            {delivery.prodSt === "IS00" ?
                                                <button className="OrderPgBt2" onClick={() => deleteDbDeliveryProduct(index)}>{t(`modAddBt3`)}</button>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <button className={'m-a-order-detail-select'}
                                        ref={(el) => dbDomesticRefs.current[index] = el}
                                        disabled={delivery.prodSt !== "IS00"}
                                        onClick={() => toggleDbDomesticShow(index)}>
                                    {delivery.domShipco === '' ? t('dv') : delivery.domShipco}
                                    {dbDomesticShow[index] && (
                                        <div className={'m-a-order-detail-select-list'}>
                                            {domesticDeliveryChoice.map((domestic, i) => (
                                                <div className={'m-a-order-detail-select-item'} key={i}
                                                     value={domestic}
                                                     onClick={() => handleDbDeliveryDomesticChange(index, domestic)}>
                                                    {domestic}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </button>
                                <div className={'m-a-order-detail-order-table'}>
                                    <div className={'m-a-order-detail-order-table-row-new'}>
                                        <div className={'m-a-order-detail-order-table-label-new'}>
                                            {t(`prodTrNum`)}
                                        </div>
                                        <input type="text" className={'m-a-order-detail-order-table-value'}
                                               value={delivery.inWaynum || ''}
                                               onChange={(e) => dbDeliveryWaynumNumberInput(index, 'inwaynum', e.target.value)}
                                               maxLength={20}
                                               disabled={delivery.prodSt !== "IS00"}/>
                                    </div>
                                    <div className={'m-a-order-detail-order-table-row-new'}>
                                        <div className={'m-a-order-detail-order-table-label-new'}>
                                            {t(`prodNm`)}
                                        </div>
                                        <input type="text" className={'m-a-order-detail-order-table-value'}
                                               value={delivery.name || ''}
                                               onChange={(e) => handleDbDeliveryProductInput(index, 'name', e.target.value)}
                                               maxLength={200}
                                               disabled={delivery.prodSt !== "IS00"} ref={(ref) => {
                                            if (dbOrderItemsRefs.current[index]) {
                                                dbOrderItemsRefs.current[index].name = ref;
                                            }
                                        }}/>
                                    </div>
                                    <div className={'m-a-order-detail-order-table-row-new'}>
                                        <div className="m-a-order-detail-order-table-label-new">
                                            {t(`prodOp`)}
                                        </div>
                                        <input type="text" className={'m-a-order-detail-order-table-value'}
                                               value={delivery.option || ''}
                                               onChange={(e) => handleDbDeliveryProductInput(index, 'option', e.target.value)}
                                               maxLength={200}
                                               disabled={delivery.prodSt !== "IS00"}/>
                                    </div>
                                    <div className="display-flex flex-row wd-100pc align-center ht-40 gap8">
                                        <div className="m-a-order-detail-order-table-label-new">
                                            {t(`prodTtQy`)}
                                        </div>
                                        <input type="text" className="m-a-order-detail-order-table-value"
                                               value={delivery.quantity || ''}
                                               onChange={(e) => dbDeliveryQuantityNumberInput(index, 'quantity', e.target.value)}
                                               maxLength={5}
                                               disabled={delivery.prodSt !== "IS00"} ref={(ref) => {
                                            if (dbOrderItemsRefs.current[index]) {
                                                dbOrderItemsRefs.current[index].quantity = ref;
                                            }
                                        }}/>
                                    </div>
                                    <div className="display-flex flex-row wd-100pc align-center ht-40 gap8">
                                        <div className="m-a-order-detail-order-table-label-new">
                                            {t(`dcPayBt`)}{t(`dchSmallMenu5`)}
                                        </div> {/*결제상태 배송대행DB폼*/}
                                        <div className="m-a-order-detail-order-table-value-st">
                                            <p>{prodStPrint(delivery?.prodSt)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </MOrderSection>
                    ))}
                    {newDeliveryFormSt && deliveryProductHandle.map((delivery, index) => (
                        <MOrderSection key={index + 1} indexNm={index + 1} onClickAdd={addDeliveryProduct} onClickClone={() => copyDeliveryProduct(index)}
                                       onClickDelete={() => deleteDeliveryProduct(index)}>
                            <div className={'m-a-order-detail-section-frame-box'}>
                                <button className={'m-a-order-detail-select'}
                                        ref={(el) => domesticRefs.current[index] = el}
                                        onClick={() => toggleDomesticShow(index)}>
                                    {delivery.domShipco === '' ? t('dv') : delivery.domShipco}
                                    {domesticShow[index] && (
                                        <div className={'m-a-order-detail-select-list'}>
                                            {domesticDeliveryChoice.map((domestic, i) => (
                                                <div className={'m-a-order-detail-select-item'} key={i}
                                                     value={domestic}
                                                     onClick={() => handleDomesticChange(index, domestic)}>
                                                    {domestic}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </button>
                                {MOrderInput(t(`prodTrNum`), delivery.inWaynum || '',
                                    false,
                                    22,
                                    null,
                                    (e) => deliveryWaynumNumberInput(index, 'inWaynum', e.target.value)
                                )}
                                {MOrderInput(t(`prodNm`), delivery.name || '',
                                    false,
                                    200,
                                    (ref) => {
                                        if (deliveryItemsRefs.current[index]) {
                                            deliveryItemsRefs.current[index].name = ref;
                                        }
                                    },
                                    (e) => handleDeliveryProductInput(index, 'name', e.target.value)
                                )}
                                {MOrderInput(t(`prodOp`),
                                    delivery.option || '',
                                    false,
                                    200,
                                    null,
                                    (e) => handleDeliveryProductInput(index, 'option', e.target.value)
                                )}
                                {MOrderInput(t(`prodTtQy`),
                                    delivery.quantity || '',
                                    false,
                                    5,
                                    (ref) => {
                                        if (deliveryItemsRefs.current[index]) {
                                            deliveryItemsRefs.current[index].quantity = ref;
                                        }
                                    },
                                    (e) => deliveryQuantityNumberInput(index, 'quantity', e.target.value)
                                )}
                            </div>
                        </MOrderSection>
                    ))}
                </div>
            )}
            {newDeliveryBtShow && deliveryAccordion && deliveryProductHandle.length === 0 && (
                <div className={'m-a-order-detail-button-sm'} onClick={newDeliveryForm}>
                    {t(`modAddBt1`)} {/*상품추가*/}
                </div>
            )}
            <div className={'m-a-order-detail-divider bold'}/>
            {/*배송대행 폼*/}
            <div className='m-a-order-detail-sub-title' onClick={invoiceAccordionFn}>
                <img className='m-a-order-detail-sub-title-icon' src={invoiceFormHeader} alt=''/>
                <div className='m-a-order-detail-sub-title-text'>{t(`modHeader7`)} </div> {/*배송대행 인보이스*/}
                <i className={`accordion-icon m-a-order-detail-sub-title-expand 
                ${invoiceAccordion && 'expanded'}`}/>
            </div>
            <div className='m-a-order-detail-divider'/>
            {invoiceAccordion &&
                <div className={'m-a-order-detail-section-frame no-margin-bottom'}>
                    <div className={'m-a-order-detail-section-title'}>{t(`modIvAccd`)}</div>
                    <div className={'m-a-order-detail-invoice-attachment'}>
                        <input className={'m-a-order-detail-invoice-attachment-file'} value={fileName} onClick={() => document.getElementById('deliveryInvoice').click()} readOnly/>
                        <label className={'m-a-order-detail-invoice-attachment-button text-center'}>{t(`modFileBt`)}
                            <input type="file" onChange={photoChangeHandler} id="deliveryInvoice" className={'m-a-order-detail-invoice-attachment-button-input'}/>
                        </label>
                    </div>
                </div>
            }
            <div className={'m-a-order-detail-divider bold'}/>
            <div className='m-a-order-detail-sub-title' onClick={requestConfigAccordionFn}>
                <img className='m-a-order-detail-sub-title-icon' src={reqFormHeader} alt=''/>
                <div className='m-a-order-detail-sub-title-text'>{t(`modHeader8`)}</div> {/*요청사항설정*/}
                <i className={`accordion-icon m-a-order-detail-sub-title-expand 
                ${requestConfigAccordion && 'expanded'}`}/>
            </div>
            <div className='m-a-order-detail-divider'/>
            {requestConfigAccordion && (
                <div className={'m-a-order-detail-section-frame'}>
                    <div className={'m-a-order-detail-section-title'}>{t(`modSmallMenu12`)}</div>
                    <MOrderSection title={'요청사항양식'} topVisibility={false}>
                        <div className={'m-a-order-detail-section-checklist-box'}>
                            <div className={'m-a-order-detail-section-checklist'}>
                                <input id="tagRemove" type="checkbox" disabled={hasPT1OrPT3}
                                       className={'m-a-order-detail-section-checklist-icon wd-20 ht-20 m-t-1'}
                                       checked={tagRemove} onChange={tagRemoveCheck}/>
                                <div className={'m-a-order-detail-section-checklist-text font-s-15'}>{t(`modReqCont1`)}</div>
                            </div>
                            {tagRemove && <div className="m-a-order-detail-error-message-box">
                                {t(`modReqWarn1`)}</div>}
                            <div className={'m-a-order-detail-section-checklist'}>
                                <input id="shoesBoxRemove" type="checkbox" disabled={hasPT1OrPT3}
                                       className={'m-a-order-detail-section-checklist-icon wd-20 ht-20 m-t-1'}
                                       checked={shoesBoxRemove} onChange={shoesBoxRemoveCheck}/>
                                <div className={'m-a-order-detail-section-checklist-text font-s-15'}>{t(`modReqCont2`)}</div>
                            </div>
                            <div className={'m-a-order-detail-section-checklist'}>
                                <input id="hangerRemove" type="checkbox" disabled={hasPT1OrPT3}
                                       className={'m-a-order-detail-section-checklist-icon wd-20 ht-20 m-t-1'}
                                       checked={hangerRemove} onChange={hangerRemoveCheck}/>
                                <div className={'m-a-order-detail-section-checklist-text font-s-15'}>{t(`modReqCont3`)}</div>
                            </div>
                            <div className={'m-a-order-detail-section-checklist'}>
                                <input id="compressionPack" type="checkbox" disabled={hasPT1OrPT3}
                                       className={'m-a-order-detail-section-checklist-icon wd-20 ht-20 m-t-1'}
                                       checked={compressionPack} onChange={compressionCheck}/>
                                <div className={'m-a-order-detail-section-checklist-text font-s-15'}>{t(`modReqCont4`)}</div>
                            </div>
                            {compressionPack && <div className="m-a-order-detail-error-message-box margin-left">
                                {t(`modReqWarn2`)}</div>}
                            <div className={'m-a-order-detail-section-checklist'}>
                                <input id="productBoxRemove" type="checkbox" disabled={hasPT1OrPT3}
                                       className={'m-a-order-detail-section-checklist-icon wd-20 ht-20 m-t-1'}
                                       checked={productBoxRemove} onChange={prodBoxRemoveCheck}/>
                                <div className={'m-a-order-detail-section-checklist-text font-s-15'}>{t(`modReqCont5`)}</div>
                            </div>
                            {productBoxRemove && <div className="m-a-order-detail-error-message-box margin-left">
                                {t(`modReqWarn3`)}</div>}
                            <div className={'m-a-order-detail-section-checklist'}>
                                <input id="packingHold" type="checkbox" disabled={hasPT1OrPT3}
                                       className={'m-a-order-detail-section-checklist-icon wd-20 ht-20 m-t-2'}
                                       checked={packingHold} onChange={packingHoldCheck}/>
                                <div className={'m-a-order-detail-section-checklist-text font-s-15'}>{t(`modReqCont6`)}</div>
                            </div>
                            {packingHold && <div className="m-a-order-detail-error-message-box margin-left">
                                {t(`modReqWarn4`)}</div>}
                            <div className={'m-a-order-detail-section-checklist'}>
                                <input id="deliveryDepositPay" type="checkbox" disabled={hasPT1OrPT3}
                                       className={'m-a-order-detail-section-checklist-icon wd-20 ht-20 m-t-2'}
                                       checked={deliveryDepositPay} onChange={deliDepositPayCheck}/>
                                <div className={'m-a-order-detail-section-checklist-text font-s-15'}>{t(`modReqCont7`)}</div>
                            </div>
                            {deliveryDepositPay && <div className="m-a-order-detail-error-message-box margin-left">
                                {t(`modReqWarn5`)}</div>}
                            <div className={'m-a-order-detail-section-checklist'}>
                                <input id="deliveryMessengerGuide" type="checkbox" disabled={hasPT1OrPT3}
                                       className={'m-a-order-detail-section-checklist-icon wd-20 ht-20 m-t-2'}
                                       checked={deliveryMessengerGuide} onChange={deliMsgGuideCheck}/>
                                <div className={'m-a-order-detail-section-checklist-text font-s-15'}>{t(`modReqCont8`)}</div>
                            </div>
                            {deliveryMessengerGuide && <div className="m-a-order-detail-error-message-box margin-left">
                                {t(`modReqCont9`)}</div>}
                            <div className={'m-a-order-detail-input-box'} >
                                <input className={'m-a-order-detail-input m-b-5'} type={"text"} value={detailRequest} maxLength={300}
                                       disabled={hasPT1OrPT3} onChange={detailRequestInput}/>
                            </div>
                        </div>
                    </MOrderSection>
                </div>
            )}
            {/*결제 목록*/}
            <div className='m-a-order-detail-sub-title' onClick={payListAccordionFn}>
                <img className='m-a-order-detail-sub-title-icon' src={payFormHeader} alt=''/>
                <div className='m-a-order-detail-sub-title-text'>{t(`modHeader9`)}</div>
                <i className={`accordion-icon m-a-order-detail-sub-title-expand 
                ${payListAccordion && 'expanded'}`}/>
            </div>
            <div className='m-a-order-detail-divider'/>
            {payListAccordion &&
                <div className={'m-a-order-detail-section-frame no-margin-bottom'}>
                    <div className={'m-a-order-detail-section-title'}>{t(`modHeader9`)}</div> {/*결제목록*/}
                    <div className={'m-a-order-detail-pay-list'}>
                        <div style={{width: "800px"}}>
                            <div className={'m-a-order-detail-pay-row-new'}>
                                <div className="wd-30">
                                    <input type="checkbox" className="m-a-admin-input-chkBox" checked={allSelectedPaylist} onChange={e => handleAllPaylistCheckboxChange(e.target.checked)}/>
                                </div>
                                <div className="m-a-order-detail-pay-item wd-100">{t(`modPlHeader1`)}</div> {/*일시*/}
                                <div className="m-a-order-detail-pay-item wd-130">{t(`modPlHeader2`)}</div> {/*상품비용*/}
                                <div className="m-a-order-detail-pay-item wd-200">{t(`modPlHeader3`)}</div> {/*해외배송비*/}
                                <div className="m-a-order-detail-pay-item wd-130">{t(`modPlHeader4`)}</div> {/*기타비용*/}
                                <div className="m-a-order-detail-pay-item wd-130">{t(`modPlHeader5`)}</div> {/*상태*/}
                                <div className="m-a-order-detail-pay-item wd-80"/>
                            </div>
                            {sortedPayinfoList.map((pay, index) => (
                                <div key={index} className="m-a-order-detail-pay-row-new">
                                    <div className="wd-30">
                                        {pay.payStatus === "PS0" && pay.payType === "PT1" ? (
                                            <input className="m-a-admin-input-chkBox" type="checkbox" checked={allSelectedPaylist} onChange={e => handleAllPaylistCheckboxChange(e.target.checked)}/>
                                        ) : <div style={{minWidth: "16px", height: "16px"}}>&nbsp;</div>}
                                    </div>
                                    <div className="text-center font-s-13 wd-100">
                                        {pay.regDate ? `${pay.regDate.substring(0, 4)}-${pay.regDate.substring(4, 6)}-${pay.regDate.substring(6, 8)}` : ''}
                                    </div>
                                    <div className={'m-a-order-detail-pay-item wd-130'}>
                                        {pay.ttProdExpense && (pay.ttProdExpense)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </div>
                                    <div className="m-a-order-detail-pay-item wd-200">
                                        {pay.payType === "PT1" ?
                                            <div className="wd-100pc display-flex flex-column just-cont-center gap10">
                                                {pay.ttFedexPrice > 0 &&
                                                    <div>
                                                        {pay.payStatus === "PS0" && pay.ttFedexPrice > 0 &&
                                                            <input style={{borderStyle: "solid", width: "15px", height: "15px", display: "none"}} type="radio" value="FedEx"
                                                                   checked={selectedOverseasShippingCost[pay.seqNum] === 'FedEx'}
                                                                   onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                            />
                                                        }
                                                        {pay.ttFedexPrice > 0 &&
                                                            <div className={'m-a-order-detail-pay-delivery-box'}>
                                                                <div className="wd-40pc ht-80">
                                                                    <div className={`m-a-order-detail-pay-delivery-box-left ${selectedOverseasShippingCost[pay.seqNum] === 'FedEx'
                                                                            && (pay.payStatus === "PS0" || pay.payStatus === "PS1") && 'selected'}
                                                                                ${pay.payStatus === "PS0" || pay.payStatus === "PS1"}`}
                                                                                    onClick={() => handleRadioChange(pay.seqNum, 'FedEx')}>
                                                                        <div className="m-a-order-detail-shipco-logo-frame">
                                                                            <img style={{width: "60px", height: "30px", alignSelf: "flex-end", objectFit: "contain"}} src={FedexLogo} alt={'fedex'}/>
                                                                        </div>
                                                                        <div className="m-a-order-detail-shipco-price-frame font-t-cont"
                                                                            style={{color: pay.ttFedexPrice > 0 ? "darkorange" : "dimgray", fontWeight: pay.ttFedexPrice > 0 ? "bold" : null,}}>
                                                                            {pay.ttFedexPrice.toLocaleString('en-US')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="display-flex flex-column wd-60pc" style={{alignItems: "flex-start"}}>
                                                                    <a className="font-s-15">FedEx</a>
                                                                    <a className="font-s-13">{t(`modPlFedex`)}</a> {/*평균2~3일 소요*/}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {pay.ttUpsPrice > 0 &&
                                                    <div>
                                                        {pay.payStatus === "PS0" && pay.ttUpsPrice > 0 &&
                                                            <input className="radioButton" type="radio" value="UPS" checked={selectedOverseasShippingCost[pay.seqNum] === 'UPS'}
                                                                onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                                style={{borderStyle: "solid", width: "15px", height: "15px", display: "none"}}/>
                                                        }
                                                        {pay.ttUpsPrice > 0 &&
                                                            <div className={'m-a-order-detail-pay-delivery-box'}>
                                                                <div className="wd-40pc ht-80">
                                                                    <div className={`m-a-order-detail-pay-delivery-box-left
                                                            ${selectedOverseasShippingCost[pay.seqNum] === 'UPS'
                                                                    && (pay.payStatus === "PS0" || pay.payStatus === "PS1") && 'selected'}
                                                                 ${pay.payStatus === "PS0" || pay.payStatus === "PS1" && 'strong'}`}
                                                                         onClick={() => handleRadioChange(pay.seqNum, 'UPS')}>
                                                                        <div className="m-a-order-detail-shipco-logo-frame">
                                                                            <img style={{width: "60px", height: "30px", alignSelf: "flex-end", objectFit: "contain"}} src={upsLogo} alt={'UPS'}/>
                                                                        </div>
                                                                        <div className="m-a-order-detail-shipco-price-frame font-t-cont"
                                                                            style={{color: pay.ttUpsPrice > 0 ? "darkorange" : "dimgray", fontWeight: pay.ttUpsPrice > 0 ? "bold" : null,}}>
                                                                            {pay.ttUpsPrice.toLocaleString('en-US')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="display-flex flex-column wd-60pc" style={{alignItems: "flex-start"}}>
                                                                    <a className="font-s-15">UPS</a>
                                                                    <a className="font-s-13">{t(`modUps`)}</a>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {pay.ttEmsPrice > 0 &&
                                                    <div>
                                                        {pay.payStatus === "PS0" && pay.ttEmsPrice > 0 &&
                                                            <input className="radioButton" type="radio" value="EMS" checked={selectedOverseasShippingCost[pay.seqNum] === 'EMS'}
                                                                onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                                style={{borderStyle: "solid", width: "15px", height: "15px", display: "none"}}/>
                                                        }
                                                        {pay.ttEmsPrice > 0 &&
                                                            <div className={'m-a-order-detail-pay-delivery-box'}>
                                                                <div className="wd-40pc ht-80">
                                                                    <div className={`m-a-order-detail-pay-delivery-box-left 
                                                                    ${selectedOverseasShippingCost[pay.seqNum] === 'EMS'
                                                                            && (pay.payStatus === "PS0" || pay.payStatus === "PS1") && 'selected'
                                                                            } ${pay.payStatus === "PS0" || pay.payStatus === "PS1" && 'strong'}`}
                                                                                 onClick={() => handleRadioChange(pay.seqNum, 'EMS')}>
                                                                        <div className="m-a-order-detail-shipco-logo-frame">
                                                                            <img style={{width: "60px", height: "30px", alignSelf: "flex-end", objectFit: "contain"}} src={EmsLogo} alt={'ems'}/>
                                                                        </div>
                                                                        <div className="m-a-order-detail-shipco-price-frame font-t-cont"
                                                                            style={{color: pay.ttEmsPrice > 0 ? "darkorange" : "dimgray", fontWeight: pay.ttEmsPrice > 0 ? "bold" : null,}}>
                                                                            {pay.ttEmsPrice.toLocaleString('en-US')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="display-flex flex-column wd-60pc" style={{alignItems: "flex-start"}}>
                                                                    <a className="font-s-15">EMS</a>
                                                                    <a className="font-s-13">{t(`modEms`)}</a>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {pay.ttKPackPrice > 0 &&
                                                    <div>
                                                        {pay.payStatus === "PS0" && pay.ttKPackPrice > 0 &&
                                                            <input className="radioButton" type="radio" value="K-Packet" checked={selectedOverseasShippingCost[pay.seqNum] === 'K-Packet'}
                                                                onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                                style={{borderStyle: "solid", width: "15px", height: "15px", display: "none"}}/>
                                                        }
                                                        {pay.ttKPackPrice > 0 &&
                                                            <div className={'m-a-order-detail-pay-delivery-box'}>
                                                                <div className="wd-40pc ht-80">
                                                                    <div className={`m-a-order-detail-pay-delivery-box-left
                                                                    ${selectedOverseasShippingCost[pay.seqNum] === 'K-Packet'
                                                                            && (pay.payStatus === "PS0" || pay.payStatus === "PS1") && 'selected'}
                                                                         ${pay.payStatus === "PS0" || pay.payStatus === "PS1" && 'strong'}`}
                                                                         onClick={() => handleRadioChange(pay.seqNum, 'K-Packet')}>
                                                                        <div className="m-a-order-detail-shipco-logo-frame">
                                                                            <img style={{width: "60px", height: "30px", alignSelf: "flex-end", objectFit: "contain"}} src={kPacketLogo} alt={'K-Packet'}/>
                                                                        </div>
                                                                        <div className="m-a-order-detail-shipco-price-frame font-t-cont"
                                                                            style={{color: pay.ttKPackPrice > 0 ? "darkorange" : "dimgray", fontWeight: pay.ttKPackPrice > 0 ? "bold" : null,}}>
                                                                            {pay.ttKPackPrice.toLocaleString('en-US')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="display-flex flex-column wd-60pc" style={{alignItems: "flex-start"}}>
                                                                    <a className="font-s-15">K-Packet</a>
                                                                    <a className="font-s-13">{t(`modKpack`)}</a>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {pay.ttAirSPrice > 0 &&
                                                    <div>
                                                        {pay.payStatus === "PS0" && pay.ttAirSPrice > 0 &&
                                                            <input className="radioButton" type="radio" value="Small-Packet" checked={selectedOverseasShippingCost[pay.seqNum] === 'Small-Packet'}
                                                                onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                                style={{borderStyle: "solid", width: "15px", height: "15px", display: "none"}}/>
                                                        }
                                                        {pay.ttAirSPrice > 0 &&
                                                            <div className={'m-a-order-detail-pay-delivery-box'}>
                                                                <div className="wd-40pc ht-80">
                                                                    <div className={`m-a-order-detail-pay-delivery-box-left
                                                                    ${selectedOverseasShippingCost[pay.seqNum] === 'Small-Packet'
                                                                            && (pay.payStatus === "PS0" || pay.payStatus === "PS1") && 'selected'}
                                                                         ${pay.payStatus === "PS0" || pay.payStatus === "PS1" && 'strong'}`}
                                                                         onClick={() => handleRadioChange(pay.seqNum, 'Small-Packet')}>
                                                                        <div className="m-a-order-detail-shipco-logo-frame">
                                                                            <img style={{width: "60px", height: "30px", alignSelf: "flex-end", objectFit: "contain"}} src={smallPacketLogo} alt={'Small-Packet'}/>
                                                                        </div>
                                                                        <div className="m-a-order-detail-shipco-price-frame font-t-cont"
                                                                            style={{color: pay.ttAirSPrice > 0 ? "darkorange" : "dimgray", fontWeight: pay.ttAirSPrice > 0 ? "bold" : null,}}>
                                                                            {pay.ttAirSPrice.toLocaleString('en-US')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="display-flex flex-column wd-60pc" style={{alignItems: "flex-start"}}>
                                                                    <a className="font-s-15">Small-Packet</a>
                                                                    <a className="font-s-13">{t(`modAirS`)}</a>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {pay.ttSeaPrice > 0 &&
                                                    <div>
                                                        {pay.payStatus === "PS0" && pay.ttFedexPrice > 0 &&
                                                            <input style={{borderStyle: "solid", width: "15px", height: "15px", display: "none"}} type="radio" value="Sea"
                                                                   checked={selectedOverseasShippingCost[pay.seqNum] === 'Sea'}
                                                                   onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                            />
                                                        }
                                                        {pay.ttSeaPrice > 0 &&
                                                            <div className={'m-a-order-detail-pay-delivery-box'}>
                                                                <div className="wd-40pc ht-80">
                                                                    <div className={`m-a-order-detail-pay-delivery-box-left ${selectedOverseasShippingCost[pay.seqNum] === 'Sea'
                                                                    && (pay.payStatus === "PS0" || pay.payStatus === "PS1") && 'selected'}
                                                                            ${pay.payStatus === "PS0" || pay.payStatus === "PS1" && 'strong'}`}
                                                                         onClick={() => handleRadioChange(pay.seqNum, 'Sea')}>
                                                                        <div className="m-a-order-detail-shipco-logo-frame">
                                                                            <img style={{width: "60px", height: "30px", alignSelf: "flex-end", objectFit: "contain"}} src={SeaShipLogo} alt={'sea'}/>
                                                                        </div>
                                                                        <div className="m-a-order-detail-shipco-price-frame font-t-cont"
                                                                             style={{color: pay.ttSeaPrice > 0 ? "darkorange" : "dimgray", fontWeight: pay.ttSeaPrice > 0 ? "bold" : null,}}>
                                                                            {pay.ttSeaPrice.toLocaleString('en-US')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="display-flex flex-column wd-60pc" style={{alignItems: "flex-start"}}>
                                                                    <a className="font-s-15">Ship(sea)</a>
                                                                    <a className="font-s-13">{t(`modSea`)}</a>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {pay.ttDomPrice > 0 &&
                                                    <div>
                                                        {pay.payStatus === "PS0" && pay.ttDomPrice > 0 &&
                                                            <input className="radioButton" type="radio" value="Dom" checked={selectedOverseasShippingCost[pay.seqNum] === 'Dom'}
                                                                onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                                style={{
                                                                    borderStyle: "solid",
                                                                    width: "15px",
                                                                    height: "15px",
                                                                    display: "none"
                                                                }}/>
                                                        }
                                                        {pay.ttDomPrice > 0 &&
                                                            <div className={'m-a-order-detail-pay-delivery-box'}>
                                                                <div className="wd-40pc ht-80">
                                                                    <div className={`m-a-order-detail-pay-delivery-box-left
                                                            ${selectedOverseasShippingCost[pay.seqNum] === 'Dom'
                                                                    && (pay.payStatus === "PS0" || pay.payStatus === "PS1") && 'selected'}
                                                                 ${pay.payStatus === "PS0" || pay.payStatus === "PS1" && 'strong'}`}
                                                                         onClick={() => handleRadioChange(pay.seqNum, 'Dom')}>
                                                                        <div className="m-a-order-detail-shipco-logo-frame">
                                                                            <img style={{width: "60px", height: "30px", alignSelf: "flex-end", objectFit: "contain"}} src={domLogo} alt={'domestic delivery'}/>
                                                                        </div>
                                                                        <div className="m-a-order-detail-shipco-price-frame font-t-cont"
                                                                            style={{color: pay.ttDomPrice > 0 ? "darkorange" : "dimgray", fontWeight: pay.ttDomPrice > 0 ? "bold" : null,}}>
                                                                            {pay.ttDomPrice.toLocaleString('en-US')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="display-flex flex-column wd-60pc" style={{alignItems: "flex-start"}}>
                                                                    <a className="font-s-15">Domestic delivery</a>
                                                                    <a className="font-s-13">{t(`modDm`)}</a>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            : 0
                                        }
                                    </div>
                                    <div className={'m-a-order-detail-pay-item wd-130'}>
                                        {(pay?.ttEtcFee + pay?.ttEtcPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </div>
                                    <div className={'m-a-order-detail-pay-item wd-130'}>
                                        {pay.payStatus === "PS0" ?
                                            <p style={{margin: "0", color: "#98B0D4", fontSize: "13px"}}>{t(`mpCmSt1`)}</p>
                                            :
                                            pay.payStatus === "PS1" ?
                                                <p style={{
                                                    margin: "0",
                                                    color: "#98B0D4",
                                                    fontSize: "13px"
                                                }}>{t(`mpCmSt2`)}</p>
                                                :
                                                pay.payStatus === "PS2" ?
                                                    <p style={{
                                                        margin: "0",
                                                        color: "#FFB378",
                                                        fontSize: "13px"
                                                    }}>{t(`mpCmSt3`)}</p>
                                                    :
                                                    pay.payStatus === "PS3" ?
                                                        <p style={{
                                                            margin: "0",
                                                            color: "#979696",
                                                            fontSize: "13px"
                                                        }}>{t(`prodStLabel11`)}</p>
                                                        :
                                                        pay.payStatus === "PS4" ?
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8F62",
                                                                fontSize: "13px"
                                                            }}>{t(`prodStLabel12`)}</p>
                                                            :
                                                            pay.payStatus === "PS5" ?
                                                                <p style={{
                                                                    margin: "0",
                                                                    color: "#FF8F62",
                                                                    fontSize: "13px"
                                                                }}>{t(`prodStLabel13`)}</p>
                                                                :
                                                                null
                                        }
                                    </div>
                                    <div className={'m-a-order-detail-pay-item wd-80'}>
                                        <button className="admin-btn-B wd-80pc ht-27 font-s-12" onClick={() =>
                                            pay.payClass === '0' && pay.payType === "PT0" ?
                                                showDetail('prod', pay.seqNum) :
                                                pay.payType === "PT1" ?
                                                    showDetail('delivery', pay.seqNum) :
                                                    pay.payType === "PT2" ?
                                                        showDetail('etc', pay.seqNum) :
                                                        pay.payClass === '1' && pay.payType === "PT0" ?
                                                            showDetail('adminProd', pay.seqNum) :
                                                            null
                                        }>{t(`myDt`)}</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
            <div className='m-a-order-detail-sub-title'>
                <img className='m-a-order-detail-sub-title-icon' src={payFormHeader} alt=''/>
                <div className='m-a-order-detail-sub-title-text'>{t(`modHeader10`)}</div> {/*결제정보*/}
            </div>
            <div className={'m-a-order-detail-divider'}/>
            <div className={'m-a-order-detail-section-frame'}>
                <div className={'m-a-order-detail-section-title'}>{t(`modSHeader1`)}</div>
                <MOrderSection topVisibility={false}>
                    <div className={'m-a-order-detail-section-payment-title'}>{t(`modSHeader1`)}</div>
                    {MOrderPaymentRow(t(`modSHeader2`), `${finalQuantity}${t(`ea`)}`)}
                    {MOrderPaymentRow(t(`modSHeader0`),
                        `${finalProdExpense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${t(`won`)}`)}
                    {MOrderPaymentRow(t(`modSHeader4`),
                        `${finalMallShipPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${t(`won`)}`)}
                    {MOrderPaymentRow(t(`modSHeader5`),
                        `${finalFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${t(`won`)}`)}
                    <div className={'m-a-order-detail-section-payment-title'}>{t(`modSHeader6`)}</div> {/*해외배송비*/}
                    {MOrderPaymentRow(t(`modSHeader7`),
                        `${finalOverseasShipExpense}${t(`won`)}`)}
                    <div className={'m-a-order-detail-section-payment-title'}>{t(`modSHeader8`)}</div> {/*기타*/}
                    {MOrderPaymentRow(t(`modSHeader9`),
                        `${memberInfo && memberInfo.point > 0 ?
                            dbPoint ? dbPoint.toLocaleString('en-US') : 0 :
                            0}${t(`won`)}`)}
                    <div className={'m-a-order-detail-section-payment-title'}>{t(`modSHeader10`)}</div> {/*할인/공제*/}
                    <div className={'m-a-order-detail-section-payment-discount'}>
                        <div className={'m-a-order-detail-section-payment-discount-label'}>
                            {t(`modSHeader11`)} <br/>
                            ({memberInfo && memberInfo.point > 0 ?
                                <div className={'m-a-order-detail-section highlight'}>
                                    {dbPoint ? dbPoint.toLocaleString('en-US') : 0}</div>
                                : <div className={'m-a-order-detail-section'}>0</div>
                            })
                        </div>
                        <div className={'m-a-order-detail-section-payment-discount-right'}>
                            <input className={'m-a-order-detail-section-payment-discount-input'} type="text"
                                   value={pointSale.toLocaleString('en-US')} onChange={(e)=> handlePointSale(e.target.value)}/>
                            <button className={'m-a-order-detail-section-payment-discount-button'}
                                 onClick={allPointSale}>{t(`modDpUseBt`)} {/*전액사용*/}
                            </button>
                        </div>
                    </div>
                    <div className={'m-a-order-detail-section-payment-discount'}>
                        <div className={'m-a-order-detail-section-payment-discount-label'}>
                            {t(`modSHeader12`)}<br/>({/*예치금*/}
                            {memberInfo && memberInfo.deposit > 0 ?
                                <div className={'m-a-order-detail-section highlight'}>{dbDeposit ? dbDeposit.toLocaleString('en-US') : 0}</div>
                                : <div className={'m-a-order-detail-section'}>0</div>
                            })
                        </div>
                        <div className={'m-a-order-detail-section-payment-discount-right'}>
                            <input className={'m-a-order-detail-section-payment-discount-input'} type="text"
                                   value={depositSale.toLocaleString('en-US')} onChange={(e)=> handleDepositSale(e.target.value)}/>
                            <button className={'m-a-order-detail-section-payment-discount-button'}
                                 onClick={allDepositSale}>{t(`modDpUseBt`)}</button> {/*전액사용*/}
                        </div>
                    </div>
                    <div className={'m-a-order-detail-section-payment-discount'}>
                        <div className={'m-a-order-detail-section-payment-discount-label'}>
                            {t(`modSHeader13`)} <br/>(
                            {dbCoupon && dbCoupon > 0 ?
                                <div className={'m-a-order-detail-section highlight'}>{dbCoupon}</div>
                                : <div className={'m-a-order-detail-section'}>0</div>
                            })
                        </div>
                        <div className={'m-a-order-detail-section-payment-discount-right'}>
                            <input className={'m-a-order-detail-section-payment-discount-input'} readOnly={true} value={couponSale.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                   onChange={(e)=> handleDepositSale(e.target.value)}/>
                            {couponSale === '' ?
                                <button className={'m-a-order-section-payment-discount-button'} onClick={showUseCoupon}>{t(`modCUseBt`)}</button>
                                :
                                <button className={'m-a-order-section-payment-discount-button'} onClick={closeUseCoupon}>{t(`st3`)}</button>
                            }
                        </div>
                    </div>
                    <div className={'m-a-order-detail-divider'}/>
                    <div className={'m-a-order-detail-section-payment-title'}>
                        {t(`modFinalPay`)} {/*최종결제금액*/}
                        <div className={'m-a-order-detail-section-payment-title-right'}>
                            <div className={'m-a-order-detail-section-payment-title-highlight'}>
                                {finalPayExpense}</div>
                            {t(`won`)}
                        </div>
                    </div>
                    {isOrderPay ? (
                        <>
                            <div className={'m-a-order-detail-section-payment-title'}>{t(`modPty`)}</div> {/*결제수단*/}
                            <div className={'m-a-order-detail-section-payment-method-grid'}>
                                <div className={`m-a-order-detail-section-payment-method ${payWay === "PM06" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM06")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "90%", height: "70%"}} src={btoragePay} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-detail-section-payment-method-text'}>BtoragePay</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-detail-section-payment-method ${payWay === "PM07" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM07")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "90%", height: "70%"}} src={creditCard} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-detail-section-payment-method-text'}>CreditCard</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-detail-section-payment-method ${payWay === "PM08" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM08")}>
                                    <div className="ht-70pc user-center" style={{paddingTop: "10px"}}>
                                        <img style={{width: "80%", height: "60%"}} src={stripe} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-detail-section-payment-method-text'}>Stripe</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-detail-section-payment-method ${payWay === "PM01" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM01")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "90%", height: "70%"}} src={PaypalLogo} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-detail-section-payment-method-text'}>Paypal</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-detail-section-payment-method ${payWay === "PM02" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM02")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "90%", height: "70%"}} src={WechatpayLogo} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-section-payment-method-text'}>WechatPay</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-detail-section-payment-method ${payWay === "PM03" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM03")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "100%", height: "60%"}} src={AlipayLogo} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-detail-section-payment-method-text'}>Alipay</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-detail-section-payment-method ${payWay === "PM00" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM00")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "100%", height: "70%"}} src={NoBank} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-detail-section-payment-method-text'}>{t(`modNoBank`)}</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-detail-section-payment-method ${payWay === "PM05" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM05")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "100%", height: "70%"}} src={DepositPay} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-detail-section-payment-method-text'}>{t(`modDep`)}</p>
                                    </div>
                                </div>
                                <div className={`m-a-order-detail-section-payment-method ${payWay === "PM04" && 'focused'}`}
                                     onClick={() => handlePayWayChange("PM04")}>
                                    <div className="ht-70pc user-center">
                                        <img style={{width: "100%", height: "60%"}} src={PointPay} alt={''}/>
                                    </div>
                                    <div className="ht-30pc">
                                        <p className={'m-a-order-detail-section-payment-method-text'}>{t(`modPt`)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'m-a-order-detail-divider'}/>
                            {payWay === "PM01" ?
                                <div className={'m-a-order-detail-payment-detail-box'}>
                                    <div className={'m-a-order-detail-payment-detail-box-row'}>
                                        <div className={'m-a-order-detail-payment-detail-box-left'}>{t(`modPpExr`)}</div>
                                        <div className={'m-a-order-detail-payment-detail-box-right'}>
                                            {lastForPrice}
                                            <div ref={dollarRef} onClick={toggleDollarShow}
                                                 className={'m-a-order-detail-payment-detail-box-dropdown'}>
                                                {currency}
                                                {dollarShow && (
                                                    <div className={'m-a-order-detail-payment-detail-dropdown-list'}>
                                                        {dollarList.map((dollar, index) => (
                                                            <div key={index}
                                                                 className={'m-a-order-detail-payment-detail-dropdown-item'}
                                                                 onClick={() => selectChangeDollar(dollar)}>
                                                                {dollar}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : payWay === "PM03" ?
                                    <div className={'m-a-order-detail-payment-detail-box'}>
                                        <div className={'m-a-order-detail-payment-detail-box-row'}>
                                            <div
                                                className={'m-a-order-detail-payment-detail-box-left'}>{t(`modExr`)}</div>
                                            <div className={'m-a-order-detail-payment-detail-box-right'}>
                                                {lastForPrice}
                                                <div
                                                    className={'m-a-order-detail-payment-detail-box-dropdown no-border'}>
                                                    {currency}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'m-a-order-detail-divider'}/>
                                        <div className={'m-a-order-detail-payment-detail-box-bottom'}>
                                            <div className={'m-a-order-detail-payment-detail-box-desc'}>
                                                - {t(`modAliAcct`)} : 13165072023
                                            </div>
                                            <div className={'m-a-order-detail-payment-detail-box-desc'}>
                                                - {t(`modAcctNm`)} : IMEUNWOO
                                            </div>
                                            <img className={'m-a-order-detail-payment-detail-box-img'}
                                                 src={AliQrCode} alt={''}/>
                                        </div>
                                    </div>
                                    : payWay === "PM02" ?
                                        <div className={'m-a-order-detail-payment-detail-box'}>
                                            <div className={'m-a-order-detail-payment-detail-box-row'}>
                                                <div
                                                    className={'m-a-order-detail-payment-detail-box-left'}>{t(`modExr`)}</div>
                                                <div className={'m-a-order-detail-payment-detail-box-right'}>
                                                    {lastForPrice}
                                                    <div
                                                        className={'m-a-order-detail-payment-detail-box-dropdown no-border'}>
                                                        {currency}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'m-a-order-detail-divider'}/>
                                            <div className={'m-a-order-detail-payment-detail-box-bottom'}>
                                                <div className={'m-a-order-detail-payment-detail-box-desc'}>
                                                    Weixin ID : lfamily-help
                                                </div>
                                                <div className={'m-a-order-detail-payment-detail-box-desc'}>
                                                    - {t(`modAcctNm`)} : IMEUNWOO
                                                </div>
                                                <img className={'m-a-order-detail-payment-detail-box-img'}
                                                     src={wechatQrCode} alt={''}/>
                                            </div>
                                        </div>
                                        : payWay === "PM00" ?
                                            <div className={'m-a-order-detail-payment-detail-box'}>
                                                <div className={'m-a-order-detail-payment-detail-box-bottom'}>
                                                    <div className={'m-a-order-detail-payment-detail-box-desc'}>
                                                        <div className={'bold'}>● {t(`modNbInfo1`)} </div>
                                                        - {t(`modAcctInfo`)}<br/>
                                                        <div className={'bold'}>● {t(`welcome`)}</div>
                                                        - {t(`modAcctNm`)} : Btorage corp<br/>
                                                        - {t(`modNbInfo4`)} ShinhanBank 140-014-047039<br/>
                                                        - {t(`modNbInfo5`)} : 82-10-3338-9354<br/>
                                                        - {t(`modNbInfo6`)} : help@btorage.com<br/>
                                                        - {t(`modNbInfo7`)} : 1F, 32-15, Yeongok-gil
                                                        42beon-gil, Gonjiam-eup, Gwangju-si, Gyeonggi-do
                                                        <br/>
                                                        - {t(`modNbInfo8`)} : 12722<br/>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                            }

                            {/*{*/}
                            {/*(*/}
                            {/*(hasBothCartAndDbCart && hasCartProduct) ||*/}
                            {/*(hasBothOrderAndDbOrder && hasOrderProduct) ||*/}
                            {/*(hasBothOfflineAndDbOffline && hasOfflineProduct) ||*/}
                            {/*(hasBothTransferAndDbTransfer && hasTransferProduct) ||*/}
                            {/*(hasBothDomesticAndDbDomestic && hasDomesticProduct) ||*/}
                            {/*(hasBothDeliveryAndDbDelivery && hasDeliveryProduct)*/}
                            {/*) ?*/}
                            {/*        (*/}
                            {/*            <div className="font-t-cont" style={{*/}
                            {/*            border: "solid 1px #EAEAEA",*/}
                            {/*            marginTop: "30px",*/}
                            {/*            minHeight: "50px",*/}
                            {/*            borderRadius: "4px",*/}
                            {/*            padding: "15px 20px"*/}
                            {/*        }}>*/}
                            {/*            /!*전체동의*!/*/}
                            {/*            <div style={{*/}
                            {/*                display: "flex",*/}
                            {/*                alignItems: "center",*/}
                            {/*                borderBottom: "solid 1px #EAEAEA",*/}
                            {/*                minHeight: "50px",*/}
                            {/*                marginBottom: "15px"*/}
                            {/*            }}>*/}
                            {/*                <input id="AllAgreeCheckBox" type="checkbox"*/}
                            {/*                       style={{width: "18px", height: "18px", cursor: "pointer"}}*/}
                            {/*                       checked={allAgreeCheck} onChange={allAgreeHandle}/>*/}
                            {/*                <label htmlFor="AllAgreeCheckBox"><span style={{*/}
                            {/*                    fontSize: "15px",*/}
                            {/*                    marginLeft: "8px",*/}
                            {/*                    cursor: "pointer"*/}
                            {/*                }}>{t(`joinAgAll`)}</span></label>*/}
                            {/*            </div>*/}
                            {/*            {cartProductHandle.some(cart => cart.name && cart.name.length > 0) ?*/}
                            {/*                <div style={{*/}
                            {/*                    display: "flex",*/}
                            {/*                    alignItems: "center",*/}
                            {/*                    minHeight: "30px",*/}
                            {/*                    marginTop: "10px"*/}
                            {/*                }}>*/}
                            {/*                    <div style={{width: "8%", display: "flex", alignItems: "center"}}>*/}
                            {/*                        <input id="cartOrderAgree" type="checkbox"*/}
                            {/*                               style={{width: "18px", height: "18px", cursor: "pointer"}}*/}
                            {/*                               checked={cartServiceAgreeCheck}*/}
                            {/*                               onChange={handleAgreeChange(setCartServiceAgreeCheck)}/>*/}
                            {/*                    </div>*/}
                            {/*                    <div style={{width: "92%", marginLeft: "5px"}}>*/}
                            {/*                        <label htmlFor="cartOrderAgree"><span style={{*/}
                            {/*                            color: "#818181",*/}
                            {/*                            fontSize: "15px",*/}
                            {/*                            cursor: "pointer"*/}
                            {/*                        }}>{t(`modPiAgCart`)}</span>*/}
                            {/*                            <span style={{*/}
                            {/*                                color: "#FF7C48",*/}
                            {/*                                fontSize: "14px",*/}
                            {/*                                marginLeft: "3px"*/}
                            {/*                            }}>({t(`essential`)})</span>*/}
                            {/*                        </label>*/}
                            {/*                    </div>*/}
                            {/*                    <span onClick={() => setCartTermsPopup(true)} style={{*/}
                            {/*                        cursor: "pointer",*/}
                            {/*                        width: "10%",*/}
                            {/*                        display: "flex",*/}
                            {/*                        alignItems: "center",*/}
                            {/*                        justifyContent: "flex-end"*/}
                            {/*                    }}>*/}
                            {/*                    <img style={{width: "20px", height: "20px", opacity: "0.4"}}*/}
                            {/*                         src={termsView} alt="view"/>*/}
                            {/*                </span>*/}
                            {/*                </div>*/}
                            {/*                :*/}
                            {/*                null*/}
                            {/*            }*/}
                            {/*            {orderProductHandle.some(order => order.name && order.name.length > 0) ?*/}
                            {/*                <div style={{*/}
                            {/*                    display: "flex",*/}
                            {/*                    alignItems: "center",*/}
                            {/*                    minHeight: "30px",*/}
                            {/*                    marginTop: "10px"*/}
                            {/*                }}>*/}
                            {/*                    <div style={{width: "8%", display: "flex", alignItems: "center"}}>*/}
                            {/*                        <input id="orderUseAgree" type="checkbox"*/}
                            {/*                               style={{width: "18px", height: "18px", cursor: "pointer"}}*/}
                            {/*                               checked={orderServiceAgreeCheck}*/}
                            {/*                               onChange={handleAgreeChange(setOrderServiceAgreeCheck)}/>*/}
                            {/*                    </div>*/}
                            {/*                    <div style={{width: "92%", marginLeft: "5px"}}>*/}
                            {/*                        <label htmlFor="orderUseAgree"><span style={{*/}
                            {/*                            color: "#818181",*/}
                            {/*                            fontSize: "15px",*/}
                            {/*                            cursor: "pointer"*/}
                            {/*                        }}>{t(`modPiAgOrder`)}</span>*/}
                            {/*                            <span style={{*/}
                            {/*                                color: "#FF7C48",*/}
                            {/*                                fontSize: "14px",*/}
                            {/*                                marginLeft: "3px"*/}
                            {/*                            }}>({t(`essential`)})</span>*/}
                            {/*                        </label>*/}
                            {/*                    </div>*/}
                            {/*                    <span onClick={() => setOrderTermsPopup(true)} style={{*/}
                            {/*                        cursor: "pointer",*/}
                            {/*                        width: "10%",*/}
                            {/*                        display: "flex",*/}
                            {/*                        alignItems: "center",*/}
                            {/*                        justifyContent: "flex-end"*/}
                            {/*                    }}>*/}
                            {/*                    <img style={{width: "20px", height: "20px", opacity: "0.4"}}*/}
                            {/*                         src={termsView} alt="view"/>*/}
                            {/*                </span>*/}
                            {/*                </div>*/}
                            {/*                :*/}
                            {/*                null*/}
                            {/*            }*/}
                            {/*            {offlineProductHandle.some(offline => offline.name && offline.name.length > 0) ?*/}
                            {/*                <div style={{*/}
                            {/*                    display: "flex",*/}
                            {/*                    alignItems: "center",*/}
                            {/*                    minHeight: "30px",*/}
                            {/*                    marginTop: "10px"*/}
                            {/*                }}>*/}
                            {/*                    <div style={{width: "8%", display: "flex", alignItems: "center"}}>*/}
                            {/*                        <input id="offlineUseAgree" type="checkbox"*/}
                            {/*                               style={{width: "18px", height: "18px", cursor: "pointer"}}*/}
                            {/*                               checked={offlineServiceAgreeCheck}*/}
                            {/*                               onChange={handleAgreeChange(setOfflineServiceAgreeCheck)}/>*/}
                            {/*                    </div>*/}
                            {/*                    <div style={{width: "92%", marginLeft: "5px"}}>*/}
                            {/*                        <label htmlFor="offlineUseAgree"><span style={{*/}
                            {/*                            color: "#818181",*/}
                            {/*                            fontSize: "15px",*/}
                            {/*                            cursor: "pointer"*/}
                            {/*                        }}>{t(`modPiAgOff`)}</span>*/}
                            {/*                            <span style={{*/}
                            {/*                                color: "#FF7C48",*/}
                            {/*                                fontSize: "14px",*/}
                            {/*                                marginLeft: "3px"*/}
                            {/*                            }}>({t(`essential`)})</span>*/}
                            {/*                        </label>*/}
                            {/*                    </div>*/}
                            {/*                    <span onClick={() => setOfflineTermsPopup(true)} style={{*/}
                            {/*                        cursor: "pointer",*/}
                            {/*                        width: "10%",*/}
                            {/*                        display: "flex",*/}
                            {/*                        alignItems: "center",*/}
                            {/*                        justifyContent: "flex-end"*/}
                            {/*                    }}>*/}
                            {/*                    <img style={{width: "20px", height: "20px", opacity: "0.4"}}*/}
                            {/*                         src={termsView} alt="view"/>*/}
                            {/*                </span>*/}
                            {/*                </div>*/}
                            {/*                :*/}
                            {/*                null*/}
                            {/*            }*/}
                            {/*            {transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0) ?*/}
                            {/*                <div style={{*/}
                            {/*                    display: "flex",*/}
                            {/*                    alignItems: "center",*/}
                            {/*                    minHeight: "30px",*/}
                            {/*                    marginTop: "10px"*/}
                            {/*                }}>*/}
                            {/*                    <div style={{width: "8%", display: "flex", alignItems: "center"}}>*/}
                            {/*                        <input id="transferUseAgree" type="checkbox"*/}
                            {/*                               style={{width: "18px", height: "18px", cursor: "pointer"}}*/}
                            {/*                               checked={transferServiceAgreeCheck}*/}
                            {/*                               onChange={handleAgreeChange(setTransferServiceAgreeCheck)}/>*/}
                            {/*                    </div>*/}
                            {/*                    <div style={{width: "92%", marginLeft: "5px"}}>*/}
                            {/*                        <label htmlFor="transferUseAgree"><span style={{*/}
                            {/*                            color: "#818181",*/}
                            {/*                            fontSize: "15px",*/}
                            {/*                            cursor: "pointer"*/}
                            {/*                        }}>{t(`modPiAgTr`)}</span>*/}
                            {/*                            <span style={{*/}
                            {/*                                color: "#FF7C48",*/}
                            {/*                                fontSize: "14px",*/}
                            {/*                                marginLeft: "3px"*/}
                            {/*                            }}>({t(`essential`)})</span>*/}
                            {/*                        </label>*/}
                            {/*                    </div>*/}
                            {/*                    <span onClick={() => setTransferTermsPopup(true)} style={{*/}
                            {/*                        cursor: "pointer",*/}
                            {/*                        width: "10%",*/}
                            {/*                        display: "flex",*/}
                            {/*                        alignItems: "center",*/}
                            {/*                        justifyContent: "flex-end"*/}
                            {/*                    }}>*/}
                            {/*                    <img style={{width: "20px", height: "20px", opacity: "0.4"}}*/}
                            {/*                         src={termsView} alt="view"/>*/}
                            {/*                </span>*/}
                            {/*                </div>*/}
                            {/*                :*/}
                            {/*                null*/}
                            {/*            }*/}
                            {/*            {domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0) ?*/}
                            {/*                <div style={{*/}
                            {/*                    display: "flex",*/}
                            {/*                    alignItems: "center",*/}
                            {/*                    minHeight: "30px",*/}
                            {/*                    marginTop: "10px"*/}
                            {/*                }}>*/}
                            {/*                    <div style={{width: "8%", display: "flex", alignItems: "center"}}>*/}
                            {/*                        <input id="domesticUseAgree" type="checkbox"*/}
                            {/*                               style={{width: "18px", height: "18px", cursor: "pointer"}}*/}
                            {/*                               checked={domesticServiceAgreeCheck}*/}
                            {/*                               onChange={handleAgreeChange(setDomesticServiceAgreeCheck)}/>*/}
                            {/*                    </div>*/}
                            {/*                    <div style={{width: "92%", marginLeft: "5px"}}>*/}
                            {/*                        <label htmlFor="domesticUseAgree"><span style={{*/}
                            {/*                            color: "#818181",*/}
                            {/*                            fontSize: "15px",*/}
                            {/*                            cursor: "pointer"*/}
                            {/*                        }}>{t(`modPiAgDm`)}</span>*/}
                            {/*                            <span style={{*/}
                            {/*                                color: "#FF7C48",*/}
                            {/*                                fontSize: "14px",*/}
                            {/*                                marginLeft: "3px"*/}
                            {/*                            }}>({t(`essential`)})</span>*/}
                            {/*                        </label>*/}
                            {/*                    </div>*/}
                            {/*                    <span onClick={() => setDomesticTermsPopup(true)} style={{*/}
                            {/*                        cursor: "pointer",*/}
                            {/*                        width: "10%",*/}
                            {/*                        display: "flex",*/}
                            {/*                        alignItems: "center",*/}
                            {/*                        justifyContent: "flex-end"*/}
                            {/*                    }}>*/}
                            {/*                    <img style={{width: "20px", height: "20px", opacity: "0.4"}}*/}
                            {/*                         src={termsView} alt="view"/>*/}
                            {/*                </span>*/}
                            {/*                </div>*/}
                            {/*                :*/}
                            {/*                null*/}
                            {/*            }*/}
                            {/*            {deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0) ?*/}
                            {/*                <div style={{*/}
                            {/*                    display: "flex",*/}
                            {/*                    alignItems: "center",*/}
                            {/*                    minHeight: "30px",*/}
                            {/*                    marginTop: "10px"*/}
                            {/*                }}>*/}
                            {/*                    <div style={{width: "8%", display: "flex", alignItems: "center"}}>*/}
                            {/*                        <input id="deliveryUseAgree" type="checkbox"*/}
                            {/*                               style={{width: "18px", height: "18px", cursor: "pointer"}}*/}
                            {/*                               checked={deliveryServiceAgreeCheck}*/}
                            {/*                               onChange={handleAgreeChange(setDeliveryServiceAgreeCheck)}/>*/}
                            {/*                    </div>*/}
                            {/*                    <div style={{width: "92%", marginLeft: "5px"}}>*/}
                            {/*                        <label htmlFor="deliveryUseAgree"><span style={{*/}
                            {/*                            color: "#818181",*/}
                            {/*                            fontSize: "15px",*/}
                            {/*                            cursor: "pointer"*/}
                            {/*                        }}>{t(`modPiAgDv`)}</span>*/}
                            {/*                            <span style={{*/}
                            {/*                                color: "#FF7C48",*/}
                            {/*                                fontSize: "14px",*/}
                            {/*                                marginLeft: "3px"*/}
                            {/*                            }}>({t(`essential`)})</span>*/}
                            {/*                        </label>*/}
                            {/*                    </div>*/}
                            {/*                    <span onClick={() => setDeliveryTermsPopup(true)} style={{*/}
                            {/*                        cursor: "pointer",*/}
                            {/*                        width: "10%",*/}
                            {/*                        display: "flex",*/}
                            {/*                        alignItems: "center",*/}
                            {/*                        justifyContent: "flex-end"*/}
                            {/*                    }}>*/}
                            {/*                    <img style={{width: "20px", height: "20px", opacity: "0.4"}}*/}
                            {/*                         src={termsView} alt="view"/>*/}
                            {/*                </span>*/}
                            {/*                </div>*/}
                            {/*                :*/}
                            {/*                null*/}
                            {/*            }*/}
                            {/*        </div>) : null*/}
                            {/*}*/}
                        </>
                    ) : null}
                    {
                        (
                            (hasBothCartAndDbCart && hasCartProduct) ||
                            (hasBothOrderAndDbOrder && hasOrderProduct) ||
                            (hasBothOfflineAndDbOffline && hasOfflineProduct) ||
                            (hasBothTransferAndDbTransfer && hasTransferProduct) ||
                            (hasBothDomesticAndDbDomestic && hasDomesticProduct) ||
                            (hasBothDeliveryAndDbDelivery && hasDeliveryProduct)
                        ) ?
                            (
                                <div className={'m-a-order-agreement-box'}>
                                    <div className={'m-a-order-agreement-row-left'}>
                                        <input id="AllAgreeCheckBox" type="checkbox"
                                               style={{width: "18px", height: "18px", cursor: "pointer"}}
                                               checked={allAgreeCheck} onChange={allAgreeHandle}/>
                                        <div className={'m-a-order-agreement-text strong'}>{t(`joinAgAll`)}</div> {/*전체동의*/}
                                    </div>
                                    <div className={'m-a-order-divider'}/>
                                    {hasBothCartAndDbCart && hasCartProduct ?
                                        (
                                            <div className={'m-a-order-agreement-row'}>
                                                <div className={'m-a-order-agreement-row-left'}>
                                                    <input id="cartOrderAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={cartServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setCartServiceAgreeCheck)}/>
                                                    <div className={'m-a-order-agreement-text'}>{t(`modPiAgCart`)}
                                                        <div className={'m-a-order-agreement-text primary'}>
                                                            ({t(`essential`)})
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className={'m-a-order-agreement-row-right'} src={termsView}
                                                     onClick={() => setCartTermsPopup(true)} alt={''}/>
                                            </div>
                                        ) : null}
                                    {hasBothOrderAndDbOrder && hasOrderProduct ?
                                        (
                                            <div className={'m-a-order-agreement-row'}>
                                                <div className={'m-a-order-agreement-row-left'}>
                                                    <input id="orderUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={orderServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setOrderServiceAgreeCheck)}/>
                                                    <div className={'m-a-order-agreement-text'}>{t(`modPiAgOrder`)}
                                                        <div className={'m-a-order-agreement-text primary'}>
                                                            ({t(`essential`)})
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className={'m-a-order-agreement-row-right'} src={termsView}
                                                     onClick={() => setOrderTermsPopup(true)} alt={''}/>
                                            </div>
                                        ) : null}
                                    {hasBothOfflineAndDbOffline && hasOfflineProduct ?
                                        (
                                            <div className={'m-a-order-agreement-row'}>
                                                <div className={'m-a-order-agreement-row-left'}>
                                                    <input id="offlineUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={offlineServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setOfflineServiceAgreeCheck)}/>
                                                    <div className={'m-a-order-agreement-text'}>{t(`modPiAgOff`)}
                                                        <div className={'m-a-order-agreement-text primary'}>
                                                            ({t(`essential`)})
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className={'m-a-order-agreement-row-right'} src={termsView}
                                                     onClick={() => setOfflineTermsPopup(true)} alt={''}/>
                                            </div>
                                        ) : null}
                                    {hasBothTransferAndDbTransfer && hasTransferProduct ?
                                        (
                                            <div className={'m-a-order-agreement-row'}>
                                                <div className={'m-a-order-agreement-row-left'}>
                                                    <input id="transferUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={transferServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setTransferServiceAgreeCheck)}/>
                                                    <div className={'m-a-order-agreement-text'}>{t(`modPiAgTr`)}
                                                        <div className={'m-a-order-agreement-text primary'}>
                                                            ({t(`essential`)})
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className={'m-a-order-agreement-row-right'} src={termsView}
                                                     onClick={() => setTransferTermsPopup(true)} alt={''}/>
                                            </div>
                                        ) : null}
                                    {hasBothDomesticAndDbDomestic && hasDomesticProduct ?
                                        (
                                            <div className={'m-a-order-agreement-row'}>
                                                <div className={'m-a-order-agreement-row-left'}>
                                                    <input id="domesticUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={domesticServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setDomesticServiceAgreeCheck)}/>
                                                    <div className={'m-a-order-agreement-text'}>{t(`modPiAgDm`)}
                                                        <div className={'m-a-order-agreement-text primary'}>
                                                            ({t(`essential`)})
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className={'m-a-order-agreement-row-right'} src={termsView}
                                                     onClick={() => setDomesticTermsPopup(true)} alt={''}/>
                                            </div>
                                        ) : null}
                                    {hasBothDeliveryAndDbDelivery && hasDeliveryProduct ?
                                        (
                                            <div className={'m-a-order-agreement-row'}>
                                                <div className={'m-a-order-agreement-row-left'}>
                                                    <input id="deliveryUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={deliveryServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setDeliveryServiceAgreeCheck)}/>
                                                    <div className={'m-a-order-agreement-text'}>{t(`modPiAgDv`)}
                                                        <div className={'m-a-order-agreement-text primary'}>
                                                            ({t(`essential`)})
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className={'m-a-order-agreement-row-right'} src={termsView}
                                                     onClick={() => setDeliveryTermsPopup(true)} alt={''}/>
                                            </div>
                                        ) : null}
                                </div>
                            ) : null
                    }
                    {agreeCheckBoxError && agreeErrorMsgCondition &&
                        <div className={'m-a-order-error-message-box'}> {t(`modPiErr4`)}</div>
                    }
                </MOrderSection>
            </div>
            <div className='m-a-order-detail-sub-title'>
                <img className='m-a-order-detail-sub-title-icon' src={footerCenterLogo} alt=''/>
                <div className='m-a-order-detail-sub-title-text'>{t(`hdChat`)}</div> {/*주문서채팅*/}
            </div>
            <div className={'m-a-order-detail-divider'}/>
            <div className="m-a-order-dt-chat-container">
                <div className="m-a-chat-body-container" ref={messagesEndRef}>
                    {/*주문서 채팅바디*/}
                    {chatMessages && chatMessages.map((message, index) => (
                        <div key={index}>
                            {(isFirstMessageOfDay(message, chatMessages[index - 1]) &&
                                <div className="m-a-chat-first-time-frame">
                                    <div
                                        className="m-a-chat-first-time font-t-cont">{formatDate(message.regDate)}</div>
                                </div>
                            )}
                            {message.memberDTO && message.memberDTO.role === '1' ?
                                // 주문서 관리자프로필
                                <div className="" style={{
                                    display: "flex",
                                    justifyContent: message.memberDTO && message.memberDTO.role === '1' ? 'flex-start' : 'flex-end'
                                }}>
                                    <div className="m-a-chat-userProfile-frame2">
                                        <div className="m-a-chat-userProfile-frame">
                                            { message.memberDTO.profileDTO ?
                                                <img
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius:"100%",
                                                        objectFit: "cover"
                                                    }}
                                                    className="m-a-chat-userProfile-img" alt="profile-img"
                                                     src={
                                                         "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + message.memberDTO.profileDTO.filename
                                                     }/>
                                                :
                                                <img style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit:"contain",
                                                    borderRadius:"100%",
                                                    border:"solid",
                                                    borderWidth: "thin 1px",
                                                    borderColor: "#EAEAEA",
                                                    padding:"8px"
                                                }} className="m-a-chat-userProfile-img"
                                                     src={BtorageLogo}
                                                     alt="profile-img"/>
                                            }
                                        </div>
                                        <div className="m-a-chat-nameCont-frame">
                                            <div
                                                // className="m-a-chat-userNm font-s-15 font-t-title">{message.mstUserNm}</div>
                                                className="m-a-chat-userNm font-s-15 font-t-title">Btorage</div>
                                            <div className="m-a-chat-content-time-frame">
                                                {/*쳇메시지 프레임*/}
                                                {message.chatKey.startsWith("chatFile") ?
                                                    <div
                                                        onClick={!message.removeFl ? () => clickFileDetail(message) : null}
                                                        className="m-a-chatKey-file-container">
                                                        {/*관리자이미지*/}
                                                        {isImage(message.chatKey) ?
                                                            <div className="m-a-chatKey-img-frame">
                                                                {!message.removeFl ?
                                                                    <img className="m-a-chatKey-img"
                                                                         alt="chatImg" src={message.fileUrl}/>
                                                                    :
                                                                    <div
                                                                        className="m-a-chatKey-file-expiration-img-container">
                                                                        <img
                                                                            className="m-a-chatKey-file-expiration-img"
                                                                            alt="expiration" src={trash}/>
                                                                        <div
                                                                            className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            :
                                                            // 관리자동영상
                                                            isVideo(message.chatKey) ?
                                                                <div className="m-a-chatKey-video-frame"
                                                                     style={{pointerEvents: "none"}}>
                                                                    {!message.removeFl ?
                                                                        <video width="250" height="250" controls>
                                                                            <source src={message.fileUrl}
                                                                                    type="video/mp4"
                                                                                    style={{pointerEvents: "auto"}}/>
                                                                        </video>
                                                                        :
                                                                        <div
                                                                            className="m-a-chatKey-file-expiration-img-container">
                                                                            <img
                                                                                className="m-a-chatKey-file-expiration-img"
                                                                                alt="expiration" src={trash}/>
                                                                            <div
                                                                                className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                :
                                                                // 관리자파일
                                                                <div className="m-a-chatKey-file-frame1">
                                                                    {!message.removeFl ?
                                                                        <div
                                                                            className="m-a-chatKey-file-frame2">
                                                                            <div
                                                                                className="m-a-chatKey-file-img-frame user-center">
                                                                                <img
                                                                                    className="m-a-chatKey-file-img"
                                                                                    alt="fileImg"
                                                                                    src={chatFileImg}/>
                                                                            </div>
                                                                            <div
                                                                                className="m-a-chatKey-fileNm-text-frame">
                                                                                        <span
                                                                                            className="m-a-chatKey-fileNm-text font-t-title">{message.fileNm}</span>
                                                                            </div>
                                                                            <div
                                                                                className="m-a-chatKey-expiration-date font-t-cont"> ~ {oneMonthFromNow(message.deadline)}까지
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div
                                                                            className="m-a-chatKey-file-expiration-img-container">
                                                                            <img
                                                                                className="m-a-chatKey-file-expiration-img"
                                                                                alt="expiration" src={trash}/>
                                                                            <div
                                                                                className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                        }
                                                    </div>
                                                    :
                                                    // 관리자텍스트
                                                    <div className="m-a-chat-msg-content-frame">
                                                        <Linkify
                                                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                <a style={{color: "orangered"}} target="blank"
                                                                   href={decoratedHref} key={key}>
                                                                    {decoratedText}
                                                                </a>
                                                            )}
                                                        >
                                                            <div
                                                                className="m-a-chat-msg-content font-t-cont font-s-14">{DOMPurify.sanitize(message.chatKey)}
                                                            </div>
                                                        </Linkify>
                                                    </div>
                                                }
                                                <div className="m-a-chat-msg-time-frame">
                                                    {!message.removeFl ?
                                                        <div
                                                            className="m-a-chat-msg-time font-t-cont font-s-11">{formatTime(message.regTime)}
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                // 주문서 사용자채팅
                                <div className="m-a-chat-adminProfile-frame1">
                                    <div className="m-a-chat-adminProfile-frame2"
                                         style={{
                                             display: "flex",
                                             justifyContent: message.memberDTO && message.memberDTO.role === '1' ? 'flex-start' : 'flex-end'
                                         }}>
                                        {!message.removeFl ?
                                            <div className="m-a-chat-edit-remove-time-frame">
                                                <div className="m-a-chat-edit-remove-frame">
                                                    {message && message.unreadCount > 0 ?
                                                        <div
                                                            className="m-a-chat-admin-unreadCount-frame user-center font-s-13">
                                                            <div className="m-a-chat-admin-unreadCount">1
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                {!message.removeFl ?
                                                    <div
                                                        className="m-a-chat-msg-time font-t-cont font-s-11 m-l-10">{formatTime(message.regTime)}
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                        {/*사용자 챗메시지 프레임*/}
                                        <div>
                                            {message.chatKey.startsWith("chatFile") ?
                                                <div
                                                    onClick={!message.removeFl ? () => clickFileDetail(message) : null}
                                                    className="m-a-chatKey-file-container m-l-5">
                                                    {/*사용자이미지*/}
                                                    {isImage(message.chatKey) ?
                                                        <div className="m-a-chatKey-img-frame">
                                                            {!message.removeFl ?
                                                                <img className="m-a-chatKey-img"
                                                                     alt="chatImg" src={message.fileUrl}/>
                                                                :
                                                                <div
                                                                    className="m-a-chatKey-file-expiration-img-container"
                                                                    style={{textAlign: "center"}}>
                                                                    <img
                                                                        className="m-a-chatKey-file-expiration-img"
                                                                        alt="expiration" src={trash}/>
                                                                    <div
                                                                        className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        :
                                                        // 사용자동영상
                                                        isVideo(message.chatKey) ?
                                                            <div className="m-a-chatKey-video-frame"
                                                                 style={{pointerEvents: "none"}}>
                                                                {!message.removeFl ?
                                                                    <video width="220" height="220" controls>
                                                                        <source src={message.fileUrl}
                                                                                type="video/mp4"
                                                                                style={{pointerEvents: "auto"}}/>
                                                                    </video>
                                                                    :
                                                                    <div
                                                                        className="m-a-chatKey-file-expiration-img-container">
                                                                        <img
                                                                            className="m-a-chatKey-file-expiration-img"
                                                                            alt="expiration" src={trash}/>
                                                                        <div
                                                                            className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            :
                                                            // 사용자파일
                                                            <div className="m-a-chatKey-file-frame1">
                                                                {!message.removeFl ?
                                                                    <div
                                                                        className="m-a-chatKey-file-frame2">
                                                                        <div
                                                                            className="m-a-chatKey-file-img-frame user-center">
                                                                            <img
                                                                                className="m-a-chatKey-file-img"
                                                                                alt="fileImg"
                                                                                src={chatFileImg}/>
                                                                        </div>
                                                                        <div
                                                                            className="m-a-chatKey-fileNm-text-frame">
                                                                                        <span
                                                                                            className="m-a-chatKey-fileNm-text font-t-title">{message.fileNm}</span>
                                                                        </div>
                                                                        <div
                                                                            className="m-a-chatKey-expiration-date font-t-cont"> ~ {oneMonthFromNow(message.deadline)}까지
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div
                                                                        className="m-a-chatKey-file-expiration-img-container">
                                                                        <img
                                                                            className="m-a-chatKey-file-expiration-img"
                                                                            alt="expiration" src={trash}/>
                                                                        <div
                                                                            className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                                :
                                                // 사용자텍스트
                                                <div className="m-a-chat-msg-admin-content-frame">
                                                    <Linkify
                                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                                            <a style={{color: "orangered"}} target="blank"
                                                               href={decoratedHref} key={key}>
                                                                {decoratedText}
                                                            </a>
                                                        )}
                                                    >
                                                        <div
                                                            className="m-a-chat-msg-admin-content font-t-cont font-s-14">{DOMPurify.sanitize(message.chatKey)}</div>
                                                    </Linkify>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                </div>
                <div className="m-a-order-dt-input-frame">
                    <div className="user-input-container">
                            <textarea ref={chatInputRef} placeholder="메시지를 입력해주세요" spellCheck="false" value={message}
                                      className="user-input" onChange={handleChatInput}
                                      onKeyDown={(e) => {
                                          if (e.key === 'Enter' && !e.shiftKey) {
                                              e.preventDefault();
                                              sendMessage(message);
                                              setMessage('');
                                              chatInputRef.current.style.height = 'auto';
                                          }
                                      }}
                                      style={{
                                          width: "100%",
                                          resize: "none",
                                          height: "35px",
                                          maxHeight: "200px",
                                          overflow: "auto",
                                          outline: "none",
                                          padding: "10px 10px 0 10px",
                                          fontSize: "14px",
                                      }}
                            />
                    </div>
                    <div style={{
                        width: "100%",
                        height: "30%",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                    }}>
                        <div style={{width: "40%", display: "flex"}}>
                            <button style={{
                                border: "none",
                                width: "35px",
                                height: "30px",
                                display: "flex",
                                overflow: "hidden",
                                justifyContent: "center",
                                alignItems: "center"
                            }} onClick={() => setShowEmoji(!showEmoji)}>
                                <img alt="" src={mainChatEmoji} style={{width: "25px", height: "25px"}}/>
                            </button>
                            {showEmoji ?
                                <div style={{position: "relative"}}>
                                    <div style={{
                                        position: "absolute",
                                        bottom: "32px",
                                        left: "-36px"
                                    }}>
                                        <Picker
                                            onEmojiSelect={handleEmojiClick}
                                            previewPosition="none"
                                            searchPosition="none"
                                            skinTonePosition="none"
                                            locale="kr"
                                        />
                                    </div>
                                </div>
                                :
                                null
                            }
                            {/*chatShow 채팅데이터 인풋*/}
                            <div>
                                <input type="file" ref={fileInputRef} style={{display: "none"}}
                                       onChange={handleFileUpload}/>
                                <button style={{
                                    border: "none",
                                    width: "35px",
                                    height: "30px",
                                    display: "flex",
                                    overflow: "hidden",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }} onClick={triggerFileInput}>
                                    <img alt="" src={mainChatUpload} style={{width: "25px", height: "25px"}}/>
                                </button>
                            </div>
                        </div>
                        <div style={{textAlign: "right", width: "60%"}}>
                            {typeof message === 'string' && message.trim() !== '' ?
                                <img alt="" src={mainChatSend}
                                     style={{width: "25px", height: "25px"}}
                                     onClick={() => {
                                         sendMessage(message);
                                         setMessage('');
                                     }}/>
                                :
                                <img alt="" src={mainChatSend} style={{width: "25px", height: "25px", opacity: "40%"}}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="m-a-order-detail-pg-bottom-navigation-bar">
                <div className={isOrderPay ? 'm-a-order-detail-pg-bottom-navigation-bar-button-save' : "m-a-order-detail-pg-bottom-navigation-bar-all-button-save"}
                    style={{pointerEvents: isSaveBtDisabled ? 'none' : 'auto'}}
                    onClick={() => handleSubmit("save")}>
                    {isSaveBtLoading ? (
                        <div className="spinner" style={{margin: "0 auto"}}></div>
                    ) : (
                        <div>Save</div>
                    )}
                </div>
                {isOrderPay &&
                    <button className="admin-btn-C wd-100pc ht-45 font-s-16"
                        style={{pointerEvents: isPayBtDisabled ? 'none' : 'auto'}}
                        onClick={() => handleSubmit("pay")}>
                        {isPayBtLoading ? (
                            <div className="spinner" style={{margin: "0 auto"}}></div>
                        ) : (
                            <div>{t(`modPiBt1`)} ({finalPayExpense}{t('won')})</div>
                        )}</button>
                }
            </div>
            {
                recmodiSt ?
                    <RecmodiModal recmodiFalseFn={recmodiFalseFn} recmodiCloseFn={recmodiCloseFn}
                                  recinfoSeqnum={recinfoList.seqnum} setAddressUpdated={setAddressUpdated}
                                  setUpdatedRecipientInfo={setUpdatedRecipientInfo}
                                  selectedShip={orderdtList.shipco}/>
                    :
                    confirmModalShowSt ?
                        <MsgConfirmModal confirmModalShowSt={confirmModalShowSt}
                                         confirmModalFalseFn={confirmModalFalseFn} recmodiModalMsg={recmodiModalMsg}/>
                        :
                        deleteOrderModalShowSt ?
                            <MMsgSelectModal deleteOrderModalShowSt={deleteOrderModalShowSt}
                                             deleteOrderFalseFn={deleteOrderFalseFn} deleteOrder={deleteOrder}
                                             deleteOrderMsg1={deleteOrderMsg1} deleteOrderMsg2={deleteOrderMsg2}/>
                            :
                            showChatFile ?
                                <MChatFileUpload showChatFileClose={showChatFileClose} selectedFile={selectedFile}
                                                 selectedFileUrl={selectedFileUrl} saveChatFile={saveChatFile}/>
                                :
                                detailState.prod ?
                                    <MProdPriceDetail paySeqnum={detailState.paySeqnum} closeDetail={closeDetail}/>
                                    :
                                    detailState.delivery ?
                                        <MDeliveryPriceDetail paySeqnum={detailState.paySeqnum}
                                                              closeDetail={closeDetail}
                                                              updatePayinfoList={updatePayinfoList}/>
                                        :
                                        detailState.etc ?
                                            <MEtcPriceDetail paySeqnum={detailState.paySeqnum}
                                                             closeDetail={closeDetail}/>
                                            :
                                            detailState.adminProd ?
                                                <ProdPriceAdminAddDetail paySeqnum={detailState.paySeqnum}
                                                                         closeDetail={closeDetail}/>
                                                :
                                                useCouponSt ?
                                                    <MCouponUse useCouponSt={useCouponSt}
                                                                closeUseCoupon={closeUseCoupon}
                                                                couponUseFn={couponUseFn}/>
                                                    :
                                                    paypalPopupSt ?
                                                        <MPaypalModal paypalPopupSt={paypalPopupSt}
                                                                      paypalPopupClose={paypalPopupClose}
                                                                      currency={currency}
                                                                      finalPayExpense={finalPayExpense}
                                                                      lastForPrice={lastForPrice}
                                                                      paypalOrderProcess={paypalOrderProcess}/>
                                                        :
                                                        isLoading ?
                                                            <CommonLoading/>
                                                            :
                                                            selectedClickSt ?
                                                                <ChatFileDown selectedClickSt={selectedClickSt}
                                                                              clickFileClose={clickFileClose}
                                                                              selectedClickFile={selectedClickFile}
                                                                              downloadFile={downloadFile}/>
                                                                :
                                                                dontTrackingShipcoSt ?
                                                                    <MsgCheckModal
                                                                        dontTrackingShipcoSt={dontTrackingShipcoSt}
                                                                        closeDontTrackingShipco={closeDontTrackingShipco}/>
                                                                    :
                                                                    cartTermsPopup ?
                                                                        <MCommonTermsPopup
                                                                            cartTermsPopup={cartTermsPopup}
                                                                            closeCartTermsPopup={closeCartTermsPopup}/>
                                                                        :
                                                                        orderTermsPopup ?
                                                                            <MCommonTermsPopup
                                                                                orderTermsPopup={orderTermsPopup}
                                                                                closeOrderTermsPopup={closeOrderTermsPopup}/>
                                                                            :
                                                                            offlineTermsPopup ?
                                                                                <MCommonTermsPopup
                                                                                    offlineTermsPopup={offlineTermsPopup}
                                                                                    closeOfflineTermsPopup={closeOfflineTermsPopup}/>
                                                                                :
                                                                                transferTermsPopup ?
                                                                                    <MCommonTermsPopup
                                                                                        transferTermsPopup={transferTermsPopup}
                                                                                        closeTransferTermsPopup={closeTransferTermsPopup}/>
                                                                                    :
                                                                                    domesticTermsPopup ?
                                                                                        <MCommonTermsPopup
                                                                                            domesticTermsPopup={domesticTermsPopup}
                                                                                            closeDomesticTermsPopup={closeDomesticTermsPopup}/>
                                                                                        :
                                                                                        deliveryTermsPopup ?
                                                                                            <MCommonTermsPopup
                                                                                                deliveryTermsPopup={deliveryTermsPopup}
                                                                                                closeDeliveryTermsPopup={closeDeliveryTermsPopup}/>
                                                                                            :
                                                                                            orderDivideMergeSt ?
                                                                                                <MsgCheckModal
                                                                                                    orderDivideMergeSt={orderDivideMergeSt}
                                                                                                    moveToMyPg={moveToMyPg}/>
                                                                                                :
                                                                                                biggerCpPriceSt ?
                                                                                                    <MsgCheckModal
                                                                                                        biggerCpPriceSt={biggerCpPriceSt}
                                                                                                        closeBiggerCpPriceSt={closeBiggerCpPriceSt}/>
                                                                                                    :
                                                                                                    commonError ?
                                                                                                        <MsgCheckModal
                                                                                                            commonError={commonError}
                                                                                                            closeCommonError={closeCommonError}/>
                                                                                                        :
                                                                                                        agreeCheckBoxError ?
                                                                                                            <MMsgCheckModal
                                                                                                                agreeCheckBoxError={agreeCheckBoxError}
                                                                                                                setAgreeCheckBoxError={setAgreeCheckBoxError}/>
                                                                                                            :
                                                                                                            payMethodError ?
                                                                                                                <MMsgCheckModal
                                                                                                                    payMethodError={payMethodError}
                                                                                                                    setPayMethodError={setPayMethodError}/>
                                                                                                                :
                                                                                                                pointPayError ?
                                                                                                                    <MMsgCheckModal
                                                                                                                        pointPayError={pointPayError}
                                                                                                                        setPointPayError={setPointPayError}/>
                                                                                                                    :
                                                                                                                    etcPayError ?
                                                                                                                        <MMsgCheckModal
                                                                                                                            etcPayError={etcPayError}
                                                                                                                            setEtcPayError={setEtcPayError}/>
                                                                                                                        :
                                                                                                                        pointSelectErr ?
                                                                                                                            <MMsgCheckModal
                                                                                                                                pointSelectErr={pointSelectErr}
                                                                                                                                setPointSelectErr={setPointSelectErr}/>
                                                                                                                            :
                                                                                                                            depositSelectErr ?
                                                                                                                                <MMsgCheckModal
                                                                                                                                    depositSelectErr={depositSelectErr}
                                                                                                                                    setDepositSelectErr={setDepositSelectErr}/>
                                                                                                                                :
                                                                                                                                fileSizeOverSt ?
                                                                                                                                    <MMsgCheckModal
                                                                                                                                        fileSizeOverSt={fileSizeOverSt}
                                                                                                                                        closeFileSizeOverSt={closeFileSizeOverSt}/>
                                                                                                                                    :
                                                                                                                                    downloadFileComplSt ?
                                                                                                                                        <MMsgCheckModal downloadFileComplSt={downloadFileComplSt} closeDownloadFileCompl={closeDownloadFileCompl}/>
                                                                                                                                        :
                                                                                                                                        readyingPopupSt ?
                                                                                                                                            <ReadyingPopup readyingPopupSt={readyingPopupSt} closeReadyingPopup={closeReadyingPopup}/>
                                                                                                                                            :
                                                                                                                                            formUploadErrSt ?
                                                                                                                                                <MsgCheckModal
                                                                                                                                                    formUploadErrSt={formUploadErrSt}
                                                                                                                                                    closeFormUploadErrSt={closeFormUploadErrSt}/>
                                                                                                                                                :
                                                                                                                                    null
            }
        </div>
    )

}

export default withAuth(MUserOrderDetailPg);
