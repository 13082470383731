import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

// 토큰검사를 하지 않는 페이지들 리스트
const PUBLIC_ROUTES = [
    "/",
    "/UserLoginPg",
    "/UserJoinPg",
    "/UserReviewPg",
    /^\/UserReviewDetailPg\/\d+$/,
    /^\/UserNoticeDtPg\/\d+$/,
    /^\/UserFaqDtPg\/\d+$/,
    "/UserJoinPg",
    "/UserIdPwFindPg",
    "/NonUserChatPg",
    "/UserMailInquiryPg",
    "/UserMailSendComplPg",
    "/UserUsageGuidePg",

    "/btcorpad-login",
    "/btcorpad-join",
    "/btcorpad-pwFind",
];

function AppWrapper({ children }) {
    const navigate = useNavigate();
    const location = useLocation();

    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const adminLCAT = localStorage.getItem('adminToken');
    const token = SSAT || LCAT || adminLCAT

    // const isPublicRoute = PUBLIC_ROUTES.includes(location.pathname);
    const isPublicRoute = PUBLIC_ROUTES.some(route => {
        if (typeof route === 'string') {
            return location.pathname === route;
        } else if (route instanceof RegExp) {
            return route.test(location.pathname);
        }
    });
    const [previousLocation, setPreviousLocation] = useState();

    useEffect(() => {
        if (location !== previousLocation) {
            setPreviousLocation(location);
            if (!token) {
                if (!isPublicRoute && location.pathname.startsWith('/btcorpad')) {
                    navigate("/btcorpad-login", { state: location.pathname });
                } else if (!isPublicRoute) {
                    navigate("/UserLoginPg", { state: location.pathname });
                }
            }
        }
    }, [location, token]);

    return children;
}

export default AppWrapper
