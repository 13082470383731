import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {BiCaretDown} from "react-icons/bi";
import {BsTrash} from "react-icons/bs";
import photoUpload from "../../assets/img/reviewUpload.png";
import axios from "axios";
import maCateImg from "../../assets/img/m-a-cate.png";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";

function AdminNoticeWritePg() {

    const navigate = useNavigate();
    const LCAT = localStorage.getItem('adminToken');

    const [langList, setLangList] = useState(["Korean", "English", "Japanese", "Chinese", "Indonesian"]);
    const [selectedLang, setSelectedLang] = useState('');
    const [langShow, setLangShow] = useState(false);
    const langRef = useRef(null);

    const toggleLangShow = () => {
        setLangShow(!langShow);
    };

    const handleLangChange = (mst) => {
        setSelectedLang(mst);
        setLangShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (langRef.current && !langRef.current.contains(event.target)) {
                setLangShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [titleErrorMsg, setTitleErrorMsg] = useState(false);
    const [contentErrorMsg, setContentErrorMsg] = useState(false);
    const [langErrorMsg, setLangErrorMsg] = useState(false);

    const [titleText, setTitleText] = useState('');
    const handleChangeTitleText = (e) => {
        setTitleText(e.target.value);
    };

    const [contentText, setContentText] = useState('');
    const handleChangeContentText = (e) => {
        setContentText(e.target.value);
    };


    const [photoContainers, setPhotoContainers] = useState([{ photoImg: photoUpload, photoImgSt: false, photoImgError: false }]);
    const photoImgHandle = (idx) => (e) => {
        const file = e.target.files[0];

        if (file) {
            setPhotoContainers((prev) => {
                const newContainers = [...prev];

                if (!file.type.startsWith('image/')) {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        photoImgError: true,
                    };
                    return newContainers;
                } else {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        photoImg: URL.createObjectURL(file),
                        photoImgSt: true,
                        photoImgError: false,
                        file: file
                    };
                    if (idx === newContainers.length - 1) {
                        addForm(newContainers);
                    }
                    return newContainers;
                }
            });
        }
    };

    const photoImgInit = (idx) => () => {
        setPhotoContainers((prev) => {
            const newContainers = [...prev];

            newContainers.splice(idx, 1);

            const lastContainer = newContainers[newContainers.length - 1];
            if (lastContainer && lastContainer.photoImgSt) {
                newContainers.push({ photoImg: photoUpload, photoImgSt: false, photoImgError: false });
            }

            return newContainers;
        });
    };


    const addForm = (newContainers) => {
        const lastContainer = newContainers[newContainers.length - 1];
        if (!lastContainer.photoImgSt) {
            return;
        }

        newContainers.push({ photoImg: photoUpload, photoImgSt: false, photoImgError: false });
    };

    const validation = () => {
        if (titleText === '' || !titleText) setTitleErrorMsg(true);
        if (contentText === '' || !contentText) setContentErrorMsg(true);
        if (selectedLang === '' || !selectedLang) setLangErrorMsg(true);
        return !!(titleText && contentText && selectedLang)
    }

    const [saveFailSt, setSaveFailSt] = useState(false);
    const closeSaveFailSt = (() => {
        setSaveFailSt(false);
    })
    const photoSave = () => {

        const lang = selectedLang === "Korean" ? 0 : selectedLang === "English" ? 1
            : selectedLang === "Japanese" ? 2 : selectedLang === "Chinese" ? 3 : 4

        const newFormData = new FormData();

        newFormData.append('title', titleText);
        newFormData.append('contentText', contentText);

        photoContainers.forEach((container, idx) => {
            if (container.file) {
                newFormData.append('photoImg', container.file);
            }
        });

        return axios({
            method: 'post',
            url: '/notice/photoSave',
            data: newFormData,
            params: {
                fixFl: fixCheck,
                lang: lang
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
        })
            .then((response) => {
                // navigate(`/btcorpad/adminnotice-detail/${response.data}`);
                navigate(`/btcorpad/adminnotice`);
            });
    }

    const valiAndSave = () => {
        if (validation()) {
            photoSave();
        }
    }

    useEffect(() => {
        if (titleText.length > 0) {
            setTitleErrorMsg(false);
        } if (contentText.length > 0) {
            setContentErrorMsg(false);
        } if (!selectedLang || selectedLang !== '') {
            setLangErrorMsg(false);
        }
    }, [titleText, contentText, selectedLang])

    const [fixCheck, setFixCheck] = useState(false);
    const selectedFixCheckBox = (() => {
        setFixCheck(!fixCheck);
    })

    return(
        <div className="AdminNoticePg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">글작성[공지사항]</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card font-t-cont">
                    <div style={{padding:"20px"}}>
                        <div style={{height:"50px", display:"flex"}}>
                            <input id="AgreeCheckBox" type="checkbox" style={{width:"20px", height:"20px", marginTop:"4px", cursor:"pointer"}}
                                   checked={fixCheck} onChange={selectedFixCheckBox}
                            />
                            <label style={{display:"flex", marginLeft:"10px", cursor:"pointer"}} htmlFor="AgreeCheckBox">
                                <span style={{fontSize:"17px"}}>공지고정</span>
                            </label>
                        </div>
                        <div>
                            <div style={{height: "10%", display: "flex"}}>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "justify-between", width: "100%"}}>
                                    <div className="ReviewPgSearchFrame acc-icon" style={{width: "80%"}}>
                                        <div className="ReviewPgInputForm" style={{width: "97%"}}>
                                            <input className="ReviewInput user-input" maxLength="50"
                                                   style={{width: "100%", marginLeft: "10px", height: "100%"}} type="text"
                                                   placeholder="" value={titleText} onChange={handleChangeTitleText}/>
                                            <label className="JoinLabel" style={{fontSize: "14px"}}>제목</label>
                                        </div>
                                    </div>
                                    <div style={{width:"20%", height: "55px", marginLeft:"10px"}}>
                                        <div ref={langRef}
                                             style={{height: "100%", width: "100%", position: "relative"}}>
                                            <button className="selectParentBt"
                                                    style={{width: "100%", height: "100%"}}
                                                    onClick={toggleLangShow}>
                                                <p>{selectedLang === "" ? "배너언어" : selectedLang}</p>
                                                <span style={{position: "absolute", left: "80%", top: "0", height: "100%", zIndex:10, width: "20%"}}>
                                                        <i style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}><BiCaretDown/></i>
                                                    </span>
                                            </button>
                                            {langShow ?
                                                <div style={{
                                                    width:"100%",
                                                    height:"200px",
                                                    overflowY:"scroll",
                                                    border: "solid",
                                                    borderWidth: "thin 1px",
                                                    borderColor: "lightgray",
                                                    position: "absolute",
                                                    top: "100%",
                                                    bottom: "100%",
                                                    zIndex: 1,
                                                    marginTop: "5px",
                                                }}>
                                                    {langList.map((lang, index) => (
                                                        <div className="selectChildBt" key={index} value={lang}
                                                             onClick={() => handleLangChange(lang)} style={{
                                                            display: "flex",
                                                            padding: "10px",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            cursor: "pointer",
                                                            fontSize: "14px",
                                                        }}>{lang}</div>
                                                    ))}
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    <div className="JoinErrorMsg">
                                        {titleErrorMsg && <p className="JoinErrMsg">&nbsp;제목을 입력해주세요</p>}
                                    </div>
                                </div>
                            </div>
                            <div style={{height: "auto", display: "flex", marginTop: "20px"}}>

                                <div style={{width: "65%"}}>
                                    <div className="ordChatInputContainer" style={{height: "100%", minHeight: "600px"}}>
                    <textarea
                        placeholder="내용"
                        spellCheck="false"
                        maxLength="4000"
                        value={contentText}
                        style={{
                            borderRadius: "6px",
                            height: "100%",
                            width: "100%",
                            borderWidth: "thin 1px",
                            borderColor: "#EAEAEA",
                            resize: "none",
                            overflow: "auto",
                            outline: "none",
                            color: "dimgrey",
                            padding: "18px",
                            fontSize: "16px",
                            ':focus': {
                                borderColor: "#FFAF34"
                            },
                        }} onChange={handleChangeContentText}/>
                                    </div>
                                    <div className="JoinErrorMsg">
                                        {contentErrorMsg && <p className="JoinErrMsg">&nbsp;내용을 입력해주세요</p>}
                                    </div>
                                </div>
                                <div style={{width: "35%", paddingLeft: "10px"}}>
                                    {photoContainers.map((container, idx) => (
                                        <div key={idx} style={{
                                            border: "solid",
                                            height: "305px",
                                            borderWidth: "thin 1px",
                                            borderColor: "#EAEAEA",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            {!container.photoImgSt ?
                                                <div style={{display: "block", textAlign:"center", cursor:"pointer"}} onClick={(e) => {
                                                    const parentDiv = e.target.parentNode;
                                                    const fileInput = parentDiv.querySelector('input[type="file"]');
                                                    if (fileInput) fileInput.click();
                                                }}>
                                                    <input type="file" accept="image/*" style={{display: "none"}} onChange={photoImgHandle(idx)}/>
                                                    <img alt="uploadImg" style={{
                                                        width: "80px",
                                                        height: "80px",
                                                        padding: "10px",
                                                        filter: "opacity(0.2) drop-shadow(0 0 0 gray)",
                                                    }} src={container.photoImg}/>
                                                    <p style={{color: "rgba(112,112,109,0.67)", fontSize: "18px"}}>Click me!</p>
                                                    <div className="JoinErrorMsg">
                                                        {container.photoImgError && <p className="JoinErrMsg">&nbsp;사진만 업로드해주세요😅</p>}
                                                    </div>
                                                </div>
                                                :
                                                <div className="user-center" style={{width:"90%", display:"flex", height:"90%", position:"relative"}}>
                                                    <img alt="photoImg" style={{
                                                        display:"flex",
                                                        justifyContent:"center",
                                                        alignItems:"center",
                                                        maxWidth:"100%",
                                                        maxHeight:"100%",
                                                    }} src={container.photoImg}/>
                                                    <button className="orderItemHandleBt" style={{
                                                        position:"absolute",
                                                        width: "40px",
                                                        height: "40px",
                                                        top:"85%",
                                                        left:"90%",
                                                        borderRadius:"4px",
                                                        opacity:"0.6"
                                                    }} onClick={photoImgInit(idx)}>
                                                        <i style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize:"26px"
                                                        }}><BsTrash/></i>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div style={{
                                height: "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "end"
                            }}>
                                <button className="orderItemHandleBt" style={{width: "100px", height: "50px", borderRadius:"4px"}} onClick={valiAndSave}>작성완료</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                        { saveFailSt ?
                <MsgCheckModal saveFailSt={saveFailSt} closeSaveFailSt={closeSaveFailSt}/>
                :
                null
            }
        </div>
    )
}

export default AdminNoticeWritePg
