import '../../css/mobile/MNoticeDtPg.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import MHeader from "../../components/mobile/MHeader";
import notice from "../../assets/img/notice.png";

const MNoticeDtPg = () => {
    const {t} = useTranslation();
    const {seqNum} = useParams();
    const navigate = useNavigate();

    const [noticeDtData, setNoticeDtData] = useState([]);
    const [photoList, setPhotoList] = useState([]);

    const selectDtData = () => axios({
        method: 'get',
        url: '/notice/selectNoticeData/',
        params: {
            seqNum: seqNum
        }
    })
        .then((response) => {
            setNoticeDtData(response.data);
            setPhotoList(response.data.noticePhotoDTO)
        })

    useState(() => {
        if (seqNum) {
            selectDtData();
        }
    }, [])

    const sanitizeHTML = (rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    };

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }


    return (
        <div className="m-a-notice-dt-page-container font-t-cont">
            <MHeader title={t(`NtdtHeader`)} url={"/"}/>
            <div className='m-a-notice-detail-sub-title'>
                <img className='m-a-notice-detail-sub-title-icon' src={notice} alt=''/>
                <div className='m-a-notice-detail-sub-title-text'>{t(`NtdtHeader`)}</div> {/*공지상세*/}
            </div>
            <div className={'m-a-notice-detail-divider'}/>
            <div className={'m-a-notice-detail-info-box'}>
                {/*<div className={'m-a-notice-detail-info-text'}> {noticeDtData?.regNm}</div>*/}
                <div className={'m-a-notice-detail-info-text'}>Btorage</div>
                <div className={'m-a-notice-detail-info-text'}>{formatDate(noticeDtData?.regDate)}</div>
                {/*<div className={'m-a-notice-detail-info-text'}>조회수: 0</div>*/}
            </div>
            <div className={'m-a-notice-detail-frame'}>
                <div className="m-a-faq-detail-title"><div className="m-a-faq-detail-title-c admin-tb-text-cut">{noticeDtData?.title}</div></div>
                <div className={'m-a-notice-detail-content'}>
                    <div className={'m-a-notice-detail-content-text'}
                         dangerouslySetInnerHTML={{__html: sanitizeHTML(noticeDtData?.content)}}/>
                    {photoList && photoList.map((photo, index) => (
                        <img className={'m-a-notice-detail-content-img'}
                             src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/writePhoto/" + photo.fileNm}
                             alt="photoImg"/>
                    ))}
                </div>
            </div>
        </div>
    )

}

export default MNoticeDtPg;
