import "../../css/mobile/MUserGuidePg.css";
import CommonHeader from "../../assets/img/commonHeader.png";
import CommonGuide from "../../assets/img/commonGuide.png";
import MHeader from "./MHeader";
import React, {useEffect, useState} from "react";
import GuideModal from "../web/modal/GuideModal";
import {useTranslation} from "react-i18next";
import detailCart from "../../assets/img/detailCart.png";
import detailOrder from "../../assets/img/detailOrder.png";
import detailOffline from "../../assets/img/detailOffline.png";
import detailTransfer from "../../assets/img/detailTransfer.png";
import detailDomestic from "../../assets/img/detailDomestic.png";
import detailDelivery from "../../assets/img/detailDelivery.png";
import guidePc1 from "../../assets/img/guidePc1.png";
import guidePc2 from "../../assets/img/guidePc2.png";
import guidePc3 from "../../assets/img/guidePc3.png";
import guidePc4 from "../../assets/img/guidePc4.png";
import guidePc5 from "../../assets/img/guidePc5.png";
import guidePc6 from "../../assets/img/guidePc6.png";
import guidePc7 from "../../assets/img/guidePc7.png";
import guidePc8 from "../../assets/img/guidePc8.png";
import axios from "axios";
import i18n from "../../i18n";

const MUsageGuidePg = () => {

    /** region DataSet */

    const { t } = useTranslation();

    const [guideData, setGuideData] = useState([]);

    /** endregion DataTable*/

    const usageGuideOfCart = [
        {title: t('ugCont15'), img: guidePc1},
        {title: t('ugCont16'), img: guidePc2},
        {title: t('ugCont17'), img: guidePc3},
        {title: t('ugCont18'), img: guidePc4},
        {title: t('ugCont19'), img: guidePc5},
        {title: t('ugCont20'), img: guidePc6},
    ];

    const usageGuideOfOrder = [
        {title: t('ugCont22'), img: guidePc2},
        {title: t('ugCont23'), img: guidePc4},
        {title: t('ugCont17'), img: guidePc3},
        {title: t('ugCont18'), img: guidePc4},
        {title: t('ugCont19'), img: guidePc5},
        {title: t('ugCont20'), img: guidePc6},
    ];

    const usageGuideOfDelivery = [
        {title: t('ugCont34'), img: guidePc8},
        {title: t('ugCont35'), img: guidePc2},
        {title: t('ugCont17'), img: guidePc3},
        {title: t('ugCont18'), img: guidePc4},
        {title: t('ugCont19'), img: guidePc5},
        {title: t('ugCont20'), img: guidePc6},
    ];

    const usageGuideOfOffline = [
        {title: t('ugCont25'), img: guidePc2},
        {title: t('ugCont23'), img: guidePc4},
        {title: t('ugCont17'), img: guidePc3},
        {title: t('ugCont18'), img: guidePc4},
        {title: t('ugCont19'), img: guidePc5},
        {title: t('ugCont20'), img: guidePc6},
    ];

    const usageGuideOfTransfer = [
        {title: t('ugCont27'), img: guidePc2},
        {title: t('ugCont28'), img: guidePc4},
        {title: t('ugCont29'), img: guidePc5},
    ];

    const usageGuideOfDomestic = [
        {title: t('ugCont31'), img: guidePc2},
        {title: t('ugCont23'), img: guidePc4},
        {title: t('ugCont32'), img: guidePc7},
    ];

    const [cartGuide, setCartGuide] = useState(true);
    const [orderGuide, setOrderGuide] = useState(false);
    const [offlineGuide, setOfflineGuide] = useState(false);
    const [transferGuide, setTransferGuide] = useState(false);
    const [domesticGuide, setDomesticGuide] = useState(false);
    const [deliveryGuide, setDeliveryGuide] = useState(false);

    const [cartGuidePopup, setCartGuidePopup] = useState(false);
    const [orderGuidePopup, setOrderGuidePopup] = useState(false);
    const [offlineGuidePopup, setOfflineGuidePopup] = useState(false);
    const [transferGuidePopup, setTransferGuidePopup] = useState(false);
    const [domesticGuidePopup, setDomesticGuidePopup] = useState(false);
    const [deliveryGuidePopup, setDeliveryGuidePopup] = useState(false);

    const cartGuideHeader = t('ugHd1');
    const cartGuideContent = t('ugCont1');
    const orderGuideHeader = t('ugHd2');
    const orderGuideContent = t('ugCont2');
    const offlineGuideHeader = t('ugHd3');
    const offlineGuideContent = t('ugCont3');
    const transferGuideHeader = t('ugHd4');
    const transferGuideContent = t('ugCont4');
    const domesticGuideHeader = t('ugHd5');
    const domesticGuideContent = t('ugCont5');
    const deliveryGuideHeader = t('ugHd6');
    const deliveryGuideContent = t('ugCont6');

    const changeSelectedGuide = ((guide) => {
        if (guide === 'cart') {
            setCartGuide(true);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'order') {
            setCartGuide(false);
            setOrderGuide(true);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'offline') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(true);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'transfer') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(true);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'domestic') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(true);
            setDeliveryGuide(false);
        } else if (guide === 'delivery') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(true);
        }
    })

    const openCartGuidePopup = (() => {
        setCartGuidePopup(true);
    })
    const openOrderGuidePopup = (() => {
        setOrderGuidePopup(true);
    })
    const openOfflineGuidePopup = (() => {
        setOfflineGuidePopup(true);
    })
    const openTransferGuidePopup = (() => {
        setTransferGuidePopup(true);
    })
    const openDomesticGuidePopup = (() => {
        setDomesticGuidePopup(true);
    })
    const openDeliveryGuidePopup = (() => {
        setDeliveryGuidePopup(true);
    })
    const closeCartGuidePopup = (() => {
        setCartGuidePopup(false);
    })
    const closeOrderGuidePopup = (() => {
        setOrderGuidePopup(false);
    })
    const closeOfflineGuidePopup = (() => {
        setOfflineGuidePopup(false);
    })
    const closeTransferGuidePopup = (() => {
        setTransferGuidePopup(false);
    })
    const closeDomesticGuidePopup = (() => {
        setDomesticGuidePopup(false);
    })
    const closeDeliveryGuidePopup = (() => {
        setDeliveryGuidePopup(false);
    })

    const groupByType = (guideData) => {
        return guideData.reduce((acc, guide) => {
            const type = guide.gtype;
            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(guide);
            return acc;
        }, {});
    };

    /** region Event */

    useEffect(() => {
        const selectGuideData = (lang) => {
            axios.get(`/guide/selectGuideList?lang=${lang}`)
                .then((response) => {
                    const filteredData = response.data.filter((guide) => guide.gmachineType === "1");
                    const groupedData = groupByType(filteredData);
                    setGuideData(groupedData);
                });
        };

        const currentLanguage = i18n.language || 'ko';
        let lang = '0';

        switch (currentLanguage) {
            case 'en':
                lang = '1';
                break;
            case 'ja':
                lang = '2';
                break;
            case 'zh':
                lang = '3';
                break;
            case 'id':
                lang = '4';
                break;
            default:
                lang = '0';
        }

        selectGuideData(lang.charAt(0));
    }, [i18n.language]);

    /** endregion */

    return (
        <div className="m-a-usage-guide-pg-container font-t-cont">
            <MHeader title={t(`hdSMenu2`)} url={"/"}/>
            <div className="wd-100pc display-flex flex-row align-center gap8">
                <img className="wd-20 ht-20 m-b-2" src={CommonHeader} alt=''/>
                <p className="font-t-title font-s-15"> {t(`fmSmallMenu5`)}</p>
            </div>
            <div className="m-a-usage-guide-divider"/>
            <div className="m-a-usage-guide-grid-frame">
                <div className={`m-a-usage-guide-item-box ${cartGuide && 'selected'}`}
                     onClick={() => changeSelectedGuide('cart')}>
                    <div className="m-a-usage-guide-item-img-box">
                        <img className="m-a-usage-guide-item-img" src={detailCart} alt={'cart'}/>
                    </div>
                    <div className={'m-a-usage-guide-item-title'}> {t('ugCont7')}</div>
                </div>
                <div className={`m-a-usage-guide-item-box ${orderGuide && 'selected'}`}
                     onClick={() => changeSelectedGuide('order')}>
                    <div className={'m-a-usage-guide-item-img-box'}>
                        <img className={'m-a-usage-guide-item-img'} src={detailOrder} alt={'order'}/>
                    </div>
                    <div className={'m-a-usage-guide-item-title'}> {t('ugCont8')}</div>
                </div>
                <div className={`m-a-usage-guide-item-box ${offlineGuide && 'selected'}`}
                     onClick={() => changeSelectedGuide('offline')}>
                    <div className={'m-a-usage-guide-item-img-box'}>
                        <img className={'m-a-usage-guide-item-img'} src={detailOffline} alt={'offline'}/>
                    </div>
                    <div className={'m-a-usage-guide-item-title'}> {t('ugCont9')}</div>
                </div>
                <div className={`m-a-usage-guide-item-box ${transferGuide && 'selected'}`}
                     onClick={() => changeSelectedGuide('transfer')}>
                    <div className={'m-a-usage-guide-item-img-box'}>
                        <img className={'m-a-usage-guide-item-img'} src={detailTransfer} alt={'transfer'}/>
                    </div>
                    <div className={'m-a-usage-guide-item-title'}> {t('ugCont10')}</div>
                </div>
                <div className={`m-a-usage-guide-item-box ${domesticGuide && 'selected'}`}
                     onClick={() => changeSelectedGuide('domestic')}>
                    <div className={'m-a-usage-guide-item-img-box'}>
                        <img className={'m-a-usage-guide-item-img'} src={detailDomestic} alt={'domestic'}/>
                    </div>
                    <div className={'m-a-usage-guide-item-title'}> {t('ugCont11')}</div>
                </div>
                <div className={`m-a-usage-guide-item-box ${deliveryGuide && 'selected'}`}
                     onClick={() => changeSelectedGuide('delivery')}>
                    <div className={'m-a-usage-guide-item-img-box'}>
                        <img className={'m-a-usage-guide-item-img'} src={detailDelivery} alt={'delivery'}/>
                    </div>
                    <div className={'m-a-usage-guide-item-title'}> {t('ugCont12')}</div>
                </div>
            </div>
            {cartGuide ? (
                <div className={'m-a-usage-guide-guideline-frame'}>
                    <div className={'m-a-usage-guide-guideline-title-box'}>
                        <div className={'m-a-usage-guide-guideline-title'}> {t('ugCont13')}</div>
                        <img className={'m-a-usage-guide-guideline-title-img'} src={CommonGuide} alt={'PopUp'} onClick={openCartGuidePopup}/>
                    </div>
                    <div className={'m-a-usage-guide-description'}>({t('ugCont14')})</div>
                    <div className={'m-a-usage-guide-divider'}/>
                    <div className="m-a-usage-guide-guideline-grid">
                        {usageGuideOfCart.map((item, index) => (
                            <div key={index} className="m-a-usage-guide-guideline-item ht-100pc">
                                <div className="m-a-usage-guide-guideline-item-content ht-50pc">
                                    <div className="m-a-usage-guide-guideline-item-number">{index + 1}</div>
                                    <img className="m-a-usage-guide-guideline-item-img" src={item.img} alt={item.title}/>
                                </div>
                                <div className="m-a-usage-guide-guideline-item-title p-t-3 ht-50pc">{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : orderGuide ? (
                <div className={'m-a-usage-guide-guideline-frame'}>
                    <div className={'m-a-usage-guide-guideline-title-box'}>
                        <div className={'m-a-usage-guide-guideline-title'}> {t('ugCont21')}</div>
                        <img className={'m-a-usage-guide-guideline-title-img'} src={CommonGuide} alt={'PopUp'} onClick={openOrderGuidePopup}/>
                    </div>
                    <div className={'m-a-usage-guide-description'}>
                        ({t('ugCont14')})
                    </div>
                    <div className={'m-a-usage-guide-divider'}/>
                    <div className={'m-a-usage-guide-guideline-grid'}>
                        {usageGuideOfOrder.map((item, index) => (
                            <div key={index} className={'m-a-usage-guide-guideline-item'}>
                                <div className={'m-a-usage-guide-guideline-item-content'}>
                                    <div className={'m-a-usage-guide-guideline-item-number'}>{index + 1}</div>
                                    <img className={'m-a-usage-guide-guideline-item-img'} src={item.img} alt={item.title}/>
                                </div>
                                <div className={'m-a-usage-guide-guideline-item-title'}>{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : offlineGuide ? (
                <div className={'m-a-usage-guide-guideline-frame'}>
                    <div className={'m-a-usage-guide-guideline-title-box'}>
                        <div className={'m-a-usage-guide-guideline-title'}> {t('ugCont24')}</div>
                        <img className={'m-a-usage-guide-guideline-title-img'} src={CommonGuide} alt={'PopUp'} onClick={openOfflineGuidePopup}/>
                    </div>
                    <div className={'m-a-usage-guide-description'}>
                        ({t('ugCont14')})
                    </div>
                    <div className={'m-a-usage-guide-divider'}/>
                    <div className={'m-a-usage-guide-guideline-grid'}>
                        {usageGuideOfOffline.map((item, index) => (
                            <div key={index} className={'m-a-usage-guide-guideline-item'}>
                                <div className={'m-a-usage-guide-guideline-item-content'}>
                                    <div className={'m-a-usage-guide-guideline-item-number'}>{index + 1}</div>
                                    <img className={'m-a-usage-guide-guideline-item-img'} src={item.img} alt={item.title}/>
                                </div>
                                <div className={'m-a-usage-guide-guideline-item-title'}>{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : transferGuide ? (
                <div className={'m-a-usage-guide-guideline-frame'}>
                    <div className={'m-a-usage-guide-guideline-title-box'}>
                        <div className={'m-a-usage-guide-guideline-title'}> {t('ugCont26')}</div>
                        <img className={'m-a-usage-guide-guideline-title-img'} src={CommonGuide} alt={'PopUp'} onClick={openTransferGuidePopup}/>
                    </div>
                    <div className={'m-a-usage-guide-description'}>
                        ({t('ugCont14')})
                    </div>
                    <div className={'m-a-usage-guide-divider'}/>
                    <div className={'m-a-usage-guide-guideline-grid'}>
                        {usageGuideOfTransfer.map((item, index) => (
                            <div key={index} className={'m-a-usage-guide-guideline-item'}>
                                <div className={'m-a-usage-guide-guideline-item-content'}>
                                    <div className={'m-a-usage-guide-guideline-item-number'}>{index + 1}</div>
                                    <img className={'m-a-usage-guide-guideline-item-img'} src={item.img} alt={item.title}/>
                                </div>
                                <div className={'m-a-usage-guide-guideline-item-title'}>{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : domesticGuide ? (
                <div className={'m-a-usage-guide-guideline-frame'}>
                    <div className={'m-a-usage-guide-guideline-title-box'}>
                        <div className={'m-a-usage-guide-guideline-title'}> {t('ugCont30')}</div>
                        <img className={'m-a-usage-guide-guideline-title-img'} src={CommonGuide} alt={'PopUp'} onClick={openDomesticGuidePopup}/>
                    </div>
                    <div className={'m-a-usage-guide-description'}>
                        ({t('ugCont14')})
                    </div>
                    <div className={'m-a-usage-guide-divider'}/>
                    <div className={'m-a-usage-guide-guideline-grid'}>
                        {usageGuideOfDomestic.map((item, index) => (
                            <div key={index} className={'m-a-usage-guide-guideline-item'}>
                                <div className={'m-a-usage-guide-guideline-item-content'}>
                                    <div className={'m-a-usage-guide-guideline-item-number'}>{index + 1}</div>
                                    <img className={'m-a-usage-guide-guideline-item-img'} src={item.img} alt={item.title}/>
                                </div>
                                <div className={'m-a-usage-guide-guideline-item-title'}>{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : deliveryGuide ? (
                <div className={'m-a-usage-guide-guideline-frame'}>
                    <div className={'m-a-usage-guide-guideline-title-box'}>
                        <div className={'m-a-usage-guide-guideline-title'}> {t('ugCont33')}</div>
                        <img className={'m-a-usage-guide-guideline-title-img'} src={CommonGuide} alt={'PopUp'} onClick={openDeliveryGuidePopup}/>
                    </div>
                    <div className={'m-a-usage-guide-description'}>
                        ({t('ugCont14')})
                    </div>
                    <div className={'m-a-usage-guide-divider'}/>
                    <div className={'m-a-usage-guide-guideline-grid'}>
                        {usageGuideOfDelivery.map((item, index) => (
                            <div key={index} className={'m-a-usage-guide-guideline-item'}>
                                <div className={'m-a-usage-guide-guideline-item-content'}>
                                    <div className={'m-a-usage-guide-guideline-item-number'}>{index + 1}</div>
                                    <img className={'m-a-usage-guide-guideline-item-img'} src={item.img} alt={item.title}/>
                                </div>
                                <div className={'m-a-usage-guide-guideline-item-title'}>{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
            {cartGuide ? (
                <div className="m-a-usage-guide-detail-frame m-t-20 m-b-20">
                    <div className="p-t-10 p-b-10 ht-100pc wd-100pc">
                        <div className="m-a-userGuideContFrame p-t-25 p-b-20">
                            {guideData['P00'] && guideData['P00'][0] && (
                                <div key={guideData['P00'][0].gtitle}>
                                    <div dangerouslySetInnerHTML={{ __html: guideData['P00'][0].gcont }} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : orderGuide ? (
                <div className="m-a-usage-guide-detail-frame m-t-20 m-b-20">
                    <div className="p-t-10 p-b-10 ht-100pc wd-100pc">
                        <div className="m-a-userGuideContFrame p-t-25 p-b-20">
                            {guideData['P01'] && guideData['P01'][0] && (
                                <div key={guideData['P01'][0].gtitle}>
                                    <div dangerouslySetInnerHTML={{ __html: guideData['P01'][0].gcont }} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : offlineGuide ? (
                <div className="m-a-usage-guide-detail-frame m-t-20 m-b-20">
                    <div className="p-t-10 p-b-10 ht-100pc wd-100pc">
                        <div className="m-a-userGuideContFrame p-t-25 p-b-20">
                            {guideData['P02'] && guideData['P02'][0] && (
                                <div key={guideData['P02'][0].gtitle}>
                                    <div dangerouslySetInnerHTML={{ __html: guideData['P02'][0].gcont }} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : transferGuide ? (
                <div className="m-a-usage-guide-detail-frame m-t-20 m-b-20">
                    <div className="p-t-10 p-b-10 ht-100pc wd-100pc">
                        <div className="m-a-userGuideContFrame p-t-25 p-b-20">
                            {guideData['P03'] && guideData['P03'][0] && (
                                <div key={guideData['P03'][0].gtitle}>
                                    <div dangerouslySetInnerHTML={{ __html: guideData['P03'][0].gcont }} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : domesticGuide ? (
                <div className="m-a-usage-guide-detail-frame m-t-20 m-b-20">
                    <div className="p-t-10 p-b-10 ht-100pc wd-100pc">
                        <div className="m-a-userGuideContFrame p-t-25 p-b-20">
                            {guideData['P04'] && guideData['P04'][0] && (
                                <div key={guideData['P04'][0].gtitle}>
                                    <div dangerouslySetInnerHTML={{ __html: guideData['P04'][0].gcont }} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : deliveryGuide ? (
                <div className="m-a-usage-guide-detail-frame m-t-20 m-b-20">
                    <div className="p-t-10 p-b-10 ht-100pc wd-100pc">
                        <div className="m-a-userGuideContFrame p-t-25 p-b-20">
                            {guideData['P05'] && guideData['P05'][0] && (
                                <div key={guideData['P05'][0].gtitle}>
                                    <div dangerouslySetInnerHTML={{ __html: guideData['P05'][0].gcont }} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : null}
            {cartGuidePopup ?
                <GuideModal cartGuidePopup={cartGuidePopup} closeCartGuidePopup={closeCartGuidePopup}
                            cartGuideHeader={cartGuideHeader} cartGuideContent={cartGuideContent}/>
                :
                orderGuidePopup ?
                    <GuideModal orderGuidePopup={orderGuidePopup} closeOrderGuidePopup={closeOrderGuidePopup}
                                orderGuideHeader={orderGuideHeader} orderGuideContent={orderGuideContent}/>
                    :
                    offlineGuidePopup ?
                        <GuideModal offlineGuidePopup={offlineGuidePopup}
                                    closeOfflineGuidePopup={closeOfflineGuidePopup}
                                    offlineGuideHeader={offlineGuideHeader}
                                    offlineGuideContent={offlineGuideContent}/>
                        :
                        transferGuidePopup ?
                            <GuideModal transferGuidePopup={transferGuidePopup}
                                        closeTransferGuidePopup={closeTransferGuidePopup}
                                        transferGuideHeader={transferGuideHeader}
                                        transferGuideContent={transferGuideContent}/>
                            :
                            domesticGuidePopup ?
                                <GuideModal domesticGuidePopup={domesticGuidePopup}
                                            closeDomesticGuidePopup={closeDomesticGuidePopup}
                                            domesticGuideHeader={domesticGuideHeader}
                                            domesticGuideContent={domesticGuideContent}/>
                                :
                                deliveryGuidePopup ?
                                    <GuideModal deliveryGuidePopup={deliveryGuidePopup}
                                                closeDeliveryGuidePopup={closeDeliveryGuidePopup}
                                                deliveryGuideHeader={deliveryGuideHeader}
                                                deliveryGuideContent={deliveryGuideContent}/>
                                    :
                                    null
            }
        </div>
    )
}

export default MUsageGuidePg;
