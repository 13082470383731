import React, {useEffect, useRef, useState} from 'react';
import '../../css/web/UserReviewPg.css';
import mainNotice from "../../assets/img/mainNotice.png";
import notice from "../../assets/img/notice.png";

import '../../css/web/HeartButton.css';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {BiCaretDown} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import commomHeader from "../../assets/img/commonHeader.png";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";

function UserCustomInfoPg() {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [emsTab, setEmsTab] = useState(true);
    const [premiumTab, setPremiumTab] = useState(false);

    const [caution, setCaution] = useState(true);
    const [prohibition, setProhibition] = useState(false);
    const [significant, setSignificant] = useState(false);
    const [customs, setCustoms] = useState(false);

    // 여기서부터 커스텀 셀렉트
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({ value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const [filteredCountryList, setFilteredCountryList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [crCode, setCrCode] = useState('')

    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(prev => !prev);
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setSearchKeyword('');
        setCrCode(country.value);
        setCountryShow(true);
    };

    const countryNameMapping = {
        'USA': 'United States of America',
    };

    const handleCountrySearch = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);

        const mappedKeyword = countryNameMapping[keyword.toUpperCase()] || keyword;

        const filteredCountries = countryList.filter(country =>
            country.label.toLowerCase().includes(mappedKeyword.toLowerCase())
        );

        setFilteredCountryList(filteredCountries);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectAllCountry = () => {
        axios.get('/order/selectCountry')
            .then(response => {
                const countries = response.data.map(country => ({
                    value: country.countryCode,
                    label: `${country.countryNm} (${country.countryEngNm})`
                })).sort((a, b) => a.label.localeCompare(b.label));

                setCountryList(countries);
                setFilteredCountryList(countries);
            })
            .catch(error => {
                console.error("Error fetching countries:", error);
            });
    };

    useEffect(() => {
        selectAllCountry();
    }, [])

    const [data, setData] = useState(null);
    const [premiumcd, setPremiumcd] = useState('31');
    const emsCountries = ["AU", "US", "CA", "JP", "FR", "DE", "GB", "IT", "CN", "KR", "SG", "TH", "VN", "NZ",
        "MY", "PH", "ID", "IN", "BR", "MX", "ZA", "RU", "ES", "SE", "NL", "CH", "DK", "NO", "FI", "BE", "PT", "GR"];
    const emsCountry = emsCountries.includes(crCode); // 위 emsCountries에 해당하는 국가코드가 하나라도 있으면 true, 없으면 false 반환
    const [selectedShipCrError, setSelectedShipCrError] = useState(false);
    const closeSelectedShipCrError = (() => {
        setSelectedShipCrError(false);
    })

    useEffect(() => {
        if (crCode && emsTab && !emsCountry) {
            setSelectedShipCrError(true);
        } else if ((crCode && emsTab && emsCountry) || (crCode && premiumTab)) {
            const regkey = 'c316db8184d8c7fd41571380612488';
            axios({
                method: 'get',
                url: `/externalApi/api.EmsApplyGoCondition.ems?regkey=${regkey}&nation=${crCode}&premiumcd=${premiumcd}`,
            })
                .then(response => {
                    if (response.status === 200) {

                        const xml = response.data;
                        const parser = new DOMParser();
                        const xmlDoc = parser.parseFromString(xml, "text/xml");

                        const nationcd = xmlDoc.querySelector("nationcd").textContent;
                        const prcapplyareacd = xmlDoc.querySelector("prcapplyareacd").textContent;
                        const limitwght = xmlDoc.querySelector("limitwght").textContent;
                        const maxstdcd = xmlDoc.querySelector("maxstdcd").textContent;
                        const maxstdcdnm = xmlDoc.querySelector("maxstdcdnm").textContent;
                        const fixtimedivcd = xmlDoc.querySelector("fixtimedivcd").textContent;
                        const delivnotictreatcd = xmlDoc.querySelector("delivnotictreatcd").textContent;
                        const delivseq = xmlDoc.querySelector("delivseq").textContent;
                        const keepddcnt = xmlDoc.querySelector("keepddcnt").textContent;
                        const customrprtdocmakestdcd = xmlDoc.querySelector("customrprtdocmakestdcd").textContent;
                        const etcdocwritecd = xmlDoc.querySelector("etcdocwritecd").textContent;
                        const writechractcont = xmlDoc.querySelector("writechractcont").textContent;
                        const delivrequirddcnt = xmlDoc.querySelector("delivrequirddcnt").textContent;
                        const delivnonableweekday = xmlDoc.querySelector("delivnonableweekday").textContent;
                        const recprsntreatcd = xmlDoc.querySelector("recprsntreatcd").textContent;
                        const areanm = xmlDoc.querySelector("EmsApplyGetServiceArea > areanm").textContent;


                        const sendcondnotes = Array.from(xmlDoc.querySelectorAll("sendcondnote"))
                            .map(note => note.textContent);

                        // Use these parsed values to update your state or render them
                        setData({
                            nationcd,
                            prcapplyareacd,
                            limitwght,
                            maxstdcd,
                            maxstdcdnm,
                            fixtimedivcd,
                            delivnotictreatcd,
                            delivseq,
                            keepddcnt,
                            customrprtdocmakestdcd,
                            etcdocwritecd,
                            writechractcont,
                            delivrequirddcnt,
                            delivnonableweekday,
                            recprsntreatcd,
                            areanm,

                            sendcondnotes
                        });

                    } else {
                        console.error('Failed to fetch data');
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [crCode]);

    const handleMainTab = ((tab) => {
        if (tab === "Ems") {
            setEmsTab(true);
            setPremiumcd('31');
            setPremiumTab(false);
        } else {
            setEmsTab(false);
            setPremiumcd('32');
            setPremiumTab(true);
        }
    })

    const handleTab = ((tab) => {
        if (tab === "caution") {
            setCaution(true);
            setProhibition(false);
            setSignificant(false);
            setCustoms(false);
        } else if (tab === "prohibition") {
            setCaution(false);
            setProhibition(true);
            setSignificant(false);
            setCustoms(false);
        } else if (tab === "significant") {
            setCaution(false);
            setProhibition(false);
            setSignificant(true);
            setCustoms(false);
        } else {
            setCaution(false);
            setProhibition(false);
            setSignificant(false);
            setCustoms(true);
        }
    })

    const deliveryItemInfoWindow = () => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 1200;
        const height = 900;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);
        window.open('https://ems.epost.go.kr/front.Introduction04pop.postal', '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    }
    const countryCommonInfoWindow = () => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 800;
        const height = 600;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);
        window.open('https://ems.epost.go.kr/ems/front/survey/pafao04b09.jsp', '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    }

    const prohibitionEpWindow = () => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 800;
        const height = 700;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);
        window.open('https://ems.epost.go.kr/ems/front/survey/pafao04b07.jsp', '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    }
    const limitItemEpWindow = () => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 800;
        const height = 400;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);
        window.open('https://ems.epost.go.kr/ems/front/survey/pafao04b08.jsp', '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    }
    const countryCommonEpWiondow = () => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 900;
        const height = 700;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);
        window.open('https://ems.epost.go.kr/ems/front/survey/pafao04b09.jsp', '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    }

    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{width: "85%"}}>
                <div style={{display:"flex", alignItems:"flex-start", padding:"10px 0"}}>
                    <img className="wd-25 ht-25" src={commomHeader} alt="header"/>
                    <p className="font-s-20 m-l-10 font-t-title font-c-default">{t(`ciHeader1`)}</p> {/*국가별통관정보*/}
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <button onClick={() => handleMainTab('Ems')} style={{
                        width: "140px",
                        height: "60px",
                        border: "solid",
                        borderColor: emsTab ? "darkorange" : "lightgray",
                        borderWidth: emsTab ? "3px" : "thin 1px",
                        borderRadius: "4px",
                        fontSize: "15px",
                        color: "dimgrey",
                        fontWeight: "bold"
                    }}>EMS
                    </button>
                    <button onClick={() => handleMainTab('Premium')} style={{
                        width: "140px",
                        height: "60px",
                        border: "solid",
                        borderColor: premiumTab ? "darkorange" : "lightgray",
                        borderWidth: premiumTab ? "3px" : "thin 1px",
                        borderRadius: "4px",
                        marginLeft: "10px",
                        fontSize: "15px",
                        color: "dimgrey",
                        fontWeight: "bold"
                    }}>EMS Premium
                    </button>
                </div>
                {/*EMS텝*/}
                {emsTab ?
                    <div className="font-t-cont" style={{
                        border: "solid",
                        borderColor: "lightgray",
                        borderWidth: "thin 1px",
                        padding: "10px",
                        marginTop: "20px"
                    }}>
                        <div>
                            <div style={{
                                height: "45px",
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: "20px"
                            }}>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu1`)}</span></div>
                                <div className="wd-80pc p-l-17">
                                    <div ref={countryRef} className="display-block ht-45 wd-45pc c-point position-relative" onClick={toggleCountryShow}>
                                        <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                                            {selectedCountry.value ? (
                                                <div className="wd-15pc text-center align-center-c">
                                                    <div className={`m-l-6 font-s-20 fi fi-${selectedCountry.value.toLowerCase()}`} />
                                                </div>
                                            ) : (
                                                <div className="wd-15pc text-center align-center-c p-l-5">
                                                    <div className="wd-29 ht-22 text-center font-s-12 m-0-a country-select-flag">?</div>
                                                </div>
                                            )}
                                            <input className="country-select-in-input wd-100pc ht-100pc c-point font-s-14 p-l-15"
                                                   type="text" placeholder={t(`modSmallMenu3-1`)} autoComplete="off"
                                                   value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '') || (selectedCountry.value ? selectedCountry.label : '')}
                                                   onChange={handleCountrySearch} />
                                            <i className="wd-10pc text-center align-center-c font-s-20 p-b-2 font-c-darkgrey">
                                                <BiCaretDown />
                                            </i>
                                        </div>
                                        {countryShow && (
                                            <div className="country-select-data wd-100pc position-absolute m-t-5">
                                                {filteredCountryList.map((country, index) => (
                                                    <div className="selectChildBt display-flex c-point p-t-10 p-b-10 p-l-20" key={index}
                                                         onClick={() => handleCountryChange(country)}>
                                                        <div className={`font-s-17 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                                        <p className="font-s-15">{country.label}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                    {/*여기서부터 커스텀 국가셀렉트*/}
                                    {/*<div ref={countryRef} style={{*/}
                                    {/*    height: "100%",*/}
                                    {/*    width: "50%",*/}
                                    {/*    position: "relative",*/}
                                    {/*    marginLeft: "10px"*/}
                                    {/*}}>*/}
                                    {/*    <button className="selectParentBt" style={{width: "100%", height: "100%"}}*/}
                                    {/*            onClick={toggleCountryShow}>*/}
                                    {/*        <p style={{margin: "0"}}>{selectedCountry.label}</p>*/}
                                    {/*        <span style={{*/}
                                    {/*            position: "absolute",*/}
                                    {/*            left: "85%",*/}
                                    {/*            top: "0",*/}
                                    {/*            height: "100%",*/}
                                    {/*            width: "20%"*/}
                                    {/*        }}>*/}
                                    {/*            <i style={{*/}
                                    {/*                display: "flex",*/}
                                    {/*                justifyContent: "center",*/}
                                    {/*                alignItems: "center",*/}
                                    {/*                height: "100%",*/}
                                    {/*            }}><BiCaretDown/></i>*/}
                                    {/*        </span>*/}
                                    {/*    </button>*/}
                                    {/*    {countryShow ?*/}
                                    {/*        <div style={{*/}
                                    {/*            width: "100%",*/}
                                    {/*            border: "solid",*/}
                                    {/*            height: "400px",*/}
                                    {/*            borderWidth: "thin 1px",*/}
                                    {/*            borderColor: "lightgray",*/}
                                    {/*            position: "absolute",*/}
                                    {/*            top: "100%",*/}
                                    {/*            bottom: "100%",*/}
                                    {/*            zIndex: 1,*/}
                                    {/*            marginTop: "5px",*/}
                                    {/*            overflowY: "scroll",*/}
                                    {/*        }}>*/}
                                    {/*            {countryList.map((country, index) => (*/}
                                    {/*                <div className="selectChildBt" key={index}*/}
                                    {/*                     onClick={() => handleCountryChange(country)} style={{*/}
                                    {/*                    display: "flex",*/}
                                    {/*                    padding: "10px",*/}
                                    {/*                    justifyContent: "center",*/}
                                    {/*                    alignItems: "center",*/}
                                    {/*                    cursor: "pointer",*/}
                                    {/*                    fontSize: "14px",*/}
                                    {/*                    borderRadius: "4px",*/}
                                    {/*                    zIndex: 99,*/}
                                    {/*                }}>{country.label}</div>*/}
                                    {/*            ))}*/}
                                    {/*        </div>*/}
                                    {/*        :*/}
                                    {/*        null*/}
                                    {/*    }*/}
                                    {/*</div>*/}
                                    {/*여기까지 커스텀 국가셀렉트*/}
                                </div>
                            </div>
                            <div style={{height: "45px", display: "flex", flexDirection: "row"}}>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu2`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.nationcd}</p>
                                </div>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu3`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.fixtimedivcd}</p>
                                </div>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu4`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.delivnotictreatcd}</p>
                                </div>
                            </div>
                            <div style={{height: "45px", display: "flex", flexDirection: "row"}}>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu5`)}</span></div>
                                <div style={{
                                    width: "47.6%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.maxstdcdnm}</p>
                                </div>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu6`)}(g)</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.limitwght}</p>
                                </div>
                            </div>
                            <div style={{height: "45px", display: "flex", flexDirection: "row"}}>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu7`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.delivseq}</p>
                                </div>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu8`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.delivrequirddcnt}</p>
                                </div>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu9`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.keepddcnt}</p>
                                </div>
                            </div>
                            <div style={{height: "45px", display: "flex", flexDirection: "row"}}>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu10`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.delivnonableweekday}</p>
                                </div>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu11`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.customrprtdocmakestdcd}</p>
                                </div>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu12`)}</span></div>
                                <div style={{width: "14.3%", height: "100%"}}>
                                </div>
                            </div>
                            <div style={{padding: "10px 0 10px 0"}}>
                                <p style={{fontSize: "12px", margin: "0"}}>※ {t(`ciNotice1`)}</p>
                                <p style={{fontSize: "12px", margin: "0"}}>※ {t(`ciNotice2`)}</p>
                                <p style={{fontSize: "12px", margin: "0"}}>※ {t(`ciNotice3`)}</p>
                            </div>
                            <div>
                                <div style={{height: "40px", display: "flex", flexDirection: "row"}}>
                                    <div style={{height: "40px", display: "flex", alignItems: "center"}}>
                                        <img src={notice} style={{width: "25px", height: "25px"}} alt=""/>
                                    </div>
                                    <div style={{
                                        height: "40px",
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "10px"
                                    }}>
                                        <span style={{fontSize: "14px", fontWeight: "bold"}}>{t(`ciHeader2`)}</span>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <button onClick={deliveryItemInfoWindow} style={{
                                            width: "200px",
                                            height: "30px",
                                            border: "solid",
                                            borderColor: "lightgray",
                                            borderWidth: "thin 1px",
                                            borderRadius: "4px",
                                            marginLeft: "10px",
                                            fontSize: "12px",
                                            color: "dimgrey",
                                            fontWeight: "bold"
                                        }}>{t(`ciNoticeBt1`)}
                                        </button>
                                        <button onClick={countryCommonInfoWindow} style={{
                                            width: "200px",
                                            height: "30px",
                                            border: "solid",
                                            borderColor: "lightgray",
                                            borderWidth: "thin 1px",
                                            borderRadius: "4px",
                                            marginLeft: "10px",
                                            fontSize: "12px",
                                            color: "dimgrey",
                                            fontWeight: "bold"
                                        }}>{t(`ciNoticeBt2`)}
                                        </button>
                                    </div>
                                </div>
                                <div style={{
                                    padding: "10px",
                                    minHeight: "100px",
                                    backgroundColor: "rgba(12,65,255,0.02)"
                                }}>
                                    {data && data.areanm.split('\n').map((text, index) =>
                                        <p key={index} style={{margin: "0", fontSize: "13px"}}>
                                            {text}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div style={{marginTop: "20px"}}>
                                <div style={{height: "40px", display: "flex", flexDirection: "row"}}>
                                    <div style={{height: "40px", display: "flex", alignItems: "center"}}>
                                        <img src={mainNotice} style={{width: "25px", height: "25px"}} alt=""/>
                                    </div>
                                    <div style={{
                                        height: "40px",
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "10px"
                                    }}>
                                        <span style={{fontSize: "14px", fontWeight: "bold"}}>{t(`ciHeader3`)}</span>
                                    </div>
                                </div>
                                <div style={{
                                    height: "60px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}>
                                    <button onClick={() => handleTab('caution')} style={{
                                        width: "24%",
                                        height: "40px",
                                        border: "solid",
                                        borderColor: caution ? "orange" : "lightgray",
                                        borderWidth: caution ? "3px" : "thin 1px",
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                        fontSize: "12px",
                                        color: "dimgrey",
                                        fontWeight: "bold"
                                    }}>{t(`ciGuideBt1`)}
                                    </button>
                                    <button onClick={() => handleTab('prohibition')} style={{
                                        width: "24%",
                                        height: "40px",
                                        border: "solid",
                                        borderColor: prohibition ? "orange" : "lightgray",
                                        borderWidth: prohibition ? "3px" : "thin 1px",
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                        fontSize: "12px",
                                        color: "dimgrey",
                                        fontWeight: "bold"
                                    }}>{t(`ciGuideBt2`)}
                                    </button>
                                    <button onClick={() => handleTab('significant')} style={{
                                        width: "24%",
                                        height: "40px",
                                        border: "solid",
                                        borderColor: significant ? "orange" : "lightgray",
                                        borderWidth: significant ? "3px" : "thin 1px",
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                        fontSize: "12px",
                                        color: "dimgrey",
                                        fontWeight: "bold"
                                    }}>{t(`ciGuideBt3`)}
                                    </button>
                                    <button onClick={() => handleTab('customs')} style={{
                                        width: "24%",
                                        height: "40px",
                                        border: "solid",
                                        borderColor: customs ? "orange" : "lightgray",
                                        borderWidth: customs ? "3px" : "thin 1px",
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                        fontSize: "12px",
                                        color: "dimgrey",
                                        fontWeight: "bold"
                                    }}>{t(`ciGuideBt4`)}
                                    </button>
                                </div>
                                <div style={{
                                    padding: "10px",
                                    minHeight: "100px",
                                    backgroundColor: "rgba(12,65,255,0.02)"
                                }}>
                                    {caution ?
                                        <div>
                                            {data && data.sendcondnotes[0].split('\n').map((text, index) =>
                                                <p key={index} style={{margin: "0", fontSize: "13px"}}>
                                                    {text}
                                                </p>
                                            )}
                                        </div>
                                        :
                                        prohibition ?
                                            <div>
                                                {data && (data.sendcondnotes[1] + '\n' + data.sendcondnotes[2]).split('\n').map((text, index) =>
                                                    <p key={index} style={{margin: "0", fontSize: "13px"}}>
                                                        {text}
                                                    </p>
                                                )}
                                            </div>
                                            :
                                            significant ?
                                                <div>
                                                    {data && data.sendcondnotes[3].split('\n').map((text, index) =>
                                                        <p key={index} style={{margin: "0", fontSize: "13px"}}>
                                                            {text}
                                                        </p>
                                                    )}
                                                </div>
                                                :
                                                customs ?
                                                    <div>
                                                        {data && data.sendcondnotes[4] && data.sendcondnotes[4].split('\n').map((text, index) =>
                                                            <p key={index} style={{margin: "0", fontSize: "13px"}}>
                                                                {text}
                                                            </p>
                                                        )}
                                                    </div>
                                                    :
                                                    null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                {/*Premium텝*/}
                {premiumTab ?
                    <div className="font-t-cont" style={{
                        border: "solid",
                        borderColor: "lightgray",
                        borderWidth: "thin 1px",
                        padding: "10px",
                        marginTop: "20px"
                    }}>
                        <div>
                            <div style={{
                                height: "45px",
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: "20px"
                            }}>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t('ciSmallMenu1')}</span></div>
                                <div className="wd-80pc p-l-17">
                                    {/*여기서부터 커스텀 국가셀렉트*/}
                                    <div ref={countryRef} className="display-block ht-45 wd-45pc c-point position-relative" onClick={toggleCountryShow}>
                                        <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                                            {selectedCountry.value ? (
                                                <div className="wd-15pc text-center align-center-c">
                                                    <div className={`m-l-6 font-s-20 fi fi-${selectedCountry.value.toLowerCase()}`} />
                                                </div>
                                            ) : (
                                                <div className="wd-15pc text-center align-center-c p-l-5">
                                                    <div className="wd-29 ht-22 text-center font-s-12 m-0-a country-select-flag">?</div>
                                                </div>
                                            )}
                                            <input className="country-select-in-input wd-100pc ht-100pc c-point font-s-14 p-l-15"
                                                   type="text" placeholder={t(`modSmallMenu3-1`)} autoComplete="off"
                                                   value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '') || (selectedCountry.value ? selectedCountry.label : '')}
                                                   onChange={handleCountrySearch} />
                                            <i className="wd-10pc text-center align-center-c font-s-20 p-b-2 font-c-darkgrey">
                                                <BiCaretDown />
                                            </i>
                                        </div>
                                        {countryShow && (
                                            <div className="country-select-data wd-100pc position-absolute m-t-5">
                                                {filteredCountryList.map((country, index) => (
                                                    <div className="selectChildBt display-flex c-point p-t-10 p-b-10 p-l-20" key={index}
                                                         onClick={() => handleCountryChange(country)}>
                                                        <div className={`font-s-17 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                                        <p className="font-s-15">{country.label}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    {/*여기까지 커스텀 국가셀렉트*/}
                                </div>
                            </div>
                            <div style={{height: "45px", display: "flex", flexDirection: "row"}}>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu2`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.prcapplyareacd}</p>
                                </div>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu13`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.delivnotictreatcd}</p>
                                </div>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu4`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.delivnotictreatcd}</p>
                                </div>
                            </div>
                            <div style={{height: "45px", display: "flex", flexDirection: "row"}}>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu5`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.maxstdcdnm}</p>
                                </div>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu6`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.limitwght}</p>
                                </div>
                            </div>
                            <div style={{height: "45px", display: "flex", flexDirection: "row"}}>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu7`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.delivseq}</p>
                                </div>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu8`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.delivrequirddcnt}</p>
                                </div>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu14`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.keepddcnt}</p>
                                </div>
                            </div>
                            <div style={{height: "45px", display: "flex", flexDirection: "row"}}>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>{t(`ciSmallMenu10`)}</span></div>
                                <div style={{
                                    width: "14.3%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "10px"
                                }}>
                                    <p style={{margin: 0, fontSize: "12px"}}>{data && data.delivnonableweekday}</p>
                                </div>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>-</span></div>
                                <div style={{width: "14.3%", height: "100%"}}>
                                </div>
                                <div style={{
                                    width: "19%",
                                    height: "100%",
                                    backgroundColor: "rgba(12,65,255,0.035)",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                        <span style={{
                                            fontWeight: "bold",
                                            color: "dimgrey",
                                            marginLeft: "10px",
                                            fontSize: "13px"
                                        }}>-</span></div>
                                <div style={{width: "14.3%", height: "100%"}}>
                                </div>
                            </div>
                            <div style={{padding: "10px 0 10px 0"}}>
                                <p style={{fontSize: "12px", margin: "0"}}>※ {t(`ciNotice1`)}</p>
                                <p style={{fontSize: "12px", margin: "0"}}>※ {t(`ciNotice3`)}</p>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginTop: "10px",
                                    justifyContent: "center"
                                }}>
                                    <button onClick={prohibitionEpWindow} style={{
                                        width: "150px",
                                        height: "30px",
                                        border: "solid",
                                        borderColor: "lightgray",
                                        borderWidth: "thin 1px",
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                        fontSize: "12px",
                                        color: "dimgrey",
                                        fontWeight: "bold"
                                    }}>{t(`ciSmallMenu15`)}
                                    </button>
                                    <button onClick={limitItemEpWindow} style={{
                                        width: "150px",
                                        height: "30px",
                                        border: "solid",
                                        borderColor: "lightgray",
                                        borderWidth: "thin 1px",
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                        fontSize: "12px",
                                        color: "dimgrey",
                                        fontWeight: "bold"
                                    }}>{t(`ciSmallMenu16`)}
                                    </button>
                                    <button onClick={countryCommonEpWiondow} style={{
                                        width: "150px",
                                        height: "30px",
                                        border: "solid",
                                        borderColor: "lightgray",
                                        borderWidth: "thin 1px",
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                        fontSize: "12px",
                                        color: "dimgrey",
                                        fontWeight: "bold"
                                    }}>{t(`ciNoticeBt2`)}
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div style={{height: "40px", display: "flex", flexDirection: "row"}}>
                                    <div style={{height: "40px", display: "flex", alignItems: "center"}}>
                                        <img src={notice} style={{width: "25px", height: "25px"}} alt=""/>
                                    </div>
                                    <div style={{
                                        height: "40px",
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "10px"
                                    }}>
                                        <span style={{fontSize: "14px", fontWeight: "bold"}}>{t(`ciHeader2`)}</span>
                                    </div>
                                </div>
                                <div style={{
                                    padding: "10px",
                                    minHeight: "100px",
                                    backgroundColor: "rgba(12,65,255,0.02)"
                                }}>
                                    {data && data.areanm.split('\n').map((text, index) =>
                                        <p key={index} style={{margin: "0", fontSize: "13px"}}>
                                            {text}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div style={{marginTop: "20px"}}>
                                <div style={{height: "40px", display: "flex", flexDirection: "row"}}>
                                    <div style={{height: "40px", display: "flex", alignItems: "center"}}>
                                        <img src={mainNotice} style={{width: "25px", height: "25px"}} alt=""/>
                                    </div>
                                    <div style={{
                                        height: "40px",
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "10px"
                                    }}>
                                        <span style={{fontSize: "14px", fontWeight: "bold"}}>{t(`ciHeader3`)}</span>
                                    </div>
                                </div>
                                <div style={{
                                    height: "60px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}>
                                    <button onClick={() => handleTab('caution')} style={{
                                        width: "24%",
                                        height: "40px",
                                        border: "solid",
                                        borderColor: caution ? "orange" : "lightgray",
                                        borderWidth: caution ? "3px" : "thin 1px",
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                        fontSize: "12px",
                                        color: "dimgrey",
                                        fontWeight: "bold"
                                    }}>{t(`ciGuideBt1`)}
                                    </button>
                                    <button onClick={() => handleTab('prohibition')} style={{
                                        width: "24%",
                                        height: "40px",
                                        border: "solid",
                                        borderColor: prohibition ? "orange" : "lightgray",
                                        borderWidth: prohibition ? "3px" : "thin 1px",
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                        fontSize: "12px",
                                        color: "dimgrey",
                                        fontWeight: "bold"
                                    }}>{t(`ciGuideBt2`)}
                                    </button>
                                    <button onClick={() => handleTab('significant')} style={{
                                        width: "24%",
                                        height: "40px",
                                        border: "solid",
                                        borderColor: significant ? "orange" : "lightgray",
                                        borderWidth: significant ? "3px" : "thin 1px",
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                        fontSize: "12px",
                                        color: "dimgrey",
                                        fontWeight: "bold"
                                    }}>{t(`ciGuideBt3`)}
                                    </button>
                                    <button onClick={() => handleTab('customs')} style={{
                                        width: "24%",
                                        height: "40px",
                                        border: "solid",
                                        borderColor: customs ? "orange" : "lightgray",
                                        borderWidth: customs ? "3px" : "thin 1px",
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                        fontSize: "12px",
                                        color: "dimgrey",
                                        fontWeight: "bold"
                                    }}>{t(`ciGuideBt4`)}
                                    </button>
                                </div>
                                <div style={{
                                    padding: "10px",
                                    minHeight: "100px",
                                    backgroundColor: "rgba(12,65,255,0.02)"
                                }}>
                                    {caution ?
                                        <div>
                                            {data && data.sendcondnotes[0].split('\n').map((text, index) =>
                                                <p key={index} style={{margin: "0", fontSize: "13px"}}>
                                                    {text}
                                                </p>
                                            )}
                                        </div>
                                        :
                                        prohibition ?
                                            <div>
                                                {data && (data.sendcondnotes[1] + '\n' + data.sendcondnotes[2]).split('\n').map((text, index) =>
                                                    <p key={index} style={{margin: "0", fontSize: "13px"}}>
                                                        {text}
                                                    </p>
                                                )}
                                            </div>
                                            :
                                            significant ?
                                                <div>
                                                    {data && data.sendcondnotes[3].split('\n').map((text, index) =>
                                                        <p key={index} style={{margin: "0", fontSize: "13px"}}>
                                                            {text}
                                                        </p>
                                                    )}
                                                </div>
                                                :
                                                customs ?
                                                    <div>
                                                        {data && data.sendcondnotes[4] && data.sendcondnotes[4].split('\n').map((text, index) =>
                                                            <p key={index} style={{margin: "0", fontSize: "13px"}}>
                                                                {text}
                                                            </p>
                                                        )}
                                                    </div>
                                                    :
                                                    null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>


            { selectedShipCrError ?
            <MsgCheckModal selectedShipCrError={selectedShipCrError} closeSelectedShipCrError={closeSelectedShipCrError}/>
                :
                null
            }

        </div>
    );
}

export default UserCustomInfoPg
