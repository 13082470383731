import React, {useEffect, useState} from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";
import commonClose from "../../assets/img/commonClose.png";
import "../../css/mobile/MProdPriceDetail.css";
import commonHether from "../../assets/img/commonHether.png";
import box from "../../assets/img/box.png";
import discount from "../../assets/img/discount.png";


function MProdPriceDetail({closeDetail, paySeqnum}) {
    const {t} = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');


    const [payInfoList, setPayInfoList] = useState([{}]);
    const [prodList, setProdList] = useState([]);

    const selectPayinfoDetail = () => axios({
        method: 'get',
        url: '/order/selectTotalPayInfo/',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
        params: {
            seqNum: paySeqnum
        },
    })
        .then((response) => {
            setPayInfoList(response.data)
            setProdList(response.data[0].orderdtDTO?.productsDTO)
        })
    useEffect(() => {
        const timer = setTimeout(() => {
            selectPayinfoDetail();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])


    const totalQuantity = prodList.reduce((acc, prod) => acc + prod?.quantity, 0);
    const totalProdExpense = (prodList[0]?.totalProdPrice + prodList[0]?.totalMallShipPrice + prodList[0]?.totalFee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    return (
        <div className={'m-a-prod-price-detail-background font-t-cont'}>
            <div className="m-a-prod-price-detail-container">
                <div className={'m-a-prod-price-detail-header'}>
                    <div className={'m-a-prod-price-detail-header-title font-t-title'}>{t(`ppdHeader`)}</div> {/*상품상세*/}
                    <img className={'m-a-prod-price-detail-header-icon'} onClick={closeDetail} src={commonClose} alt={''}/>
                </div>
                {/*상세바디*/}
                <div className={'m-a-prod-price-detail-body'}>
                    <div className={'m-a-prod-price-detail-table-title'}>
                        <img className={'m-a-prod-price-detail-table-icon'} src={commonHether} alt={''}/>
                        {t(`modPlHeader2`)} {/*상품비용*/}
                    </div>
                    <div className={'m-a-prod-price-detail-table'}>
                        <div className={'m-a-prod-price-detail-table-row label'}>
                            <div className={'m-a-prod-price-detail-table-item sm'}>{t(`num`)}</div>
                            <div className={'m-a-prod-price-detail-table-item lg'}>{t(`prodNm`)}</div> {/*상품명*/}
                            <div className={'m-a-prod-price-detail-table-item'}>{t(`prodOp`)}</div> {/*옵션*/}
                            <div className={'m-a-prod-price-detail-table-item'}>{t(`prodQy`)}</div> {/*수량*/}
                            <div className={'m-a-prod-price-detail-table-item'}>{t(`modSHeader3`)}</div> {/*총 금액*/}
                        </div>
                        {prodList.map((prod, index) => (
                            <div className={'m-a-prod-price-detail-table-row'}>
                                <div className={'m-a-prod-price-detail-table-item sm'}>{index + 1}</div>
                                <div className={'m-a-prod-price-detail-table-item lg admin-tb-text-cut'}>{prod?.name}</div>
                                <div className={'m-a-prod-price-detail-table-item admin-tb-text-cut'}>{prod?.option}</div>
                                <div className={'m-a-prod-price-detail-table-item'}>{prod?.quantity?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                <div className={'m-a-prod-price-detail-table-item'}>
                                    {(prod?.price + prod?.mallShipPrice + prod?.fee)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={'m-a-prod-price-detail-divider-bold'}/>
                    <div className={'m-a-prod-price-detail-table-title'}>
                        <img className={'m-a-prod-price-detail-table-icon'} src={commonHether} alt={''}/>
                        {t(`modHeader10`)}
                    </div>
                    {payInfoList.map((pay, index) => (
                        <div className={'m-a-prod-price-detail-info-box'}>
                            <img className={'m-a-prod-price-detail-info-icon'} src={box} alt={''}/>
                            <div className={'m-a-prod-price-detail-divider'}/>
                            <div className={'m-a-prod-price-detail-info-row'}>
                                <div className={'m-a-prod-price-detail-info-text'}>{t(`prodQy`)}</div>
                                <div className={'m-a-prod-price-detail-info-text'}>
                                    {totalQuantity?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                            </div>
                            <div className={'m-a-prod-price-detail-info-row'}>
                                <div className={'m-a-prod-price-detail-info-text'}>{t(`prodPr`)}</div>
                                <div className={'m-a-prod-price-detail-info-text'}>
                                    {pay.ttProdPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                            </div>
                            <div className={'m-a-prod-price-detail-info-row'}>
                                <div className={'m-a-prod-price-detail-info-text'}>{t(`prodMallPr`)}</div>
                                <div className={'m-a-prod-price-detail-info-text'}>
                                    {pay.ttMallShipPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                            </div>
                            <div className={'m-a-prod-price-detail-info-row'}>
                                <div className={'m-a-prod-price-detail-info-text'}>{t(`prodFee`)}</div>
                                <div className={'m-a-prod-price-detail-info-text'}>
                                    {pay.ttEtcFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                            </div>

                            <img className={'m-a-prod-price-detail-info-icon'} src={discount} alt={''}/>
                            <div className={'m-a-prod-price-detail-divider'}/>
                            <div className={'m-a-prod-price-detail-info-row'}>
                                <div className={'m-a-prod-price-detail-info-text'}>{t(`myUseDep`)}</div>
                                <div className={'m-a-prod-price-detail-info-text'}>
                                    {pay.depUsePrice === 0 || pay.depUsePrice === null ? 0 :
                                        <span>- {pay.depUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>}
                                </div>
                            </div>
                            <div className={'m-a-prod-price-detail-info-row'}>
                                <div className={'m-a-prod-price-detail-info-text'}>{t(`myUsePt`)}</div>
                                <div className={'m-a-prod-price-detail-info-text'}>
                                    {pay.pointUsePrice === 0 || pay.pointUsePrice === null ? 0 :
                                        <span>- {pay.pointUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>}
                                </div>
                            </div>
                            <div className={'m-a-prod-price-detail-info-row'}>
                                <div className={'m-a-prod-price-detail-info-text'}>{t(`dpdCpNm`)}</div> {/*쿠폰(쿠폰명)*/}
                                <div className={'m-a-prod-price-detail-info-text'}>
                                    {pay.couponUsePrice === 0 || pay.couponUsePrice === null ? 0 :
                                        <span>- {pay.couponUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>}
                                </div>
                            </div>
                            <div className={'m-a-prod-price-detail-divider'}/>
                            <div className={'m-a-prod-price-detail-info-row margin-top'}>
                                <div className={'m-a-prod-price-detail-info-text font-b'}>{t(`modFinalPay`)}</div> {/*최종결제금액*/}
                                <div className={'m-a-prod-price-detail-info-text font-b primary'}>
                                    &#8361; {pay?.payStatus === "PS0" ? 0 : pay?.lastPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MProdPriceDetail
