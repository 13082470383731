import React from "react";
import '../../css/mobile/MChatFileDownModal.css'
import commonDownload from "../../assets/img/commonDownload.png";
import commonClose from "../../assets/img/commonClose.png";
import testImg from "../../assets/img/boxLogo.png";
import chatExcelImg from "../../assets/img/chatExcelImg.png";
import chatFileImg from "../../assets/img/chatFolderImg.png";
import chatFileDownload from "../../assets/img/chatFileDownload.png";
import fileExchange from "../../assets/img/adminFileExchange.png";

function MChatFileDownModal({ selectedClickSt, clickFileClose, selectedClickFile, downloadFile, nonSelectedClickSt, nonClickFileClose, nonSelectedClickFile, nonDownloadFile }) {

    const renderClickFile = () => {
        const isImage = selectedClickFile.fileUrl.endsWith('.png') || selectedClickFile.fileUrl.endsWith('.PNG')
            || selectedClickFile.fileUrl.endsWith('.jpg')  || selectedClickFile.fileUrl.endsWith('.JPG') ||
            selectedClickFile.fileUrl.endsWith('.jpeg') || selectedClickFile.fileUrl.endsWith('.JPEG')
        selectedClickFile.fileUrl.endsWith('.gif') || selectedClickFile.fileUrl.endsWith('.GIF');
        const isVideo = selectedClickFile.fileUrl.endsWith('.mp4') || selectedClickFile.fileUrl.endsWith('.avi');
        const isPdf = selectedClickFile.fileUrl.endsWith('.pdf');

        if (isImage) {
            return <img style={{maxWidth:"300px", maxHeight:"300px", borderRadius:"4px"}} alt="" src={selectedClickFile.fileUrl}/>;
        }

        if (isVideo) {
            return <video style={{maxWidth:"300px", maxHeight:"300px", borderRadius:"4px"}} controls src={selectedClickFile.fileUrl}></video>;
        }

        if (isPdf) {
            return <object style={{maxWidth:"300px", maxHeight:"300px", borderRadius:"4px"}} type="application/pdf" data={selectedClickFile.fileUrl}></object>;
        }

        let fileExtension = selectedClickFile.fileUrl.split('.').pop().toLowerCase();
        if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
            return <img style={{maxWidth:"300px", maxHeight:"300px", borderRadius:"4px"}} alt="" src={chatExcelImg}/>;
        }

        return <img style={{maxWidth:"300px", maxHeight:"300px", borderRadius:"4px"}} alt="" src={chatFileImg}/>;
    };


    const nonRenderClickFile = () => {
        const isImage = nonSelectedClickFile.fileUrl.endsWith('.png') || nonSelectedClickFile.fileUrl.endsWith('.PNG')
            || nonSelectedClickFile.fileUrl.endsWith('.jpg')  || nonSelectedClickFile.fileUrl.endsWith('.JPG') ||
            nonSelectedClickFile.fileUrl.endsWith('.jpeg') || nonSelectedClickFile.fileUrl.endsWith('.JPEG')
        nonSelectedClickFile.fileUrl.endsWith('.gif') || nonSelectedClickFile.fileUrl.endsWith('.GIF');
        const isVideo = nonSelectedClickFile.fileUrl.endsWith('.mp4') || nonSelectedClickFile.fileUrl.endsWith('.avi');
        const isPdf = nonSelectedClickFile.fileUrl.endsWith('.pdf');

        if (isImage) {
            return <img style={{maxWidth:"300px", maxHeight:"300px", borderRadius:"4px"}} alt="" src={nonSelectedClickFile.fileUrl}/>;
        }

        if (isVideo) {
            return <video style={{maxWidth:"300px", maxHeight:"300px", borderRadius:"4px"}} controls src={nonSelectedClickFile.fileUrl}></video>;
        }

        if (isPdf) {
            return <object style={{maxWidth:"300px", maxHeight:"300px", borderRadius:"4px"}} type="application/pdf" data={nonSelectedClickFile.fileUrl}></object>;
        }

        let fileExtension = nonSelectedClickFile.fileUrl.split('.').pop().toLowerCase();
        if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
            return <img style={{maxWidth:"300px", maxHeight:"300px", borderRadius:"4px"}} alt="" src={chatExcelImg}/>;
        }

        return <img style={{maxWidth:"300px", maxHeight:"300px", borderRadius:"4px"}} alt="" src={chatFileImg}/>;
    };

    return (
        <div className="m-a-file-modal-container">
            <div className="m-a-file-modal-frame">
                <div className="m-a-file-modal-hd-frame">
                    <div className="m-a-file-icon-container">
                        <img alt='' src={commonDownload} className="m-a-file-icon"/>
                    </div>
                    <div className="m-a-flex-jc-start ht-100pc wd-100pc m-l-5">
                        <div className="m-a-chatKey-fileNm-text wd-250">
                            <span className="font-t-title font-s-18">{nonSelectedClickSt ? nonSelectedClickFile && nonSelectedClickFile.fileNm :
                            selectedClickFile ? selectedClickFile && selectedClickFile.fileNm : null}
                            </span>
                            </div>
                    </div>
                    <div className="m-a-file-close">
                        <img onClick={nonSelectedClickSt ? nonClickFileClose : clickFileClose} className="m-a-file-close-img" src={commonClose} alt="close"/>
                    </div>
                </div>

                <div className="m-a-file-content-container user-center" style={{height:"70%"}}>
                    <div>
                        <div className="m-a-file-content-img user-center" style={{height:"70%"}}>
                            {nonSelectedClickSt ? nonRenderClickFile() : renderClickFile()}
                        </div>
                    </div>
                </div>

                {/*<div className="m-a-file-content-fileNm">*/}
                {/*    <div className="m-a-file-content-fileNm-text font-t-head user-center">*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="m-a-file-content-file-exchange-frame user-center">*/}
                {/*</div>*/}

                {nonSelectedClickSt ?
                    <div onClick={() => nonDownloadFile(nonSelectedClickFile.chatKey, nonSelectedClickFile.fileNm, "chat")} className="m-a-file-bt-container">
                        <button className="m-a-colorBt ht-40 m-b-5 wd-80">
                            <div className="m-a-colorBt-text font-t-cont">Download</div>
                        </button>
                    </div>
                    :
                    <div onClick={() => downloadFile(selectedClickFile.chatKey, selectedClickFile.fileNm, "chat")} className="m-a-file-bt-container">
                    <button className="m-a-colorBt ht-40 m-b-5 wd-80">
                    <div className="m-a-colorBt-text font-t-cont">Download</div>
                    </button>
                    </div>
                }

            </div>
        </div>
    )
}

export default MChatFileDownModal
