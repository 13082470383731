import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../../css/web/AdminRegModal.css';
import {AiOutlineMinusSquare, AiOutlinePlusSquare} from "react-icons/ai";
import axios from "axios";
import MsgSelectModal from "./MsgSelectModal";
import {useWebSocket} from "../../../services/WebsocketContext";
import CommonLoading from "./CommonLoading";
import maCateImg from "../../../assets/img/m-a-cate.png";
import orderIMG from "../../../assets/img/mypgPayinfo.png";
import {IoIosArrowDropdown} from "@react-icons/all-files/io/IoIosArrowDropdown";
import {IoIosArrowDropright} from "@react-icons/all-files/io/IoIosArrowDropright";

function AdminRegModal() {

    /** region DataTable */

    const storedOrderData = localStorage.getItem('orderData');
    const initialOrderData = storedOrderData ? JSON.parse(storedOrderData) : null;
    const [orderData, setOrderData] = useState(initialOrderData);
    const [inputType, setInputType] = useState(orderData.dataType === 'payinfo' ? 'shipInfoDTO' : 'box');
    const LCAT = localStorage.getItem('adminToken');
    const [shipcoData, setShipcoData] = useState([]);
    const [selectedShipCompany, setSelectedShipCompany] = useState(orderData.shipco);
    const [selectedDataType, setSelectedDataType] = useState(orderData.dataType);
    const [fedexExData, setFedexExData] = useState([]);
    const [emsExData, setEmsExData] = useState([]);
    const [shipExData, setShipExData] = useState([]);
    const [upsExData, setUpsExData] = useState([]);
    const [kpExData, setKpExData] = useState([]);
    const [asExData, setAsExData] = useState([]);
    const [domeExData, setDomeExData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /** endregion DataTable*/

    /** region Function */

    const [tables, setTables] = useState([]);
    const [tableCount, setTableCount] = useState(1);
    const [showOrdInputs, setShowOrdInputs] = useState(true);
    const [showCostInputs, setShowCostInputs] = useState(false);
    const [showItemInputs, setShowItemInputs] = useState(false);
    const [showBoxInputs, setShowBoxInputs] = useState(true);
    const [showBoxDtPrice, setShowBoxDtPrice] = useState(Array(tableCount).fill(false));
    const handleToggle = (index) => {
        setShowBoxDtPrice(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };
    const [buyValue, setBuyValue] = useState(""); //구매비
    const [codValue, setCodValue] = useState(orderData.payInfoDTO?.[0]?.courierPay || ""); //착불비
    const [domeValue, setDomeValue] = useState(orderData.payInfoDTO?.[0]?.returnFee || ""); //국내반송비
    const [repackValue, setRePackValue] = useState(orderData.payInfoDTO?.[0]?.repackFee || ""); //재포장수수료
    const [agenciesValue, setAgenciesValue] = useState(orderData.payInfoDTO?.[0]?.agencyFee || ""); //대행수수료
    const [vacPackPrice, setVacPackPrice] = useState(orderData.payInfoDTO?.[0]?.comprFee || ""); //압축팩수수료
    const [etcValue, setEtcValue] = useState(""); //기타비용
    const [etcNmValue, setEtcNmValue] = useState(""); //기타비용 내용
    const [etcFeeValue, setEtcFeeValue] = useState(""); //기타수수료
    const [etcFeeNmValue, setEtcFeeNmValue] = useState(""); //기타수수료 내용
    const [fedexFuelValue, setFedexFuelValue] = useState(() => {
        if (orderData && orderData.dataType === 'orderdt') {
            return orderData.recinfoDTO?.countryDTO?.fedexFuelSurcharge || '';
        } else if (orderData && orderData.dataType === 'payinfo') {
            return orderData.orderdtDTO?.recinfoDTO?.countryDTO?.fedexFuelSurcharge || '';
        }
        return '';
    });

    //fedex 유류할증료
    // const [ffeeetcValue, setFfeeEtcValue] = useState(orderData.payInfoDTO?.[0]?.outOfArea || ""); //fedex 서비스 외 지역 비용
    // const [payTypeValue, setPayTypeValue] = useState([]); // PT0, PT2

    const [packSize, setPackSize] = useState('Select');
    const [vacuumPack, setVacuumPack] = useState(!!orderData.payInfoDTO?.[0]?.comprFee);
    const [buyCheckbox, setBuyCheckbox] = useState(false);
    const [codCheckbox, setCodCheckbox] = useState(!!orderData.payInfoDTO?.[0]?.courierPay || false);
    const [domeCheckbox, setDomeCheckbox] = useState(!!orderData.payInfoDTO?.[0]?.returnFee);
    const [repackCheckbox, setRePackCheckbox] = useState(!!orderData.payInfoDTO?.[0]?.repackFee);
    const [agenciesCheckbox, setAgenciesCheckbox] = useState(!!orderData.payInfoDTO?.[0]?.agencyFee);
    const [etcCheckbox, setEtcCheckbox] = useState(false);
    const [etcFeeCheckbox, setEtcFeeCheckbox] = useState(false);
    const [fpackCheckbox, setFpackCheckbox] = useState(false);
    const [ffeeCheckbox, setFfeeCheckbox] = useState(false);
    const [ffeeetcCheckbox, setFfeeEtcCheckbox] = useState(false);
    const [efeeCheckbox, setEfeeCheckbox] = useState(false);
    const [emsAmount, setEmsAmount] = useState(false);
    const [fedexAmount, setFedexAmount] = useState(false);
    const fpackValue = fpackCheckbox ? "Y" : "N";
    const [appliedWeights] = useState({E: "", F: "", S: "", P: "", K: "", A: ""});
    const [boxData, setBoxData] = useState(
        Array.from({length: tableCount}, () => {
            let products = [];
            if (orderData.dataType === "orderdt") {
                products = orderData.productsDTO?.map(item => item.seqnum) || [];
            } else if (orderData.dataType === "payinfo") {
                products = orderData.packListDTO.productsDTO?.map(item => item.seqnum) || [];
            }
            return {
                width: "",
                height: "",
                length: "",
                netWeight: "",
                appliedWeights: {E: "", F: "", S: "", P: "", K: "", A: ""},
                products: products
            };
        })
    );

    const [fedexPayChk, setFedexPayChk] = useState(true);
    const [emsPayChk, setEmsPayChk] = useState(true);
    const [seaPayChk, setSeaPayChk] = useState(true);
    const [upsPayChk, setUpsPayChk] = useState(true);
    const [kpPayChk, setKpPayChk] = useState(true);
    const [asPayChk, setAsPayChk] = useState(true);

    // useEffect(() => {
    //     boxData.forEach(box => {
    //         const totalWeightFedEx = parseFloat(box.netWeight) || box.appliedWeights.F;
    //         const totalWeightUps = parseFloat(box.netWeight) || box.appliedWeights.P;
    //         const totalWeightEms = parseFloat(box.netWeight) || box.appliedWeights.E;
    //         const totalWeightKpAs = parseFloat(box.netWeight) && box.appliedWeights.E;
    //         const totalWeightSHIP = parseFloat(box.netWeight);
    //
    //         if (totalWeightFedEx > 70) {
    //             setFedexPayChk(false);
    //         } else {
    //             setFedexPayChk(true);
    //         }
    //
    //         if (totalWeightUps > 70) {
    //             setUpsPayChk(false);
    //         } else {
    //             setUpsPayChk(true);
    //         }
    //
    //         if (totalWeightEms > 30) {
    //             setEmsPayChk(false);
    //         } else {
    //             setEmsPayChk(true);
    //         }
    //
    //         if (totalWeightSHIP > 20) {
    //             setSeaPayChk(false);
    //         } else {
    //             setSeaPayChk(true);
    //         }
    //
    //         if (totalWeightKpAs > 2) {
    //             setKpPayChk(false);
    //             setAsPayChk(false);
    //         } else {
    //             setKpPayChk(true);
    //             setAsPayChk(true);
    //         }
    //     });
    //     },);

    const [appliedWeightsD] = useState({D: ""});
    const [boxDomeData, setBoxDomeData] = useState([{
        width: "",
        height: "",
        length: "",
        netWeight: "",
        appliedWeightsD: {D: ""}
    },]);

    const prodStPrint = (prodSt) => {
        const prodStStyles = {
            IS00: {color: "#98B0D4", label: "입고대기"},
            IS01: {color: "#98B0D4", label: "결제대기(상품)"},
            IS02: {color: "#98B0D4", label: "결제중(상품)"},
            IS03: {color: "#FFB378", label: "결제완료(상품)"},
            IS04: {color: "#FF8600", label: "구매완료"},
            IS05: {color: "#6EDA60", label: "센터도착"},
            IS06: {color: "#98B0D4", label: "패킹중"},
            IS07: {color: "#98B0D4", label: "결제대기(배송)"},
            IS08: {color: "#98B0D4", label: "결제중(배송)"},
            IS09: {color: "#FFB378", label: "결제완료(배송)"},
            IS10: {color: "#979696", label: "출고완료"},
            IS11: {color: "#E05E6D", label: "반송중"},
            IS12: {color: "#E05E6D", label: "신청취소"},
            IS13: {color: "#98B0D4", label: "구매중"},
            IS14: {color: "#98B0D4", label: "입고중"}
        };

        const style = prodStStyles[prodSt] || {color: "black", label: null};
        return <p style={{margin: "0", color: style.color, fontSize: "14px"}}>{style.label}</p>;
    };

    const [userNm, setUserNm] = useState(orderData.dataType === 'payinfo' ? orderData.orderdtDTO.memberDTO.userName : orderData.memberDTO.userName);
    const [adminInfo, setAdminInfo] = useState([]);
    const selectAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setAdminInfo(response.data[0])
            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })

    function roundToTen(value) {
        return Math.round(value / 10) * 10;
    }

    useEffect(() => {
        selectAdminInfo();
    }, [])

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!adminInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(adminInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [adminInfo.mbNum, isConnected, stompClient]);

    //region CheckBox

    const [checked, setChecked] = useState(false);

    const allcheckBoxChange = () => {
        setChecked(!checked);
        setBoxData(prevBoxData => {
            const updatedBoxData = [...prevBoxData];
            if (!checked) {
                updatedBoxData.forEach(box => {
                    if (box.products.length === 0) {
                        box.products = orderData.productsDTO
                            .filter(item => item.prodSt === "IS05" || item.prodSt === "IS06")
                            .map(item => item.seqnum);
                    }
                });
            } else {
                updatedBoxData.forEach(box => {
                    box.products = [];
                });
            }
            return updatedBoxData;
        });
    };

    const [selectedBoxIndex, setSelectedBoxIndex] = useState(Array(orderData.productsDTO?.length).fill(0));
    useEffect(() => {
        setSelectedBoxIndex(Array(orderData.productsDTO?.length).fill(0));
        setChecked(false);
    }, [orderData.productsDTO]);

    const handleBoxSelection = (productIndex, boxIndex) => {
        setSelectedBoxIndex(prevState => {
            const newState = [...prevState];
            newState[productIndex] = boxIndex;
            return newState;
        });

        setBoxData(prevBoxData => {
            const updatedBoxData = [...prevBoxData];
            const productSeqnum = orderData.productsDTO[productIndex].seqnum;
            const box = updatedBoxData[boxIndex];

            if (box.products.includes(productSeqnum)) {
                box.products = box.products.filter(seqnum => seqnum !== productSeqnum);
            } else {
                box.products.push(productSeqnum);
            }

            return updatedBoxData;
        });
    };

    //endregion

    const handleBoxChange = (index, field, value) => {
        const updatedBoxData = [...boxData];
        const updatedBoxDomeData = [...boxDomeData];

        if (selectedShipCompany === '국내배송') {
            updatedBoxDomeData[index][field] = value;
            setBoxDomeData(updatedBoxDomeData);
        } else {
            if (field === "fpackCheckbox") {
                updatedBoxData[index][field] = !updatedBoxData[index][field];
            } else {
                updatedBoxData[index][field] = value;
            }
            setBoxData(updatedBoxData);
        }
    };

    const handleVacPackSizeChange = (event) => {
        const selectedSize = event.target.value;
        setPackSize(selectedSize);

        if (selectedSize === '소형') {
            setVacuumPack(true);
            setVacPackPrice(10000);
        } else if (selectedSize === '대형') {
            setVacuumPack(true);
            setVacPackPrice(20000);
        } else {
            setVacuumPack(false);
            setVacPackPrice(0);
        }
    };

    const handleFedexValueChange = (index, value) => {
        setBoxData(prevBoxes => {
            const newBoxes = [...prevBoxes];
            newBoxes[index].fedexValue = value === "" ? "" : parseFloat(value);
            return newBoxes;
        });
    };

    const handlePlusClick = () => {
        setTables([...tables, tableCount]);
        setTableCount(tableCount + 1);
        if (selectedShipCompany === '국내배송') {
            const newBoxDomeData = [...boxDomeData, {
                width: "",
                height: "",
                length: "",
                netWeight: "",
                appliedWeightsD: {D: ""}
            }];
            setBoxDomeData(newBoxDomeData);
        } else {
            setBoxData(prevBoxData => [...prevBoxData, {
                width: "",
                height: "",
                length: "",
                netWeight: "",
                appliedWeights: {E: "", F: "", S: "", D: "", P: "", K: "", A: ""},
                products: []
            }]);
        }
    };

    const handleMinusClick = () => {
        if (tables.length > 0) {
            setTables(tables.slice(0, -1));
            setTableCount(tableCount - 1);
            if (selectedShipCompany === '국내배송') {
                setBoxDomeData(boxDomeData.slice(0, -1));
            } else {
                // setBoxData(boxData.slice(0, -1));
                setBoxData(prevBoxData => prevBoxData.slice(0, -1));
            }
        }
    };

    // region Alert

    const [alertShow, setAlertShow] = useState(false);
    const [alertShow3, setAlertShow3] = useState(false);
    const msgConfirmData1 = "비용정보를 등록 하시겠습니까?";
    const msgConfirmData2 = "입력한 정보를 다시한번 확인해주세요.";
    const msgConfirmData5 = "입력된 정보를 저장 하시겠습니까?";
    const msgConfirmData6 = "수정된 정보를 다시한번 확인해주세요.";
    const alert = (() => {
        setAlertShow(true)
    })
    const alert3 = (() => {
        setAlertShow3(true)
    })
    const modalCancel1 = useCallback(() => {
        setAlertShow(false);
    })
    const modalCancel3 = useCallback(() => {
        setAlertShow3(false);
    })

    // endregion

    // region 부피중량 / 적용중량 계산

    const [boxEmsResultFees, setBoxEmsResultFees] = useState(Array(boxData.length).fill(0));
    const [boxFedexResultFees, setBoxFedexResultFees] = useState(Array(boxData.length).fill(0));
    const [boxSeaResultFees, setBoxSeaResultFees] = useState(Array(boxData.length).fill(0));
    // const [boxDhlResultFees, setBoxDhlResultFees] = useState(Array(boxData.length).fill(0));
    const [boxUpsResultFees, setBoxUpsResultFees] = useState(Array(boxData.length).fill(0));
    const [boxKpResultFees, setBoxKpResultFees] = useState(Array(boxData.length).fill(0));
    const [boxAsResultFees, setBoxAsResultFees] = useState(Array(boxData.length).fill(0));

    const getShippingFees = () => {
        const countryDTO = orderData.recinfoDTO && orderData.recinfoDTO.countryDTO
            ? orderData.recinfoDTO.countryDTO
            : (orderData.appRecDTO && orderData.appRecDTO.countryDTO
                ? orderData.appRecDTO.countryDTO
                : null);
        return {
            emsFee: countryDTO ? parseFloat(countryDTO.emsFee || 0) : 0,
            fedexFee: countryDTO ? parseFloat(countryDTO.fedexFee || 0) : 0,
            seaFee: countryDTO ? parseFloat(countryDTO.seaFee || 0) : 0,
            // dhlFee: countryDTO ? parseFloat(countryDTO.seaFee || 0) : 0,
            upsFee: countryDTO ? parseFloat(countryDTO.upsFee || 0) : 0,
            kpFee: countryDTO ? parseFloat(countryDTO.kpFee || 0) : 0,
            asFee: countryDTO ? parseFloat(countryDTO.asFee || 0) : 0
        };
    };

    const {emsFee, fedexFee, seaFee, upsFee, kpFee, asFee} = getShippingFees();

    const [emsFeeState, setEmsFee] = useState(emsFee);
    const [fedexFeeState, setFedexFee] = useState(fedexFee);
    const [seaFeeState, setSeaFee] = useState(seaFee);
    // const [dhlFeeState, setDhlFee] = useState(dhlFee);
    const [upsFeeState, setUpsFee] = useState(upsFee);
    const [kpFeeState, setKpFee] = useState(kpFee);
    const [asFeeState, setAsFee] = useState(asFee);

    const appliedWeightE = parseFloat(appliedWeights.E);
    const [emsResultFee, setEmsResultFee] = useState(emsFee * appliedWeightE * 10);

    const appliedWeightF = parseFloat(appliedWeights.F);
    const [fedexResultFee, setFedexResultFee] = useState(fedexFee * appliedWeightF * 10);

    const appliedWeightS = parseFloat(appliedWeights.S);
    const [seaResultFee, setSeaResultFee] = useState(seaFee * appliedWeightS * 10);

    // const appliedWeightD = parseFloat(appliedWeights.D);
    // const [dhlResultFee, setDhlResultFee] = useState(dhlFee * appliedWeightD * 10);

    const appliedWeightP = parseFloat(appliedWeights.P);
    const [upsResultFee, setUpsResultFee] = useState(upsFee * appliedWeightP * 10);

    const appliedWeightK = parseFloat(appliedWeights.K);
    const [kpResultFee, setKpResultFee] = useState(kpFee * appliedWeightK * 10);

    const appliedWeightA = parseFloat(appliedWeights.A);
    const [asResultFee, setAsResultFee] = useState(asFee * appliedWeightA * 10);

    const getVolumeWeightFedex = (width, height, length) => {
        return ((width * height * length) / 5000).toFixed(2);
    };

    const getVolumeWeightEms = (width, height, length) => {
        return ((width * height * length) / 6000).toFixed(2);
    };

    // const getVolumeWeightDhl = (width, height, length) => {
    //     return ((width * height * length) / 5000).toFixed(2);
    // };

    const getVolumeWeightUps = (width, height, length) => {
        return ((width * height * length) / 6000).toFixed(2);
    };

    const getVolumeWeightDome = (width, height, length) => {
        return ((width + height + length) / 5000).toFixed(1);
    };

    const fedexThresholds = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5,
        16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
        61, 62, 63, 64, 65, 66, 67, 68, 68, 70];

    const emsThresholds = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14,
        14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27, 27.5,
        28, 28.5, 29, 29.5, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
        61, 62, 63, 64, 65, 66, 67, 68, 68, 70];

    const upsThresholds = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14,
        14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 21, 22, 23, 24, 25, 26, 27,
        28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
        61, 62, 63, 64, 65, 66, 67, 68, 68, 70];

    const seaThresholds = [1, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20];
    const kpAsThresholds = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1,
        1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2];

    const domeThresholds = [3, 5, 10, 20, 25];

    const applyWeightBands = (volumeWeight, thresholds) => {
        return thresholds.find(threshold => volumeWeight <= threshold) || thresholds[thresholds.length - 1];
    };

    const applyWeightDomeBands = (volumeWeight, thresholds) => {
        return thresholds.find(threshold => volumeWeight <= threshold) || thresholds[thresholds.length - 1];
    };

    const getAmountForWeight = (appliedWeight, data) => {
        const weightColumn = `w${Math.round(appliedWeight * 1000)}`;
        const dataObject = data.find(item => item[weightColumn] !== undefined);
        return dataObject ? dataObject[weightColumn] : 0;
    };

    const getAmountForDomeWeight = (appliedWeight, data) => {
        const weightColumn = `w${Math.round(appliedWeight * 1000)}`;
        const dataObject = data.find(item => item[weightColumn] !== undefined);
        return dataObject ? dataObject[weightColumn] : 0;
    };

    useEffect(() => {
        const updatedBoxData = [...boxData];
        let totalEmsFees = Array(updatedBoxData.length).fill(0);
        let totalFedexFees = Array(updatedBoxData.length).fill(0);
        let totalSeaFees = Array(updatedBoxData.length).fill(0);
        // let totalDhlFees = Array(updatedBoxData.length).fill(0);
        let totalUpsFees = Array(updatedBoxData.length).fill(0);
        let totalKpFees = Array(updatedBoxData.length).fill(0);
        let totalAsFees = Array(updatedBoxData.length).fill(0);

        for (let i = 0; i < updatedBoxData.length; i++) {
            const width = parseFloat(updatedBoxData[i].width);
            const height = parseFloat(updatedBoxData[i].height);
            const length = parseFloat(updatedBoxData[i].length);
            const netWeight = parseFloat(updatedBoxData[i].netWeight);

            if (!isNaN(width) && !isNaN(height) && !isNaN(length)) {
                const volumeWeightF = ((width * length * height) / 5000).toFixed(2);
                const volumeWeightE = ((width * length * height) / 6000).toFixed(2);
                const volumeWeightP = ((width * length * height) / 6000).toFixed(2);
                // const volumeWeightD = ((width * length * height) / 5000).toFixed(2);

                const weightF = applyWeightBands(Math.max(netWeight, volumeWeightF), fedexThresholds);
                const weightP = applyWeightBands(Math.max(netWeight, volumeWeightP), upsThresholds);
                const weightE = applyWeightBands(Math.max(netWeight, volumeWeightE), emsThresholds);
                // const weightD = applyWeightBands(Math.max(netWeight, volumeWeightD), dhlThresholds);
                const weightK = applyWeightBands(Math.max(netWeight), kpAsThresholds);
                const weightA = applyWeightBands(Math.max(netWeight), kpAsThresholds);
                const weightS = applyWeightBands(Math.max(netWeight), seaThresholds);

                updatedBoxData[i].appliedWeights = {
                    F: weightF,
                    P: weightP,
                    E: weightE,
                    K: weightK,
                    A: weightA,
                    S: weightS
                };

                totalEmsFees[i] = emsFee * weightE * 10;
                totalFedexFees[i] = fedexFee * weightF * 10;
                totalSeaFees[i] = seaFee * weightS * 10;
                // totalDhlFees[i] = dhlFee * weightD * 10;
                totalUpsFees[i] = upsFee * weightP * 10;
                totalKpFees[i] = kpFee * weightK * 10;
                totalAsFees[i] = asFee * weightA * 10;
            }
        }
        setBoxFedexResultFees(totalFedexFees);
        setBoxUpsResultFees(totalUpsFees);
        setBoxEmsResultFees(totalEmsFees);
        // setBoxDhlResultFees(totalDhlFees);
        setBoxKpResultFees(totalKpFees);
        setBoxAsResultFees(totalAsFees);
        setBoxSeaResultFees(totalSeaFees);

        if (JSON.stringify(updatedBoxData) !== JSON.stringify(boxData)) {
            setBoxData(updatedBoxData);
        }
    }, [boxData]);

    useEffect(() => {
        const updatedBoxDomeData = [...boxDomeData];

        for (let i = 0; i < updatedBoxDomeData.length; i++) {
            const width = parseFloat(updatedBoxDomeData[i].width);
            const height = parseFloat(updatedBoxDomeData[i].height);
            const length = parseFloat(updatedBoxDomeData[i].length);
            const netWeight = parseFloat(updatedBoxDomeData[i].netWeight);

            if (!isNaN(width) && !isNaN(height) && !isNaN(length)) {
                const weightD = applyWeightDomeBands(Math.max(netWeight), domeThresholds);

                updatedBoxDomeData[i].appliedWeightsD = {D: weightD};
            }
        }
        if (JSON.stringify(updatedBoxDomeData) !== JSON.stringify(boxDomeData)) {
            setBoxDomeData(updatedBoxDomeData);
        }
    }, [boxDomeData]);

    // endregion

    const calculateTotalFEDEXCost = () => {
        let totalCost = 0;
        let totalFuelFees = 0;

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 30) {
                totalCost += roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData));
                totalFuelFees += roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData) * (fedexFuelValue / 100));
            } else {
                totalCost += Math.round(parseFloat(box.fedexValue || 0));
            }
        });

        const totalFees = boxFedexResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        return (totalCost + totalFees + totalFuelFees).toLocaleString();
    };

    //페덱스 30kg 초과시 ㄱ
    const calculateFedexPrice = (weight, groupCode) => {
        if (weight > 30) {
            if (groupCode === 'U') { //뉴질랜드, 호주
                if (groupCode === 'U' && orderData.recinfoDTO.countryDTO.countryCode === 'NZ') {
                    const roundedWeight = Math.ceil(weight);
                    return roundedWeight * 14900;
                } else if (groupCode === 'U' && orderData.recinfoDTO.countryDTO.countryCode === 'AU') {
                    return weight * 7900;
                }
            }
            if (groupCode === 'M') { //독일
                if (groupCode === 'U' && orderData.recinfoDTO.countryDTO.countryCode === 'DE') {
                    const roundedWeight = Math.ceil(weight);
                    return roundedWeight * 14200;
                }
            }
            if (groupCode === 'F') { //미국, 캐나다, 멕시코
                const roundedWeight = Math.ceil(weight);
                return roundedWeight * 11900;
            }
        }
        return 0;
    };

    const getFedexCalculatedValue = (box, fedexExData) => {
        const groupCode = fedexExData && fedexExData[0] ? fedexExData[0].appGroup : null;
        const weight = box.appliedWeights && box.appliedWeights.F ? box.appliedWeights.F : 0;
        return calculateFedexPrice(weight, groupCode);
    };

    const calculateTotalUPSCost = () => {
        let totalCost = 0;
        let totalFees = boxUpsResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 70) {
                totalCost += getAmountForWeight(box.appliedWeights.P, upsExData);
            } else {
                totalFees -= boxUpsResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);
            }
        });

        return (totalCost + totalFees).toLocaleString();
    };

    const calculateTotalEMSCost = () => {
        let totalCost = 0;
        let totalFees = boxEmsResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 30) {
                totalCost += getAmountForWeight(box.appliedWeights.E, emsExData);
            } else {
                totalFees -= boxEmsResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);
            }
        });

        return (totalCost + totalFees).toLocaleString();
    };

    const calculateTotalSHIPCost = () => {
        let totalCost = 0;
        let totalFees = boxSeaResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 20) {
                totalCost += getAmountForWeight(box.appliedWeights.S, shipExData);
            } else {
                totalFees -= boxSeaResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);
            }
        });

        return (totalCost + totalFees).toLocaleString();
    };

    const calculateTotalKPACKCost = () => {
        let totalCost = 0;
        let totalFees = boxKpResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 30) {
                totalCost += getAmountForWeight(box.appliedWeights.K, kpExData);
            } else {
                totalFees -= boxKpResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);
            }
        });

        return (totalCost + totalFees).toLocaleString();
    };

    const calculateTotalAIRSCost = () => {
        let totalCost = 0;
        let totalFees = boxAsResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 30) {
                totalCost += getAmountForWeight(box.appliedWeights.A, asExData);
            } else {
                totalFees -= boxAsResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);
            }
        });

        return (totalCost + totalFees).toLocaleString();
    };

    const calculateTotalDOMECost = () => {
        let totalCost = 0;
        boxDomeData.forEach(box => {
            if (parseFloat(box.netWeight) <= 30) {
                totalCost += getAmountForDomeWeight(box.appliedWeightsD.D, domeExData);
            }
        });
        return (totalCost).toLocaleString();
    };

    /** endregion */

    /** region Event */

    useEffect(() => {
        shipCompanySelect();
        fedexExrSelect();
        upsExrSelect();
        emsExrSelect();
        shipExrSelect();
        kpackExrSelect();
        airSExrSelect();
    }, []);

    const selectPackSize = (price) => {
        if (price === 10000) {
            setPackSize('소형');
        } else if (price === 20000) {
            setPackSize('대형');
        } else {
            setPackSize('Select');
        }
    };

    useEffect(() => {
        if (vacPackPrice !== '') {
            selectPackSize(parseInt(vacPackPrice));
        }
    }, [vacPackPrice]);


    const shipCompanySelect = () => {
        axios.get('/shipcompany/shipcoselect')
            .then((response) => {
                const shipType = response.data.filter(cData => cData.shippingCompanyCode === '1');
                setShipcoData(shipType);
            }, [])
            .catch(() => {
            })
    }

    const fedexExrSelect = () => {
        axios.get('/shipinfo/fedexexrdata')
            .then((response) => {
                const countryCode = orderData.type === 'orderdt'
                    ? orderData.recinfoDTO.countryDTO.countryCode
                    : orderData.type === 'payinfo'
                        ? orderData.appRecDTO.countryDTO.countryCode
                        : null;

                if (!countryCode) return;

                const fCountryCode = response.data.filter(cData => {
                    return cData.appGroup === countryCode ||
                        (cData.appGroup === 'A' && countryCode === 'MO') ||
                        (cData.appGroup === 'D' && ['KH', 'LA', 'MN'].includes(countryCode)) ||
                        (cData.appGroup === 'F' && ['CA', 'US', 'MX'].includes(countryCode)) ||
                        (cData.appGroup === 'G' && ['AT', 'CZ', 'DK', 'FI', 'GR', 'HU', 'IE', 'NO', 'PL', 'PT', 'SE', 'CH'].includes(countryCode)) ||
                        (cData.appGroup === 'H' && ['IL', 'TR', 'RU', 'UA', 'UZ'].includes(countryCode)) ||
                        (cData.appGroup === 'I' && ['AR', 'BR', 'CL', 'PE'].includes(countryCode)) ||
                        (cData.appGroup === 'J' && ['BD', 'MA', 'QA', 'AE', 'JO', 'SA'].includes(countryCode)) ||
                        (cData.appGroup === 'M' && ['BE', 'DE', 'IT', 'NL', 'ES', 'GB', 'FR'].includes(countryCode)) ||
                        (cData.appGroup === 'N' && countryCode === 'VN') ||
                        (cData.appGroup === 'O' && countryCode === 'IN') ||
                        (cData.appGroup === 'P' && countryCode === 'JP') ||
                        (cData.appGroup === 'Q' && countryCode === 'MY') ||
                        (cData.appGroup === 'R' && countryCode === 'TH') ||
                        (cData.appGroup === 'S' && countryCode === 'PH') ||
                        (cData.appGroup === 'T' && countryCode === 'ID') ||
                        (cData.appGroup === 'U' && ['AU', 'NZ'].includes(countryCode)) ||
                        (cData.appGroup === 'V' && countryCode === 'HK') ||
                        (cData.appGroup === 'W' && countryCode === 'CN') ||
                        (cData.appGroup === 'X' && countryCode === 'TW') ||
                        (cData.appGroup === 'Y' && countryCode === 'SG');
                });
                setFedexExData(fCountryCode);
            })
            .catch((error) => {
                console.error("err:", error);
            });
    }

    const upsExrSelect = () => {
        axios.get('/shipinfo/upsexrdata')
            .then((response) => {
                const countryCode = orderData.type === 'orderdt'
                    ? orderData.recinfoDTO.countryDTO.countryCode
                    : orderData.type === 'payinfo'
                        ? orderData.appRecDTO.countryDTO.countryCode
                        : null;

                if (!countryCode) return;

                const sCountryCode = response.data.filter(cData => {
                    return cData.appUpsCode === countryCode ||
                        (["CN", "HK", "MO", "TW"].includes(countryCode) && cData.appUpsCode === 'Z1') ||

                        (["BN", "KH", "JP", "LA", "MY", "SG", "TH", "VN"].includes(countryCode) && cData.appUpsCode === 'Z2') ||

                        (["AL", "AM", "AU", "AT", "AZ", "BH", "BE", "BG", "BA", "CA", "HR", "CZ", "DK", "GR",
                            "HU", "IS", "IN", "ID", "IE", "IT", "LV", "LU", "MV", "MD", "MN", "NL", "NZ", "MK", "NO",
                            "PG", "PH", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "CH", "TR", "US", "AE", "GB", "BY", "BT"
                        ].includes(countryCode) && cData.appUpsCode === 'Z3') ||

                        (["DZ", "AO", "BS", "BB", "BJ", "BM", "BO", "BW", "BF", "CM", "CV", "TD", "CL", "CO",
                            "CR", "CI", "DJ", "EG", "ER", "ET", "FJ", "GT", "GY", "HT", "HN", "SV", "IL", "JM", "JO",
                            "KW", "LS", "LR", "ML", "MX", "MZ", "NI", "NE", "NG", "OM", "PK", "PA", "PY", "QA", "RW", "TZ", "TG", "ZM"
                        ].includes(countryCode) && cData.appUpsCode === 'Z4') ||

                        (["RU"].includes(countryCode) && cData.appUpsCode === 'ZR') ||

                        (["AF", "AD", "AG", "AR", "AW", "BD", "BZ", "BR", "BI", "CF", "CG", "DM", "DO", "TL", "GA",
                            "GM", "GH", "GI", "GL", "GD", "GP", "GU", "GF", "GN", "GW", "IQ", "KZ", "KE", "SZ", "KG",
                            "LB", "LI", "LT", "MG", "MW", "MT", "MP", "MQ", "MR", "MU", "YT", "FM", "MC", "MS", "MA",
                            "NA", "NP", "NC", "NF", "PW", "PE", "ZA", "RE", "ME", "KN", "LC", "VC", "AS", "SM", "SA",
                            "SN", "SC", "SL", "LK", "SR", "RS", "TO", "TN", "UG", "UA", "UY", "UZ", "VU", "VE", "VI", "VG", "WF", "WS", "ZW"
                        ].includes(countryCode) && cData.appUpsCode === 'Z5');
                });
                setUpsExData(sCountryCode);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const emsExrSelect = () => {
        axios.get('/shipinfo/emsexrdata')
            .then((response) => {
                const countryCode = orderData.type === 'orderdt' ? orderData.recinfoDTO.countryDTO.countryCode
                    : orderData.type === 'payinfo' ? orderData.appRecDTO.countryDTO.countryCode : null;
                if (!countryCode) return;

                const eCountryCode = response.data.filter(cData => {
                    return cData.countryDTO.countryCode === countryCode ||
                        ((countryCode === 'LA' || countryCode === 'MO') && cData.seqNum === 21) ||
                        (countryCode === 'BD' && cData.seqNum === 22) ||
                        (['IE', 'NL', 'PL', 'CH', 'IL', 'AE'].includes(countryCode) && cData.seqNum === 23) ||
                        (['PE', 'CL', 'MX'].includes(countryCode) && cData.seqNum === 24);
                });

                setEmsExData(eCountryCode);
            })
            .catch((error) => {
                console.error("Error: ", error);
            });
    }

    const shipExrSelect = () => {
        axios.get('/shipinfo/shipexrdata')
            .then((response) => {
                const countryCode = orderData.type === 'orderdt'
                    ? orderData.recinfoDTO.countryDTO.countryCode
                    : orderData.type === 'payinfo'
                        ? orderData.appRecDTO.countryDTO.countryCode
                        : null;

                if (!countryCode) return;

                const sCountryCode = response.data.filter(cData => {
                    return cData.appShipCode === countryCode ||
                        (['CN', 'HK', 'JP', 'TW'].includes(countryCode) && cData.appShipCode === 'Z1') ||
                        (['BD', 'ID', 'MY', 'PH', 'SG', 'VN'].includes(countryCode) && cData.appShipCode === 'Z2') ||
                        (['AU', 'CA', 'DE', 'ES', 'FR', 'GB', 'IE', 'NL', 'NZ', 'PL', 'US'].includes(countryCode) && cData.appShipCode === 'Z3') ||
                        (['PE', 'CL', 'MX'].includes(countryCode) && cData.appShipCode === 'Z4');
                });
                setShipExData(sCountryCode);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const kpackExrSelect = () => {
        axios.get('/shipinfo/kpexrdata')
            .then((response) => {
                const countryCode = orderData.type === 'orderdt'
                    ? orderData.recinfoDTO.countryDTO.countryCode
                    : orderData.type === 'payinfo'
                        ? orderData.appRecDTO.countryDTO.countryCode
                        : null;

                if (!countryCode) return;

                const kCountryCode = response.data.filter(cData => {
                    return cData.countryDTO.countryCode === countryCode;
                });

                setKpExData(kCountryCode);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const airSExrSelect = () => {
        axios.get('/shipinfo/airsexrdata')
            .then((response) => {
                const countryCode = orderData.type === 'orderdt'
                    ? orderData.recinfoDTO.countryDTO.countryCode
                    : orderData.type === 'payinfo'
                        ? orderData.appRecDTO.countryDTO.countryCode
                        : null;

                if (!countryCode) return;

                const aCountryCode = response.data.filter(cData => {
                    return cData.appAsCode === countryCode ||
                        (["CN", "HK", "JP", "MO", "TW"].includes(countryCode) && cData.appAsCode === 'Z1') ||
                        (["BD", "BN", "KH", "TL", "ID", "LA", "MY", "MN", "MM", "PH", "SG", "TW", "VN"].includes(countryCode) && cData.appAsCode === 'Z2') ||
                        (["AF", "AL", "AD", "AM", "AU", "AT", "AZ", "BH", "BY", "BE", "BT", "BG", "BA",
                            "CA", "CX", "CC", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "GE", "DE", "GI", "GR",
                            "GL", "GU", "HU", "IS", "IL", "IT", "JO", "KZ", "KW", "KG", "LV", "LB", "LI", "LT",
                            "LU", "MV", "MT", "MP", "MD", "MC", "NP", "NL", "NZ", "NU", "NF", "MK", "NO", "OM",
                            "PK", "PG", "PL", "PT", "QA", "RO", "RU", "ME", "SM", "SA", "SK", "SI", "ES", "LK",
                            "SE", "CH", "RS", "TR", "US", "UA", "AE", "GB", "UZ", "VA"
                        ].includes(countryCode) && cData.appAsCode === 'Z3') ||
                        (["DZ", "AO", "AG", "AR", "AW", "BS", "BB", "BZ", "BJ", "BM", "BW", "BR", "BF", "BI",
                            "CM", "CV", "CF", "TD", "CL", "CO", "CG", "CR", "CI", "CU", "DJ", "DM", "DO", "CG", "EG",
                            "GQ", "ER", "ET", "FJ", "FR", "GA", "GM", "GH", "GD", "GP", "GT", "GF", "GN", "GW", "GY",
                            "HN", "JM", "KE", "SZ", "LS", "LR", "MG", "MW", "ML", "MQ", "MR", "MU", "YT", "MX", "FM",
                            "MS", "MA", "MZ", "NA", "NR", "NC", "NI", "NE", "NG", "PW", "PE", "ZA", "RE", "RW", "KN",
                            "AS", "ST", "SN", "SC", "SB", "SO", "SD", "SR", "TZ", "TG", "TO", "TN", "UG", "UY", "VU",
                            "VE", "VI", "VG", "WF", "WS", "ZM", "ZW",
                        ].includes(countryCode) && cData.appAsCode === 'Z4');
                });
                setAsExData(aCountryCode);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    useEffect(() => {
        const fetchDomeExData = async () => {
            try {
                const response = await axios.get("/shipinfo/domeexrdata");
                const volumeRanges = {
                    "V08": {min: 1, max: 80},
                    "V10": {min: 81, max: 100},
                    "V12": {min: 101, max: 120},
                    "V14": {min: 121, max: 140},
                    "V16": {min: 141, max: 160},
                    "V20": {min: 161, max: 200},
                };
                const totalVolume = parseFloat(boxDomeData[0].width) + parseFloat(boxDomeData[0].height) + parseFloat(boxDomeData[0].length);

                const filteredData = response.data.filter(item => {
                    const range = volumeRanges[item.volumes];
                    return totalVolume >= range.min && totalVolume <= range.max;
                });

                setDomeExData(filteredData);
            } catch (error) {
                console.error("Error: ", error);
            }
        };
        fetchDomeExData();
    }, [boxDomeData]);

    const boxesData = boxData.map((box, index) => ({
        wid: parseFloat(box.width || 0),
        ver: parseFloat(box.height || 0),
        hei: parseFloat(box.length || 0),
        weight: parseFloat(box.netWeight || 0),
        fVweight: parseFloat(getVolumeWeightFedex(box.width, box.height, box.length || 0)),
        fApyWeight: parseFloat(box.appliedWeights.F || 0),
        fedexPack: box.fpackCheckbox ? '1' : '0',
        eVweight: parseFloat(getVolumeWeightEms(box.width, box.height, box.length || 0)),
        eApyWeight: parseFloat(box.appliedWeights.E || 0),
        sApyWeight: parseFloat(box.netWeight || 0),
        // dhVweight: parseFloat(getVolumeWeightEms(box.width, box.height, box.length  || 0)),
        // dhApyWeight: parseFloat(box.appliedWeights.D || 0),
        pVweight: parseFloat(getVolumeWeightEms(box.width, box.height, box.length || 0)),
        pApyWeight: parseFloat(box.appliedWeights.P || 0),
        kApyWeight: parseFloat(box.netWeight || 0),
        aApyWeight: parseFloat(box.netWeight || 0),
        dVweight: 0,
        dApyWeight: 0,
    }));

    const boxesDomeData = boxDomeData.map((box, index) => ({
        wid: parseFloat(box.width || 0),
        ver: parseFloat(box.height || 0),
        hei: parseFloat(box.length || 0),
        weight: parseFloat(box.netWeight || 0),
        fVweight: 0,
        fApyWeight: 0,
        fedexPack: '0',
        eVweight: 0,
        eApyWeight: 0,
        sApyWeight: 0,
        dhVweight: 0,
        dhApyWeight: 0,
        pVweight: 0,
        pApyWeight: 0,
        kApyWeight: 0,
        aApyWeight: 0,
        dVweight: (!isNaN(parseFloat(box.width)) ? parseFloat(box.width) : 0) + (!isNaN(parseFloat(box.height)) ? parseFloat(box.height) : 0) + (!isNaN(parseFloat(box.length)) ? parseFloat(box.length) : 0),
        dApyWeight: parseFloat(box.appliedWeightsD.D || 0),
    }));

    const shipModiInfoData = {
        seqNum: (orderData.dataType === 'payinfo') ? orderData.shipInfoDTO.seqNum : undefined,
    };

    const shipInfoData = boxesData.map((box, index) => ({
        boxNum: `B${(index + 1).toString().padStart(4, '0')}`,
        wid: box.wid,
        ver: box.ver,
        hei: box.hei,
        weight: box.weight,
        fedexWeight: box.fVweight,
        fedexApyWeight: box.fApyWeight,
        fedexPack: box.fedexPack,
        emsWeight: box.eVweight,
        emsApyWeight: box.eApyWeight,
        seaApyWeight: box.sApyWeight,
        dhlWeight: 0,
        dhlApyWeight: 0,
        upsWeight: box.pVweight,
        upsApyWeight: box.pApyWeight,
        kpackApyWeight: box.kApyWeight,
        airSApyWeight: box.aApyWeight
    }));

    const shipInfoDomeData = boxesDomeData.map((box, index) => ({
        boxNum: `B${(index + 1).toString().padStart(4, '0')}`,
        wid: box.wid,
        ver: box.ver,
        hei: box.hei,
        weight: box.weight,
        fedexWeight: box.fVweight,
        fedexApyWeight: box.fApyWeight,
        fedexPack: box.fedexPack,
        emsWeight: box.eVweight,
        emsApyWeight: box.eApyWeight,
        seaApyWeight: box.sApyWeight,
        dhlWeight: 0,
        dhlApyWeight: 0,
        upsWeight: box.pVweight,
        upsApyWeight: box.pApyWeight,
        kpackApyWeight: box.kApyWeight,
        airSApyWeight: box.aApyWeight,
        domeWeight: box.dVweight,
        domeApyWeight: box.dApyWeight,
    }));

    const orderDtData = {
        seqnum: (orderData.dataType === 'orderdt') ? orderData.seqnum : orderData.orderdtDTO.seqnum,
        orderStatus: 'OS02'
    };

    const payInfoBuyData = {
        ttProdPrice: buyValue,
    };

    const tEp = (Number(etcValue) || 0) + (Number(codValue) || 0) + (Number(domeValue) || 0);
    const tEf = (Number(etcFeeValue) || 0) + (Number(repackValue) || 0) + (Number(agenciesValue) || 0) + (Number(vacPackPrice) || 0);

    const payInfoEtcData = {
        courierPay: codValue ? codValue : 0,//착불비
        returnFee: domeValue ? domeValue : 0, //인천반송비
        repackFee: repackValue ? repackValue : 0, //재포장수수료
        agencyFee: agenciesValue ? agenciesValue : 0, //대행수수료
        comprFee: vacPackPrice ? vacPackPrice : 0, //압축팩수수료
        etcPrice: etcValue ? etcValue : 0, //기타비용
        etcPriceNm: etcNmValue ? etcNmValue : '', //기타비용명
        ttEtcPrice: tEp, //총기타비용
        etcFee: etcFeeValue ? etcFeeValue : 0, //기타수수료
        etcFeeNm: etcFeeNmValue ? etcFeeNmValue : '', //기타수수료명
        ttEtcFee: tEf, //총기타수수료
        lastPrice: tEp + tEf
    };

    const [initialPayInfoBoxData, setInitialPayInfoBoxData] = useState();

    const getPayInfoBoxData = (selectedShipCompany, boxData) => {
        return boxData.map((box, index) => {
            const fedexPrice = parseFloat(box.netWeight) > 30 || box.appliedWeights.F > 30
                ? getFedexCalculatedValue(box, fedexExData)
                : roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData));
            const fedexFuelSurch = parseFloat(box.netWeight) > 30 || box.appliedWeights.F > 30 ? 0 : Math.round((fedexPrice * (fedexFuelValue / 100)) / 10) * 10;
            const fedexAddFee = parseInt(boxFedexResultFees[index]);
            const ttFedexPrice = fedexPrice + fedexFuelSurch + fedexAddFee;

            const upsPrice = parseFloat(box.netWeight) > 30 || box.appliedWeights.P > 30 ? 0 : getAmountForWeight(box.appliedWeights.P, upsExData);
            const upsAddFee = parseFloat(box.netWeight) > 30 || box.appliedWeights.P > 30 ? 0 : parseInt(boxUpsResultFees[index]);
            const ttUpsPrice = upsPrice + upsAddFee;

            const emsPrice = parseFloat(box.netWeight) > 30 || box.appliedWeights.E > 30 ? 0 : getAmountForWeight(box.appliedWeights.E, emsExData);
            const emsAddFee = parseFloat(box.netWeight) > 30 || box.appliedWeights.E > 30 ? 0 : parseInt(boxEmsResultFees[index]);
            const ttEmsPrice = emsPrice + emsAddFee;

            const shipPrice = parseFloat(box.netWeight) > 20 ? 0 : getAmountForWeight(box.appliedWeights.S, shipExData);
            const seaAddFee = parseFloat(box.netWeight) > 20 ? 0 : parseInt(boxSeaResultFees[index]);
            const ttShipPrice = shipPrice + seaAddFee;

            const kPackPrice = parseFloat(box.netWeight) > 2 && box.appliedWeights.E > 2 ? 0 : getAmountForWeight(box.appliedWeights.K, kpExData);
            const kpAddFee = parseFloat(box.netWeight) > 2 ? 0 : parseInt(boxKpResultFees[index]);
            const ttKPackPrice = kPackPrice + kpAddFee;

            const asPrice = parseFloat(box.netWeight) > 2 && box.appliedWeights.E > 2 ? 0 : getAmountForWeight(box.appliedWeights.A, asExData);
            const asAddFee = parseFloat(box.netWeight) > 2 ? 0 : parseInt(boxAsResultFees[index]);
            const ttAirSPrice = asPrice + asAddFee;

            const ttDomPrice = 0;
            let lastPrice;

            const finalFedexPrice = fedexPayChk ? ttFedexPrice : 0;
            const finalUpsPrice = upsPayChk ? ttUpsPrice : 0;
            const finalEmsPrice = emsPayChk ? ttEmsPrice : 0;
            const finalShipPrice = seaPayChk ? ttShipPrice : 0;
            const finalKPackPrice = kpPayChk ? ttKPackPrice : 0;
            const finalAirSPrice = asPayChk ? ttAirSPrice : 0;

            const defFedexPrice = fedexPayChk ? fedexPrice : 0;
            const defUpsPrice = upsPayChk ? upsPrice : 0;
            const defEmsPrice = emsPayChk ? emsPrice : 0;
            const defShipPrice = seaPayChk ? shipPrice : 0;
            const defKPackPrice = kpPayChk ? kPackPrice : 0;
            const defAirSPrice = asPayChk ? asPrice : 0;

            let shipCompanyCode;
            switch (selectedShipCompany) {
                case "FedEx":
                    shipCompanyCode = "D01";
                    break;
                case "UPS":
                    shipCompanyCode = "D11";
                    break;
                case "EMS":
                    shipCompanyCode = "D02";
                    break;
                case "K-Packet":
                    shipCompanyCode = "D13";
                    break;
                case "항공소형":
                    shipCompanyCode = "D14";
                    break;
                case "SHIP":
                    shipCompanyCode = "D03";
                    break;
                case "국내배송":
                    shipCompanyCode = "D00";
                    break;
                default:
                    shipCompanyCode = "";
            }

            switch (selectedShipCompany) {
                case "FedEx":
                    lastPrice = finalFedexPrice;
                    break;
                case "UPS":
                    lastPrice = finalUpsPrice;
                    break;
                case "EMS":
                    lastPrice = finalEmsPrice;
                    break;
                case "K-Packet":
                    lastPrice = finalKPackPrice;
                    break;
                case "항공소형":
                    lastPrice = finalAirSPrice;
                    break;
                case "SHIP":
                    lastPrice = finalShipPrice;
                    break;
                default:
                    lastPrice = 0;
            }

            return {
                fedexPrice: defFedexPrice,
                upsPrice: defUpsPrice,
                emsPrice: defEmsPrice,
                seaPrice: defShipPrice,
                dhlPrice: 0,
                kpackPrice: defKPackPrice,
                airSPrice: defAirSPrice,
                fedexFuelSurch: fedexFuelSurch,
                ttFedexPrice: finalFedexPrice,
                ttUpsPrice: finalUpsPrice,
                ttEmsPrice: finalEmsPrice,
                ttSeaPrice: finalShipPrice,
                ttDhlPrice: 0,
                dhlFuelSurch: 0,
                upsFuelSurch: 0,
                ttKPackPrice: finalKPackPrice,
                ttAirSPrice: finalAirSPrice,
                fedexAddFee: fedexAddFee,
                emsAddFee: emsAddFee,
                seaAddFee: seaAddFee,
                dhlAddFee: 0,
                upsAddFee: upsAddFee,
                kpackAddFee: kpAddFee,
                airSAddFee: asAddFee,
                ttDomPrice: ttDomPrice,
                lastPrice: lastPrice,
                payStatus: 'PS0',
                dvType: shipCompanyCode,
            };
        });
    };

    const getPayInfoBoxDomeData = (boxDomeData) => {
        return boxDomeData.map((box, index) => {
            const fedexPrice = 0;
            const emsPrice = 0;
            const shipPrice = 0;
            const dhlPrice = 0;
            const upsPrice = 0;
            const kPackPrice = 0;
            const asPrice = 0;
            const fedexFuelSurch = 0;
            const ttFedexPrice = 0;
            const ttEmsPrice = 0;
            const ttDhlPrice = 0;
            const ttUpsPrice = 0;
            const ttKPackPrice = 0;
            const ttAirSPrice = 0;
            const emsAddFee = 0;
            const fedexAddFee = 0;
            const seaAddFee = 0;
            const upsAddFee = 0;
            const kPackAddFee = 0;
            const airSAddFee = 0;
            const ttDomPrice = parseFloat(box.netWeight) > 30 ? 0 : getAmountForDomeWeight(box.appliedWeightsD.D, domeExData);

            return {
                fedexPrice: fedexPrice,
                emsPrice: emsPrice,
                seaPrice: shipPrice,
                dhlPrice: dhlPrice,
                upsPrice: upsPrice,
                kpackPrice: kPackPrice,
                airSPrice: asPrice,
                fedexFuelSurch: fedexFuelSurch,
                ttFedexPrice: ttFedexPrice,
                ttEmsPrice: ttEmsPrice,
                ttSeaPrice: shipPrice,
                ttDhlPrice: ttDhlPrice,
                dhlFuelSurch: 0,
                upsFuelSurch: 0,
                ttUpsPrice: ttUpsPrice,
                ttKPackPrice: ttKPackPrice,
                ttAirSPrice: ttAirSPrice,
                fedexAddFee: fedexAddFee,
                emsAddFee: emsAddFee,
                seaAddFee: seaAddFee,
                dhlAddFee: 0,
                upsAddFee: upsAddFee,
                kpackAddFee: kPackAddFee,
                airSAddFee: airSAddFee,
                ttDomPrice: ttDomPrice,
                lastPrice: ttDomPrice,
                payStatus: 'PS2',
                dvType: 'D00'
            };
        });
    };

    const appReqData = {
        seqnum: (orderData.reqmnDTO ? orderData.reqmnDTO.seqnum : (orderData.appReqDTO ? orderData.appReqDTO.seqnum : null))
    };

    const appRecData = {
        seqnum: (orderData.recinfoDTO ? orderData.recinfoDTO.seqnum : (orderData.appRecDTO ? orderData.appRecDTO.seqnum : null))
    };

    const expRegComplete = async () => {
        if (isLoading) return;

        setIsLoading(true);

        try {
            const selectedProductData = boxData.flatMap(box => box.products);
            const selectedProducts = (orderData.productsDTO || orderData.packListDTO?.productsDTO || [])
                .filter(item =>
                    ['IS05', 'IS06'].includes(item.prodSt) && selectedProductData.includes(item.seqnum)
                );

            const productData = selectedProducts.map(product => ({
                seqnum: product.seqnum,
                prodSt: 'IS07'
            }));

            const productInfoMData = orderData.packListDTO ?
                orderData.packListDTO.productsDTO.map(product => ({
                    seqnum: product.seqnum,
                    prodSt: 'IS07'
                })) : [];

            const selectedData = selectedShipCompany === "국내배송" ? shipInfoDomeData : shipInfoData;
            const productInfoData = selectedDataType === "orderdt" ? productData : productInfoMData;
            const formData = new FormData();

            const dataToAppend = {
                orderDtData,
                payInfoBuyData,
                payInfoEtcData,
                payInfoBoxData: selectedShipCompany === "국내배송" ? getPayInfoBoxDomeData(boxDomeData) : getPayInfoBoxData(selectedShipCompany, boxData),
                shipInfoData: selectedData,
                productInfoData,
                appReqData,
                appRecData,
                shipModiInfoData,
                dataType: orderData.dataType
            };

            Object.entries(dataToAppend).forEach(([key, value]) => {
                formData.append(key, typeof value === 'string' ? value : JSON.stringify(value));
            });

            const response = await axios.post('/ordPay/expreginsert', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: LCAT
                }
            });

            if (Number(boxData[0].netWeight) > 0 || Number(boxesDomeData[0].weight) > 0) {
                await stompClient.send("/app/updateOrderList", {}, JSON.stringify({userMbNum: orderData.memberDTO.mbNum}));
                sendMessage();
            }

            setInitialPayInfoBoxData(selectedShipCompany === "국내배송" ? getPayInfoBoxDomeData(boxDomeData) : getPayInfoBoxData(selectedShipCompany, boxData));

            if (window.opener) {
                window.opener.location.reload();
                window.close();
            }

            // console.log("111: ", getPayInfoBoxDomeData(boxDomeData));
            // console.log("222: ", selectedShipCompany);
            // console.log("333: ", getPayInfoBoxData(selectedShipCompany, boxData));
            // console.log("444: ", dataType);

        } catch (error) {
            console.error("Error r:", error.response);
            console.error("Error m:", error.message);
            console.error("Error c:", error.config);
        } finally {
            setIsLoading(false);
        }
    };

    const myPgLink = `https://btorage.com/UserMyPg`;
    // <a href="${myPgLink}">마이페이지 바로가기</a>

    const domesticChatKoMsg = `안녕하세요 ${orderData.memberDTO?.userName}님^^*\n포장완료 후 주문서 상세페이지의 결제목록에 국내배송료가 등록되었습니다.\n\n결제 전까지 결제목록의 '상세'버튼을 통해 열리는 팝업창에서\n최종 한국 수취인정보를 한 번 더 확인해주세요 :)\n\n저희 비토리지를 믿고 이용해주셔서 진심으로 감사드립니다 !`.replace(/\n/g, '');
    const domesticChatEnMsg = `Hello ${orderData.memberDTO?.userName}^^*\nThe domestic shipping fee has been added to the payment list on the order details page after packaging is complete.\n\nBefore making payment, please double-check the final recipient information in Korea through the pop-up window that opens by clicking the 'Details' button in the payment list :)\n\nThank you sincerely for trusting and using Btorage!`;
    const domesticChatJaMsg = `こんにちは、${orderData.memberDTO?.userName}さん^^*\n梱包完了後、注文書詳細ページの決済リストに国内送料が登録されました。\n\n決済前に、決済リストの「詳細」ボタンから開くポップアップ画面で\n最終受取人情報をもう一度ご確認ください :)\n\nBtorageをご利用いただき、本当にありがとうございます！`;
    const domesticChatZhMsg = `您好，${orderData.memberDTO?.userName}^^*\n打包完成后，订单详情页面的支付列表中已登记国内运费。\n\n在支付之前，请通过支付列表中的“详情”按钮打开的弹窗\n再次确认最终的韩国收件人信息 :)\n\n感谢您信任并使用Btorage！`;
    const domesticChatIdMsg = `Halo ${orderData.memberDTO?.userName}^^*\nSetelah pengemasan selesai, biaya pengiriman domestik telah terdaftar di daftar pembayaran pada halaman detail pesanan.\n\nSebelum melakukan pembayaran, pastikan untuk memeriksa kembali informasi penerima terakhir di Korea melalui jendela pop-up yang terbuka saat Anda mengklik tombol 'Detail' di daftar pembayaran :)\n\nTerima kasih telah mempercayai dan menggunakan Btorage!`;

    const overseasChatKoMsg = `안녕하세요 ${orderData.memberDTO?.userName}님^^*\n포장완료 후 주문서 상세페이지의 결제목록에 해외배송료가 등록되었습니다.\n\n결제 전까지 결제목록의 '상세'버튼을 통해 열리는 팝업창에서\n반드시 최종 수취인정보/세관정보를 확인하신 후 배송사를 선택/결제해주세요 :)\n\n저희 비토리지를 믿고 이용해주셔서 진심으로 감사드립니다 !`;
    const overseasChatEnMsg = `Hello ${orderData.memberDTO?.userName}^^*\nThe international shipping fee has been added to the payment list on the order details page after packaging is complete.\n\nBefore making payment, please be sure to confirm the final recipient and customs information through the pop-up window that opens by clicking the 'Details' button in the payment list, then select the shipping company and proceed with payment :)\n\nThank you sincerely for trusting and using Btorage!`;
    const overseasChatJaMsg = `こんにちは、${orderData.memberDTO?.userName}さん^^*\n梱包完了後、注文書詳細ページの決済リストに海外送料が登録されました。\n\n決済前に、決済リストの「詳細」ボタンから開くポップアップ画面で\n必ず最終受取人情報/通関情報をご確認の上、配送業者を選択し決済してください :)\n\nBtorageをご利用いただき、本当にありがとうございます！`;
    const overseasChatZhMsg = `您好，${orderData.memberDTO?.userName}^^*\n打包完成后，订单详情页面的支付列表中已登记国际运费。\n\n在支付之前，请务必通过支付列表中的“详情”按钮打开的弹窗\n确认最终收件人信息/海关信息后再选择配送公司并支付 :)\n\n感谢您信任并使用Btorage！`;
    const overseasChatIdMsg = `Halo ${orderData.memberDTO?.userName}^^*\nSetelah pengemasan selesai, biaya pengiriman internasional telah terdaftar di daftar pembayaran pada halaman detail pesanan.\n\nSebelum melakukan pembayaran, pastikan untuk memeriksa informasi penerima terakhir/informasi bea cukai di jendela pop-up yang terbuka saat Anda mengklik tombol 'Detail' di daftar pembayaran,\nlalu pilih perusahaan pengiriman dan lanjutkan dengan pembayaran :)\n\nTerima kasih telah mempercayai dan menggunakan Btorage!`;

    const domesticEmailKoMsg = `안녕하세요 ${orderData.memberDTO?.userName}님^^*<br>포장완료 후 주문서 상세페이지의 결제목록에 국내배송료가 등록되었습니다.<br><br>결제 전까지 결제목록의 '상세'버튼을 통해 열리는 팝업창에서<br>최종 한국 수취인정보를 한 번 더 확인해주세요 :)<br><br>저희 비토리지를 믿고 이용해주셔서 진심으로 감사드립니다 !`;
    const domesticEmailEnMsg = `Hello ${orderData.memberDTO?.userName}^^*<br>The domestic shipping fee has been added to the payment list on the order details page after packaging is complete.<br><br>Before making payment, please double-check the final recipient information in Korea through the pop-up window that opens by clicking the 'Details' button in the payment list :)<br><br>Thank you sincerely for trusting and using Btorage!`;
    const domesticEmailJaMsg = `こんにちは、${orderData.memberDTO?.userName}さん^^*<br>梱包完了後、注文書詳細ページの決済リストに国内送料が登録されました。<br><br>決済前に、決済リストの「詳細」ボタンから開くポップアップ画面で<br>最終受取人情報をもう一度ご確認ください :)<br><br>Btorageをご利用いただき、本当にありがとうございます！`;
    const domesticEmailZhMsg = `您好，${orderData.memberDTO?.userName}^^*<br>打包完成后，订单详情页面的支付列表中已登记国内运费。<br><br>在支付之前，请通过支付列表中的“详情”按钮打开的弹窗<br>再次确认最终的韩国收件人信息 :)<br><br>感谢您信任并使用Btorage！`;
    const domesticEmailIdMsg = `Halo ${orderData.memberDTO?.userName}^^*<br>Setelah pengemasan selesai, biaya pengiriman domestik telah terdaftar di daftar pembayaran pada halaman detail pesanan.<br><br>Sebelum melakukan pembayaran, pastikan untuk memeriksa kembali informasi penerima terakhir di Korea melalui jendela pop-up yang terbuka saat Anda mengklik tombol 'Detail' di daftar pembayaran :)<br><br>Terima kasih telah mempercayai dan menggunakan Btorage!`;

    const overseasEmailKoMsg = `안녕하세요 ${orderData.memberDTO?.userName}님^^*<br>포장완료 후 주문서 상세페이지의 결제목록에 해외배송료가 등록되었습니다.<br><br>결제 전까지 결제목록의 '상세'버튼을 통해 열리는 팝업창에서<br>반드시 최종 수취인정보/세관정보를 확인하신 후 배송사를 선택/결제해주세요 :)<br><br>저희 비토리지를 믿고 이용해주셔서 진심으로 감사드립니다 !`;
    const overseasEmailEnMsg = `Hello ${orderData.memberDTO?.userName}^^*<br>The international shipping fee has been added to the payment list on the order details page after packaging is complete.<br><br>Before making payment, please be sure to confirm the final recipient and customs information through the pop-up window that opens by clicking the 'Details' button in the payment list, then select the shipping company and proceed with payment :)<br><br>Thank you sincerely for trusting and using Btorage!`;
    const overseasEmailJaMsg = `こんにちは、${orderData.memberDTO?.userName}さん^^*<br>梱包完了後、注文書詳細ページの決済リストに海外送料が登録されました。<br><br>決済前に、決済リストの「詳細」ボタンから開くポップアップ画面で<br>必ず最終受取人情報/通関情報をご確認の上、配送業者を選択し決済してください :)<br><br>Btorageをご利用いただき、本当にありがとうございます！`;
    const overseasEmailZhMsg = `您好，${orderData.memberDTO?.userName}^^*<br>打包完成后，订单详情页面的支付列表中已登记国际运费。<br><br>在支付之前，请务必通过支付列表中的“详情”按钮打开的弹窗<br>确认最终收件人信息/海关信息后再选择配送公司并支付 :)<br><br>感谢您信任并使用Btorage！`;
    const overseasEmailIdMsg = `Halo ${orderData.memberDTO?.userName}^^*<br>Setelah pengemasan selesai, biaya pengiriman internasional telah terdaftar di daftar pembayaran pada halaman detail pesanan.<br><br>Sebelum melakukan pembayaran, pastikan untuk memeriksa informasi penerima terakhir/informasi bea cukai di jendela pop-up yang terbuka saat Anda mengklik tombol 'Detail' di daftar pembayaran,<br>lalu pilih perusahaan pengiriman dan lanjutkan dengan pembayaran :)<br><br>Terima kasih telah mempercayai dan menggunakan Btorage!`;

    const domesticChatSendLang = orderData.memberDTO?.lang === "Korean" ? domesticChatKoMsg : orderData.memberDTO?.lang === "Chinese" ? domesticChatZhMsg :
        orderData.memberDTO?.lang === "Japanese" ? domesticChatJaMsg : orderData.memberDTO?.lang === "Indonesian" ? domesticChatIdMsg : domesticChatEnMsg;
    const overseasChatSendLang = orderData.memberDTO?.lang === "Korean" ? overseasChatKoMsg : orderData.memberDTO?.lang === "Chinese" ? overseasChatZhMsg :
        orderData.memberDTO?.lang === "Japanese" ? overseasChatJaMsg : orderData.memberDTO?.lang === "Indonesian" ? overseasChatIdMsg : overseasChatEnMsg;

    const domesticEmailSendLang = orderData.memberDTO?.lang === "Korean" ? domesticEmailKoMsg : orderData.memberDTO?.lang === "Chinese" ? domesticEmailZhMsg :
        orderData.memberDTO?.lang === "Japanese" ? domesticEmailJaMsg : orderData.memberDTO?.lang === "Indonesian" ? domesticEmailIdMsg : domesticEmailEnMsg;
    const overseasEmailSendLang = orderData.memberDTO?.lang === "Korean" ? overseasEmailKoMsg : orderData.memberDTO?.lang === "Chinese" ? overseasEmailZhMsg :
        orderData.memberDTO?.lang === "Japanese" ? overseasEmailJaMsg : orderData.memberDTO?.lang === "Indonesian" ? overseasEmailIdMsg : overseasEmailEnMsg;

    const sendMessage = async () => {
        if (stompClient) {
            const mstMbNum = orderData.dataType === "payinfo"
                ? orderData.orderdtDTO.memberDTO.mbNum
                : orderData.memberDTO.mbNum;

            const mstUserNm = orderData.dataType === "payinfo"
                ? orderData.orderdtDTO.memberDTO.userName
                : orderData.memberDTO.userName;

            const email = orderData.dataType === "payinfo"
                ? orderData.orderdtDTO.memberDTO.id
                : orderData.memberDTO.id;

            const lang = orderData.dataType === "payinfo"
                ? orderData.orderdtDTO.memberDTO.lang
                : orderData.memberDTO.lang;

            const chatMessage = {
                chatKey: selectedShipCompany === "국내배송" ? domesticChatSendLang : overseasChatSendLang,
                fileNm: null,
                chatOrdNum: Number(orderData.orderNumber),
                mbNum: adminInfo.mbNum,
                mstMbNum: mstMbNum,
                mstUserNm: mstUserNm,
                email: email,
                sendMailFl: true,
                autoChatFl: true,
                lang: lang,
                process: "PS0",
                emailKey: selectedShipCompany === "국내배송" ? domesticEmailSendLang : overseasEmailSendLang
            };
            await stompClient.send("/app/chat/adminMessage", {}, JSON.stringify(chatMessage));
        } else {
            console.log("메시지 전송실패");
        }
    };

    const regExit = () => {
        window.close();
    }

    /** endregion */

    return (
        <div className="AdminNewRegModal font-t-cont">
            <div className="an-header-frame">
                <div className="an-header-title display-flex flex-row just-cont-fstart c-def align-center p-l-17">
                    <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                    <p className="font-t-title font-s-18 p-t-4 m-l-2" style={{color: "#FFF"}}>비용등록</p>
                </div>
                <div className="an-ord-info-frame c-def">
                    <div className="ht-100pc display-flex flex-column just-cont-sb">
                        <div className="display-flex flex-row just-cont-fstart m-b-5">
                            <img className="wd-18 ht-18 m-t-2 m-r-5" alt="" src={orderIMG}/>
                            <p className="font-b font-s-16">{orderData.orderNumber ? orderData.orderNumber : (orderData.orderdtDTO ? orderData.orderdtDTO.orderNumber : '')}</p>
                        </div>
                        <div className="display-flex flex-row just-cont-fstart">
                            <div className="display-flex flex-column wd-120 font-s-13" style={{backgroundColor: "#f3f3f3"}}>
                                <div className="pd7"><p className="m-l-7">고객명</p></div>
                                <div className="pd7"><p className="m-l-7">수취인명</p></div>
                                <div className="pd7"><p className="m-l-7">국가</p></div>
                                <div className="pd7"><p className="m-l-7">배송사</p></div>
                            </div>
                            <div className="display-flex flex-column wd-230 font-s-13">
                                <div className="pd7"><p>{orderData.memberDTO ? orderData.memberDTO.userName : (orderData.orderdtDTO ? orderData.orderdtDTO.memberDTO.userName : '')}</p></div>
                                <div className="pd7"><p className="font-b">{orderData.memberDTO ? orderData.recinfoDTO.recname : (orderData.orderdtDTO ? orderData.orderdtDTO.recinfoDTO.recname : '')}</p></div>
                                <div className="pd7">
                                    <p className="font-b">
                                        {orderData.recinfoDTO && orderData.recinfoDTO.countryDTO
                                            ? orderData.recinfoDTO.countryDTO.countryNm + "[" + orderData.recinfoDTO.countryDTO.countryCode + "]"
                                            : (orderData.appRecDTO && orderData.appRecDTO.countryDTO
                                                ? orderData.appRecDTO.countryDTO.countryNm + "[" + orderData.appRecDTO.countryDTO.countryCode + "]"
                                                : '')
                                        }
                                    </p>
                                </div>
                                <div className="p-l-7 p-t-3">
                                    <select className="admin-combo wd-110 ht-30 font-s-13" value={selectedShipCompany}
                                            onChange={(e) => {
                                                setSelectedShipCompany(e.target.value);
                                                let shipCompanyCode;
                                                switch (e.target.value) {
                                                    case "FedEx":
                                                        shipCompanyCode = "D01";
                                                        break;
                                                    case "UPS":
                                                        shipCompanyCode = "D11";
                                                        break;
                                                    case "EMS":
                                                        shipCompanyCode = "D02";
                                                        break;
                                                    case "K-Packet":
                                                        shipCompanyCode = "D13";
                                                        break;
                                                    case "항공소형":
                                                        shipCompanyCode = "D14";
                                                        break;
                                                    case "SHIP":
                                                        shipCompanyCode = "D03";
                                                        break;
                                                    case "국내배송":
                                                        shipCompanyCode = "D00";
                                                        break;
                                                    default:
                                                        shipCompanyCode = "";
                                                }
                                            }}>
                                        {shipcoData.map((cData) => (
                                            <option value={cData.courierCompanyName} key={cData.seqnum}>
                                                {cData.courierCompanyName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="an-cont-frame pop-scroll">
                <div className="m-a-open-bar m-a-open-bar-pc p-l-20 p-r-20 c-point" onClick={() => setShowCostInputs(!showCostInputs)}>
                    <p className="font-t-title font-s-14">비용입력</p>
                    {showCostInputs ?
                        <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/> :
                        <i className="m-a-ord-accordion-icon"></i>
                    }
                </div>
                {showCostInputs &&
                    <div className="m-a-top-border-card display-flex flex-column wd-100pc font-t-cont">
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${codCheckbox ? 'm-a-text-colored' : ''}`}><p className="">택배착불비</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0" maxLength="7" inputMode="numeric" pattern="\d*"
                                       value={codCheckbox ? parseInt(codValue).toLocaleString() : ''}
                                       onChange={(e) => {
                                           const newValue = e.target.value.replace(/,/g, '');
                                           if (/^\d*$/.test(newValue)) {
                                               setCodValue(newValue);
                                               setCodCheckbox(newValue !== '' && parseInt(newValue) !== 0);
                                           }
                                       }}
                                />
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${domeCheckbox ? 'm-a-text-colored' : ''}`}><p className="">국내반송비</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0" maxLength="7" inputMode="numeric" pattern="\d*"
                                       value={domeCheckbox ? parseInt(domeValue).toLocaleString() : ''}
                                       onChange={(e) => {
                                           const newValue = e.target.value.replace(/,/g, '');
                                           if (/^\d*$/.test(newValue)) {
                                               setDomeValue(newValue);
                                               setDomeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                           }
                                       }}
                                />
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 ${repackCheckbox ? 'm-a-text-colored' : ''}`}><p className="">재포장수수료</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0" maxLength="7" inputMode="numeric" pattern="\d*"
                                       value={repackCheckbox ? parseInt(repackValue).toLocaleString() : ""}
                                       onChange={(e) => {
                                           const newValue = e.target.value.replace(/,/g, '');
                                           if (/^\d*$/.test(newValue)) {
                                               setRePackValue(newValue);
                                               setRePackCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                           }
                                       }}
                                />
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${agenciesCheckbox ? 'm-a-text-colored' : ''}`}><p className="">대행수수료</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0" maxLength="8" inputMode="numeric" pattern="\d*"
                                       value={agenciesCheckbox ? parseInt(agenciesValue).toLocaleString() : ""}
                                       onChange={(e) => {
                                           const newValue = e.target.value.replace(/,/g, '');
                                           if (/^\d*$/.test(newValue)) {
                                               setAgenciesValue(newValue);
                                               setAgenciesCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                           }
                                       }}
                                />
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${vacuumPack ? 'm-a-text-colored' : ''}`}><p className="">압축팩수수료</p></div>
                            <div className="wd-26pc font-s-14 font-c-default font-t-cont" style={{marginLeft: "6%"}}>
                                <select className="admin-combo wd-100pc ht-40" value={packSize} onChange={handleVacPackSizeChange}>
                                    <option value="선택">선택</option>
                                    <option value="소형">소형</option>
                                    <option value="대형">대형</option>
                                </select>
                            </div>
                            <div className="m-a-input-frame ht-40 wd-30pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0" disabled={true}
                                       value={vacuumPack ? parseInt(vacPackPrice).toLocaleString() : ""}/>
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${etcCheckbox ? 'm-a-text-colored' : ''}`}><p className="">기타비용</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0" maxLength="8" inputMode="numeric" pattern="\d*"
                                       value={etcCheckbox ? parseInt(etcValue).toLocaleString() : ""}
                                       onChange={(e) => {
                                           const newValue = e.target.value.replace(/,/g, '');
                                           if (/^\d*$/.test(newValue)) {
                                               setEtcValue(newValue);
                                               setEtcCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                           }
                                       }}
                                />
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${etcNmValue ? 'm-a-text-colored' : ''}`}><p className="">기타비용내용</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 font-s-14" type={"text"} maxLength="25" placeholder="비용 입력시 활성화"
                                       value={etcCheckbox ? etcNmValue : ""} disabled={etcCheckbox === false}
                                       onChange={(e) => {setEtcNmValue(e.target.value)}}
                                       style={{}}/>
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${etcFeeValue ? 'm-a-text-colored' : ''}`}><p className="">기타수수료</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0" maxLength="8" inputMode="numeric" pattern="\d*"
                                       value={etcFeeValue === "" ? "" : parseInt(etcFeeValue).toLocaleString()}
                                       onChange={(e) => {
                                           const newValue = e.target.value.replace(/,/g, '');
                                           if (/^\d*$/.test(newValue)) {
                                               setEtcFeeValue(newValue);
                                               setEtcFeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                           }
                                       }}
                                />
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${etcFeeNmValue ? 'm-a-text-colored' : ''}`}><p className="">기타수수료내용</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 font-s-14" type={"text"} maxLength="25" placeholder="수수료 입력시 활성화"
                                       value={etcFeeCheckbox ? etcFeeNmValue : ""} disabled={etcFeeCheckbox === false}
                                       onChange={(e) => {setEtcFeeNmValue(e.target.value)}}/>
                            </div>
                        </div>
                    </div>
                }
                {/*{showCostInputs &&*/}
                {/*    <div>*/}
                {/*        <Table bordered className="reg-tb m-b-20">*/}
                {/*            <tbody>*/}
                {/*            <tr>*/}
                {/*                <th><input className="admin-input-chkBox-s m-r-5"*/}
                {/*                           style={{verticalAlign: "middle"}} type="checkbox"*/}
                {/*                           checked={codCheckbox}*/}
                {/*                           onChange={(e) => setCodCheckbox(e.target.checked)}/>택배착불비(&#8361;)*/}
                {/*                </th>*/}
                {/*                <td>*/}
                {/*                    <input className="admin-input-line wd-100 m-r-5 p-l-5 text-right p-r-5"*/}
                {/*                           type="text" placeholder="0" maxLength="7"*/}
                {/*                           value={codCheckbox ? parseInt(codValue).toLocaleString() : ''}*/}
                {/*                           onChange={(e) => {*/}
                {/*                               const newValue = e.target.value.replace(/,/g, '');*/}
                {/*                               if (/^\d*$/.test(newValue)) {*/}
                {/*                                   setCodValue(newValue);*/}
                {/*                                   setCodCheckbox(newValue !== '' && parseInt(newValue) !== 0);*/}
                {/*                               }*/}
                {/*                           }}*/}
                {/*                    />*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*            <tr>*/}
                {/*                <th><input className="admin-input-chkBox-s m-r-5"*/}
                {/*                           style={{verticalAlign: "middle"}} type={"checkbox"}*/}
                {/*                           checked={domeCheckbox}*/}
                {/*                           onChange={(e) => setDomeCheckbox(e.target.checked)}/>국내반송비(&#8361;)*/}
                {/*                </th>*/}
                {/*                <td>*/}
                {/*                    <input className="admin-input-line wd-100 m-r-5 p-l-5 text-right p-r-5"*/}
                {/*                           type="text" placeholder="0" maxLength="7"*/}
                {/*                           value={domeCheckbox ? parseInt(domeValue).toLocaleString() : ''}*/}
                {/*                           onChange={(e) => {*/}
                {/*                               const newValue = e.target.value.replace(/,/g, '');*/}
                {/*                               if (/^\d*$/.test(newValue)) {*/}
                {/*                                   setDomeValue(newValue);*/}
                {/*                                   setDomeCheckbox(newValue !== "" && parseInt(newValue) !== 0);*/}
                {/*                               }*/}
                {/*                           }}*/}
                {/*                    />*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*            <tr>*/}
                {/*                <th><input className="admin-input-chkBox-s m-r-5"*/}
                {/*                           style={{verticalAlign: "middle"}} type={"checkbox"}*/}
                {/*                           checked={repackCheckbox}*/}
                {/*                           onChange={(e) => setRePackCheckbox(e.target.checked)}/>재포장수수료(&#8361;)*/}
                {/*                </th>*/}
                {/*                <td>*/}
                {/*                    <input className="admin-input-line wd-100 m-r-5 p-l-5 text-right p-r-5"*/}
                {/*                           type="text" placeholder="0" maxLength="7"*/}
                {/*                           value={repackCheckbox ? parseInt(repackValue).toLocaleString() : ""}*/}
                {/*                           onChange={(e) => {*/}
                {/*                               const newValue = e.target.value.replace(/,/g, '');*/}
                {/*                               if (/^\d*$/.test(newValue)) {*/}
                {/*                                   setRePackValue(newValue);*/}
                {/*                                   setRePackCheckbox(newValue !== "" && parseInt(newValue) !== 0);*/}
                {/*                               }*/}
                {/*                           }}*/}
                {/*                    />*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*            <tr>*/}
                {/*                <th><input className="admin-input-chkBox-s m-r-5"*/}
                {/*                           style={{verticalAlign: "middle"}} type={"checkbox"}*/}
                {/*                           checked={agenciesCheckbox}*/}
                {/*                           onChange={(e) => setAgenciesCheckbox(e.target.checked)}/>대행수수료(&#8361;)*/}
                {/*                </th>*/}
                {/*                <td>*/}
                {/*                    <input className="admin-input-line wd-100 m-r-5 p-l-5 text-right p-r-5"*/}
                {/*                           type="text" placeholder="0" maxLength="8"*/}
                {/*                           value={agenciesCheckbox ? parseInt(agenciesValue).toLocaleString() : ""}*/}
                {/*                           onChange={(e) => {*/}
                {/*                               const newValue = e.target.value.replace(/,/g, '');*/}
                {/*                               if (/^\d*$/.test(newValue)) {*/}
                {/*                                   setAgenciesValue(newValue);*/}
                {/*                                   setAgenciesCheckbox(newValue !== "" && parseInt(newValue) !== 0);*/}
                {/*                               }*/}
                {/*                           }}*/}
                {/*                    />*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*            <tr>*/}
                {/*                <th><input className="admin-input-chkBox-s m-r-5"*/}
                {/*                           style={{verticalAlign: "middle"}} type={"checkbox"}*/}
                {/*                           checked={vacuumPack}*/}
                {/*                           onChange={(e) => setVacuumPack(e.target.checked)}/>압축팩수수료(&#8361;)*/}
                {/*                </th>*/}
                {/*                <td>*/}
                {/*                    <input className="admin-input-line wd-100 m-r-5 p-l-5 text-right p-r-5"*/}
                {/*                           type="text" placeholder="0" disabled={true}*/}
                {/*                           value={vacuumPack ? parseInt(vacPackPrice).toLocaleString() : ""}/>*/}
                {/*                    <select className="admin-combo wd-70" value={packSize}*/}
                {/*                            onChange={handleVacPackSizeChange}>*/}
                {/*                        <option value="선택">선택</option>*/}
                {/*                        <option value="소형">소형</option>*/}
                {/*                        <option value="대형">대형</option>*/}
                {/*                    </select>*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*            <tr>*/}
                {/*                <th><input className="admin-input-chkBox-s m-r-5"*/}
                {/*                           style={{verticalAlign: "middle"}} type={"checkbox"}*/}
                {/*                           checked={etcCheckbox}*/}
                {/*                           onChange={(e) => setEtcCheckbox(e.target.checked)}/>기타비용(&#8361;)*/}
                {/*                </th>*/}
                {/*                <td>*/}
                {/*                    <input className="admin-input-line wd-100 m-r-5 p-l-5 text-right p-r-5"*/}
                {/*                           type="text" placeholder="0" maxLength="8"*/}
                {/*                           value={etcCheckbox ? parseInt(etcValue).toLocaleString() : ""}*/}
                {/*                           onChange={(e) => {*/}
                {/*                               const newValue = e.target.value.replace(/,/g, '');*/}
                {/*                               if (/^\d*$/.test(newValue)) {*/}
                {/*                                   setEtcValue(newValue);*/}
                {/*                                   setEtcCheckbox(newValue !== "" && parseInt(newValue) !== 0);*/}
                {/*                               }*/}
                {/*                           }}*/}
                {/*                    />*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*            <tr>*/}
                {/*                <th><input className="admin-input-chkBox-s m-r-5"*/}
                {/*                           style={{verticalAlign: "middle"}} type={"checkbox"}*/}
                {/*                           checked={etcCheckbox}*/}
                {/*                           onChange={(e) => setEtcCheckbox(e.target.checked)}/>기타비용내용*/}
                {/*                </th>*/}
                {/*                <td><input className="admin-input-line p-l-5" type={"text"}*/}
                {/*                           style={{width: "570px"}} maxLength="25"*/}
                {/*                           value={etcCheckbox ? etcNmValue : ""}*/}
                {/*                           disabled={etcCheckbox === false}*/}
                {/*                           onChange={(e) => {*/}
                {/*                               setEtcNmValue(e.target.value)*/}
                {/*                           }}/>*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*            <tr>*/}
                {/*                <th><input className="admin-input-chkBox-s m-r-5"*/}
                {/*                           style={{verticalAlign: "middle"}} type={"checkbox"}*/}
                {/*                           checked={etcFeeCheckbox}*/}
                {/*                           onChange={(e) => setEtcFeeCheckbox(e.target.checked)}/>기타수수료(&#8361;)*/}
                {/*                </th>*/}
                {/*                <td>*/}
                {/*                    <input className="admin-input-line wd-100 m-r-5 p-l-5 text-right p-r-5"*/}
                {/*                           type="text" placeholder="0" maxLength="8"*/}
                {/*                           value={etcFeeValue === "" ? "" : parseInt(etcFeeValue).toLocaleString()}*/}
                {/*                           onChange={(e) => {*/}
                {/*                               const newValue = e.target.value.replace(/,/g, '');*/}
                {/*                               if (/^\d*$/.test(newValue)) {*/}
                {/*                                   setEtcFeeValue(newValue);*/}
                {/*                                   setEtcFeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);*/}
                {/*                               }*/}
                {/*                           }}*/}
                {/*                    />*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*            <tr>*/}
                {/*                <th><input className="admin-input-chkBox-s m-r-5"*/}
                {/*                           style={{verticalAlign: "middle"}} type={"checkbox"}*/}
                {/*                           checked={etcFeeCheckbox}*/}
                {/*                           onChange={(e) => setEtcFeeCheckbox(e.target.checked)}/>기타수수료내용*/}
                {/*                </th>*/}
                {/*                <td><input className="admin-input-line p-l-5" type={"text"}*/}
                {/*                           style={{width: "570px"}} maxLength="25"*/}
                {/*                           value={etcFeeCheckbox ? etcFeeNmValue : ""}*/}
                {/*                           disabled={etcFeeCheckbox === false}*/}
                {/*                           onChange={(e) => {*/}
                {/*                               setEtcFeeNmValue(e.target.value)*/}
                {/*                           }}/>*/}
                {/*                </td>*/}
                {/*            </tr>*/}
                {/*            </tbody>*/}
                {/*        </Table>*/}
                {/*    </div>*/}
                {/*}*/}
                <div className="m-a-open-bar m-a-open-bar-pc p-l-20 p-r-20 m-t-20 c-point" onClick={() => setShowItemInputs(!showItemInputs)}>
                    <p className="font-t-title font-s-14">상품할당</p>
                    {showItemInputs ?
                        <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/> :
                        <i className="m-a-ord-accordion-icon"></i>
                    }
                </div>
                {showItemInputs && (
                    <div className="m-a-top-border-card wd-100pc font-t-cont">
                        <div className="m-a-tb-frame-b ht-350">
                            <div className="m-a-div-tb">
                                <div className="m-a-div-tb-head-b ht-35 font-s-13">
                                    <div className="wd-10pc">
                                        <input className="admin-input-chkBox-s m-0-a"
                                            type="checkbox"
                                            checked={orderData.productsDTO.some(item => item.prodSt === "IS05" || item.prodSt === "IS06") && checked}
                                            onChange={allcheckBoxChange}/>
                                    </div>
                                    <div className="wd-15pc"><p>번호</p></div>
                                    <div className="wd-35pc"><p>품명&운송장</p></div>
                                    <div className="wd-20pc"><p>상태구분</p></div>
                                    <div className="wd-20pc"><p>박스번호</p></div>
                                </div>
                                {orderData.productsDTO?.sort((a, b) => a.seqnum - b.seqnum).map((item, i) => (
                                    <div key={i} className="m-a-div-tb-body-b ht-45">
                                        <div className="wd-10pc">
                                            <input className="admin-input-chkBox-s m-0-a"
                                                type="checkbox"
                                                name={item.seqnum}
                                                checked={(item?.prodSt === "IS05" || item?.prodSt === "IS06") && boxData[selectedBoxIndex[i]]?.products.includes(item.seqnum)}
                                                onChange={() => handleBoxSelection(i, selectedBoxIndex[i])}
                                                disabled={item?.prodSt !== "IS05" && item?.prodSt !== "IS06"}/>
                                        </div>
                                        <div className="wd-15pc font-s-14"><p>{i + 1}</p></div>
                                        <div className="wd-35pc font-s-12 text-center"><p className="m-a-text-cut">{item?.name}</p></div>
                                        <div className="wd-20pc"><a className="font-b font-s-13">{prodStPrint(item?.prodSt)}</a></div>
                                        <div className="wd-20pc align-center">
                                            {(item?.prodSt === "IS05" || item?.prodSt === "IS06") && (
                                                <select className="admin-combo wd-80 font-s-13 ht-25 font-b" value={selectedBoxIndex[i]}
                                                    onChange={e => handleBoxSelection(i, parseInt(e.target.value))}>
                                                    {boxData.map((box, j) => (
                                                        <option key={j} value={j}>
                                                            Box {j + 1}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className="m-a-open-bar p-l-20 p-r-20 m-t-20">
                    <p className="font-t-title font-s-14 c-def">박스정보</p>
                    <div>
                        <AiOutlineMinusSquare className="font-c-default font-s-28 c-point btorage-hover-exp btorage-hover m-r-5" onClick={handleMinusClick}/>
                        <AiOutlinePlusSquare className="font-c-default font-s-28 c-point btorage-hover-exp btorage-hover" onClick={handlePlusClick}/>
                    </div>
                </div>
                {selectedShipCompany !== "국내배송" && showBoxInputs && (
                    <div className="m-a-top-border-card display-flex flex-column wd-100pc">
                        {boxData.map((box, index) => (
                            <div key={index} className="wd-100pc">
                                <div className="display-flex flex-row just-cont-sb font-s-14 wd-100pc text-center gap7">
                                    <p className="wd-20pc c-def">가로(cm)</p>
                                    <p className="wd-20pc c-def">세로(cm)</p>
                                    <p className="wd-20pc c-def">높이(cm)</p>
                                    <p className="wd-20pc c-def">실무게(kg)</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc gap7">
                                    <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                           minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                           value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.wid : box.width}
                                           onChange={(e) => {
                                               const newValue = e.target.value;
                                               if (orderData.dataType === 'payinfo') {
                                                   handleBoxChange(index, "width", newValue);
                                                   const updatedShipInfoDTO = { ...orderData.shipInfoDTO, wid: newValue };
                                                   setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                               } else {
                                                   handleBoxChange(index, "width", newValue);
                                               }
                                               setInputType(newValue === box.width ? 'box' : 'shipInfoDTO');
                                           }}
                                    />
                                    <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                           minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                           value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.ver : box.height}
                                           onChange={(e) => {
                                               const newValue = e.target.value;
                                               if (orderData.dataType === 'payinfo') {
                                                   handleBoxChange(index, "height", newValue);
                                                   const updatedShipInfoDTO = { ...orderData.shipInfoDTO, ver: newValue };
                                                   setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                               } else {
                                                   handleBoxChange(index, "height", newValue);
                                               }
                                               setInputType(newValue === box.height ? 'box' : 'shipInfoDTO');
                                           }}
                                    />
                                    <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                           minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                           value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.hei : box.length}
                                           onChange={(e) => {
                                               const newValue = e.target.value;
                                               if (orderData.dataType === 'payinfo') {
                                                   handleBoxChange(index, "length", newValue);
                                                   const updatedShipInfoDTO = { ...orderData.shipInfoDTO, hei: newValue };
                                                   setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                               } else {
                                                   handleBoxChange(index, "length", newValue);
                                               }
                                               setInputType(newValue === box.length ? 'box' : 'shipInfoDTO');
                                           }}
                                    />
                                    <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                           minLength="1" maxLength="5" type="text " placeholder="0.00" style={{ color: box.netWeight > 30 ? "red" : ""}}
                                           value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.weight : box.netWeight || ""}
                                           onChange={(e) => {
                                               const newValue = e.target.value;
                                               if (orderData.dataType === 'payinfo') {
                                                   handleBoxChange(index, "netWeight", newValue);
                                                   if (!box.width && !box.height && !box.length) {
                                                       handleBoxChange(index, "width", "0");
                                                       handleBoxChange(index, "height", "0");
                                                       handleBoxChange(index, "length", "0");
                                                   }
                                                   if (e.target.value !== "") {
                                                       setEfeeCheckbox(true);
                                                       setFfeeCheckbox(true);
                                                   } else {
                                                       setEfeeCheckbox(false);
                                                       setFfeeCheckbox(false);
                                                   }
                                                   const updatedShipInfoDTO = { ...orderData.shipInfoDTO, weight: newValue };
                                                   setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                               } else {
                                                   handleBoxChange(index, "netWeight", newValue);
                                                   if (!box.width && !box.height && !box.length) {
                                                       handleBoxChange(index, "width", "0");
                                                       handleBoxChange(index, "height", "0");
                                                       handleBoxChange(index, "length", "0");
                                                   }
                                                   if (e.target.value !== "") {
                                                       setEfeeCheckbox(true);
                                                       setFfeeCheckbox(true);
                                                   } else {
                                                       setEfeeCheckbox(false);
                                                       setFfeeCheckbox(false);
                                                   }
                                               }
                                               setInputType(newValue === box.netWeight ? 'box' : 'shipInfoDTO');
                                           }}
                                    />
                                </div>
                                <div className="display-flex flex-column m-t-5 m-b-25">
                                        <div className="display-flex flex-row just-cont-sb">
                                            <div><p className="font-s-14 c-def">Box {index + 1}</p></div>
                                            <div className="display-flex flex-row btorage-hover c-point" onClick={() => handleToggle(index)}>
                                                <p className="font-s-14 m-r-2">자세히</p>
                                                {showBoxDtPrice[index] ?
                                                    <IoIosArrowDropdown className="font-s-17 font-c-default m-t-2" style={{ color: "inherit" }}/> :
                                                    <IoIosArrowDropright className="font-s-17 font-c-default m-t-2" style={{ color: "inherit" }}/>
                                                }
                                            </div>
                                        </div>
                                        {showBoxDtPrice[index] && (
                                            <div className="c-def">
                                                {/*FedEx*/}
                                                <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-a-reg-box-price-top m-t-10 p-t-5 p-b-5">
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                        <div className="display-flex flex-row align-center">
                                                            <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                            <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>FedEx</p>
                                                            <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        </div>
                                                        <div className="display-flex flex-row font-s-15">
                                                            <p>{getVolumeWeightFedex(box.width, box.height, box.length) + "kg"}</p>
                                                            <p className="m-l-4 m-r-5">/</p>
                                                            <p className="font-b">{box.appliedWeights.F + "kg"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <div className="display-flex flex-row align-center">
                                                            <p className="m-r-10">페덱스팩여부</p>
                                                            <input className="m-a-admin-input-chkBox m-0-a m-r-5" type="checkbox"
                                                                   checked={parseFloat(box.netWeight) > 1.5 ? false : box.fpackCheckbox} disabled={parseFloat(box.netWeight) > 1.5 || selectedShipCompany !== 'FedEx'}
                                                                   onChange={() => handleBoxChange(index, "fpackCheckbox")}/>
                                                            {/*<p className="">FedEx Pack</p>*/}
                                                        </div>
                                                        <div className="display-flex flex-row">
                                                            <p className="font-b">{parseFloat(box.netWeight) > 1.5 ? " N" : box.fpackCheckbox ? " Y" : " N"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <div className="display-flex flex-row align-center">
                                                            <p className="m-r-10">유류할증료</p>
                                                            <div className="m-a-input-frame ht-28 wd-65 m-l-10">
                                                                <input className="m-a-input text-center wd-65 ht-28 font-c-default" type={"text"} value={fedexFuelValue} maxLength="4"
                                                                       onChange={(e) => setFedexFuelValue(e.target.value)} inputMode="numeric" pattern="\d*"
                                                                       disabled={parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30}
                                                                />
                                                            </div>
                                                            <p className="m-t-3"> %</p>
                                                        </div>
                                                        <div className="display-flex flex-row">
                                                            <input className="wd-90 ht-25 text-right font-c-default m-a-all-none" type={"text"} disabled placeholder="0"
                                                                   value={parseFloat(box.netWeight) > 30 ? "-" : "\u20A9 " + roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData) * (fedexFuelValue / 100)).toLocaleString()}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">수수료</p>
                                                        <input className="wd-30pc ht-25 text-right font-c-default m-a-all-none" type={"text"} placeholder="0" disabled
                                                               // value={parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30 ? "-" : box.netWeight ? "\u20A9 " + parseInt(boxFedexResultFees[index]).toLocaleString() : "-"}
                                                               value={"\u20A9 " + parseInt(boxFedexResultFees[index]).toLocaleString()}
                                                               onChange={(e) => {
                                                                   const newValue = e.target.value.replace(/,/g, '');
                                                                   if (/^\d*$/.test(newValue)) {
                                                                       const updatedBoxFedexResultFees = [...boxFedexResultFees];
                                                                       updatedBoxFedexResultFees[index] = newValue * appliedWeights.F * 10;
                                                                       setBoxFedexResultFees(updatedBoxFedexResultFees);
                                                                       setEfeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                                       if (newValue !== "" && parseInt(newValue) !== 0) {
                                                                           setEmsAmount(true);
                                                                       }
                                                                   }
                                                               }}
                                                        />
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">배송료</p>
                                                        <p className="">{parseFloat(box.netWeight) > 30 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.F, fedexExData).toLocaleString()}</p>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                        <p className="">총배송비용</p>
                                                        <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text"
                                                               disabled={!(parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30)}
                                                               value={(parseFloat(box.netWeight) > 70 || getVolumeWeightFedex(box.width, box.height, box.length) > 70) ? "-"
                                                                   : (parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30)
                                                                       ? "\u20A9 " + (getFedexCalculatedValue(box, fedexExData) + parseInt(boxFedexResultFees[index])).toLocaleString()
                                                                       : "\u20A9 " + roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData) * (1 + fedexFuelValue / 100) + parseInt(boxFedexResultFees[index])).toLocaleString()}
                                                               onChange={(e) => {
                                                                   const newValue = e.target.value.replace(/[^0-9.]/g, '');
                                                                   if (newValue === "" || !isNaN(newValue)) {
                                                                       handleFedexValueChange(index, newValue);
                                                                   }
                                                               }}
                                                        />
                                                    </div>
                                                </div>
                                                {/*UPS*/}
                                                <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-10 p-t-5 p-b-5">
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                        <div className="display-flex flex-row align-center">
                                                            <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                            <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>UPS</p>
                                                            <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        </div>
                                                        <div className="display-flex flex-row font-s-15">
                                                            <p>{getVolumeWeightUps(box.width, box.height, box.length) + "kg"}</p>
                                                            <p className="m-l-4 m-r-5">/</p>
                                                            <p className="font-b">{box.appliedWeights.P + "kg"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">수수료</p>
                                                        <input className="wd-30pc ht-25 text-right font-c-default m-a-all-none" type={"text"} placeholder="0" disabled
                                                               value={parseFloat(box.netWeight) > 30 || getVolumeWeightUps(box.width, box.height, box.length) > 30 ? "-" : box.netWeight ? "\u20A9 " + parseInt(boxUpsResultFees[index]).toLocaleString() : "-"}
                                                               onChange={(e) => {
                                                                   const newValue = e.target.value.replace(/,/g, '');
                                                                   if (/^\d*$/.test(newValue)) {
                                                                       const updatedBoxUpsResultFees = [...boxUpsResultFees];
                                                                       updatedBoxUpsResultFees[index] = newValue * appliedWeights.P * 10;
                                                                       setBoxUpsResultFees(updatedBoxUpsResultFees);
                                                                   }
                                                               }}
                                                        />
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">배송료</p>
                                                        <p className="">{parseFloat(box.netWeight) > 30 || getVolumeWeightUps(box.width, box.height, box.length) ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.P, upsExData).toLocaleString()}</p>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                        <p className="">총배송비용</p>
                                                        <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text" disabled
                                                               value={parseFloat(box.netWeight) > 30 || getVolumeWeightUps(box.width, box.height, box.length) > 30 ? "-"
                                                                   : "\u20A9 " + (getAmountForWeight(box.appliedWeights.P, upsExData) + parseInt(boxUpsResultFees[index])).toLocaleString()}
                                                        />
                                                    </div>
                                                </div>
                                                {/*EMS*/}
                                                <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-10 p-t-5 p-b-5">
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                        <div className="display-flex flex-row align-center">
                                                            <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                            <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>EMS</p>
                                                            <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        </div>
                                                        <div className="display-flex flex-row font-s-15">
                                                            <p className="">{getVolumeWeightEms(box.width, box.height, box.length) + "kg"}</p>
                                                            <p className="m-l-4 m-r-5">/</p>
                                                            <p className="font-b">{box.appliedWeights.E + "kg"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">수수료</p>
                                                        <input className="wd-30pc ht-25 text-right m-a-all-none" type={"text"} placeholder="0" disabled
                                                               value={parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? "-" : box.netWeight ? "\u20A9 " + parseInt(boxEmsResultFees[index]).toLocaleString() : "-"}
                                                               onChange={(e) => {
                                                                   const newValue = e.target.value.replace(/,/g, '');
                                                                   if (/^\d*$/.test(newValue)) {
                                                                       const updatedBoxEmsResultFees = [...boxEmsResultFees];
                                                                       updatedBoxEmsResultFees[index] = newValue * appliedWeights.E * 10;
                                                                       setBoxEmsResultFees(updatedBoxEmsResultFees);
                                                                       setEfeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                                       if (newValue !== "" && parseInt(newValue) !== 0) {
                                                                           setEmsAmount(true);
                                                                       }
                                                                   }
                                                               }}
                                                        />
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">배송료</p>
                                                        <p>{parseFloat(box.netWeight) > 30  || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.E, emsExData).toLocaleString()}</p>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                        <p className="">총배송비용</p>
                                                        <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text" disabled
                                                               value={parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? "-"
                                                                   : "\u20A9 " + (getAmountForWeight(box.appliedWeights.E, emsExData) + parseInt(boxEmsResultFees[index])).toLocaleString()}/>
                                                    </div>
                                                </div>
                                                {/*K-Packet*/}
                                                <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-10 p-t-5 p-b-5">
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                        <div className="display-flex flex-row align-center">
                                                            <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                            <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>K-Packet</p>
                                                            <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        </div>
                                                        <div className="display-flex flex-row font-s-15">
                                                            <p className="font-b">{box.netWeight + "kg"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">수수료</p>
                                                        <input className="wd-30pc ht-25 text-right m-a-all-none" type={"text"} placeholder="0" disabled
                                                               value={boxKpResultFees[index] > 0 ? "\u20A9 " + parseInt(boxKpResultFees[index]).toLocaleString() : "-"}
                                                               onChange={(e) => {
                                                                   const newValue = e.target.value.replace(/,/g, '');
                                                                   if (/^\d*$/.test(newValue)) {
                                                                       const updatedBoxKpResultFees = [...boxKpResultFees];
                                                                       updatedBoxKpResultFees[index] = newValue * appliedWeights.K * 10
                                                                       setBoxKpResultFees(updatedBoxKpResultFees);
                                                                       // setEfeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                                       // if (newValue !== "" && parseInt(newValue) !== 0) {
                                                                       //     setEmsAmount(true);
                                                                       // }
                                                                   }
                                                               }}
                                                        />
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">배송료</p>
                                                        <p>{parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.K, kpExData).toLocaleString()}</p>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                        <p className="">총배송비용</p>
                                                        <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text" disabled
                                                               value={parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "-"
                                                                   : "\u20A9 " + (getAmountForWeight(box.appliedWeights.K, kpExData) + parseInt(boxKpResultFees[index])).toLocaleString()}/>
                                                    </div>
                                                </div>
                                                {/*항공소형*/}
                                                <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-10 p-t-5 p-b-5">
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                        <div className="display-flex flex-row align-center">
                                                            <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                            <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>항공소형</p>
                                                            <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        </div>
                                                        <div className="display-flex flex-row font-s-15">
                                                            <p className="font-b">{box.netWeight + "kg"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">수수료</p>
                                                        <input className="wd-30pc ht-25 text-right m-a-all-none" type={"text"} placeholder="0" disabled
                                                               value={boxAsResultFees[index] > 0 ? "\u20A9 " + parseInt(boxAsResultFees[index]).toLocaleString() : "-"}
                                                               onChange={(e) => {
                                                                   const newValue = e.target.value.replace(/,/g, '');
                                                                   if (/^\d*$/.test(newValue)) {
                                                                       const updatedBoxAsResultFees = [...boxAsResultFees];
                                                                       updatedBoxAsResultFees[index] = newValue * appliedWeights.A * 10;
                                                                       setBoxAsResultFees(updatedBoxAsResultFees);
                                                                   }
                                                               }}
                                                        />
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">배송료</p>
                                                        <p>{parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.A, asExData).toLocaleString()}</p>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                        <p className="">총배송비용</p>
                                                        <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text" disabled
                                                               value={parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "-"
                                                                   : "\u20A9 " + (getAmountForWeight(box.appliedWeights.A, asExData) + parseInt(boxAsResultFees[index])).toLocaleString()}/>
                                                    </div>
                                                </div>
                                                {/*Sea*/}
                                                <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-10 p-t-5 p-b-5">
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                        <div className="display-flex flex-row align-center">
                                                            <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                            <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>선편</p>
                                                            <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        </div>
                                                        <div className="display-flex flex-row font-s-15"><p className="font-b">{box.netWeight + "kg"}</p></div>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">수수료</p>
                                                        <input className="wd-100 ht-25 text-right m-a-all-none" type={"text"} placeholder="0" disabled
                                                               value={parseFloat(box.netWeight) > 20 ? "-" : boxSeaResultFees[index] > 0 ? "\u20A9 " + parseInt(boxSeaResultFees[index]).toLocaleString() : "-"}
                                                               onChange={(e) => {
                                                                   const newValue = e.target.value.replace(/,/g, '');
                                                                   if (/^\d*$/.test(newValue)) {
                                                                       const updatedBoxSeaResultFees = [...boxSeaResultFees];
                                                                       updatedBoxSeaResultFees[index] = newValue * appliedWeights.S * 10;
                                                                       setBoxSeaResultFees(updatedBoxSeaResultFees);
                                                                   }
                                                               }}
                                                        />
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">배송료</p>
                                                        <p>{parseFloat(box.netWeight) > 20 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.S, shipExData).toLocaleString()}</p>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                        <p className="">총배송비용</p>
                                                        <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text" disabled
                                                               value={parseFloat(box.netWeight) > 20 ? "-"
                                                                   : "\u20A9 " + (getAmountForWeight(box.appliedWeights.S, shipExData) + parseInt(boxSeaResultFees[index])).toLocaleString()}/>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                            </div>
                        ))}
                    </div>
                )}
                {selectedShipCompany === "국내배송" && (
                    <div className="m-a-top-border-card display-flex flex-column wd-100pc">
                        {boxDomeData.map((box, index) => (
                            <div key={index} className="wd-100pc font-t-cont">
                                <div className="display-flex flex-column">
                                    <div className="display-flex flex-row font-s-14 wd-100pc text-center gap7">
                                        <p className="wd-20pc">가로(cm)</p>
                                        <p className="wd-20pc">세로(cm)</p>
                                        <p className="wd-20pc">높이(cm)</p>
                                        <p className="wd-20pc">실무게(kg)</p>
                                    </div>
                                </div>
                                <div className="display-flex flex-column">
                                    <div className="display-flex flex-row just-cont-sb wd-100pc gap7">
                                        <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                               minLength="1" maxLength="3"  type="text" placeholder="0"
                                               disabled={fpackValue === 'Y'} value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.wid : box.width}
                                               onChange={(e) => {
                                                   const newValue = e.target.value;
                                                   if (orderData.dataType === 'payinfo') {
                                                       handleBoxChange(index, "width", newValue);
                                                       const updatedShipInfoDTO = { ...orderData.shipInfoDTO, wid: newValue };
                                                       setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                                   } else {
                                                       handleBoxChange(index, "width", newValue);
                                                   }
                                                   setInputType(newValue === box.width ? 'box' : 'shipInfoDTO');
                                               }}
                                        />
                                        <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                               minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                               value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.ver : box.height}
                                               onChange={(e) => {
                                                   const newValue = e.target.value;
                                                   if (orderData.dataType === 'payinfo') {
                                                       handleBoxChange(index, "height", newValue);
                                                       const updatedShipInfoDTO = { ...orderData.shipInfoDTO, ver: newValue };
                                                       setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                                   } else {
                                                       handleBoxChange(index, "height", newValue);
                                                   }
                                                   setInputType(newValue === box.height ? 'box' : 'shipInfoDTO');
                                               }}
                                        />
                                        <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                               minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                               value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.hei : box.length}
                                               onChange={(e) => {
                                                   const newValue = e.target.value;
                                                   if (orderData.dataType === 'payinfo') {
                                                       handleBoxChange(index, "length", newValue);
                                                       const updatedShipInfoDTO = { ...orderData.shipInfoDTO, hei: newValue };
                                                       setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                                   } else {
                                                       handleBoxChange(index, "length", newValue);
                                                   }
                                                   setInputType(newValue === box.length ? 'box' : 'shipInfoDTO');
                                               }}
                                        />
                                        <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                               minLength="1" maxLength="5" type="text " placeholder="0.00" style={{ color: box.netWeight > 30 ? "red" : ""}}
                                               value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.weight : box.netWeight}
                                               onChange={(e) => {
                                                   const newValue = e.target.value;
                                                   if (orderData.dataType === 'payinfo') {
                                                       handleBoxChange(index, "netWeight", newValue);
                                                       if (!box.width && !box.height && !box.length) {
                                                           handleBoxChange(index, "width", "0");
                                                           handleBoxChange(index, "height", "0");
                                                           handleBoxChange(index, "length", "0");
                                                       }
                                                       if (e.target.value !== "") {
                                                           setEfeeCheckbox(true);
                                                           setFfeeCheckbox(true);
                                                       } else {
                                                           setEfeeCheckbox(false);
                                                           setFfeeCheckbox(false);
                                                       }
                                                       const updatedShipInfoDTO = { ...orderData.shipInfoDTO, weight: newValue };
                                                       setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                                   } else {
                                                       handleBoxChange(index, "netWeight", newValue);
                                                       if (!box.width && !box.height && !box.length) {
                                                           handleBoxChange(index, "width", "0");
                                                           handleBoxChange(index, "height", "0");
                                                           handleBoxChange(index, "length", "0");
                                                       }
                                                       if (e.target.value !== "") {
                                                           setEfeeCheckbox(true);
                                                           setFfeeCheckbox(true);
                                                       } else {
                                                           setEfeeCheckbox(false);
                                                           setFfeeCheckbox(false);
                                                       }
                                                   }
                                                   setInputType(newValue === box.netWeight ? 'box' : 'shipInfoDTO');
                                               }}
                                        />
                                    </div>
                                </div>
                                <div className="display-flex flex-column m-t-5 m-b-25">
                                    <div className="display-flex flex-row just-cont-sb">
                                        <div><p className="font-s-14 font-c-btorage">Box {index + 1}</p></div>
                                        <div className="display-flex flex-row text-active c-point" onClick={() => handleToggle(index)}>
                                            <p className="font-s-14">자세히</p>
                                            {showBoxDtPrice[index] ?
                                                <IoIosArrowDropdown className="font-s-18 font-c-default"/> :
                                                <IoIosArrowDropright className="font-s-18 font-c-default"/>
                                            }
                                        </div>
                                    </div>
                                    {showBoxDtPrice[index] && (
                                        <div>
                                            <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-15">
                                                <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                    <p className="font-t-title">국내배송</p>
                                                    <div className="display-flex flex-row font-s-15">
                                                        <p className="" style={{ color: (parseFloat(box.width) + parseFloat(box.height) + parseFloat(box.length)) > 200 ? "red" : "" }}>
                                                            {(!isNaN(parseFloat(box.width)) ? parseFloat(box.width) : 0) +
                                                                (!isNaN(parseFloat(box.height)) ? parseFloat(box.height) : 0) +
                                                                (!isNaN(parseFloat(box.length)) ? parseFloat(box.length) : 0) + "cm"}
                                                        </p>
                                                        <p className="m-l-4 m-r-5">/</p>
                                                        <a className="font-b" style={{ color: parseFloat(box.netWeight) > 30 ? "red" : "" }}>
                                                            {box.netWeight + " kg"}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                    <p className="">총배송비용</p>
                                                    <input className="wd-30pc ht-25 text-right font-b font-c-progress m-a-all-none" type="text" disabled
                                                           value={parseFloat(box.netWeight) > 30 ? 0 : (box.netWeight === "" ? "\\" + 0
                                                               : (domeExData.length > 0 ? getAmountForDomeWeight(box.appliedWeightsD.D, domeExData).toLocaleString() : "N/A"))} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {selectedShipCompany === "국내배송" && tables.length > 0 && tables.length === tableCount - 1 && (
                    <div className="display-flex flex-column font-c-default m-t-20">
                        <div className="m-a-open-bar p-l-20 p-r-20">
                            <p className="font-t-title font-s-14">총배송비용[전체박스]</p>
                            <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/>
                        </div>
                        <div className="m-a-top-border-card display-flex flex-column wd-100pc">
                            <div className="display-flex flex-column wd-100pc">
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">국내배송</p>
                                    <p className="font-c-btorage">{calculateTotalDOMECost()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {selectedShipCompany !== "국내배송" && tables.length > 0 && tables.length === tableCount - 1 && (
                    <div className="display-flex flex-column font-c-default m-t-20">
                        <div className="m-a-open-bar p-l-20 p-r-20">
                            <p className="font-t-title font-s-14">총배송비용[전체박스]</p>
                            <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/>
                        </div>
                        <div className="m-a-top-border-card display-flex flex-column wd-100pc">
                            <div className="display-flex flex-column wd-100pc">
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">FedEx</p>
                                    <p className="font-c-btorage">{calculateTotalFEDEXCost()}</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">UPS</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">EMS</p>
                                    <p className="font-c-btorage">{calculateTotalEMSCost()}</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">SHIP</p>
                                    <p className="font-c-btorage">{calculateTotalSHIPCost()}</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">항공소형</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">K-Packet</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {selectedShipCompany !== "국내배송" && (
                <div className="display-flex flex-row just-cont-sb wd-100pc p-l-20 p-r-20 p-t-5 p-b-5 font-s-14">
                    <div className="display-flex flex-row"><p>배송료제외</p></div>
                    <div className="display-flex flex-row gap15">
                        <div className="display-flex flex-row">
                            <p>FedEx</p>
                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"
                                   checked={fedexPayChk} onChange={() => setFedexPayChk(!fedexPayChk)}/>
                        </div>
                        <div className="display-flex flex-row">
                            <p>UPS</p>
                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"
                                   checked={upsPayChk} onChange={() => setUpsPayChk(!upsPayChk)}/>
                        </div>
                        <div className="display-flex flex-row">
                            <p>EMS</p>
                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"
                                   checked={emsPayChk} onChange={() => setEmsPayChk(!emsPayChk)}/>
                        </div>
                        <div className="display-flex flex-row">
                            <p>선편</p>
                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"
                                   checked={seaPayChk} onChange={() => setSeaPayChk(!seaPayChk)}/>
                        </div>
                        <div className="display-flex flex-row">
                            <p>K-Pack</p>
                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"
                                   checked={kpPayChk} onChange={() => setKpPayChk(!kpPayChk)}/>
                        </div>
                        <div className="display-flex flex-row">
                            <p>항공소형</p>
                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"
                                   checked={asPayChk} onChange={() => setAsPayChk(!asPayChk)}/>
                        </div>
                    </div>
                </div>
            )}
            <div className="an-footer-frame display-flex flex-row just-cont-center align-center">
                {orderData.type === 'orderdt' && (
                    <div>
                        <button className="admin-btn-A wd-100 ht-35 font-s-13 btn-click-event m-r-8" onClick={regExit}>닫기</button>
                        <button className="admin-btn-C wd-100 ht-35 font-s-13 btn-click-event" id="expRegComplete" disabled={isLoading} onClick={alert}>등록완료</button>
                    </div>
                )}
                {orderData.type === 'payinfo' && (
                    <div>
                        <button className="admin-btn-A wd-100 ht-35 font-s-13 btn-click-event m-r-8" onClick={regExit}>닫기</button>
                        <button className="admin-btn-C wd-100 ht-35 font-s-13 btn-click-event" onClick={alert3}>저장</button>
                    </div>
                )}
            </div>





            {/*<div className="openModal1 regModal1 font-t-cont">*/}
            {/*    <section>*/}
            {/*        <main>*/}
            {/*            {selectedShipCompany !== "국내배송" && showBoxInputs && (*/}
            {/*                <div>*/}
            {/*                    {boxData.map((box, index) => (*/}
            {/*                        <div key={index} style={{marginBottom: "40px"}}>*/}
            {/*                            <Table bordered className="reg-tb" style={{marginBottom: "-4px"}}>*/}
            {/*                                <tbody>*/}
            {/*                                <tr>*/}
            {/*                                    <th className="text-center">부피 | 실중량(kg)</th>*/}
            {/*                                    <td className="text-center" colSpan="3"*/}
            {/*                                        style={{backgroundColor: "#EBEBEF7F"}}>*/}
            {/*                                        <a>가로</a>*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-15 m-r-30 m-l-3 font-b font-c-progress wd-80 ht-27 text-center font-s-14 com-input"*/}
            {/*                                            minLength="1" maxLength="3" type="text" placeholder="0"*/}
            {/*                                            disabled={fpackValue === 'Y'}*/}
            {/*                                            value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.wid : box.width}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value;*/}
            {/*                                                if (orderData.dataType === 'payinfo') {*/}
            {/*                                                    handleBoxChange(index, "width", newValue);*/}
            {/*                                                    const updatedShipInfoDTO = {*/}
            {/*                                                        ...orderData.shipInfoDTO,*/}
            {/*                                                        wid: newValue*/}
            {/*                                                    };*/}
            {/*                                                    setOrderData({*/}
            {/*                                                        ...orderData,*/}
            {/*                                                        shipInfoDTO: updatedShipInfoDTO*/}
            {/*                                                    });*/}
            {/*                                                } else {*/}
            {/*                                                    handleBoxChange(index, "width", newValue);*/}
            {/*                                                }*/}
            {/*                                                setInputType(newValue === box.width ? 'box' : 'shipInfoDTO');*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                        <a>세로</a>*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-15 m-r-30 m-l-3 font-b font-c-progress wd-80 ht-27 text-center font-s-14 com-input"*/}
            {/*                                            minLength="1" maxLength="3" type="text" placeholder="0"*/}
            {/*                                            disabled={fpackValue === 'Y'}*/}
            {/*                                            value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.ver : box.height}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value;*/}
            {/*                                                if (orderData.dataType === 'payinfo') {*/}
            {/*                                                    handleBoxChange(index, "height", newValue);*/}
            {/*                                                    const updatedShipInfoDTO = {*/}
            {/*                                                        ...orderData.shipInfoDTO,*/}
            {/*                                                        ver: newValue*/}
            {/*                                                    };*/}
            {/*                                                    setOrderData({*/}
            {/*                                                        ...orderData,*/}
            {/*                                                        shipInfoDTO: updatedShipInfoDTO*/}
            {/*                                                    });*/}
            {/*                                                } else {*/}
            {/*                                                    handleBoxChange(index, "height", newValue);*/}
            {/*                                                }*/}
            {/*                                                setInputType(newValue === box.height ? 'box' : 'shipInfoDTO');*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                        <a>높이</a>*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-15 m-r-30 m-l-3 font-b font-c-progress wd-80 ht-27 text-center font-s-14 com-input"*/}
            {/*                                            minLength="1" maxLength="3" type="text" placeholder="0"*/}
            {/*                                            disabled={fpackValue === 'Y'}*/}
            {/*                                            value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.hei : box.length}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value;*/}
            {/*                                                if (orderData.dataType === 'payinfo') {*/}
            {/*                                                    handleBoxChange(index, "length", newValue);*/}
            {/*                                                    const updatedShipInfoDTO = {*/}
            {/*                                                        ...orderData.shipInfoDTO,*/}
            {/*                                                        hei: newValue*/}
            {/*                                                    };*/}
            {/*                                                    setOrderData({*/}
            {/*                                                        ...orderData,*/}
            {/*                                                        shipInfoDTO: updatedShipInfoDTO*/}
            {/*                                                    });*/}
            {/*                                                } else {*/}
            {/*                                                    handleBoxChange(index, "length", newValue);*/}
            {/*                                                }*/}
            {/*                                                setInputType(newValue === box.length ? 'box' : 'shipInfoDTO');*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                        <a>무게</a>*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-15 m-l-3 font-b font-c-progress wd-80 ht-27 text-center font-s-14 com-input"*/}
            {/*                                            minLength="1" maxLength="5" type="text " placeholder="0.00"*/}
            {/*                                            value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.weight : box.netWeight || ""}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value;*/}
            {/*                                                if (orderData.dataType === 'payinfo') {*/}
            {/*                                                    handleBoxChange(index, "netWeight", newValue);*/}
            {/*                                                    if (!box.width && !box.height && !box.length) {*/}
            {/*                                                        handleBoxChange(index, "width", "0");*/}
            {/*                                                        handleBoxChange(index, "height", "0");*/}
            {/*                                                        handleBoxChange(index, "length", "0");*/}
            {/*                                                    }*/}
            {/*                                                    const updatedShipInfoDTO = {*/}
            {/*                                                        ...orderData.shipInfoDTO,*/}
            {/*                                                        weight: newValue*/}
            {/*                                                    };*/}
            {/*                                                    setOrderData({*/}
            {/*                                                        ...orderData,*/}
            {/*                                                        shipInfoDTO: updatedShipInfoDTO*/}
            {/*                                                    });*/}
            {/*                                                } else {*/}
            {/*                                                    handleBoxChange(index, "netWeight", newValue);*/}
            {/*                                                    if (!box.width && !box.height && !box.length) {*/}
            {/*                                                        handleBoxChange(index, "width", "0");*/}
            {/*                                                        handleBoxChange(index, "height", "0");*/}
            {/*                                                        handleBoxChange(index, "length", "0");*/}
            {/*                                                    }*/}
            {/*                                                }*/}
            {/*                                                setInputType(newValue === box.netWeight ? 'box' : 'shipInfoDTO');*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                                </tbody>*/}
            {/*                            </Table>*/}
            {/*                            <Table bordered className="reg-tb m-b-5">*/}
            {/*                                <tbody>*/}
            {/*                                <tr>*/}
            {/*                                    <td className="text-center"><b*/}
            {/*                                        className="font-s-14 font-c-btorage">Box {index + 1}</b></td>*/}
            {/*                                    <td className="text-center font-s-12"*/}
            {/*                                        colSpan="2">적용부피 &nbsp;|&nbsp; 적용중량*/}
            {/*                                    </td>*/}
            {/*                                    <td className="text-center font-s-12">수수료</td>*/}
            {/*                                    <td className="text-center font-s-12">배송료</td>*/}
            {/*                                    <td className="text-center font-s-12">총배송비용</td>*/}
            {/*                                </tr>*/}
            {/*                                FedEx*/}
            {/*                                <tr>*/}
            {/*                                    <th className="text-center" rowSpan="2">FedEx</th>*/}
            {/*                                    <td colSpan="1" className="text-center wd-110">*/}
            {/*                                        <a className="font-s-15">*/}
            {/*                                            {getVolumeWeightFedex(box.width, box.height, box.length) + "kg"}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center wd-110">*/}
            {/*                                        <a className="font-s-15 font-b">*/}
            {/*                                            {box.appliedWeights.F + "kg"}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center wd-140">*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14 wd-100 ht-25 p-r-5 text-right"*/}
            {/*                                            type={"text"} placeholder="0" disabled*/}
            {/*                                            style={{backgroundColor: parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                            value={parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30 ? 0 : box.netWeight ? "\u20A9 " + parseInt(boxFedexResultFees[index]).toLocaleString() : ""}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value.replace(/,/g, '');*/}
            {/*                                                if (/^\d*$/.test(newValue)) {*/}
            {/*                                                    const updatedBoxFedexResultFees = [...boxFedexResultFees];*/}
            {/*                                                    updatedBoxFedexResultFees[index] = newValue * appliedWeights.F * 10;*/}
            {/*                                                    setBoxFedexResultFees(updatedBoxFedexResultFees);*/}
            {/*                                                    setEfeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);*/}
            {/*                                                    if (newValue !== "" && parseInt(newValue) !== 0) {*/}
            {/*                                                        setEmsAmount(true);*/}
            {/*                                                    }*/}
            {/*                                                }*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                        <input className="admin-input-line font-s-14 wd-100 ht-27 p-r-5 text-right" type={"text"} placeholder="0" disabled*/}
            {/*                                               style={{backgroundColor: parseFloat(box.netWeight) > 70 || getVolumeWeightFedex(box.width, box.height, box.length) > 70 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                               value={parseFloat(box.netWeight) > 70  || getVolumeWeightFedex(box.width, box.height, box.length) > 70 ? "-" : box.netWeight ? "\u20A9 " + parseInt(boxFedexResultFees[index]).toLocaleString() : "-"}*/}
            {/*                                               onChange={(e) => {*/}
            {/*                                                   const newValue = e.target.value.replace(/,/g, '');*/}
            {/*                                                   if (/^\d*$/.test(newValue)) {*/}
            {/*                                                       const updatedBoxFedexResultFees = [...boxFedexResultFees];*/}
            {/*                                                       updatedBoxFedexResultFees[index] = newValue * appliedWeights.F * 10;*/}
            {/*                                                       setBoxFedexResultFees(updatedBoxFedexResultFees);*/}
            {/*                                                   }*/}
            {/*                                               }}*/}
            {/*                                        />*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center wd-140">*/}
            {/*                                        <a className="c-def font-s-15">*/}
            {/*                                            {*/}
            {/*                                                (parseFloat(box.netWeight) > 70 || getVolumeWeightFedex(box.width, box.height, box.length) > 70) ? "-"*/}
            {/*                                                    : (parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30)*/}
            {/*                                                        ? "\u20A9 " + getFedexCalculatedValue(box, fedexExData).toLocaleString()*/}
            {/*                                                        : "\u20A9 " + getAmountForWeight(box.appliedWeights.F, fedexExData).toLocaleString()*/}
            {/*                                            }*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" rowSpan="2" className="text-center">*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14 p-r-5 wd-110 ht-27 text-right font-b font-c-progress m-t-20"*/}
            {/*                                            type="text"*/}
            {/*                                            disabled={!(parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30)}*/}
            {/*                                            style={{backgroundColor: parseFloat(box.netWeight) > 70 || getVolumeWeightUps(box.width, box.height, box.length) > 70 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                            value={(parseFloat(box.netWeight) > 70 || getVolumeWeightFedex(box.width, box.height, box.length) > 70) ? "-"*/}
            {/*                                                : (parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30)*/}
            {/*                                                    ? "\u20A9 " + (getFedexCalculatedValue(box, fedexExData) + parseInt(boxFedexResultFees[index])).toLocaleString()*/}
            {/*                                                    : "\u20A9 " + roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData) * (1 + fedexFuelValue / 100) + parseInt(boxFedexResultFees[index])).toLocaleString()}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value.replace(/[^0-9.]/g, '');*/}
            {/*                                                if (newValue === "" || !isNaN(newValue)) {*/}
            {/*                                                    handleFedexValueChange(index, newValue);*/}
            {/*                                                }*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                                <tr>*/}
            {/*                                    <td colSpan="2" className="text-center">*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14 text-center wd-60 ht-27"*/}
            {/*                                            type={"text"} value={fedexFuelValue} maxLength="4"*/}
            {/*                                            onChange={(e) => setFedexFuelValue(e.target.value)}*/}
            {/*                                            style={{backgroundColor: parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                            disabled={parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30}*/}
            {/*                                        />*/}
            {/*                                        <a className="m-r-15"> %</a>*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14  p-r-5 wd-90 ht-27 text-right"*/}
            {/*                                            type={"text"} disabled placeholder="0"*/}
            {/*                                            style={{backgroundColor: parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                            value={parseFloat(box.netWeight) > 30 ? "-" : "\u20A9 " + roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData) * (fedexFuelValue / 100)).toLocaleString()}*/}
            {/*                                        />*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="2" className="text-center">*/}
            {/*                                        <input className="admin-input-chkBox"*/}
            {/*                                               style={{marginRight: "5px", verticalAlign: "middle"}}*/}
            {/*                                               type={"checkbox"}*/}
            {/*                                               checked={parseFloat(box.netWeight) > 1.5 ? false : box.fpackCheckbox}*/}
            {/*                                               disabled={parseFloat(box.netWeight) > 1.5 || selectedShipCompany !== 'FedEx'}*/}
            {/*                                               onChange={() => handleBoxChange(index, "fpackCheckbox")}*/}
            {/*                                        />*/}
            {/*                                        <a className="c-def m-b-3">FedEx Pack -</a>*/}
            {/*                                        <strong*/}
            {/*                                            className="m-l-10 c-def font-s-15">{parseFloat(box.netWeight) > 1.5 ? "N" : box.fpackCheckbox ? "Y" : "N"}</strong>*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                                UPS*/}
            {/*                                <tr>*/}
            {/*                                    <th className="text-center">UPS</th>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <a className="font-s-15">*/}
            {/*                                            {getVolumeWeightUps(box.width, box.height, box.length) + "kg"}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <a className="font-s-15 font-b">*/}
            {/*                                            {box.appliedWeights.P + "kg"}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14 wd-100 ht-27 p-r-5 text-right"*/}
            {/*                                            type={"text"} placeholder="0" disabled*/}
            {/*                                            style={{backgroundColor: parseFloat(box.netWeight) > 70 || getVolumeWeightUps(box.width, box.height, box.length) > 70 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                            value={parseFloat(box.netWeight) > 70 || getVolumeWeightUps(box.width, box.height, box.length) > 70 ? "-" : box.netWeight ? "\u20A9 " + parseInt(boxUpsResultFees[index]).toLocaleString() : "-"}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value.replace(/,/g, '');*/}
            {/*                                                if (/^\d*$/.test(newValue)) {*/}
            {/*                                                    const updatedBoxUpsResultFees = [...boxUpsResultFees];*/}
            {/*                                                    updatedBoxUpsResultFees[index] = newValue * appliedWeights.P * 10;*/}
            {/*                                                    setBoxUpsResultFees(updatedBoxUpsResultFees);*/}
            {/*                                                }*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <a className="c-def font-s-15">*/}
            {/*                                            {parseFloat(box.netWeight) > 70 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.P, upsExData).toLocaleString()}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14 p-r-5 wd-110 ht-27 text-right font-b font-c-progress"*/}
            {/*                                            type="text" disabled*/}
            {/*                                            style={{backgroundColor: parseFloat(box.netWeight) > 70 || getVolumeWeightUps(box.width, box.height, box.length) > 70 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                            value={parseFloat(box.netWeight) > 70 || getVolumeWeightUps(box.width, box.height, box.length) > 70 ? "-"*/}
            {/*                                                : "\u20A9 " + (getAmountForWeight(box.appliedWeights.P, upsExData) + parseInt(boxUpsResultFees[index])).toLocaleString()}*/}
            {/*                                        />*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                                EMS*/}
            {/*                                <tr>*/}
            {/*                                    <th className="text-center">EMS</th>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <a className="font-s-15">*/}
            {/*                                            {getVolumeWeightEms(box.width, box.height, box.length) + "kg"}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <a className="font-s-15 font-b">*/}
            {/*                                            {box.appliedWeights.E + "kg"}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14 wd-100 ht-27 p-r-5 text-right"*/}
            {/*                                            type={"text"} placeholder="0" disabled*/}
            {/*                                            style={{backgroundColor: parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                            value={parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? "-" : box.netWeight ? "\u20A9 " + parseInt(boxEmsResultFees[index]).toLocaleString() : "-"}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value.replace(/,/g, '');*/}
            {/*                                                if (/^\d*$/.test(newValue)) {*/}
            {/*                                                    const updatedBoxEmsResultFees = [...boxEmsResultFees];*/}
            {/*                                                    updatedBoxEmsResultFees[index] = newValue * appliedWeights.E * 10;*/}
            {/*                                                    setBoxEmsResultFees(updatedBoxEmsResultFees);*/}
            {/*                                                }*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <a className="c-def font-s-15">*/}
            {/*                                            {parseFloat(box.netWeight) > 30 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.E, emsExData).toLocaleString()}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14 p-r-5 wd-110 ht-27 text-right font-b font-c-progress"*/}
            {/*                                            type="text" disabled*/}
            {/*                                            style={{backgroundColor: parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                            value={parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? "-"*/}
            {/*                                                : "\u20A9 " + (getAmountForWeight(box.appliedWeights.E, emsExData) + parseInt(boxEmsResultFees[index])).toLocaleString()}/>*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                                K-Packet*/}
            {/*                                <tr>*/}
            {/*                                    <th className="text-center">K-Packet</th>*/}
            {/*                                    <td colSpan="1" className="text-center">-</td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <a className="font-s-15 font-b">*/}
            {/*                                            {box.netWeight + "kg"}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14 wd-100 ht-27 p-r-5 text-right"*/}
            {/*                                            type={"text"} placeholder="0" disabled*/}
            {/*                                            style={{backgroundColor: parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                            value={boxKpResultFees[index] > 0 ? "\u20A9 " + parseInt(boxKpResultFees[index]).toLocaleString() : "-"}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value.replace(/,/g, '');*/}
            {/*                                                if (/^\d*$/.test(newValue)) {*/}
            {/*                                                    const updatedBoxKpResultFees = [...boxKpResultFees];*/}
            {/*                                                    updatedBoxKpResultFees[index] = newValue * appliedWeights.K * 10;*/}
            {/*                                                    setBoxKpResultFees(updatedBoxKpResultFees);*/}
            {/*                                                    // setEfeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);*/}
            {/*                                                    // if (newValue !== "" && parseInt(newValue) !== 0) {*/}
            {/*                                                    //     setEmsAmount(true);*/}
            {/*                                                    // }*/}
            {/*                                                }*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <a className="c-def font-s-15">*/}
            {/*                                            {parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.K, kpExData).toLocaleString()}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14 p-r-5 wd-110 ht-27 text-right font-b font-c-progress"*/}
            {/*                                            type="text" disabled*/}
            {/*                                            style={{backgroundColor: parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                            value={parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "-"*/}
            {/*                                                : "\u20A9 " + (getAmountForWeight(box.appliedWeights.K, kpExData) + parseInt(boxKpResultFees[index])).toLocaleString()}/>*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                                항공소형*/}
            {/*                                <tr>*/}
            {/*                                    <th className="text-center">항공소형</th>*/}
            {/*                                    <td colSpan="1" className="text-center">-</td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <a className="font-s-15 font-b">*/}
            {/*                                            {box.netWeight + "kg"}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14 wd-100 ht-27 p-r-5 text-right"*/}
            {/*                                            type={"text"} placeholder="0" disabled*/}
            {/*                                            style={{backgroundColor: parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                            value={boxAsResultFees[index] > 0 ? "\u20A9 " + parseInt(boxAsResultFees[index]).toLocaleString() : "-"}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value.replace(/,/g, '');*/}
            {/*                                                if (/^\d*$/.test(newValue)) {*/}
            {/*                                                    const updatedBoxAsResultFees = [...boxAsResultFees];*/}
            {/*                                                    updatedBoxAsResultFees[index] = newValue * appliedWeights.A * 10;*/}
            {/*                                                    setBoxAsResultFees(updatedBoxAsResultFees);*/}
            {/*                                                    // setEfeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);*/}
            {/*                                                    // if (newValue !== "" && parseInt(newValue) !== 0) {*/}
            {/*                                                    //     setEmsAmount(true);*/}
            {/*                                                    // }*/}
            {/*                                                }*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <a className="c-def font-s-15">*/}
            {/*                                            {parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.A, asExData).toLocaleString()}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14 p-r-5 wd-110 ht-27 text-right font-b font-c-progress"*/}
            {/*                                            type="text" disabled*/}
            {/*                                            style={{backgroundColor: parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                            value={parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "-"*/}
            {/*                                                : "\u20A9 " + (getAmountForWeight(box.appliedWeights.A, asExData) + parseInt(boxAsResultFees[index])).toLocaleString()}/>*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                                선편*/}
            {/*                                <tr>*/}
            {/*                                    <th className="text-center">선편</th>*/}
            {/*                                    <td colSpan="1" className="text-center">-</td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <a className="font-s-15 font-b">*/}
            {/*                                            {box.netWeight + "kg"}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14 wd-100 ht-27 p-r-5 text-right"*/}
            {/*                                            type={"text"} placeholder="0" disabled*/}
            {/*                                            style={{backgroundColor: parseFloat(box.netWeight) > 20 || getVolumeWeightFedex(box.width, box.height, box.length) > 20 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                            value={boxSeaResultFees[index] > 0 ? "\u20A9 " + parseInt(boxSeaResultFees[index]).toLocaleString() : "-"}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value.replace(/,/g, '');*/}
            {/*                                                if (/^\d*$/.test(newValue)) {*/}
            {/*                                                    const updatedBoxSeaResultFees = [...boxSeaResultFees];*/}
            {/*                                                    updatedBoxSeaResultFees[index] = newValue * appliedWeights.S * 10;*/}
            {/*                                                    setBoxSeaResultFees(updatedBoxSeaResultFees);*/}
            {/*                                                    // setEfeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);*/}
            {/*                                                    // if (newValue !== "" && parseInt(newValue) !== 0) {*/}
            {/*                                                    //     setEmsAmount(true);*/}
            {/*                                                    // }*/}
            {/*                                                }*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <a className="c-def font-s-15">*/}
            {/*                                            {parseFloat(box.netWeight) > 20 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.S, shipExData).toLocaleString()}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14 p-r-5 wd-110 ht-27 text-right font-b font-c-progress"*/}
            {/*                                            type="text" disabled*/}
            {/*                                            style={{backgroundColor: parseFloat(box.netWeight) > 20 ? "rgba(169,169,169,0.71)" : ""}}*/}
            {/*                                            value={parseFloat(box.netWeight) > 20 ? "-"*/}
            {/*                                                : "\u20A9 " + (getAmountForWeight(box.appliedWeights.S, shipExData) + parseInt(boxSeaResultFees[index])).toLocaleString()}/>*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                                </tbody>*/}
            {/*                            </Table>*/}
            {/*                        </div>*/}
            {/*                    ))}*/}
            {/*                </div>*/}
            {/*            )}*/}
            {/*            {selectedShipCompany !== "국내배송" && (*/}
            {/*                <div className="display-flex flex-row just-cont-sb wd-100pc p-t-5 p-b-5">*/}
            {/*                    <div className="display-flex flex-row"><p className="font-t-title">배송비 제외</p><p*/}
            {/*                        className="font-s-14">(체크 해제시 배송비 = &#8361;0)</p></div>*/}
            {/*                    <div className="display-flex flex-row gap20">*/}
            {/*                        <div className="display-flex flex-row">*/}
            {/*                            <p>FedEx</p>*/}
            {/*                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"*/}
            {/*                                   checked={fedexPayChk} onChange={() => setFedexPayChk(!fedexPayChk)}/>*/}
            {/*                        </div>*/}
            {/*                        <div className="display-flex flex-row">*/}
            {/*                            <p>UPS</p>*/}
            {/*                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"*/}
            {/*                                   checked={upsPayChk} onChange={() => setUpsPayChk(!upsPayChk)}/>*/}
            {/*                        </div>*/}
            {/*                        <div className="display-flex flex-row">*/}
            {/*                            <p>EMS</p>*/}
            {/*                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"*/}
            {/*                                   checked={emsPayChk} onChange={() => setEmsPayChk(!emsPayChk)}/>*/}
            {/*                        </div>*/}
            {/*                        <div className="display-flex flex-row">*/}
            {/*                            <p>선편</p>*/}
            {/*                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"*/}
            {/*                                   checked={seaPayChk} onChange={() => setSeaPayChk(!seaPayChk)}/>*/}
            {/*                        </div>*/}
            {/*                        <div className="display-flex flex-row">*/}
            {/*                            <p>K-Packet</p>*/}
            {/*                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"*/}
            {/*                                   checked={kpPayChk} onChange={() => setKpPayChk(!kpPayChk)}/>*/}
            {/*                        </div>*/}
            {/*                        <div className="display-flex flex-row">*/}
            {/*                            <p>항공소형</p>*/}
            {/*                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"*/}
            {/*                                   checked={asPayChk} onChange={() => setAsPayChk(!asPayChk)}/>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            )}*/}
            {/*            {selectedShipCompany !== "국내배송" && tables.length > 0 && tables.length === tableCount - 1 && (*/}
            {/*                <div>*/}
            {/*                    <div className="admin-ord-reg-wrap m-t-40">*/}
            {/*                        <b className="m-l-5 font-s-13"><BsSquareFill className="admin-tb-header-notice"/>총배송비용[전체박스]</b>*/}
            {/*                        <i className="ord-accordion-icon" style={{transform: "rotate(90deg)"}}/>*/}
            {/*                    </div>*/}
            {/*                    <Table bordered className="reg-tb">*/}
            {/*                        <tbody>*/}
            {/*                        <tr>*/}
            {/*                            <th>FedEx</th>*/}
            {/*                            <td>*/}
            {/*                                <input*/}
            {/*                                    className="admin-input-line m-r-5 font-b font-c-btorage p-r-5 text-right wd-150 ht-25"*/}
            {/*                                    type="text" disabled*/}
            {/*                                    value={"\u20A9 " + calculateTotalFEDEXCost()}*/}
            {/*                                />*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                        <tr>*/}
            {/*                            <th>DHL</th>*/}
            {/*                            <td>*/}
            {/*                                <input className="admin-input-line m-r-5 font-b font-c-btorage p-r-5 text-right wd-150 ht-25" type="text" disabled*/}
            {/*                                       value={"\u20A9 " + calculateTotalDHLCost()}*/}
            {/*                                />*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                        <tr>*/}
            {/*                            <th>EMS</th>*/}
            {/*                            <td>*/}
            {/*                                <input*/}
            {/*                                    className="admin-input-line m-r-5 font-b font-c-btorage p-r-5 text-right wd-150 ht-25"*/}
            {/*                                    type="text" disabled*/}
            {/*                                    value={"\u20A9 " + calculateTotalEMSCost()}*/}
            {/*                                />*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                        <tr>*/}
            {/*                            <th>UPS</th>*/}
            {/*                            <td>*/}
            {/*                                <input*/}
            {/*                                    className="admin-input-line m-r-5 font-b font-c-btorage p-r-5 text-right wd-150 ht-25"*/}
            {/*                                    type="text" disabled*/}
            {/*                                    value={"\u20A9 " + calculateTotalUPSCost()}*/}
            {/*                                />*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                        <tr>*/}
            {/*                            <th>SHIP</th>*/}
            {/*                            <td>*/}
            {/*                                <input*/}
            {/*                                    className="admin-input-line m-r-5 font-b font-c-btorage p-r-5 text-right wd-150 ht-25"*/}
            {/*                                    type="text" disabled*/}
            {/*                                    value={"\u20A9 " + calculateTotalSHIPCost()}*/}
            {/*                                />*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                        <tr>*/}
            {/*                            <th>K-Packet</th>*/}
            {/*                            <td>*/}
            {/*                                <input*/}
            {/*                                    className="admin-input-line m-r-5 font-b font-c-btorage p-r-5 text-right wd-150 ht-25"*/}
            {/*                                    type="text" disabled*/}
            {/*                                    value={"\u20A9 " + calculateTotalKPACKCost()}*/}
            {/*                                />*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                        <tr>*/}
            {/*                            <th>항공소형</th>*/}
            {/*                            <td>*/}
            {/*                                <input*/}
            {/*                                    className="admin-input-line m-r-5 font-b font-c-btorage p-r-5 text-right wd-150 ht-25"*/}
            {/*                                    type="text" disabled*/}
            {/*                                    value={"\u20A9 " + calculateTotalAIRSCost()}*/}
            {/*                                />*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                        </tbody>*/}
            {/*                    </Table>*/}
            {/*                </div>*/}
            {/*            )}*/}
            {/*            {selectedShipCompany === "국내배송" && (*/}
            {/*                <div>*/}
            {/*                    {boxDomeData.map((box, index) => (*/}
            {/*                        <div key={index}>*/}
            {/*                            <Table bordered className="reg-tb" style={{marginBottom: "-4px"}}>*/}
            {/*                                <tbody>*/}
            {/*                                <tr>*/}
            {/*                                    <th className="text-center">부피 | 실중량(kg)</th>*/}
            {/*                                    <td className="text-center" colSpan="4"*/}
            {/*                                        style={{backgroundColor: "#EBEBEF7F"}}>*/}
            {/*                                        <a>가로</a>*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-15 m-r-30 m-l-3 font-b font-c-progress wd-80 ht-27 text-center font-s-14 com-input"*/}
            {/*                                            minLength="1" maxLength="3" type="text" placeholder="0"*/}
            {/*                                            disabled={fpackValue === 'Y'}*/}
            {/*                                            value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.wid : box.width}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value;*/}
            {/*                                                if (orderData.dataType === 'payinfo') {*/}
            {/*                                                    handleBoxChange(index, "width", newValue);*/}
            {/*                                                    const updatedShipInfoDTO = {*/}
            {/*                                                        ...orderData.shipInfoDTO,*/}
            {/*                                                        wid: newValue*/}
            {/*                                                    };*/}
            {/*                                                    setOrderData({*/}
            {/*                                                        ...orderData,*/}
            {/*                                                        shipInfoDTO: updatedShipInfoDTO*/}
            {/*                                                    });*/}
            {/*                                                } else {*/}
            {/*                                                    handleBoxChange(index, "width", newValue);*/}
            {/*                                                }*/}
            {/*                                                setInputType(newValue === box.width ? 'box' : 'shipInfoDTO');*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                        <a>세로</a>*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-15 m-r-30 m-l-3 font-b font-c-progress wd-80 ht-27 text-center font-s-14 com-input"*/}
            {/*                                            minLength="1" maxLength="3" type="text" placeholder="0"*/}
            {/*                                            disabled={fpackValue === 'Y'}*/}
            {/*                                            value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.ver : box.height}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value;*/}
            {/*                                                if (orderData.dataType === 'payinfo') {*/}
            {/*                                                    handleBoxChange(index, "height", newValue);*/}
            {/*                                                    const updatedShipInfoDTO = {*/}
            {/*                                                        ...orderData.shipInfoDTO,*/}
            {/*                                                        ver: newValue*/}
            {/*                                                    };*/}
            {/*                                                    setOrderData({*/}
            {/*                                                        ...orderData,*/}
            {/*                                                        shipInfoDTO: updatedShipInfoDTO*/}
            {/*                                                    });*/}
            {/*                                                } else {*/}
            {/*                                                    handleBoxChange(index, "height", newValue);*/}
            {/*                                                }*/}
            {/*                                                setInputType(newValue === box.height ? 'box' : 'shipInfoDTO');*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                        <a>높이</a>*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-15 m-r-30 m-l-3 font-b font-c-progress wd-80 ht-27 text-center font-s-14 com-input"*/}
            {/*                                            minLength="1" maxLength="3" type="text" placeholder="0"*/}
            {/*                                            disabled={fpackValue === 'Y'}*/}
            {/*                                            value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.hei : box.length}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value;*/}
            {/*                                                if (orderData.dataType === 'payinfo') {*/}
            {/*                                                    handleBoxChange(index, "length", newValue);*/}
            {/*                                                    const updatedShipInfoDTO = {*/}
            {/*                                                        ...orderData.shipInfoDTO,*/}
            {/*                                                        hei: newValue*/}
            {/*                                                    };*/}
            {/*                                                    setOrderData({*/}
            {/*                                                        ...orderData,*/}
            {/*                                                        shipInfoDTO: updatedShipInfoDTO*/}
            {/*                                                    });*/}
            {/*                                                } else {*/}
            {/*                                                    handleBoxChange(index, "length", newValue);*/}
            {/*                                                }*/}
            {/*                                                setInputType(newValue === box.length ? 'box' : 'shipInfoDTO');*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                        <a>무게</a>*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-15 m-l-3 font-b font-c-progress wd-80 ht-27 text-center font-s-14 com-input"*/}
            {/*                                            minLength="1" maxLength="5" type="text " placeholder="0.00"*/}
            {/*                                            style={{color: box.netWeight > 30 ? "red" : ""}}*/}
            {/*                                            value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.weight : box.netWeight}*/}
            {/*                                            onChange={(e) => {*/}
            {/*                                                const newValue = e.target.value;*/}
            {/*                                                if (orderData.dataType === 'payinfo') {*/}
            {/*                                                    handleBoxChange(index, "netWeight", newValue);*/}
            {/*                                                    if (!box.width && !box.height && !box.length) {*/}
            {/*                                                        handleBoxChange(index, "width", "0");*/}
            {/*                                                        handleBoxChange(index, "height", "0");*/}
            {/*                                                        handleBoxChange(index, "length", "0");*/}
            {/*                                                    }*/}
            {/*                                                    const updatedShipInfoDTO = {*/}
            {/*                                                        ...orderData.shipInfoDTO,*/}
            {/*                                                        weight: newValue*/}
            {/*                                                    };*/}
            {/*                                                    setOrderData({*/}
            {/*                                                        ...orderData,*/}
            {/*                                                        shipInfoDTO: updatedShipInfoDTO*/}
            {/*                                                    });*/}
            {/*                                                } else {*/}
            {/*                                                    handleBoxChange(index, "netWeight", newValue);*/}
            {/*                                                    if (!box.width && !box.height && !box.length) {*/}
            {/*                                                        handleBoxChange(index, "width", "0");*/}
            {/*                                                        handleBoxChange(index, "height", "0");*/}
            {/*                                                        handleBoxChange(index, "length", "0");*/}
            {/*                                                    }*/}
            {/*                                                }*/}
            {/*                                                setInputType(newValue === box.netWeight ? 'box' : 'shipInfoDTO');*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                                </tbody>*/}
            {/*                            </Table>*/}
            {/*                            <Table bordered className="reg-tb m-b-30">*/}
            {/*                                <tbody>*/}
            {/*                                <tr>*/}
            {/*                                    <td className="text-center"><b*/}
            {/*                                        className="font-s-14 font-c-btorage">Box {index + 1}</b></td>*/}
            {/*                                    <td className="text-center font-s-12"*/}
            {/*                                        colSpan="2">적용부피 &nbsp;|&nbsp; 적용중량*/}
            {/*                                    </td>*/}
            {/*                                    <td className="text-center font-s-12">총배송비용</td>*/}
            {/*                                </tr>*/}
            {/*                                <tr>*/}
            {/*                                    <th className="text-center">국내배송</th>*/}
            {/*                                    <td colSpan="1" className="text-center wd-150">*/}
            {/*                                        <a className="font-s-15"*/}
            {/*                                           style={{color: (parseFloat(box.width) + parseFloat(box.height) + parseFloat(box.length)) > 200 ? "red" : ""}}>*/}
            {/*                                            {(!isNaN(parseFloat(box.width)) ? parseFloat(box.width) : 0) +*/}
            {/*                                                (!isNaN(parseFloat(box.height)) ? parseFloat(box.height) : 0) +*/}
            {/*                                                (!isNaN(parseFloat(box.length)) ? parseFloat(box.length) : 0) + "cm"}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center wd-150">*/}
            {/*                                        <a className="font-s-15 font-b"*/}
            {/*                                           style={{color: parseFloat(box.netWeight) > 30 ? "red" : ""}}>*/}
            {/*                                            {box.netWeight + " kg"}*/}
            {/*                                        </a>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan="1" className="text-center">*/}
            {/*                                        <input*/}
            {/*                                            className="admin-input-line font-s-14 p-r-5 wd-300 ht-27 text-right font-b font-c-progress"*/}
            {/*                                            type="text" disabled*/}
            {/*                                            value={parseFloat(box.netWeight) > 30 ? 0 : (box.netWeight === "" ? "\u20A9 " + 0*/}
            {/*                                                : (domeExData.length > 0 ? "\u20A9 " + getAmountForDomeWeight(box.appliedWeightsD.D, domeExData).toLocaleString() : "N/A"))}/>*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                                </tbody>*/}
            {/*                            </Table>*/}
            {/*                        </div>*/}
            {/*                    ))}*/}
            {/*                </div>*/}
            {/*            )}*/}
            {/*            {selectedShipCompany === "국내배송" && tables.length > 0 && tables.length === tableCount - 1 && (*/}
            {/*                <div>*/}
            {/*                    <div className="admin-ord-reg-wrap m-t-40">*/}
            {/*                        <b className="m-l-5 font-s-13"><BsSquareFill className="admin-tb-header-notice"/>총배송비용[전체박스]</b>*/}
            {/*                        <i className="ord-accordion-icon" style={{transform: "rotate(90deg)"}}/>*/}
            {/*                    </div>*/}
            {/*                    <Table bordered className="reg-tb">*/}
            {/*                        <tbody>*/}
            {/*                        <tr>*/}
            {/*                            <th>국내배송</th>*/}
            {/*                            <td>*/}
            {/*                                <input*/}
            {/*                                    className="admin-input-line m-r-5 font-b font-c-btorage p-r-5 text-right wd-150 ht-25"*/}
            {/*                                    type="text" disabled*/}
            {/*                                    value={"\u20A9 " + calculateTotalDOMECost()}*/}
            {/*                                />*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                        </tbody>*/}
            {/*                    </Table>*/}
            {/*                </div>*/}
            {/*            )}*/}
            {/*        </main>*/}
            {/*    </section>*/}
            {/*</div>*/}

            {isLoading && <CommonLoading/>}
            {alertShow === true ?
                <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel1} eventHandle2={expRegComplete}
                                msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow3 === true ?
                <MsgSelectModal alertShow={alertShow3} eventHandle1={modalCancel3} eventHandle2={expRegComplete}
                                msgData1={msgConfirmData5} msgData2={msgConfirmData6}/> : null}
        </div>
    );
}

export default AdminRegModal
