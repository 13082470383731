import React, {useEffect, useState} from "react";
import commonClose from '../../../assets/img/commonClose.png'
import {useTranslation} from "react-i18next";

function KoreaTime({koreaTimeFalseFn}) {

    const { t } = useTranslation();

    //한국기준 시간
    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const week = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const dayWeek = week[date.getDay()];
    const DateViewValue = year + '-' + month + '-' + day + ' ' + dayWeek;

    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const id = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return (() => clearInterval(id))
    }, []);

    return(
        <div className="bookContainer">
            <div className="bookFrame ht-auto wd-400 border-rd4" style={{top: "calc(25vh - -15%)", left: "calc(50vw - 12%)"}}>
                <div className="ht-50 display-flex just-cont-sb align-center font-t-title p-l-13 p-r-10 border-bottom-bt">
                    <p className="font-s-18 font-c-default c-def">{t(`ktHeader`)}</p>
                    <div className="c-point wd-30 ht-30" onClick={koreaTimeFalseFn}>
                        <img className="m-a-opc-07 wd-100pc ht-100pc" alt="close" src={commonClose}/>
                    </div>
                </div>
                <div className="font-t-cont font-c-default c-def ht-80 align-center">
                    <div className="ht-100pc p-t-15 p-l-15">
                        <p>{DateViewValue}</p>
                        <p>{time.toLocaleTimeString('en-US')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default KoreaTime
