import React, {useEffect, useState} from "react";
import '../../css/web/UserLoginPg.css';
import naverLogo from '../../assets/img/naverLogo.png'
import googleLogo from '../../assets/img/googleLogo.png'
import {AiFillLock} from "react-icons/ai";
import {BsFillPersonCheckFill} from "react-icons/bs";
import {Link, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import SelectLangModal from "../../components/web/modal/SelectLangModal";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import {useTranslation} from "react-i18next";
import i18n from '../../i18n';
import CommonTermsPopup from "../../components/web/modal/CommonTermsPopup";
import HeaderLogo from "../../assets/img/HeaderLogo.png";

function UserLoginPg() {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation();

    const { state } = useLocation();
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [loginErrorMsg, setLoginErrorMsg] = useState(false);
    const [googleLoginErrorMsg, setGoogleLoginErrorMsg] = useState(false);
    const [autoLogin, setAutoLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isNaverLoading, setIsNaverLoading] = useState(false);
    const [isGoogleLoading, setIsGoogleLoading] = useState(false);

    const handleChange_email = (e) => {
        setId(e.target.value);
        setLoginErrorMsg(false);
    }
    const handleChange_password = (e) => {
        setPassword(e.target.value);
        setLoginErrorMsg(false);
    }

    // 체크하면 LCAT도 받고 로그인 유지
    const handleAutoLogin = (e) => {
        if (e.target.checked)
            setAutoLogin(true)
        else setAutoLogin(false)
    }

    // 로그인 비밀번호 양식에서 엔터누르면 로그인 버튼 클릭
    const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
            LoginCheck().then();
        }
    };

    // "2022-12-28T21:15:07.109Z"  // 좌측이 몇번째 자리까지 가져올건지, 우측이 시작위치
    // 앞에서 네글자 가져오기 이런식으로 년/월/일 가져오고, 중간에 하이픈 및 : 없애기
    const dateToString = JSON.stringify(new Date())
    const year = dateToString.substring(1, 5) * 60 * 60 * 24 * 365; // 2022년
    const month = dateToString.substring(8, 6) * 60 * 60 * 24 * 30; // 12월
    const day = dateToString.substring(11, 9) * 60 * 60 * 24; // 29일
    const hours = dateToString.substring(14, 12) * 60 * 60; // 07시
    const minutes = dateToString.substring(17, 15) * 60; // 39분
    const seconds = dateToString.substring(20, 18) * 1; // 20초
    const atTime = year + month + day + hours + minutes + seconds; // 2022-12-29 // 07:39:20

    // ■■■■■■■■■■■■■■ 비토리지 로그인
    async function LoginCheck() {
        if (id === "" || password === "") {
            setLoginErrorMsg(true);
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        try {
            const response = await axios.get("/member/userOutFl/" + id, {
                params: {
                    id: id,
                    password: password
                }
            });
            login(response.data);
        } catch (error) {
            setIsLoading(false);
            setLoginErrorMsg(true);
        }
    }

    const sendAccessInfo = async (deviceType, id) => {
        try {
            const response = await axios.post('/init/accessInfo', {
                userId: id,
                accessTime: new Date().toISOString(),
                machFl: deviceType
            });
        } catch (error) {
            console.error(error);
        }
    };

    const determineMachFl = () => {
        if (window.navigator.userAgent.includes('ReactNative')) {
            return 'A'; //App
        } else if (window.navigator.userAgent.includes('Mobi')) {
            return 'M'; //웹앱
        } else {
            return 'P'; //PC
        }
    };

    const login = async (res) => {
        if (res === "Y") {
            setLoginErrorMsg(true);
            setIsLoading(false);
        } else if (res === "N") {
            try {
                const response = await axios.post("/member/login", {
                    id: id,
                    password: password,
                    loginPath: location.pathname
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })

                const deviceType = determineMachFl();
                await sendAccessInfo(deviceType, id);

                const currentTime = new Date();
                if (autoLogin) {
                    localStorage.setItem('AToken', response.headers.get('Authorization'));
                    localStorage.setItem('lastLoginTime', currentTime);
                } else {
                    sessionStorage.setItem('AToken', response.headers.get('Authorization'));
                    sessionStorage.setItem('lastLoginTime', currentTime);
                }
                let navigateBackTo = location.state?.from || "/";
                navigate(navigateBackTo);
            } catch (error) {
                setIsLoading(false);
                setLoginErrorMsg(true);
            }
        }
    }

    // 언어선택
    const [selectLangSt, setSelectLangSt] = useState(false);
    const selectLangTrueFn = (() => {
        setSelectLangSt(true);
    })
    const selectLangFalseFn = (() => {
        setSelectLangSt(false);
    })
    const handleSelectLangChange = (lang) => {
        setSelectLangSt(false);
        // 로컬스토리지 언어변경 + i18next 언어변경 code
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang);
    }

    useEffect(() => {
        selectedGoogleId();
        selectedNaverId();
    }, [])

    const [googleId, setGoogleId] = useState(null);
    const selectedGoogleId = () => axios({
        method: 'get',
        url: '/credentials/secretManager/googleId',
    })
        .then((response) => {
            setGoogleId(response.data);
        })

    // ■■■■■■■■■■■■■■ 구글로그인
    const googleLogin = () => {
        setIsGoogleLoading(true);
        let popup = null;

        try {
            const codeClient = window.google.accounts.oauth2.initCodeClient({
                client_id: googleId,
                scope: 'openid email profile',
                ux_mode: 'popup',
                callback: async (response, error) => {
                    if (error) {
                        setIsGoogleLoading(false);
                        return;
                    }
                    try {
                        const res = await axios.post('/externalApi/googleLogin', { code: response.code });
                        const currentTime = new Date();
                        localStorage.setItem('AToken', res.headers['authorization']);
                        localStorage.setItem('lastLoginTime', currentTime);
                        const userEmail = res.data.email;
                        const deviceType = determineMachFl();
                        await sendAccessInfo(deviceType, userEmail);

                        let navigateBackTo = location.state?.from || "/";
                        navigate(navigateBackTo);
                    } catch (error) {
                        googleLoginErrorTrue();
                    }
                    setIsGoogleLoading(false);
                },
            });

            popup = codeClient.requestCode();

            const checkPopupClosed = setInterval(() => {
                if (!popup || popup.closed) {
                    clearInterval(checkPopupClosed);
                    setIsGoogleLoading(false);
                }
            }, 1000);
        } catch (e) {
            setIsGoogleLoading(false);
            console.error('Google login failed:', e);
        }
    };

    const googleLoginErrorTrue = (() => {
        setGoogleLoginErrorMsg(true);
    })
    const googleLoginErrorClose = (() => {
        setGoogleLoginErrorMsg(false);
    })

    // ■■■■■■■■■■■■■■ 네이버로그인
    const [naverId, setNaverId] = useState(null);
    const selectedNaverId = () => axios({
        method: 'get',
        url: '/credentials/secretManager/naverId',
    })
        .then((response) => {
            setNaverId(response.data);
        })

    function generateRandomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    async function naverLogin() {
        setIsNaverLoading(true);
        try {
            const clientId = naverId;
            const redirectUri = "https://btorage.com/NaverLoginLoading"; // 운영
            // const redirectUri = "http://localhost:3000/NaverLoginLoading"; // 개발
            const state = generateRandomString(16);
            const url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}`;
            checkLoginPopup(url);

        } catch (error) {
            setIsNaverLoading(false);
        }
    }

    function checkLoginPopup(url) {
        // url로 네이버창 띄우고 로그인되면 redirectUri로 전송
        // NaverLoginLoading으로 code, state 전달됨
        window.open(url, '_self')
    }

    const moveToIdPwFindPg = (() => {
        navigate("/UserIdPwFindPg");
    })

    const moveToJoinPg = (() => {
        navigate("/UserJoinPg");
    })

    const [termsOfUsePopup, setTermsOfUsePopup] = useState(false);
    const closeTermsOfUsePopup = (() => {
        setTermsOfUsePopup(false);
    })

    const [privacyPolicyPopup, setPrivacyPolicyPopup] = useState(false);
    const closePrivacyPolicyPopup = (() => {
        setPrivacyPolicyPopup(false);
    })

    return (
        <div className="UserLoginPg">
            <div className="LoginMain">
                <div className="LoginFrame" style={{position:"relative"}}>
                    <div className="AccPgLogo">
                        <a onClick={()=> navigate("/")}><img alt="mainLogo" style={{width:"159px", height:"38px", opacity: "0.8", cursor:"pointer"}} src={HeaderLogo}/></a>
                    </div>
                    <div className="LoginPgIdPwContainer font-t-cont">
                        <div className="LoginContainer acc-icon">
                            <div className="acc-lg-icon">
                                <i style={{paddingLeft: "5px"}}><BsFillPersonCheckFill/></i>
                            </div>
                            <div className="acc-input-form">
                                <input className="LoginIdInput" type="text" onChange={handleChange_email} onKeyDown={handleOnKeyPress} placeholder=" "/>
                                <label className="JoinLabel" style={{fontSize:"13px"}}>{t(`loginLabel1`)}</label>
                            </div>
                        </div>
                        <div className="LoginContainer acc-icon">
                            <div className="acc-lg-icon">
                                <i><AiFillLock/></i>
                            </div>
                            <div className="acc-input-form">
                                <input className="LoginIdInput" type="password" onChange={handleChange_password} onKeyDown={handleOnKeyPress} placeholder=" "/>
                                <label className="JoinLabel" style={{fontSize:"13px"}}>{t(`loginLabel2`)}</label>
                            </div>
                        </div>
                    </div>
                    { loginErrorMsg ?
                        <div className="JoinErrorMsg font-t-cont">
                            <p className="JoinErrMsg">&nbsp;{t(`loginErr1`)}</p>
                        </div>
                        :
                        null
                    }
                    <div className="autoLoginContainer font-t-cont">
                        <div className="user-center" style={{height:"100%"}}>
                        <div className="acc-ckbox-frame">
                            <input id="AutoCheckBox" type="checkbox" onClick={handleAutoLogin}/>
                            <label htmlFor="AutoCheckBox" className="btorage-hover">{t(`loginAuto`)}</label>
                        </div>
                        </div>
                        <div style={{display:"flex", alignItems:"flex-start", justifyContent:"space-between", width:"160px", height:"23px"}}>
                            <div onClick={moveToIdPwFindPg}><p className="btorage-hover font-s-14 c-point">{t(`loginRePw`)}</p></div>
                            <div style={{width: "1px", height: "13px", backgroundColor: "darkgray", alignSelf: "center", marginBottom:"2px"}}></div>
                            <div onClick={moveToJoinPg}><p className="btorage-hover font-s-14 c-point">{t(`loginJoin`)}</p></div>
                        </div>
                    </div>
                    <div className="font-t-cont">
                        <button onClick={LoginCheck} className="colorFullBt user-center" style={{
                            width: "100%",
                            height: "50px",
                            textAlign: "center",
                            fontSize: "20px",
                            borderRadius:"4px",
                            marginTop:"10px",
                        }}>
                            <style>
                                {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                            </style>
                            {isLoading ? (
                                <div className="spinner"></div>
                            ) : (
                                <p style={{margin:"0", fontSize:"16px", color:"white", zIndex:10}}>{t(`loginBt1`)}</p>
                            )}
                        </button>
                        <div id="naverLoginButton" onClick={naverLogin} style={{height:"50px", marginTop:"5px", border:"solid", borderWidth:"thin 1px", borderColor:"#EAEAEA",
                            cursor:"pointer", borderRadius:"4px", display:"flex", alignItems:"center"}}>
                            <div style={{width:"12%", position:"absolute", padding:"13px", display:"flex", alignItems:"center"}}>
                            <img style={{width:"40px", height:"40px", marginLeft:"-8px"}} src={naverLogo} alt=""/>
                            </div>
                            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <style>
                                    {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                                </style>
                                {isNaverLoading ? (
                                    <div className="blackSpinner"></div>
                                ) : (
                                    <p style={{margin:"0", width:"100%", height:"100%", display:"flex", justifyContent:"center",
                                        alignItems:"center", fontWeight:"lighter", fontSize:"15px", color:"black"}}>{t(`loginBt2`)}</p>
                                )}
                            </div>
                        </div>
                        <div onClick={googleLogin} style={{height:"50px", marginTop:"5px", border:"solid", borderWidth:"thin 1px", borderColor:"#EAEAEA", cursor:"pointer",
                            borderRadius:"4px", display:"flex", alignItems:"center"}}>
                            <div style={{width:"12%", position:"absolute", padding:"13px", display:"flex", alignItems:"center"}}>
                                <img style={{width:"19px", height:"19px"}} src={googleLogo} alt=""/>
                            </div>
                            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <style>
                                    {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                                </style>
                                {isGoogleLoading ? (
                                    <div className="blackSpinner"></div>
                                ) : (
                                    <p style={{margin:"0", width:"100%", height:"100%", display:"flex", justifyContent:"center",
                                        alignItems:"center", fontWeight:"lighter", fontSize:"15px", color:"black"}}>{t(`loginBt3`)}</p>
                                )}
                            </div>
                        </div>
                        { googleLoginErrorMsg &&
                        <MsgCheckModal googleLoginErrorMsg={googleLoginErrorMsg} googleLoginErrorClose={googleLoginErrorClose}/>
                        }
                    </div>
                    <div className="user-center font-t-cont" style={{height:"40px", position:"absolute", left:"70px", bottom:0, minWidth:"350px", flexDirection:"row", alignItems:"flex-start"}}>
                        <p className="btorage-hover c-point font-s-13" onClick={selectLangTrueFn}>{t(`lang`)}</p>
                        <p className="btorage-hover c-point font-s-13 m-l-30" onClick={()=> setTermsOfUsePopup(true)}>{t(`UseInfo`)}</p>
                        <p className="btorage-hover c-point font-s-13 m-l-30" onClick={()=> setPrivacyPolicyPopup(true)}>{t(`infoPolicy`)}</p>
                    </div>
                </div>
            </div>
            {selectLangSt ?
                <SelectLangModal selectLangTrueFn={selectLangTrueFn} selectLangFalseFn={selectLangFalseFn} handleSelectLangChange={handleSelectLangChange}/>
                :
                termsOfUsePopup ?
                    <CommonTermsPopup termsOfUsePopup={termsOfUsePopup} closeTermsOfUsePopup={closeTermsOfUsePopup}/>
                    :
                    privacyPolicyPopup ?
                        <CommonTermsPopup privacyPolicyPopup={privacyPolicyPopup} closePrivacyPolicyPopup={closePrivacyPolicyPopup}/>
                        :
                null
            }
        </div>
    )
}

export default UserLoginPg
