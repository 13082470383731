import React, {createRef, useEffect, useRef, useState} from "react";
import commonClose from "../../../assets/img/commonClose.png";
import {BiCaretDown} from "react-icons/bi";

function AdminDelSetModal({ closeModiShow, shipModifychecked, modifyData }) {
    const [shipNameErrorMsg, setShipNameErrorMsg] = useState(false);
    const [shipTypeErrorMsg, setShipTypeErrorMsg] = useState(false);
    const [shipApiFlErrorMsg, setShipApiFlErrorMsg] = useState(false);

    const [checkedStates, setCheckedStates] = useState(shipModifychecked.map(item => ({
        seqnum: item.seqnum,
        courierCompanyName: item.courierCompanyName || '',
        shippingCompanyCode: item.shippingCompanyCode || '작업구분선택',
        connFl: item.connFl || '연동여부선택',
        regDate: item.regDate,
        regTime: item.regTime,
        regNm: item.regNm,
    })));

    const handleFieldChange = (index, field, value) => {
        const newCheckedStates = [...checkedStates];
        newCheckedStates[index][field] = value;
        setCheckedStates(newCheckedStates);

        if (field === 'shippingCompanyCode') {
            setShipTypeErrorMsg(value.trim() === '' || value === '작업구분선택');
        } else if (field === 'connFl') {
            setShipApiFlErrorMsg(value.trim() === '' || value === '연동여부선택');
        }
    };

    const connFlRef = useRef([]);
    const shipTypeRef = useRef([]);

    if (shipTypeRef.current.length !== shipModifychecked.length) {
        shipTypeRef.current = Array(shipModifychecked.length).fill().map(() => createRef());
        connFlRef.current = Array(shipModifychecked.length).fill().map(() => createRef());
    }

    const [shipTypeShow, setShipTypeShow] = useState(null);
    const [connFlShow, setConnFlShow] = useState(null);
    // const shipTypeList = ["해외", "국내"];
    // const connFlList = ["연동", "미연동"];

    const shipTypeList = [
        { label: "국내", value: "0" },
        { label: "해외", value: "1" }
    ];

    const connFlList = [
        { label: "연동", value: "1" },
        { label: "미연동", value: "0" }
    ];

    const toggleShipTypeShow = (index) => {
        setShipTypeShow(shipTypeShow === index ? null : index);
    };

    const toggleConnFlShow = (index) => {
        setConnFlShow(connFlShow === index ? null : index);
    };

    const handleShipTypeChange = (index, value) => {
        handleFieldChange(index, 'shippingCompanyCode', value);
        setShipTypeShow(null);
    };

    const handleConnFlChange = (index, value) => {
        handleFieldChange(index, 'connFl', value);
        setConnFlShow(null);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                !shipTypeRef.current.some(ref => ref.current && ref.current.contains(event.target)) &&
                !connFlRef.current.some(ref => ref.current && ref.current.contains(event.target))
            ) {
                setShipTypeShow(null);
                setConnFlShow(null);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const validation = () => {
        let isValid = true;
        setShipNameErrorMsg(false);
        setShipTypeErrorMsg(false);
        setShipApiFlErrorMsg(false);

        checkedStates.forEach((item) => {
            if (!item.courierCompanyName || item.courierCompanyName.trim() === '') {
                setShipNameErrorMsg(true);
                isValid = false;
            }

            if (!item.shippingCompanyCode || item.shippingCompanyCode === "작업구분선택") {
                setShipTypeErrorMsg(true);
                isValid = false;
            }

            if (!item.connFl || item.connFl === "연동여부선택") {
                setShipApiFlErrorMsg(true);
                isValid = false;
            }
        });

        if (isValid) {
            modifyData(checkedStates);
        }
    };

    return(
        <div className="a-common-set-modal font-t-cont c-def">
            <div className="a-c-s-modi-frame">
                <div className="display-flex flex-column just-cont-sb ht-100pc">
                    <div className="ht-55 display-flex flex-row just-cont-sb align-center border-c-b">
                        <div></div>
                        <div className="m-l-45 p-t-4"><p className="font-t-title font-s-17">수정</p></div>
                        <div className="text-right m-r-7 m-t-1 c-point">
                            <img className="wd-32 ht-32 m-a-opc-07" onClick={closeModiShow} alt="closeImage" src={commonClose}/>
                        </div>
                    </div>
                    <div className="sm-f-body ht-100pc p-t-40 p-l-40 p-r-40 p-b-40">
                        <div className="display-flex flex-column gap10">
                            {checkedStates.map((data, i) => (
                                <div key={i} className="display-flex flex-row just-cont-sb">
                                    <input className="admin-input-line wd-5pc ht-45 font-s-14 text-center" disabled value={1 + i}/>
                                    <input className="admin-input-line wd-20pc ht-45 font-s-15 text-center font-c-btorage" disabled value={data.courierCompanyName}
                                           onChange={(e) => handleFieldChange(i, 'courierCompanyName', e.target.value)} />
                                    <div className="wd-35pc ht-45">
                                        <div ref={el => shipTypeRef.current[i] = el} className="wd-100pc ht-100pc position-relative">
                                            <button className="selectParentBt wd-100pc ht-100pc" onClick={() => toggleShipTypeShow(i)}>
                                                <p>{shipTypeList.find(ty => ty.value === data.shippingCompanyCode)?.label || "작업구분선택"}</p>
                                                <span className="position-absolute ht-100pc wd-20pc" style={{ left: "80%", top: "0" }}>
                                                    <i className="display-flex just-cont-center align-center text-center ht-100pc"><BiCaretDown /></i>
                                                </span>
                                            </button>
                                            {shipTypeShow === i && (
                                                <div className="wd-100pc border-c m-t-2 position-absolute" style={{ zIndex: 1 }}>
                                                    {shipTypeList.map((ty, index) => (
                                                        <div className="selectChildBt display-flex just-cont-center align-center c-point font-s-15 p-t-10 p-b-10"
                                                             key={index} value={ty.value} onClick={() => handleShipTypeChange(i, ty.value)}>
                                                            {ty.label}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="wd-35pc ht-45">
                                        <div ref={el => connFlRef.current[i] = el} className="wd-100pc ht-100pc position-relative">
                                            <button className="selectParentBt wd-100pc ht-100pc" onClick={() => toggleConnFlShow(i)}>
                                                <p>{connFlList.find(fl => fl.value === data.connFl)?.label || "연동여부선택"}</p>
                                                <span className="position-absolute ht-100pc wd-20pc" style={{ left: "80%", top: "0" }}>
                                                    <i className="display-flex just-cont-center align-center text-center ht-100pc"><BiCaretDown /></i>
                                                </span>
                                            </button>
                                            {connFlShow === i && (
                                                <div className="wd-100pc border-c m-t-2 position-absolute" style={{ zIndex: 1 }}>
                                                    {connFlList.map((fl, index) => (
                                                        <div className="selectChildBt display-flex just-cont-center align-center c-point font-s-15 p-t-10 p-b-10"
                                                             key={index} value={fl.value} onClick={() => handleConnFlChange(i, fl.value)}>
                                                            {fl.label}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="wd-100pc ht-80 text-center align-center">
                        <button className="admin-new-btn-color wd-100 ht-40 font-s-15 m-t-10" onClick={validation}>저장</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDelSetModal
