import React from "react";
import commonClose from "../../../assets/img/commonClose.png";
import commonGuide from "../../../assets/img/commonGuide.png";

function GuideModal({cartGuidePopup,     closeCartGuidePopup,     cartGuideHeader,     cartGuideContent,
                        orderGuidePopup,    closeOrderGuidePopup,    orderGuideHeader,    orderGuideContent,
                        offlineGuidePopup,  closeOfflineGuidePopup,  offlineGuideHeader,  offlineGuideContent,
                        transferGuidePopup, closeTransferGuidePopup, transferGuideHeader, transferGuideContent,
                        domesticGuidePopup, closeDomesticGuidePopup, domesticGuideHeader, domesticGuideContent,
                        deliveryGuidePopup, closeDeliveryGuidePopup, deliveryGuideHeader, deliveryGuideContent}) {

    //Close
    const closeFunction = cartGuidePopup ? closeCartGuidePopup
        : orderGuidePopup ? closeOrderGuidePopup
            : offlineGuidePopup ? closeOfflineGuidePopup
                : transferGuidePopup ? closeTransferGuidePopup
                    : domesticGuidePopup ? closeDomesticGuidePopup
                        : deliveryGuidePopup ? closeDeliveryGuidePopup
                            : null;

    //헤더
    const headerToDisplay = cartGuidePopup ? cartGuideHeader
        : orderGuidePopup ? orderGuideHeader
            : offlineGuidePopup ? offlineGuideHeader
                : transferGuidePopup ? transferGuideHeader
                    : domesticGuidePopup ? domesticGuideHeader
                        : deliveryGuidePopup ? deliveryGuideHeader
                            : "";

    //내용
    const contentToDisplay = cartGuidePopup ? cartGuideContent
        : orderGuidePopup ? orderGuideContent
            : offlineGuidePopup ? offlineGuideContent
                : transferGuidePopup ? transferGuideContent
                    : domesticGuidePopup ? domesticGuideContent
                        : deliveryGuidePopup ? deliveryGuideContent
                            : "";

    return(
        <div className="display-flex just-cont-center align-center position-fixed wd-100pc ht-100pc" style={{top:"0", left:"0", background:"rgba(0, 0, 0, 0.3)", zIndex:2}}>
            <div style={{width: "500px", height: "350px", background: "white", boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", borderRadius: "6px"}}>
                <div className="display-flex ht-20pc" style={{borderBottom: "solid 1px #EAEAEA", padding: "10px"}}>
                    <div className="ht-100pc wd-10pc display-flex just-cont-center align-center">
                        <img className="wd-30 ht-30" alt='' src={commonGuide}/>
                    </div>
                    <div className="display-flex align-center wd-80pc ht-100pc">
                        <p className="font-t-title font-s-17">{headerToDisplay}</p>
                    </div>
                    <div className="user-center wd-10pc ht-100pc">
                        <img className="wd-30 ht-30 m-a-opc-07 c-point" onClick={closeFunction} src={commonClose} alt="close"/>
                    </div>
                </div>
                <div className="font-t-cont font-s-16 ht-80pc" style={{padding: "20px", whiteSpace: 'pre-line'}}>
                    {contentToDisplay}
                </div>
            </div>
        </div>
    )
}

export default GuideModal
