import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Table} from "react-bootstrap";
import {BsSquareFill} from "react-icons/bs";
import '../../css/mobile/MAdmin.css';
import '../../css/Common.css';
import axios from "axios";
import MsgSelectModal from "../web/modal/MsgSelectModal";
import {connect} from "../../services/WebsocketService";
import useTheme from "../web/layoutscommon/DarkModeStorage";
import back from "../../assets/img/commonBack.png";
import {useNavigate} from "react-router-dom";
import orderIMG from "../../assets/img/mypgPayinfo.png";
import {IoIosArrowDropdown} from "@react-icons/all-files/io/IoIosArrowDropdown";
import {IoIosArrowDropright} from "@react-icons/all-files/io/IoIosArrowDropright";
import {useWebSocket} from "../../services/WebsocketContext";

function MAdminRegModal() {

    /** region DataTable */

    const storedOrderData = localStorage.getItem('orderData');
    const initialOrderData = storedOrderData ? JSON.parse(storedOrderData) : null;
    const [orderData, setOrderData] = useState(initialOrderData);
    const [inputType, setInputType] = useState(orderData.dataType === 'payinfo' ? 'shipInfoDTO' : 'box');
    const LCAT = localStorage.getItem('adminToken');
    const [shipcoData, setShipcoData] = useState([]);
    const [selectedShipCompany, setSelectedShipCompany] = useState(orderData.shipco);
    const [selectedDataType, setSelectedDataType] = useState(orderData.dataType);
    const [fedexExData, setFedexExData] = useState([]);
    const [emsExData, setEmsExData] = useState([]);
    const [shipExData, setShipExData] = useState([]);
    const [domeExData, setDomeExData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /** endregion DataTable*/

    /** region Function */

    const [tables, setTables] = useState([]);
    const [tableCount, setTableCount] = useState(1);
    const [showCostInputs, setShowCostInputs] = useState(false);
    const [showItemInputs, setShowItemInputs] = useState(false);
    const [showBoxInputs, setShowBoxInputs] = useState(true);
    const [showBoxDtPrice, setShowBoxDtPrice] = useState(Array(tableCount).fill(false));
    const handleToggle = (index) => {
        setShowBoxDtPrice(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };
    const [buyValue, setBuyValue] = useState(""); //구매비
    const [codValue, setCodValue] = useState(orderData.payInfoDTO?.[0]?.courierPay || ""); //착불비
    const [domeValue, setDomeValue] = useState(orderData.payInfoDTO?.[0]?.returnFee || ""); //국내반송비
    const [repackValue, setRePackValue] = useState(orderData.payInfoDTO?.[0]?.repackFee || ""); //재포장수수료
    const [agenciesValue, setAgenciesValue] = useState(orderData.payInfoDTO?.[0]?.agencyFee || ""); //대행수수료
    const [vacPackPrice, setVacPackPrice] = useState(orderData.payInfoDTO?.[0]?.comprFee || ""); //압축팩수수료
    const [etcValue, setEtcValue] = useState(""); //기타비용
    const [etcNmValue, setEtcNmValue] = useState(""); //기타비용 내용
    const [etcFeeValue, setEtcFeeValue] = useState(""); //기타수수료
    const [etcFeeNmValue, setEtcFeeNmValue] = useState(""); //기타수수료 내용
    const [fedexFuelValue, setFedexFuelValue] = useState(() => {
        if (orderData && orderData.dataType === 'orderdt') {
            return orderData.recinfoDTO?.countryDTO?.fedexFuelSurcharge || '';
        } else if (orderData && orderData.dataType === 'payinfo') {
            return orderData.orderdtDTO?.recinfoDTO?.countryDTO?.fedexFuelSurcharge || '';
        }
        return '';
    }); //fedex 유류할증료

    const [packSize, setPackSize] = useState('선택');
    const [vacPackPopup, setVacPackPopup] = useState(false);
    const [vacuumPack, setVacuumPack] = useState(!!orderData.payInfoDTO?.[0]?.comprFee);
    const [buyCheckbox, setBuyCheckbox] = useState(false);
    const [codCheckbox, setCodCheckbox] = useState(!!orderData.payInfoDTO?.[0]?.courierPay || false);
    const [domeCheckbox, setDomeCheckbox] = useState(!!orderData.payInfoDTO?.[0]?.returnFee);
    const [repackCheckbox, setRePackCheckbox] = useState(!!orderData.payInfoDTO?.[0]?.repackFee);
    const [agenciesCheckbox, setAgenciesCheckbox] = useState(!!orderData.payInfoDTO?.[0]?.agencyFee);
    const [etcCheckbox, setEtcCheckbox] = useState(false);
    const [etcFeeCheckbox, setEtcFeeCheckbox] = useState(false);
    const [fpackCheckbox, setFpackCheckbox] = useState(false);
    const [ffeeCheckbox, setFfeeCheckbox] = useState(false);
    const [efeeCheckbox, setEfeeCheckbox] = useState(false);
    const [emsAmount, setEmsAmount] = useState(false);
    const fpackValue = fpackCheckbox ? "Y" : "N";
    const [appliedWeights] = useState({E: "", F: "", S: ""});
    const [boxData, setBoxData] = useState(
        Array.from({ length: tableCount }, () => {
            let products = [];
            if (orderData.dataType === "orderdt") {
                products = orderData.productsDTO?.map(item => item.seqnum) || [];
            } else if (orderData.dataType === "payinfo") {
                products = orderData.packListDTO.productsDTO?.map(item => item.seqnum) || [];
            }
            return {
                width: "",
                height: "",
                length: "",
                netWeight: "",
                appliedWeights: { E: "", F: "", S: "" },
                products: products
            };
        })
    );

    const [appliedWeightsD] = useState({D: ""});
    const [boxDomeData, setBoxDomeData] = useState([{width: "", height: "", length: "", netWeight: "", appliedWeightsD: { D: "" }},]);

    const prodStPrint = (prodSt) => {
        const prodStStyles = {
            IS00: { color: "#98B0D4", label: "입고대기" },
            IS01: { color: "#98B0D4", label: "결제대기(상품)" },
            IS02: { color: "#98B0D4", label: "결제중(상품)" },
            IS03: { color: "#FFB378", label: "결제완료(상품)" },
            IS04: { color: "#FF8600", label: "구매완료" },
            IS05: { color: "#6EDA60", label: "센터도착" },
            IS06: { color: "#98B0D4", label: "패킹중" },
            IS07: { color: "#98B0D4", label: "결제대기(배송)" },
            IS08: { color: "#98B0D4", label: "결제중(배송)" },
            IS09: { color: "#FFB378", label: "결제완료(배송)" },
            IS10: { color: "#979696", label: "출고완료" },
            IS11: { color: "#E05E6D", label: "반송중" },
            IS12: { color: "#E05E6D", label: "신청취소" },
            IS13: { color: "#98B0D4", label: "구매중" },
            IS14: { color: "#98B0D4", label: "입고중" }
        };

        const style = prodStStyles[prodSt] || { color: "black", label: null };
        return <p style={{ margin: "0", color: style.color }}>{style.label}</p>;
    };

    const [userNm, setUserNm] = useState(orderData.dataType === 'payinfo' ? orderData.orderdtDTO.memberDTO.userName : orderData.memberDTO.userName);
    const [adminInfo, setAdminInfo] = useState([]);
    const selectAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data[0])
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    function roundToTen(value) {
        return Math.round(value / 10) * 10;
    }

    useEffect(() => {
        selectAdminInfo();
    }, [])

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!adminInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(adminInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [adminInfo.mbNum, isConnected, stompClient]);


    //region CheckBox

    const [checked, setChecked] = useState(false);

    const allcheckBoxChange = () => {
        setChecked(!checked);
        setBoxData(prevBoxData => {
            const updatedBoxData = [...prevBoxData];
            if (!checked) {
                updatedBoxData.forEach(box => {
                    if (box.products.length === 0) {
                        box.products = orderData.productsDTO.filter(item => item.prodSt === "IS05").map(item => item.seqnum);
                    }
                });
            } else {
                updatedBoxData.forEach(box => {
                    box.products = [];
                });
            }
            return updatedBoxData;
        });
    };

    const [selectedBoxIndex, setSelectedBoxIndex] = useState(Array(orderData.productsDTO?.length).fill(0));
    useEffect(() => {
        setSelectedBoxIndex(Array(orderData.productsDTO?.length).fill(0));
        setChecked(false);
    }, [orderData.productsDTO]);

    const [boxPopup, setBoxPopup] = useState(null);

    const handleBoxSelection = (productIndex, boxIndex) => {
        setSelectedBoxIndex(prevState => {
            const newState = [...prevState];
            newState[productIndex] = boxIndex;
            return newState;
        });

        setBoxData(prevBoxData => {
            const updatedBoxData = [...prevBoxData];
            const productSeqnum = orderData.productsDTO[productIndex].seqnum;
            const box = updatedBoxData[boxIndex];

            if (box.products.includes(productSeqnum)) {
                box.products = box.products.filter(seqnum => seqnum !== productSeqnum);
            } else {
                box.products.push(productSeqnum);
            }

            return updatedBoxData;
        });

        setBoxPopup(null);
    };

    //endregion

    const handleBoxChange = (index, field, value) => {
        const updatedBoxData = [...boxData];
        const updatedBoxDomeData = [...boxDomeData];

        if (selectedShipCompany === '국내배송') {
            updatedBoxDomeData[index][field] = value;
            setBoxDomeData(updatedBoxDomeData);
        } else {
            if (field === "fpackCheckbox") {
                updatedBoxData[index][field] = !updatedBoxData[index][field];
            } else {
                updatedBoxData[index][field] = value;
            }
            setBoxData(updatedBoxData);
        }
    };

    const handleVacPackSizeChange = (selectedSize) => {
        setPackSize(selectedSize);
        setVacPackPopup(false);

        if (selectedSize === '소형') {
            setVacuumPack(true);
            setVacPackPrice(10000);
        } else if (selectedSize === '대형') {
            setVacuumPack(true);
            setVacPackPrice(20000);
        } else {
            setVacuumPack(false);
            setVacPackPrice(0);
        }
    };

    const handleFedexValueChange = (index, value) => {
        setBoxData(prevBoxes => {
            const newBoxes = [...prevBoxes];
            newBoxes[index].fedexValue = value === "" ? "" : parseFloat(value);
            return newBoxes;
        });
    };

    const handlePlusClick = () => {
        setTables([...tables, tableCount]);
        setTableCount(tableCount + 1);
        if(selectedShipCompany === '국내배송') {
            const newBoxDomeData = [...boxDomeData, {width: "", height: "", length: "", netWeight: "", appliedWeightsD: { D: "" }}];
            setBoxDomeData(newBoxDomeData);
        } else {
            setBoxData(prevBoxData => [...prevBoxData, {width: "", height: "", length: "", netWeight: "", appliedWeights: { E: "", F: "", S: "" }, products: []}]);
        }
    };

    const handleMinusClick = () => {
        if (tables.length > 0) {
            setTables(tables.slice(0, -1));
            setTableCount(tableCount - 1);
            if(selectedShipCompany === '국내배송') {
                setBoxDomeData(boxDomeData.slice(0, -1));
            } else {
                // setBoxData(boxData.slice(0, -1));
                setBoxData(prevBoxData => prevBoxData.slice(0, -1));
            }
        }
    };

    // region Alert

    const [alertShow, setAlertShow] = useState(false);
    const [alertShow3, setAlertShow3] = useState(false);
    const msgConfirmData1 = "비용정보를 등록 하시겠습니까?";
    const msgConfirmData2 = "입력한 정보를 다시한번 확인해주세요.";
    const msgConfirmData5 = "입력된 정보를 저장 하시겠습니까?";
    const msgConfirmData6 = "수정된 정보를 다시한번 확인해주세요.";
    const alert = (() => {
        setAlertShow(true)
    })
    const alert3 = (() => {
        setAlertShow3(true)
    })
    const modalCancel1 = useCallback(() => {
        setAlertShow(false);
    })
    const modalCancel3 = useCallback(() => {
        setAlertShow3(false);
    })

    // endregion

    // region 부피중량 / 적용중량 계산

    const [boxEmsResultFees, setBoxEmsResultFees] = useState(Array(boxData.length).fill(0));
    const [boxFedexResultFees, setBoxFedexResultFees] = useState(Array(boxData.length).fill(0));
    const [boxSeaResultFees, setBoxSeaResultFees] = useState(Array(boxData.length).fill(0));

    const getShippingFees = () => {
        const countryDTO = orderData.recinfoDTO && orderData.recinfoDTO.countryDTO
            ? orderData.recinfoDTO.countryDTO
            : (orderData.appRecDTO && orderData.appRecDTO.countryDTO
                ? orderData.appRecDTO.countryDTO
                : null);
        return {
            emsFee: countryDTO ? parseFloat(countryDTO.emsFee || 0) : 0,
            fedexFee: countryDTO ? parseFloat(countryDTO.fedexFee || 0) : 0,
            seaFee: countryDTO ? parseFloat(countryDTO.seaFee || 0) : 0
        };
    };

    const { emsFee, fedexFee, seaFee } = getShippingFees();

    const [emsFeeState, setEmsFee] = useState(emsFee);
    const [fedexFeeState, setFedexFee] = useState(fedexFee);
    const [seaFeeState, setSeaFee] = useState(seaFee);

    const appliedWeightE = parseFloat(appliedWeights.E);
    const [emsResultFee, setEmsResultFee] = useState(emsFee * appliedWeightE * 10);

    const appliedWeightF = parseFloat(appliedWeights.F);
    const [fedexResultFee, setFedexResultFee] = useState(fedexFee * appliedWeightF * 10);

    const appliedWeightS = parseFloat(appliedWeights.S);
    const [seaResultFee, setSeaResultFee] = useState(seaFee * appliedWeightS * 10);

    const getVolumeWeightFedex = (width, height, length) => {
        return ((width * height * length) / 5000).toFixed(2);
    };

    const getVolumeWeightEms = (width, height, length) => {
        return ((width * height * length) / 6000).toFixed(2);
    };

    const getVolumeWeightDome = (width, height, length) => {
        return ((width + height + length) / 5000).toFixed(1);
    };

    const emsThresholds = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14,
        14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27, 27.5,
        28, 28.5, 29, 29.5, 30,
        30.5, 31, 31.5, 32, 32.5, 33, 33,5, 34, 34.5, 35, 35.5, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40]; //초과지점
    const fedexThresholds = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5,
        16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        30.5, 31, 31.5, 32, 32.5, 33, 33.5, 34, 34.5, 35, 35.5, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40]; //초과지점
    const seaThresholds = [1, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20];
    const domeThresholds = [3, 5, 10, 20, 25];

    const applyWeightBands = (volumeWeight, thresholds) => {
        return thresholds.find(threshold => volumeWeight <= threshold) || thresholds[thresholds.length - 1];
    };

    const applyWeightDomeBands = (volumeWeight, thresholds) => {
        return thresholds.find(threshold => volumeWeight <= threshold) || thresholds[thresholds.length - 1];
    };

    const getAmountForWeight = (appliedWeight, data) => {
        const weightColumn = `w${Math.round(appliedWeight * 1000)}`;
        const dataObject = data.find(item => item[weightColumn] !== undefined);
        return dataObject ? dataObject[weightColumn] : 0;
    };

    const getAmountForDomeWeight = (appliedWeight, data) => {
        const weightColumn = `w${Math.round(appliedWeight * 1000)}`;
        const dataObject = data.find(item => item[weightColumn] !== undefined);
        return dataObject ? dataObject[weightColumn] : 0;
    };

    useEffect(() => {
        const updatedBoxData = [...boxData];
        let totalEmsFees = Array(updatedBoxData.length).fill(0);
        let totalFedexFees = Array(updatedBoxData.length).fill(0);
        let totalSeaFees = Array(updatedBoxData.length).fill(0);

        for (let i = 0; i < updatedBoxData.length; i++) {
            const width = parseFloat(updatedBoxData[i].width);
            const height = parseFloat(updatedBoxData[i].height);
            const length = parseFloat(updatedBoxData[i].length);
            const netWeight = parseFloat(updatedBoxData[i].netWeight);

            if (!isNaN(width) && !isNaN(height) && !isNaN(length)) {
                const volumeWeightE = ((width * length * height) / 6000).toFixed(2);
                const volumeWeightF = ((width * length * height) / 5000).toFixed(2);

                const weightE = applyWeightBands(Math.max(netWeight, volumeWeightE), emsThresholds);
                const weightF = applyWeightBands(Math.max(netWeight, volumeWeightF), fedexThresholds);
                const weightS = applyWeightBands(Math.max(netWeight), seaThresholds);

                updatedBoxData[i].appliedWeights = { E: weightE, F: weightF, S: weightS };

                totalEmsFees[i] = emsFee * weightE * 10;
                totalFedexFees[i] = fedexFee * weightF * 10;
                totalSeaFees[i] = seaFee * weightS * 10;
            }
        }

        setBoxEmsResultFees(totalEmsFees);
        setBoxFedexResultFees(totalFedexFees);
        setBoxSeaResultFees(totalSeaFees);

        if (JSON.stringify(updatedBoxData) !== JSON.stringify(boxData)) {
            setBoxData(updatedBoxData);
        }
    }, [boxData, emsFee, fedexFee, emsExData]);

    useEffect(() => {
        const updatedBoxDomeData = [...boxDomeData];

        for (let i = 0; i < updatedBoxDomeData.length; i++) {
            const width = parseFloat(updatedBoxDomeData[i].width);
            const height = parseFloat(updatedBoxDomeData[i].height);
            const length = parseFloat(updatedBoxDomeData[i].length);
            const netWeight = parseFloat(updatedBoxDomeData[i].netWeight);

            if (!isNaN(width) && !isNaN(height) && !isNaN(length)) {
                const weightD = applyWeightDomeBands(Math.max(netWeight), domeThresholds);

                updatedBoxDomeData[i].appliedWeightsD = { D: weightD };
            }
        }
        if (JSON.stringify(updatedBoxDomeData) !== JSON.stringify(boxDomeData)) {
            setBoxDomeData(updatedBoxDomeData);
        }
    }, [boxDomeData]);

    // endregion

    const calculateTotalEMSCost = () => {
        let totalCost = 0;
        let totalFees = boxEmsResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 30) {
                totalCost += getAmountForWeight(box.appliedWeights.E, emsExData);
            } else {
                totalFees -= boxEmsResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);
            }
        });

        return (totalCost + totalFees).toLocaleString();
    };

    const calculateTotalFEDEXCost = () => {
        let totalCost = 0;
        let totalFuelFees = 0;

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 30) {
                totalCost += roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData));
                totalFuelFees += roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData) * (fedexFuelValue / 100));
            } else {
                totalCost += Math.round(parseFloat(box.fedexValue || 0));
            }
        });

        const totalFees = boxFedexResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        return (totalCost + totalFees + totalFuelFees).toLocaleString();
    };

    const calculateTotalSHIPCost = () => {
        let totalCost = 0;
        let totalFees = boxSeaResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 20) {
                totalCost += getAmountForWeight(box.appliedWeights.S, shipExData);
            } else {
                totalFees -= boxSeaResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);
            }
        });

        return (totalCost + totalFees).toLocaleString();
    };

    const calculateTotalDOMECost = () => {
        let totalCost = 0;
        boxDomeData.forEach(box => {
            if (parseFloat(box.netWeight) <= 30) {
                totalCost += getAmountForDomeWeight(box.appliedWeightsD.D, domeExData);
            }
        });
        return (totalCost).toLocaleString();
    };

    //region 다크모드

    const [theme] = useTheme();

    //endregion

    const navigate = useNavigate();
    const pageBack = (() => {
        navigate(-1);
    })

    const [shipCompanyPopup, setShipCompanyPopup] = useState(false);

    const handleShipCompanyClick = (company) => {
        setSelectedShipCompany(company);
        setShipCompanyPopup(false);

        let shipCompanyCode;
        switch (company) {
            case "국내배송":
                shipCompanyCode = "D00";
                break;
            case "FedEx":
                shipCompanyCode = "D01";
                break;
            case "EMS":
                shipCompanyCode = "D02";
                break;
            case "SHIP":
                shipCompanyCode = "D03";
                break;
            case "DHL":
                shipCompanyCode = "D04";
                break;
            default:
                shipCompanyCode = "";
        }
        // getPayInfoBoxData(shipCompanyCode);
    };

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + '...';
    };

    /** endregion */

    /** region Event */

    useEffect(() => {
        shipCompanySelect();
        fedexExrSelect();
        emsExrSelect();
        shipExrSelect();
    }, []);

    // useEffect(() => {
    //     if (orderData.dataType === 'payinfo') {
    //         payTypeSelect();
    //     }
    // }, []);

    const selectPackSize = (price) => {
        if (price === 10000) {
            setPackSize('소형');
        } else if (price === 20000) {
            setPackSize('대형');
        } else {
            setPackSize('선택');
        }
    };

    useEffect(() => {
        if (vacPackPrice !== '') {
            selectPackSize(parseInt(vacPackPrice));
        }
    }, [vacPackPrice]);

    const shipCompanySelect = () => {
        axios.get('/shipcompany/shipcoselect')
            .then((response) => {
                const shipType = response.data.filter(cData => cData.shippingCompanyCode === '1');
                setShipcoData(shipType);
            }, [])
            .catch(() => {})
    }

    const fedexExrSelect = () => {
        axios.get('/shipinfo/fedexexrdata')
            .then((response) => {
                const countryCode = orderData.type === 'orderdt'
                    ? orderData.recinfoDTO.countryDTO.countryCode
                    : orderData.type === 'payinfo'
                        ? orderData.appRecDTO.countryDTO.countryCode
                        : null;

                if (!countryCode) return;

                const fCountryCode = response.data.filter(cData => {
                    return cData.appGroup === countryCode ||
                        (cData.appGroup === 'A' && countryCode === 'MO') ||
                        (cData.appGroup === 'D' && ['KH', 'LA', 'MN'].includes(countryCode)) ||
                        (cData.appGroup === 'F' && ['CA', 'US', 'MX'].includes(countryCode)) ||
                        (cData.appGroup === 'G' && ['AT', 'CZ', 'DK', 'FI', 'GR', 'HU', 'IE', 'NO', 'PL', 'PT', 'SE', 'CH'].includes(countryCode)) ||
                        (cData.appGroup === 'H' && ['IL', 'TR', 'RU', 'UA', 'UZ'].includes(countryCode)) ||
                        (cData.appGroup === 'I' && ['AR', 'BR', 'CL', 'PE'].includes(countryCode)) ||
                        (cData.appGroup === 'J' && ['BD', 'MA', 'QA', 'AE', 'JO', 'SA'].includes(countryCode)) ||
                        (cData.appGroup === 'M' && ['BE', 'DE', 'IT', 'NL', 'ES', 'GB', 'FR'].includes(countryCode)) ||
                        (cData.appGroup === 'N' && countryCode === 'VN') ||
                        (cData.appGroup === 'O' && countryCode === 'IN') ||
                        (cData.appGroup === 'P' && countryCode === 'JP') ||
                        (cData.appGroup === 'Q' && countryCode === 'MY') ||
                        (cData.appGroup === 'R' && countryCode === 'TH') ||
                        (cData.appGroup === 'S' && countryCode === 'PH') ||
                        (cData.appGroup === 'T' && countryCode === 'ID') ||
                        (cData.appGroup === 'U' && ['AU', 'NZ'].includes(countryCode)) ||
                        (cData.appGroup === 'V' && countryCode === 'HK') ||
                        (cData.appGroup === 'W' && countryCode === 'CN') ||
                        (cData.appGroup === 'X' && countryCode === 'TW') ||
                        (cData.appGroup === 'Y' && countryCode === 'SG');
                });
                setFedexExData(fCountryCode);
            })
            .catch((error) => {console.error("err:", error);});
    }

    const emsExrSelect = () => {
        axios.get('/shipinfo/emsexrdata')
            .then((response) => {
                const countryCode = orderData.type === 'orderdt' ? orderData.recinfoDTO.countryDTO.countryCode
                    : orderData.type === 'payinfo' ? orderData.appRecDTO.countryDTO.countryCode : null;
                if (!countryCode) return;

                const eCountryCode = response.data.filter(cData => {
                    return cData.countryDTO.countryCode === countryCode ||
                        ((countryCode === 'LA' || countryCode === 'MO') && cData.seqNum === 21) ||
                        (countryCode === 'BD' && cData.seqNum === 22) ||
                        (['IE', 'NL', 'PL', 'CH', 'IL', 'AE'].includes(countryCode) && cData.seqNum === 23) ||
                        (['PE', 'CL', 'MX'].includes(countryCode) && cData.seqNum === 24);
                });

                setEmsExData(eCountryCode);
            })
            .catch((error) => {
                console.error("Error: ", error);
            });
    }

    const shipExrSelect = () => {
        axios.get('/shipinfo/shipexrdata')
            .then((response) => {
                const countryCode = orderData.type === 'orderdt'
                    ? orderData.recinfoDTO.countryDTO.countryCode
                    : orderData.type === 'payinfo'
                        ? orderData.appRecDTO.countryDTO.countryCode
                        : null;

                if (!countryCode) return;

                const sCountryCode = response.data.filter(cData => {
                    return cData.appShipCode === countryCode ||
                        (['CN', 'HK', 'JP', 'TW'].includes(countryCode) && cData.appShipCode === 'Z1') ||
                        (['BD', 'ID', 'MY', 'PH', 'SG', 'VN'].includes(countryCode) && cData.appShipCode === 'Z2') ||
                        (['AU', 'CA', 'DE', 'ES', 'FR', 'GB', 'IE', 'NL', 'NZ', 'PL', 'US'].includes(countryCode) && cData.appShipCode === 'Z3') ||
                        (['PE', 'CL', 'MX'].includes(countryCode) && cData.appShipCode === 'Z4');
                });
                setShipExData(sCountryCode);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    useEffect(() => {
        const fetchDomeExData = async () => {
            try {
                const response = await axios.get("/shipinfo/domeexrdata");
                const volumeRanges = {
                    "V08": { min: 1, max: 80 },
                    "V10": { min: 81, max: 100 },
                    "V12": { min: 101, max: 120 },
                    "V14": { min: 121, max: 140 },
                    "V16": { min: 141, max: 160 },
                    "V20": { min: 161, max: 200 },
                };
                const totalVolume = parseFloat(boxDomeData[0].width) + parseFloat(boxDomeData[0].height) + parseFloat(boxDomeData[0].length);

                const filteredData = response.data.filter(item => {
                    const range = volumeRanges[item.volumes];
                    return totalVolume >= range.min && totalVolume <= range.max;
                });

                setDomeExData(filteredData);
            } catch (error) {
                console.error("Error: ", error);
            }
        };
        fetchDomeExData();
    }, [boxDomeData]);

    const boxesData = boxData.map((box, index) => ({
        wid: parseFloat(box.width || 0),
        ver: parseFloat(box.height || 0),
        hei: parseFloat(box.length || 0),
        weight: parseFloat(box.netWeight || 0),
        fVweight: parseFloat(getVolumeWeightFedex(box.width, box.height, box.length || 0)),
        fApyWeight: parseFloat(box.appliedWeights.F || 0),
        fedexPack: box.fpackCheckbox ? '1' : '0',
        eVweight: parseFloat(getVolumeWeightEms(box.width, box.height, box.length  || 0)),
        eApyWeight: parseFloat(box.appliedWeights.E || 0),
        sApyWeight: parseFloat(box.netWeight || 0),
        dVweight: 0,
        dApyWeight: 0,
    }));

    const boxesDomeData = boxDomeData.map((box, index) => ({
        wid: parseFloat(box.width || 0),
        ver: parseFloat(box.height || 0),
        hei: parseFloat(box.length || 0),
        weight: parseFloat(box.netWeight || 0),
        fVweight: 0,
        fApyWeight: 0,
        fedexPack: '0',
        eVweight: 0,
        eApyWeight: 0,
        sApyWeight: 0,
        dVweight: (!isNaN(parseFloat(box.width)) ? parseFloat(box.width) : 0) + (!isNaN(parseFloat(box.height)) ? parseFloat(box.height) : 0) + (!isNaN(parseFloat(box.length)) ? parseFloat(box.length) : 0),
        dApyWeight: parseFloat(box.appliedWeightsD.D || 0),
    }));

    const shipModiInfoData = {
        seqNum: (orderData.dataType === 'payinfo') ? orderData.shipInfoDTO.seqNum : undefined,
    };

    const shipInfoData = boxesData.map((box, index) => ({
        boxNum: `B${(index + 1).toString().padStart(4, '0')}`,
        wid: box.wid,
        ver: box.ver,
        hei: box.hei,
        weight: box.weight,
        fedexWeight: box.fVweight,
        fedexApyWeight: box.fApyWeight,
        fedexPack: box.fedexPack,
        emsWeight: box.eVweight,
        emsApyWeight: box.eApyWeight,
        seaApyWeight: box.sApyWeight
    }));

    const shipInfoDomeData = boxesDomeData.map((box, index) => ({
        boxNum: `B${(index + 1).toString().padStart(4, '0')}`,
        wid: box.wid,
        ver: box.ver,
        hei: box.hei,
        weight: box.weight,
        fedexWeight: box.fVweight,
        fedexApyWeight: box.fApyWeight,
        fedexPack: box.fedexPack,
        emsWeight: box.eVweight,
        emsApyWeight: box.eApyWeight,
        seaApyWeight: box.sApyWeight,
        domeWeight: box.dVweight,
        domeApyWeight: box.dApyWeight,
    }));

    const orderDtData = {
        seqnum: (orderData.dataType === 'orderdt') ? orderData.seqnum : orderData.orderdtDTO.seqnum,
        orderStatus: 'OS02'
    };

    const payInfoBuyData = {
        ttProdPrice: buyValue,
    };

    const tEp = (Number(etcValue) || 0) + (Number(codValue) || 0) + (Number(domeValue) || 0);
    const tEf = (Number(etcFeeValue) || 0) + (Number(repackValue) || 0) + (Number(agenciesValue) || 0) + (Number(vacPackPrice) || 0);

    const payInfoEtcData = {
        courierPay: codValue ? codValue : 0,//착불비
        returnFee: domeValue ? domeValue : 0, //인천반송비
        repackFee: repackValue ? repackValue : 0, //재포장수수료
        agencyFee: agenciesValue ? agenciesValue : 0, //대행수수료
        comprFee: vacPackPrice ? vacPackPrice : 0, //압축팩수수료
        etcPrice: etcValue ? etcValue : 0, //기타비용
        etcPriceNm: etcNmValue ? etcNmValue : '', //기타비용명
        ttEtcPrice: tEp, //총기타비용
        etcFee: etcFeeValue ? etcFeeValue : 0, //기타수수료
        etcFeeNm: etcFeeNmValue ? etcFeeNmValue : '', //기타수수료명
        ttEtcFee: tEf, //총기타수수료
        lastPrice: tEp + tEf
    };

    const [initialPayInfoBoxData, setInitialPayInfoBoxData] = useState();

    const getPayInfoBoxData = (selectedShipCompany, boxData) => {
        return boxData.map((box, index) => {
            const fedexPrice = parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30 ? 0 : roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData));
            const fedexFuelSurch = Math.round((fedexPrice * (fedexFuelValue / 100)) / 10) * 10;
            const fedexAddFee = parseInt(boxFedexResultFees[index]);
            const ttFedexPrice = fedexPrice + fedexFuelSurch + fedexAddFee;

            const emsPrice = parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? 0 : getAmountForWeight(box.appliedWeights.E, emsExData);
            const emsAddFee = parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? 0 : parseInt(boxEmsResultFees[index]);
            const ttEmsPrice = emsPrice + emsAddFee;

            const shipPrice = parseFloat(box.netWeight) > 20 ? 0 : getAmountForWeight(box.appliedWeights.S, shipExData);
            const seaAddFee = parseFloat(box.netWeight) > 20 ? 0 : parseInt(boxSeaResultFees[index]);
            const ttShipPrice = shipPrice + seaAddFee;

            const ttDomPrice = 0;
            let lastPrice;

            switch (selectedShipCompany) {
                case "FedEx":
                    lastPrice = ttFedexPrice;
                    break;
                case "EMS":
                    lastPrice = ttEmsPrice;
                    break;
                case "SHIP":
                    lastPrice = ttShipPrice;
                    break;
                default:
                    lastPrice = 0;
            }

            return {
                fedexPrice: fedexPrice,
                emsPrice: emsPrice,
                seaPrice: shipPrice,
                fedexFuelSurch: fedexFuelSurch,
                ttFedexPrice: ttFedexPrice,
                ttEmsPrice: ttEmsPrice,
                ttSeaPrice: ttShipPrice,
                fedexAddFee: fedexAddFee,
                emsAddFee: emsAddFee,
                seaAddFee: seaAddFee,
                ttDomPrice: ttDomPrice,
                lastPrice: lastPrice,
                payStatus: 'PS0',
            };
        });
    };

    const getPayInfoBoxDomeData = (boxDomeData) => {
        return boxDomeData.map((box, index) => {
            const fedexPrice = 0;
            const emsPrice = 0;
            const shipPrice = 0;
            const fedexFuelSurch = 0;
            const ttFedexPrice = 0;
            const ttEmsPrice = 0;
            const emsAddFee = 0;
            const fedexAddFee = 0;
            const seaAddFee = 0;
            const ttDomPrice = parseFloat(box.netWeight) > 30 ? 0 : getAmountForDomeWeight(box.appliedWeightsD.D, domeExData);

            return {
                fedexPrice: fedexPrice,
                emsPrice: emsPrice,
                seaPrice: shipPrice,
                fedexFuelSurch: fedexFuelSurch,
                ttFedexPrice: ttFedexPrice,
                ttEmsPrice: ttEmsPrice,
                ttSeaPrice: shipPrice,
                fedexAddFee: fedexAddFee,
                emsAddFee: emsAddFee,
                seaAddFee: seaAddFee,
                ttDomPrice: ttDomPrice,
                lastPrice: ttDomPrice,
                payStatus: 'PS2'
            };
        });
    };

    const appReqData = {
        seqnum: (orderData.reqmnDTO ? orderData.reqmnDTO.seqnum : (orderData.appReqDTO ? orderData.appReqDTO.seqnum : null))
    };

    const appRecData = {
        seqnum: (orderData.recinfoDTO ? orderData.recinfoDTO.seqnum : (orderData.appRecDTO ? orderData.appRecDTO.seqnum : null))
    };

    const productInfoMData = orderData.packListDTO ? orderData.packListDTO.productsDTO : [].map(product => ({
        seqnum: product.seqnum,
        prodSt: 'IS07'
    }));

    const expRegComplete = async () => {
        if (isLoading) return;

        setIsLoading(true);

        try {
            const selectedProductData = boxData.flatMap(box => box.products);
            const selectedProducts = (orderData.productsDTO || orderData.packListDTO?.productsDTO || [])
                .filter(item =>
                    ['IS05', 'IS06'].includes(item.prodSt) && selectedProductData.includes(item.seqnum)
                );

            const productData = selectedProducts.map(product => ({
                seqnum: product.seqnum,
                prodSt: 'IS07'
            }));

            const productInfoMData = orderData.packListDTO ?
                orderData.packListDTO.productsDTO.map(product => ({
                    seqnum: product.seqnum,
                    prodSt: 'IS07'
                })) : [];

            const selectedData = selectedShipCompany === "국내배송" ? shipInfoDomeData : shipInfoData;
            const productInfoData = selectedDataType === "orderdt" ? productData : productInfoMData;
            const formData = new FormData();

            const dataToAppend = {
                orderDtData,
                payInfoBuyData,
                payInfoEtcData,
                payInfoBoxData: selectedShipCompany === "국내배송" ? getPayInfoBoxDomeData(boxDomeData) : getPayInfoBoxData(selectedShipCompany, boxData),
                shipInfoData: selectedData,
                productInfoData,
                appReqData,
                appRecData,
                shipModiInfoData,
                dataType: orderData.dataType
            };

            Object.entries(dataToAppend).forEach(([key, value]) => {
                formData.append(key, typeof value === 'string' ? value : JSON.stringify(value));
            });

            const response = await axios.post('/ordPay/expreginsert', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: LCAT
                }
            });

            if (Number(boxData[0].netWeight) > 0 || Number(boxesDomeData[0].weight) > 0) {
                await stompClient.send("/app/updateOrderList", {}, JSON.stringify({userMbNum: orderData.memberDTO.mbNum}));
                sendMessage();
            }

            setInitialPayInfoBoxData(selectedShipCompany === "국내배송" ? getPayInfoBoxDomeData(boxDomeData) : getPayInfoBoxData(selectedShipCompany, boxData));

            if (window.opener) {
                window.opener.location.reload();
                window.close();
            }

        } catch (error) {
            console.error("Error r:", error.response);
            console.error("Error m:", error.message);
            console.error("Error c:", error.config);
        } finally {
            setIsLoading(false);
        }
    };

    const myPgLink = `https://btorage.com/UserMyPg`;
    // <a href="${myPgLink}">마이페이지 바로가기</a>

    const domesticChatKoMsg = `안녕하세요 ${orderData.memberDTO?.userName}님^^*\n포장완료 후 주문서 상세페이지의 결제목록에 국내배송료가 등록되었습니다.\n\n결제 전까지 결제목록의 '상세'버튼을 통해 열리는 팝업창에서\n최종 한국 수취인정보를 한 번 더 확인해주세요 :)\n\n저희 비토리지를 믿고 이용해주셔서 진심으로 감사드립니다 !`.replace(/\n/g, '');
    const domesticChatEnMsg = `Hello ${orderData.memberDTO?.userName}^^*\nThe domestic shipping fee has been added to the payment list on the order details page after packaging is complete.\n\nBefore making payment, please double-check the final recipient information in Korea through the pop-up window that opens by clicking the 'Details' button in the payment list :)\n\nThank you sincerely for trusting and using Btorage!`;
    const domesticChatJaMsg = `こんにちは、${orderData.memberDTO?.userName}さん^^*\n梱包完了後、注文書詳細ページの決済リストに国内送料が登録されました。\n\n決済前に、決済リストの「詳細」ボタンから開くポップアップ画面で\n最終受取人情報をもう一度ご確認ください :)\n\nBtorageをご利用いただき、本当にありがとうございます！`;
    const domesticChatZhMsg = `您好，${orderData.memberDTO?.userName}^^*\n打包完成后，订单详情页面的支付列表中已登记国内运费。\n\n在支付之前，请通过支付列表中的“详情”按钮打开的弹窗\n再次确认最终的韩国收件人信息 :)\n\n感谢您信任并使用Btorage！`;
    const domesticChatIdMsg = `Halo ${orderData.memberDTO?.userName}^^*\nSetelah pengemasan selesai, biaya pengiriman domestik telah terdaftar di daftar pembayaran pada halaman detail pesanan.\n\nSebelum melakukan pembayaran, pastikan untuk memeriksa kembali informasi penerima terakhir di Korea melalui jendela pop-up yang terbuka saat Anda mengklik tombol 'Detail' di daftar pembayaran :)\n\nTerima kasih telah mempercayai dan menggunakan Btorage!`;

    const overseasChatKoMsg = `안녕하세요 ${orderData.memberDTO?.userName}님^^*\n포장완료 후 주문서 상세페이지의 결제목록에 해외배송료가 등록되었습니다.\n\n결제 전까지 결제목록의 '상세'버튼을 통해 열리는 팝업창에서\n반드시 최종 수취인정보/세관정보를 확인하신 후 배송사를 선택/결제해주세요 :)\n\n저희 비토리지를 믿고 이용해주셔서 진심으로 감사드립니다 !`;
    const overseasChatEnMsg = `Hello ${orderData.memberDTO?.userName}^^*\nThe international shipping fee has been added to the payment list on the order details page after packaging is complete.\n\nBefore making payment, please be sure to confirm the final recipient and customs information through the pop-up window that opens by clicking the 'Details' button in the payment list, then select the shipping company and proceed with payment :)\n\nThank you sincerely for trusting and using Btorage!`;
    const overseasChatJaMsg = `こんにちは、${orderData.memberDTO?.userName}さん^^*\n梱包完了後、注文書詳細ページの決済リストに海外送料が登録されました。\n\n決済前に、決済リストの「詳細」ボタンから開くポップアップ画面で\n必ず最終受取人情報/通関情報をご確認の上、配送業者を選択し決済してください :)\n\nBtorageをご利用いただき、本当にありがとうございます！`;
    const overseasChatZhMsg = `您好，${orderData.memberDTO?.userName}^^*\n打包完成后，订单详情页面的支付列表中已登记国际运费。\n\n在支付之前，请务必通过支付列表中的“详情”按钮打开的弹窗\n确认最终收件人信息/海关信息后再选择配送公司并支付 :)\n\n感谢您信任并使用Btorage！`;
    const overseasChatIdMsg = `Halo ${orderData.memberDTO?.userName}^^*\nSetelah pengemasan selesai, biaya pengiriman internasional telah terdaftar di daftar pembayaran pada halaman detail pesanan.\n\nSebelum melakukan pembayaran, pastikan untuk memeriksa informasi penerima terakhir/informasi bea cukai di jendela pop-up yang terbuka saat Anda mengklik tombol 'Detail' di daftar pembayaran,\nlalu pilih perusahaan pengiriman dan lanjutkan dengan pembayaran :)\n\nTerima kasih telah mempercayai dan menggunakan Btorage!`;

    const domesticEmailKoMsg = `안녕하세요 ${orderData.memberDTO?.userName}님^^*<br>포장완료 후 주문서 상세페이지의 결제목록에 국내배송료가 등록되었습니다.<br><br>결제 전까지 결제목록의 '상세'버튼을 통해 열리는 팝업창에서<br>최종 한국 수취인정보를 한 번 더 확인해주세요 :)<br><br>저희 비토리지를 믿고 이용해주셔서 진심으로 감사드립니다 !`;
    const domesticEmailEnMsg = `Hello ${orderData.memberDTO?.userName}^^*<br>The domestic shipping fee has been added to the payment list on the order details page after packaging is complete.<br><br>Before making payment, please double-check the final recipient information in Korea through the pop-up window that opens by clicking the 'Details' button in the payment list :)<br><br>Thank you sincerely for trusting and using Btorage!`;
    const domesticEmailJaMsg = `こんにちは、${orderData.memberDTO?.userName}さん^^*<br>梱包完了後、注文書詳細ページの決済リストに国内送料が登録されました。<br><br>決済前に、決済リストの「詳細」ボタンから開くポップアップ画面で<br>最終受取人情報をもう一度ご確認ください :)<br><br>Btorageをご利用いただき、本当にありがとうございます！`;
    const domesticEmailZhMsg = `您好，${orderData.memberDTO?.userName}^^*<br>打包完成后，订单详情页面的支付列表中已登记国内运费。<br><br>在支付之前，请通过支付列表中的“详情”按钮打开的弹窗<br>再次确认最终的韩国收件人信息 :)<br><br>感谢您信任并使用Btorage！`;
    const domesticEmailIdMsg = `Halo ${orderData.memberDTO?.userName}^^*<br>Setelah pengemasan selesai, biaya pengiriman domestik telah terdaftar di daftar pembayaran pada halaman detail pesanan.<br><br>Sebelum melakukan pembayaran, pastikan untuk memeriksa kembali informasi penerima terakhir di Korea melalui jendela pop-up yang terbuka saat Anda mengklik tombol 'Detail' di daftar pembayaran :)<br><br>Terima kasih telah mempercayai dan menggunakan Btorage!`;

    const overseasEmailKoMsg = `안녕하세요 ${orderData.memberDTO?.userName}님^^*<br>포장완료 후 주문서 상세페이지의 결제목록에 해외배송료가 등록되었습니다.<br><br>결제 전까지 결제목록의 '상세'버튼을 통해 열리는 팝업창에서<br>반드시 최종 수취인정보/세관정보를 확인하신 후 배송사를 선택/결제해주세요 :)<br><br>저희 비토리지를 믿고 이용해주셔서 진심으로 감사드립니다 !`;
    const overseasEmailEnMsg = `Hello ${orderData.memberDTO?.userName}^^*<br>The international shipping fee has been added to the payment list on the order details page after packaging is complete.<br><br>Before making payment, please be sure to confirm the final recipient and customs information through the pop-up window that opens by clicking the 'Details' button in the payment list, then select the shipping company and proceed with payment :)<br><br>Thank you sincerely for trusting and using Btorage!`;
    const overseasEmailJaMsg = `こんにちは、${orderData.memberDTO?.userName}さん^^*<br>梱包完了後、注文書詳細ページの決済リストに海外送料が登録されました。<br><br>決済前に、決済リストの「詳細」ボタンから開くポップアップ画面で<br>必ず最終受取人情報/通関情報をご確認の上、配送業者を選択し決済してください :)<br><br>Btorageをご利用いただき、本当にありがとうございます！`;
    const overseasEmailZhMsg = `您好，${orderData.memberDTO?.userName}^^*<br>打包完成后，订单详情页面的支付列表中已登记国际运费。<br><br>在支付之前，请务必通过支付列表中的“详情”按钮打开的弹窗<br>确认最终收件人信息/海关信息后再选择配送公司并支付 :)<br><br>感谢您信任并使用Btorage！`;
    const overseasEmailIdMsg = `Halo ${orderData.memberDTO?.userName}^^*<br>Setelah pengemasan selesai, biaya pengiriman internasional telah terdaftar di daftar pembayaran pada halaman detail pesanan.<br><br>Sebelum melakukan pembayaran, pastikan untuk memeriksa informasi penerima terakhir/informasi bea cukai di jendela pop-up yang terbuka saat Anda mengklik tombol 'Detail' di daftar pembayaran,<br>lalu pilih perusahaan pengiriman dan lanjutkan dengan pembayaran :)<br><br>Terima kasih telah mempercayai dan menggunakan Btorage!`;

    const domesticChatSendLang = orderData.memberDTO?.lang === "Korean" ? domesticChatKoMsg : orderData.memberDTO?.lang === "Chinese" ? domesticChatZhMsg :
        orderData.memberDTO?.lang === "Japanese" ? domesticChatJaMsg : orderData.memberDTO?.lang === "Indonesian" ? domesticChatIdMsg : domesticChatEnMsg;
    const overseasChatSendLang = orderData.memberDTO?.lang === "Korean" ? overseasChatKoMsg : orderData.memberDTO?.lang === "Chinese" ? overseasChatZhMsg :
        orderData.memberDTO?.lang === "Japanese" ? overseasChatJaMsg : orderData.memberDTO?.lang === "Indonesian" ? overseasChatIdMsg : overseasChatEnMsg;

    const domesticEmailSendLang = orderData.memberDTO?.lang === "Korean" ? domesticEmailKoMsg : orderData.memberDTO?.lang === "Chinese" ? domesticEmailZhMsg :
        orderData.memberDTO?.lang === "Japanese" ? domesticEmailJaMsg : orderData.memberDTO?.lang === "Indonesian" ? domesticEmailIdMsg : domesticEmailEnMsg;
    const overseasEmailSendLang = orderData.memberDTO?.lang === "Korean" ? overseasEmailKoMsg : orderData.memberDTO?.lang === "Chinese" ? overseasEmailZhMsg :
        orderData.memberDTO?.lang === "Japanese" ? overseasEmailJaMsg : orderData.memberDTO?.lang === "Indonesian" ? overseasEmailIdMsg : overseasEmailEnMsg;

    const sendMessage = async () => {
        if (stompClient) {
            const chatMessage = {
                chatKey: selectedShipCompany === "국내배송" ? domesticChatSendLang : overseasChatSendLang,
                fileNm: null,
                chatOrdNum: Number(orderData.orderNumber),
                mbNum: adminInfo.mbNum,
                mstMbNum: orderData.memberDTO.mbNum,
                mstUserNm: orderData.memberDTO.userName,
                email: orderData.memberDTO.id,
                sendMailFl: true,
                autoChatFl: true,
                lang: orderData.memberDTO.lang,
                process: "PS0",
                emailKey: selectedShipCompany === "국내배송" ? domesticEmailSendLang : overseasEmailSendLang
            };
            await stompClient.send("/app/chat/adminMessage", {}, JSON.stringify(chatMessage));
        } else {
            console.log("메시지 전송실패");
        }
    };

    /** endregion */

    return (
        <div id="m-a-reg-modal-container" data-theme={theme}>
            <div className="m-a-common-header">
                <img onClick={pageBack} className="m-a-backIcon" src={back} alt="back"/>
                <div className="m-a-pg-header-text font-t-head">비용등록</div>
            </div>
            <div className="m-a-fixed-container-b">
                <div className="m-t-15 wd-100pc">
                    <div className="m-a-card m-b-15">
                        <div className="display-flex flex-column wd-100pc dark-font font-s-14">
                            <p className="font-b font-s-17">
                                <img className="wd-17 ht-17 m-b-4 m-r-3" alt="" src={orderIMG}/>
                                {orderData.orderNumber ? orderData.orderNumber : (orderData.orderdtDTO ? orderData.orderdtDTO.orderNumber : '')}
                            </p>
                            <div className="display-flex flex-row just-cont-sb wd-100pc">
                                <div className="wd-65pc">
                                    <div className="display-flex flex-row">
                                        <p className="wd-25pc">고객명:</p>
                                        <p className="wd-75pc">{orderData.memberDTO ? orderData.memberDTO.userName : (orderData.orderdtDTO ? orderData.orderdtDTO.memberDTO.userName : '')}</p>
                                    </div>
                                    <div className="display-flex flex-row font-s-14">
                                        <p className="wd-25pc">국가:</p>
                                        <p className="wd-75pc">
                                            {orderData.recinfoDTO && orderData.recinfoDTO.countryDTO
                                                ? orderData.recinfoDTO.countryDTO.countryNm + "[" + orderData.recinfoDTO.countryDTO.countryCode + "]"
                                                : (orderData.appRecDTO && orderData.appRecDTO.countryDTO
                                                    ? orderData.appRecDTO.countryDTO.countryNm + "[" + orderData.appRecDTO.countryDTO.countryCode + "]"
                                                    : '')
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="wd-35pc align-center p-t-2">
                                    <div className="m-a-combo wd-100pc ht-40 font-s-15 m-a-flex-jc-start font-b" onClick={() => setShipCompanyPopup(true)}>
                                        {selectedShipCompany}
                                        {shipCompanyPopup && (
                                            <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setShipCompanyPopup(false); }}>
                                                <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                    <div className="m-a-page-popup-header-frame">
                                                        <div className="font-t-title font-s-18 m-t-4">배송사선택</div>
                                                    </div>
                                                    <div className="m-a-page-popup-body-frame">
                                                        {shipcoData.map((cData) => (
                                                            <div key={cData.seqnum}
                                                                 className={`m-a-card m-t-10 m-b-10 ${selectedShipCompany === cData.courierCompanyName ? "m-a-selected-option" : ""}`}
                                                                 onClick={() => handleShipCompanyClick(cData.courierCompanyName)}>
                                                                <p>{cData.courierCompanyName}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="m-a-page-popup-footer-frame">
                                                        <div className="m-a-file-bt-container">
                                                            <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setShipCompanyPopup(false); }}>
                                                                <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="display-flex flex-column font-c-default m-b-15">
                        {orderData.type === 'orderdt' &&
                            <div className="m-a-open-bar p-l-15 p-r-10" onClick={() => setShowCostInputs(!showCostInputs)}>
                                <p className="font-t-title font-s-14">비용입력</p>
                                {showCostInputs ?
                                    <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/> :
                                    <i className="m-a-ord-accordion-icon"></i>
                                }
                            </div>
                        }
                        {showCostInputs &&
                            <div className="m-a-top-border-card display-flex flex-column wd-100pc font-t-cont">
                                <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                                    <div className={`wd-30pc ht-40 font-s-15 p-t-8 ${codCheckbox ? 'm-a-text-colored' : ''}`}><p className="">택배착불비</p></div>
                                    <div className="m-a-input-frame ht-40 wd-60pc">
                                        <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0" maxLength="7" inputMode="numeric" pattern="\d*"
                                               value={codCheckbox ? parseInt(codValue).toLocaleString() : ''}
                                               onChange={(e) => {
                                                   const newValue = e.target.value.replace(/,/g, '');
                                                   if (/^\d*$/.test(newValue)) {
                                                       setCodValue(newValue);
                                                       setCodCheckbox(newValue !== '' && parseInt(newValue) !== 0);
                                                   }
                                               }}
                                        />
                                    </div>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                                    <div className={`wd-30pc ht-40 font-s-15 p-t-8 ${domeCheckbox ? 'm-a-text-colored' : ''}`}><p className="">국내반송비</p></div>
                                    <div className="m-a-input-frame ht-40 wd-60pc">
                                        <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0" maxLength="7" inputMode="numeric" pattern="\d*"
                                               value={domeCheckbox ? parseInt(domeValue).toLocaleString() : ''}
                                               onChange={(e) => {
                                                   const newValue = e.target.value.replace(/,/g, '');
                                                   if (/^\d*$/.test(newValue)) {
                                                       setDomeValue(newValue);
                                                       setDomeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                   }
                                               }}
                                        />
                                    </div>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                                    <div className={`wd-30pc ht-40 font-s-15 p-t-8 ${repackCheckbox ? 'm-a-text-colored' : ''}`}><p className="">재포장수수료</p></div>
                                    <div className="m-a-input-frame ht-40 wd-60pc">
                                        <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0" maxLength="7" inputMode="numeric" pattern="\d*"
                                               value={repackCheckbox ? parseInt(repackValue).toLocaleString() : ""}
                                               onChange={(e) => {
                                                   const newValue = e.target.value.replace(/,/g, '');
                                                   if (/^\d*$/.test(newValue)) {
                                                       setRePackValue(newValue);
                                                       setRePackCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                   }
                                               }}
                                        />
                                    </div>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                                    <div className={`wd-30pc ht-40 font-s-15 p-t-8 ${agenciesCheckbox ? 'm-a-text-colored' : ''}`}><p className="">대행수수료</p></div>
                                    <div className="m-a-input-frame ht-40 wd-60pc">
                                        <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0" maxLength="8" inputMode="numeric" pattern="\d*"
                                               value={agenciesCheckbox ? parseInt(agenciesValue).toLocaleString() : ""}
                                               onChange={(e) => {
                                                   const newValue = e.target.value.replace(/,/g, '');
                                                   if (/^\d*$/.test(newValue)) {
                                                       setAgenciesValue(newValue);
                                                       setAgenciesCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                   }
                                               }}
                                        />
                                    </div>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                                    <div className={`wd-30pc ht-40 font-s-15 p-t-8 ${vacuumPack ? 'm-a-text-colored' : ''}`}><p className="">압축팩수수료</p></div>
                                    <div className="wd-26pc font-s-14 font-c-default font-t-cont" style={{marginLeft: "6%"}}>
                                        <div className="m-a-combo wd-100pc ht-40 m-a-flex-jc-start" onClick={() => setVacPackPopup(!vacPackPopup)}>
                                            {packSize}
                                            {vacPackPopup && (
                                                <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setVacPackPopup(false); }}>
                                                    <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                        <div className="m-a-page-popup-header-frame">
                                                            <div className="font-t-title font-s-18 m-t-4">압축팩크기 선택</div>
                                                        </div>
                                                        <div className="m-a-page-popup-body-frame">
                                                            <div className={`m-a-card m-t-10 m-b-10 ${packSize === "선택" ? "m-a-selected-option" : ""}`}
                                                                 onClick={() => handleVacPackSizeChange("선택")}><p>선택</p></div>
                                                            <div className={`m-a-card m-t-10 m-b-10 ${packSize === "소형" ? "m-a-selected-option" : ""}`}
                                                                 onClick={() => handleVacPackSizeChange("소형")}><p>소형</p></div>
                                                            <div className={`m-a-card m-t-10 m-b-10 ${packSize === "대형" ? "m-a-selected-option" : ""}`}
                                                                 onClick={() => handleVacPackSizeChange("대형")}><p>대형</p></div>
                                                        </div>
                                                        <div className="m-a-page-popup-footer-frame">
                                                            <div className="m-a-file-bt-container">
                                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setVacPackPopup(false); }}>
                                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="m-a-input-frame ht-40 wd-30pc">
                                        <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0" disabled={true}
                                               value={vacuumPack ? parseInt(vacPackPrice).toLocaleString() : ""}/>
                                    </div>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                                    <div className={`wd-30pc ht-40 font-s-15 p-t-8 ${etcCheckbox ? 'm-a-text-colored' : ''}`}><p className="">기타비용</p></div>
                                    <div className="m-a-input-frame ht-40 wd-60pc">
                                        <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0" maxLength="8" inputMode="numeric" pattern="\d*"
                                               value={etcCheckbox ? parseInt(etcValue).toLocaleString() : ""}
                                               onChange={(e) => {
                                                   const newValue = e.target.value.replace(/,/g, '');
                                                   if (/^\d*$/.test(newValue)) {
                                                       setEtcValue(newValue);
                                                       setEtcCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                   }
                                               }}
                                        />
                                    </div>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                                    <div className={`wd-30pc ht-40 font-s-15 p-t-8 ${etcNmValue ? 'm-a-text-colored' : ''}`}><p className="">기타비용내용</p></div>
                                    <div className="m-a-input-frame ht-40 wd-60pc">
                                        <input className="m-a-input wd-100pc p-l-5 font-s-14" type={"text"} maxLength="25" placeholder="비용 입력시 활성화"
                                               value={etcCheckbox ? etcNmValue : ""} disabled={etcCheckbox === false}
                                               onChange={(e) => {setEtcNmValue(e.target.value)}}
                                                style={{}}/>
                                    </div>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                                    <div className={`wd-30pc ht-40 font-s-15 p-t-8 ${etcFeeValue ? 'm-a-text-colored' : ''}`}><p className="">기타수수료</p></div>
                                    <div className="m-a-input-frame ht-40 wd-60pc">
                                        <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0" maxLength="8" inputMode="numeric" pattern="\d*"
                                               value={etcFeeValue === "" ? "" : parseInt(etcFeeValue).toLocaleString()}
                                               onChange={(e) => {
                                                   const newValue = e.target.value.replace(/,/g, '');
                                                   if (/^\d*$/.test(newValue)) {
                                                       setEtcFeeValue(newValue);
                                                       setEtcFeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                   }
                                               }}
                                        />
                                    </div>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc">
                                    <div className={`wd-30pc ht-40 font-s-15 p-t-8 ${etcFeeNmValue ? 'm-a-text-colored' : ''}`}><p className="">기타수수료내용</p></div>
                                    <div className="m-a-input-frame ht-40 wd-60pc">
                                        <input className="m-a-input wd-100pc p-l-5 font-s-14" type={"text"} maxLength="25" placeholder="수수료 입력시 활성화"
                                               value={etcFeeCheckbox ? etcFeeNmValue : ""} disabled={etcFeeCheckbox === false}
                                               onChange={(e) => {setEtcFeeNmValue(e.target.value)}}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="display-flex flex-column font-c-default m-b-15">
                        {orderData.type === 'orderdt' &&
                            <div className="m-a-open-bar p-l-15 p-r-10" onClick={() => setShowItemInputs(!showItemInputs)}>
                                <p className="font-t-title font-s-14">상품할당</p>
                                {showItemInputs ?
                                    <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/> :
                                    <i className="m-a-ord-accordion-icon"></i>
                                }
                            </div>
                        }

                    </div>
                    <div className="display-flex flex-column font-c-default">
                        <div className="m-a-open-bar p-l-15 p-r-10">
                            <p className="font-t-title font-s-14">박스정보</p>
                            <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/>
                        </div>
                        {selectedShipCompany !== "국내배송" && showBoxInputs && (
                            <div className="m-a-top-border-card display-flex flex-column wd-100pc">
                                {boxData.map((box, index) => (
                                    <div key={index} className="wd-100pc font-t-cont">
                                        <div className="display-flex flex-column">
                                            <div className="display-flex flex-row font-s-14 wd-100pc text-center gap7">
                                                <p className="wd-25pc">가로(cm)</p>
                                                <p className="wd-25pc">세로(cm)</p>
                                                <p className="wd-25pc">높이(cm)</p>
                                                <p className="wd-25pc">실무게(kg)</p>
                                            </div>
                                        </div>
                                        <div className="display-flex flex-column">
                                            <div className="display-flex flex-row just-cont-sb wd-100pc gap7">
                                                <input className="admin-input-line font-b font-c-default wd-25pc ht-40 text-center font-s-14 com-input" inputMode="numeric" pattern="\d*"
                                                       minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                                       value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.wid : box.width}
                                                       onChange={(e) => {
                                                           const newValue = e.target.value;
                                                           if (orderData.dataType === 'payinfo') {
                                                               handleBoxChange(index, "width", newValue);
                                                               const updatedShipInfoDTO = { ...orderData.shipInfoDTO, wid: newValue };
                                                               setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                                           } else {
                                                               handleBoxChange(index, "width", newValue);
                                                           }
                                                           setInputType(newValue === box.width ? 'box' : 'shipInfoDTO');
                                                       }}
                                                />
                                                <input className="admin-input-line font-b font-c-default wd-25pc ht-40 text-center font-s-14 com-input" inputMode="numeric" pattern="\d*"
                                                       minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                                       value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.ver : box.height}
                                                       onChange={(e) => {
                                                           const newValue = e.target.value;
                                                           if (orderData.dataType === 'payinfo') {
                                                               handleBoxChange(index, "height", newValue);
                                                               const updatedShipInfoDTO = { ...orderData.shipInfoDTO, ver: newValue };
                                                               setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                                           } else {
                                                               handleBoxChange(index, "height", newValue);
                                                           }
                                                           setInputType(newValue === box.height ? 'box' : 'shipInfoDTO');
                                                       }}
                                                />
                                                <input className="admin-input-line font-b font-c-default wd-25pc ht-40 text-center font-s-14 com-input" inputMode="numeric" pattern="\d*"
                                                       minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                                       value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.hei : box.length}
                                                       onChange={(e) => {
                                                           const newValue = e.target.value;
                                                           if (orderData.dataType === 'payinfo') {
                                                               handleBoxChange(index, "length", newValue);
                                                               const updatedShipInfoDTO = { ...orderData.shipInfoDTO, hei: newValue };
                                                               setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                                           } else {
                                                               handleBoxChange(index, "length", newValue);
                                                           }
                                                           setInputType(newValue === box.length ? 'box' : 'shipInfoDTO');
                                                       }}
                                                />
                                                <input className="admin-input-line font-b font-c-default wd-25pc ht-40 text-center font-s-14 com-input" inputMode="numeric" pattern="\d*"
                                                       minLength="1" maxLength="5" type="text " placeholder="0.00" style={{ color: box.netWeight > 30 ? "red" : ""}}
                                                       value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.weight : box.netWeight || ""}
                                                       onChange={(e) => {
                                                           const newValue = e.target.value;
                                                           if (orderData.dataType === 'payinfo') {
                                                               handleBoxChange(index, "netWeight", newValue);
                                                               if (!box.width && !box.height && !box.length) {
                                                                   handleBoxChange(index, "width", "0");
                                                                   handleBoxChange(index, "height", "0");
                                                                   handleBoxChange(index, "length", "0");
                                                               }
                                                               if (e.target.value !== "") {
                                                                   setEfeeCheckbox(true);
                                                                   setFfeeCheckbox(true);
                                                               } else {
                                                                   setEfeeCheckbox(false);
                                                                   setFfeeCheckbox(false);
                                                               }
                                                               const updatedShipInfoDTO = { ...orderData.shipInfoDTO, weight: newValue };
                                                               setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                                           } else {
                                                               handleBoxChange(index, "netWeight", newValue);
                                                               if (!box.width && !box.height && !box.length) {
                                                                   handleBoxChange(index, "width", "0");
                                                                   handleBoxChange(index, "height", "0");
                                                                   handleBoxChange(index, "length", "0");
                                                               }
                                                               if (e.target.value !== "") {
                                                                   setEfeeCheckbox(true);
                                                                   setFfeeCheckbox(true);
                                                               } else {
                                                                   setEfeeCheckbox(false);
                                                                   setFfeeCheckbox(false);
                                                               }
                                                           }
                                                           setInputType(newValue === box.netWeight ? 'box' : 'shipInfoDTO');
                                                       }}
                                                />
                                            </div>
                                        </div>
                                        <div className="display-flex flex-column m-t-5 m-b-25">
                                            <div className="display-flex flex-row just-cont-sb">
                                                <div><p className="font-s-13 font-c-btorage">Box {index + 1}</p></div>
                                                {/*<div className="display-flex flex-row text-active" onClick={() => setShowBoxDtPrice(!showBoxDtPrice)}>*/}
                                                <div className="display-flex flex-row text-active" onClick={() => handleToggle(index)}>
                                                    <p className="font-s-13">상세비용</p>
                                                    {/*{showBoxDtPrice ?*/}
                                                    {/*    <IoIosArrowDropdown className="font-s-18 font-c-default"/> : <IoIosArrowDropright className="font-s-18 font-c-default"/>*/}
                                                    {/*}*/}
                                                    {showBoxDtPrice[index] ?
                                                        <IoIosArrowDropdown className="font-s-18 font-c-default"/> :
                                                        <IoIosArrowDropright className="font-s-18 font-c-default"/>
                                                    }
                                                </div>
                                            </div>
                                            {/*{showBoxDtPrice && (*/}
                                            {showBoxDtPrice[index] && (
                                                <div>
                                                    <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-a-reg-box-price-top m-t-5 p-t-15">
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                            <p className="font-t-title">FedEx</p>
                                                            <div className="display-flex flex-row font-s-15">
                                                                <p className="" style={{ color: getVolumeWeightFedex(box.width, box.height, box.length) > 30 || box.netWeight > 30 ? "red" : ""}}>
                                                                    {getVolumeWeightFedex(box.width, box.height, box.length) + "kg"}
                                                                </p>
                                                                <p className="m-l-4 m-r-5">/</p>
                                                                <p className="font-b" style={{ color: box.appliedWeights.F > 30 ? "red" : ""}}>
                                                                    {box.appliedWeights.F + "kg"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                            <p className="">페덱스팩여부</p>
                                                            <div className="display-flex flex-row">
                                                                <input className="m-a-admin-input-chkBox m-0-a m-r-5" type="checkbox"
                                                                       checked={parseFloat(box.netWeight) > 1.5 ? false : box.fpackCheckbox} disabled={parseFloat(box.netWeight) > 1.5 || selectedShipCompany !== 'FedEx'}
                                                                       onChange={() => handleBoxChange(index, "fpackCheckbox")}/>
                                                                <p className="">FedEx Pack -</p>
                                                                <p className="font-b">{parseFloat(box.netWeight) > 1.5 ? " N" : box.fpackCheckbox ? " Y" : " N"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                            <p className="">유류할증료</p>
                                                            <div className="display-flex flex-row">
                                                                <div className="m-a-input-frame ht-25 wd-60">
                                                                    <input className="m-a-input text-center wd-60 ht-25 font-c-default" type={"text"} value={fedexFuelValue} maxLength="4"
                                                                           onChange={(e) => setFedexFuelValue(e.target.value)} inputMode="numeric" pattern="\d*"
                                                                           style={{backgroundColor: parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30 ? "rgba(169,169,169,0.71)" : ""}}
                                                                           disabled={parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30}
                                                                    />
                                                                </div>
                                                                <p className="m-t-3"> %</p>
                                                                <input className="wd-90 ht-25 text-right font-c-default m-a-all-none" type={"text"} disabled placeholder="0"
                                                                       style={{backgroundColor: parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30 ? "rgba(169,169,169,0.71)" : ""}}
                                                                       value={parseFloat(box.netWeight) > 30 ? 0 : roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData) * (fedexFuelValue / 100)).toLocaleString()}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                            <p className="">수수료</p>
                                                            <input className="wd-30pc ht-25 text-right font-c-default m-a-all-none" type={"text"} placeholder="0" disabled
                                                                   value={parseFloat(box.netWeight) > 30  || getVolumeWeightFedex(box.width, box.height, box.length) > 30 ? 0 : box.netWeight ? parseInt(boxFedexResultFees[index]).toLocaleString() : ""}
                                                                   onChange={(e) => {
                                                                       const newValue = e.target.value.replace(/,/g, '');
                                                                       if (/^\d*$/.test(newValue)) {
                                                                           const updatedBoxFedexResultFees = [...boxFedexResultFees];
                                                                           updatedBoxFedexResultFees[index] = newValue * appliedWeights.F * 10;
                                                                           setBoxFedexResultFees(updatedBoxFedexResultFees);
                                                                           setEfeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                                           if (newValue !== "" && parseInt(newValue) !== 0) {
                                                                               setEmsAmount(true);
                                                                           }
                                                                       }
                                                                   }}
                                                            />
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                            <p className="">배송료</p>
                                                            <p className="">{parseFloat(box.netWeight) > 30 ? 0 : getAmountForWeight(box.appliedWeights.F, fedexExData).toLocaleString()}</p>
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                            <p className="">총배송비용</p>
                                                            <input className="wd-30pc ht-25 text-right font-b font-c-progress m-a-all-none" type="text"
                                                                   disabled={!(parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30)}
                                                                   style={{backgroundColor: box.netWeight > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30 ? "rgba(255,234,152,0.5)" : "" }}
                                                                   value={(parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30) && box.fedexValue
                                                                       ? Math.round(box.fedexValue).toLocaleString()
                                                                       : roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData) * (1 + fedexFuelValue / 100)).toLocaleString()}
                                                                   onChange={(e) => {
                                                                       const newValue = e.target.value.replace(/[^0-9.]/g, '');
                                                                       if (newValue === "" || !isNaN(newValue)) {
                                                                           handleFedexValueChange(index, newValue);
                                                                       }
                                                                   }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-15">
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                            <p className="font-t-title">UPS</p>
                                                            <div className="display-flex flex-row font-s-15">

                                                            </div>
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                            <p className="">수수료</p>

                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                            <p className="">배송료</p>

                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                            <p className="">총배송비용</p>

                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-15">
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                            <p className="font-t-title">EMS</p>
                                                            <div className="display-flex flex-row font-s-15">
                                                                <p className="" style={{ color: getVolumeWeightEms(box.width, box.height, box.length) > 30 || box.netWeight > 30 ? "red" : ""}}>
                                                                    {getVolumeWeightEms(box.width, box.height, box.length) + "kg"}
                                                                </p>
                                                                <p className="m-l-4 m-r-5">/</p>
                                                                <p className="font-b" style={{ color: box.appliedWeights.E > 30 ? "red" : ""}}>
                                                                    {box.appliedWeights.E + "kg"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                            <p className="">수수료</p>
                                                            <input className="wd-30pc ht-25 text-right m-a-all-none" type={"text"} placeholder="0" disabled
                                                                   style={{backgroundColor: parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? "rgba(169,169,169,0.71)" : ""}}
                                                                   value={parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? 0 : box.netWeight ? parseInt(boxEmsResultFees[index]).toLocaleString() : ""}
                                                                   onChange={(e) => {
                                                                       const newValue = e.target.value.replace(/,/g, '');
                                                                       if (/^\d*$/.test(newValue)) {
                                                                           const updatedBoxEmsResultFees = [...boxEmsResultFees];
                                                                           updatedBoxEmsResultFees[index] = newValue * appliedWeights.E * 10;
                                                                           setBoxEmsResultFees(updatedBoxEmsResultFees);
                                                                           setEfeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                                           if (newValue !== "" && parseInt(newValue) !== 0) {
                                                                               setEmsAmount(true);
                                                                           }
                                                                       }
                                                                   }}
                                                            />
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                            <p className="">배송료</p>
                                                            <p style={{backgroundColor: parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? "rgba(169,169,169,0.71)" : ""}}>
                                                                {parseFloat(box.netWeight) > 30 ? 0 : getAmountForWeight(box.appliedWeights.E, emsExData).toLocaleString()}
                                                            </p>
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                            <p className="">총배송비용</p>
                                                            <input className="wd-30pc ht-25 text-right font-b font-c-progress m-a-all-none" type="text" disabled
                                                                   style={{backgroundColor: parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? "rgba(169,169,169,0.71)" : ""}}
                                                                   value={parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? 0
                                                                       : (getAmountForWeight(box.appliedWeights.E, emsExData) + parseInt(boxEmsResultFees[index])).toLocaleString()}/>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-15">
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                            <p className="font-t-title">SHIP</p>
                                                            <div className="display-flex flex-row font-s-15">
                                                                <p className="font-b" style={{ color: box.netWeight > 20 ? "red" : ""}}>
                                                                    {box.netWeight + "kg"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                            <p className="">수수료</p>
                                                            <input className="wd-100 ht-25 text-right m-a-all-none" type={"text"} placeholder="0" disabled
                                                                   style={{backgroundColor: parseFloat(box.netWeight) > 20 || getVolumeWeightFedex(box.width, box.height, box.length) > 20 ? "rgba(169,169,169,0.71)" : ""}}
                                                                   value={parseInt(boxSeaResultFees[index]).toLocaleString()}
                                                                   onChange={(e) => {
                                                                       const newValue = e.target.value.replace(/,/g, '');
                                                                       if (/^\d*$/.test(newValue)) {
                                                                           const updatedBoxSeaResultFees = [...boxSeaResultFees];
                                                                           updatedBoxSeaResultFees[index] = newValue * appliedWeights.S * 10;
                                                                           setBoxSeaResultFees(updatedBoxSeaResultFees);
                                                                           setEfeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                                           if (newValue !== "" && parseInt(newValue) !== 0) {
                                                                               setEmsAmount(true);
                                                                           }
                                                                       }
                                                                   }}
                                                            />
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                            <p className="">배송료</p>
                                                            <p>{parseFloat(box.netWeight) > 20 ? 0 : getAmountForWeight(box.appliedWeights.S, shipExData).toLocaleString()}</p>
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                            <p className="">총배송비용</p>
                                                            <input className="wd-30pc ht-25 text-right font-b font-c-progress m-a-all-none" type="text" disabled
                                                                   style={{backgroundColor: parseFloat(box.netWeight) > 20 ? "rgba(169,169,169,0.71)" : ""}}
                                                                   value={parseFloat(box.netWeight) > 20 ? 0
                                                                       : (getAmountForWeight(box.appliedWeights.S, shipExData) + parseInt(boxSeaResultFees[index])).toLocaleString()}/>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-15">
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                            <p className="font-t-title">Small-Packet</p>
                                                            <div className="display-flex flex-row font-s-15">

                                                            </div>
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                            <p className="">수수료</p>

                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                            <p className="">배송료</p>

                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                            <p className="">총배송비용</p>

                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-15">
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                            <p className="font-t-title">K-Packet</p>
                                                            <div className="display-flex flex-row font-s-15">

                                                            </div>
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                            <p className="">수수료</p>

                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                            <p className="">배송료</p>

                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                            <p className="">총배송비용</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {selectedShipCompany === "국내배송" && (
                            <div className="m-a-top-border-card display-flex flex-column wd-100pc">
                                {boxDomeData.map((box, index) => (
                                    <div key={index} className="wd-100pc font-t-cont">
                                        <div className="display-flex flex-column">
                                            <div className="display-flex flex-row font-s-14 wd-100pc text-center gap7">
                                                <p className="wd-25pc">가로(cm)</p>
                                                <p className="wd-25pc">세로(cm)</p>
                                                <p className="wd-25pc">높이(cm)</p>
                                                <p className="wd-25pc">실무게(kg)</p>
                                            </div>
                                        </div>
                                        <div className="display-flex flex-column">
                                            <div className="display-flex flex-row just-cont-sb wd-100pc gap7">
                                                <input className="admin-input-line font-b font-c-default wd-25pc ht-40 text-center font-s-14 com-input" inputMode="numeric" pattern="\d*"
                                                       minLength="1" maxLength="3"  type="text" placeholder="0"
                                                       disabled={fpackValue === 'Y'} value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.wid : box.width}
                                                       onChange={(e) => {
                                                           const newValue = e.target.value;
                                                           if (orderData.dataType === 'payinfo') {
                                                               handleBoxChange(index, "width", newValue);
                                                               const updatedShipInfoDTO = { ...orderData.shipInfoDTO, wid: newValue };
                                                               setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                                           } else {
                                                               handleBoxChange(index, "width", newValue);
                                                           }
                                                           setInputType(newValue === box.width ? 'box' : 'shipInfoDTO');
                                                       }}
                                                />
                                                <input className="admin-input-line font-b font-c-default wd-25pc ht-40 text-center font-s-14 com-input" inputMode="numeric" pattern="\d*"
                                                       minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                                       value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.ver : box.height}
                                                       onChange={(e) => {
                                                           const newValue = e.target.value;
                                                           if (orderData.dataType === 'payinfo') {
                                                               handleBoxChange(index, "height", newValue);
                                                               const updatedShipInfoDTO = { ...orderData.shipInfoDTO, ver: newValue };
                                                               setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                                           } else {
                                                               handleBoxChange(index, "height", newValue);
                                                           }
                                                           setInputType(newValue === box.height ? 'box' : 'shipInfoDTO');
                                                       }}
                                                />
                                                <input className="admin-input-line font-b font-c-default wd-25pc ht-40 text-center font-s-14 com-input" inputMode="numeric" pattern="\d*"
                                                       minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                                       value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.hei : box.length}
                                                       onChange={(e) => {
                                                           const newValue = e.target.value;
                                                           if (orderData.dataType === 'payinfo') {
                                                               handleBoxChange(index, "length", newValue);
                                                               const updatedShipInfoDTO = { ...orderData.shipInfoDTO, hei: newValue };
                                                               setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                                           } else {
                                                               handleBoxChange(index, "length", newValue);
                                                           }
                                                           setInputType(newValue === box.length ? 'box' : 'shipInfoDTO');
                                                       }}
                                                />
                                                <input className="admin-input-line font-b font-c-default wd-25pc ht-40 text-center font-s-14 com-input" inputMode="numeric" pattern="\d*"
                                                       minLength="1" maxLength="5" type="text " placeholder="0.00" style={{ color: box.netWeight > 30 ? "red" : ""}}
                                                       value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.weight : box.netWeight}
                                                       onChange={(e) => {
                                                           const newValue = e.target.value;
                                                           if (orderData.dataType === 'payinfo') {
                                                               handleBoxChange(index, "netWeight", newValue);
                                                               if (!box.width && !box.height && !box.length) {
                                                                   handleBoxChange(index, "width", "0");
                                                                   handleBoxChange(index, "height", "0");
                                                                   handleBoxChange(index, "length", "0");
                                                               }
                                                               if (e.target.value !== "") {
                                                                   setEfeeCheckbox(true);
                                                                   setFfeeCheckbox(true);
                                                               } else {
                                                                   setEfeeCheckbox(false);
                                                                   setFfeeCheckbox(false);
                                                               }
                                                               const updatedShipInfoDTO = { ...orderData.shipInfoDTO, weight: newValue };
                                                               setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                                           } else {
                                                               handleBoxChange(index, "netWeight", newValue);
                                                               if (!box.width && !box.height && !box.length) {
                                                                   handleBoxChange(index, "width", "0");
                                                                   handleBoxChange(index, "height", "0");
                                                                   handleBoxChange(index, "length", "0");
                                                               }
                                                               if (e.target.value !== "") {
                                                                   setEfeeCheckbox(true);
                                                                   setFfeeCheckbox(true);
                                                               } else {
                                                                   setEfeeCheckbox(false);
                                                                   setFfeeCheckbox(false);
                                                               }
                                                           }
                                                           setInputType(newValue === box.netWeight ? 'box' : 'shipInfoDTO');
                                                       }}
                                                />
                                            </div>
                                        </div>
                                        <div className="display-flex flex-column m-t-5 m-b-25">
                                            <div className="display-flex flex-row just-cont-sb">
                                                <div><p className="font-s-13 font-c-btorage">Box {index + 1}</p></div>
                                                <div className="display-flex flex-row text-active" onClick={() => handleToggle(index)}>
                                                    <p className="font-s-13">상세비용</p>
                                                    {showBoxDtPrice[index] ?
                                                        <IoIosArrowDropdown className="font-s-18 font-c-default"/> :
                                                        <IoIosArrowDropright className="font-s-18 font-c-default"/>
                                                    }
                                                </div>
                                            </div>
                                            {showBoxDtPrice[index] && (
                                                <div>
                                                    <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-15">
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                            <p className="font-t-title">국내배송</p>
                                                            <div className="display-flex flex-row font-s-15">
                                                                <p className="" style={{ color: (parseFloat(box.width) + parseFloat(box.height) + parseFloat(box.length)) > 200 ? "red" : "" }}>
                                                                    {(!isNaN(parseFloat(box.width)) ? parseFloat(box.width) : 0) +
                                                                        (!isNaN(parseFloat(box.height)) ? parseFloat(box.height) : 0) +
                                                                        (!isNaN(parseFloat(box.length)) ? parseFloat(box.length) : 0) + "cm"}
                                                                </p>
                                                                <p className="m-l-4 m-r-5">/</p>
                                                                <a className="font-b" style={{ color: parseFloat(box.netWeight) > 30 ? "red" : "" }}>
                                                                    {box.netWeight + " kg"}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                            <p className="">총배송비용</p>
                                                            <input className="wd-30pc ht-25 text-right font-b font-c-progress m-a-all-none" type="text" disabled
                                                                   value={parseFloat(box.netWeight) > 30 ? 0 : (box.netWeight === "" ? "\\" + 0
                                                                       : (domeExData.length > 0 ? getAmountForDomeWeight(box.appliedWeightsD.D, domeExData).toLocaleString() : "N/A"))} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {selectedShipCompany === "국내배송" && tables.length > 0 && tables.length === tableCount - 1 && (
                            <div className="display-flex flex-column font-c-default m-t-15">
                                <div className="m-a-open-bar p-l-15 p-r-10">
                                    <p className="font-t-title font-s-14">총배송비용[전체박스]</p>
                                    <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/>
                                </div>
                                <div className="m-a-top-border-card display-flex flex-column wd-100pc">
                                    <div className="display-flex flex-column wd-100pc">
                                        <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                            <p className="">국내배송</p>
                                            <p className="font-c-btorage">{calculateTotalDOMECost()}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {selectedShipCompany !== "국내배송" && tables.length > 0 && tables.length === tableCount - 1 && (
                        <div className="display-flex flex-column font-c-default m-t-15">
                            <div className="m-a-open-bar p-l-15 p-r-10">
                                <p className="font-t-title font-s-14">총배송비용[전체박스]</p>
                                <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/>
                            </div>
                            <div className="m-a-top-border-card display-flex flex-column wd-100pc">
                                <div className="display-flex flex-column wd-100pc">
                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                        <p className="">FedEx</p>
                                        <p className="font-c-btorage">{calculateTotalFEDEXCost()}</p>
                                    </div>
                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                        <p className="">UPS</p>
                                    </div>
                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                        <p className="">EMS</p>
                                        <p className="font-c-btorage">{calculateTotalEMSCost()}</p>
                                    </div>
                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                        <p className="">SHIP</p>
                                        <p className="font-c-btorage">{calculateTotalSHIPCost()}</p>
                                    </div>
                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                        <p className="">항공소형</p>
                                    </div>
                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                        <p className="">K-Packet</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {orderData.type === 'orderdt' && (
                <div className="m-a-m-fixed-bt-frame-b align-center p-l-6 p-r-6">
                    <div className="display-flex flex-row wd-100pc gap6">
                        <div className="wd-15pc ht-45">
                            <button className="m-a-commonBt wd-100pc ht-45 font-c-default" onClick={handleMinusClick}><p className="font-s-26">-</p></button>
                        </div>
                        <div className="wd-15pc ht-45">
                            <button className="m-a-commonBt wd-100pc ht-45 font-c-default font-s-23" onClick={handlePlusClick}>+</button>
                        </div>
                        <div className="wd-35pc">
                            {/*<button className="m-a-colorBt-B m-a-colorBt-text wd-100pc ht-45 font-t-cont">초기화</button>*/}
                        </div>
                        <div className="wd-35pc font-t-cont">
                            <button className="m-a-colorBt m-a-colorBt-text wd-100pc ht-45" id="expRegComplete" onClick={alert}>등록</button>
                        </div>
                    </div>
                </div>
            )}
            {orderData.type === 'payinfo' && (
                <div className="m-a-m-fixed-bt-frame align-center">
                    <div className="display-flex flex-row wd-100pc">
                        <button className="m-a-colorBt-radius-n m-a-colorBt-text wd-100pc ht-50" onClick={alert3}>저장</button>
                    </div>
                </div>
            )}
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel1} eventHandle2={expRegComplete} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow3 === true ? <MsgSelectModal alertShow={alertShow3} eventHandle1={modalCancel3} eventHandle2={expRegComplete} msgData1={msgConfirmData5} msgData2={msgConfirmData6}/> : null}
        </div>
    );
}

export default MAdminRegModal
