import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import complete from "../../assets/img/complete.png";
import "../../css/mobile/MUserPayComplPg.css";
import withAuth from "../../auth/UserRouteAuth";

const MUserPayComplPg = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const data = location.state.data;
    const navigate = useNavigate();

    const orderDetailPgMove = (ordNum) => () => {
        navigate(`/UserMyOrderPg/${ordNum}`);
    };

    return (
        <div className={'m-a-user-pay-compl-page-container font-t-cont'}>
            <div className={'m-a-user-pay-compl-contents'}>
                <img className={'m-a-user-pay-compl-icon'} src={complete} alt={''}/>
                <div className={'m-a-user-pay-compl-title'}>{t(`pcCont`)}</div> {/*결제가 성공적으로 완료되었습니다*/}
                <div className={'m-a-user-pay-compl-divider'}/>
                <div className={'m-a-user-pay-compl-row'}>
                    <div className={'m-a-user-pay-compl-label wd-30pc'}>{t(`modNum`)}</div> {/*주문번호*/}
                    <div className={'m-a-user-pay-compl-value wd-70pc font-b'}>{data && data?.orderNumber}</div>
                </div>
                <div className={'m-a-user-pay-compl-row'}>
                    <div className={'m-a-user-pay-compl-label wd-30pc'}>{t(`modPty`)}</div> {/*결제수단*/}
                    <div className={'m-a-user-pay-compl-value wd-70pc'}>
                        {data && data?.payWay === "PM01" ? 'Paypal'
                            : data?.payWay === "PM05" ? t(`modDep`)
                                : data?.payWay === "PM04" ? t(`modPt`)
                                    : null
                        }
                    </div>
                </div>
                <div className={'m-a-user-pay-compl-row font-c-default'}>
                    <div className={'m-a-user-pay-compl-label wd-30pc'}>{t(`pcWon`)}</div>
                    <div className={'m-a-user-pay-compl-value wd-70pc font-b'}>
                        {data && "\u20A9 " + data?.lastPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                </div>
                {data && data?.payWay === "PM01" &&
                    <div className={'m-a-user-pay-compl-row'}>
                        <div className={'m-a-user-pay-compl-label'}>{t(`pcFor`)}</div>
                        <div className={'m-a-user-pay-compl-value bold primary'}>
                            {data && data?.lastPrice}
                            <span className={'m-a-user-pay-compl-value pale'}>
                                &nbsp;{data && data?.forCurrency}
                            </span>
                        </div>
                    </div>
                }
            </div>
            <div className={'m-a-user-pay-compl-buttons'}>
                <div className={'m-a-user-pay-compl-button'}
                     onClick={() => navigate("/UserMyPg")}>{t(`hdMypg`)}</div>
                <div className={'m-a-user-pay-compl-button primary'}
                     onClick={orderDetailPgMove(data.orderNumber)}>{t(`modHeader`)}</div>
            </div>
        </div>
    )
}

export default withAuth(MUserPayComplPg);
