import React, { useEffect, useRef } from 'react';

function PaypalPaymentBt({ total, processAfterPaypal, currency }) {
    const paypalRef = useRef();

    useEffect(() => {
        window.paypal.Buttons({
            createOrder: (data, actions) => {
                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            currency_code: currency,
                            value: total
                        }
                    }]
                });
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture();
                if (order && order.status === "COMPLETED") {
                    processAfterPaypal("COMPLETED");
                }
            },
            onError: (err) => {
                console.error("Payment Error!", err);
            }
        }).render(paypalRef.current);
    }, [total]);

    return (
        <div>
            <div ref={paypalRef}></div>
        </div>
    );
}

export default PaypalPaymentBt;
