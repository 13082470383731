import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import MHeader from "../../components/mobile/MHeader";
import faqDetail from "../../assets/img/faqDetail.png";
import Expand from "../../assets/img/commonBottom.png";
import "../../css/mobile/MUserFaqPg.css";


const MUserFaqPg = () => {

    const navigate = useNavigate();
    const {t} = useTranslation();

    const moveToDetail = ((seqNum) => {
        navigate(`/UserFaqDtPg/${seqNum}`)
    })

    const [faqMstList, setFaqMstList] = useState([]);
    const [selectedMstTab, setSelectedMstTab] = useState('');

    const selectMstTab = () => axios({
        method: 'get',
        url: '/faq/selectMstTab/',
    })
        .then((response) => {
            const titles = response.data.map(item => item.title);
            setFaqMstList(titles);
        })

    useEffect(() => {
        setSelectedMstTab("전체");
        selectDtList();
    }, [])

    // 여기서부터 커스텀 셀렉트

    const [mstShow, setMstShow] = useState(false);
    const faqMstRef = useRef(null);

    const toggleMstShow = () => {
        setMstShow(!mstShow);
    };

    const handleMstChange = (title) => {
        selectDtList(title);
        setSelectedMstTab(title === null ? "전체" : title);
        setMstShow(false);
    };

    const [faqDtList, setFaqDtList] = useState([]);
    const selectDtList = (title) => axios({
        method: 'get',
        url: '/faq/selectDtList/',
        params: {
            title: title
        }
    })
        .then((response) => {
            setFaqDtList(response.data);
        })

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }


    return (
        <div className="m-a-user-faq-pg-container font-t-cont">
            <MHeader title={t(`fHeader`)} url={"/"}/>
            <div className={'m-a-user-faq-pg-header'}>
                <div className={'m-a-user-faq-pg-header-left'}>
                    <img className={'m-a-user-faq-pg-header-icon'} src={faqDetail} alt={''}/>
                    {t(`fHeader`)} {/*자주묻는질문*/}
                </div>
                <div ref={faqMstRef} className={'m-a-user-faq-pg-header-right'} onClick={toggleMstShow}>{selectedMstTab}</div>
            </div>
            <div className={'m-a-user-faq-pg-divider'}/>
            <div className={'m-a-user-faq-pg-table'}>
                <div className={'m-a-user-faq-pg-table-row label'}>
                    <div className={'m-a-user-faq-pg-table-row-item label'}>{t(`fSmallMenu1`)}</div>
                    <div className={'m-a-user-faq-pg-table-row-item label'}>{t(`fSmallMenu2`)}</div>
                    <div className={'m-a-user-faq-pg-table-row-item label lg'}>{t(`fSmallMenu3`)}</div>
                    <div className={'m-a-user-faq-pg-table-row-item label'}>{t(`fSmallMenu5`)}</div>
                    <div className={'m-a-user-faq-pg-table-row-item label'}>{t(`fSmallMenu4`)}</div>
                </div>
                {faqDtList.filter(data => data.fixFl === true).map((dt, i) => (
                    <div className={'m-a-user-faq-pg-table-row label'} key={i} onClick={() => moveToDetail(dt.seqNum)}>
                        <div className={'m-a-user-faq-pg-table-row-item fixed'}>{t(`fdFix`)}</div> {/*고정*/}
                        <div className={'m-a-user-faq-pg-table-row-item font-b admin-tb-text-cut'}>
                            [{dt?.faqMstDTO?.title}]
                        </div>
                        <div className={'m-a-user-faq-pg-table-row-item wd-200 admin-tb-text-cut'} style={{border: "solid 2px red"}}>
                            {dt?.title}
                        </div>
                        <div className={'m-a-user-faq-pg-table-row-item'}>
                            {formatDate(dt?.regDate)}
                        </div>
                        <div className={'m-a-user-faq-pg-table-row-item'}>
                            {dt?.regNm}
                        </div>
                    </div>
                ))}
                {faqDtList.filter(data => data.fixFl === false).map((dt, i) => (
                    <div className={'m-a-user-faq-pg-table-row label'}
                         key={i} onClick={() => moveToDetail(dt.seqNum)}>
                        <div className={'m-a-user-faq-pg-table-row-item'}>{i + 1}</div>
                        <div className={'m-a-user-faq-pg-table-row-item admin-tb-text-cut m-a-opc-07'}>
                            [{dt?.faqMstDTO?.title}]
                        </div>
                        <div className={'m-a-user-faq-pg-table-row-item lg admin-tb-text-cut'}>
                            {dt?.title}
                        </div>
                        <div className={'m-a-user-faq-pg-table-row-item'}>
                            {formatDate(dt?.regDate)}
                        </div>
                        <div className={'m-a-user-faq-pg-table-row-item'}>
                            {dt?.regNm}
                        </div>
                    </div>
                ))}
            </div>
            {mstShow && (
                <div className={'m-a-user-faq-pg-list-container'}
                     onClick={() => setMstShow(false)}>
                    <div className={'m-a-user-faq-pg-header-select-list'}>
                        <div className={'m-a-user-faq-pg-header-select-list-item'}
                             onClick={() => handleMstChange(null)}>
                            {t(`all`)}
                        </div>
                        {faqMstList.map((mst, index) => (
                            <div className={'m-a-user-faq-pg-header-select-list-item'}
                                 key={index} onClick={() => {
                                handleMstChange(mst)
                            }}
                                 value={selectedMstTab}> {mst}</div>
                        ))}
                        <div className={'m-a-user-faq-pg-header-select-close'}
                             onClick={() => setMstShow(false)}>
                            Close
                        </div>
                    </div>
                </div>
            )}

        </div>
    )

}

export default MUserFaqPg;
