import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../../css/web/UserHeaderMain.css'
import HeaderLogo from '../../../assets/img/HeaderLogo.png'
import {useLocation} from "react-router-dom";
import profileImage from '../../../assets/img/profileDefaultImg.png'
import clock from '../../../assets/img/clock.png'
import selectLang from '../../../assets/img/selectLang.png'
import ordChatAlarm from '../../../assets/img/orderChatAlarm.png'
import {useNavigate} from "react-router-dom";
import axios from "axios";
import commonClose from '../../../assets/img/commonClose.png'
import SelectLangModal from "../modal/SelectLangModal";
import KoreaTime from "../modal/KoreaTime";
import {useChat} from '../../contextApi/ChatContext';
import noMessage from "../../../assets/img/noMessage.png"
import {connect} from "../../../services/WebsocketService";

import i18n from '../../../i18n';
import {useTranslation} from "react-i18next";
import {useWebSocket} from "../../../services/WebsocketContext";

function UserHeaderMain() {
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();

    // 세션스토리지에 저장되어 있는 AT
    const SSAT = sessionStorage.getItem('AToken');
    // 로컬스토리지에 저장되어 있는 AT
    const LCAT = localStorage.getItem('AToken');

    // 로그아웃 axios
    const logout = () => axios({
        method: 'post',
        url: '/member/logout',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        }
    })
        .then(() => {
            if (SSAT) {
                sessionStorage.removeItem('AToken')
                sessionStorage.removeItem('lastLoginTime')
            } else {
                localStorage.removeItem('AToken');
                localStorage.removeItem('lastLoginTime')
            }
            navigate("/UserLoginPg")
        })
        .catch(() => {
            if (SSAT) {
                sessionStorage.removeItem('AToken')
                sessionStorage.removeItem('lastLoginTime')
            } else {
                localStorage.removeItem('AToken');
                localStorage.removeItem('lastLoginTime')
            }
            navigate("/UserLoginPg")
        })

    //메인메뉴 드롭다운 state
    const [dropMenu, setDropMenu] = useState(0);

    const [profilePhotoLine, setProfilePhotoLine] = useState(profileImage);
    const [profileName, setProfileName] = useState("");
    const [memberDto, setMemberDto] = useState([]);

    const selectMember = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            }
        })
            .then((response) => {
                setMemberDto(response.data[0]);
                setProfileName(response.data[0].userName)

                if (response.data && response.data[0] && response.data[0].profileDTO && response.data[0].profileDTO.filename) {
                    setProfilePhotoLine("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + response.data[0].profileDTO.filename)
                } else {
                    setProfilePhotoLine(profileImage)
                }
            })
            .catch(() => {
                console.log("실패")
            })
    })

    useEffect(() => {
        if (LCAT || SSAT) {
            selectMember();
        }
    }, [LCAT, SSAT]);

    useEffect(() => {
        if (memberDto && memberDto.mbNum) {
            getChatData(memberDto.mbNum);
        }
    }, [memberDto]);

    const [chatData, setChatData] = useState([]);
    const [totalUnreadCount, setTotalUnreadCount] = useState(0);

    const getChatData = (mbNum) => {
        axios.get(`/order/chatAlarmList/${mbNum}`, {
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            }
        })
            .then(response => {
                let data = response.data;
                data.sort((a, b) => b.seqnum - a.seqnum);
                // data = data.slice(0, 6);
                setChatData(data)
                const unreadCountUpdate = data.reduce((total, chat) => total + (chat.unreadCount || 0), 0);
                setTotalUnreadCount(unreadCountUpdate);
            })
            .catch(error => {
                console.log(error);
            })
    }

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!memberDto.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(memberDto.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                // 관리자메시지
                subscribe(`/user/${memberDto.mbNum}/queue/adminSubMessage`, onMessageReceived)
                // 전송메시지를 관리자가 읽을 시 수신
                subscribe(`/user/${memberDto.mbNum}/queue/headerCountInit`, onChatAdminUpdate)
                // 메시지 및 파일챗 변경 시 수신
                subscribe(`/user/${memberDto.mbNum}/queue/adminChatUpdate`, onChatAdminUpdate)
                subscribe(`/user/${memberDto.mbNum}/queue/adminUpdateOrder`, updateOrderList)
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                unsubscribe(`/user/${memberDto.mbNum}/queue/adminSubMessage`)
                unsubscribe(`/user/${memberDto.mbNum}/queue/headerCountInit`)
                unsubscribe(`/user/${memberDto.mbNum}/queue/adminChatUpdate`)
                unsubscribe(`/user/${memberDto.mbNum}/queue/adminUpdateOrder`)
                isSubscribedRef.current = false;
            }
        };

    }, [memberDto.mbNum, isConnected, stompClient]);

    const onMessageReceived = async (payload) => {
        let newMessage = JSON.parse(payload.body);
        if (Number(newMessage.mstMbNum) === Number(memberDto.mbNum) && Number(newMessage.memberDTO.role) === 1) {
            getChatData(memberDto.mbNum);
        }
    }

    const onChatAdminUpdate = ((payload) => {
        getChatData(memberDto.mbNum);
    })

    const updateOrderList = ((payload) => {
        getChatData(memberDto.mbNum);
    })

    const [dropdownSt, setDropdownSt] = useState(false);
    const dropdownRef = useRef(null);
    const dropdownShow = (() => {
        setDropdownSt(true);
    })
    const dropdownClose = (() => {
        setDropdownSt(false);
    })

    useEffect(() => {
        // 외부 클릭 감지 함수
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                dropdownClose();
            }
        }

        // 문서에 클릭 이벤트 리스너 추가
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const myOrdPgMove = (ordNum) => () => {
        setDropdownSt(false);
        navigate(`/UserMyOrderPg/${ordNum}`)
    };

    // 언어선택
    const [selectLangSt, setSelectLangSt] = useState(false);
    const selectLangTrueFn = (() => {
        setSelectLangSt(true);
    })
    const selectLangFalseFn = (() => {
        setSelectLangSt(false);
    })
    const handleSelectLangChange = (lang) => {
        setSelectLangSt(false);
        // 로컬스토리지 언어변경 + i18next 언어변경 code
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang);
    }

    const [koreaTimeSt, setKoreaTimeSt] = useState(false);
    const koreaTimeTrueFn = (() => {
        setKoreaTimeSt(true);
    })
    const koreaTimeFalseFn = (() => {
        setKoreaTimeSt(false);
    })

    const moveToMyPg = (() => {
        navigate("/UserMyPg")
    })

    const convertToLastChatDate = ((regDate) => {
        const regTime = new Date(regDate);
        const currentTime = new Date();
        const timeDifference = currentTime - regTime;

        // 날짜 차이를 분으로 변환
        const minutesDiff = Math.floor(timeDifference / 60000);
        const hoursDiff = Math.floor(minutesDiff / 60);
        const daysDiff = Math.floor(hoursDiff / 24);

        // 날짜 차이가 1일 미만일 때
        if (daysDiff < 1) {
            if (minutesDiff < 1) return t('now');
            if (minutesDiff < 60) return `${minutesDiff}${t('min')}`;
            return `${hoursDiff}${t('hour')}`;
        } else if (daysDiff === 1) {
            return t('yesterday');
        } else if (daysDiff === 2) {
            return t('2daysAgo');
        } else if (daysDiff < 7) {
            return `${daysDiff}${t('daysAgo')}`;
        } else {
            // 7일 이상 차이날 때는 월-일 형식으로 반환
            return `${(regTime.getMonth() + 1).toString().padStart(2, '0')}-${regTime.getDate().toString().padStart(2, '0')}`;
        }
    })

    const handleMenuCart = () => {
        navigate("/UserOrderPg", {state: {cartState: true, headerText: t("hdMenu2")}});
    };
    const handleMenuOrder = () => {
        navigate("/UserOrderPg", {state: {orderState: true, headerText: t("hdSMenu6")}});
    };
    const handleMenuOffline = () => {
        navigate("/UserOrderPg", {state: {offlineState: true, headerText: t("hdSMenu8")}});
    };
    const handleMenuTransfer = () => {
        navigate("/UserOrderPg", {state: {transferState: true, headerText: t("hdSMenu7")}});
    };
    const handleMenuDomestic = () => {
        navigate("/UserOrderPg", {state: {domesticState: true, headerText: t("hdSMenu9")}});
    };
    const handleMenuDelivery = () => {
        navigate("/UserOrderPg", {state: {deliveryState: true, headerText: t("hdMenu4")}});
    };

    const mainRefresh = () => {
        navigate(`/`);
        setSelectedCategory(null);
        setServiceMenu(false);
        setOrderMenu(false);
    }

    const handleMenu = (menu) => {
        setSelectedCategory(menu);
        if (menu === "service") {
            setServiceMenu(true);
            setOrderMenu(false);
        } else if (menu === "order") {
            setServiceMenu(false);
            setOrderMenu(true);
        }
    };

    const [serviceMenu, setServiceMenu] = useState(false);
    const [orderMenu, setOrderMenu] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [menuState, setMenuState] = useState({service: false, order: false});

    const handleMenuClick = (item) => {
        if (item === "service" || item === "order") {
            handleMenu(item);
        } else {
            setServiceMenu(false);
            setOrderMenu(false);
            setSelectedCategory(item);

            switch (item) {
                case t("hdMenu2"):
                    handleMenuCart();
                    break;
                case t("hdMenu3"):
                    handleMenuOrder();
                    break;
                case t("hdMenu4"):
                    handleMenuDelivery();
                    break;
                case t("hdMenu5"):
                    navigate("/UserReviewPg");
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <div style={{
            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
            position: "fixed",
            zIndex: 18,
            marginBottom: "100px",
            top: 0,
            left: 0,
            right: 0,
            margin: "auto",
            backgroundColor: "#fff"
        }}>
            <div style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                backgroundColor: "white",
                zIndex: -5,
                opacity: 0.8
            }}></div>
            {/*메인메뉴*/}
            <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{width: "1280px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    {/*메인메뉴영역*/}
                    <div style={{width: "78%", height: "60px", display: "flex", alignItems: "center"}}>
                        <div onClick={mainRefresh} className="display-flex align-center c-point p-b-1 m-r-30">
                            <img alt="mainLogo" style={{width: "140px", height: "32px", opacity: "0.8"}}
                                 src={HeaderLogo}/>
                        </div>
                        {/*<div className="btorage-hover c-point p-t-6" onClick={()=> handleMenu("service")}*/}
                        {/*     style={{minWidth:"120px", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", marginRight:"-10px"}}>*/}
                        {/*    <p className="font-t-head font-s-17">{t("hdMenu1")}</p>*/}
                        {/*</div>*/}
                        <div className="btorage-hover c-point p-t-6" onClick={() => handleMenuClick("service")}
                             style={{
                                 minWidth: "120px",
                                 height: "100%",
                                 display: "flex",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 marginRight: "-10px"
                             }}>
                            <p className={`font-t-head font-s-17 ${serviceMenu ? "bt-clicked" : ""}`}>{t("hdMenu1")}</p>
                        </div>

                        {/*<div className="btorage-hover c-point p-t-6" onClick={handleMenuCart}*/}
                        {/*     style={{minWidth:"120px", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>*/}
                        {/*    <p className={`font-t-head font-s-17 bt-active ${selectedCategory === t("hdMenu2") ? "bt-clicked" : ""}`} onClick={handleCategoryClick}>{t("hdMenu2")}</p>*/}
                        {/*</div>*/}
                        <div
                            className={`btorage-hover c-point p-t-6 ${selectedCategory === t("hdMenu2") ? "bt-clicked" : ""}`}
                            onClick={() => handleMenuClick(t("hdMenu2"))}
                            style={{
                                minWidth: "120px",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                            <p className="font-t-head font-s-17">{t("hdMenu2")}</p>
                        </div>
                        {/*<div className="btorage-hover c-point p-t-6" onClick={()=> handleMenu("order")}*/}
                        {/*     style={{minWidth:"120px", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>*/}
                        {/*    <p className="font-t-head font-s-17">{t("hdMenu3")}</p>*/}
                        {/*</div>*/}
                        <div className="btorage-hover c-point p-t-6" onClick={() => handleMenuClick("order")}
                             style={{
                                 minWidth: "120px",
                                 height: "100%",
                                 display: "flex",
                                 justifyContent: "center",
                                 alignItems: "center"
                             }}>
                            <p className={`font-t-head font-s-17 ${orderMenu ? "bt-clicked" : ""}`}>{t("hdMenu3")}</p>
                        </div>
                        {/*<div className="btorage-hover c-point p-t-6" onClick={handleMenuDelivery}*/}
                        {/*     style={{minWidth:"120px", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>*/}
                        {/*    <p className="font-t-head font-s-17">{t("hdMenu4")}</p>*/}
                        {/*</div>*/}
                        <div
                            className={`btorage-hover c-point p-t-6 ${selectedCategory === t("hdMenu4") ? "bt-clicked" : ""}`}
                            onClick={() => handleMenuClick(t("hdMenu4"))}
                            style={{
                                minWidth: "120px",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                            <p className="font-t-head font-s-17">{t("hdMenu4")}</p>
                        </div>
                        {/*<div className="btorage-hover c-point p-t-6" onClick={()=> navigate("/UserReviewPg")}*/}
                        {/*     style={{minWidth:"120px", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>*/}
                        {/*    <p className="font-t-head font-s-17">{t("hdMenu5")}</p>*/}
                        {/*</div>*/}
                        <div
                            className={`btorage-hover c-point p-t-6 ${selectedCategory === t("hdMenu5") ? "bt-clicked" : ""}`}
                            onClick={() => handleMenuClick(t("hdMenu5"))}
                            style={{
                                minWidth: "120px",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                            <p className="font-t-head font-s-17">{t("hdMenu5")}</p>
                        </div>
                    </div>
                    {/*로그인영역*/}
                    <div className="wd-20pc ht-100pc display-flex just-cont-fend align-center">
                        <div onClick={selectLangTrueFn}
                             className="wd-40 ht-100pc display-flex just-cont-center align-center c-point">
                            <img alt="lang" style={{width: "30px", height: "30px", opacity: "0.7"}} src={selectLang}/>
                        </div>
                        <div onClick={koreaTimeTrueFn} style={{
                            width: "40px",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer"
                        }}>
                            <img alt="clock" style={{width: "32px", height: "32px"}} src={clock}/>
                        </div>
                        {LCAT || SSAT ?
                            <div className="headerPropileContainer font-t-cont font-c-default">
                                <div ref={dropdownRef} className="profileImg"
                                     style={{margin: "auto", marginRight: "10px"}}>
                                    <button style={{
                                        overflow: "hidden",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "100%",
                                        cursor: "pointer",
                                        border: "none"
                                    }} onClick={dropdownShow}>
                                        <img className="pfContent wd-40 ht-40" alt="" src={profilePhotoLine}></img>
                                    </button>
                                    {totalUnreadCount > 0 ?
                                        <span style={{
                                            position: "absolute",
                                            top: -5,
                                            right: -5,
                                            borderRadius: "100%",
                                            width: "auto",
                                            height: "18px",
                                            backgroundColor: "#FF6324",
                                            color: "white",
                                            fontSize: "14px",
                                            marginLeft: "5px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "0 5px"
                                        }}>{totalUnreadCount}</span>
                                        :
                                        null
                                    }
                                    {dropdownSt ?
                                        <div style={{
                                            width: "325px",
                                            height: "auto",
                                            right: "-90px",
                                            position: "absolute",
                                            backgroundColor: "white",
                                            minWidth: "130px",
                                            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                                            zIndex: "3",
                                            left: "-180px",
                                            top: "55px",
                                            borderRadius: "6px"
                                        }}>
                                            <div style={{
                                                height: "40px",
                                                width: "30px",
                                                paddingTop: "8px",
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: "0",
                                                right: "5px"
                                            }} onClick={dropdownClose}>
                                                <img alt="" style={{width: "30px", height: "30px", opacity: "0.6"}}
                                                     src={commonClose}/>
                                            </div>
                                            <div style={{height: "auto", width: "100%"}}>
                                                <div className="userImg" style={{
                                                    height: "90px",
                                                    marginTop: "20px",
                                                    borderRadius: "8px",
                                                    display: "block"
                                                }}>
                                                    <div className="profilePhoto"
                                                         style={{textAlign: "center", borderRadius: "10px"}}>
                                                        <img style={{
                                                            height: "80px",
                                                            marginTop: "5px",
                                                            borderRadius: "6px"
                                                        }} alt="" src={profilePhotoLine}></img>
                                                    </div>
                                                </div>
                                                <div className="userViewName text-center font-s-18"><p>{profileName}</p>
                                                </div>
                                                <div style={{padding: "20px", marginBottom: "10px"}}>
                                                    <div style={{
                                                        height: "30px",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center"
                                                    }}>
                                                        <p style={{fontSize: "15px"}}>{t(`myOrdPc1`)}</p>
                                                        <div style={{display: "flex", alignItems: "center"}}>
                                                            <p style={{fontSize: "15px", marginRight: "3px"}}>BT</p>
                                                            <p style={{
                                                                fontSize: "15px",
                                                                color: "darkorange"
                                                            }}>{memberDto.mbNum}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        height: "30px",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center"
                                                    }}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "15px",
                                                            fontWeight: "lighter"
                                                        }}>{t(`myOrdPc2`)}</p>
                                                        <p style={{margin: "0", fontSize: "15px", color: "darkorange"}}>
                                                            {memberDto && memberDto?.deposit?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || ''}
                                                        </p>
                                                    </div>
                                                    <div style={{
                                                        height: "30px",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center"
                                                    }}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "15px",
                                                            fontWeight: "lighter"
                                                        }}>{t(`myOrdPc3`)}</p>
                                                        <p style={{margin: "0", fontSize: "15px", color: "darkorange"}}>
                                                            {memberDto && memberDto?.point?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || ''}
                                                        </p>
                                                    </div>
                                                </div>
                                                {/*알림 챗메시지 컨테이너*/}
                                                <div style={{
                                                    // margin: "45px 0 10px 0",
                                                    height: "auto",
                                                    display: "block",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: "10px"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginBottom: "10px"
                                                    }}>
                                                        <img alt="alarmImg"
                                                             style={{width: "25px", height: "25px", margin: "0 5px"}}
                                                             src={ordChatAlarm}/>
                                                        <p style={{margin: "0", fontSize: "15px"}}>{t(`hdChat`)}</p>
                                                    </div>
                                                    {chatData[0] ?
                                                        <div>
                                                            {chatData.map((data, index) => (
                                                                data.unreadCount > 0 && (
                                                                    <div key={index} style={{
                                                                        width: "95%",
                                                                        height: "60px",
                                                                        borderTop: "solid",
                                                                        borderWidth: "thin 1px",
                                                                        borderColor: "lightgray",
                                                                        borderRadius: "0",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        position: "relative",
                                                                        margin: "auto"
                                                                    }}>
                                                                        {/*관리자로부터 도착한 메시지 // button 클릭 시 주문번호를 가지고 navigate("/UserMyOrderPg")로 이동 */}
                                                                        <button className="chatListBt" style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            display: "flex",
                                                                            border: "none"
                                                                        }} onClick={myOrdPgMove(data.chatOrdNum)}>
                                                    <span style={{
                                                        position: "absolute",
                                                        left: "35px",
                                                        top: "6px",
                                                        borderRadius: "100%",
                                                        width: "auto",
                                                        height: "16px",
                                                        backgroundColor: "#FF6324",
                                                        color: "white",
                                                        fontSize: "12px",
                                                        marginLeft: "5px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        padding: "0 5px"
                                                    }}>{data.unreadCount}</span>
                                                                            <div style={{
                                                                                borderWidth: "thin 1px",
                                                                                borderColor: "rgba(0, 0, 0, 0.1)",
                                                                                height: "80%",
                                                                                width: "18%",
                                                                                marginRight: "5px",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                overflow: "hidden",
                                                                                margin: "auto",
                                                                            }}><img style={{
                                                                                width: "45px",
                                                                                height: "45px",
                                                                                display: "block",
                                                                                margin: "auto",
                                                                                borderRadius: "100%",
                                                                            }} alt=""
                                                                                    src={data.memberDTO && data.memberDTO.profileDTO && data.memberDTO.profileDTO.filename ? "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + data.memberDTO.profileDTO.filename : HeaderLogo}/>
                                                                            </div>
                                                                            <div style={{
                                                                                width: "80%",
                                                                                padding: "0 8px",
                                                                                margin: "auto",
                                                                            }}>
                                                                                <div style={{
                                                                                    height: "50%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "space-between"
                                                                                }}>
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        fontSize: "13px",
                                                                                    }}>{data.chatOrdNum}
                                                                                    </p>
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        fontSize: "10px",
                                                                                        color: "darkgray"
                                                                                    }}>{convertToLastChatDate(data.regTime)}</p>
                                                                                </div>
                                                                                <span style={{
                                                                                    height: "50%",
                                                                                    fontSize: "13px",
                                                                                    textAlign: "left",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    color: "dimgrey",
                                                                                    overflow: "hidden",
                                                                                    textOverflow: "ellipsis",
                                                                                    whiteSpace: "nowrap"
                                                                                }}>{data.chatKey.startsWith("chatFile") ? data.fileNm : data.chatKey}</span>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                )
                                                            ))}
                                                        </div>
                                                        :
                                                        <div style={{
                                                            width: "100%",
                                                            height: "100px",
                                                            borderTop: "solid",
                                                            borderWidth: "thin 1px",
                                                            borderColor: "#EAEAEA"
                                                        }}>
                                                            <div style={{
                                                                height: "60%",
                                                                display: "flex",
                                                                alignItems: "flex-end",
                                                                justifyContent: "center"
                                                            }}>
                                                                <img alt="noData" style={{
                                                                    width: "35px",
                                                                    height: "35px",
                                                                    opacity: "0.6"
                                                                }} src={noMessage}/>
                                                            </div>
                                                            <div style={{
                                                                height: "40%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                marginTop: "5px"
                                                            }}>
                                                                <p style={{
                                                                    margin: "0",
                                                                    fontSize: "14px",
                                                                    color: "darkgray",
                                                                    fontWeight: "lighter"
                                                                }}>No Message</p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                {/*드랍다운 버튼*/}
                                                <div style={{
                                                    height: "60px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    padding: "0 10px"
                                                }}>
                                                    <div className="user-center" style={{width: "50%"}}>
                                                        <button className="OrderPgBt2"
                                                                style={{width: "145px", height: "40px"}}
                                                                onClick={() => navigate("/UserModiPg")}>{t(`hdBt1`)}
                                                        </button>
                                                    </div>
                                                    <div className="user-center" style={{width: "50%"}}>
                                                        <button className="OrderPgBt2"
                                                                style={{width: "145px", height: "40px"}}
                                                                onClick={logout}>{t(`hdBt2`)}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                <div style={{
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <button onClick={moveToMyPg} className="headerLoginBt" style={{minWidth: "90px"}}>
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`hdMypg`)}</p>
                                    </button>
                                </div>
                            </div>
                            :
                            <div style={{display: "flex"}}>
                                <div style={{
                                    width: "75px",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "0 10px 0 5px"
                                }}>
                                    <button onClick={() => navigate("/UserLoginPg")} className="headerLoginBt">
                                        <p style={{margin: "0", fontSize: "12px"}}>{t(`hdLogin`)}</p>
                                    </button>
                                </div>
                                <div style={{
                                    width: "75px",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginLeft: "-5px"
                                }}>
                                    <button onClick={() => navigate("/UserJoinPg")} className="headerJoinBt">
                                        <p style={{margin: "0", fontSize: "12px", color: "white"}}>{t(`hdJoin`)}</p>
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/*소메뉴*/}
            {serviceMenu ?
                <div className="display-flex just-cont-center">
                    <div className="display-flex align-center" style={{width: "1280px"}}>
                        <div className="display-flex align-center c-point" style={{minWidth: "165px"}}></div>
                        <div className="ht-30 display-flex just-cont-sb m-l-30 font-s-13" style={{minWidth: "100px"}}>
                            <a className="btorage-hover font-t-cont c-point"
                               onClick={() => navigate('/UserUsageGuidePg')}
                               style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu2`)}</a>
                            <a className="btorage-hover font-t-cont c-point"
                               onClick={() => navigate('/UserCustomInfoPg')}
                               style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu3`)}</a>
                            <a className="btorage-hover font-t-cont c-point"
                               onClick={() => navigate('/UserCompareShipPg')}
                               style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu4`)}</a>
                            <a className="btorage-hover font-t-cont c-point" onClick={() => navigate('/UserDepChargPg')}
                               style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu5`)}</a>
                        </div>
                    </div>
                </div>
                :
                orderMenu ?
                    <div className="display-flex just-cont-center">
                        <div className="display-flex align-center" style={{width: "1280px"}}>
                            <div className="display-flex align-center c-point" style={{minWidth: "165px"}}></div>
                            <div className="ht-30 display-flex just-cont-sb m-l-30 font-s-13"
                                 style={{minWidth: "100px"}}>
                                <a className="btorage-hover font-t-cont c-point" onClick={handleMenuOrder}
                                   style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu6`)}</a>
                                <a className="btorage-hover font-t-cont c-point" onClick={handleMenuTransfer}
                                   style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu7`)}</a>
                                <a className="btorage-hover font-t-cont c-point" onClick={handleMenuOffline}
                                   style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu8`)}</a>
                                <a className="btorage-hover font-t-cont c-point" onClick={handleMenuDomestic}
                                   style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu9`)}</a>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            {selectLangSt ?
                <SelectLangModal selectLangTrueFn={selectLangTrueFn} selectLangFalseFn={selectLangFalseFn}
                                 handleSelectLangChange={handleSelectLangChange}/>
                :
                koreaTimeSt ?
                    <KoreaTime koreaTimeFalseFn={koreaTimeFalseFn}/>
                    :
                    null
            }
        </div>
    )
}

export default UserHeaderMain
