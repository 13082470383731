import '../../css/mobile/MFaqDtPg.css';
import MHeader from "../../components/mobile/MHeader";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import faqDetail from "../../assets/img/faqDetail.png";

const MFaqDtPg = () => {

    const {t} = useTranslation();
    const {seqNum} = useParams();
    const navigate = useNavigate();

    const [faqDtData, setFaqDtData] = useState([]);
    const [photoList, setPhotoList] = useState([]);

    const selectDtData = () => axios({
        method: 'get',
        url: '/faq/selectFaqDtData/',
        params: {
            seqNum: seqNum
        }
    })
        .then((response) => {
            setFaqDtData(response.data);
            setPhotoList(response.data.faqDtPhotoDTO)
            console.log("Faq Dt데이터 : ", response.data)
        })

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    useEffect(() => {
        if (seqNum) {
            selectDtData();
        }
    }, [])

    const sanitizeHTML = (rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    };


    return (
        <div className="m-a-faq-dt-page-container font-t-cont">
            <MHeader title={t(`fdHeader`)} url={"/UserFaqPg"}/> {/*질문상세*/}
            <div className='m-a-faq-detail-sub-title'>
                <img className='m-a-faq-detail-sub-title-icon' src={faqDetail} alt=''/>
                <div className='m-a-faq-detail-sub-title-text'>{t(`fHeader`)}</div> {/*자주묻는질문*/}
            </div>
            <div className={'m-a-faq-detail-divider'}/>
            <div className={'m-a-faq-detail-info-box'}>
                <div className={'m-a-faq-detail-info-text'}> {faqDtData?.regNm}</div>
                <div className={'m-a-faq-detail-info-text'}>{formatDate(faqDtData?.regDate)}</div>
                {/*<div className={'m-a-faq-detail-info-text'}>조회수: 0</div>*/}
            </div>
            <div className={'m-a-faq-detail-frame'}>
                <div className="m-a-faq-detail-title"><div className="m-a-faq-detail-title-c">{faqDtData?.title}</div></div>
                <div className={'m-a-faq-detail-content'}>
                    <div className={'m-a-notice-faq-content-text'}
                         dangerouslySetInnerHTML={{__html: sanitizeHTML(faqDtData?.content)}}/>
                    {photoList && photoList.map((photo, index) => (
                        <img className={'m-a-notice-faq-content-img'}
                             src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/writePhoto/" + photo.fileNm}
                             alt={'photoImg'}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MFaqDtPg;
