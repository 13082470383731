import React, {useCallback, useEffect, useState} from 'react';
import axios from "axios";
import MsgSelectModal from "./MsgSelectModal";

function OutWaybillModal() {

    /** region DataTable */

    const [ordOutWaybillData, setOrdOutWaybillData] = useState([]);
    const [waybillData, setWaybillData] = useState({});

    /** endregion */

    /** region Function */

    // region Alert

    const [alertShow, setAlertShow] = useState(false);
    const msgConfirmData1 = "주문정보를 출고처리 하시겠습니까??";
    const msgConfirmData2 = "입력한 운송장번호를 다시 한 번 확인해주세요.";
    const alert = (() => {
        setAlertShow(true)
    })
    const modalCancel1 = useCallback(() => {
        setAlertShow(false);
    })

    // endregion

    const handleInputChange = (value, index, field) => {
        const updatedData = ordOutWaybillData.map((item, i) => {
            if (i === index) {
                return {...item, shipInfoDTO: {...item.shipInfoDTO, [field]: value}};
            }
            return item;
        });
        setOrdOutWaybillData(updatedData);
    };

    /** endregion */

    /** region Event */

    useEffect(() => {
        const receivedData = JSON.parse(localStorage.getItem('ordOutWaybill'));
        localStorage.removeItem('ordOutWaybill');

        const dataWithSelectedCustom = receivedData.map(item => ({
            ...item,
            selectedCustom: item.customsDTO.customItemDTO.hsCodeNm
        }));

        setOrdOutWaybillData(dataWithSelectedCustom);
    }, []);

    const shipInfoData = ordOutWaybillData.map(entry => ({
        seqNum: entry.shipInfoDTO.seqNum,
        outWayNum: entry.shipInfoDTO.outWayNum
    }));

    const orderdtData = ordOutWaybillData.map(data => ({
        seqnum: data.orderdtDTO.seqnum
    }));

    const payInfoData = ordOutWaybillData.map(data => ({
        seqNum: data.seqNum
    }));

    const outWaybillUpdate = () => {
        const updateRequest = {
            shipInfoDTOList: shipInfoData,
            orderdtDTOList: orderdtData,
            payinfoDTOList: payInfoData
        };
        axios.post('/order/ordWaybillUpdate', updateRequest, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(() => {
                setAlertShow(false);
                if (window.opener) {
                    window.opener.location.reload();
                    window.close();
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    /** endregion */

    return (
        <div className="OutWaybillModal admin-modal-default">
            <div className="openModal regModal">
                <section>
                    <header className="admin-work-title">
                        <b style={{color: "#ffffff"}}>임의세팅 & 출고운송장 등록</b>
                    </header>
                    <main>
                        <div className="div-tb m-b-10">
                            <div className="div-tb-head ht-50">
                                <div style={{width: "210px"}}><p>주문번호</p></div>
                                <div style={{width: "170px"}}><p>수취인명</p></div>
                                <div style={{width: "330px"}}><p>내용 및 운송장번호</p></div>
                            </div>
                            {ordOutWaybillData.map((oData, i) => (
                                <div key={i} className="div-tb-body ht-70">
                                    <div style={{width: "210px"}}><p className="font-b">{oData.orderdtDTO.orderNumber} - {oData.shipInfoDTO.boxNum}</p></div>
                                    <div style={{width: "170px"}}><p className="font-b">{oData.appRecDTO.recname}</p></div>
                                    <div style={{width: "330px"}}>
                                        <input className="admin-input-line ht-30 text-center font-s-14 m-l-5 font-c-progress font-b" style={{width: "320px"}} type="text"
                                               placeholder="운송장번호 및 내용을 입력하세요." value={oData.shipInfoDTO.outWayNum}
                                               onChange={(e) => handleInputChange(e.target.value, i, 'outWayNum')}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </main>
                    <footer>
                        <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event m-r-30" onClick={() => window.close()}>취소</button>
                        <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={alert}>등록</button>
                    </footer>
                </section>
            </div>
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel1} eventHandle2={outWaybillUpdate} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
        </div>
    );
}

export default OutWaybillModal
