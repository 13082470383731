import React, {useEffect, useState} from 'react';
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import axios from "axios";
import {MdKeyboardArrowDown} from "react-icons/md";
import MsgConfirmModal from "../../components/web/modal/MsgConfirmModal";
import maCateImg from "../../assets/img/m-a-cate.png";
import AdminDelSetModal from "../../components/web/modal/AdminDelSetModal";
import AdminDelAddModal from "../../components/web/modal/AdminDelAddModal";

function AdminCountryZipcodePg() {

    /**region DataTable */

    const [shipCoData, setShipCoData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [shipCoData.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (shipCoData.length > displayLimit && displayLimit < 30) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(15);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (dataItem) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.some(item => item.seqnum === dataItem.seqnum)) {
                return prevSelectedData.filter((item) => item.seqnum !== dataItem.seqnum);
            } else {
                return [...prevSelectedData, dataItem];
            }
        });
    };

    const allCheckBoxChange = (e) => {
        if (e.target.checked) {
            setChecked(shipCoData);
        } else {
            setChecked([]);
        }
    };

    // const [checked, setChecked] = useState([]);

    // const isAllChecked = shipCoData.length > 0 && checked.length === shipCoData.length;

    // const checkBoxChange = (id) => {
    //     setChecked(prevSelectedData => {
    //         if (prevSelectedData.includes(id)) {
    //             return prevSelectedData.filter(item => item !== id);
    //         } else {
    //             return [...prevSelectedData, id];
    //         }
    //     });
    // };

    // const allCheckBoxChange = (e) => {
    //     if (e.target.checked) {
    //         setChecked(shipCoData.map(item => item.seqnum));
    //     } else {
    //         setChecked([]);
    //     }
    // };

    //endregion

    /** endregion */

    /** region Event  */

    useEffect(() => {
        shipCompanySelect();
    }, []);

    const shipCompanySelect = () => {
        axios.get('/shipcompany/shipcoselect')
            .then((response) => {
                const data = response.data;
                setShipCoData(data);

                if (data.length > 0) {
                    const lastCode = data[data.length - 1].shipCoCode;
                    const nextCode = `D${parseInt(lastCode.replace('D', '')) + 1}`;
                    setAddShow(prev => ({ ...prev, PrimaryCode: nextCode }));
                } else {
                    setAddShow(prev => ({ ...prev, PrimaryCode: 'D1' }));
                }
            })
            .catch((error) => {
                console.error("데이터조회 실패", error);
            });
    };

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    const excelDown = () => {
        try {
            const selectedRows = shipCoData.filter((item) => checked.includes(item.seqnum));

            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function mapDataToExcelRow(item, index) {
                return {
                    '번호': index + 1,
                    '배송사명': item.courierCompanyName,
                    '배송사코드': item.shipCoCode,
                    '배송사구분': item.shippingCompanyCode === '0'? '국내배송' : '해외배송',
                    '연동여부': item.connFl === '0' ? 'N' : 'Y',
                    '등록일시': item.regTime,
                };
            }

            const sheetConfigs = {
                '배송사정보': {
                    name: '배송사정보',
                    columns: [
                        {header: '번호', key: '번호', hidden: false, width: 6, style: { alignment: { horizontal: 'center' }}},
                        {header: '배송사명', key: '배송사명', width: 20, style: { alignment: { horizontal: 'center' }}},
                        {header: '배송사코드', key: '배송사코드', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '배송사구분', key: '배송사구분', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '연동여부', key: '연동여부', width: 8, style: { alignment: { horizontal: 'center' }}},
                        {header: '등록일시', key: '등록일시', width: 20, style: { alignment: { horizontal: 'center' }}},
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });
            };

            const applyStylesToHeader = (sheet, height) => {
                const invisibleRow = sheet.getRow();
                invisibleRow.height = 1;

                const headerRow = sheet.getRow(1);
                headerRow.font = { name: '맑은 고딕', bold: true, size: 10 };
                headerRow.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                headerRow.height = height;

                headerRow.eachCell(() => {});
                applyBordersToRow(headerRow, borderHeaderStyle);
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                applyStylesToHeader(sheet, 23);
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item) => {
                    const row = sheet.addRow(item);
                    for (let i = 1; i <= sheet.columns.length; i++) {
                        row.getCell(i).font = { name: '맑은 고딕', size: 10 };
                    }
                    applyBordersToRow(row, border);
                });
            };

            let transformedSelectedRows;
            transformedSelectedRows = selectedRows.map(mapDataToExcelRow);

            if (sheetConfigs) {
                const sheet = createSheet(sheetConfigs['배송사정보'].name, sheetConfigs['배송사정보'].columns);
                applyStylesToHeader(sheet, 23);
                addRowsToSheet(sheet, transformedSelectedRows, borderHeaderStyle);
            }

            let fileName = `비토리지 배송사정보_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error(error);
        }
    };

    // endregion

    const [noSelectedPaypalExShow, setNoSelectedPaypalExShow] = useState(false);
    const noSelectedPaypalExMsg = "수정할 항목을 선택해주세요"
    const closeNoSelectedPaypalExShow = (() => {
        setNoSelectedPaypalExShow(false);
    })

    const [modifyShow, setModifyShow] = useState(false);

    const closeModifyShow = (() => {
        setModifyShow(false);
    })

    const [addShow, setAddShow] = useState({ show: false, PrimaryCode: '' });
    const [modiShow, setModiShow] = useState({ show: false, PrimaryCode: '' });

    const openAddShow = () => {
        setAddShow(prev => ({ ...prev, show: true }));
    };

    const openModiShow = () => {
        if (!checked || checked.length === 0) {
            setNoSelectedPaypalExShow(true);
        } else {
            setModiShow(prev => ({ ...prev, show: true }));
        }
    };

    const closeAddShow = () => {
        setAddShow(prev => ({ ...prev, show: false, PrimaryCode: '' }));
    };

    const closeModiShow = () => {
        setModiShow(prev => ({ ...prev, show: false, PrimaryCode: '' }));
    };

    const saveData = (shipNm, shipCode, shipTaskTy, apiFl) => {
        const shippingCompanyCode = shipTaskTy === "국내" ? "0" : "1";
        const connFl = apiFl === "연동" ? "1" : "0";

        axios({
            method: 'post',
            url: '/shipcompany/saveShipData',
            data: {
                courierCompanyName: shipNm,
                shipCoCode: shipCode,
                shippingCompanyCode: shippingCompanyCode,
                connFl: connFl
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setShipCoData([...shipCoData, response.data]);
                closeAddShow();
            })
            .catch(() => {});
    }

    const modifyData = ((modifyData) => {
        axios({
            method: 'post',
            url: '/paypshipcompanyal/modifyExrData',
            data: modifyData,
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                const modifiedData = response.data;
                const updatedData = shipCoData.map(item => {
                    const matchingItem = modifiedData.find(modifiedItem => modifiedItem.seqnum === item.seqnum);
                    if (matchingItem) {
                        return matchingItem;
                    }
                    return item;
                });
                setShipCoData(updatedData);
                closeModifyShow(false);
            })
            .catch((error) => {

            });
    });

    /** endregion */

    return (
        <div className="AdminEmployeeInfoPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">국가별 배송 우편번호관리</p>
            </div>
            <div className="admin-wrap">

            </div>
        </div>
    );
}

export default AdminCountryZipcodePg
