import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {BiCaretDown} from "react-icons/bi";
import commonHeader from "../../assets/img/commonHeader.png";
import mailHeader from "../../assets/img/mailInquiry.png";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

function UserMailInquiryPg() {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [selectedType, setSelectedType] = useState({value: '', label: t('umiLb1')});
    useEffect(() => {
        // 언어가 변경될 때마다 selectedType 업데이트
        setSelectedType({value: selectedType.value, label: t('umiLb1')});
    }, [t, selectedType.value]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [receivedMail, setReceivedMail] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [contentText, setContentText] = useState('');

    const [typeError, setTypeError] = useState(false);
    const [mailError, setMailError] = useState(false);
    const [contentError, setContentError] = useState(false);

    const [isSendBtLoading, setIsSendBtLoading] = useState(false);
    const [sendEmailFail, setSendEmailFail] = useState(false);

    const typeList = [
        {value: "help@btorage.com", label: t('umi1')},
        {value: "help@btorage.com", label: t('umi2')},
        {value: "help@btorage.com", label: t('umi3')},
        {value: "help@btorage.com", label: t('umi4')},
        {value: "help@btorage.com", label: t('umi5')},
        {value: "help@btorage.com", label: t('umi6')},
        {value: "help@btorage.com", label: t('umi7')},
        {value: "help@btorage.com", label: t('umi8')},
        {value: "help@btorage.com", label: t('umi9')},
        {value: "help@btorage.com", label: t('umi10')}
    ]

    const [typeShow, setTypeShow] = useState(false);
    const typeRef = useRef(null);

    const toggleCountryShow = () => {
        setTypeShow(!typeShow);
    };

    const handleTypeChange = (type) => {
        setSelectedType(type);
        setTypeError(false);
        setTypeShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (typeRef.current && !typeRef.current.contains(event.target)) {
                setTypeShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEmailChange = (e) => {
        setMailError(false);
        setReceivedMail(e.target.value);
    };

    const handleOrdNumChange = (e) => {
        setOrderNumber(e.target.value);
    };

    const handleContentChange = (e) => {
        setContentError(false);
        setContentText(e.target.value);
    };

    const validation = (() => {
        if (selectedType.value === '') {
            setTypeError(true);
            return false;
        }
        if (receivedMail === '') {
            setMailError(true);
            return false;
        }
        if (contentText === '') {
            setContentError(true);
            return false;
        }
        return true;
    })

    const sendEmail = (() => {
        setIsSendBtLoading(true);
        if (validation()) {
            axios({
                method: 'post',
                url: '/member/mailInquiry/',
                data: {
                    type: selectedType.label,
                    helperMail: selectedType.value,
                    receivedMail: receivedMail,
                    orderNumber: orderNumber,
                    content: contentText
                }
            })
                .then((response) => {
                    navigate("/UserMailSendComplPg");
                })
                .catch((error) => {
                    setIsSendBtLoading(false);
                    setSendEmailFail(true);
                    console.log("전송실패 : ", error)
                })
        } else {
            setIsSendBtLoading(false);
        }
    })
    // 실무게&가로&세로&높이 모두 입력될때만 DB에 전송하여 조회

    return (
        <div>
            <div style={{
                height: "700px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <div>
                    <div style={{
                        display:"flex",
                        height: "50px",
                        justifyContent:"space-between",
                    }}>
                        <div className="font-t-title" style={{display:"flex", justifyContent:"center"}}>
                            <img style={{width:"27px", height:"27px", opacity:"0.8"}} src={mailHeader} alt="header"/>
                            <p className="font-s-18 ht-100pc m-l-10 m-t-2">{t('umiHd1')}</p>
                        </div>
                    </div>
                    <div className="font-t-cont" style={{
                        width: "500px",
                        minHeight: "550px",
                        border:"solid",
                        borderWidth: "thin 1px",
                        borderColor: "#EAEAEA",
                        borderRadius: "6px",
                        padding: "50px"
                    }}>
                        {/*국가*/}
                        <div style={{height: "50px", display:"flex", marginBottom:"5px"}}>
                            <div style={{width:"100%"}}>
                                {/*여기서부터 커스텀 국가셀렉트*/}
                                <div ref={typeRef} style={{height: "100%", width: "100%", position: "relative", paddingTop:"6px"}}>
                                    <button className="selectParentBt" style={{width: "100%", height: "90%"}}
                                            onClick={toggleCountryShow}>
                                        <p style={{margin: "0", color:"dimgrey"}}>{selectedType.label}</p>
                                        <span style={{position: "absolute", left: "85%", top: "5%", height: "100%", width: "20%"}}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    color:"darkgray"
                                }}><BiCaretDown/></i>
                            </span>
                                    </button>
                                    {typeShow ?
                                        <div style={{
                                            border: "solid",
                                            height: "400px",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            marginTop: "5px",
                                            overflowY: "scroll",
                                        }}>
                                            {typeList.map((type, index) => (
                                                <div className="selectChildBt" key={index}
                                                     onClick={() => handleTypeChange(type)} style={{
                                                    display: "flex", padding: "10px", justifyContent: "center",
                                                    alignItems: "center", cursor: "pointer", fontSize: "14px", borderRadius: "4px",
                                                }}>{type.label}</div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                {/*여기까지 커스텀 국가셀렉트*/}
                            </div>
                        </div>
                        { typeError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg">{t('umiErr1')}</p>
                            </div>
                            :
                            null
                        }
                        {/*수신이메일*/}
                        <div className="user-input-container" style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center", height:"40px", marginBottom:"5px"}}>
                            <input onChange={(e)=> handleEmailChange(e)} type="text" className="user-input" style={{
                                width: "100%", height: "35px", borderRadius: "4px",
                                padding: "0 6px 0 6px", fontSize:"12px", color:"dimgray"
                            }} placeholder={t('umiPlaceHd1')} value={receivedMail}/>
                        </div>
                        { mailError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg">{t('umiErr2')}</p>
                            </div>
                            :
                            null
                        }
                        {/*주문서번호(선택)*/}
                        <div className="user-input-container" style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center", height:"40px", marginBottom:"5px"}}>
                            <input onChange={(e)=> handleOrdNumChange(e)} type="text" className="user-input" style={{
                                width: "100%", height: "35px", borderRadius: "4px",
                                padding: "0 6px 0 6px", fontSize:"12px", color:"dimgray"
                            }} placeholder={t('umiPlaceHd2')} value={orderNumber}/>
                        </div>
                        {/*내용*/}
                        <div className="user-input-container" style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center", marginBottom:"10px"}}>
                            <textarea
                                className="user-input"
                                placeholder={t('umiPlaceHd3')}
                                spellCheck="false"
                                value={contentText}
                                maxLength={10000}
                                style={{
                                    // height: "100px", // Initial height
                                    height:"250px",
                                    borderRadius: "6px",
                                    width: "100%",
                                    borderWidth: "thin 1px",
                                    borderColor: "lightgray",
                                    resize: "none",
                                    overflow: "auto",
                                    outline: "none",
                                    color: "dimgrey",
                                    padding: "10px",
                                    fontSize: "12px",
                                    ':focus': {
                                        borderColor: "orange"
                                    }
                                }} onChange={(e)=> handleContentChange(e)}/>
                        </div>
                        { contentError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg">{t('umiErr3')}</p>
                            </div>
                            :
                            null
                        }
                        {/*버튼*/}
                        <div onClick={sendEmail} className="user-center m-t-20 m-b-10">
                            <style>
                                {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                            </style>
                            <button className="colorFullBt user-center" style={{
                                width: "70px",
                                height: "35px",
                                textAlign: "center",
                                fontSize: "13px",
                            }}>
                                {isSendBtLoading ? (
                                    <div className="smallSpinner"></div>
                                ) : (
                                    <div>{t('umiBt1')}</div>
                                )}
                            </button>
                        </div>
                        { sendEmailFail &&
                            <div className="JoinErrorMsg" style={{textAlign: "center"}}>
                                <p className="JoinErrMsg">{t('umiErr4')}! &#128514;</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserMailInquiryPg
