import "../../css/mobile/MUserMailInquiryPg.css";
import MailInquiry from '../../assets/img/mailInquiry.png';
import MHeader from "../../components/mobile/MHeader";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import Expand from "../../assets/img/commonBottom.png";
import axios from "axios";

const MUserMailInquiryPg = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const [selectedType, setSelectedType] = useState({value: '', label: t('umiLb1')});
    useEffect(() => {
        // 언어가 변경될 때마다 selectedType 업데이트
        setSelectedType({value: selectedType.value, label: t('umiLb1')});
    }, [t, selectedType.value]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [receivedMail, setReceivedMail] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [contentText, setContentText] = useState('');

    const [typeError, setTypeError] = useState(false);
    const [mailError, setMailError] = useState(false);
    const [contentError, setContentError] = useState(false);

    const [isSendBtLoading, setIsSendBtLoading] = useState(false);
    const [sendEmailFail, setSendEmailFail] = useState(false);

    const typeList = [
        {value: 'help@btorage.com', label: t('umi1')},
        {value: 'help@btorage.com', label: t('umi2')},
        {value: 'help@btorage.com', label: t('umi3')},
        {value: 'help@btorage.com', label: t('umi4')},
        {value: 'help@btorage.com', label: t('umi5')},
        {value: 'help@btorage.com', label: t('umi6')},
        {value: 'help@btorage.com', label: t('umi7')},
        {value: 'help@btorage.com', label: t('umi8')},
        {value: 'help@btorage.com', label: t('umi9')},
        {value: 'help@btorage.com', label: t('umi10')}
    ]

    const [typeShow, setTypeShow] = useState(false);
    const typeRef = useRef(null);

    const toggleCountryShow = () => {
        setTypeShow(!typeShow);
    };

    const handleTypeChange = (type) => {
        setSelectedType(type);
        setTypeError(false);
        setTypeShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (typeRef.current && !typeRef.current.contains(event.target)) {
                setTypeShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const handleEmailChange = (e) => {
        setMailError(false);
        setReceivedMail(e.target.value);
    };

    const handleOrdNumChange = (e) => {
        setOrderNumber(e.target.value);
    };

    const handleContentChange = (e) => {
        setContentError(false);
        setContentText(e.target.value);
    };


    const validation = (() => {
        if (selectedType.value === '') {
            setTypeError(true);
            return false;
        }
        if (receivedMail === '') {
            setMailError(true);
            return false;
        }
        if (contentText === '') {
            setContentError(true);
            return false;
        }
        return true;
    })

    const sendEmail = (() => {
        setIsSendBtLoading(true);
        if (validation()) {
            axios({
                method: 'post',
                url: '/member/mailInquiry/',
                data: {
                    type: selectedType.label,
                    helperMail: selectedType.value,
                    receivedMail: receivedMail,
                    orderNumber: orderNumber,
                    content: contentText
                }
            })
                .then((response) => {
                    navigate("/UserMailSendComplPg");
                })
                .catch((error) => {
                    setIsSendBtLoading(false);
                    setSendEmailFail(true);
                    console.log("전송실패 : ", error)
                })
        } else {
            setIsSendBtLoading(false);
        }
    })


    // 실무게&가로&세로&높이 모두 입력될때만 DB에 전송하여 조회

    return (
        <div className='m-a-mail-inquiry-pg-container'>
            <MHeader title={t('umiHd1')} url={"/"}/>
            <div className='m-a-mail-inquiry-sub-title'>
                <img className='m-a-mail-inquiry-sub-title-icon' src={MailInquiry} alt=''/>
                <div className='m-a-mail-inquiry-sub-title-text'>{t('umiHd1')}</div>
            </div>
            <div className='m-a-mail-inquiry-divider'/>
            <div className='m-a-mail-inquiry-input-button'
                 ref={typeRef} onClick={toggleCountryShow}>
                {selectedType.label}
                <img className={'m-a-mail-inquiry-input-expand-icon'} src={Expand} alt={""}/>
                {typeShow && (
                    <div className={'m-a-mail-inquiry-type-list'}>
                        {typeList.map((type, index) => (
                            <div className={'m-a-mail-inquiry-type-item'}
                                 key={index} onClick={() => handleTypeChange(type)}>
                                {type.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {typeError &&
                <div className={'m-a-mail-inquiry-error'}>{t('umiErr1')}</div>
            }
            <input className='m-a-mail-inquiry-input'
                   onChange={(e) => handleEmailChange(e)}
                   type="text" placeholder={t('umiPlaceHd1')}
                   value={receivedMail}/>
            {mailError &&
                <div className={'m-a-mail-inquiry-error'}>{t('umiErr2')}</div>
            }
            <input className='m-a-mail-inquiry-input' placeholder={t('umiPlaceHd2')}
                   onChange={(e) => handleOrdNumChange(e)}
                   type="text" value={orderNumber}/>
            <textarea className='m-a-mail-inquiry-input mail-inquiry-content'
                      placeholder={t('umiPlaceHd3')}
                      spellCheck="false"
                      value={contentText}
                      maxLength={10000}
                      onChange={(e) => handleContentChange(e)}
            />
            {contentError &&
                <div className={'m-a-mail-inquiry-error'}>{t('umiErr3')}</div>
            }
            <div className='m-a-mail-inquiry-button'
                 onClick={sendEmail}>
                {isSendBtLoading ? (
                    <div className='m-a-small-spinner'/>
                ) : (
                    <div>{t('umiBt1')}</div>
                )}
            </div>
        </div>
    )
}

export default MUserMailInquiryPg;
