import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import axios from "axios";
import commonCheck from "../../assets/img/commonCheck.png";
import commonClose from "../../assets/img/commonClose.png";
import {MdKeyboardArrowDown} from "react-icons/md";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import IntegDetailPopup from "../../components/web/modal/IntegDetailPopup";
import maCateImg from "../../assets/img/m-a-cate.png";
import MAdminPageSmallEvent from "./MAdminPageSmallEvent";
import MAdminSearchEvent from "./MAdminSearchEvent";
import {useWebSocket} from "../../services/WebsocketContext";

function MAdminPayWaitPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');

    const [userNm, setUserNm] = useState('');
    const [adminInfo, setAdminInfo] = useState([]);
    const selectAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data[0])
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        selectAdminInfo();
    }, [])




    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!adminInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(adminInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [adminInfo.mbNum, isConnected, stompClient]);




    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        select();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (data.length > displayLimit && displayLimit < 30) {
            setDisplayLimit(prevLimit => prevLimit + perPg);
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e);
        setperPg(newPerPage);
        // setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    const [popupVisible, setPopupVisible] = useState(false);

    const togglePopup = () => {
        setPopupVisible(!popupVisible);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region Alert

    const [alertShow, setAlertShow] = useState(false);
    const msgConfirmData1 = "확인 하시겠습니까?";
    const msgConfirmData2 = "결제처리가 완료됩니다.";
    const modalCancel = useCallback (() => {setAlertShow(false);})
    const alert = (()=> {setAlertShow(true)})

    //endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (id) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxChange = (e) => {
        let visibleData = data.slice(0, displayLimit);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setChecked(visibleIds);
        } else {
            setChecked([]);
        }
    };

    //endregion

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchData = data.filter((cData) => {
        if (cData.orderdtDTO && cData.orderdtDTO.memberDTO && cData.orderdtDTO.memberDTO.userName) {
            return (
                cData.orderdtDTO.memberDTO.userName.toLowerCase().includes(searchInput.toLowerCase())
            );
        }
        return false;
    });

    //endregion

    /** endregion */

    /** region Event */

    useEffect(() => {
        select();
    }, [])

    const select = () => {
        axios({
            method: 'get',
            url: '/order/selectPayWait',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUserNm(response.data[0].orderdtDTO.memberDTO.userName);
                setData(response.data)
                setChecked([]);
            })
            .catch((error) => {
                if (error?.response?.status === 400 || error?.response?.status === 401 || error?.response?.status === 402 || error?.response?.status === 403) {
                    window.location.href = '/Error401';
                } else if (error?.response?.status === 500) {
                    window.location.href = '/Error500';
                } else if (error?.response?.status === 404 || error?.response?.status === 405) {
                    window.location.href = '/Error404';
                }
            });
    };

    const [confirmPaySt, setConfirmPaySt] = useState(false);
    const confirmPayMsg1 = "입금확인을 진행하시겠습니까?";
    const confirmPayMsg2 = "진행 시 결제완료로 처리됩니다";

    const [cancelPaySt, setCancelPaySt] = useState(false);
    const cancelPayMsg1 = "입금취소를 진행하시겠습니까?";
    const cancelPayMsg2 = "진행 시 결제대기로 처리됩니다";

    const [selectedSeqNum, setSelectedSeqNum] = useState(null);

    const openConfirmPaySt = ((seqNum) => {
        setSelectedSeqNum(seqNum);
        setConfirmPaySt(true);
    })
    const closeConfirmPaySt = (() => {
        setSelectedSeqNum(null);
        setConfirmPaySt(false);
    })

    const openCancelPaySt = ((seqNum) => {
        setSelectedSeqNum(seqNum);
        setCancelPaySt(true);
    })
    const closeCancelPaySt = (() => {
        setSelectedSeqNum(null);
        setCancelPaySt(false);
    })

    const payDataSucc = (seqNum) => {
        axios({
            method: 'post',
            url: '/order/adminpayconfirm',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum,
            }
        })
            .then(async (response) => {
                const mbNum = response.data
                await stompClient.send("/app/updateOrderList", {}, JSON.stringify({userMbNum: mbNum}));
                closeConfirmPaySt()
                select();
            })
            .catch((error) => {
                console.log("입금확인 실패 : ", error)
            });
    };

    const payDataCancel = (seqNum) => {
        axios({
            method: 'post',
            url: '/order/adminpaycancel',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then(async (response) => {
                const mbNum = response.data;
                await stompClient.send("/app/updateOrderList", {}, JSON.stringify({userMbNum: mbNum}));
                closeCancelPaySt();
                select();
            })
            .catch((error) => {
                console.log("입금취소 실패 : ", error)
            });
    };

    const [integPayDetailPopupSt, setIntegPayDetailPopupSt] = useState(false);
    const [integPaySeqnum, setIntegPaySeqnum] = useState(null);
    const integPayDetail = ((seqnum) => {
        setIntegPaySeqnum(seqnum);
        setIntegPayDetailPopupSt(true)
    })
    const integDetailPopupClose = (() => {
        setIntegPayDetailPopupSt(false);
    })
    useEffect(() => {
        if (integPaySeqnum !== null) {
            setIntegPayDetailPopupSt(true);
        }
    }, [integPaySeqnum]);

    /** endregion */

    return (
        <div id="m-a-payWait-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">입금대기</p>
            </div>
            <div className="m-a-card display-flex flex-column">
                <div className="m-a-event-frame">
                    <div className="m-a-header-event">
                        <div className="m-a-header-event-combo">
                            <div className="m-a-combo font-t-cont font-c-default wd-55 ht-40 font-s-14 m-a-flex-jc-start m-r-10" onClick={togglePopup}>
                                <div className="font-t-cont">{perPg}</div>
                            </div>
                            {popupVisible && (
                                <MAdminPageSmallEvent perPg={perPg} handleSelectChange={handleSelectChange} togglePopup={togglePopup} />
                            )}
                        </div>
                    </div>
                    <div className="m-a-header-event">
                        <div className="m-a-header-event-search">
                            <MAdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                        </div>
                    </div>
                </div>
                <div className="m-a-tb-frame">
                    <div className="m-a-div-tb">
                        <div className="m-a-div-tb-head ht-60">
                            <div className="wd-40">
                                <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === data.length}
                                       onChange={allcheckBoxChange}/>
                            </div>
                            <div className="wd-40"><p>번호</p></div>
                            <div className="wd-150"><p>이름</p></div>
                            <div className="wd-200"><p>주문번호</p></div>
                            <div className="wd-100"><p>결제타입</p></div>
                            <div className="wd-100"><p>결제수단</p></div>
                            <div className="wd-140"><p>총금액(&#8361;)</p></div>
                            <div className="wd-140"><p>최종금액(&#8361;)</p></div>
                            <div className="wd-80"><p>예치금사용</p></div>
                            <div className="wd-80"><p>적립금사용</p></div>
                            <div className="wd-80"><p>쿠폰사용</p></div>
                            <div className="wd-80"><p>결제상태</p></div>
                            <div className="wd-140"><p>결제일시</p></div>
                            <div className="wd-60">승인</div>
                            <div className="wd-60">취소</div>
                        </div>
                        {searchData.slice(0, displayLimit).map((cData, i) => (
                            <div key={i} className="m-a-div-tb-body ht-70">
                                <div className="wd-40">
                                    <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" name={cData.seqNum} checked={checked.includes(cData.seqNum)}
                                           onChange={() => checkBoxChange(cData.seqNum)}/>
                                </div>
                                <div className="wd-40"><p className="font-b">{i + 1}</p></div>
                                <div className="wd-150"><p>{cData.orderdtDTO.memberDTO.userName}</p></div>
                                <div className="wd-200"><p>{cData.orderdtDTO.orderNumber}</p></div>
                                <div className="user-center wd-100">
                                    <p>{cData.payType === "PT0" ? "상품" : cData.payType === "PT1" ? "배송료" : cData.payType === "PT2" ? "기타" : "통합"}</p>
                                    { cData.payType === "PT3" ?
                                        <button onClick={()=> integPayDetail(cData.seqNum)} className="user-center m-a-commonBt wd-40 ht-25">
                                            <p className="font-c-default">내역</p>
                                        </button>
                                        :
                                        null
                                    }
                                </div>
                                <div className="wd-100">
                                    <p>{cData.payWay  === "PM00" ? "무통장입금" : cData.payWay === "PM01" ? "Paypal" : cData.payWay === "PM02" ? "Wechat" :
                                        cData.payWay === "PM03" ? "Alipay" : cData.payWay === "PM04" ? "예치금결제" : "적립금결제"}</p>
                                </div>
                                <div className="wd-140">
                                    { cData.payType === "PT0" ?
                                        <p>{cData.ttProdExpense.toLocaleString()}</p>
                                        :
                                        cData.payType === "PT1" ?
                                            <p>{cData.ttShipExpense.toLocaleString()}</p>
                                            :
                                            <p>{cData.lastPrice.toLocaleString()}</p>
                                    }
                                </div>
                                <div className="user-center wd-140" style={{display:"flex", alignItems:"center"}}>
                                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                        <p>{cData.lastPrice.toLocaleString()}</p>
                                        {cData.lastForPrice > 0 ?
                                            <div className="user-center m-l-3" style={{flexDirection:"row"}}>
                                                <p style={{margin:"0", fontSize:"11px", marginLeft:"3px"}}>-</p>
                                                <p style={{margin:"0", fontSize:"13px", color:"darkorange", marginLeft:"3px"}}>{cData.lastForPrice}</p>
                                                <p style={{margin:"0", fontSize:"11px", marginLeft:"3px"}}>{cData.forCurrency}</p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                <div className="wd-80"><p>{cData.depUsePrice}</p></div>
                                <div className="wd-80"><p>{cData.pointUsePrice}</p></div>
                                <div className="wd-80"><p>{cData.couponUsePrice}</p></div>
                                <div className="wd-80">
                                    <p style={{
                                        color: cData.payStatus === 'PS1' ? "#276AB7" : cData.payStatus === 'PS5' ? "#EE5757" : "#FFAF34"
                                    }}>
                                        {cData.payStatus === 'PS1' ? "결제중" : cData.payStatus === 'PS5' ? "취소" : "결제완료"}
                                    </p>
                                </div>
                                <div className="wd-140"><p>{cData.regTime}</p></div>
                                <div className="user-center wd-60">
                                    {cData.payStatus === 'PS1' && (
                                        <img className="c-point wd-30 ht-30 op m-a-opc-06" alt="closeImage" src={commonCheck}
                                             onClick={() => openConfirmPaySt(cData.seqNum)}/>)}
                                </div>
                                <div className="user-center wd-60">
                                    {(cData.payStatus === 'PS1' || cData.payStatus === 'PS2' ) && (
                                        <img className="c-point wd-30 ht-30 m-a-opc-07" alt="closeImage" src={commonClose}
                                             onClick={() => openCancelPaySt(cData.seqNum)}/>)}
                                </div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l-3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            { confirmPaySt ?
                <MsgSelectModal confirmPaySt={confirmPaySt} confirmPayMsg1={confirmPayMsg1} confirmPayMsg2={confirmPayMsg2}
                                closeConfirmPaySt={closeConfirmPaySt} selectedSeqNum={selectedSeqNum} payDataSucc={payDataSucc}/>
                :
                cancelPaySt ?
                    <MsgSelectModal cancelPaySt={cancelPaySt} cancelPayMsg1={cancelPayMsg1} cancelPayMsg2={cancelPayMsg2}
                                    closeCancelPaySt={closeCancelPaySt} selectedSeqNum={selectedSeqNum} payDataCancel={payDataCancel}/>
                    :
                    integPayDetailPopupSt ?
                        <IntegDetailPopup integDetailPopupClose={integDetailPopupClose} integPaySeqnum={integPaySeqnum}/>
                        :
                        null
            }
        </div>
    );
}

export default MAdminPayWaitPg
