import React, {useEffect, useState} from "react";
import {AiOutlineClose, AiOutlineDoubleRight} from "react-icons/ai";
import axios from "axios";
import {useTranslation} from "react-i18next";
import commonClose from "../../../assets/img/commonClose.png";

function EtcPriceDetail({closeDetail, paySeqnum}) {
    const { t } = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [payList, setPayList] = useState([]);

    const selectedEtcPayinfo = (() => {
        axios({
            method: 'get',
            url: '/order/selectedEtcPayinfo/',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                seqNum: paySeqnum
            },
        })
            .then((response) => {
                setPayList(response.data)
            })
    })


    useEffect(() => {
        const timer = setTimeout(() => {
            selectedEtcPayinfo();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])


    return (
        <div className="bookContainer font-t-cont">
            <div className="price-pop-center">
                <div className="display-block ht-100pc">
                    <div className="ht-50 c-def border-bottom-bt">
                        <div className="ht-100pc display-flex flex-row">
                            <div className="wd-92pc text-center p-l-45 align-center align-center-c">
                                <p className="font-s-18 m-l-10">{t(`epdHeader1`)}</p> {/*기타상세*/}
                            </div>
                            <div className="wd-8pc text-center align-center align-center-c" onClick={closeDetail}>
                                <img className="wd-30 ht-30 m-a-opc-07 c-point" alt="" src={commonClose}/>
                            </div>
                        </div>
                    </div>
                    <div className="ht-650 border-bt-none pop-scroll pd40" style={{overflowY: "auto"}}>
                        <div style={{
                            border: "solid",
                            borderWidth: "thin 1px",
                            borderColor: "lightgray",
                            borderRadius: "6px"
                        }}>
                            <div style={{
                                borderBottom: "solid",
                                borderWidth: "thin 1px",
                                borderColor: "lightgray",
                                width: "100%",
                                height: "40px",
                                display: "flex",
                                backgroundColor: "rgba(198,193,193,0.22)"
                            }}>
                                <span style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 4px 0 10px",
                                    fontSize: "14px"
                                }}><AiOutlineDoubleRight/></span>
                                <span style={{
                                    height: "100%",
                                    fontSize: "13px",
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "bold"
                                }}>{t(`epdHeader2`)}</span>
                            </div>
                            <div style={{margin: "10px", color: "dimgrey"}}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    marginTop: "10px",
                                    fontSize: "13px",
                                    marginBottom: "6px"
                                }}><span>{t(`epdHeader3`)}</span><span>{payList?.courierPay?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "13px",
                                    marginBottom: "6px"
                                }}><span>{t(`epdHeader4`)}</span><span>{payList?.returnFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "13px",
                                    borderBottom:"solid",
                                    borderWidth:"thin 1px",
                                    borderColor:"#EEEEEE",
                                    height:"35px"
                                }}>
                                    <span>{t(`epdHeader5`)}({payList?.etcPriceNm || ''})</span><span>{payList?.etcPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "13px",
                                    margin: "15px 0 6px 0"
                                }}><span>{t(`epdHeader6`)}</span><span>{payList?.repackFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "13px",
                                    marginBottom: "6px"
                                }}><span>{t(`epdHeader7`)}</span><span>{payList?.comprFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "13px",
                                    marginBottom: "6px"
                                }}><span>{t(`epdHeader8`)}</span><span>{payList?.agencyFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    wordBreak: "break-all",
                                    fontSize: "13px",
                                    marginBottom: "6px"
                                }}>
                                    <span>{t(`epdHeader9`)}({payList?.etcFeeNm || ''})</span><span>{payList?.etcFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>
                            </div>
                        </div>
                        <div className="m-t-40" style={{color: "dimgrey"}}>
                            <div style={{
                                height: "40px",
                                borderBottom: "solid",
                                borderWidth: "thin 1px",
                                borderColor: "lightgray",
                                display: "flex",
                                marginBottom: "5px"
                            }}><p style={{fontSize: "16px", height: "100%", paddingTop: "8px"}}>{t(`modHeader10`)}</p></div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                wordBreak: "break-all",
                                padding: "5px",
                                fontSize: "13px"
                            }}><span>{t(`modSHeader9`)}</span>
                                <span>{(payList?.ttEtcFee + payList?.ttEtcPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                wordBreak: "break-all",
                                padding: "5px",
                                fontSize: "13px",
                                marginTop: "6px"
                            }}><span>{t(`myUseDep`)}</span><span>{payList?.depUsePrice !== 0 ?
                                <span>- {payList?.depUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : 0}</span></div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                wordBreak: "break-all",
                                padding: "5px",
                                fontSize: "13px"
                            }}><span>{t(`myUsePt`)}</span><span>{payList?.pointUsePrice !== 0 ?
                                <span>- {payList?.pointUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : 0}</span></div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                wordBreak: "break-all",
                                padding: "5px",
                                fontSize: "13px"
                            }}><span>{t(`modSHeader13`)}</span><span>{payList?.couponUsePrice !== 0 ?
                                <span>- {payList?.couponUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : 0}</span>
                            </div>
                            <div style={{
                                height: "50px",
                                borderBottom: "solid",
                                borderWidth: "thin 1px",
                                borderColor: "lightgray",
                                display: "flex",
                                alignItems: "end",
                                justifyContent: "space-between",
                                wordBreak: "break-all",
                                padding: "5px",
                                marginBottom: "50px"
                            }}><span style={{fontSize: "16px", fontWeight: "bold"}}>{t(`modFinalPay`)}</span>
                                <span style={{
                                color: "#FFAF34",
                                fontWeight: "bold"
                            }}>&#8361; {payList?.payStatus === "PS0" ? 0 : payList?.lastPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EtcPriceDetail
