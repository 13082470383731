import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import axios from "axios";
import {connect} from "../../services/WebsocketService";
import excel from "exceljs";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";
import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";
import {MdKeyboardArrowDown} from "react-icons/md";
import SaveMsgModal from "../../components/web/modal/SaveMsgModal";
import maCateImg from "../../assets/img/m-a-cate.png";
import MAdminPageSmallEvent from "./MAdminPageSmallEvent";
import MAdminSearchEvent from "./MAdminSearchEvent";
import MAdminYmdSmallEvent from "./MAdminYmdSmallEvent";
import {useWebSocket} from "../../services/WebsocketContext";

function MAdminCancelItemPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');

    const [userNm, setUserNm] = useState('');
    const [adminInfo, setAdminInfo] = useState([]);
    const selectAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data[0])
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        selectAdminInfo();
    }, [])

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!adminInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(adminInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [adminInfo.mbNum, isConnected, stompClient]);



    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        select();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (data.length > displayLimit && displayLimit < 30) {
            setDisplayLimit(prevLimit => prevLimit + perPg);
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e);
        setperPg(newPerPage);
        setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    const [popupVisible, setPopupVisible] = useState(false);

    const togglePopup = () => {
        setPopupVisible(!popupVisible);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region Alert

    const [alertShow, setAlertShow] = useState(false);
    const msgConfirmData1 = "제외처리 하시겠습니까?";
    const msgConfirmData2 = "제외된 데이터는 복구가 불가능합니다.";
    const modalCancel = useCallback (() => {setAlertShow(false);})
    const alert = (()=> {setAlertShow(true)})

    //endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (id) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxChange = (e) => {
        let visibleData = data.slice(0, displayLimit);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setChecked(visibleIds);
        } else {
            setChecked([]);
        }
    };

    //endregion

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchData = data.filter((cData) => {
        return cData.regNm.toLowerCase().includes(searchInput.toLowerCase());
    });

    //endregion

    //region DateSelect

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {setStartDate(e.target.value);};
    const endHandle = (e) => {setEndDate(e.target.value);};

    const currentDate = new Date();
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(currentDate.getDate() - 30);

    const defaultMonth = () => {
        const stringStartDate = formatDate(twoWeeksAgo);
        const stringEndDate = formatDate(currentDate);
        setStartDate(stringStartDate);
        setEndDate(stringEndDate);
    }

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    //endregion DateSelect

    /** endregion */

    /** region Event */

    useEffect(() => {
        select();
        defaultMonth();
    }, [endDate])

    const select = (() => {
        axios({
            method: 'post',
            url: '/order/selectConditionProdList',
            params: {
                prodSt: "IS12"
            },
        })
            .then((response) => {
                setData(response.data)
            })
            .catch(() => {})
    })

    const [adminProdMemos, setAdminProdMemos] = useState({});
    const handleProdMemoChange = (seqnum, value) => {
        setAdminProdMemos(prevMemos => ({
            ...prevMemos,
            [seqnum]: value
        }));
    };

    const [saveMsgSt, setSaveMsgSt] = useState(false);
    const [savePopupMsg, setSavePopupMsg] = useState('');

    const modifyAdminProdMemo = ((seqnum, memo) => {
        axios({
            method: 'post',
            url: '/order/modifyAdminProdMemo',
            headers: {
                Authorization: LCAT
            },
            data: {
                seqNum: seqnum,
                memo: memo
            }
        })
            .then((response) => {
                setSavePopupMsg("저장 완료");
                setSaveMsgSt(true);
                setTimeout(() => setSaveMsgSt(false), 1000);
            })
            .catch((error) => {
                setSavePopupMsg("저장 실패");
                setSaveMsgSt(true);
                setTimeout(() => setSaveMsgSt(false), 1000);
            })
    })

    const saveProdMemo = (e, seqnum) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            const memo = adminProdMemos[seqnum] || '';
            modifyAdminProdMemo(seqnum, memo);
        }
    };

    useEffect(() => {
        const initialMemos = {};
        searchData.forEach(prod => {
            initialMemos[prod.seqnum] = prod.adminMemo;
        });
        // 상품메모 초기설정
        setAdminProdMemos(initialMemos);
    }, [data]);

    /** endregion */

    return (
        <div id="m-a-depositWait-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">취소상품관리</p>
            </div>
            <div className="m-a-card display-flex flex-column">
                <div className="m-a-event-frame">
                    <div className="m-a-header-event display-flex flex-row just-cont-sb">
                        <div className="m-a-header-event-combo wd-80pc">
                            <div className="m-a-combo font-t-cont font-c-default wd-21pc ht-40 font-s-13 m-a-flex-jc-start" onClick={togglePopup}>
                                <div className="font-t-cont">{perPg}</div>
                            </div>
                            {popupVisible && (
                                <MAdminPageSmallEvent perPg={perPg} handleSelectChange={handleSelectChange} togglePopup={togglePopup} />
                            )}
                            <MAdminYmdSmallEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                        </div>
                        <div className="m-a-header-event-btn font-t-cont font-s-13 wd-20pc">
                            <button className="m-a-colorBt m-a-colorBt-text wd-100pc ht-40" onClick={alert}>취소</button>
                        </div>
                    </div>
                    <div className="m-a-header-event">
                        <div className="m-a-header-event-search">
                            <MAdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                        </div>
                    </div>
                </div>
                <div className="m-a-tb-frame">
                    <div className="m-a-div-tb">
                        <div className="m-a-div-tb-head ht-60">
                            <div className="wd-40">
                                <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === data.length}
                                       onChange={allcheckBoxChange}/>
                            </div>
                            <div className="wd-40"><p>번호</p></div>
                            <div className="wd-150"><p>고객명</p></div>
                            <div className="wd-150"><p>구매일시</p></div>
                            <div className="wd-230"><p>상품명</p></div>
                            <div className="wd-230"><p>URL</p></div>
                            <div className="wd-100"><p>상품금액(&#8361;)</p></div>
                            <div className="wd-100"><p>배송료(&#8361;)</p></div>
                            <div className="wd-50"><p>수량</p></div>
                            <div className="wd-280"><p>상품메모</p></div>
                        </div>
                        {searchData.slice(0, displayLimit).map((cData, i) => (
                            <div key={i} className="m-a-div-tb-body ht-70">
                                <div className="wd-40">
                                    <input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.seqNum} checked={checked.includes(cData.seqNum)}
                                           onChange={() => checkBoxChange(cData.seqNum)}/>
                                </div>
                                <div className="wd-40"><p>{i + 1}</p></div>
                                <div className="wd-150"><p>{cData.regNm}</p></div>
                                <div className="wd-150"><p>{cData.modiTime}</p></div>
                                <div className="wd-230"><p>{cData.name}</p></div>
                                <div className="wd-230"><p>{cData.url}</p></div>
                                <div className="wd-100"><p>{cData.price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                <div className="wd-100"><p>{cData.mallShipPrice.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                <div className="wd-50"><p>{cData.quantity}</p></div>
                                <div className="wd-280">
                                    <textarea
                                        className="admin-input-line prod-textarea-memo"
                                        cols="50"
                                        rows="3"
                                        value={adminProdMemos[cData.seqnum] || ''}
                                        onChange={(e) => handleProdMemoChange(cData.seqnum, e.target.value)}
                                        onKeyDown={(e) => saveProdMemo(e, cData.seqnum)}
                                    ></textarea>
                                </div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {
                saveMsgSt ?
                    <SaveMsgModal message={savePopupMsg} />
                    :
                    null
            }
        </div>
    );
}

export default MAdminCancelItemPg
