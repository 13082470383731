import "../../css/mobile/MUserNoticePg.css";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import MHeader from "../../components/mobile/MHeader";
import notice from "../../assets/img/notice.png";

const MUserNoticePg = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();
    const moveToDetail = ((seqNum) => {
        navigate(`/UserNoticeDtPg/${seqNum}`)
    })

    const [noticeList, setNoticeList] = useState([]);
    const selectAllNoticeList = () => axios({
        method: 'get',
        url: '/notice/selectAllNoticeList/',
    })
        .then((response) => {
            setNoticeList(response.data);
        })

    useEffect(() => {
        selectAllNoticeList();
    }, [])

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }


    return (
        <div className={'m-a-user-notice-pg-container'}>
            <MHeader title={t(`NtHeader`)} url={"/"}/>
            <div className={'m-a-user-notice-pg-header'}>
                <div className={'m-a-user-notice-pg-header-left'}>
                    <img className={'m-a-user-notice-pg-header-icon'} src={notice} alt={''}/>
                    {t(`NtHeader`)}
                </div>
            </div>
            <div className={'m-a-user-notice-pg-divider'}/>
            <div className={'m-a-user-notice-pg-table'}>
                <div className={'m-a-user-notice-pg-table-row label'}>
                    <div className={'m-a-user-notice-pg-table-row-item label'}>{t(`fSmallMenu1`)}</div>
                    <div className={'m-a-user-notice-pg-table-row-item label lg'}>{t(`fSmallMenu3`)}</div>
                    <div className={'m-a-user-notice-pg-table-row-item label'}>{t(`fSmallMenu4`)}</div>
                    <div className={'m-a-user-notice-pg-table-row-item label'}>{t(`fSmallMenu5`)}</div>
                </div>
                {noticeList.filter(data => data.fixFl === true).map((notice, i) => (
                    <div className={'m-a-user-notice-pg-table-row label'}
                         key={i} onClick={() => moveToDetail(notice.seqNum)}>
                        <div className={'m-a-user-notice-pg-table-row-item fixed'}>{t(`fdFix`)}</div>
                        <div className={'m-a-user-notice-pg-table-row-item lg admin-tb-text-cut'}>
                            {notice?.title}
                        </div>
                        <div className={'m-a-user-notice-pg-table-row-item'}>
                            {notice?.regNm}
                        </div>
                        <div className={'m-a-user-notice-pg-table-row-item'}>
                            {formatDate(notice?.regDate)}
                        </div>
                    </div>
                ))}
                {noticeList.filter(data => data.fixFl === false).map((notice, i) => (
                    <div className={'m-a-user-notice-pg-table-row label'}
                         key={i} onClick={() => moveToDetail(notice.seqNum)}>
                        <div className={'m-a-user-notice-pg-table-row-item'}>{i + 1}</div>
                        <div className={'m-a-user-notice-pg-table-row-item lg admin-tb-text-cut'}>
                            {notice?.title}
                        </div>
                        <div className={'m-a-user-notice-pg-table-row-item'}>
                            {notice?.regNm}
                        </div>
                        <div className={'m-a-user-notice-pg-table-row-item'}>
                            {formatDate(notice?.regDate)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )

}

export default MUserNoticePg;
