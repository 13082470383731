import '../../css/mobile/MDepChargPg.css';
import MHeader from "../../components/mobile/MHeader";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {connect} from "../../services/WebsocketService";
import commonHeader from "../../assets/img/commonHeader.png";
import mainNotice from "../../assets/img/mainNotice.png";
import PaypalModal from "../../components/web/modal/PaypalModal";
import {useWebSocket} from "../../services/WebsocketContext";
import i18n from "../../i18n";
import withAuth from "../../auth/UserRouteAuth";


const MDepChargPg = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [memberInfo, setMemberInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                setMemberInfo(response.data)
            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (SSAT || LCAT) {
            findMember();
        }
    }, [SSAT, LCAT])




    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!memberInfo.mbNum || isSubscribedRef.current) return;
            if (!stompClient && !isConnected) {
                await websocketConnect(memberInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [memberInfo.mbNum, isConnected, stompClient]);










    const [submitBtDisabled, setSubmitBtDisabled] = useState(false);

    const [paypalTab, setPaypalTab] = useState(true);
    const [nobankTab, setNobankTab] = useState(false);
    const [yuanTab, setYuanTab] = useState(false);

    const [nobankChargTab, setNobankChargTab] = useState(true);
    const [nobankRefundTab, setNobankRefundTab] = useState(false);
    const [paypalChargTab, setPaypalChargTab] = useState(true);
    const [paypalRefundTab, setPaypalRefundTab] = useState(false);
    const [yuanChargTab, setYuanChargTab] = useState(true);
    const [yuanRefundTab, setYuanRefundTab] = useState(false);

    const handleMainTab = ((tab) => {
        if (tab === "nobank") {
            setNobankTab(true);
            setPaypalTab(false);
            setYuanTab(false);
        } else if (tab === "paypal") {
            setSelectedDollar("USD")
            setNobankTab(false);
            setPaypalTab(true);
            setYuanTab(false);
        } else if (tab === 'yuan') {
            setSelectedDollar("CNY")
            setNobankTab(false);
            setPaypalTab(false);
            setYuanTab(true);
        }
    })

    const handleNobankAssiTab = ((tab) => {
        if (tab === "charg") {
            setNobankChargTab(true);
            setNobankRefundTab(false);
        } else {
            setNobankChargTab(false);
            setNobankRefundTab(true);
        }
    })

    const handlePaypalAssiTab = ((tab) => {
        if (tab === "charg") {
            setPaypalChargTab(true);
            setPaypalRefundTab(false);
        } else {
            setPaypalChargTab(false);
            setPaypalRefundTab(true);
        }
    })

    const handleYuanAssiTab = ((tab) => {
        if (tab === "charg") {
            setYuanChargTab(true);
            setYuanRefundTab(false);
        } else {
            setYuanChargTab(false);
            setYuanRefundTab(true);
        }
    })

    const btorageAccount = t(`dcMdMsg`);

    // 페이팔통화 셀렉트박스
    const dollarList = ['USD', 'CAD', 'AUD', 'NZD', 'EUR', 'JPY', 'SGD']
    const [selectedDollar, setSelectedDollar] = useState('USD');


    const [dollarShow, setDollarShow] = useState(false);
    const dollarRef = useRef(null);

    const toggleDollarShow = () => {
        setDollarShow(!dollarShow);
    };

    const selectChangeDollar = (e) => {
        setSelectedDollar(e);
        setDollarShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (dollarRef.current && !dollarRef.current.contains(event.target)) {
                setDollarShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getTodayFormatted = () => {
        let date = new Date();
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');
        setNobankChargSendDate(`${year}${month}${day}`);
        setYuanChargSendDate(`${year}${month}${day}`);
    };

    useEffect(() => {
        getTodayFormatted();
    }, []);

    const formatStringWithCommas = ((numberString) => {
        const numericString = numberString.replace(/[^\d]/g, '');
        return numericString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    })

    // 무통장충전
    const [nobankChargPrice, setNobankChargPrice] = useState('');
    const [nobankChargSendNm, setNobankChargSendNm] = useState('');
    const [nobankChargSendDate, setNobankChargSendDate] = useState('');

    const handleNobankChargPrice = ((e) => {
        const {value} = e.target;
        const formattedValue = formatStringWithCommas(value);
        setNobankChargPrice(formattedValue);
    })
    const handleNobankChargSendNm = ((e) => {
        setNobankChargSendNm(e.target.value);
    })
    const handleNobankChargSendDate = ((e) => {
        setNobankChargSendDate(e.target.value);
    })

    const saveNobankCharg = (() => {
        if (submitBtDisabled) return;
        try {
            axios({
                method: 'post',
                url: '/member/saveDepConfirmInfo',
                data: {
                    userNm: memberInfo.userName,
                    userId: memberInfo.id,
                    name: nobankChargSendNm,
                    account: btorageAccount,
                    price: Number(nobankChargPrice.replace(/,/g, "")),
                    chargFl: true,
                    sendDate: nobankChargSendDate,
                    status: '0',
                    autoPayFl: false
                },
            })
                .then(async (response) => {
                    saveDepositAlarmData(response.data);
                    navigate("/UserDepChargHistoryPg")
                })
                .catch(() => {
                    console.log("무통장충전 실패")
                    setSubmitBtDisabled(false);
                })
        } catch (error) {
            setSubmitBtDisabled(false);
        } finally {
            setSubmitBtDisabled(false);
        }
    })

    const nobankChargInit = (() => {
        setNobankChargPrice('');
        setNobankChargSendNm('');
        setNobankChargSendDate('');
    })


    // 무통장환급
    const [nobankRefundPrice, setNobankRefundPrice] = useState('');
    const [nobankRefundNm, setNobankRefundNm] = useState('');
    const [nobankRefundBankNm, setNobankRefundBankNm] = useState('');
    const [nobankRefundAccount, setNobankRefundAccount] = useState('');

    const handleNobankRefundPrice = ((e) => {
        const {value} = e.target;
        const formattedValue = formatStringWithCommas(value);
        setNobankRefundPrice(formattedValue);
    })
    const handleNobankRefundNm = ((e) => {
        setNobankRefundNm(e.target.value);
    })
    const handleNobankRefundBankNm = ((e) => {
        setNobankRefundBankNm(e.target.value);
    })
    const handleNobankRefundAccount = ((e) => {
        setNobankRefundAccount(e.target.value);
    })

    const saveNobankRefund = (() => {
        if (submitBtDisabled) return;
        try {
            axios({
                method: 'post',
                url: '/member/saveDepConfirmInfo',
                data: {
                    userNm: memberInfo.userName,
                    userId: memberInfo.id,
                    name: nobankRefundNm,
                    account: nobankRefundBankNm + nobankRefundAccount,
                    price: Number(nobankRefundPrice.replace(/,/g, "")),
                    chargFl: false,
                    status: '0',
                    autoPayFl: false
                },
            })
                .then(async (response) => {
                    saveDepositAlarmData(response.data);
                    navigate("/UserDepChargHistoryPg")
                })
                .catch(() => {
                    console.log(" 실패")
                    setSubmitBtDisabled(false);
                })
        } catch (error) {
            setSubmitBtDisabled(false);
        } finally {
            setSubmitBtDisabled(false);
        }
    })

    const nobankRefundInit = (() => {
        setNobankRefundPrice('');
        handleNobankRefundNm('');
        handleNobankRefundBankNm('');
        handleNobankRefundAccount('');
    })

    const formatNumber = (value) => {
        // 숫자 외의 모든 문자를 제거
        const number = value.replace(/[^\d]/g, "");
        // 세자리 수마다 콤마를 찍어서 반환
        return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // 페이팔충전
    const [paypalChargPrice, setPaypalChargPrice] = useState('');

    const handlePaypalChargPrice = ((e) => {
        const formattedValue = formatNumber(e.target.value);
        setPaypalChargPrice(formattedValue);
    })

    const openPaypalPopup = (() => {
        setPaypalPopupSt(true);
    })

    const savePaypalCharg = (() => {
            axios({
                method: 'post',
                url: '/member/saveDepConfirmInfo',
                data: {
                    userNm: memberInfo.userName,
                    userId: memberInfo.id,
                    name: memberInfo.userName,
                    account: 'Paypal Btorage',
                    price: Number(paypalChargPrice.replace(/,/g, "")),
                    chargFl: true,
                    sendDate: nobankChargSendDate,
                    status: '1', // API response.ok면 '1' 그대로 완료
                    currency: selectedDollar,
                    dollarPrice: paypalChargExPrice,
                    autoPayFl: true
                },
            })
                .then(async (response) => {
                    setPaypalPopupSt(false);
                    saveDepositAlarmData(response.data);
                    navigate("/UserDepChargHistoryPg")
                })
                .catch((error) => {
                    console.log("페이팔충전 실패 : ", error)
                    setSubmitBtDisabled(false);
                })
    })

    const paypalChargInit = (() => {
        setPaypalChargPrice('');
        setPaypalChargExPrice('');
        setPaypalChargExPrice(0);
        if (yuanTab) {
            setSelectedDollar("CNY")
        } else {
            setSelectedDollar("USD")
        }
    })


    // 페이팔환급
    const [paypalRefundPrice, setPaypalRefundPrice] = useState('');
    const [paypalRefundEmail, setPaypalRefundEmail] = useState('');

    const handlePaypalRefundPrice = ((e) => {
        const formattedValue = formatNumber(e.target.value);
        setPaypalRefundPrice(formattedValue);
    })
    const handlePaypalRefundEmail = ((e) => {
        setPaypalRefundEmail(e.target.value);
    })

    const savePaypalRefund = (() => {
        if (submitBtDisabled) return;
        try {
            axios({
                method: 'post',
                url: '/member/saveDepConfirmInfo',
                data: {
                    userNm: memberInfo.userName,
                    userId: memberInfo.id,
                    name: '',
                    account: paypalRefundEmail,
                    price: Number(paypalRefundPrice.replace(/,/g, "")),
                    chargFl: false,
                    sendDate: '',
                    status: '0',
                    currency: selectedDollar,
                    dollarPrice: paypalRefundExPrice,
                    autoPayFl: false
                },
            })
                .then(async (response) => {
                    saveDepositAlarmData(response.data);
                    navigate("/UserDepChargHistoryPg")
                })
                .catch(() => {
                    console.log(" 실패")
                    setSubmitBtDisabled(false);
                })
        } catch (error) {
            setSubmitBtDisabled(false);
        } finally {
            setSubmitBtDisabled(false);
        }
    })

    const paypalRefundInit = (() => {
        setPaypalRefundPrice('');
        setPaypalRefundExPrice('');
        setPaypalRefundEmail('');
    })


    // 위안충전
    const [yuanChargPrice, setYuanChargPrice] = useState('');
    const [yuanChargSendId, setYuanChargSendId] = useState('');
    const [yuanChargSendDate, setYuanChargSendDate] = useState('');

    const handleYuanChargPrice = ((e) => {
        const formattedValue = formatNumber(e.target.value);
        setYuanChargPrice(formattedValue);
    })
    const handleYuanChargSendId = ((e) => {
        setYuanChargSendId(e.target.value);
    })
    const handleYuanChargSendDate = ((e) => {
        setYuanChargSendDate(e.target.value);
    })

    const saveYuanCharg = (() => {
        if (submitBtDisabled) return;
        try {
            axios({
                method: 'post',
                url: '/member/saveDepConfirmInfo',
                data: {
                    userNm: memberInfo.userName,
                    userId: memberInfo.id,
                    name: yuanChargSendId,
                    account: btorageAccount,
                    price: Number(yuanChargPrice.replace(/,/g, "")),
                    chargFl: true,
                    sendDate: yuanChargSendDate,
                    status: '0',
                    currency: "CNY",
                    dollarPrice: yuanChargExPrice,
                    autoPayFl: false
                },
            })
                .then(async (response) => {
                    saveDepositAlarmData(response.data);
                    navigate("/UserDepChargHistoryPg")
                })
                .catch(() => {
                    console.log(" 실패")
                    setSubmitBtDisabled(false);
                })
        } catch (error) {
            setSubmitBtDisabled(false);
        } finally {
            setSubmitBtDisabled(false);
        }
    })

    const yuanChargInit = (() => {
        setYuanChargPrice('');
        setYuanChargSendId('');
        setYuanChargSendDate('');
    })


    // 위안환급
    const [yuanRefundPrice, setYuanRefundPrice] = useState('');
    const [yuanRefundId, setYuanRefundId] = useState('');

    const handleYuanRefundPrice = ((e) => {
        const formattedValue = formatNumber(e.target.value);
        setYuanRefundPrice(formattedValue);
    })
    const handleYuanRefundId = ((e) => {
        setYuanRefundId(e.target.value);
    })

    const saveYuanRefund = (() => {
        if (submitBtDisabled) return;
        try {
            axios({
                method: 'post',
                url: '/member/saveDepConfirmInfo',
                data: {
                    userNm: memberInfo.userName,
                    userId: memberInfo.id,
                    name: '',
                    account: yuanRefundId,
                    price: Number(yuanRefundPrice.replace(/,/g, "")),
                    chargFl: false,
                    sendDate: '',
                    status: '0',
                    currency: "CNY",
                    dollarPrice: yuanRefundExPrice,
                    autoPayFl: false
                },
            })
                .then(async (response) => {
                    saveDepositAlarmData(response.data);
                    navigate("/UserDepChargHistoryPg")
                })
                .catch(() => {
                    console.log(" 실패")
                    setSubmitBtDisabled(false);
                })
        } catch (error) {
            setSubmitBtDisabled(false);
        } finally {
            setSubmitBtDisabled(false);
        }
    })

    const yuanRefundInit = (() => {
        setYuanRefundPrice('');
        setYuanRefundId('');
    })


    const saveDepositAlarmData = ((depositData) => {
        axios({
            method: 'post',
            url: '/order/saveDepositData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT,
                'Content-Type': 'application/json',
            },
            data: depositData
        })
            .then(async () => {
                await stompClient.send("/app/depConfirmAlarm", {}, JSON.stringify({sendFl: true}));
            })
            .catch((error) => {
                console.log("알림데이터 저장실패 : ", error)
            })
    })

    const [paypalPopupSt, setPaypalPopupSt] = useState(false);
    const paypalPopupClose = (() => {
        setPaypalPopupSt(false);
    })

    const [usdExrInfo, setUsdExrInfo] = useState([]);
    const [paypalChargExPrice, setPaypalChargExPrice] = useState(0);
    const [paypalRefundExPrice, setPaypalRefundExPrice] = useState(0);
    const [yuanChargExPrice, setYuanChargExPrice] = useState(0);
    const [yuanRefundExPrice, setYuanRefundExPrice] = useState(0);

    const selectedAllExrInfo = (() => {
        axios({
            method: 'get',
            url: '/paypal/selectedAllExrInfo/',
        })
            .then((response) => {
                setUsdExrInfo(response.data)
            })
            .catch(() => {
                console.log("환율조회실패")
            })
    })

    useEffect(() => {
        selectedAllExrInfo();
    }, [])


    const calculateLastForPrice = () => {
        if (selectedDollar && usdExrInfo.length > 0) {
            const usdExInfo = usdExrInfo.find(info => info.curPrice === selectedDollar);
            let numericFinalPayExpense;

            if (paypalTab && paypalChargTab) {
                numericFinalPayExpense = parseFloat(paypalChargPrice.replace(/,/g, ''));
            } else if (paypalTab && paypalRefundTab) {
                numericFinalPayExpense = parseFloat(paypalRefundPrice.replace(/,/g, ''));
            } else if (yuanTab && yuanChargTab) {
                numericFinalPayExpense = parseFloat(yuanChargPrice.replace(/,/g, ''));
            } else if (yuanTab && yuanRefundTab) {
                numericFinalPayExpense = parseFloat(yuanRefundPrice.replace(/,/g, ''));
            }

            // 금액(50,000) / (환율(1333.14) - 요율(9.1%)) = 41.26usd
            if (!isNaN(numericFinalPayExpense) && usdExInfo) {
                const payRateAsRatio = usdExInfo.payRate / 100;
                const newLastForPrice = numericFinalPayExpense / (usdExInfo.payExr - (usdExInfo.payExr * payRateAsRatio));

                if (paypalTab && paypalChargTab) {
                    setPaypalChargExPrice(Number(newLastForPrice.toFixed(2)));
                } else if (paypalTab && paypalRefundTab) {
                    setPaypalRefundExPrice(Number(newLastForPrice.toFixed(2)));
                } else if (yuanTab && yuanChargTab) {
                    setYuanChargExPrice(Number(newLastForPrice.toFixed(2)));
                } else if (yuanTab && yuanRefundTab) {
                    setYuanRefundExPrice(Number(newLastForPrice.toFixed(2)));
                }
            }
        }
    }

    useEffect(() => {
        calculateLastForPrice();
    }, [selectedDollar, usdExrInfo, paypalChargPrice, paypalRefundPrice, yuanChargPrice, yuanRefundPrice]);

    const currentLanguage = i18n.language || 'ko';
    const moveToPaypalFaq = (() => {
        let seqNum;
        switch (currentLanguage) {
            case 'en':
                seqNum = "53"
                break;
            case 'ja':
                seqNum = "54"
                break;
            case 'zh':
                seqNum = "55"
                break;
            case 'id':
                seqNum = "56"
                break;
            default:
                seqNum = "52"
        }
        navigate(`/UserFaqDtPg/${seqNum}`)
    })

    const moveToYuanFaq = (() => {
        let seqNum = '52'
        switch (currentLanguage) {
            case 'en':
                seqNum = "96"
                break;
            case 'ja':
                seqNum = "97"
                break;
            case 'zh':
                seqNum = "98"
                break;
            case 'id':
                seqNum = "99"
                break;
            default:
                seqNum = "95"
        }
        navigate(`/UserFaqDtPg/${seqNum}`)
    })


    return (
        <div className={'m-a-dep-charg-pg-container'}>
            <MHeader title={t(`dcHeader`)} url={"/"}/>
            <div className={'m-a-dep-charg-pg-top'}>
                <div className='m-a-dep-charg-sub-title'>
                    <img className='m-a-dep-charg-sub-title-icon' src={commonHeader} alt=''/>
                    <div className='m-a-dep-charg-sub-title-text'>{t(`dcHeader`)}</div>
                </div>
                <div className={'m-a-dep-charg-pg-top-right'}
                     onClick={() => navigate("/UserDepChargHistoryPg")}> {t('dcHistory')}
                </div>
            </div>
            <div className={'m-a-dep-charg-pg-divider'}/>
            <div className={'m-a-dep-charg-pg-frame'}>
                <div className={'m-a-dep-charg-pg-button-row'}>
                    <div className={`m-a-dep-charg-pg-button ${paypalTab && 'selected'}`}
                         onClick={() => handleMainTab('paypal')}> Paypal
                    </div>
                    <div className={`m-a-dep-charg-pg-button ${yuanTab && 'selected'}`}
                         onClick={() => handleMainTab('yuan')}> Wechat/Alipay
                    </div>
                </div>
                <div className={`m-a-dep-charg-pg-button ${nobankTab && 'selected'}`}
                     onClick={() => handleMainTab('nobank')}>{t(`dcBt1`)}
                </div>
            </div>
            <div className={'m-a-dep-charg-pg-bottom-frame'}>
                {nobankTab ?
                    <div className={'m-a-dep-charg-pg-binary-selector'}>
                        <div className={`m-a-dep-charg-pg-binary-selector-item ${nobankChargTab && 'selected'}`}
                             onClick={() => handleNobankAssiTab('charg')}>{t(`st8`)}
                        </div>
                        <div className={`m-a-dep-charg-pg-binary-selector-item ${nobankRefundTab && 'selected'}`}
                             onClick={() => handleNobankAssiTab('refund')}>{t(`st9`)}
                        </div>
                    </div>
                    : paypalTab ?
                        <div className={'m-a-dep-charg-pg-binary-selector'}>
                            <div className={`m-a-dep-charg-pg-binary-selector-item ${paypalChargTab && 'selected'}`}
                                 onClick={() => handlePaypalAssiTab('charg')}>{t(`st8`)}
                            </div>
                            <div className={`m-a-dep-charg-pg-binary-selector-item ${paypalRefundTab && 'selected'}`}
                                 onClick={() => handlePaypalAssiTab('refund')}>{t(`st9`)}
                            </div>
                        </div>
                        : yuanTab ?
                            <div className={'m-a-dep-charg-pg-binary-selector'}>
                                <div className={`m-a-dep-charg-pg-binary-selector-item ${yuanChargTab && 'selected'}`}
                                     onClick={() => handleYuanAssiTab('charg')}>{t(`st8`)}
                                </div>
                                <div className={`m-a-dep-charg-pg-binary-selector-item ${yuanRefundTab && 'selected'}`}
                                     onClick={() => handleYuanAssiTab('refund')}>{t(`st9`)}
                                </div>
                            </div> : null
                }
                {nobankTab && nobankChargTab ? (
                    <div className={'m-a-dep-charg-pg-table-frame'}>
                        <div className={'m-a-dep-charg-pg-table'}>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`myBal`)}</div>
                                <div className={'m-a-dep-charg-pg-table-item'}>
                                    {memberInfo && memberInfo?.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`dcDepPr`)}</div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={25} type="text"
                                       onChange={handleNobankChargPrice} value={nobankChargPrice}/>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>
                                    {t(`dcSd`)}
                                </div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={25} type="text"
                                       onChange={handleNobankChargSendNm} value={nobankChargSendNm}/>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>
                                    {t(`dcSdDate`)}
                                </div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={8} type="text"
                                       onChange={handleNobankChargSendDate} value={nobankChargSendDate}
                                />
                            </div>
                        </div>
                        <div className={'m-a-dep-charg-pg-buttons'}>
                            <div className={'m-a-dep-charg-pg-button-outline'}
                                 onClick={nobankChargInit}>{t(`dcInitBt`)}</div>
                            <div className={'m-a-dep-charg-pg-button-fill'}
                                 onClick={saveNobankCharg} style={{pointerEvents: submitBtDisabled ? 'none' : 'auto'}}>{t(`dcDoneBt`)}
                            </div>
                        </div>
                    </div>
                ) : nobankTab && nobankRefundTab ? (
                    <div className={'m-a-dep-charg-pg-table-frame'}>
                        <div className={'m-a-dep-charg-pg-table'}>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`myBal`)}</div>
                                <div className={'m-a-dep-charg-pg-table-item'}>
                                    {memberInfo && memberInfo?.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`dcRfPr`)}</div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={25} type="text"
                                       onChange={handleNobankRefundPrice} value={nobankRefundPrice}/>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>
                                    {t(`modNbInfo3`)}
                                </div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={25} type="text"
                                       onChange={handleNobankRefundNm} value={nobankRefundNm}/>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`dcBkNm`)}</div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={25} type="text"
                                       onChange={handleNobankRefundBankNm} value={nobankRefundBankNm}/>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`dcAcctNum`)}</div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={25} type="text"
                                       onChange={handleNobankRefundAccount} value={nobankRefundAccount}/>
                            </div>
                        </div>
                        <div className={'m-a-dep-charg-pg-buttons'}>
                            <div className={'m-a-dep-charg-pg-button-outline'}
                                 onClick={nobankRefundInit}>{t(`dcInitBt`)}</div>
                            <div className={'m-a-dep-charg-pg-button-fill'}
                                 onClick={saveNobankRefund} style={{pointerEvents: submitBtDisabled ? 'none' : 'auto'}}>{t(`dcDoneBt`)}
                            </div>
                        </div>
                    </div>
                ) : paypalTab && paypalChargTab ? (
                    <div className={'m-a-dep-charg-pg-table-frame'}>
                        <div className={'m-a-dep-charg-pg-table'}>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`myBal`)}</div>
                                <div className={'m-a-dep-charg-pg-table-item'}>
                                    {memberInfo && memberInfo?.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`dcDepPr`)}</div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={25} type="text"
                                       onChange={handlePaypalChargPrice} value={paypalChargPrice}/>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>
                                    {t(`pmHeader2`)}
                                    <div className={'m-a-dep-charg-pg-table-item-unit'} ref={dollarRef}
                                         onClick={toggleDollarShow}>
                                        {selectedDollar}
                                        {dollarShow && (
                                            <div className={'m-a-dep-charg-pg-table-dollar-list'}>
                                                {dollarList.map((dollar, index) => (
                                                    <div
                                                        className={`m-a-dep-charg-pg-table-dollar-item ${selectedDollar === dollar && 'selected'}`}
                                                        key={index}
                                                        onClick={() => selectChangeDollar(dollar)}>
                                                        {dollar}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={'m-a-dep-charg-pg-table-input'}>
                                    {Number(paypalChargPrice.replace(/,/g, "")) > 0 ? paypalChargExPrice : null}
                                </div>
                            </div>
                        </div>
                        <div className={'m-a-dep-charg-pg-notice'}>
                            <div className='m-a-dep-charg-sub-title'>
                                <img className='m-a-dep-charg-sub-title-icon' src={mainNotice} alt=''/>
                                <div className='m-a-dep-charg-sub-title-text'>{t('dcTx4')}</div>
                            </div>
                            <div className={'m-a-dep-charg-pg-notice-text-box'}>
                                - {t(`dcTx1`)} &nbsp;
                                <div onClick={moveToPaypalFaq} className={'m-a-dep-charg-pg-notice-text highlight'}>
                                    [{t(`dcTx2`)}]
                                </div>
                                {t(`dcTx3`)} <br/>
                                - {t(`dcTx6`)}
                            </div>
                        </div>
                        <div className={'m-a-dep-charg-pg-buttons'}>
                            <div className={'m-a-dep-charg-pg-button-outline'}
                                 onClick={paypalChargInit}>{t(`dcInitBt`)}</div>
                            <div className={'m-a-dep-charg-pg-button-fill'}
                                 onClick={openPaypalPopup}>{t(`dcPayBt`)}
                            </div>
                        </div>
                    </div>
                ) : paypalTab && paypalRefundTab ? (
                    <div className={'m-a-dep-charg-pg-table-frame'}>
                        <div className={'m-a-dep-charg-pg-table'}>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`myBal`)}</div>
                                <div className={'m-a-dep-charg-pg-table-item'}>
                                    {memberInfo && memberInfo?.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`dcRfPr`)}</div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={25} type="text"
                                       onChange={handlePaypalRefundPrice}
                                       value={paypalRefundPrice}/>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>
                                    {t(`pmHeader2`)}
                                    <div className={'m-a-dep-charg-pg-table-item-unit'} ref={dollarRef}
                                         onClick={toggleDollarShow}>
                                        {selectedDollar}
                                        {dollarShow && (
                                            <div className={'m-a-dep-charg-pg-table-dollar-list'}>
                                                {dollarList.map((dollar, index) => (
                                                    <div
                                                        className={`m-a-dep-charg-pg-table-dollar-item ${selectedDollar === dollar && 'selected'}`}
                                                        key={index}
                                                        onClick={() => selectChangeDollar(dollar)}>
                                                        {dollar}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={'m-a-dep-charg-pg-table-input'}>
                                    {Number(paypalRefundPrice.replace(/,/g, "")) > 0 ? paypalRefundExPrice : null}
                                </div>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`dcPpMail`)}</div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={50} type="text"
                                       onChange={handlePaypalRefundEmail}
                                       value={paypalRefundEmail}/>
                            </div>
                        </div>
                        <div className={'m-a-dep-charg-pg-notice'}>
                            <div className='m-a-dep-charg-sub-title'>
                                <img className='m-a-dep-charg-sub-title-icon' src={mainNotice} alt=''/>
                                <div className='m-a-dep-charg-sub-title-text'>{t(`dcTx4`)}</div>
                            </div>
                            <div className={'m-a-dep-charg-pg-notice-text-box'}>
                                - {t(`dcTx5`)} <br/>
                                - {t(`dcTx6`)}
                            </div>
                        </div>
                        <div className={'m-a-dep-charg-pg-buttons'}>
                            <div className={'m-a-dep-charg-pg-button-outline'}
                                 onClick={paypalRefundInit}>{t(`dcInitBt`)}</div>
                            <div className={'m-a-dep-charg-pg-button-fill'}
                                 onClick={savePaypalRefund} style={{pointerEvents: submitBtDisabled ? 'none' : 'auto'}}>{t(`dcDoneBt`)}
                            </div>
                        </div>
                    </div>
                ) : yuanTab && yuanChargTab ? (
                    <div className={'m-a-dep-charg-pg-table-frame'}>
                        <div className={'m-a-dep-charg-pg-table'}>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`myBal`)}</div>
                                <div className={'m-a-dep-charg-pg-table-item'}>
                                    {memberInfo && memberInfo?.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`dcDepPr`)}</div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={25} type="text"
                                       onChange={handleYuanChargPrice}
                                       value={yuanChargPrice}/>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>
                                    {t(`dcTx7`)}(CNY)
                                </div>
                                <div className={'m-a-dep-charg-pg-table-input'}>
                                    {Number(yuanChargPrice.replace(/,/g, "")) > 0 ? yuanChargExPrice : null}
                                </div>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>
                                    {t(`dcTx8`)}ID
                                </div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={25} type="text"
                                       placeholder={'ex) Wechat ID'}
                                       onChange={handleYuanChargSendId}
                                       value={yuanChargSendId}/>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>
                                    {t(`dcTx9`)}
                                </div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={8} type="text"
                                       onChange={handleYuanChargSendDate}
                                       value={yuanChargSendDate}/>
                            </div>
                        </div>
                        <div className={'m-a-dep-charg-pg-notice'}>
                            <div className='m-a-dep-charg-sub-title'>
                                <img className='m-a-dep-charg-sub-title-icon' src={mainNotice} alt=''/>
                                <div className='m-a-dep-charg-sub-title-text'>{t('dcTx4')}</div>
                            </div>
                            <div className={'m-a-dep-charg-pg-notice-text-box'}>
                                - {t(`dcTx10`)} &nbsp;
                                <div onClick={moveToYuanFaq} className={'m-a-dep-charg-pg-notice-text highlight'}>[{t(`dcTx11`)}]</div>
                                {t(`dcTx3`)}
                            </div>
                        </div>
                        <div className={'m-a-dep-charg-pg-buttons'}>
                            <div className={'m-a-dep-charg-pg-button-outline'}
                                 onClick={yuanChargInit}>{t(`dcInitBt`)}</div>
                            <div className={'m-a-dep-charg-pg-button-fill'}
                                 onClick={saveYuanCharg} style={{pointerEvents: submitBtDisabled ? 'none' : 'auto'}}>{t(`dcDoneBt`)}
                            </div>
                        </div>
                    </div>
                ) : yuanTab && yuanRefundTab ? (
                    <div className={'m-a-dep-charg-pg-table-frame'}>
                        <div className={'m-a-dep-charg-pg-table'}>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`myBal`)}</div>
                                <div className={'m-a-dep-charg-pg-table-item'}>
                                    {memberInfo && memberInfo?.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`dcRfPr`)}</div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={25} type="text"
                                       onChange={handleYuanRefundPrice}
                                       value={yuanRefundPrice}/>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>
                                    {t(`dcTx7`)}(cny)
                                </div>
                                <div className={'m-a-dep-charg-pg-table-input'}>
                                    {Number(yuanRefundPrice.replace(/,/g, "")) > 0 ? yuanRefundExPrice : null}
                                </div>
                            </div>
                            <div className={'m-a-dep-charg-pg-table-row'}>
                                <div className={'m-a-dep-charg-pg-table-item label'}>{t(`dcTx12`)}ID</div>
                                <input className={'m-a-dep-charg-pg-table-input'} maxLength={25} type="text"
                                       onChange={handleYuanRefundId}
                                       value={yuanRefundId}/>
                            </div>
                        </div>
                        <div className={'m-a-dep-charg-pg-buttons'}>
                            <div className={'m-a-dep-charg-pg-button-outline'}
                                 onClick={yuanRefundInit}>{t(`dcInitBt`)}</div>
                            <div className={'m-a-dep-charg-pg-button-fill'}
                                 onClick={saveYuanRefund} style={{pointerEvents: submitBtDisabled ? 'none' : 'auto'}}>{t(`dcDoneBt`)}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
            {paypalPopupSt && (
                <PaypalModal paypalPopupSt={paypalPopupSt} paypalPopupClose={paypalPopupClose}
                             currency={selectedDollar}
                             finalPayExpense={paypalChargPrice} lastForPrice={paypalChargExPrice}
                             paypalOrderProcess={savePaypalCharg}/>
            )}
        </div>
    )
}

export default withAuth(MDepChargPg);
