import React, {useEffect, useRef, useState} from "react";
import '../../css/web/AdminGuideSetPg.css';
import '../../css/BtorageEditor.css';
import maCateImg from "../../assets/img/m-a-cate.png";
import {useNavigate, useParams} from "react-router-dom";
import {GoPlus} from "react-icons/go";
import axios from "axios";
import {BiCaretDown} from "react-icons/bi";
import CommonLoading from "../../components/web/modal/CommonLoading";
import {
    RxFontBold,
    RxFontItalic, RxLink2, RxListBullet,
    RxTextAlignCenter,
    RxTextAlignLeft,
    RxTextAlignRight,
    RxUnderline
} from "react-icons/rx";

function AdminGuideSetPg() {

    /** region DataSet */

    const { seqNum } = useParams();

    const LCAT = localStorage.getItem('adminToken');

    const [guideTitle, setGuideTitle] = useState('');
    const [guideCont, setGuideCont] = useState('');
    const [guideImages, setGuideImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /** endregion */

    /** region Function */

    const contentRef = useRef(null);
    const navigate = useNavigate();

    const [fontSize, setFontSize] = useState("12pt");
    const [formError, setFormError] = useState(false);

    const langRef = useRef(null);
    const [langList, setLangList] = useState(["한국어", "English", "日本語", "中文語", "Indonesian"]);
    const [selectedLang, setSelectedLang] = useState('');
    const [langShow, setLangShow] = useState(false);

    const serviceRef = useRef(null);
    const [serviceList, setServiceList] = useState(["장바구니", "구매대행", "배송대행", "이체대행", "오프라인", "국내배송"]);
    const [selectedService, setSelectedService] = useState('');
    const [serviceShow, setServiceShow] = useState(false);

    const deviceRef = useRef(null);
    const [deviceList, setDeviceList] = useState(["PC", "모바일", "앱"]);
    const [selectedDevice, setSelectedDevice] = useState('');
    const [deviceShow, setDeviceShow] = useState(false);

    const [selectedImages, setSelectedImages] = React.useState([]);

    const [showOptions, setShowOptions] = useState(false);

    const [history, setHistory] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(-1);

    const toggleLangShow = () => {
        setLangShow(!langShow);
    };

    const handleLangChange = (mst) => {
        setSelectedLang(mst);
        setLangShow(false);
    };

    const toggleServiceShow = () => {
        setServiceShow(!langShow);
    };

    const handleServiceChange = (mst) => {
        setSelectedService(mst);
        setServiceShow(false);
    };

    const toggleDeviceShow = () => {
        setDeviceShow(!deviceShow);
    };

    const handleDeviceChange = (mst) => {
        setSelectedDevice(mst);
        setDeviceShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (langRef.current && !langRef.current.contains(event.target)) {
                setLangShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (serviceRef.current && !serviceRef.current.contains(event.target)) {
                setServiceShow(false);
            }
            if (deviceRef.current && !deviceRef.current.contains(event.target)) {
                setDeviceShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleGuideImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setSelectedImages(prevImages => {
            return [...prevImages, ...files];
        });

        const newImages = files.map(file => URL.createObjectURL(file));
        newImages.forEach(imageURL => {
            insertImageAtCursor(imageURL);
        });
    };

    const insertImageAtCursor = (imageURL) => {
        const imgElement = document.createElement("img");
        imgElement.src = imageURL;
        imgElement.style.width = "100%";
        imgElement.style.maxWidth = "1280px";
        imgElement.style.minWidth = "320px";
        imgElement.style.display = "block";
        imgElement.style.borderRadius = "8px";
        imgElement.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";

        const contentEditableDiv = contentRef.current;
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);

        if (range && range.commonAncestorContainer === contentEditableDiv) {
            range.deleteContents();
            range.insertNode(imgElement);
            range.setStartAfter(imgElement);
            range.setEndAfter(imgElement);
            selection.removeAllRanges();
            selection.addRange(range);
            setGuideImages(prevImages => [...prevImages, imageURL]);
        } else {
            contentEditableDiv.appendChild(imgElement);
            setGuideImages(prevImages => [...prevImages, imageURL]);
        }
        // handleContentChange();
    };

    useEffect(() => {
        if (contentRef.current && !guideCont) {
            contentRef.current.innerHTML = '<p>본문 내용을 입력하세요.</p>';
        }
    }, [guideCont]);

    const handleContentChange = () => {
        const newContent = contentRef.current.innerHTML;

        if (currentIndex === history.length - 1) {
            setHistory((prev) => [...prev, newContent]);
            setCurrentIndex(currentIndex + 1);
        } else {
            setHistory((prev) => [...prev.slice(0, currentIndex + 1), newContent]);
            setCurrentIndex(currentIndex + 1);
        }

        setGuideCont(newContent);
    };

    const undo = () => {
        if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            setCurrentIndex(newIndex);
            contentRef.current.innerHTML = history[newIndex];
            setGuideCont(history[newIndex]);
        }
    };

    const redo = () => {
        if (currentIndex < history.length - 1) {
            const newIndex = currentIndex + 1;
            setCurrentIndex(newIndex);
            contentRef.current.innerHTML = history[newIndex];
            setGuideCont(history[newIndex]);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey) {
                if (event.key === 'Z') {
                    event.preventDefault();
                    undo();
                } else if (event.key === 'Y') {
                    event.preventDefault();
                    redo();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [history, currentIndex]);

    const convertToLang = ((lang) => {
        if (lang === "0") return "한국어"
        if (lang === "1") return "English"
        if (lang === "2") return "日本語"
        if (lang === "3") return "中文語"
        if (lang === "4") return "Indonesian"
    })

    const handleInsertDividerClick = () => {
        contentRef.current.focus();
        insertDivider();
    };

    const insertDivider = () => {
        const selection = window.getSelection();

        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);

            const divider = document.createElement("hr");
            divider.style.border = "1px solid #bbbbbb";
            divider.style.margin = "30px 10px";

            range.insertNode(divider);

            range.setStartAfter(divider);
            range.setEndAfter(divider);

            selection.removeAllRanges();
            selection.addRange(range);
        }
    };

    /** endregion */

    /** region Event */

    useEffect(() => {
        return () => {
            selectedImages.forEach(image => URL.revokeObjectURL(image));
        };
    }, [selectedImages]);

    useEffect(() => {
        if (seqNum) {
            axios.get(`/guide/getSelectGuideData?seqNum=${seqNum}`)
                .then(response => {
                    const data = response.data;
                    const serviceType = data.gtype;
                    const langName = convertToLang(data.glang);
                    const device = data.gmachineType;

                    setGuideTitle(data.gtitle);
                    setGuideCont(data.gcont);
                    setSelectedLang(langName);
                    setSelectedDevice(device);

                    let serviceName = "";
                    switch (serviceType) {
                        case "P00":
                            serviceName = "장바구니";
                            break;
                        case "P01":
                            serviceName = "구매대행";
                            break;
                        case "P02":
                            serviceName = "오프라인";
                            break;
                        case "P03":
                            serviceName = "이체대행";
                            break;
                        case "P04":
                            serviceName = "국내배송";
                            break;
                        case "P05":
                            serviceName = "배송대행";
                            break;
                        default:
                            serviceName = serviceType;
                    }
                    setSelectedService(serviceName);
                    const guidePhoto = data.userGuidePhotoDTOS[0];

                    if (guidePhoto) {
                        setGuideImages({ photoImg: "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/useGuide/" + guidePhoto.fileNm, photoImgSt: true, photoImgError: false, photoImgOriNm: '' })
                    }
                    setGuideImages(data.guideImages || []);
                })
                .catch(error => {
                    console.error("수정data err:", error);
                });
        } else {
            setGuideTitle("");
            setGuideCont("");
            setSelectedLang("");
            setSelectedService("");
            setGuideImages([]);
        }
    }, [seqNum]);

    const guideSave = () => {
        let hasError = false;

        if (!selectedService || selectedService === "서비스선택") {
            hasError = true;
        }

        if (!selectedLang || selectedLang === "언어선택") {
            hasError = true;
        }

        if (!selectedDevice || selectedDevice.trim() === '기기선택') {
            hasError = true;
        }

        const content = contentRef.current.innerHTML.trim();
        if (!content || content === '<p>본문 내용을 입력하세요.</p>' || content === '') {
            hasError = true;
        }

        if (hasError) {
            setFormError(true);
            return;
        } else {
            setFormError(false);
        }

        if (isLoading) return;

        setIsLoading(true);

        const newFormData = new FormData();

        newFormData.append('guideTitle', guideTitle);
        newFormData.append('guideCont', contentRef.current.innerHTML);

        selectedImages.forEach((file, index) => {
            newFormData.append('guideImg', file, file.name);
        });

        const service = selectedService === "장바구니" ? "P00" : selectedService === "구매대행" ? "P01"
            : selectedService === "오프라인" ? "P02" : selectedService === "이체대행" ? "P03" : selectedService === "국내배송" ? "P04" : "P05";

        const lang = selectedLang === "한국어" ? 0 : selectedLang === "English" ? 1
            : selectedLang === "日本語" ? 2 : selectedLang === "中文語" ? 3 : 4;

        const device = selectedDevice === "PC" ? 0 : selectedDevice === "모바일" ? 1 : 2;

        newFormData.append('gType', service);
        newFormData.append('mType', device);
        newFormData.append('gLang', lang);

        return axios({
            method: 'post',
            url: '/guide/saveGuide',
            data: newFormData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
        })
            .then(async (response) => {
                navigate('/btcorpad/pageset');
                setIsLoading(false);
            });
    }

    const guideModifySave = () => {
        if (isLoading) return;

        setIsLoading(true);

        const newFormData = new FormData();

        newFormData.append('guideTitle', guideTitle);
        newFormData.append('guideCont', contentRef.current.innerHTML);

        selectedImages.forEach((file) => {
            newFormData.append('guideImg', file, file.name);
        });

        const service = selectedService === "장바구니" ? "P00" : selectedService === "구매대행" ? "P01"
            : selectedService === "오프라인" ? "P02" : selectedService === "이체대행" ? "P03" : selectedService === "국내배송" ? "P04" : "P05";

        const lang = selectedLang === "한국어" ? 0 : selectedLang === "English" ? 1
            : selectedLang === "Japanese" ? 2 : selectedLang === "Chinese" ? 3 : 4;

        const device = selectedDevice === "PC" ? 0 : selectedDevice === "모바일" ? 1 : 2;

        newFormData.append('mType', device);
        newFormData.append('gType', service);
        newFormData.append('gLang', lang);

        return axios({
            method: 'put',
            url: `/guide/saveGuide/${seqNum}`,
            data: newFormData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
        })
            .then((response) => {
                navigate('/btcorpad/pageset');
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Modify guide error:", error);
            });
    }

    let savedSelection = null;

    const saveSelection = () => {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            savedSelection = selection.getRangeAt(0);
        }
    };

    const restoreSelection = () => {
        const selection = window.getSelection();
        if (savedSelection) {
            selection.removeAllRanges();
            selection.addRange(savedSelection);
        }
    };

    const applyTextStyle = (style) => {
        restoreSelection();

        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const selectedText = range.toString();

            if (selectedText) {
                const parentElement = range.startContainer.parentNode;
                const isBold = parentElement.style.fontWeight === "bold";
                const isItalic = parentElement.style.fontStyle === "italic";
                const isUnderlined = parentElement.style.textDecoration === "underline";

                const span = document.createElement("span");

                if (style === "bold") {
                    if (isBold) {
                        span.style.fontWeight = "normal";
                    } else {
                        span.style.fontWeight = "bold";
                    }
                } else if (style === "italic") {
                    if (isItalic) {
                        span.style.fontStyle = "normal";
                    } else {
                        span.style.fontStyle = "italic";
                    }
                } else if (style === "underline") {
                    if (isUnderlined) {
                        span.style.textDecoration = "none";
                    } else {
                        span.style.textDecoration = "underline";
                    }
                }

                range.surroundContents(span);
                const newHtmlContent = contentRef.current.innerHTML;
                setGuideCont(newHtmlContent);
            }
        }
    };

    const handleBoldClick = () => {
        saveSelection();
        applyTextStyle("bold");
    };
    const handleItalicClick = () => {
        saveSelection();
        applyTextStyle("italic");
    };
    const handleUnderlineClick = () => {
        saveSelection();
        applyTextStyle("underline");
    };

    const handleFontSizeChange = (e) => {
        const newFontSize = e.target.value;
        setFontSize(newFontSize);

        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const selectedText = range.toString();

            if (selectedText) {
                const parentSpan = range.startContainer.parentNode;
                if (parentSpan.nodeName === 'SPAN' && parentSpan.style.fontSize) {
                    parentSpan.style.fontSize = newFontSize;
                } else {
                    const fontSizeSpan = document.createElement("span");
                    fontSizeSpan.style.fontSize = newFontSize;
                    fontSizeSpan.textContent = selectedText;

                    range.deleteContents();
                    range.insertNode(fontSizeSpan);
                    range.setStartAfter(fontSizeSpan);
                    range.setEndAfter(fontSizeSpan);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            }
        }
    };

    const handleTextAlign = (alignType) => {
        const selection = window.getSelection();

        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const alignSpan = document.createElement("span");
            alignSpan.style.display = "inline-block";
            alignSpan.style.textAlign = alignType;
            range.surroundContents(alignSpan);

            const newHtmlContent = contentRef.current.innerHTML;
            setGuideCont(newHtmlContent);
        }
    };

    const applyNumberAndTitleStyle = () => {
        const selection = window.getSelection();

        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const selectedText = range.toString().trim();

            const firstSpaceIndex = selectedText.indexOf(" ");
            const number = selectedText.slice(0, firstSpaceIndex);
            const title = selectedText.slice(firstSpaceIndex + 1).trim();

            if (number && title) {
                const numberDiv = document.createElement("div");
                const flexDiv = document.createElement("div");

                if (selectedDevice === "PC") {
                    numberDiv.style.width = "32px";
                    numberDiv.style.fontSize = "23px";
                    flexDiv.style.height = "29px";
                } else {
                    numberDiv.style.width = "28px";
                    numberDiv.style.fontSize = "20px";
                    flexDiv.style.height = "26px";
                }

                numberDiv.style.borderRadius = "8px";
                numberDiv.style.border = "none";
                numberDiv.style.backgroundColor = "#d2d2d2";
                numberDiv.style.color = "#fff";
                numberDiv.style.marginRight = "7px";
                numberDiv.style.display = "inline-block";
                numberDiv.textContent = number;

                const titleDiv = document.createElement("div");
                titleDiv.style.color = "black";
                titleDiv.style.fontSize = "22px";
                titleDiv.style.display = "inline-block";
                titleDiv.textContent = title;

                flexDiv.style.textAlign = "center";
                flexDiv.style.fontWeight = "bold";
                flexDiv.style.display = "flex";
                flexDiv.style.flexDirection = "row";
                flexDiv.style.justifyContent = "start";
                flexDiv.style.alignItems = "center";
                flexDiv.style.marginBottom = "15px";
                flexDiv.style.marginTop = "30px";

                flexDiv.appendChild(numberDiv);
                flexDiv.appendChild(titleDiv);

                range.deleteContents();
                range.insertNode(flexDiv);

                const newHtmlContent = contentRef.current.innerHTML;
                setGuideCont(newHtmlContent);

                moveCursorToEnd(contentRef.current);
            }
        }
    };

    const moveCursorToEnd = (element) => {
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(element);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
        element.focus();
    };

    const handleUrlLinkClick = () => {
        const selection = window.getSelection();

        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const selectedText = range.toString().trim();

            const urlPattern = /^(https?:\/\/[^\s]+)/g;
            if (urlPattern.test(selectedText)) {
                const anchorTag = document.createElement("a");
                anchorTag.href = selectedText;
                anchorTag.target = "_blank";
                anchorTag.textContent = selectedText;
                anchorTag.style.color = "#0c93ff";
                anchorTag.style.textDecoration = "underline";
                anchorTag.style.cursor = "pointer";

                range.deleteContents();
                range.insertNode(anchorTag);

                const newHtmlContent = contentRef.current.innerHTML;
                setGuideCont(newHtmlContent);
            } else {

            }
        }
    };

    const handleOutsideClick = (event) => {
        const optionsMenu = document.querySelector('.options-menu-new');
        const target = event.target;
        if (showOptions && optionsMenu && !optionsMenu.contains(target) && target.closest('.g-file-upload-new') === null) {
            setShowOptions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showOptions]);

    /** endregion */

    return (
        <div id="AdminGuideSetPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">이용가이드 관리</p>
            </div>
            <div className="admin-wrap font-t-cont">
                <div className="admin-wrap-card guide-container">
                    <div className="display-flex justify-content-center">
                        <div className="display-flex flex-column guide-frame m-b-20">
                            <div className="gf-gbn p-b-20">
                                <div className="display-flex flex-row">
                                    <div className="wd-150 ht-40 m-r-20">
                                        <div ref={serviceRef} className="ht-100pc wd-100pc position-relative">
                                            <button className="selectParentBt wd-100pc ht-100pc" onClick={toggleServiceShow}>
                                                <p>{selectedService === "" ? "서비스선택" : selectedService}</p>
                                                <span style={{position: "absolute", left: "80%", top: "0", height: "100%", zIndex:10, width: "20%"}}>
                                                        <i style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}><BiCaretDown/></i>
                                                    </span>
                                            </button>
                                            {serviceShow ?
                                                <div className="wd-100pc ht-200 m-t-5 position-absolute" style={{
                                                    overflowY:"scroll",
                                                    border: "solid 1px lightgray",
                                                    top: "100%",
                                                    bottom: "100%",
                                                    zIndex: 1,
                                                }}>
                                                    {serviceList.map((service, index) => (
                                                        <div className="selectChildBt display-flex just-cont-center c-point font-s-14 align-center" key={index} value={service}
                                                             onClick={() => handleServiceChange(service)} style={{padding: "10px"}}>
                                                            {service}
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    <div className="wd-150 ht-40 m-r-20">
                                        <div ref={langRef} className="ht-100pc wd-100pc position-relative">
                                            <button className="selectParentBt wd-100pc ht-100pc" onClick={toggleLangShow}>
                                                <p>{selectedLang === "" ? "언어선택" : selectedLang}</p>
                                                <span style={{position: "absolute", left: "80%", top: "0", height: "100%", zIndex:10, width: "20%"}}>
                                                                <i style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    height: "100%"
                                                                }}><BiCaretDown/></i>
                                                            </span>
                                            </button>
                                            {langShow ?
                                                <div className="wd-100pc ht-200 m-t-5 position-absolute" style={{
                                                    overflowY:"scroll",
                                                    border: "solid 1px lightgray",
                                                    top: "100%",
                                                    bottom: "100%",
                                                    zIndex: 1,
                                                }}>
                                                    {langList.map((lang, index) => (
                                                        <div className="selectChildBt display-flex just-cont-center c-point font-s-14 align-center" key={index} value={lang}
                                                             onClick={() => handleLangChange(lang)} style={{padding: "10px"}}>
                                                            {lang}
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    <div className="wd-150 ht-40">
                                        <div ref={deviceRef} className="ht-100pc wd-100pc position-relative">
                                            <button className="selectParentBt wd-100pc ht-100pc" onClick={toggleDeviceShow}>
                                                {/*<p>{selectedDevice === "" ? "기기선택" : selectedDevice}</p>*/}
                                                <p>{selectedDevice === "0" ? "PC" : selectedDevice === "1" ? "모바일" : selectedDevice === "2" ? "앱" : selectedDevice === "" ? "기기선택" : selectedDevice}</p>
                                                <span style={{position: "absolute", left: "80%", top: "0", height: "100%", zIndex:10, width: "20%"}}>
                                                                <i style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    height: "100%"
                                                                }}><BiCaretDown/></i>
                                                            </span>
                                            </button>
                                            {deviceShow ?
                                                <div className="wd-100pc ht-120 m-t-5 position-absolute" style={{
                                                    overflowY:"scroll",
                                                    border: "solid 1px lightgray",
                                                    top: "100%",
                                                    bottom: "100%",
                                                    zIndex: 1,
                                                }}>
                                                    {deviceList.map((device, index) => (
                                                        <div className="selectChildBt display-flex just-cont-center c-point font-s-14 align-center" key={index} value={device}
                                                             onClick={() => handleDeviceChange(device)} style={{padding: "10px"}}>
                                                            {device}
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="wd-60pc display-flex flex-row just-cont-fend text-right">
                                    {formError && <div className="font-c-cancel font-s-16 p-t-9 m-r-7">선택항목 또는 내용을 확인해주세요.</div>}
                                    {seqNum ?
                                        <button className="admin-btn-A font-s-14 btn-click-event ht-40 wd-120" type="submit" onClick={guideModifySave}>저장</button>
                                        :
                                        <button className="admin-btn-A font-s-14 btn-click-event ht-40 wd-120" type="submit" onClick={guideSave}>등록</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {(selectedDevice === "모바일" || selectedDevice === "앱" || selectedDevice === "PC") && (
                        <div className="display-flex justify-content-center m-0-a bt-toolbar-frame" style={{width: "1280px"}}>
                            <div className="display-flex flex-row bt-toolbar">
                                <div className="g-file-upload-new font-t-cont">
                                    <span className="g-img-upload-new" onClick={() => setShowOptions(!showOptions)}>
                                        <GoPlus className="font-c-default font-s-21 m-t-2"/>
                                    </span>
                                    {showOptions && (
                                        <div className="options-menu-new font-s-14">
                                            <div onClick={() => document.getElementById('image').click()}>이미지 추가</div>
                                            <div onClick={handleInsertDividerClick}>구분선 추가</div>
                                            <input type="file" id="image" accept="image/*" multiple onChange={handleGuideImageUpload} style={{ display: 'none' }} />
                                        </div>
                                    )}
                                </div>
                                <button className="bt-t-button" onClick={handleBoldClick}><RxFontBold className="font-s-24 c-point btorage-hover btn-click-event"/></button>
                                <button className="bt-t-button" onClick={handleItalicClick}><RxFontItalic className="font-s-24 c-point btorage-hover btn-click-event"/></button>
                                <button className="bt-t-button" onClick={handleUnderlineClick}><RxUnderline className="font-s-22 c-point btorage-hover btn-click-event"/></button>
                                <div>
                                    <select className="bt-t-select wd-60 ht-30 font-s-12" value={fontSize} onChange={handleFontSizeChange}>
                                        <option value="10pt">10pt</option>
                                        <option value="12pt">12pt</option>
                                        <option value="14pt">14pt</option>
                                        <option value="16pt">16pt</option>
                                        <option value="18pt">18pt</option>
                                        <option value="20pt">20pt</option>
                                        <option value="22pt">22pt</option>
                                        <option value="24pt">24pt</option>
                                        <option value="26pt">26pt</option>
                                        <option value="28pt">28pt</option>
                                    </select>
                                </div>
                                <button className="bt-t-button" onClick={() => handleTextAlign('left')}><RxTextAlignLeft className="font-s-24 c-point btorage-hover btn-click-event"/></button>
                                <button className="bt-t-button" onClick={() => handleTextAlign('center')}><RxTextAlignCenter className="font-s-24 c-point btorage-hover btn-click-event"/></button>
                                <button className="bt-t-button" onClick={() => handleTextAlign('right')} ><RxTextAlignRight className="font-s-24 c-point btorage-hover btn-click-event"/></button>
                                <button className="bt-t-button" onClick={applyNumberAndTitleStyle}><RxListBullet className="font-s-24 c-point btorage-hover btn-click-event"/></button>
                                <button className="bt-t-button" onClick={handleUrlLinkClick}><RxLink2 className="font-s-22 c-point btorage-hover btn-click-event"/></button>
                            </div>
                        </div>
                    )}
                    <div className="display-flex justify-content-center p-b-20">
                        {selectedDevice === "PC" && (
                            <div className="display-flex flex-column guide-frame">
                                <div className="gf-title m-t-10">
                                    <input className="gf-title-input font-t-title" type="text" id="guideTitle" placeholder="제목(관리자용)" maxLength="30"
                                           value={guideTitle}
                                           onChange={(e) => setGuideTitle(e.target.value)}
                                    />
                                </div>
                                <div className="gf-cont p-b-10">
                                    <div className="gf-cont-form p-b-40 p-l-15 p-r-15 font-s-19" contentEditable="true" ref={contentRef} dangerouslySetInnerHTML={{ __html: guideCont }}
                                        onFocus={() => {
                                            const content = contentRef.current.innerHTML.trim();
                                            if (content === '<p>본문 내용을 입력하세요.</p>' || content === '') {
                                                contentRef.current.innerHTML = '';
                                            }
                                        }}
                                        onBlur={() => {
                                            handleContentChange();
                                        }}
                                         onMouseUp={saveSelection}
                                    />
                                </div>
                            </div>
                        )}
                        {(selectedDevice === "모바일" || selectedDevice === "앱") && (
                            <div className="display-flex flex-column guide-frame-m m-t-20">
                                <div className="gf-title m-t-20">
                                    <input className="gf-title-input font-t-title" type="text" id="guideTitle" placeholder="제목(관리자용)" maxLength="30"
                                           value={guideTitle}
                                           onChange={(e) => setGuideTitle(e.target.value)}
                                    />
                                </div>
                                <div className="gf-cont p-b-10">
                                    <div className="gf-cont-form p-b-40 p-l-15 p-r-15 font-s-13" contentEditable="true" ref={contentRef} dangerouslySetInnerHTML={{ __html: guideCont }}
                                         onFocus={() => {
                                             const content = contentRef.current.innerHTML.trim();
                                             if (content === '<p>본문 내용을 입력하세요.</p>' || content === '') {
                                                 contentRef.current.innerHTML = '';
                                             }
                                         }}
                                         onBlur={() => {
                                             handleContentChange();
                                         }}
                                         onMouseUp={saveSelection}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isLoading && <CommonLoading />}
        </div>
    );
}
export default AdminGuideSetPg;
