import React from "react";
import commonClose from '../../../assets/img/commonClose.png'
import HeaderLangKr from '../../../assets/img/HeaderLangKr.png'
import HeaderLangUs from '../../../assets/img/HeaderLangUs.png'
import HeaderLangCn from '../../../assets/img/HeaderLangCn.png'
import HeaderLangJp from '../../../assets/img/HeaderLangJp.png'
import HeaderLangId from '../../../assets/img/HeaderLangId.png'

function SelectLangModal({selectLangFalseFn, handleSelectLangChange}) {
    return (
        <div className="bookContainer">
            <div className="modalFrame modalFrame-lang">
                <div className="ht-50 display-flex just-cont-sb align-center font-t-title p-l-15 p-r-13 border-bottom-bt">
                    <p className="font-s-18 font-c-default c-def">Language</p>
                    <div className="c-point wd-30 ht-30" onClick={selectLangFalseFn}>
                        <img className="m-a-opc-07 wd-100pc ht-100pc" alt="close" src={commonClose}/>
                    </div>
                </div>
                <div className="display-flex flex-column font-t-cont pd15 gap15">
                    <div onClick={() => handleSelectLangChange("ko")} className="wd-100pc ht-50 user-center btorage-hover-background-grey border-rd4 c-point border-bt">
                        <div className="text-center wd-40pc">
                            <img className="m-a-opc-08 wd-45 ht-45" alt="korea" src={HeaderLangKr}/>
                        </div>
                        <div className="wd-60pc">
                            <p className="fon-s-14">한국어</p>
                        </div>
                    </div>
                    <div onClick={() => handleSelectLangChange("en")} className="wd-100pc ht-50 user-center btorage-hover-background-grey border-rd4 c-point border-bt">
                        <div className="text-center wd-40pc">
                            <img className="m-a-opc-08 wd-45 ht-45" alt="korea" src={HeaderLangUs}/>
                        </div>
                        <div className="wd-60pc">
                            <p className="fon-s-14">English</p>
                        </div>
                    </div>
                    <div onClick={() => handleSelectLangChange("ja")} className="wd-100pc ht-50 user-center btorage-hover-background-grey border-rd4 c-point border-bt">
                        <div className="text-center wd-40pc">
                            <img className="m-a-opc-08 wd-45 ht-45" alt="korea" src={HeaderLangJp}/>
                        </div>
                        <div className="wd-60pc">
                            <p className="fon-s-14">日本語</p>
                        </div>
                    </div>
                    <div onClick={() => handleSelectLangChange("zh")} className="wd-100pc ht-50 user-center btorage-hover-background-grey border-rd4 c-point border-bt">
                        <div className="text-center wd-40pc">
                            <img className="m-a-opc-08 wd-45 ht-45" alt="korea" src={HeaderLangCn}/>
                        </div>
                        <div className="wd-60pc">
                            <p className="fon-s-14">中国語</p>
                        </div>
                    </div>
                    <div onClick={() => handleSelectLangChange("id")} className="wd-100pc ht-50 user-center btorage-hover-background-grey border-rd4 c-point border-bt">
                        <div className="text-center wd-40pc">
                            <img className="m-a-opc-08 wd-45 ht-45" alt="korea" src={HeaderLangId}/>
                        </div>
                        <div className="wd-60pc">
                            <p className="fon-s-14">bahasaIndo</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectLangModal
