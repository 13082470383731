import React, {createRef, useCallback, useEffect, useMemo, useRef, useState} from "react";
import '../../css/web/UserMyOrderPg.css';
import '../../css/web/UserOrderPg.css';
import {BiCaretDown} from "react-icons/bi";
import PaypalLogo from '../../assets/img/orderPaypal.png';
import WechatpayLogo from '../../assets/img/WechatPayLogo.png';
import AlipayLogo from '../../assets/img/AlipayLogo.png';
import DepositPay from '../../assets/img/depositPay.png';
import PointPay from '../../assets/img/pointPay.png';
import NoBank from '../../assets/img/noBank.png';
import AliQrCode from '../../assets/img/aliqrcode.png';
import wechatQrCode from '../../assets/img/wechatqrcode.png';
import cartImage from '../../assets/img/cartImage.png'
import BtorageLogo from '../../assets/img/BtorageLogo.png'
import chatEmotion from '../../assets/img/chatEmotion.png'
import chatFile from '../../assets/img/chatFile.png'
import chatStart from '../../assets/img/detailpgChatStart.png'
import chatFileImg from '../../assets/img/chatFolderImg.png'
import profileImage from '../../assets/img/profileDefaultImg.png'
import trash from '../../assets/img/mainChatTrash.png'
import commonDown from '../../assets/img/commonDown.png'
import upsLogo from '../../assets/img/UpsLogo.png';
import kPacketLogo from '../../assets/img/Kpacket.png';
import smallPacketLogo from '../../assets/img/commonSmallPacket.png';

import axios from "axios";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";

import excel from "exceljs";
import * as XLSX from "xlsx";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import Picker from "@emoji-mart/react";
import ChatFileUpload from "../../components/web/modal/ChatFileUpload";

import Linkify from 'react-linkify';
import DOMPurify from 'dompurify';
import ProdPriceDetail from "../../components/web/modal/ProdPriceDetail";
import DeliveryPriceDetail from "../../components/web/modal/DeliveryPriceDetail";
import EtcPriceDetail from "../../components/web/modal/EtcPriceDetail";
import CouponUse from "../../components/web/modal/CouponUse";
import {BsFillPersonCheckFill, BsMailbox2} from "react-icons/bs";
import {MdPhoneIphone} from "react-icons/md";
import {TbAddressBook} from "react-icons/tb";
import TopBtn from "../../components/web/layoutsadmin/TopBtn";
import ChatModifyModal from "../../components/web/modal/ChatModifyModal";
import ChatFileDown from "../../components/web/modal/ChatFileDown";
import ProdPriceAdminAddDetail from "../../components/web/modal/ProdPriceAdminAddDetail";
import PaypalModal from "../../components/web/modal/PaypalModal";
import {HiReceiptTax} from "react-icons/hi";
import searchTrack from "../../assets/img/searchTrack.png";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import {orderStatusUpdate} from "../../services/AutoChangeOrderSt";
import commonClose from "../../assets/img/commonClose.png";
import detailCart from "../../assets/img/detailCart.png";
import detailDelivery from "../../assets/img/detailDelivery.png";
import detailOrder from "../../assets/img/detailOrder.png";
import detailOffline from "../../assets/img/detailOffline.png";
import detailTransfer from "../../assets/img/detailTransfer.png";
import detailDomestic from "../../assets/img/detailDomestic.png";
import termsView from "../../assets/img/termsView.png";
import {useTranslation} from "react-i18next";
import CommonTermsPopup from "../../components/web/modal/CommonTermsPopup";
import FedexLogo from "../../assets/img/FedexLogo.png";
import EmsLogo from "../../assets/img/EmsLogo.png";
import SeaShipLogo from "../../assets/img/SeaShipLogo.png";
import domLogo from "../../assets/img/detailDomestic.png";
import {useWebSocket} from "../../services/WebsocketContext";
import ReadyingPopup from "../../components/web/modal/ReadyingPopup";
import btoragePay from "../../assets/img/BtorageLogo.png";
import creditCard from "../../assets/img/payFormHeader.png";
import stripe from "../../assets/img/StripeLogo.png";
import DhlLogo from "../../assets/img/DhlLogo.png";

function AdminOrderDtModiPg() {
    const {t} = useTranslation();
    const {ordNum} = useParams();
    const navigate = useNavigate();
    const LCAT = localStorage.getItem('adminToken');

    const [isLoading, setIsLoading] = useState(false);
    const [isSaveBtLoading, setIsSaveBtLoading] = useState(false);
    const [isSaveBtDisabled, setIsSaveBtDisabled] = useState(false);
    const [isPayBtLoading, setIsPayBtLoading] = useState(false);
    const [isPayBtDisabled, setIsPayBtDisabled] = useState(false);

    const [recieverInfoAccordion, setRecieverInfoAccordion] = useState(true);
    const [cartOrderAccordion, setCartOrderAccordion] = useState(false);
    const [orderAccordion, setOrderAccordion] = useState(false);
    const [offlineAccordion, setOfflineAccordion] = useState(false);
    const [transferAccordion, setTransferAccordion] = useState(false);
    const [domesticAccordion, setDomesticAccordion] = useState(false);
    const [deliveryAccordion, setDeliveryAccordion] = useState(false);
    const [invoiceAccordion, setInvoiceAccordion] = useState(true);
    const [requestConfigAccordion, setRequestConfigAccordion] = useState(true);
    const [payListAccordion, setPayListAccordion] = useState(true);

    const [selectedOrderChoiceOption, setSelectedOrderChoiceOption] = useState('');

    const [useType, setUseType] = useState("U0");

    const [defaultAddr, setDefaultAddr] = useState(true);
    const [newAddr, setNewAddr] = useState(false);

    const [deliveryChoice, setDeliveryChoice] = useState([]);
    const [selectedDeliveryChoiceOption, setSelectedDeliveryChoiceOption] = useState('배송사');
    const [domesticDeliveryChoice, setDomesticDeliveryChoice] = useState([]);

    const [country, setCountry] = useState('국가');
    const [taxid, setTaxid] = useState("");
    const [recname, setRecname] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [telnum, setTelnum] = useState("");

    const selectedOrderChoiceOptionRef = useRef(null);
    const selectedDeliveryChoiceOptionRef = useRef(null);
    const taxidRef = useRef(null);
    const recnameRef = useRef(null);
    const address1Ref = useRef(null);
    const cityRef = useRef(null);
    const zipcodeRef = useRef(null);
    const telnumRef = useRef(null);

    const [reqSeqnum, setReqSeqnum] = useState("");
    const [tagRemove, setTagRemove] = useState(false);
    const [shoesBoxRemove, setShoesBoxRemove] = useState(false);
    const [hangerRemove, setHangerRemove] = useState(false);
    const [compressionPack, setCompressionPack] = useState(false);
    const [productBoxRemove, setProductBoxRemove] = useState(false);
    const [packingHold, setPackingHold] = useState(false);
    const [deliveryDepositPay, setDeliveryDepositPay] = useState(false);
    const [deliveryMessengerGuide, setDeliveryMessengerGuide] = useState(false);
    const [detailRequest, setDetailRequest] = useState("");

    const [orderChoiceError, setOrderChoiceError] = useState("");
    const [deliveryChoiceError, setDeliveryChoiceError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [recnameError, setRecnameError] = useState("");
    const [address1Error, setAddress1Error] = useState("");
    const [address2Error, setAddress2Error] = useState("");
    const [address3Error, setAddress3Error] = useState("");
    const [cityError, setCityError] = useState("");
    const [zipcodeError, setZipcodeError] = useState("");
    const [telnumError, setTelnumError] = useState("");

    const [taxidError, setTaxidError] = useState(false);
    const [payMethodError, setPayMethodError] = useState(false);
    const [pointPayError, setPointPayError] = useState(false);
    const [depositSelectErr, setDepositSelectErr] = useState(false);
    const [pointSelectErr, setPointSelectErr] = useState(false);
    const [etcPayError, setEtcPayError] = useState(false);
    const [discountError, setDiscountError] = useState(false);

    const [orderAddrBookSt, setOrderAddrBookSt] = useState(false);
    const [getDefaultAddrList, setGetDefaultAddrList] = useState("");

    const [nameInputCount, setNameInputCount] = useState(0);
    const [telnumInputCount, setTelnumInputCount] = useState(0);
    const [zipcodeInputCount, setZipcodeInputCount] = useState(0);
    const [address1InputCount, setAddress1InputCount] = useState(0);
    const [address2InputCount, setAddress2InputCount] = useState(0);
    const [address3InputCount, setAddress3InputCount] = useState(0);
    const [cityInputCount, setCityInputCount] = useState(0);

    // new product bt show fl
    const [newCartBtShow, setNewCartBtShow] = useState(true);
    const [newOrderBtShow, setNewOrderBtShow] = useState(true);
    const [newOfflineBtShow, setNewOfflineBtShow] = useState(true);
    const [newTransferBtShow, setNewTransferBtShow] = useState(true);
    const [newDomesticBtShow, setNewDomesticBtShow] = useState(true);
    const [newDeliveryBtShow, setNewDeliveryBtShow] = useState(true);

    //  add product form state
    const [newCartFormSt, setNewCartFormSt] = useState(false);
    const [newOrderFormSt, setNewOrderFormSt] = useState(false);
    const [newOfflineFormSt, setNewOfflineFormSt] = useState(false);
    const [newTransferFormSt, setNewTransferFormSt] = useState(false);
    const [newDomesticFormSt, setNewDomesticFormSt] = useState(false);
    const [newDeliveryFormSt, setNewDeliveryFormSt] = useState(false);

    // database product form
    const [dbCartProductHandle, setDbCartProductHandle] = useState([]);
    const [dbOrderProductHandle, setDbOrderProductHandle] = useState([]);
    const [dbOfflineProductHandle, setDbOfflineProductHandle] = useState([]);
    const [dbTransferProductHandle, setDbTransferProductHandle] = useState([]);
    const [dbDomesticProductHandle, setDbDomesticProductHandle] = useState([]);
    const [dbDeliveryProductHandle, setDbDeliveryProductHandle] = useState([]);

    // add product form
    const [cartProductHandle, setCartProductHandle] = useState([]);
    const [orderProductHandle, setOrderProductHandle] = useState([]);
    const [offlineProductHandle, setOfflineProductHandle] = useState([]);
    const [transferProductHandle, setTransferProductHandle] = useState([]);
    const [domesticProductHandle, setDomesticProductHandle] = useState([]);
    const [deliveryProductHandle, setDeliveryProductHandle] = useState([]);

    // order addForm & dbForm merged
    const mergedCartProducts = [...dbCartProductHandle, ...cartProductHandle];
    const mergedOrderProducts = [...dbOrderProductHandle, ...orderProductHandle];
    const mergedOfflineProducts = [...dbOfflineProductHandle, ...offlineProductHandle];
    const mergedTransferProducts = [...dbTransferProductHandle, ...transferProductHandle];
    const mergedDomesticProducts = [...dbDomesticProductHandle, ...domesticProductHandle];
    const mergedDeliveryProducts = [...dbDeliveryProductHandle, ...deliveryProductHandle];

    const excelFileSelectRefCart = useRef(null);
    const excelFileSelectRefOrder = useRef(null);
    const excelFileSelectRefOffline = useRef(null);
    const excelFileSelectRefTransfer = useRef(null);
    const excelFileSelectRefDomestic = useRef(null);
    const excelFileSelectRefDelivery = useRef(null);

    const [invoiceFile, setInvoiceFile] = useState(null);
    const [fileName, setFileName] = useState("파일선택");
    const [fileUrl, setFileUrl] = useState(null);

    // const [methodPayPaypal, setMethodPayPaypal] = useState(false);
    // const [methodPayWechat, setMethodPayWechat] = useState(false);
    // const [methodPayAli, setMethodPayAli] = useState(false);
    // const [methodPayNobank, setMethodPayNobank] = useState(false);
    // const [methodPayDeposit, setMethodPayDeposit] = useState(false);
    // const [methodPayPoint, setMethodPayPoint] = useState(false);
    const [payWay, setPayWay] = useState('');
    const [readyingPopupSt, setReadyingPopupSt] = useState(false);
    const dollarList = ['USD', 'CAD', 'AUD', 'NZD', 'EUR', 'JPY', 'SGD']
    const [currency, setCurrency] = useState('USD');
    const [lastForPrice, setLastForPrice] = useState(0);

    const [dbFormTotalPrices, setDbFormTotalPrices] = useState(0);
    const [dbFormTotalMallShipPrice, setDbFormTotalMallShipPrice] = useState(0);
    const [dbFormTotalFee, setDbFormTotalFee] = useState(0);
    const [dbFormTotalQuantity, setDbFormTotalQuantity] = useState(0);
    const [dbFormTotalOverseasShippingExpense, setDbFormTotalOverseasShippingExpense] = useState(0);
    const [dbFormTotalEtcExpense, setDbFormTotalEtcExpense] = useState(0);

    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalPrices, setTotalPrices] = useState(0);
    const [totalAdminProdPrice, setTotalAdminProdPrice] = useState(0);
    const [totalMallShipPrice, setTotalMallShipPrice] = useState(0);
    const [totalFee, setTotalFee] = useState(0);
    const [totalOverseasShippingExpense, setTotalOverseasShippingExpense] = useState(0);
    const [totalEtcExpense, setTotalEtcExpense] = useState(0);
    const [pointSale, setPointSale] = useState('');
    const [depositSale, setDepositSale] = useState('');
    const [couponSale, setCouponSale] = useState('');

    const finalQuantity = (totalQuantity + dbFormTotalQuantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const finalProdExpense = (totalPrices + dbFormTotalPrices + totalAdminProdPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const finalMallShipPrice = (totalMallShipPrice + dbFormTotalMallShipPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const finalFee = (totalFee + dbFormTotalFee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const finalOverseasShipExpense = (totalOverseasShippingExpense + dbFormTotalOverseasShippingExpense).toLocaleString('en-US');
    const finalEtcExpense = (totalEtcExpense + dbFormTotalEtcExpense);

    const finalPayExpense = (totalPrices + dbFormTotalPrices + totalAdminProdPrice + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
        totalOverseasShippingExpense + finalEtcExpense - (Number(pointSale) + Number(depositSale) + Number(couponSale))).toLocaleString('en-US');

    const [allAgreeCheck, setAllAgreeCheck] = useState(false);
    // const [infoCollectionAgreeCheck, setInfoCollectionAgreeCheck] = useState(0);
    const [cartServiceAgreeCheck, setCartServiceAgreeCheck] = useState(false);
    const [orderServiceAgreeCheck, setOrderServiceAgreeCheck] = useState(false);
    const [offlineServiceAgreeCheck, setOfflineServiceAgreeCheck] = useState(false);
    const [transferServiceAgreeCheck, setTransferServiceAgreeCheck] = useState(false);
    const [domesticServiceAgreeCheck, setDomesticServiceAgreeCheck] = useState(false);
    const [deliveryServiceAgreeCheck, setDeliveryServiceAgreeCheck] = useState(false);

    // new product handle
    const newCartForm = () => {
        setNewCartFormSt(true);
        addCartProduct();
        if (cartProductHandle.length > 0) {
            setNewCartBtShow(false);
        }
    };

    const addCartProduct = () => {
        setCartProductHandle([...cartProductHandle, {}]);
        setCartOrderAccordion(true);
    };

    const deleteCartProduct = (index) => {
        setCartProductHandle(cartProductHandle.filter((_, i) => i !== index));
        if (cartProductHandle.length === 0 && dbCartProductHandle.length === 0) {
            setCartOrderAccordion(false);
            setCartServiceAgreeCheck(false);
        }
        if (cartProductHandle.length === 0) {
            setNewCartBtShow(true);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        updatePayinfoAddform();
    }

    const copyCartProduct = (index) => {
        setCartProductHandle((prevState) => {
            const copiedProduct = {...prevState[index]};
            return [...prevState, copiedProduct];
        });
        updatePayinfoAddform();
    };

    const handleCartProductInput = (index, field, value) => {
        const updatedProducts = [...cartProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value, newProd: true};
        setCartProductHandle(updatedProducts);
        updatePayinfoAddform();
    };

    const cartQuantityReplaceInput = (cartSeqnum, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleCartQuantityChange(cartSeqnum, onlyNumbers);
    };
    const handleCartQuantityChange = (index, newValue) => {
        const updatedCartProductHandle = cartProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue, newProd: true};
            }
            return item;
        });
        setCartProductHandle(updatedCartProductHandle);
    };

    const cartNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleCartPriceChange = (index, newValue) => {
        const updatedCartProductHandle = cartProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue, newProd: true};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setCartProductHandle(updatedCartProductHandle);
    };

    const cartPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = cartNumberWithComma(onlyNumbers);
        handleCartPriceChange(index, formattedValue);
    };

    const cartUploadInputChange = (index, key, value) => {
        const updatedRows = [...cartProductHandle];
        updatedRows[index][key] = value;
        setCartProductHandle(updatedRows);
    };

    const cartQuantityNumberInput = (index, field, value) => {
        cartUploadInputChange(index, field, value);
        handleCartProductInput(index, field, value);
        cartQuantityReplaceInput(index, value);
    }

    const cartPriceNumberInput = (index, field, value) => {
        cartUploadInputChange(index, field, value);
        handleCartProductInput(index, field, value);
        cartPriceReplaceInput(index, value);
    }

    const useCartItemsRefs = (cartProductHandle) => {
        const cartItemsRefs = useRef(
            cartProductHandle.map(() => ({
                name: null,
                option: null,
                quantity: null,
                price: null,
                url: null,
            }))
        );

        const initializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                option: null,
                quantity: null,
                price: null,
                url: null,
            }));
        };

        useMemo(() => {
            cartItemsRefs.current = initializeRefs(cartProductHandle.length);
        }, [cartProductHandle.length]);

        return cartItemsRefs;
    };
    const cartItemsRefs = useCartItemsRefs(cartProductHandle);

    const scrollToEmptyFieldCart = () => {
        let isValid = true;
        for (let i = 0; i < cartProductHandle.length; i++) {
            if (!cartProductHandle[i].name || !cartProductHandle[i].option || !cartProductHandle[i].quantity || !cartProductHandle[i].price || !cartProductHandle[i].url) {
                const emptyField = cartItemsRefs.current[i];
                if (!cartProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!cartProductHandle[i].option) {
                    emptyField.option.scrollIntoView({behavior: 'smooth'});
                    emptyField.option.focus();
                } else if (!cartProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!cartProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.url.scrollIntoView({behavior: 'smooth'});
                    emptyField.url.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const deleteDbCartProduct = (index) => {
        setDbCartProductHandle((prevState) => {
            const updatedState = prevState.filter((product, i) => {
                if (i === index) {
                    setDeletedProductsData((prevDeleted) => ({
                        ...prevDeleted,
                        deletedDbCartProducts: [...prevDeleted.deletedDbCartProducts, product],
                    }));
                }
                return i !== index;
            });
            if (updatedState.length === 0 && dbCartProductHandle.length === 0) {
                setCartOrderAccordion(false);
                setCartServiceAgreeCheck(false);
            }
            return updatedState;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        updatePayinfoAddform();
    };

    const handleDbCartProductInput = (index, field, value) => {
        const updatedProducts = [...dbCartProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDbCartProductHandle(updatedProducts);
        updatePayinfoDbform();
    };
    const dbCartQuantityReplaceInput = (cartSeqnum, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDbCartQuantityChange(cartSeqnum, onlyNumbers);
    };
    const handleDbCartQuantityChange = (index, newValue) => {
        const updatedCartProductHandle = dbCartProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setDbCartProductHandle(updatedCartProductHandle);
    };
    const handleDbCartPriceChange = (index, newValue) => {
        const updatedCartProductHandle = dbCartProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbCartProductHandle(updatedCartProductHandle);
    };

    const dbCartPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = cartNumberWithComma(onlyNumbers);
        handleDbCartPriceChange(index, formattedValue);
    };

    const dbCartQuantityNumberInput = (index, field, value) => {
        handleDbCartProductInput(index, field, value);
        dbCartQuantityReplaceInput(index, value);
    }

    const dbCartPriceNumberInput = (index, field, value) => {
        handleDbCartProductInput(index, field, value);
        dbCartPriceReplaceInput(index, value);
    }

    const useDbCartItemsRefs = (dbCartProductHandle) => {
        const dbCartItemsRefs = useRef(
            dbCartProductHandle.map(() => ({
                name: null,
                option: null,
                quantity: null,
                price: null,
                url: null,
            }))
        );

        const dbCartInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                option: null,
                quantity: null,
                price: null,
                url: null,
            }));
        };

        useMemo(() => {
            dbCartItemsRefs.current = dbCartInitializeRefs(cartProductHandle.length);
        }, [dbCartProductHandle.length]);

        return cartItemsRefs;
    };
    const dbCartItemsRefs = useDbCartItemsRefs(dbCartProductHandle);

    const scrollToEmptyFieldDbCart = () => {
        let isValid = true;
        if (dbCartProductHandle.length > 0) {
            for (let i = 0; i < cartProductHandle.length; i++) {
                if (!dbCartProductHandle[i].name || !dbCartProductHandle[i].option || !dbCartProductHandle[i].quantity || !dbCartProductHandle[i].price || !dbCartProductHandle[i].url) {
                    const emptyField = dbCartItemsRefs.current[i];
                    if (!dbCartProductHandle[i].name) {
                        emptyField.name.scrollIntoView({behavior: 'smooth'});
                        emptyField.name.focus();
                    } else if (!dbCartProductHandle[i].option) {
                        emptyField.option.scrollIntoView({behavior: 'smooth'});
                        emptyField.option.focus();
                    } else if (!dbCartProductHandle[i].quantity) {
                        emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                        emptyField.quantity.focus();
                    } else if (!dbCartProductHandle[i].price) {
                        emptyField.price.scrollIntoView({behavior: 'smooth'});
                        emptyField.price.focus();
                    } else {
                        emptyField.url.scrollIntoView({behavior: 'smooth'});
                        emptyField.url.focus();
                    }
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    };

    // new product handle
    const newOrderForm = () => {
        setNewOrderFormSt(true);
        addOrderProduct();
        if (orderProductHandle.length > 0) {
            setNewOrderBtShow(false);
        }
    };

    const addOrderProduct = () => {
        setOrderProductHandle([...orderProductHandle, {}]);
        setOrderAccordion(true);
    };
    const deleteOrderProduct = (index) => {
        setOrderProductHandle(orderProductHandle.filter((_, i) => i !== index));
        if (orderProductHandle.length === 0 && dbOrderProductHandle.length === 0) {
            setOrderAccordion(false);
            setOrderServiceAgreeCheck(false);
        }
        if (orderProductHandle.length === 0) {
            setNewOrderBtShow(true);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        updatePayinfoAddform();
    }
    const handleOrderProductInput = (index, field, value) => {
        const updatedProducts = [...orderProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value, newProd: true};
        setOrderProductHandle(updatedProducts);
        updatePayinfoAddform();
    };
    const copyOrderProduct = (index) => {
        const copiedProduct = {...orderProductHandle[index]};
        setOrderProductHandle([...orderProductHandle, copiedProduct]);
        updatePayinfoAddform();
    };

    const orderQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleOrderQuantityChange(index, onlyNumbers);
    };
    const handleOrderQuantityChange = (index, newValue) => {
        const updatedOrderProductHandle = orderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue, newProd: true};
            }
            return item;
        });
        setOrderProductHandle(updatedOrderProductHandle);
    };

    const orderNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleOrderPriceChange = (index, newValue) => {
        const updatedOrderProductHandle = orderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue, newProd: true};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOrderProductHandle(updatedOrderProductHandle);
    };
    const handleOrderMallpriceChange = (index, newValue) => {
        const updatedOrderProductHandle = orderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, mallShipPrice: newValue, newProd: true};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOrderProductHandle(updatedOrderProductHandle);
    };

    const orderPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = orderNumberWithComma(onlyNumbers);
        handleOrderPriceChange(index, formattedValue);
    };
    const orderMallpriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = orderNumberWithComma(onlyNumbers);
        handleOrderMallpriceChange(index, formattedValue);
    };

    const orderUploadInputChange = (index, key, value) => {
        const updatedRows = [...orderProductHandle];
        updatedRows[index][key] = value;
        setOrderProductHandle(updatedRows);
    };

    const orderQuantityNumberInput = (index, field, value) => {
        orderUploadInputChange(index, field, value);
        handleOrderProductInput(index, field, value);
        orderQuantityReplaceInput(index, value);
    }

    const orderPriceNumberInput = (index, field, value) => {
        orderUploadInputChange(index, field, value);
        handleOrderProductInput(index, field, value);
        orderPriceReplaceInput(index, value);
    }
    const orderMallpricericeNumberInput = (index, field, value) => {
        orderUploadInputChange(index, field, value);
        handleOrderProductInput(index, field, value);
        orderMallpriceReplaceInput(index, value);
    }

    const useOrderItemsRefs = (orderProductHandle) => {
        const orderItemsRefs = useRef(
            orderProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                url: null,
            }))
        );

        const orderInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                url: null,
            }));
        };

        useMemo(() => {
            orderItemsRefs.current = orderInitializeRefs(orderProductHandle.length);
        }, [orderProductHandle.length]);

        return orderItemsRefs;
    };
    const orderItemsRefs = useOrderItemsRefs(orderProductHandle);

    const scrollToEmptyFieldOrder = () => {
        let isValid = true;
        for (let i = 0; i < orderProductHandle.length; i++) {
            if (!orderProductHandle[i].name || !orderProductHandle[i].quantity || !orderProductHandle[i].price || !orderProductHandle[i].url) {
                const emptyField = orderItemsRefs.current[i];
                if (!orderProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!orderProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!orderProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.url.scrollIntoView({behavior: 'smooth'});
                    emptyField.url.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const deleteDbOrderProduct = (index) => {
        setDbOrderProductHandle((prevState) => {
            const updatedState = prevState.filter((product, i) => {
                if (i === index) {
                    setDeletedProductsData((prevDeleted) => ({
                        ...prevDeleted,
                        deletedDbOrderProducts: [...prevDeleted.deletedDbOrderProducts, product],
                    }));
                }
                return i !== index;
            });
            if (updatedState.length === 0 && dbOrderProductHandle.length === 0) {
                setOrderAccordion(false);
                setOrderServiceAgreeCheck(false);
            }
            return updatedState;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        updatePayinfoAddform();
    };

    const handleDbOrderProductInput = (index, field, value) => {
        const updatedProducts = [...dbOrderProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDbOrderProductHandle(updatedProducts);
        updatePayinfoDbform();
    };
    const dbOrderQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDbOrderQuantityChange(index, onlyNumbers);
    };
    const handleDbOrderQuantityChange = (index, newValue) => {
        const updatedOrderProductHandle = dbOrderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setDbOrderProductHandle(updatedOrderProductHandle);
    };
    const handleDbOrderPriceChange = (index, newValue) => {
        const updatedOrderProductHandle = dbOrderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbOrderProductHandle(updatedOrderProductHandle);
    };
    const handleDbOrderMallpriceChange = (index, newValue) => {
        const updatedOrderProductHandle = dbOrderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, mallShipPrice: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbOrderProductHandle(updatedOrderProductHandle);
    };

    const dbOrderPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = orderNumberWithComma(onlyNumbers);
        handleDbOrderPriceChange(index, formattedValue);
    };
    const dbOrderMallpriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = orderNumberWithComma(onlyNumbers);
        handleDbOrderMallpriceChange(index, formattedValue);
    };
    const dbOrderQuantityNumberInput = (index, field, value) => {
        handleDbOrderProductInput(index, field, value);
        dbOrderQuantityReplaceInput(index, value);
    }

    const dbOrderPriceNumberInput = (index, field, value) => {
        handleDbOrderProductInput(index, field, value);
        dbOrderPriceReplaceInput(index, value);
    }
    const dbOrderMallpricericeNumberInput = (index, field, value) => {
        handleDbOrderProductInput(index, field, value);
        dbOrderMallpriceReplaceInput(index, value);
    }

    const useDbOrderItemsRefs = (dbOrderProductHandle) => {
        const dbOrderItemsRefs = useRef(
            dbOrderProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                url: null,
            }))
        );

        const dbOrderInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                url: null,
            }));
        };

        useMemo(() => {
            dbOrderItemsRefs.current = dbOrderInitializeRefs(dbOrderProductHandle.length);
        }, [dbOrderProductHandle.length]);

        return dbOrderItemsRefs;
    };
    const dbOrderItemsRefs = useDbOrderItemsRefs(dbOrderProductHandle);

    const scrollToEmptyFieldDbOrder = () => {
        let isValid = true;
        for (let i = 0; i < dbOrderProductHandle.length; i++) {
            if (!dbOrderProductHandle[i].name || !dbOrderProductHandle[i].quantity || !dbOrderProductHandle[i].price || !dbOrderProductHandle[i].url) {
                const emptyField = dbOrderItemsRefs.current[i];
                if (!dbOrderProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!dbOrderProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!dbOrderProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.url.scrollIntoView({behavior: 'smooth'});
                    emptyField.url.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    // new product handle
    const newOfflineForm = () => {
        setNewOfflineFormSt(true);
        addOfflineProduct();
        if (offlineProductHandle.length > 0) {
            setNewOfflineBtShow(false);
        }
    };

    const addOfflineProduct = () => {
        setOfflineProductHandle([...offlineProductHandle, {}]);
        setOfflineAccordion(true);
    };
    const deleteOfflineProduct = (index) => {
        setOfflineProductHandle(offlineProductHandle.filter((_, i) => i !== index));
        if (offlineProductHandle.length === 0 && dbOfflineProductHandle.length === 0) {
            setOfflineAccordion(false);
            setOfflineServiceAgreeCheck(false);
        }
        if (offlineProductHandle.length === 0) {
            setNewOfflineBtShow(true);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
    }
    const handleOfflineProductInput = (index, field, value) => {
        const updatedProducts = [...offlineProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value, newProd: true};
        setOfflineProductHandle(updatedProducts);
    };
    const copyOfflineProduct = (index) => {
        const copiedProduct = {...offlineProductHandle[index]};
        setOfflineProductHandle([...offlineProductHandle, copiedProduct]);
    };

    const offlineQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleOfflineQuantityChange(index, onlyNumbers);
    };
    const handleOfflineQuantityChange = (index, newValue) => {
        const updatedOfflineProductHandle = offlineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue, newProd: true};
            }
            return item;
        });
        setOfflineProductHandle(updatedOfflineProductHandle);
    };

    const offlineNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleOfflinePriceChange = (index, newValue) => {
        const updatedOfflineProductHandle = offlineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue, newProd: true};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOfflineProductHandle(updatedOfflineProductHandle);
    };

    const offlinePriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = offlineNumberWithComma(onlyNumbers);
        handleOfflinePriceChange(index, formattedValue);
    };

    const handleOfflineFeeChange = (index, newValue) => {
        const updatedOfflineProductHandle = offlineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue, newProd: true};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOfflineProductHandle(updatedOfflineProductHandle);
    };

    const offlineFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = offlineNumberWithComma(onlyNumbers);
        handleOfflineFeeChange(index, formattedValue);
    };

    const offlineUploadInputChange = (index, key, value) => {
        const updatedRows = [...offlineProductHandle];
        updatedRows[index][key] = value;
        setOfflineProductHandle(updatedRows);
    };

    const offlineQuantityNumberInput = (index, field, value) => {
        offlineUploadInputChange(index, field, value);
        handleOfflineProductInput(index, field, value);
        offlineQuantityReplaceInput(index, value);
    }

    const offlinePriceNumberInput = (index, field, value) => {
        offlineUploadInputChange(index, field, value);
        handleOfflineProductInput(index, field, value);
        offlinePriceReplaceInput(index, value);
    }
    const offlineFeeNumberInput = (index, field, value) => {
        offlineUploadInputChange(index, field, value);
        handleOfflineProductInput(index, field, value);
        offlineFeeReplaceInput(index, value);
    }

    const useOfflineItemsRefs = (offlineProductHandle) => {
        const offlineItemsRefs = useRef(
            offlineProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
            }))
        );

        const offlineInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
            }));
        };

        useMemo(() => {
            offlineItemsRefs.current = offlineInitializeRefs(offlineProductHandle.length);
        }, [offlineProductHandle.length]);

        return offlineItemsRefs;
    };
    const offlineItemsRefs = useOfflineItemsRefs(offlineProductHandle);

    const scrollToEmptyFieldOffline = () => {
        let isValid = true;
        for (let i = 0; i < offlineProductHandle.length; i++) {
            if (!offlineProductHandle[i].name || !offlineProductHandle[i].quantity || !offlineProductHandle[i].price || !offlineProductHandle[i].fee) {
                const emptyField = offlineItemsRefs.current[i];
                if (!offlineProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!offlineProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!offlineProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const deleteDbOfflineProduct = (index) => {
        setDbOfflineProductHandle((prevState) => {
            const updatedState = prevState.filter((product, i) => {
                if (i === index) {
                    setDeletedProductsData((prevDeleted) => ({
                        ...prevDeleted,
                        deletedDbOfflineProducts: [...prevDeleted.deletedDbOfflineProducts, product],
                    }));
                }
                return i !== index;
            });
            if (updatedState.length === 0 && dbOfflineProductHandle.length === 0) {
                setOfflineAccordion(false);
                setOfflineServiceAgreeCheck(false);
            }
            return updatedState;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        updatePayinfoAddform();
    };

    const handleDbOfflineProductInput = (index, field, value) => {
        const updatedProducts = [...dbOfflineProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDbOfflineProductHandle(updatedProducts);
    };
    const dbOfflineQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDbOfflineQuantityChange(index, onlyNumbers);
    };
    const handleDbOfflineQuantityChange = (index, newValue) => {
        const updatedOfflineProductHandle = dbOfflineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setDbOfflineProductHandle(updatedOfflineProductHandle);
    };
    const handleDbOfflinePriceChange = (index, newValue) => {
        const updatedOfflineProductHandle = dbOfflineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbOfflineProductHandle(updatedOfflineProductHandle);
    };

    const dbOfflinePriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = offlineNumberWithComma(onlyNumbers);
        handleDbOfflinePriceChange(index, formattedValue);
    };

    const handleDbOfflineFeeChange = (index, newValue) => {
        const updatedOfflineProductHandle = dbOfflineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbOfflineProductHandle(updatedOfflineProductHandle);
    };

    const dbOfflineFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = offlineNumberWithComma(onlyNumbers);
        handleDbOfflineFeeChange(index, formattedValue);
    };
    const dbOfflineQuantityNumberInput = (index, field, value) => {
        handleDbOfflineProductInput(index, field, value);
        dbOfflineQuantityReplaceInput(index, value);
    }

    const dbOfflinePriceNumberInput = (index, field, value) => {
        handleDbOfflineProductInput(index, field, value);
        dbOfflinePriceReplaceInput(index, value);
    }
    const dbOfflineFeeNumberInput = (index, field, value) => {
        handleDbOfflineProductInput(index, field, value);
        dbOfflineFeeReplaceInput(index, value);
    }

    const useDbOfflineItemsRefs = (dbOfflineProductHandle) => {
        const dbOfflineItemsRefs = useRef(
            dbOfflineProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
            }))
        );

        const dbOfflineInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
            }));
        };

        useMemo(() => {
            dbOfflineItemsRefs.current = dbOfflineInitializeRefs(dbOfflineProductHandle.length);
        }, [dbOfflineProductHandle.length]);

        return dbOfflineItemsRefs;
    };
    const dbOfflineItemsRefs = useDbOfflineItemsRefs(dbOfflineProductHandle);

    const scrollToEmptyFieldDbOffline = () => {
        let isValid = true;
        for (let i = 0; i < dbOfflineProductHandle.length; i++) {
            if (!dbOfflineProductHandle[i].name || !dbOfflineProductHandle[i].quantity || !dbOfflineProductHandle[i].price || !dbOfflineProductHandle[i].fee) {
                const emptyField = dbOfflineItemsRefs.current[i];
                if (!dbOfflineProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!dbOfflineProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!dbOfflineProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    // new product handle
    const newTransferForm = () => {
        setNewTransferFormSt(true);
        addTransferProduct();
        if (transferProductHandle.length > 0) {
            setNewTransferBtShow(false);
        }
    };

    const addTransferProduct = () => {
        setTransferProductHandle([...transferProductHandle, {}]);
        setTransferAccordion(true);
    };
    const deleteTransferProduct = (index) => {
        setTransferProductHandle(transferProductHandle.filter((_, i) => i !== index));
        if (transferProductHandle.length === 0 && dbTransferProductHandle.length === 0) {
            setTransferAccordion(false);
            setTransferServiceAgreeCheck(false);
        }
        if (transferProductHandle.length === 0) {
            setNewTransferBtShow(true);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
    }
    const handleTransferProductInput = (index, field, value) => {
        const updatedProducts = [...transferProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value, newProd: true};
        setTransferProductHandle(updatedProducts);
    };
    const copyTransferProduct = (index) => {
        const copiedProduct = {...transferProductHandle[index]};
        setTransferProductHandle([...transferProductHandle, copiedProduct]);
    };

    const transferNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number;
    };

    const handleTransferPriceChange = (index, newValue) => {
        const updatedTransferProductHandle = transferProductHandle.map((item, idx) => {
            if (idx === index) {
                const rawCommission = newValue * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭
                return {
                    ...item,
                    price: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    newProd: true,
                    fee: (roundedCommission).toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setTransferProductHandle(updatedTransferProductHandle);
    };

    const transferPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = transferNumberWithComma(onlyNumbers);
        handleTransferPriceChange(index, formattedValue);
    };

    const handleTransferFeeChange = (index, newValue) => {
        const updatedTransferProductHandle = transferProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, newProd: true, fee: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setTransferProductHandle(updatedTransferProductHandle);
    };

    const transferFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = transferNumberWithComma(onlyNumbers);
        handleTransferFeeChange(index, formattedValue);
    };

    const transferUploadInputChange = (index, key, value) => {
        const updatedRows = [...transferProductHandle];
        updatedRows[index][key] = value;
        setTransferProductHandle(updatedRows);
    };

    const transferPriceNumberInput = (index, field, value) => {
        transferUploadInputChange(index, field, value);
        handleTransferProductInput(index, field, value);
        transferPriceReplaceInput(index, value);
    }

    const transferFeeNumberInput = (index, field, value) => {
        transferUploadInputChange(index, field, value);
        handleTransferProductInput(index, field, value);
        transferFeeReplaceInput(index, value);
    }

    const useTransferItemsRefs = (transferProductHandle) => {
        const transferItemsRefs = useRef(
            transferProductHandle.map(() => ({
                name: null,
                price: null,
                fee: null,
            }))
        );

        const transferInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                price: null,
                fee: null,
            }));
        };

        useMemo(() => {
            transferItemsRefs.current = transferInitializeRefs(transferProductHandle.length);
        }, [transferProductHandle.length]);

        return transferItemsRefs;
    };
    const transferItemsRefs = useTransferItemsRefs(transferProductHandle);

    const scrollToEmptyFieldTransfer = () => {
        let isValid = true;
        for (let i = 0; i < transferProductHandle.length; i++) {
            if (!transferProductHandle[i].name || !transferProductHandle[i].price || !transferProductHandle[i].fee) {
                const emptyField = transferItemsRefs.current[i];
                if (!transferProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!transferProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const deleteDbTransferProduct = (index) => {
        setDbTransferProductHandle((prevState) => {
            const updatedState = prevState.filter((product, i) => {
                if (i === index) {
                    setDeletedProductsData((prevDeleted) => ({
                        ...prevDeleted,
                        deletedDbTransferProducts: [...prevDeleted.deletedDbTransferProducts, product],
                    }));
                }
                return i !== index;
            });
            if (updatedState.length === 0 && dbTransferProductHandle.length === 0) {
                setTransferAccordion(false);
                setTransferServiceAgreeCheck(false);
            }
            return updatedState;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        updatePayinfoAddform();
    };

    const handleDbTransferProductInput = (index, field, value) => {
        const updatedProducts = [...dbTransferProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDbTransferProductHandle(updatedProducts);
    };
    const handleDbTransferPriceChange = (index, newValue) => {
        const updatedTransferProductHandle = dbTransferProductHandle.map((item, idx) => {
            if (idx === index) {
                const rawCommission = newValue * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭
                return {
                    ...item,
                    price: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    fee: (roundedCommission).toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbTransferProductHandle(updatedTransferProductHandle);
    };

    const dbTransferPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = transferNumberWithComma(onlyNumbers);
        handleDbTransferPriceChange(index, formattedValue);
    };

    const handleDbTransferFeeChange = (index, newValue) => {
        const updatedTransferProductHandle = dbTransferProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbTransferProductHandle(updatedTransferProductHandle);
    };

    const dbTransferFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = transferNumberWithComma(onlyNumbers);
        handleDbTransferFeeChange(index, formattedValue);
    };
    const dbTransferPriceNumberInput = (index, field, value) => {
        handleDbTransferProductInput(index, field, value);
        dbTransferPriceReplaceInput(index, value);
    }

    const dbTransferFeeNumberInput = (index, field, value) => {
        handleDbTransferProductInput(index, field, value);
        dbTransferFeeReplaceInput(index, value);
    }

    const useDbTransferItemsRefs = (dbTransferProductHandle) => {
        const dbTransferItemsRefs = useRef(
            dbTransferProductHandle.map(() => ({
                name: null,
                price: null,
                fee: null,
            }))
        );

        const dbTransferInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                price: null,
                fee: null,
            }));
        };

        useMemo(() => {
            dbTransferItemsRefs.current = dbTransferInitializeRefs(dbTransferProductHandle.length);
        }, [transferProductHandle.length]);

        return dbTransferItemsRefs;
    };
    const dbTransferItemsRefs = useDbTransferItemsRefs(dbTransferProductHandle);

    const scrollToEmptyFieldDbTransfer = () => {
        let isValid = true;
        for (let i = 0; i < dbTransferProductHandle.length; i++) {
            if (!dbTransferProductHandle[i].name || !dbTransferProductHandle[i].price || !dbTransferProductHandle[i].fee) {
                const emptyField = transferItemsRefs.current[i];
                if (!dbTransferProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!dbTransferProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    // new product handle
    const newDomesticForm = () => {
        setNewDomesticFormSt(true);
        addDomesticProduct();
        if (domesticProductHandle.length > 0) {
            setNewDomesticBtShow(false);
        }
    };

    const addDomesticProduct = () => {
        setDomesticProductHandle([...domesticProductHandle, {}]);
        setDomesticAccordion(true);
    };
    const deleteDomesticProduct = (index) => {
        setDomesticProductHandle(domesticProductHandle.filter((_, i) => i !== index));
        if (domesticProductHandle.length === 0 && dbDomesticProductHandle.length === 0) {
            setDomesticAccordion(false);
            setDomesticServiceAgreeCheck(false);
        }
        if (domesticProductHandle.length === 0) {
            setNewDomesticBtShow(true);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
    }
    const handleDomesticProductInput = (index, field, value) => {
        const updatedProducts = [...domesticProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value, newProd: true};
        setDomesticProductHandle(updatedProducts);
    };
    const copyDomesticProduct = (index) => {
        const copiedProduct = {...domesticProductHandle[index]};
        setDomesticProductHandle([...domesticProductHandle, copiedProduct]);
    };

    const domesticQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDomesticQuantityChange(index, onlyNumbers);
    };
    const handleDomesticQuantityChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue, newProd: true};
            }
            return item;
        });
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const domesticNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number;
    };

    const handleDomesticPriceChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                const price = parseInt(newValue) || 0;
                const mallShipPrice = parseInt((item.mallShipPrice || "").toString().replace(/,/g, '')) || 0;
                const total = mallShipPrice + price;

                const rawCommission = total * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                return {
                    ...item,
                    price: (newValue || "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    newProd: true,
                    fee: roundedCommission.toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const handleDomesticMallpriceChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                const mallShipPrice = parseInt(newValue) || 0;
                const price = parseInt(item.price.replace(/,/g, '')) || 0;
                const total = price + mallShipPrice;

                const rawCommission = total * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                return {
                    ...item,
                    mallShipPrice: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    newProd: true,
                    fee: roundedCommission.toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const handleDomesticFeeChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), newProd: true};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const domesticPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDomesticPriceChange(index, formattedValue);
    };

    const domesticMallpriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDomesticMallpriceChange(index, formattedValue);
    };

    const domesticFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDomesticFeeChange(index, formattedValue);
    };

    const domesticUploadInputChange = (index, key, value) => {
        const updatedRows = [...domesticProductHandle];
        updatedRows[index][key] = value;
        setDomesticProductHandle(updatedRows);
    };

    const domesticQuantityNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticQuantityReplaceInput(index, value);
    }

    const domesticPriceNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticPriceReplaceInput(index, value);
    }

    const domesticMallpriceNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticMallpriceReplaceInput(index, value);
    }

    const domesticFeeNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticFeeReplaceInput(index, value);
    }

    const useDomesticItemsRefs = (domesticProductHandle) => {
        const domesticItemsRefs = useRef(
            domesticProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
                url: null,
            }))
        );

        const domesticInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
                url: null,
            }));
        };

        useMemo(() => {
            domesticItemsRefs.current = domesticInitializeRefs(domesticProductHandle.length);
        }, [domesticProductHandle.length]);

        return domesticItemsRefs;
    };
    const domesticItemsRefs = useDomesticItemsRefs(domesticProductHandle);

    const scrollToEmptyFieldDomestic = () => {
        let isValid = true;
        for (let i = 0; i < domesticProductHandle.length; i++) {
            if (!domesticProductHandle[i].name || !domesticProductHandle[i].quantity || !domesticProductHandle[i].price || !domesticProductHandle[i].fee || !domesticProductHandle[i].url) {
                const emptyField = domesticItemsRefs.current[i];
                if (!domesticProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!domesticProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!domesticProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else if (!domesticProductHandle[i].fee) {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                } else {
                    emptyField.url.scrollIntoView({behavior: 'smooth'});
                    emptyField.url.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const deleteDbDomesticProduct = (index) => {
        setDbDomesticProductHandle((prevState) => {
            const updatedState = prevState.filter((product, i) => {
                if (i === index) {
                    setDeletedProductsData((prevDeleted) => ({
                        ...prevDeleted,
                        deletedDbDomesticProducts: [...prevDeleted.deletedDbDomesticProducts, product],
                    }));
                }
                return i !== index;
            });
            if (updatedState.length === 0 && dbDomesticProductHandle.length === 0) {
                setDomesticAccordion(false);
                setDomesticServiceAgreeCheck(false);
            }
            return updatedState;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        updatePayinfoAddform();
    };

    const handleDbDomesticProductInput = (index, field, value) => {
        const updatedProducts = [...dbDomesticProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDbDomesticProductHandle(updatedProducts);
    };
    const dbDomesticQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDbDomesticQuantityChange(index, onlyNumbers);
    };
    const handleDbDomesticQuantityChange = (index, newValue) => {
        const updatedDomesticProductHandle = dbDomesticProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setDbDomesticProductHandle(updatedDomesticProductHandle);
    };

    const handleDbDomesticPriceChange = (index, newValue) => {
        const updatedDomesticProductHandle = dbDomesticProductHandle.map((item, idx) => {
            if (idx === index) {
                const price = parseInt(newValue) || 0;
                const mallShipPrice = parseInt(item.mallShipPrice.toString().replace(/,/g, '')) || 0;
                const total = mallShipPrice + price;

                const rawCommission = total * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                return {
                    ...item,
                    price: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    fee: roundedCommission.toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbDomesticProductHandle(updatedDomesticProductHandle);
    };

    const handleDbDomesticMallpriceChange = (index, newValue) => {
        const updatedDomesticProductHandle = dbDomesticProductHandle.map((item, idx) => {
            if (idx === index) {
                const mallShipPrice = parseInt(newValue) || 0;
                const price = parseInt(item.price.toString().replace(/,/g, '')) || 0;
                const total = price + mallShipPrice;

                const rawCommission = total * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                return {
                    ...item,
                    mallShipPrice: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    fee: roundedCommission.toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbDomesticProductHandle(updatedDomesticProductHandle);
    };

    const handleDbDomesticFeeChange = (index, newValue) => {
        const updatedDomesticProductHandle = dbDomesticProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDbDomesticProductHandle(updatedDomesticProductHandle);
    };

    const dbDomesticPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDbDomesticPriceChange(index, formattedValue);
    };

    const dbDomesticMallpriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDbDomesticMallpriceChange(index, formattedValue);
    };

    const dbDomesticFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDbDomesticFeeChange(index, formattedValue);
    };
    const dbDomesticQuantityNumberInput = (index, field, value) => {
        handleDbDomesticProductInput(index, field, value);
        dbDomesticQuantityReplaceInput(index, value);
    }

    const dbDomesticPriceNumberInput = (index, field, value) => {
        handleDbDomesticProductInput(index, field, value);
        dbDomesticPriceReplaceInput(index, value);
    }

    const dbDomesticMallpriceNumberInput = (index, field, value) => {
        handleDbDomesticProductInput(index, field, value);
        dbDomesticMallpriceReplaceInput(index, value);
    }

    const dbDomesticFeeNumberInput = (index, field, value) => {
        handleDbDomesticProductInput(index, field, value);
        dbDomesticFeeReplaceInput(index, value);
    }

    const useDbDomesticItemsRefs = (dbDomesticProductHandle) => {
        const dbDomesticItemsRefs = useRef(
            dbDomesticProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
                url: null,
            }))
        );

        const dbDomesticInitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
                url: null,
            }));
        };

        useMemo(() => {
            dbDomesticItemsRefs.current = dbDomesticInitializeRefs(dbDomesticProductHandle.length);
        }, [dbDomesticProductHandle.length]);

        return dbDomesticItemsRefs;
    };
    const dbDomesticItemsRefs = useDbDomesticItemsRefs(dbDomesticProductHandle);

    const scrollToEmptyFieldDbDomestic = () => {
        let isValid = true;
        for (let i = 0; i < dbDomesticProductHandle.length; i++) {
            if (!dbDomesticProductHandle[i].name || !dbDomesticProductHandle[i].quantity || !dbDomesticProductHandle[i].price || !dbDomesticProductHandle[i].fee || !dbDomesticProductHandle[i].url) {
                const emptyField = dbDomesticItemsRefs.current[i];
                if (!dbDomesticProductHandle[i].name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (!dbDomesticProductHandle[i].quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                } else if (!dbDomesticProductHandle[i].price) {
                    emptyField.price.scrollIntoView({behavior: 'smooth'});
                    emptyField.price.focus();
                } else if (!dbDomesticProductHandle[i].fee) {
                    emptyField.fee.scrollIntoView({behavior: 'smooth'});
                    emptyField.fee.focus();
                } else {
                    emptyField.url.scrollIntoView({behavior: 'smooth'});
                    emptyField.url.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    // new product handle
    const newDeliveryForm = () => {
        setNewDeliveryFormSt(true);
        addDeliveryProduct();
        if (deliveryProductHandle.length > 0) {
            setNewDeliveryBtShow(false);
        }
    };

    const addDeliveryProduct = () => {
        setDeliveryProductHandle([...deliveryProductHandle, {
            domShipco: "국내배송사",
            inWaynum: "",
            name: "",
            quantity: ""
        }]);
        setDeliveryAccordion(true);
    };
    const deleteDeliveryProduct = (index) => {
        setDeliveryProductHandle(deliveryProductHandle.filter((_, i) => i !== index));
        if (deliveryProductHandle.length === 0 && dbDeliveryProductHandle.length === 0) {
            setDeliveryAccordion(false);
            setDeliveryServiceAgreeCheck(false);
        }
        if (deliveryProductHandle.length === 0) {
            setNewDeliveryBtShow(true);
        }
        if (deliveryProductHandle.length === 0 || dbDeliveryProductHandle.length === 0) {
            setDeliveryAccordion(true);
        }
    }
    const handleDeliveryProductInput = (index, field, value) => {
        const updatedProducts = [...deliveryProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value, newProd: true};
        setDeliveryProductHandle(updatedProducts);
    };
    const copyDeliveryProduct = (index) => {
        const copiedProduct = {...deliveryProductHandle[index]};
        setDeliveryProductHandle([...deliveryProductHandle, copiedProduct]);
    };

    const deliveryQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDeliveryQuantityChange(index, onlyNumbers);
    };

    const handleDeliveryQuantityChange = (index, newValue) => {
        const updatedDeliveryProductHandle = deliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue, newProd: true};
            }
            return item;
        });
        setDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const deliveryWaynumReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDeliveryWaynumChange(index, onlyNumbers);
    };

    const handleDeliveryWaynumChange = (index, newValue) => {
        const updatedDeliveryProductHandle = deliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, inWaynum: newValue, newProd: true};
            }
            return item;
        });
        setDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const handleDeliveryDomesticChange = (index, newValue) => {
        const updatedDeliveryProductHandle = deliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, domShipco: newValue, newProd: true};
            }
            return item;
        });
        setDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const deliveryUploadInputChange = (index, key, value) => {
        const updatedRows = [...deliveryProductHandle];
        updatedRows[index][key] = value;
        setDeliveryProductHandle(updatedRows);
    };

    const deliveryQuantityNumberInput = (index, field, value) => {
        deliveryUploadInputChange(index, field, value);
        handleDeliveryProductInput(index, field, value);
        deliveryQuantityReplaceInput(index, value);
    }

    const deliveryWaynumNumberInput = (index, field, value) => {
        deliveryUploadInputChange(index, field, value);
        handleDeliveryProductInput(index, field, value);
        deliveryWaynumReplaceInput(index, value);
    }

    const deliveryDomesticSelect = (index, field, value) => {
        handleDeliveryProductInput(index, field, value);
        handleDeliveryDomesticChange(index, value);
    }

    const useDeliveryItemsRefs = (deliveryProductHandle) => {
        const deliveryItemsRefs = useRef(
            deliveryProductHandle.map(() => ({
                name: null,
                quantity: null,
            }))
        );

        const deliveryinitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
            }));
        };

        useMemo(() => {
            deliveryItemsRefs.current = deliveryinitializeRefs(deliveryProductHandle.length);
        }, [deliveryProductHandle.length]);

        return deliveryItemsRefs;
    };
    const deliveryItemsRefs = useDeliveryItemsRefs(deliveryProductHandle);

    const scrollToEmptyFieldDelivery = () => {
        let isValid = true;
        if (deliveryProductHandle.length === 0) {
            return;
        }
        for (let i = 0; i < deliveryProductHandle.length; i++) {
            if (!deliveryProductHandle[i].name || !deliveryProductHandle[i].quantity) {
                const emptyField = deliveryItemsRefs.current[i];
                if (!deliveryProductHandle[i].name && emptyField.name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (emptyField.quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    // newForm 국내배송 셀렉트함수
    const [domesticShow, setDomesticShow] = useState(
        new Array(deliveryProductHandle.length).fill(false)
    );

    const deliveryProductHandleRef = useRef(deliveryProductHandle);
    deliveryProductHandleRef.current = deliveryProductHandle;

    const domesticRefs = useRef([]);
    const toggleDomesticShow = (index) => {
        const updatedShowStates = [...domesticShow];
        updatedShowStates[index] = !updatedShowStates[index];
        setDomesticShow(updatedShowStates);
    };

    const handleDomesticChange = (index, domestic) => {
        const updatedHandles = [...deliveryProductHandle];
        updatedHandles[index].domShipco = domestic;
        setDeliveryProductHandle(updatedHandles);

        const updatedShowStates = [...domesticShow];
        updatedShowStates[index] = false;
        setDomesticShow(updatedShowStates);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            domesticRefs.current.forEach((ref, index) => {
                if (ref && !ref.contains(event.target)) {
                    setDomesticShow(prevShow => {
                        const updatedShow = [...prevShow];
                        updatedShow[index] = false;
                        return updatedShow;
                    });
                }
            });
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [domesticRefs, domesticShow]);

    useEffect(() => {
        domesticRefs.current = domesticRefs.current.slice(0, deliveryProductHandle.length);
        deliveryProductHandle.forEach((_, index) => {
            if (!domesticRefs.current[index]) {
                domesticRefs.current[index] = createRef();
            }
        });
    }, [deliveryProductHandle]);

    const deleteDbDeliveryProduct = (index) => {
        setDbDeliveryProductHandle((prevState) => {
            const updatedState = prevState.filter((product, i) => {
                if (i === index) {
                    setDeletedProductsData((prevDeleted) => ({
                        ...prevDeleted,
                        deletedDbDeliveryProducts: [...prevDeleted.deletedDbDeliveryProducts, product],
                    }));
                }
                return i !== index;
            });
            if (updatedState.length === 0 && dbDeliveryProductHandle.length === 0) {
                setDeliveryAccordion(false);
                setDeliveryServiceAgreeCheck(false);
            }
            return updatedState;
        });
        updatePayinfoAddform();
    };

    const handleDbDeliveryProductInput = (index, field, value) => {
        const updatedProducts = [...dbDeliveryProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDbDeliveryProductHandle(updatedProducts);
    };
    const dbDeliveryQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDbDeliveryQuantityChange(index, onlyNumbers);
    };

    const handleDbDeliveryQuantityChange = (index, newValue) => {
        const updatedDeliveryProductHandle = dbDeliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setDbDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const dbDeliveryWaynumReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDbDeliveryWaynumChange(index, onlyNumbers);
    };

    const handleDbDeliveryWaynumChange = (index, newValue) => {
        const updatedDeliveryProductHandle = dbDeliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, inWaynum: newValue};
            }
            return item;
        });
        setDbDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const handleDbDeliveryDomesticChange = (index, newValue) => {
        const updatedDeliveryProductHandle = dbDeliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, domShipco: newValue};
            }
            return item;
        });
        const updatedShowStates = [...dbDomesticShow];
        updatedShowStates[index] = false;
        setDbDomesticShow(updatedShowStates);
        setDbDeliveryProductHandle(updatedDeliveryProductHandle);
    };
    const dbDeliveryQuantityNumberInput = (index, field, value) => {
        handleDbDeliveryProductInput(index, field, value);
        dbDeliveryQuantityReplaceInput(index, value);
    }

    const dbDeliveryWaynumNumberInput = (index, field, value) => {
        handleDbDeliveryProductInput(index, field, value);
        dbDeliveryWaynumReplaceInput(index, value);
    }

    const [dontTrackingShipcoSt, setDontTrackingShipcoSt] = useState(false);
    const closeDontTrackingShipco = (() => {
        setDontTrackingShipcoSt(false);
    })

    const dbSelectedDeliveryTracking = ((selectedDomestic, dsTrackNum) => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 1200;
        const height = 800;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);

        if (selectedDomestic === "CJ대한통운") {
            const url = `https://trace.cjlogistics.com/next/tracking.html?wblNo=${dsTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "로젠택배") {
            const url = `https://www.ilogen.com/web/personal/trace/${dsTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "우체국") {
            const url = `https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=${dsTrackNum}&displayHeader=N`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "한진택배") {
            const url = `https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "롯데택배") {
            const url = `https://www.lotteglogis.com/open/tracking?invno=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "경동택배") {
            const url = `https://kdexp.com/newDeliverySearch.kd?barcode=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else {
            setDontTrackingShipcoSt(true);
        }
    })

    const useDbDeliveryItemsRefs = (dbDeliveryProductHandle) => {
        const dbDeliveryItemsRefs = useRef(
            dbDeliveryProductHandle.map(() => ({
                name: null,
                quantity: null,
            }))
        );

        const dbDeliveryinitializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
            }));
        };

        useMemo(() => {
            dbDeliveryItemsRefs.current = dbDeliveryinitializeRefs(dbDeliveryProductHandle.length);
        }, [dbDeliveryProductHandle.length]);

        return dbDeliveryItemsRefs;
    };
    const dbDeliveryItemsRefs = useDbDeliveryItemsRefs(dbDeliveryProductHandle);

    const scrollToEmptyFieldDbDelivery = () => {
        let isValid = true;
        if (dbDeliveryProductHandle.length === 0) {
            return;
        }
        for (let i = 0; i < dbDeliveryProductHandle.length; i++) {
            if (!dbDeliveryProductHandle[i].name || !dbDeliveryProductHandle[i].quantity) {
                const emptyField = dbDeliveryItemsRefs.current[i];
                if (!dbDeliveryProductHandle[i].name && emptyField.name) {
                    emptyField.name.scrollIntoView({behavior: 'smooth'});
                    emptyField.name.focus();
                } else if (emptyField.quantity) {
                    emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                    emptyField.quantity.focus();
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    // dbForm 국내배송 셀렉트함수
    const [dbDomesticShow, setDbDomesticShow] = useState(
        new Array(dbDeliveryProductHandle.length).fill(false)
    );

    const dbDeliveryProductHandleRef = useRef(dbDeliveryProductHandle);
    dbDeliveryProductHandleRef.current = dbDeliveryProductHandle;

    const dbDomesticRefs = useRef([]);
    const toggleDbDomesticShow = (index) => {
        const updatedShowStates = [...dbDomesticShow];
        updatedShowStates[index] = !updatedShowStates[index];
        setDbDomesticShow(updatedShowStates);
    };

    const handleDbDomesticChange = (index, domestic) => {
        const updatedHandles = [...dbDeliveryProductHandle];
        updatedHandles[index].domShipco = domestic;
        setDbDeliveryProductHandle(updatedHandles);

        const updatedShowStates = [...dbDomesticShow];
        updatedShowStates[index] = false;
        setDbDomesticShow(updatedShowStates);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            dbDomesticRefs.current.forEach((ref, index) => {
                if (ref && !ref.contains(event.target)) {
                    setDbDomesticShow(prevShow => {
                        const updatedShow = [...prevShow];
                        updatedShow[index] = false;
                        return updatedShow;
                    });
                }
            });
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dbDomesticRefs, dbDomesticShow]);

    useEffect(() => {
        dbDomesticRefs.current = dbDomesticRefs.current.slice(0, dbDeliveryProductHandle.length);
        dbDeliveryProductHandle.forEach((_, index) => {
            if (!dbDomesticRefs.current[index]) {
                dbDomesticRefs.current[index] = createRef();
            }
        });
    }, [dbDeliveryProductHandle]);


    const [formUploadErrSt, setFormUploadErrSt] = useState(false);
    const closeFormUploadErrSt = (() => {
        setFormUploadErrSt(false);
    })

    // 장바구니 업로드
    const readExcelCart = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((cartSheet) => {
                            const ws = workbook.Sheets[cartSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {
                                if (!row["MallId"] || !row["MallPw"] || !row["Quantity"] || !row["CartPrice"] || !row["MallURL"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        name: String(row["MallId"]),
                                        option: row["MallPw"],
                                        quantity: row["Quantity"],
                                        price: row["CartPrice"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        url: row["MallURL"],
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setCartProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const cartExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelCart(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 구매대행 업로드
    const readExcelOrder = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((orderSheet) => {
                            const ws = workbook.Sheets[orderSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {
                                if (!row["ProductName"] || !row["Price"] || !row["ShipPrice"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        name: String(row["ProductName"]),
                                        option: row["Option"],
                                        quantity: row["Quantity"],
                                        price: row["Price"]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        mallShipPrice: row["ShipPrice"]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        url: row["ProductURL"],
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setOrderProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const orderExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelOrder(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 오프라인 업로드
    const readExcelOffline = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((offlineSheet) => {
                            const ws = workbook.Sheets[offlineSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {
                                if (!row["ProductName"] || !row["Quantity"] || !row["TotalPrice"] || !row["Fee"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        name: String(row["ProductName"]),
                                        quantity: row["Quantity"],
                                        price: row["TotalPrice"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        fee: row["Fee"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setOfflineProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const offlineExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelOffline(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 이체대행 업로드
    const readExcelTransfer = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((transferSheet) => {
                            const ws = workbook.Sheets[transferSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {

                                if (!row["TransferAccountNumber"] || !row["TransferPrice"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    const transferPrice = parseFloat(row["TransferPrice"]);
                                    const fee = transferPrice * 0.07; // 7% 수수료
                                    const roundedCommission = Math.floor(fee / 10) * 10; // 10원 단위 절삭

                                    return {
                                        name: String(row["TransferAccountNumber"]),
                                        price: row["TransferPrice"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        fee: roundedCommission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setTransferProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const transferExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelTransfer(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 국내배송 업로드
    const readExcelDomestic = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((domesticSheet) => {
                            const ws = workbook.Sheets[domesticSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {

                                if (!row["ProductName"] || !row["Quantity"] || !row["Price"] || !row["ProductURL"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    const price = parseFloat(row["Price"]);
                                    const mallShipPrice = parseFloat(row["ShipPrice"]);
                                    const fee = price + mallShipPrice;
                                    const rawCommission = fee * 0.07; // 7% 수수료
                                    const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                                    return {
                                        name: String(row["ProductName"]),
                                        option: row["Option"],
                                        quantity: row["Quantity"],
                                        price: price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        mallShipPrice: mallShipPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        fee: roundedCommission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        url: row["ProductURL"],
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setDomesticProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const domesticExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelDomestic(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 배송대행 업로드
    const readExcelDelivery = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((deliverySheet) => {
                            const ws = workbook.Sheets[deliverySheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {
                                if (!row["PackageName"] || !row["TotalQuantity"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        domShipco: t('dv'),
                                        inWaynum: row["WaybillNumber"],
                                        option: row["Option"],
                                        name: String(row["PackageName"]),
                                        quantity: row["TotalQuantity"],
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setDeliveryProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const deliveryExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelDelivery(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };


    // 여기서부터 엑셀다운 함수
    const cartExcelDown = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('cartSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('cartSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'MallId', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'MallPw', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'cartPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'MallURL', width: 70, style: {alignment: {horizontal: 'center'}}},
        ];
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `cartForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const orderExcelDown = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('orderSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('orderSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'ProductName', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Option', width: 40, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 10, style: {alignment: {horizontal: 'center'}}},
            {header: 'Price', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'ShipPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'ProductURL', width: 70, style: {alignment: {horizontal: 'center'}}},
        ];
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `orderForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const offlineExcelDown = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('offlineSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('offlineSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'ProductName', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 10, style: {alignment: {horizontal: 'center'}}},
            {header: 'TotalPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'Fee', width: 20, style: {alignment: {horizontal: 'center'}}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `offlineForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const transferExcelDown = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('transferSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('transferSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'TransferAccountNumber', width: 40, style: {alignment: {horizontal: 'center'}}},
            {header: 'TransferPrice', width: 30, style: {alignment: {horizontal: 'center'}}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `transferForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const domesticExcelDown = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('domesticSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('domesticSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'ProductName', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Option', width: 40, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 10, style: {alignment: {horizontal: 'center'}}},
            {header: 'Price', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'ShipPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'ProductURL', width: 70, style: {alignment: {horizontal: 'center'}}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `domesticForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const deliveryExcelDown = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('deliverySheet');
        const sheetOne = workbook.getWorksheet('deliverySheet');

        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;

        sheetOne.columns = [
            {header: 'WaybillNumber', width: 40, style: {alignment: {horizontal: 'center'}}, numFmt: '0'},
            {header: 'PackageName', width: 60, style: {alignment: {horizontal: 'center'}}},
            {header: 'Option', width: 60, style: {alignment: {horizontal: 'center'}}},
            {header: 'TotalQuantity', width: 20, style: {alignment: {horizontal: 'center'}}},
        ];

        for (let i = 2; i <= 1; i++) {
            sheetOne.getRow(i).getCell(1).value = '';
            sheetOne.getRow(i).getCell(1).numFmt = '0';
        }

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `deliveryForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const address1Length = selectedDeliveryChoiceOption === "EMS" ? 200 :
        selectedDeliveryChoiceOption === "UPS" ? 200 :
            selectedDeliveryChoiceOption === "Small-Packet" ? 200 :
                selectedDeliveryChoiceOption === "K-Packet" ? 200 :
                    selectedDeliveryChoiceOption === "DHL" ? 35 :
                        selectedDeliveryChoiceOption === "FedEx" ? 35 :
                            selectedDeliveryChoiceOption === "SHIP" ? 200 :
                                selectedDeliveryChoiceOption === "국내배송" ? 100 : 35;
    const address2Length = selectedDeliveryChoiceOption === "EMS" ? 50 :
        selectedDeliveryChoiceOption === "UPS" ? 50 :
            selectedDeliveryChoiceOption === "Small-Packet" ? 50 :
                selectedDeliveryChoiceOption === "K-Packet" ? 50 :
                    selectedDeliveryChoiceOption === "DHL" ? 35 :
                        selectedDeliveryChoiceOption === "FedEx" ? 35 :
                            selectedDeliveryChoiceOption === "SHIP" ? 50 :
                                selectedDeliveryChoiceOption === "국내배송" ? 100 : 35;
    const address3Length = selectedDeliveryChoiceOption === "EMS" ? 50 :
        selectedDeliveryChoiceOption === "UPS" ? 50 :
            selectedDeliveryChoiceOption === "Small-Packet" ? 50 :
                selectedDeliveryChoiceOption === "K-Packet" ? 50 :
                    selectedDeliveryChoiceOption === "DHL" ? 35 :
                        selectedDeliveryChoiceOption === "FedEx" ? 35 :
                            selectedDeliveryChoiceOption === "SHIP" ? 50 :
                                selectedDeliveryChoiceOption === "국내배송" ? 100 : 35;
    const cityLength = selectedDeliveryChoiceOption === "EMS" ? 50 :
        selectedDeliveryChoiceOption === "UPS" ? 50 :
            selectedDeliveryChoiceOption === "Small-Packet" ? 50 :
                selectedDeliveryChoiceOption === "K-Packet" ? 50 :
                    selectedDeliveryChoiceOption === "DHL" ? 35 :
                        selectedDeliveryChoiceOption === "FedEx" ? 35 :
                            selectedDeliveryChoiceOption === "SHIP" ? 50 :
                                selectedDeliveryChoiceOption === "국내배송" ? 100 : 35;


    const validTextTaxid = ((e) => {
        const taxidRegex = /^[a-zA-Z0-9\s\-\.,]{2,50}$/; /*2~50자*/
        if (!e.target.value || taxidRegex.test(e.target.value)) setTaxidError(false);
        else setTaxidError(true);
        setTaxid(e.target.value);
    })

    const validTextRecname = ((e) => {
        const recnameRegex = /^[a-zA-Z ]{2,25}/; /*영문 2~25자*/
        if (!e.target.value || recnameRegex.test(e.target.value)) setRecnameError(false);
        else setRecnameError(true);
        setRecname(e.target.value);
    })
    const validTextAddr1 = ((e) => {
        const Addr1Regex = /^[a-zA-Z,-/,\d- ]{2,35}$/; // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 35자 이하
        if (!e.target.value || Addr1Regex.test(e.target.value)) setAddress1Error(false);
        else setAddress1Error(true);
        setAddress1(e.target.value);
    })
    const validTextAddr2 = ((e) => {
        const Addr2Regex = /^[a-zA-Z,-/,\d- ]{2,35}$/; // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 35자 이하
        if (!e.target.value || Addr2Regex.test(e.target.value)) setAddress2Error(false);
        else setAddress2Error(true);
        setAddress2(e.target.value);
    })
    const validTextAddr3 = ((e) => {
        const Addr3Regex = /^[a-zA-Z,-/,\d- ]{2,35}$/; // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 35자 이하
        if (!e.target.value || Addr3Regex.test(e.target.value)) setAddress3Error(false);
        else setAddress3Error(true);
        setAddress3(e.target.value);
    })
    const validTextCity = ((e) => {
        const cityRegex = /^[a-zA-Z,-/,\d- ]{2,35}$/; // 영문 또는 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || cityRegex.test(e.target.value)) setCityError(false);
        else setCityError(true);
        setCity(e.target.value);
    })
    const validTextZipcode = ((e) => {
        const zipcodeRegex = /^[a-zA-Z\d- ]{3,10}$/; // 암호화 // 영문 또는 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || zipcodeRegex.test(e.target.value)) setZipcodeError(false);
        else setZipcodeError(true);
        setZipcode(e.target.value);
    })
    const validTextTelnum = ((e) => {
        const telnumRegex = /^[\d-]{5,15}$/; // 암호화 // 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || telnumRegex.test(e.target.value)) setTelnumError(false);
        else setTelnumError(true);
        setTelnum(e.target.value);
    })

    const [orderdtList, setOrderdtList] = useState([{}]);
    const [ordermstList, setOrdermstList] = useState([{}]);
    const [payinfoList, setPayinfoList] = useState([{}]);
    const [recinfoList, setRecinfoList] = useState([{}]);
    const [reqinfoList, setReqinfoList] = useState([{}]);
    const [memberInfo, setMemberInfo] = useState([{}]);

    const [runOrderProcessFl, setRunOrderProcessFl] = useState(false);
    const selectOrdnumData = (() => {
        setIsLoading(true);
        setRunOrderProcessFl(true);
        axios({
            method: 'get',
            url: '/order/selectOrdnumData',
            headers: {
                Authorization: LCAT
            },
            params: {
                ordNum: ordNum
            },
        })
            .then((response) => {
                const data = response.data[0];
                setOrderdtList(data);
                shipcoHandleSelect(data?.shipco);
                setUseType(data?.useType);

                const origFileName = data.invoiceFileOrinm || "Select File";
                setFileName(origFileName);

                if (data.invoiceFileName) {
                    loadInvoiceFromS3(data.invoiceFileName, origFileName);
                }

                const ProdList = data.productsDTO || [];
                updateProductHandleStatesBatch(ProdList);

                const ordermstList = data.ordermstDTO || [];
                setOrdermstList(ordermstList);

                const payList = data.payInfoDTO || [];
                const filterPayList = payList.filter((pay) => pay.payType !== 'PT3');
                setPayinfoList(filterPayList);

                const recInfo = data.recinfoDTO || [];
                setRecinfoList(recInfo);

                const reqInfo = data.reqmnDTO || [];
                setReqinfoList(reqInfo);

                const memberInfo = data.memberDTO || [];
                setMemberInfo(memberInfo);

                const countryData = {
                    value: data.recinfoDTO.countryDTO.countryCode,
                    label: data.recinfoDTO.countryDTO.countryNm + '(' + data.recinfoDTO.countryDTO.countryEngNm + ')'
                };

                setSelectedCountry(countryData)
                setTaxid(recInfo.taxid);
                setRecname(recInfo.recname);
                setNameInputCount(recInfo.recname.length)
                setTelnum(recInfo.telnum);
                setTelnumInputCount(recInfo.telnum.length)
                setZipcode(recInfo.zipcode);
                setZipcodeInputCount(recInfo.zipcode.length)
                setAddress1(recInfo.address1);
                setAddress1InputCount(recInfo.address1.length)
                setAddress2(recInfo.address2);
                setAddress2InputCount(recInfo.address2.length)
                setAddress3(recInfo.address3);
                setAddress3InputCount(recInfo.address3.length)
                setCity(recInfo.city);
                setCityInputCount(recInfo.city.length)

                setReqSeqnum(reqInfo.seqnum);
                setTagRemove(reqInfo.tagRemove);
                setShoesBoxRemove(reqInfo.shoesBoxRemove);
                setHangerRemove(reqInfo.hangerRemove);
                setCompressionPack(reqInfo.compressionPack);
                setProductBoxRemove(reqInfo.prodBoxRemove);
                setPackingHold(reqInfo.packingHold);
                setDeliveryDepositPay(reqInfo.shipPriceDepositpay);
                setDeliveryMessengerGuide(reqInfo.shipPriceMessenger);
                setDetailRequest(reqInfo.detailReq);

                setUserProfileImg("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + memberInfo.profileDTO.filename)
            })
            .catch(() => {
                console.log("주문서조회 실패")
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    })

    useEffect(() => {
        if (ordNum && !runOrderProcessFl) {
            selectOrdnumData();
        }
    }, [ordNum, runOrderProcessFl]);

    const [selectTokenMbNum, setSelectTokenMbNum] = useState(0);
    const [selectMemberProcessFl, setSelectMemberProcessFl] = useState(false);
    const selectTokenMember = (() => {
        setSelectMemberProcessFl(true);
        axios({
            method: 'get',
            url: '/order/selectTokenMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setSelectTokenMbNum(response.data);
            })
            .catch(() => {
                console.log("토큰조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT, !selectMemberProcessFl) {
            selectTokenMember();
        }
    }, [LCAT, selectMemberProcessFl]);

    const sortedPayinfoList = [...payinfoList].sort((a, b) => a.seqNum - b.seqNum);

    const [paypalPopupSt, setPaypalPopupSt] = useState(false);
    const paypalPopupClose = (() => {
        setIsPayBtLoading(false);
        setPaypalPopupSt(false);
    })

    const parseNumber = (value) => {
        if (typeof value === 'string') {
            return parseFloat(value.replace(/,/g, ''));
        }
        return value;
    };

    // 결제수단 출력조건
    // 뉴폼 또는 DB폼의 상품양식마다 결제대기 상태의 상품금액값이 하나라도 있거나 || 결제리스트(selectedPayList)마다 결제대기 && 상품타입 외 데이터가 하나라도 있을 경우 결제수단 보이게
    const [isOrderPay, setIsOrderPay] = useState(false);
    useEffect(() => {
        const isPay =
            sortedPayinfoList.some(info => info.payStatus === "PS0") ||
            (
                cartProductHandle.some(item => parseNumber(item.price) > 0) ||
                dbCartProductHandle.some(item => item.prodSt === 'IS01' || item.prodSt === 'IS07') ||
                orderProductHandle.some(item => parseNumber(item.price) > 0 || parseNumber(item.mallShipPrice) > 0) ||
                dbOrderProductHandle.some(item => item.prodSt === 'IS01' || item.prodSt === 'IS07') ||
                offlineProductHandle.some(item => parseNumber(item.price) > 0 || parseNumber(item.fee) > 0) ||
                dbOfflineProductHandle.some(item => item.prodSt === 'IS01' || item.prodSt === 'IS07') ||
                transferProductHandle.some(item => parseNumber(item.price) > 0 || parseNumber(item.fee) > 0) ||
                dbTransferProductHandle.some(item => item.prodSt === 'IS01' || item.prodSt === 'IS07') ||
                domesticProductHandle.some(item => parseNumber(item.price) > 0 || parseNumber(item.mallShipPrice) > 0 || parseNumber(item.fee) > 0) ||
                dbDomesticProductHandle.some(item => item.prodSt === 'IS01' || item.prodSt === 'IS07')
            );

        setIsOrderPay(isPay);
    }, [cartProductHandle, dbCartProductHandle, orderProductHandle, dbOrderProductHandle, offlineProductHandle, dbOfflineProductHandle, transferProductHandle, dbTransferProductHandle, domesticProductHandle, dbDomesticProductHandle, sortedPayinfoList]);

    // 이용약관 에러메시지 출력조건
    const agreeErrorMsgCondition =
        (!cartProductHandle.some(cart => cart.name && cart.name.length > 0) || cartServiceAgreeCheck) &&
        (!orderProductHandle.some(order => order.name && order.name.length > 0) || orderServiceAgreeCheck) &&
        (!offlineProductHandle.some(offline => offline.name && offline.name.length > 0) || offlineServiceAgreeCheck) &&
        (!transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0) || transferServiceAgreeCheck) &&
        (!domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0) || domesticServiceAgreeCheck) &&
        (!deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0) || deliveryServiceAgreeCheck);

    const hasCartProduct = cartProductHandle.some(cart => cart.name && cart.name.length > 0);
    const hasBothCartAndDbCart = hasCartProduct && (dbCartProductHandle.length === 0 || dbCartProductHandle.some(cart => cart.name && cart.name.length === 0));

    const hasOrderProduct = orderProductHandle.some(order => order.name && order.name.length > 0);
    const hasBothOrderAndDbOrder = hasOrderProduct && (dbOrderProductHandle.length === 0 || dbOrderProductHandle.some(order => order.name && order.name.length === 0));

    const hasOfflineProduct = offlineProductHandle.some(offline => offline.name && offline.name.length > 0);
    const hasBothOfflineAndDbOffline = hasOfflineProduct && (dbOfflineProductHandle.length === 0 || dbOfflineProductHandle.some(offline => offline.name && offline.name.length === 0));

    const hasTransferProduct = transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0);
    const hasBothTransferAndDbTransfer = hasTransferProduct && (dbTransferProductHandle.length === 0 || dbTransferProductHandle.some(transfer => transfer.name && transfer.name.length === 0));

    const hasDomesticProduct = domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0);
    const hasBothDomesticAndDbDomestic = hasDomesticProduct && (dbDomesticProductHandle.length === 0 || dbDomesticProductHandle.some(domestic => domestic.name && domestic.name.length === 0));

    const hasDeliveryProduct = deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0);
    const hasBothDeliveryAndDbDelivery = hasDeliveryProduct && (dbDeliveryProductHandle.length === 0 || dbDeliveryProductHandle.some(delivery => delivery.name && delivery.name.length === 0));

    const [agreeCheckBoxError, setAgreeCheckBoxError] = useState(false);

    const validation = (saveFl) => {
        if (selectedDeliveryChoiceOption === "배송사") {
            setDeliveryChoiceError(true);
            return false;
        }
        if (selectedCountry.value === "ID" && !taxid) {
            setTaxidError(true);
            return false;
        }
        if (selectedCountry.value === "" || !selectedCountry.value) {
            setCountryError(true);
            return false;
        }
        if (!recname) {
            setRecnameError(true);
            return false;
        }
        if (!address1) {
            setAddress1Error(true);
            return false;
        }
        if (selectedCountry.value !== "KR" && !city) {
            setCityError(true);
            return false;
        }
        if (selectedCountry.value !== "KR" && !zipcode) {
            setZipcodeError(true);
            return false;
        }
        if (!telnum) {
            setTelnumError(true);
            return false;
        }

        // 상품 인풋양식들 검증
        if ((cartProductHandle.length > 0 && !scrollToEmptyFieldCart()) || (orderProductHandle.length > 0 && !scrollToEmptyFieldOrder()) ||
            (offlineProductHandle.length > 0 && !scrollToEmptyFieldOffline()) || (transferProductHandle.length > 0 && !scrollToEmptyFieldTransfer()) ||
            (domesticProductHandle.length > 0 && !scrollToEmptyFieldDomestic()) || (deliveryProductHandle.length > 0 && !scrollToEmptyFieldDelivery()) ||
            (dbCartProductHandle.length > 0 && !scrollToEmptyFieldDbCart()) || (dbOrderProductHandle.length > 0 && !scrollToEmptyFieldDbOrder()) ||
            (dbOfflineProductHandle.length > 0 && !scrollToEmptyFieldDbOffline()) || (dbTransferProductHandle.length > 0 && !scrollToEmptyFieldDbTransfer()) ||
            (dbDomesticProductHandle.length > 0 && !scrollToEmptyFieldDbDomestic()) || (dbDeliveryProductHandle.length > 0 && !scrollToEmptyFieldDbDelivery())) {
            return false;
        }

        // 각 폼마다 입력했으면 저장이 아닌 결제일 때 동의란에 체크하지 않았을 경우 Error
        // 각 약관들이 세이브일 땐 검증 안하려고 했으나 그럼 배송대행 단독 작성 시 약관없이 작성가능
        if (hasCartProduct && hasBothCartAndDbCart && !cartServiceAgreeCheck) {
            setAgreeCheckBoxError(true);
            return false;
        }

        if (hasOrderProduct && hasBothOrderAndDbOrder && !orderServiceAgreeCheck) {
            setAgreeCheckBoxError(true);
            return false;
        }

        if (hasOfflineProduct && hasBothOfflineAndDbOffline && !offlineServiceAgreeCheck) {
            setAgreeCheckBoxError(true);
            return false;
        }

        if (hasTransferProduct && hasBothTransferAndDbTransfer && !transferServiceAgreeCheck) {
            setAgreeCheckBoxError(true);
            return false;
        }

        if (hasDomesticProduct && hasBothDomesticAndDbDomestic && !domesticServiceAgreeCheck) {
            setAgreeCheckBoxError(true);
            return false;
        }

        if (hasDeliveryProduct && hasBothDeliveryAndDbDelivery && !deliveryServiceAgreeCheck) {
            setAgreeCheckBoxError(true);
            return false;
        }

        // isOrderPay(결제수단)이 true(출력)인데 결제수단을 선택하지 않았을 경우 Error
        if (isOrderPay && payWay === '' && !saveFl) {
            setPayMethodError(true);
            return false;
        }
        //  || 예치금이 아닌 다른 결제수단으로 시도할 경우 Error
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) === 0 && (payWay !== 'PM04' && payWay !== 'PM05') && !saveFl) {
            setPointPayError(true);
            return false;
        }
        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원이고 결제수단이 예치금결제인데 예치금을 사용하지 않았을 경우
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) === 0 && payWay === "PM05" && depositSale === '' && !saveFl) {
            setPointSelectErr(true);
            return false;
        }
        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원이고 결제수단이 포인트결제인데 포인트나 쿠폰을 사용하지 않았을 경우
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) === 0 && payWay === "PM04" && (pointSale === '' && couponSale === '') && !saveFl) {
            setDepositSelectErr(true);
            return false;
        }
        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원이고 포인트 사용금액이나 쿠폰 사용금액이 0보다 큰데 결제수단이 포인트결제나 예치금결제가 아닐 경우
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) === 0 && (pointSale !== '' && depositSale !== '') && couponSale > 0 && (payWay !== 'PM04' && payWay !== 'PM05') && !saveFl) {
            setDepositSelectErr(true);
            return false;
        }
        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원보다 큰데 포인트 || 예치금으로 결제시도할 경우 Error
        if (isOrderPay && Number(finalPayExpense.replace(/,/g, '')) > 0 && (payWay === "PM04" || payWay === "PM05") && !saveFl) {
            setEtcPayError(true);
            return false;
        }

        if (Number(finalPayExpense.replace(/,/g, '')) < 0) {
            setDiscountError(true);
            return false;
        }

        return true;
    };


    const handleSubmit = (btType) => {
        if (isSaveBtDisabled || isPayBtDisabled) return;
        let saveFl;
        if (btType === "save") {
            saveFl = true;
            setIsSaveBtLoading(true);
            setIsSaveBtDisabled(true);
        } else if (btType === "pay") {
            saveFl = false;
            setIsPayBtLoading(true);
            setIsPayBtDisabled(true);
        }
        try {
            const cartProductFormValid = cartProductHandle.every((item) => item.name !== '') || dbCartProductHandle.every((item) => item.name !== '');
            const orderProductFormValid = orderProductHandle.every((item) => item.name !== '') || dbOrderProductHandle.every((item) => item.name !== '');
            const offlineProductFormValid = offlineProductHandle.every((item) => item.name !== '') || dbOfflineProductHandle.every((item) => item.name !== '');
            const transferProductFormValid = transferProductHandle.every((item) => item.name !== '') || dbTransferProductHandle.every((item) => item.name !== '');
            const domesticProductFormValid = domesticProductHandle.every((item) => item.name !== '') || dbDomesticProductHandle.every((item) => item.name !== '');
            const deliveryProductFormValid = deliveryProductHandle.every((item) => item.name !== '') || dbDeliveryProductHandle.every((item) => item.name !== '');

            const orderInputCheck = (cartProductFormValid && (cartProductHandle.length > 0 || dbCartProductHandle.length > 0)) ||
                (orderProductFormValid && (orderProductHandle.length > 0 || dbOrderProductHandle.length > 0)) ||
                (offlineProductFormValid && (offlineProductHandle.length > 0 || dbOfflineProductHandle.length > 0)) ||
                (transferProductFormValid && (transferProductHandle.length > 0 || dbTransferProductHandle.length > 0)) ||
                (domesticProductFormValid && (domesticProductHandle.length > 0 || dbDomesticProductHandle.length > 0)) ||
                (deliveryProductFormValid && (deliveryProductHandle.length > 0 || dbDeliveryProductHandle.length > 0));

            if (validation(saveFl) && orderInputCheck) {
                // 결제수단이 페이팔이 아니면서 save 버튼을 클릭했을 때
                if (payWay !== "PM01" && saveFl) {
                    orderDataSave(true);
                    // 결제수단이 페이팔이 아니면서 Pay Now 버튼을 클릭했을 때
                } else if (payWay !== "PM01" && !saveFl) {
                    orderDataSave(false);
                    // 결제수단이 페이팔이면서 Pay Now 버튼을 클릭했을 때
                } else if (payWay === "PM01" && !saveFl){
                    setPaypalPopupSt(true);
                }
            } else {
                scrollToEmptyFieldCart();
                scrollToEmptyFieldOrder();
                scrollToEmptyFieldOffline();
                scrollToEmptyFieldTransfer();
                scrollToEmptyFieldDomestic();
                scrollToEmptyFieldDelivery();
                scrollToEmptyFieldDbCart();
                scrollToEmptyFieldDbOrder();
                scrollToEmptyFieldDbOffline();
                scrollToEmptyFieldDbTransfer();
                scrollToEmptyFieldDbDomestic();
                scrollToEmptyFieldDbDelivery();
                throw new Error("유효성검사 실패");
            }
        } catch {
            if (saveFl) {
                setIsSaveBtLoading(false);
            } else {
                setIsPayBtLoading(false);
            }
        } finally {
            if (saveFl) {
                setIsSaveBtDisabled(false);
            } else {
                setIsPayBtDisabled(false);
            }
        }
    };

    const addrBookCloseFn = (() => {
        setOrderAddrBookSt(false);
    })

    const handleUseType = (event) => {
        setUseType(event.target.value);
    };

    const handleChangeDeliveryChoice = (event) => {
        setSelectedDeliveryChoiceOption(event.target.value);
        setDeliveryChoiceError(false);
    };

    const shipCode = "1"
    const bringToShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + shipCode,
        headers: {
            Authorization: LCAT
        },
    })
        .then((response) => {
            setDeliveryChoice(response.data)
        })

    const DomesticShipCode = "0"
    const bringToDomesticShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + DomesticShipCode,
        headers: {
            Authorization: LCAT
        },
    })
        .then((response) => {
            setDomesticDeliveryChoice(response.data)
        })

    const [shipcoSelectedOption, setShipcoSelectedOption] = useState("배송사");
    const [shipcoIsOpen, setShipcoIsOpen] = useState(false);
    const [shipcoFocusedIndex, setShipcoFocusedIndex] = useState(0);
    const shipcoDropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (shipcoDropdownRef.current && !shipcoDropdownRef.current.contains(e.target)) {
                setShipcoIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const shipcoHandleSelect = (option, index) => {
        setShipcoSelectedOption(option);
        setShipcoFocusedIndex(index);
        setShipcoIsOpen(false);
        setSelectedDeliveryChoiceOption(option);
        if (option !== "배송사") {
            setDeliveryChoiceError(false);
        }
        if (option === "국내배송") {
            setSelectedCountry({value: 'KR', label: '대한민국(Republic of Korea)'});
        }
        // resetRecInfo();
    };

    const resetRecInfo = (() => {
        setRecname('');
        setTelnum('');
        setZipcode('');
        setAddress1('');
        setAddress2('');
        setAddress3('');
        setCity('');
    })

    const shipcoHandleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setShipcoFocusedIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % deliveryChoice.length;
                setShipcoSelectedOption(deliveryChoice[newIndex]);
                setSelectedDeliveryChoiceOption(deliveryChoice[newIndex]);
                if (e !== "배송사") {
                    setDeliveryChoiceError(false);
                }
                return newIndex;
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setShipcoFocusedIndex((prevIndex) => {
                const newIndex = (prevIndex - 1 + deliveryChoice.length) % deliveryChoice.length;
                setShipcoSelectedOption(deliveryChoice[newIndex]);
                setSelectedDeliveryChoiceOption(deliveryChoice[newIndex]);
                if (e !== "배송사") {
                    setDeliveryChoiceError(false);
                }
                return newIndex;
            });
        } else if (e.key === 'Enter') {
            e.preventDefault();
            shipcoHandleSelect(deliveryChoice[shipcoFocusedIndex], shipcoFocusedIndex);
            if (e !== "배송사") {
                setDeliveryChoiceError(false);
            }
        } else if (e.key === 'Escape') {
            if (e !== "배송사") {
                setDeliveryChoiceError(false);
            }
            setShipcoIsOpen(false);
        }
    };

    const shipcoHandleOnFocus = () => {
        if (shipcoFocusedIndex === -1) {
            setShipcoFocusedIndex(deliveryChoice.findIndex((option) => option.value === shipcoSelectedOption.value));
        }
    };

    // 여기서부터 국가 커스텀셀렉트
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({ value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const [filteredCountryList, setFilteredCountryList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(prev => !prev);
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setSearchKeyword('');
        // setCountryShow(false);
        setCountryShow(true);
    };

    const countryNameMapping = {
        'USA': 'United States of America',
    };

    const handleCountrySearch = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);

        const mappedKeyword = countryNameMapping[keyword.toUpperCase()] || keyword;

        const filteredCountries = countryList.filter(country =>
            country.label.toLowerCase().includes(mappedKeyword.toLowerCase())
        );

        setFilteredCountryList(filteredCountries);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectAllCountry = () => {
        axios.get('/order/selectCountry')
            .then(response => {
                const countries = response.data.map(country => ({
                    value: country.countryCode,
                    label: `${country.countryNm} (${country.countryEngNm})`
                })).sort((a, b) => a.label.localeCompare(b.label));

                setCountryList(countries);
                setFilteredCountryList(countries);
            })
            .catch(error => {
                console.error("Error fetching countries:", error);
            });
    };

    useEffect(() => {
        selectAllCountry();
    }, []);

    // 여기까지 커스텀 셀렉트

    const nameInputLengthCheck = (e) => {
        setNameInputCount(e.target.value.length);
    };

    const telnumInputLengthCheck = (e) => {
        setTelnumInputCount(e.target.value.length);
    };

    const zipcodeInputLengthCheck = (e) => {
        setZipcodeInputCount(e.target.value.length);
    };

    const address1InputLengthCheck = (e) => {
        setAddress1InputCount(e.target.value.length);
    };

    const address2InputLengthCheck = (e) => {
        setAddress2InputCount(e.target.value.length);
    };

    const address3InputLengthCheck = (e) => {
        setAddress3InputCount(e.target.value.length);
    };

    const cityInputLengthCheck = (e) => {
        setCityInputCount(e.target.value.length);
    };

    const nameInputRTCheck = (e) => {
        setNameInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const telnumInputRTCheck = (e) => {
        setTelnumInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const zipcodeInputRTCheck = (e) => {
        setZipcodeInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address1InputRTCheck = (e) => {
        setAddress1InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address2InputRTCheck = (e) => {
        setAddress2InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address3InputRTCheck = (e) => {
        setAddress3InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const cityInputRTCheck = (e) => {
        setCityInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const nameValid = (e) => {
        validTextRecname(e)
        nameInputLengthCheck(e);
        nameInputRTCheck(e)
    }

    const telnumValid = (e) => {
        validTextTelnum(e)
        telnumInputLengthCheck(e);
        telnumInputRTCheck(e)
    }

    const zipcodeValid = (e) => {
        validTextZipcode(e)
        zipcodeInputLengthCheck(e);
        zipcodeInputRTCheck(e)
    }

    const address1Valid = (e) => {
        validTextAddr1(e)
        address1InputLengthCheck(e);
        address1InputRTCheck(e)
    }

    const address2Valid = (e) => {
        validTextAddr2(e)
        address2InputLengthCheck(e);
        address2InputRTCheck(e)
    }

    const address3Valid = (e) => {
        validTextAddr3(e)
        address3InputLengthCheck(e);
        address3InputRTCheck(e)
    }

    const cityValid = (e) => {
        validTextCity(e)
        cityInputLengthCheck(e);
        cityInputRTCheck(e)
    }

    const recieverInfoAccordionFn = () => {
        setRecieverInfoAccordion(!recieverInfoAccordion);
    }
    const cartOrderAccordionFn = () => {
        setCartOrderAccordion(!cartOrderAccordion)
    }
    const orderAccordionFn = () => {
        setOrderAccordion(!orderAccordion)
    }
    const offlineAccordionFn = () => {
        setOfflineAccordion(!offlineAccordion)
    }
    const transferAccordionFn = () => {
        setTransferAccordion(!transferAccordion)
    }
    const domesticAccordionFn = () => {
        setDomesticAccordion(!domesticAccordion)
    }
    const deliveryAccordionFn = () => {
        setDeliveryAccordion(!deliveryAccordion)
    }
    const invoiceAccordionFn = () => {
        setInvoiceAccordion(!invoiceAccordion)
    }
    const requestConfigAccordionFn = () => {
        setRequestConfigAccordion(!requestConfigAccordion)
    }
    const payListAccordionFn = () => {
        setPayListAccordion(!payListAccordion)
    }

    const [inputMallPwEncryption, setInputMallPwEncryption] = useState({});
    const toggleMallPassword = (index) => {
        setInputMallPwEncryption(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const [inputMallDbPwEncryption, setInputMallDbPwEncryption] = useState({});
    const toggleMallDbPassword = (index) => {
        setInputMallDbPwEncryption(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const tagRemoveCheck = ((e) => {
        if (e.target.checked) {
            setTagRemove(true);
        } else setTagRemove(false);
    })
    const shoesBoxRemoveCheck = ((e) => {
        if (e.target.checked) {
            setShoesBoxRemove(true);
        } else setShoesBoxRemove(false);
    })
    const hangerRemoveCheck = ((e) => {
        if (e.target.checked) {
            setHangerRemove(true);
        } else setHangerRemove(false);
    })
    const compressionCheck = ((e) => {
        if (e.target.checked) {
            setCompressionPack(true);
        } else setCompressionPack(false);
    })
    const prodBoxRemoveCheck = ((e) => {
        if (e.target.checked) {
            setProductBoxRemove(true);
        } else setProductBoxRemove(false);
    })
    const packingHoldCheck = ((e) => {
        if (e.target.checked) {
            setPackingHold(true);
        } else setPackingHold(false);
    })
    const deliDepositPayCheck = ((e) => {
        if (e.target.checked) {
            setDeliveryDepositPay(true);
        } else setDeliveryDepositPay(false);
    })
    const deliMsgGuideCheck = ((e) => {
        if (e.target.checked) {
            setDeliveryMessengerGuide(true);
        } else setDeliveryMessengerGuide(false);
    })
    const detailRequestInput = ((e) => {
        setDetailRequest(e.target.value)
    })

    // add form payinfo update
    const updatePayinfoAddform = useCallback(() => {
        let addFormTotalPrices = 0;
        let addFormTotalMallShipPrice = 0;
        let addFormTotalFee = 0;
        let addFormTotalQty = 0;

        cartProductHandle.forEach((product) => {
            if (product.price) {
                addFormTotalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.quantity) {
                addFormTotalQty += parseInt(product.quantity);
            }
        });
        orderProductHandle.forEach((product) => {
            if (product.price) {
                addFormTotalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.mallShipPrice) {
                addFormTotalMallShipPrice += (parseInt(product.mallShipPrice.replace(/,/g, '')));
            }
            if (product.quantity) {
                addFormTotalQty += parseInt(product.quantity);
            }
        });
        offlineProductHandle.forEach((product) => {
            if (product.price) {
                addFormTotalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.quantity) {
                addFormTotalQty += parseInt(product.quantity);
            }
            if (product.fee) {
                addFormTotalFee += (parseInt(product.fee.replace(/,/g, '')));
            }
        });
        transferProductHandle.forEach((product) => {
            if (product.price) {
                addFormTotalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.fee) {
                addFormTotalFee += (parseInt(product.fee.replace(/,/g, '')))
            }
        });
        domesticProductHandle.forEach((product) => {
            if (product.price) {
                addFormTotalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.mallShipPrice) {
                addFormTotalMallShipPrice += (parseInt(product.mallShipPrice.replace(/,/g, '')));
            }
            if (product.fee) {
                addFormTotalFee += (parseInt(product.fee.replace(/,/g, '')));
            }
            if (product.quantity) {
                addFormTotalQty += parseInt(product.quantity);
            }
        });
        setTotalPrices(addFormTotalPrices);
        setTotalMallShipPrice(addFormTotalMallShipPrice);
        setTotalFee(addFormTotalFee);
        setTotalQuantity(addFormTotalQty);
    }, [cartProductHandle, orderProductHandle, offlineProductHandle, transferProductHandle, domesticProductHandle]);

    // database form payinfo update
    const updatePayinfoDbform = useCallback(() => {
        let dbFormTotalPrices = 0;
        let dbFormTotalMallShipPrice = 0;
        let dbFormTotalFee = 0;
        let dbFormTotalQty = 0;

        dbCartProductHandle.forEach((product) => {
            if (product.prodSt === "IS01") {
                if (product.price) {
                    const price = product.price.toString().replace(/,/g, '');
                    dbFormTotalPrices += parseInt(price);
                }
                if (product.quantity) {
                    dbFormTotalQty += parseInt(product.quantity);
                }
            }
        });

        dbOrderProductHandle.forEach((product) => {
            if (product.prodSt === "IS01") {
                if (product.price) {
                    const price = product.price.toString().replace(/,/g, '');
                    dbFormTotalPrices += parseInt(price);
                }
                if (product.mallShipPrice) {
                    const mallShipPrice = product.mallShipPrice.toString().replace(/,/g, '');
                    dbFormTotalMallShipPrice += parseInt(mallShipPrice);
                }
                if (product.quantity) {
                    dbFormTotalQty += parseInt(product.quantity);
                }
            }
        });

        dbOfflineProductHandle.forEach((product) => {
            if (product.prodSt === "IS01") {
                if (product.price) {
                    const price = product.price.toString().replace(/,/g, '');
                    dbFormTotalPrices += parseInt(price);
                }
                if (product.quantity) {
                    dbFormTotalQty += parseInt(product.quantity);
                }
                if (product.fee) {
                    const fee = product.fee.toString().replace(/,/g, '');
                    dbFormTotalFee += parseInt(fee);
                }
            }
        });

        dbTransferProductHandle.forEach((product) => {
            if (product.prodSt === "IS01") {
                if (product.price) {
                    const price = product.price.toString().replace(/,/g, '');
                    dbFormTotalPrices += parseInt(price);
                }
                if (product.fee) {
                    const fee = product.fee.toString().replace(/,/g, '');
                    dbFormTotalFee += parseInt(fee);
                }
            }
        });

        dbDomesticProductHandle.forEach((product) => {
            if (product.prodSt === "IS01") {
                if (product.price) {
                    const price = product.price.toString().replace(/,/g, '');
                    dbFormTotalPrices += parseInt(price);
                }
                if (product.mallShipPrice) {
                    const mallShipPrice = product.mallShipPrice.toString().replace(/,/g, '');
                    dbFormTotalMallShipPrice += parseInt(mallShipPrice);
                }
                if (product.fee) {
                    const fee = product.fee.toString().replace(/,/g, '');
                    dbFormTotalFee += parseInt(fee);
                }
                if (product.quantity) {
                    dbFormTotalQty += parseInt(product.quantity);
                }
            }
        });

        setDbFormTotalPrices(dbFormTotalPrices);
        setDbFormTotalMallShipPrice(dbFormTotalMallShipPrice);
        setDbFormTotalFee(dbFormTotalFee);
        setDbFormTotalQuantity(dbFormTotalQty);
    }, [dbCartProductHandle, dbOrderProductHandle, dbOfflineProductHandle, dbTransferProductHandle, dbDomesticProductHandle]);

    useEffect(() => {
        const timer = setTimeout(() => {
            bringToShipCo();
            bringToDomesticShipCo();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])

    const allAgreeHandle = (e) => {
        const isChecked = e.target.checked;

        setAllAgreeCheck(isChecked);
        setAgreeCheckBoxError(false);

        if (cartProductHandle.some(cart => cart.name && cart.name.length > 0)) {
            setCartServiceAgreeCheck(isChecked);
        }
        if (orderProductHandle.some(order => order.name && order.name.length > 0)) {
            setOrderServiceAgreeCheck(isChecked);
        }
        if (offlineProductHandle.some(offline => offline.name && offline.name.length > 0)) {
            setOfflineServiceAgreeCheck(isChecked);
        }
        if (transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0)) {
            setTransferServiceAgreeCheck(isChecked);
        }
        if (domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0)) {
            setDomesticServiceAgreeCheck(isChecked);
        }
        if (deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0)) {
            setDeliveryServiceAgreeCheck(isChecked);
        }
    };

    useEffect(() => {
        const visibleConditions = [
            cartProductHandle.some(cart => cart.name && cart.name.length > 0),
            orderProductHandle.some(order => order.name && order.name.length > 0),
            offlineProductHandle.some(offline => offline.name && offline.name.length > 0),
            transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0),
            domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0),
            deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0)
        ];

        const checkedConditions = [
            cartServiceAgreeCheck,
            orderServiceAgreeCheck,
            offlineServiceAgreeCheck,
            transferServiceAgreeCheck,
            domesticServiceAgreeCheck,
            deliveryServiceAgreeCheck
        ];

        const visibleCheckedCount = visibleConditions.map((condition, index) => {
            if (condition) {
                return checkedConditions[index];
            }
            return null;
        }).filter(checked => checked !== null).length;

        const checkedCount = checkedConditions.filter(checked => checked).length;

        if (visibleCheckedCount === checkedCount) {
            setAllAgreeCheck(true);
        } else {
            setAllAgreeCheck(false);
        }
    }, [cartServiceAgreeCheck, orderServiceAgreeCheck, offlineServiceAgreeCheck, transferServiceAgreeCheck, domesticServiceAgreeCheck, deliveryServiceAgreeCheck, cartProductHandle, orderProductHandle, offlineProductHandle, transferProductHandle, domesticProductHandle, deliveryProductHandle]);

    const handleAgreeChange = (checkboxSetter) => (e) => {
        checkboxSetter(e.target.checked);
        setAgreeCheckBoxError(false);
    };

    const chatRef = useRef(null);

    const [fileSizeOverSt, setFileSizeOverSt] = useState(false);
    const closeFileSizeOverSt = (() => {
        setFileSizeOverSt(false);
    })

    const photoChangeHandler = (e) => {
        const file = e.target.files[0];
        const maxSize = 128 * 1024 * 1024; // 최대파일크기 128MB(백엔드에 맞추기)
        if (file.size > maxSize) {
            setFileSizeOverSt(true);
            return;
        }
        setInvoiceFile(file);
        setFileName(file.name)

        const newFileUrl = URL.createObjectURL(file);
        setFileUrl(newFileUrl);
    };

    const invoiceDownload = () => {
        const link = document.createElement('a');
        link.href = fileUrl; // Assuming fileUrl is the URL created from the S3 blob
        link.download = fileName; // This ensures the file is downloaded with the correct filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const loadInvoiceFromS3 = (invoiceFileName, oriFileNm) => {
        axios({
            method: "get",
            url: "/order/getInvoiceFile",
            responseType: "blob",
            headers: {
                Authorization: LCAT
            },
            params: {
                fileName: invoiceFileName,
            },
        }).then((response) => {

            // 파일의 원본 생성 날짜를 설정하기 위해 사용할 예시 날짜 (테스트용으로 현재 날짜를 사용)
            const lastModified = Date.now();

            // Blob 대신 File 객체 생성
            const file = new File([response.data], oriFileNm, {
                type: response.data.type,
                lastModified: lastModified
            });

            const url = URL.createObjectURL(file);
            setInvoiceFile(file); // File 객체를 상태로 설정
            setFileUrl(url); // URL도 업데이트
        });
    };

    const beforeBackendRemoveComma = (str) => {
        return String(str).replace(/,/g, '');
    }

    // 컨트롤러에 결제대기나 뉴폼만 전송
    // const filterModifiedFields = (productList) => {
    //     return productList.filter((product) => product.prodSt === "IS01" || product.newProd === true);
    // };

    const orderProductDataList = () => {
        const orderProductDTO = {};

        const formatPrice = (productList) => {
            return productList.map(product => {
                if (product.price) product.price = beforeBackendRemoveComma(product.price);
                if (product.mallShipPrice) product.mallShipPrice = beforeBackendRemoveComma(product.mallShipPrice);
                if (product.fee) product.fee = beforeBackendRemoveComma(product.fee);
                return product;
            });
        };

        // if (mergedCartProducts.length > 0) {
        //     orderProductDTO.cartProductHandle = formatPrice(filterModifiedFields(mergedCartProducts));
        // }
        // if (mergedOrderProducts.length > 0) {
        //     orderProductDTO.orderProductHandle = formatPrice(filterModifiedFields(mergedOrderProducts));
        // }
        // if (mergedOfflineProducts.length > 0) {
        //     orderProductDTO.offlineProductHandle = formatPrice(filterModifiedFields(mergedOfflineProducts));
        // }
        // if (mergedTransferProducts.length > 0) {
        //     orderProductDTO.transferProductHandle = formatPrice(filterModifiedFields(mergedTransferProducts));
        // }
        // if (mergedDomesticProducts.length > 0) {
        //     orderProductDTO.domesticProductHandle = formatPrice(filterModifiedFields(mergedDomesticProducts));
        // }
        // if (mergedDeliveryProducts.length > 0) {
        //     orderProductDTO.deliveryProductHandle = formatPrice(filterModifiedFields(mergedDeliveryProducts));
        // }

        if (mergedCartProducts.length > 0) {
            orderProductDTO.cartProductHandle = formatPrice(mergedCartProducts);
        }
        if (mergedOrderProducts.length > 0) {
            orderProductDTO.orderProductHandle = formatPrice(mergedOrderProducts);
        }
        if (mergedOfflineProducts.length > 0) {
            orderProductDTO.offlineProductHandle = formatPrice(mergedOfflineProducts);
        }
        if (mergedTransferProducts.length > 0) {
            orderProductDTO.transferProductHandle = formatPrice(mergedTransferProducts);
        }
        if (mergedDomesticProducts.length > 0) {
            orderProductDTO.domesticProductHandle = formatPrice(mergedDomesticProducts);
        }
        if (mergedDeliveryProducts.length > 0) {
            orderProductDTO.deliveryProductHandle = formatPrice(mergedDeliveryProducts);
        }

        return orderProductDTO;
    };

    const orderRequest = {
        seqnum: reqinfoList.seqnum,
        tagRemove: tagRemove,
        shoesBoxRemove: shoesBoxRemove,
        hangerRemove: hangerRemove,
        compressionPack: compressionPack,
        prodBoxRemove: productBoxRemove,
        packingHold: packingHold,
        shipPriceDepositpay: deliveryDepositPay,
        shipPriceMessenger: deliveryMessengerGuide,
        detailReq: detailRequest
    }

    const handlePayWayChange = (method) => {
        const initPaypal = method === "PM01";
        // 준비중(준비되면 initPaypal과 통합)
        const initDollar = method === "PM06" || method === "PM07" || method === "PM08";
        const initYuan = method === "PM02" || method === "PM03";
        if (initPaypal) {
            setCurrency("USD")
        } else if (initDollar) {
            setReadyingPopupSt(true);
            return;
        } else if (initYuan) {
            setCurrency("CNY")
        } else {
            setCurrency("")
        }
        setPayWay(method);
        setPayMethodError(false);
        setPointPayError(false);
        setDepositSelectErr(false);
        setPointSelectErr(false);
        setEtcPayError(false);
    }

    const closeReadyingPopup = (() => {
        setReadyingPopupSt(false);
    })

    const [paypalPayFl, setPaypalPayFl] = useState(false);
    const [couponSeqnum, setCouponSeqnum] = useState('')
    const removeCommas = (str) => {
        return str.replace(/,/g, '');
    }

    const orderFinalPayInfo = {
        // seqNum: payinfoList.seqNum,
        pointUsePrice: pointSale ? pointSale : 0 ,
        depUsePrice: depositSale ? depositSale : 0,
        couponUseNum: couponSeqnum,
        couponUsePrice: couponSale ? couponSale : 0,
        lastPrice: finalPayExpense,
        ttProdPrice: finalProdExpense,
        ttMallShipPrice: finalMallShipPrice,
        ttFee: finalFee,
        ttProdExpense: (Number(removeCommas(finalProdExpense)) + Number(removeCommas(finalMallShipPrice))),
        ttEtcPrice: finalEtcExpense,
        ttShipExpense: finalOverseasShipExpense,
        payStatus: "PS1",
        payWay: payWay,
        payClass: '0',
    }

    const ordermstData = {
        seqnum: ordermstList.seqnum,
        oriOrdNum: ordermstList.oriOrdNum,
    }

    const orderdtData = {
        seqnum: orderdtList.seqnum,
        orderStatus: orderdtList.orderStatus,
        orderNumber: orderdtList.orderNumber,
        recinfoDTO: orderdtList.recinfoDTO,
        useType: useType,
        shipco: shipcoSelectedOption,
        cartAgree: !!(mergedCartProducts.some(cart => cart.name && cart.name.length > 0) && cartServiceAgreeCheck || orderdtList.cartAgree),
        orderAgree: !!(mergedOrderProducts.some(order => order.name && order.name.length > 0) && orderServiceAgreeCheck || orderdtList.orderAgree),
        offlineAgree: !!(mergedOfflineProducts.some(offline => offline.name && offline.name.length > 0) && offlineServiceAgreeCheck || orderdtList.offlineAgree),
        transferAgree: !!(mergedTransferProducts.some(transfer => transfer.name && transfer.name.length > 0) && transferServiceAgreeCheck || orderdtList.transferAgree),
        domesticAgree: !!(mergedDomesticProducts.some(domestic => domestic.name && domestic.name.length > 0) && domesticServiceAgreeCheck || orderdtList.domesticAgree),
        shipAgree: !!(mergedDeliveryProducts.some(delivery => delivery.name && delivery.name.length > 0) && deliveryServiceAgreeCheck || orderdtList.shipAgree),
    }

    const orderRecinfo = {
        seqnum: recinfoList.seqnum,
        country: selectedCountry.value,
        taxid: taxid,
        recname: recname,
        telnum: telnum,
        zipcode: zipcode,
        address1: address1,
        address2: address2,
        address3: address3,
        city: city
    }

    // orderdt에서 배송용도+배송사만 일부수정(분리)
    const orderRecinfoShip = {
        seqnum: orderdtList.seqnum,
        useType: useType,
        shipco: shipcoSelectedOption,
    }

    const convertedOrderFinalPrice = Object.entries(orderFinalPayInfo).reduce((acc, [key, value]) => {
        if (key === 'payStatus' || key === 'payClass' || key === 'payWay' || key === 'forCurrency') {
            acc[key] = value;
        } else if (typeof value === 'number' && !Number.isInteger(value)) {
            acc[key] = parseFloat(String(value).replace(',', ''));
        } else {
            acc[key] = parseInt(String(value).replace(',', ''));
        }
        return acc;
    }, {});

    const [deletedProductsData, setDeletedProductsData] = useState({
        deletedDbCartProducts: [],
        deletedDbOrderProducts: [],
        deletedDbOfflineProducts: [],
        deletedDbTransferProducts: [],
        deletedDbDomesticProducts: [],
        deletedDbDeliveryProducts: [],
    });

    const orderDataSave = async (data) => {
        if (data === "COMPLETED") {
            setPaypalPayFl(true);
        } else if (data) {
            await sendOrderDataToServer(true);
        } else {
            await sendOrderDataToServer(false);
        }
    };

    useEffect(() => {
        if (paypalPayFl) {
            sendOrderDataToServer(false);
        }
    }, [paypalPayFl]);

    const sendOrderDataToServer = (saveFl) => {
        if (saveFl) {
            convertedOrderFinalPrice.pointUsePrice = 0;
            convertedOrderFinalPrice.depUsePrice = 0;
            convertedOrderFinalPrice.couponUseNum = '';
            convertedOrderFinalPrice.couponUsePrice = 0;
            convertedOrderFinalPrice.lastPrice = totalPrices + totalMallShipPrice + totalFee + totalOverseasShippingExpense + totalEtcExpense;
        }

        const orderProductListSave = orderProductDataList();
        const selectedPayinfos = getSelectedPayinfos();

        const formData = new FormData();
        if (invoiceFile) {
            formData.append('file', invoiceFile);
        }

        formData.append('orderdtData', JSON.stringify(orderdtData));
        formData.append('orderRecinfo', JSON.stringify(orderRecinfo));
        formData.append('ordermstData', JSON.stringify(ordermstData));
        formData.append('orderRequest', JSON.stringify(orderRequest));
        formData.append('orderFinalPayInfo', JSON.stringify(convertedOrderFinalPrice));
        formData.append('orderProductListSave', JSON.stringify(orderProductListSave));
        formData.append("deletedProductsData", JSON.stringify(deletedProductsData));
        formData.append('selectedPayinfos', JSON.stringify(selectedPayinfos));
        formData.append('couponSeqnum', couponSeqnum);

        selectedPayinfos.forEach((payInfo, index) => {
            formData.append(`selectedPayinfos[${index}]`, JSON.stringify(payInfo));
            if (payInfo.packListDTO && payInfo.packListDTO.productsDTO) {
                formData.append(`selectedPayinfos[${index}].packListDTO.productsDTO`, JSON.stringify(payInfo.packListDTO.productsDTO));
            }
        })

        axios({
            method: 'post',
            url: '/order/orderModify',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
            params: {
                paypalPayFl: paypalPayFl,
                orderSaveFl: saveFl
            }
        })
            .then(async (response) => {
                const {allProducts, ...filterData} = response.data;
                // 관리자메인에 오더알림업뎃
                saveOrderAlarmData(filterData);
                // 수정 후 전체 하위상품상태에 따라 orderSt 변경
                orderStatusUpdate(stompClient, allProducts, response.data.orderdtSeqNum);
                navigate("/btcorpad")
            })
            .catch(() => {
                console.log('order fail');
            })
            .finally(() => {
                setIsSaveBtLoading(false);
                setIsPayBtLoading(false);
            })
    };

    const saveOrderAlarmData = ((orderData) => {
        axios({
            method: 'post',
            url: '/order/saveOrderData',
            headers: {
                Authorization: LCAT,
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({orderData: orderData}),
        })
            .then(async () => {
                await stompClient.send("/app/sendOrderAlarm", {}, JSON.stringify({sendFl: true}));
            })
            .catch((error) => {
                console.log("알림데이터 저장실패 : ", error)
            })
    })

    const [dollarShow, setDollarShow] = useState(false);
    const dollarRef = useRef(null);

    const toggleDollarShow = () => {
        setDollarShow(!dollarShow);
    };

    const selectChangeDollar = (e) => {
        setCurrency(e);
        setDollarShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (dollarRef.current && !dollarRef.current.contains(event.target)) {
                setDollarShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const paypalOrderProcess = (() => {
        paypalPopupClose();
        orderDataSave("COMPLETED");
    })

    const updateProductHandleStatesBatch = useCallback((products) => {
        const cartProducts = [];
        const orderProducts = [];
        const offlineProducts = [];
        const transferProducts = [];
        const domesticProducts = [];
        const deliveryProducts = [];

        products.forEach(product => {
            const productData = {...product};
            switch (product.prodTy) {
                case 'P00':
                    cartProducts.push(productData);
                    break;
                case 'P01':
                    orderProducts.push(productData);
                    break;
                case 'P02':
                    offlineProducts.push(productData);
                    break;
                case 'P03':
                    transferProducts.push(productData);
                    break;
                case 'P04':
                    domesticProducts.push(productData);
                    break;
                case 'P05':
                    deliveryProducts.push(productData);
                    break;
            }
        });

        setDbCartProductHandle(cartProducts);
        setDbOrderProductHandle(orderProducts);
        setDbOfflineProductHandle(offlineProducts);
        setDbTransferProductHandle(transferProducts);
        setDbDomesticProductHandle(domesticProducts);
        setDbDeliveryProductHandle(deliveryProducts);
    }, []);

    const checkProductTypes = () => {
        const allProducts = [
            ...dbCartProductHandle,
            ...dbOrderProductHandle,
            ...dbOfflineProductHandle,
            ...dbTransferProductHandle,
            ...dbDomesticProductHandle,
            ...dbDeliveryProductHandle,
            ...cartProductHandle,
            ...orderProductHandle,
            ...offlineProductHandle,
            ...transferProductHandle,
            ...domesticProductHandle,
            ...deliveryProductHandle
        ];
        return allProducts.every(product => product.prodSt === "IS00" || product.prodSt === "IS01" || product.prodSt === "IS12");
    }

    const [deleteOrderModalShowSt, setDeleteOrderModalShowSt] = useState(false);
    const deleteOrderMsg1 = "주문서를 삭제하시겠습니까?"
    const deleteOrderMsg2 = "삭제된 주문서는 복구할 수 없습니다"
    const deleteOrderFalseFn = (() => {
        setDeleteOrderModalShowSt(false);
    })
    const deleteOrderTrueFn = (() => {
        setDeleteOrderModalShowSt(true);
    })

    const deleteOrder = (() => {
        axios({
            method: 'post',
            url: '/order/deleteOrder',
            data: {
                orderdtSeqnum: orderdtList.seqnum,
                ordermstSeqnum: ordermstList.seqnum,
                orderRequestSeqnum: reqinfoList.seqnum,
                orderRecinfoSeqnum: recinfoList.seqnum,
                invoiceFileName: orderdtList.invoiceFileName
                // 여기에 왜 PayInfoVO는 없음?
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then(() => {
                setDeleteOrderModalShowSt(false);
                if (window.opener) {
                    window.opener.location.reload();
                    window.close();
                }
            })
            .catch(() => {
                console.log("삭제실패")
            })
    })

    const [updatedRecipientInfo, setUpdatedRecipientInfo] = useState(null);

    useEffect(() => {
        if (updatedRecipientInfo) {
            setRecinfoList((prevState) => [
                {
                    ...prevState[0],
                    ...updatedRecipientInfo,
                },
                ...prevState.slice(1),
            ]);
        } else {
            chatRef.current.scrollIntoView({behavior: 'smooth'});

        }
    }, [updatedRecipientInfo, ordNum]);

    const location = useLocation();
    // 상품폼 중복맵핑방지
    useEffect(() => {
        setDbCartProductHandle([]);
        setDbOrderProductHandle([]);
        setDbOfflineProductHandle([]);
        setDbTransferProductHandle([]);
        setDbDomesticProductHandle([]);
        setDbDeliveryProductHandle([]);
        chatRef.current.scrollIntoView({behavior: 'smooth'});
    }, [location.key]);

    useEffect(() => {
        setCartOrderAccordion(dbCartProductHandle.length > 0);
        setOrderAccordion(dbOrderProductHandle.length > 0);
        setOfflineAccordion(dbOfflineProductHandle.length > 0);
        setTransferAccordion(dbTransferProductHandle.length > 0);
        setDomesticAccordion(dbDomesticProductHandle.length > 0);
        setDeliveryAccordion(dbDeliveryProductHandle.length > 0);
    }, [dbCartProductHandle, dbOrderProductHandle, dbOfflineProductHandle, dbTransferProductHandle, dbDomesticProductHandle, dbDeliveryProductHandle]);

    const [cartProductImage] = useState(cartImage);

    // 채팅메시지 입력
    const [message, setMessage] = useState('');

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!memberInfo.mbNum || isSubscribedRef.current) return;
            if (!stompClient && !isConnected) {
                await websocketConnect(memberInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                subscribe(`/topic/userOrdMessage`, onMessageReceived);
                subscribe(`/topic/userOrdChatRead`, onReadMessageReceived);
                subscribe(`/topic/adminChatUpdate`, onChatAdminUpdate);
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                unsubscribe(`/topic/userOrdMessage`)
                unsubscribe(`/topic/userOrdChatRead`)
                unsubscribe(`/topic/adminChatUpdate`)
                isSubscribedRef.current = false;
            }
        };

    }, [memberInfo.mbNum, isConnected, stompClient]);

    const [chatMessages, setChatMessages] = useState([]);
    const [unreadCount, setUnreadCount] = useState(null);
    const [lastChatKey, setLastChatKey] = useState(null);
    const [lastFileKey, setLastFileKey] = useState(null);
    const [chatShow, setChatShow] = useState(false);

    const orderListRef = useRef(orderdtList?.orderNumber);
    const chatShowRef = useRef(chatShow);

    const chatFrameHandle = (() => {
        setChatShow(true);
        resetUnreadChat({...chatMessages[0]});
    })

    const onMessageReceived = (payload) => {
        let newMessage = JSON.parse(payload.body);

        if (newMessage.chatKey.startsWith('chatFile')) {
            const filename = newMessage.chatKey.split('/').pop();
            if (isVideo(filename)) {
                newMessage.isVideo = true;
            } else if (isImage(filename)) {
                newMessage.isImage = true;
            } else {
                newMessage.isFile = true;
            }
        }

        if (newMessage.memberDTO.role === '0') {
            setChatMessages(chatMessages => {
                // 중복 메시지를 체크하고 제거하는 로직 추가
                const isDuplicate = chatMessages.some(message => message.seqnum === newMessage.seqnum);
                if (isDuplicate) return chatMessages; // 이미 메시지가 존재하면 추가하지 않음

                const newChatMessages = [...chatMessages, newMessage];
                newChatMessages.sort((a, b) => b.seqnum - a.seqnum);
                setLastChatKey(newChatMessages[0].chatKey);
                setLastFileKey(newChatMessages[0].fileNm);
                return newChatMessages;
            });
        }

        fetchChatMessages(newMessage.chatOrdNum)

        // 새로 등록된 메시지의 chatOrdNum과 내 주문번호와 동일 && chatStarted가 true 두 조건 모두 충족할 경우 자동읽음처리
        if (newMessage.chatOrdNum === orderListRef.current && chatShowRef.current) {
            resetUnreadChat(newMessage);
            stompClientRead(newMessage.chatOrdNum, newMessage.mstUserNm, newMessage.mstMbNum);
        }
    };

    const onChatAdminUpdate = ((payload) => {
        let adminUpdateData = JSON.parse(payload.body);
        if (adminUpdateData !== null) {
            fetchChatMessages(adminUpdateData);
        }
    })

    // 함수 내 비동기로 인해 미리 값 세팅
    useEffect(() => {
        orderListRef.current = orderdtList?.orderNumber;
        chatShowRef.current = chatShow;
    }, [orderdtList, chatShow]);


    const isImage = (fileName) => {
        const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp"];
        const fileExtension = fileName.split('.').pop().toLowerCase();
        return imageExtensions.includes(fileExtension);
    }

    const sendMessage = async (printFilename, originalFilename) => {
        if (stompClient && printFilename.trim() !== '') {
            const chatMessage = {
                chatKey: printFilename,
                fileNm: originalFilename,
                chatOrdNum: Number(orderdtList?.orderNumber),
                mbNum: selectTokenMbNum,
                mstMbNum: orderdtList?.memberDTO?.mbNum,
                mstUserNm: orderdtList?.memberDTO?.userName,
                sendMailFl: true,
                autoChatFl: false,
            };
            // console.log("Sending message: ", chatMessage);
            await stompClient.send("/app/chat/adminMessage", {}, JSON.stringify(chatMessage));
            setChatShow(true);
            chatInputRef.current.style.height = '42px'

            await new Promise(resolve => setTimeout(resolve, 500));
            fetchChatMessages(chatMessage.chatOrdNum);
        } else {
            console.log("메시지 전송실패");
        }
    };

    // 채팅 메시지 실시간 업뎃/출력
    const fetchChatMessages = async (chatOrdNum) => {
        if (!chatOrdNum) {
            return;
        }
        try {
            const response = await axios({
                method: 'get',
                url: '/order/chatFetch',
                headers: {
                    Authorization: LCAT
                },
                params: {
                    chatOrdNum: chatOrdNum
                }
            });
            if (!response || response.data === false) {
                console.log('데이터없음');
                return;
            }
            const data = response.data;
            data.sort((a, b) => b.seqnum - a.seqnum);

            for (let message of data) {
                if (message.chatKey.startsWith('chatFile')) {
                    const filename = message.chatKey.split('/').pop();
                    if (isVideo(filename)) {
                        message.fileUrl = 'https://toeloperfile.s3.ap-northeast-2.amazonaws.com/ordChatMessage/' + message.chatKey;
                        message.isVideo = true;
                    } else if (isImage(filename)) {
                        message.fileUrl = 'https://toeloperfile.s3.ap-northeast-2.amazonaws.com/ordChatMessage/' + message.chatKey;
                        message.isImage = true;
                    } else {
                        message.fileUrl = 'file';
                    }
                }

                if (message &&
                    message.memberDTO &&
                    message.memberDTO.profileDTO &&
                    message.memberDTO.profileDTO.filename &&
                    message.memberDTO.role === '1') {
                    setChatProfileImg('https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/' + message.memberDTO.profileDTO.filename);
                    break;
                }
            }

            setChatMessages(data);
            if (data && data.length > 0) {
                if (data[0].memberDTO.role === '0') {
                    setUnreadCount(data[0].unreadCount);
                }
                setLastChatKey(data[0].chatKey);
                setLastFileKey(data[0].fileNm);
            }

            return data;

        } catch (error) {
            console.error('Error fetching chat messages:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (orderdtList) {
            fetchChatMessages(orderdtList && orderdtList.orderNumber)
                .then(data => setChatMessages(data))
                .catch(err => console.error(err));
        }
    }, [orderdtList]);

    const onReadMessageReceived = (payload) => {
        // 사용자가 읽었을 경우 관리자채팅 옆 1삭제
        let userReadData = JSON.parse(payload.body);
        if (userReadData.readerRole === "USER") {
            setChatMessages(currentChatMessages => {
                const updatedMessages = [...currentChatMessages];
                let hasUpdates = false;
                for (let message of updatedMessages) {
                    if (message.chatOrdNum === String(userReadData.chatOrdNum) && message.unreadCount > 0 && message.memberDTO.role === '1') {
                        message.unreadCount = 0;
                        hasUpdates = true;
                    }
                }
                return updatedMessages;
            })
        }
    };

    const messagesEndRef = useRef(null);

    const scrollToBottom = (() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTo({
                top: messagesEndRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    })

    useEffect(scrollToBottom, [chatMessages]);

    const getDayOfWeek = (date) => {
        const dayOfWeek = new Date(date).getDay(); // 일요일은 0, 월요일은 1, ... 토요일은 6
        return [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat'),][dayOfWeek];
    };

    const formatDate = (dateString) => {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);

        // 요일 계산
        const date = new Date(`${year}/${month}/${day}`);
        const dayOfWeek = getDayOfWeek(date);

        return `${year}/${month}/${day} ${dayOfWeek}`;
    };

    function isSameDay(date1, date2) {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }

    function isFirstMessageOfDay(current, previous) {
        if (!previous) {
            return true; // 이전 메시지가 없다면 현재 메시지는 당연히 첫 번째 메시지입니다.
        }
        // 날짜 문자열을 분리하여 Date 객체를 생성합니다.
        const currentYear = current.regDate.substring(0, 4);
        const currentMonth = current.regDate.substring(4, 6);
        const currentDay = current.regDate.substring(6, 8);
        const currentDate = new Date(`${currentYear}-${currentMonth}-${currentDay}`);

        const previousYear = previous.regDate.substring(0, 4);
        const previousMonth = previous.regDate.substring(4, 6);
        const previousDay = previous.regDate.substring(6, 8);
        const previousDate = new Date(`${previousYear}-${previousMonth}-${previousDay}`);

        return !isSameDay(currentDate, previousDate);
    }

    const formatTime = (timeString) => {
        const parts = timeString.split(/[- :]/);
        const date = new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
        return date.toTimeString().split(' ')[0];
    };

    const [chatProfileImg, setChatProfileImg] = useState(BtorageLogo);

    const resetUnreadChat = ((newMessage) => {
        axios({
            method: 'post',
            url: `/order/chat/unread/`,
            params: {
                chatOrdNum: newMessage.chatOrdNum,
                userFl: false
            },
            headers: {
                Authorization: LCAT
            }
        })
                .then(response => {
                    if (response.status !== 200) {
                        throw new Error("HTTP status " + response.status);
                    } else {
                        setUnreadCount(0);
                        stompClientRead(newMessage.chatOrdNum, newMessage.mstUserNm, newMessage.mstMbNum);
                    }
                })
                .catch(error => console.error(error));
    })

    const stompClientRead = (async (chatOrdNum, mstUserNm, mstMbNum) => {
        if (!stompClient) {
            return;
        }
        const readChat = {
            chatOrdNum: chatOrdNum,
            mstMbNum: mstMbNum,
            mstUserNm: mstUserNm,
            readerRole: "ADMIN",
        };
        await stompClient.send("/app/chat/adminRead", {}, JSON.stringify(readChat));
    })

    const [inputChatValue, setInputChatValue] = useState('');
    const [showEmoji, setShowEmoji] = useState(false);
    const [showChatFile, setShowChatFile] = useState(false);

    const [selectedFileUrl, setSelectedFileUrl] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleEmojiClick = (emoji) => {
        setMessage(inputChatValue + emoji.native);
        setShowEmoji(false);
    };

    const fileInputRef = useRef();
    const handleFileUpload = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedFile(file);
            setSelectedFileUrl(URL.createObjectURL(file));
        }
    };

    const saveChatFile = async () => {
        if (!selectedFile || !selectedFile.size) {
            setShowChatFile(false);
            return;
        }
        const MAX_FILE_SIZE = 128 * 1024 * 1024; // 최대파일크기 128MB(백엔드에 맞추기)
        if (selectedFile.size > MAX_FILE_SIZE) {
            setShowChatFile(false);
            resetFileInput();
            setFileSizeOverSt(true);
            return;
        }
        const formData = new FormData();
        formData.append('file', selectedFile);
        const response = await axios.post('/order/upload', formData, {
        });
        if (!response.ok) {
            // console.log('File upload failed');
            return;
        }
        const fileKeys = await response.json();
        await sendMessage(fileKeys.printFilename, fileKeys.originalFilename);
        setChatShow(true);
        setShowChatFile(false);
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
        setShowChatFile(true);
    };

    const showChatFileClose = (() => {
        setShowChatFile(false);
        resetFileInput();
    })

    const resetFileInput = () => {
        fileInputRef.current.value = "";
        setSelectedFile(null);
        setSelectedFileUrl(null);
    };

    const downloadFile = async (filename, originalFilename) => {
        const response = await axios.get(`/order/chatFileDownload/${filename}`, {
            responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', originalFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setSelectedClickSt(false);
        openDownloadFileCompl();
    }
    const [downloadFileComplSt, setDownloadFileComplSt] = useState(false);
    const openDownloadFileCompl = (() => {
        setDownloadFileComplSt(true);
    })
    const closeDownloadFileCompl = (() => {
        setDownloadFileComplSt(false);
    })

    const isVideo = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        return ['mp4', 'webm', 'ogg'].includes(extension);
    };

    const oneMonthFromNow = (deadline) => {
        return deadline.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    }

    // 챗메시지 인풋조절
    const chatInputRef = React.useRef(null);
    const resizeTextArea = () => {
        const textArea = chatInputRef.current;
        // 최소 높이 설정 후 스크롤 높이에 맞게 늘어나도록 함
        textArea.style.height = '35px';
        textArea.style.height = textArea.scrollHeight + "px";
    };
    const handleChatInput = (e) => {
        setMessage(e.target.value);
        resizeTextArea();
    };

    const [detailState, setDetailState] = useState({
        prod: false,
        delivery: false,
        etc: false,
        adminProd: false,
        paySeqnum: null,
    });

    const showDetail = ((type, paySeqnum) => {
        // set the state of the selected type to true and others to false
        setDetailState(prevState => ({
            ...prevState,
            prod: type === 'prod',
            delivery: type === 'delivery',
            etc: type === 'etc',
            adminProd: type === 'adminProd',
            paySeqnum: paySeqnum,
        }));
    })

    const closeDetail = (() => {
        setDetailState({prod: false, delivery: false, etc: false, adminProd: false});
    })

    const [selectedPayInfos, setSelectedPayInfos] = useState({});
    const [allSelectedPaylist, setAllSelectedPaylist] = useState(false);
    const [selectedOverseasShippingCost, setSelectedOverseasShippingCost] = useState({});
    const [lastSelectedOverseasShippingCost, setLastSelectedOverseasShippingCost] = useState({});

    const handlePaylistCheckboxChange = (seqNum, isChecked) => {
        setSelectedPayInfos(prevState => {
            return {...prevState, [seqNum]: isChecked};
        });

        if (isChecked && lastSelectedOverseasShippingCost[seqNum]) {
            setSelectedOverseasShippingCost(prevState => {
                return {...prevState, [seqNum]: lastSelectedOverseasShippingCost[seqNum]};
            });
        } else if (!isChecked) {
            setLastSelectedOverseasShippingCost(prevState => {
                return {...prevState, [seqNum]: selectedOverseasShippingCost[seqNum]};
            });
            setSelectedOverseasShippingCost(prevState => {
                return {...prevState, [seqNum]: ''};
            });
        }
    };

    const handleAllPaylistCheckboxChange = (isChecked) => {
        setAllSelectedPaylist(isChecked);
        const newSelectedPayInfos = {};
        const newSelectedOverseasShippingCost = {};
        payinfoList.forEach((payInfo, index) => {
            newSelectedPayInfos[payInfo.seqNum] = isChecked;
            // 선택된 해외배송료[array]는 payInfo에 합산
            if (isChecked && lastSelectedOverseasShippingCost[payInfo.seqNum]) {
                newSelectedOverseasShippingCost[payInfo.seqNum] = lastSelectedOverseasShippingCost[payInfo.seqNum];
            } else if (!isChecked) {
                lastSelectedOverseasShippingCost[payInfo.seqNum] = selectedOverseasShippingCost[payInfo.seqNum];
                newSelectedOverseasShippingCost[payInfo.seqNum] = '';
            }
        });
        setSelectedPayInfos(newSelectedPayInfos);
        setSelectedOverseasShippingCost(newSelectedOverseasShippingCost);
    };

    useEffect(() => {
        const allItemsChecked = Object.values(selectedPayInfos).every(val => val === true);
        setAllSelectedPaylist(allItemsChecked);
    }, [selectedPayInfos]);

    const handleRadioChange = (seqNum, value) => {
        setSelectedOverseasShippingCost(prevState => {
            return {...prevState, [seqNum]: value};
        });
        setLastSelectedOverseasShippingCost(prevState => {
            return {...prevState, [seqNum]: value};
        });
    };

    // 결제리스트에서 선택한 해외배송 payinfo 데이터들
    const getSelectedPayinfos = () => {
        return payinfoList.filter(payInfo => {
            const id = payInfo.seqNum;
            if (payInfo.payStatus !== "PS0") {
                return false;
            }
            if (payInfo.payType === "PT1") {
                return selectedPayInfos[id] === true;
            }
            if (payInfo.payType !== "PT1") {
                return true;
            }
        }).map(payInfo => {
            const id = payInfo.seqNum;
            let newPayInfo = {...payInfo};

            if (selectedOverseasShippingCost[id] === 'FedEx') {
                newPayInfo.fedexPrice = payInfo.fedexPrice
                newPayInfo.fedexFuelSurch = payInfo.fedexFuelSurch
                newPayInfo.fedexAddFee = payInfo.fedexAddFee
                newPayInfo.fedexInsrFee = payInfo.fedexInsrFee
                newPayInfo.fedexOutOfArea = payInfo.fedexOutOfArea
                newPayInfo.ttFedexPrice = payInfo.ttFedexPrice

                newPayInfo.emsPrice = 0;
                newPayInfo.emsAddFee = 0;
                newPayInfo.emsInsrFee = 0;
                newPayInfo.ttEmsPrice = 0;

                newPayInfo.seaPrice = 0;
                newPayInfo.seaAddFee = 0;
                newPayInfo.seaInsrFee = 0;
                newPayInfo.ttSeaPrice = 0;

                newPayInfo.ttDomPrice = 0;

                newPayInfo.dhlPrice = 0;
                newPayInfo.dhlAddFee = 0;
                newPayInfo.dhlInsrFee = 0;
                newPayInfo.ttDhlPrice = 0;

                newPayInfo.upsPrice = 0;
                newPayInfo.upsFuelSurch = 0;
                newPayInfo.upsAddFee = 0;
                newPayInfo.upsInsrFee = 0;
                newPayInfo.upsOutOfArea = 0;
                newPayInfo.ttUpsPrice = 0;

                newPayInfo.kPackPrice = 0;
                newPayInfo.kPackAddFee = 0;
                newPayInfo.kPackInsrFee = 0;
                newPayInfo.ttKPackPrice = 0;

                newPayInfo.airSPrice = 0;
                newPayInfo.airSAddFee = 0;
                newPayInfo.airSInsrFee = 0;
                newPayInfo.ttAirSPrice = 0;

            } else if (selectedOverseasShippingCost[id] === 'EMS') {

                newPayInfo.fedexPrice = 0;
                newPayInfo.fedexFuelSurch = 0;
                newPayInfo.fedexAddFee = 0;
                newPayInfo.fedexInsrFee = 0;
                newPayInfo.fedexOutOfArea = 0;
                newPayInfo.ttFedexPrice = 0;

                newPayInfo.emsPrice = payInfo.emsPrice
                newPayInfo.emsAddFee = payInfo.emsAddFee
                newPayInfo.emsInsrFee = payInfo.emsInsrFee
                newPayInfo.ttEmsPrice = payInfo.ttEmsPrice

                newPayInfo.seaPrice = 0;
                newPayInfo.seaAddFee = 0;
                newPayInfo.seaInsrFee = 0;
                newPayInfo.ttSeaPrice = 0;

                newPayInfo.ttDomPrice = 0;

                newPayInfo.dhlPrice = 0;
                newPayInfo.dhlAddFee = 0;
                newPayInfo.dhlInsrFee = 0;
                newPayInfo.ttDhlPrice = 0;

                newPayInfo.upsPrice = 0;
                newPayInfo.upsFuelSurch = 0;
                newPayInfo.upsAddFee = 0;
                newPayInfo.upsInsrFee = 0;
                newPayInfo.upsOutOfArea = 0;
                newPayInfo.ttUpsPrice = 0;

                newPayInfo.kPackPrice = 0;
                newPayInfo.kPackAddFee = 0;
                newPayInfo.kPackInsrFee = 0;
                newPayInfo.ttKPackPrice = 0;

                newPayInfo.airSPrice = 0;
                newPayInfo.airSAddFee = 0;
                newPayInfo.airSInsrFee = 0;
                newPayInfo.ttAirSPrice = 0;

            } else if (selectedOverseasShippingCost[id] === 'Sea') {

                newPayInfo.fedexPrice = 0;
                newPayInfo.fedexFuelSurch = 0;
                newPayInfo.fedexAddFee = 0;
                newPayInfo.fedexInsrFee = 0;
                newPayInfo.fedexOutOfArea = 0;
                newPayInfo.ttFedexPrice = 0;

                newPayInfo.emsPrice = 0;
                newPayInfo.emsAddFee = 0;
                newPayInfo.emsInsrFee = 0;
                newPayInfo.ttEmsPrice = 0;

                newPayInfo.seaPrice = payInfo.seaPrice
                newPayInfo.seaAddFee = payInfo.seaAddFee
                newPayInfo.seaInsrFee = payInfo.seaInsrFee
                newPayInfo.ttSeaPrice = payInfo.ttSeaPrice

                newPayInfo.ttDomPrice = 0;

                newPayInfo.dhlPrice = 0;
                newPayInfo.dhlAddFee = 0;
                newPayInfo.dhlInsrFee = 0;
                newPayInfo.ttDhlPrice = 0;

                newPayInfo.upsPrice = 0;
                newPayInfo.upsFuelSurch = 0;
                newPayInfo.upsAddFee = 0;
                newPayInfo.upsInsrFee = 0;
                newPayInfo.upsOutOfArea = 0;
                newPayInfo.ttUpsPrice = 0;

                newPayInfo.kPackPrice = 0;
                newPayInfo.kPackAddFee = 0;
                newPayInfo.kPackInsrFee = 0;
                newPayInfo.ttKPackPrice = 0;

                newPayInfo.airSPrice = 0;
                newPayInfo.airSAddFee = 0;
                newPayInfo.airSInsrFee = 0;
                newPayInfo.ttAirSPrice = 0;

            } else if (selectedOverseasShippingCost[id] === 'Dom'){
                newPayInfo.fedexPrice = 0;
                newPayInfo.fedexFuelSurch = 0;
                newPayInfo.fedexAddFee = 0;
                newPayInfo.fedexInsrFee = 0;
                newPayInfo.fedexOutOfArea = 0;
                newPayInfo.ttFedexPrice = 0;

                newPayInfo.emsPrice = 0;
                newPayInfo.emsAddFee = 0;
                newPayInfo.emsInsrFee = 0;
                newPayInfo.ttEmsPrice = 0;

                newPayInfo.seaPrice = 0;
                newPayInfo.seaInsrFee = 0;
                newPayInfo.ttSeaPrice = 0;

                newPayInfo.dhlPrice = 0;
                newPayInfo.dhlAddFee = 0;
                newPayInfo.dhlInsrFee = 0;
                newPayInfo.ttDhlPrice = 0;

                newPayInfo.upsPrice = 0;
                newPayInfo.upsFuelSurch = 0;
                newPayInfo.upsAddFee = 0;
                newPayInfo.upsInsrFee = 0;
                newPayInfo.upsOutOfArea = 0;
                newPayInfo.ttUpsPrice = 0;

                newPayInfo.kPackPrice = 0;
                newPayInfo.kPackAddFee = 0;
                newPayInfo.kPackInsrFee = 0;
                newPayInfo.ttKPackPrice = 0;

                newPayInfo.airSPrice = 0;
                newPayInfo.airSAddFee = 0;
                newPayInfo.airSInsrFee = 0;
                newPayInfo.ttAirSPrice = 0;

                newPayInfo.ttDomPrice = payInfo.ttDomPrice
            } else if (selectedOverseasShippingCost[id] === 'DHL'){
                newPayInfo.fedexPrice = 0;
                newPayInfo.fedexFuelSurch = 0;
                newPayInfo.fedexAddFee = 0;
                newPayInfo.fedexInsrFee = 0;
                newPayInfo.fedexOutOfArea = 0;
                newPayInfo.ttFedexPrice = 0;

                newPayInfo.emsPrice = 0;
                newPayInfo.emsAddFee = 0;
                newPayInfo.emsInsrFee = 0;
                newPayInfo.ttEmsPrice = 0;

                newPayInfo.seaPrice = 0;
                newPayInfo.seaInsrFee = 0;
                newPayInfo.ttSeaPrice = 0;

                newPayInfo.dhlPrice = payInfo.dhlPrice
                newPayInfo.dhlAddFee = payInfo.dhlAddFee
                newPayInfo.dhlInsrFee = payInfo.dhlInsrFee
                newPayInfo.ttDhlPrice = payInfo.ttDhlPrice

                newPayInfo.upsPrice = 0;
                newPayInfo.upsFuelSurch = 0;
                newPayInfo.upsAddFee = 0;
                newPayInfo.upsInsrFee = 0;
                newPayInfo.upsOutOfArea = 0;
                newPayInfo.ttUpsPrice = 0;

                newPayInfo.kPackPrice = 0;
                newPayInfo.kPackAddFee = 0;
                newPayInfo.kPackInsrFee = 0;
                newPayInfo.ttKPackPrice = 0;

                newPayInfo.airSPrice = 0;
                newPayInfo.airSAddFee = 0;
                newPayInfo.airSInsrFee = 0;
                newPayInfo.ttAirSPrice = 0;

                newPayInfo.ttDomPrice = 0;
            } else if (selectedOverseasShippingCost[id] === 'UPS'){
                newPayInfo.fedexPrice = 0;
                newPayInfo.fedexFuelSurch = 0;
                newPayInfo.fedexAddFee = 0;
                newPayInfo.fedexInsrFee = 0;
                newPayInfo.fedexOutOfArea = 0;
                newPayInfo.ttFedexPrice = 0;

                newPayInfo.emsPrice = 0;
                newPayInfo.emsAddFee = 0;
                newPayInfo.emsInsrFee = 0;
                newPayInfo.ttEmsPrice = 0;

                newPayInfo.seaPrice = 0;
                newPayInfo.seaInsrFee = 0;
                newPayInfo.ttSeaPrice = 0;

                newPayInfo.dhlPrice = 0;
                newPayInfo.dhlAddFee = 0;
                newPayInfo.dhlInsrFee = 0;
                newPayInfo.ttDhlPrice = 0;

                newPayInfo.upsPrice = payInfo.upsPrice;
                newPayInfo.upsFuelSurch = payInfo.upsFuelSurch;
                newPayInfo.upsAddFee = payInfo.upsAddFee;
                newPayInfo.upsInsrFee = payInfo.upsInsrFee;
                newPayInfo.upsOutOfArea = payInfo.upsOutOfArea;
                newPayInfo.ttUpsPrice = payInfo.ttUpsPrice;

                newPayInfo.kPackPrice = 0;
                newPayInfo.kPackAddFee = 0;
                newPayInfo.kPackInsrFee = 0;
                newPayInfo.ttKPackPrice = 0;

                newPayInfo.airSPrice = 0;
                newPayInfo.airSAddFee = 0;
                newPayInfo.airSInsrFee = 0;
                newPayInfo.ttAirSPrice = 0;

                newPayInfo.ttDomPrice = 0;
            } else if (selectedOverseasShippingCost[id] === 'K-Packet'){
                newPayInfo.fedexPrice = 0;
                newPayInfo.fedexFuelSurch = 0;
                newPayInfo.fedexAddFee = 0;
                newPayInfo.fedexInsrFee = 0;
                newPayInfo.fedexOutOfArea = 0;
                newPayInfo.ttFedexPrice = 0;

                newPayInfo.emsPrice = 0;
                newPayInfo.emsAddFee = 0;
                newPayInfo.emsInsrFee = 0;
                newPayInfo.ttEmsPrice = 0;

                newPayInfo.seaPrice = 0;
                newPayInfo.seaInsrFee = 0;
                newPayInfo.ttSeaPrice = 0;

                newPayInfo.dhlPrice = 0;
                newPayInfo.dhlAddFee = 0;
                newPayInfo.dhlInsrFee = 0;
                newPayInfo.ttDhlPrice = 0;

                newPayInfo.upsPrice = 0;
                newPayInfo.upsFuelSurch = 0;
                newPayInfo.upsAddFee = 0;
                newPayInfo.upsInsrFee = 0;
                newPayInfo.upsOutOfArea = 0;
                newPayInfo.ttUpsPrice = 0;

                newPayInfo.kPackPrice = payInfo.kPackPrice
                newPayInfo.kPackAddFee = payInfo.kPackAddFee
                newPayInfo.kPackInsrFee = payInfo.kPackInsrFee
                newPayInfo.ttKPackPrice = payInfo.ttKPackPrice

                newPayInfo.airSPrice = 0;
                newPayInfo.airSAddFee = 0;
                newPayInfo.airSInsrFee = 0;
                newPayInfo.ttAirSPrice = 0;

                newPayInfo.ttDomPrice = 0;
            } else if (selectedOverseasShippingCost[id] === 'Small-Packet'){
                newPayInfo.fedexPrice = 0;
                newPayInfo.fedexFuelSurch = 0;
                newPayInfo.fedexAddFee = 0;
                newPayInfo.fedexInsrFee = 0;
                newPayInfo.fedexOutOfArea = 0;
                newPayInfo.ttFedexPrice = 0;

                newPayInfo.emsPrice = 0;
                newPayInfo.emsAddFee = 0;
                newPayInfo.emsInsrFee = 0;
                newPayInfo.ttEmsPrice = 0;

                newPayInfo.seaPrice = 0;
                newPayInfo.seaInsrFee = 0;
                newPayInfo.ttSeaPrice = 0;

                newPayInfo.dhlPrice = 0;
                newPayInfo.dhlAddFee = 0;
                newPayInfo.dhlInsrFee = 0;
                newPayInfo.ttDhlPrice = 0;

                newPayInfo.upsPrice = 0;
                newPayInfo.upsFuelSurch = 0;
                newPayInfo.upsAddFee = 0;
                newPayInfo.upsInsrFee = 0;
                newPayInfo.upsOutOfArea = 0;
                newPayInfo.ttUpsPrice = 0;

                newPayInfo.kPackPrice = 0;
                newPayInfo.kPackAddFee = 0;
                newPayInfo.kPackInsrFee = 0;
                newPayInfo.ttKPackPrice = 0;

                newPayInfo.airSPrice = payInfo.airSPrice
                newPayInfo.airSAddFee = payInfo.airSAddFee
                newPayInfo.airSInsrFee = payInfo.airSInsrFee
                newPayInfo.ttAirSPrice = payInfo.ttAirSPrice

                newPayInfo.ttDomPrice = 0;
            }

            if (payInfo.packListDTO && payInfo.packListDTO.productsDTO !== null) {
                if (!newPayInfo.packListDTO) {
                    newPayInfo.packListDTO = {};
                }
                newPayInfo.packListDTO.productsDTO = payInfo.packListDTO.productsDTO;
            }
            return newPayInfo;
        });
    };


    useEffect(() => {
        if (allSelectedPaylist) {
            handleAllPaylistCheckboxChange(true);
        }
    }, [allSelectedPaylist]);

    useEffect(() => {
        if (payinfoList && payinfoList.length > 0) {
            const initialSelectedPayInfos = {};
            const initialSelectedOverseasShippingCost = {};
            payinfoList.forEach((payinfo, index) => {
                // payinfo의 payStatus가 "PS0"인 경우에만 true로 설정, 그렇지 않으면 false로 설정
                initialSelectedPayInfos[payinfo.seqNum] = payinfo.payStatus === "PS0";
                initialSelectedOverseasShippingCost[payinfo.seqNum] = orderdtList.shipco;
            });
            setSelectedPayInfos(initialSelectedPayInfos);
            setSelectedOverseasShippingCost(initialSelectedOverseasShippingCost);
        }
    }, [payinfoList, orderdtList]);

    // 실시간 결제정보합산
    useEffect(() => {
        updatePayinfoAddform();
        updatePayinfoDbform();

        let totalEtcFee = 0;
        let totalEtcPrice = 0;
        payinfoList.forEach((payInfo) => {

            if (payInfo.payStatus === 'PS0') {
                totalEtcPrice += payInfo.ttEtcPrice;
                if (payInfo.payClass === '1') {
                    totalEtcFee += payInfo.ttEtcFee;
                }
            }
        });
        setDbFormTotalEtcExpense(totalEtcFee);
        setTotalEtcExpense(totalEtcPrice);

        let totalAdminProdPrice = 0;
        payinfoList.forEach((payInfo) => {
            if (payInfo.payStatus === 'PS0' && payInfo.payClass === '1') {
                totalAdminProdPrice += payInfo.ttProdExpense;
            }
        });
        setTotalAdminProdPrice(totalAdminProdPrice);

        let totalOverseasShippingExpense = 0;
        payinfoList.forEach((payInfo, index) => {
            if (
                selectedPayInfos[payInfo.seqNum] &&
                payInfo.payType === "PT1" &&
                payInfo.payStatus === "PS0"
            ) {
                switch (selectedOverseasShippingCost[payInfo.seqNum]) {
                    case 'FedEx':
                        totalOverseasShippingExpense += payInfo.ttFedexPrice;
                        break;
                    case 'EMS':
                        totalOverseasShippingExpense += payInfo.ttEmsPrice;
                        break;
                    case 'Sea':
                        totalOverseasShippingExpense += payInfo.ttSeaPrice;
                        break;
                    case 'Dom':
                        totalOverseasShippingExpense += payInfo.ttDomPrice;
                        break;
                    case 'DHL':
                        totalOverseasShippingExpense += payInfo.ttDhlPrice;
                        break;
                    case 'UPS':
                        totalOverseasShippingExpense += payInfo.ttUpsPrice;
                        break;
                    case 'K-Packet':
                        totalOverseasShippingExpense += payInfo.ttKPackPrice;
                        break;
                    case 'Small-Packet':
                        totalOverseasShippingExpense += payInfo.ttAirSPrice;
                        break;
                }
            }
        });
        setTotalOverseasShippingExpense(totalOverseasShippingExpense);
    }, [updatePayinfoAddform, updatePayinfoDbform, payinfoList, selectedPayInfos, selectedOverseasShippingCost]);

    const updatePayinfoList = (updatedPayinfo) => {
        setPayinfoList((prevPayinfoList) => {
            return prevPayinfoList.map((payinfo) =>
                payinfo.seqNum === updatedPayinfo.seqNum ? updatedPayinfo : payinfo,
            );
        });
    };

    const [useCouponSt, setUseCouponSt] = useState(false);
    const [dbCoupon, setDbCoupon] = useState('');
    const [biggerCpPriceSt, setBiggerCpPriceSt] = useState(false);

    const openBiggerCpPriceSt = (() => {
        setBiggerCpPriceSt(true);
    })
    const closeBiggerCpPriceSt = (() => {
        setBiggerCpPriceSt(false);
    })

    const showUseCoupon = (() => {
        if (Number(finalPayExpense) === 0) {
            openBiggerCpPriceSt();
        } else {
            setUseCouponSt(true);
        }
    })

    const closeUseCoupon = (() => {
        setUseCouponSt(false);
        setCouponSale('');
        if (couponSale !== '') {
            setDbCoupon(dbCoupon + 1);
            setCouponSeqnum('');
        }
    })

    const couponUseFn = (coupon) => {
        if (coupon && coupon.couponMstDTO) {
            const couponPrice = coupon.couponMstDTO.couponPrice;
            const finalExpense = Number(finalPayExpense.replace(/,/g, ''));

            if (couponPrice > finalExpense) {
                setUseCouponSt(false);
                openBiggerCpPriceSt();
            } else {
                setCouponSale(couponPrice);
                setUseCouponSt(false);
                if (!couponSale && coupon) {
                    setDbCoupon(dbCoupon - 1);
                }
                setCouponSeqnum(coupon.seqNum);
            }
        }
    };

    const [dbPoint, setDbPoint] = useState('');
    useEffect(() => {
        setDbPoint(memberInfo && memberInfo.point);
        setDbDeposit(memberInfo && memberInfo.deposit);
        const activeCouponCount = memberInfo && memberInfo.coupons && memberInfo.coupons.filter(coupon => coupon && coupon.activeFl === true).length;
        setDbCoupon(activeCouponCount || 0);
    }, [memberInfo]);


    const handlePointSale = (e) => {
        let value = Number(e.replace(/[^0-9]/g, ''));
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            dbFormTotalEtcExpense + totalOverseasShippingExpense + totalEtcExpense - (Number(depositSale) + Number(couponSale)));
        // 입력값이 최종결제금액보다 크면 최종결제금액으로 제한
        if (value > finalExpense) {
            value = finalExpense;
        }

        // 입력값이 적립금잔액보다 크면 input에 잔액(맥시멈)으로 업뎃하고 잔액은 0으로 업뎃
        if (value > memberInfo.point) {
            setPointSale(memberInfo.point);
            setDbPoint('');
            // 입력값을 지우면 잔액은 되돌리고 input값은 0으로 업뎃
        } else if (value === 0 || value === '') {
            setPointSale('');
            setDbPoint(memberInfo.point);
        } else {
            // 이 외에는 input에 사용자입력값, 잔액은 입력값만큼만 차감
            setPointSale(value);
            setDbPoint(memberInfo.point - value);
        }
    };

    const allPointSale = () => {
        let maxPoint = memberInfo ? memberInfo.point : '';
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            dbFormTotalEtcExpense + totalOverseasShippingExpense + totalEtcExpense - (Number(depositSale) + Number(couponSale)));
        if (maxPoint > finalExpense) {
            maxPoint = finalExpense;
        }

        setPointSale(maxPoint);
        setDbPoint(memberInfo.point - maxPoint);
    };

    const [dbDeposit, setDbDeposit] = useState('');

    const handleDepositSale = (e) => {
        let value = Number(e.replace(/[^0-9]/g, ''));
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            dbFormTotalEtcExpense + totalOverseasShippingExpense + totalEtcExpense - (Number(pointSale) + Number(couponSale)));

        if (value > finalExpense) {
            value = finalExpense;
        }

        if (value > memberInfo.deposit) {
            setDepositSale(memberInfo.deposit);
            setDbDeposit('');
        } else if (value === 0 || value === '') {
            setDepositSale('');
            setDbDeposit(memberInfo.deposit);
        } else {
            setDepositSale(value);
            setDbDeposit(memberInfo.deposit - value);
        }
    };

    const allDepositSale = () => {
        let maxDeposit = memberInfo ? memberInfo.deposit : '';
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            dbFormTotalEtcExpense + totalOverseasShippingExpense + totalEtcExpense - (Number(pointSale) + Number(couponSale)));
        if (maxDeposit > finalExpense) {
            maxDeposit = finalExpense;
        }

        setDepositSale(maxDeposit);
        setDbDeposit(memberInfo.deposit - maxDeposit);
    };

    // const [showPayNow, setShowPayNow] = useState(false);
    // useEffect(() => {
    //     const checkIfPayNowShouldBeShown = () => {
    //         // 데이터베이스 폼들 확인
    //         const dbFormsCheck = [
    //             ...dbCartProductHandle, ...dbOrderProductHandle, ...dbOfflineProductHandle,
    //             ...dbTransferProductHandle, ...dbDomesticProductHandle
    //         ].some(form =>
    //             form.prodSt === "IS01" &&
    //             (parseFloat(form.price || 0) > 0 ||
    //                 parseFloat(form.mallShipPrice || 0) > 0 ||
    //                 parseFloat(form.fee || 0) > 0)
    //         );
    //
    //         // 새로 추가된 폼들 확인
    //         const newFormsCheck = [
    //             ...cartProductHandle, ...orderProductHandle, ...offlineProductHandle,
    //             ...transferProductHandle, ...domesticProductHandle
    //         ].some(form =>
    //             parseFloat(form.price || 0) > 0 ||
    //             parseFloat(form.mallShipPrice || 0) > 0 ||
    //             parseFloat(form.fee || 0) > 0
    //         );
    //
    //         const payListCheck = [
    //             ...sortedPayinfoList
    //         ].some(pay =>
    //             pay.payStatus === "PS0" && (pay.lastPrice || 0) > 0
    //         ) && Object.values(selectedPayInfos).some(value => value === true);
    //
    //         // 상태 업데이트
    //         setShowPayNow(dbFormsCheck || newFormsCheck || payListCheck);
    //     };
    //
    //     checkIfPayNowShouldBeShown();
    // }, [
    //     dbCartProductHandle, dbOrderProductHandle, dbOfflineProductHandle, dbTransferProductHandle, dbDomesticProductHandle,
    //     cartProductHandle, orderProductHandle, offlineProductHandle, transferProductHandle, domesticProductHandle, sortedPayinfoList
    // ]);

    let WayModal;
    const width = 850;
    const height = 900;
    const left = (window.innerWidth / 2) - (width / 2);
    const top = (window.innerHeight / 2) - (height / 2);
    const openModal = () => {
        WayModal = window.open('/btcorpad/btad-Payreg', '_blank', `width=${width},height=${height},left=${left},top=${top}, scrollbars=yes`);
    };

    const [selectedPayinfo, setSelectedPayinfo] = useState(null);
    const [deletePaySt, setDeletePaySt] = useState(false);

    const deletePayMsgTrueFn = (() => {
        setDeletePaySt(true);
    })
    const deletePayMsgFalseFn = (() => {
        setDeletePaySt(false);
    })

    const deletePayMsg = "선택하신 결제건을 삭제하시겠습니까?"

    const deletePay = async () => {
        if (!selectedPayinfo) return;
        await axios({
            method: 'post',
            url: '/ordPay/deletePayinfoList/',
            data: {
                customsSeqnum: selectedPayinfo?.customsDTO?.seqNum,
                appReqSeqnum: selectedPayinfo?.appReqDTO?.seqNum,
                appRecSeqnum: selectedPayinfo?.appRecDTO?.seqnum,
                shipInfoSeqnum: selectedPayinfo?.shipInfoDTO?.seqNum,
                payConSeqnum: selectedPayinfo?.payConDTOList?.map(item => item.seqNum),
                packListSeqnum: selectedPayinfo?.packListDTO?.seqnum,
                payinfoSeqnum: selectedPayinfo?.seqNum,
                memoSeqnum: selectedPayinfo?.appOrderMemoDTO ? selectedPayinfo.appOrderMemoDTO.amSeqNum : null
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                // 삭제된 결제리스트 payinfo 필터
                const updatedSortedPayinfoList = payinfoList.filter((item) => item.seqNum !== selectedPayinfo.seqNum);
                setPayinfoList(updatedSortedPayinfoList);
                setDeletePaySt(false);
                setSelectedPayinfo(null);
            })
            .catch((error) => {
                console.log("결제리스트 삭제실패 : ", error)
            })
    }

    const [userProfileImg, setUserProfileImg] = useState(profileImage);
    const rows = Math.min(Math.max(Math.floor(message.length / 30) + 1, 1), 10);
    // 채팅메시지 텍스트 팝업 show
    const [chatModifySt, setChatModifySt] = useState(false)
    // 채팅메시지 파일 팝업 show
    const [chatFileModifySt, setChatFileModifySt] = useState(false)
    const [messageToModify, setMessageToModify] = useState(null);

    const chatModifyFalseFn = (() => {
        setChatModifySt(false);
    })
    const chatFileModifyFalseFn = (() => {
        setChatFileModifySt(false);
    })

    const openModifyModal = (message) => {
        setMessageToModify(message);
        if (message.chatKey.startsWith("chatFile")) {
            setChatFileModifySt(true);
        } else {
            setChatModifySt(true);
        }
    };

    const updateChatMessage = (modifiedMessage) => {
        const index = chatMessages.findIndex(msg => msg.seqnum === modifiedMessage.seqnum);
        const updatedChatMessages = [...chatMessages];
        updatedChatMessages[index] = modifiedMessage;
        setChatMessages(updatedChatMessages);
    };

    const chatFileModify = (changeFile, changeData) => {

        if (changeData.chatKey.startsWith('chatFile')) {
            // Handle the case where changeFile is a file
            const formData = new FormData();
            formData.append('changeFile', changeFile);
            formData.append('seqnum', changeData.seqnum);
            axios({
                method: 'post',
                url: '/order/chatFileModify/',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: LCAT
                },
            })
                .then(async (response) => {
                    const data = response.data;
                    const userInfo = {
                        userMbNum: data.mstMbNum,
                        userName: data.userName,
                        keyNumber: data.chatOrdNum,
                        sendFl: true
                    };
                    await updateChatMessage(response.data)
                    await stompClient.send("/app/chat/modifyMessage", {}, JSON.stringify(userInfo));
                    setChatFileModifySt(false);
                })
        } else {
            axios({
                method: 'post',
                url: '/order/chatFileModify/',
                params: {
                    seqnum: changeData.seqnum
                },

                data: changeFile,

                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: LCAT
                },
            })
                .then(async (response) => {
                    const data = response.data;
                    const userInfo = {
                        userMbNum: data.mstMbNum,
                        userName: data.userName,
                        keyNumber: data.chatOrdNum,
                        sendFl: true
                    };
                    await updateChatMessage(response.data)
                    await stompClient.send("/app/chat/modifyMessage", {}, JSON.stringify(userInfo));
                    setChatModifySt(false);
                })
        }
    };

    const [chatDeleteSt, setChatDeleteSt] = useState(false);
    const chatDeleteFalseFn = (() => {
        setChatDeleteSt(false);
    })
    const chatDeleteMsg1 = "해당 채팅메시지를 삭제하시겠습니까?"
    const chatDeleteMsg2 = "삭제 시 복구가 불가능합니다"

    const openDeleteModal = ((chatData) => {
        if (chatData !== null) {
            setMessageToModify(chatData);
        }
        setChatDeleteSt(true);
    })

    const deleteChat = ((deleteMessage) => {
        axios({
            method: 'post',
            url: '/order/deleteChat/',
            params: {
                seqnum: deleteMessage.seqnum
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then(async () => {
                const userInfo = {
                    userMbNum: deleteMessage.mstMbNum,
                    userName: deleteMessage.userName,
                    keyNumber: deleteMessage.chatOrdNum,
                    sendFl: true
                };
                await setChatMessages((prevChatMessages) => prevChatMessages.filter(message => message.seqnum !== deleteMessage.seqnum));
                await stompClient.send("/app/chat/deleteMessage", {}, JSON.stringify(userInfo));
                chatDeleteFalseFn();
            })
    })

    const [selectedClickFile, setSelectedClickFile] = useState(null);
    const [selectedClickSt, setSelectedClickSt] = useState(false);

    const clickFileDetail = ((file) => {
        setSelectedClickFile(file);
        setSelectedClickSt(true);
    })
    const clickFileClose = (() => {
        setSelectedClickSt(false);
    })

    const [usdExrInfo, setUsdExrInfo] = useState([]);

    const selectedAllExrInfo = (() => {
        axios({
            method: 'get',
            url: '/paypal/selectedAllExrInfo/',
        })
            .then((response) => {
                setUsdExrInfo(response.data)
            })
            .catch(() => {
                console.log("환율조회실패")
            })
    })

    useEffect(() => {
        selectedAllExrInfo();
    }, [])

    const calculateLastForPrice = () => {
        if ((payWay === "PM01" || payWay === "PM02" || payWay === "PM03") && currency && usdExrInfo.length > 0) {
            const usdExInfo = usdExrInfo.find(info => info.curPrice === currency);
            const numericFinalPayExpense = parseFloat(finalPayExpense.replace(/,/g, ''));

            // 금액(50,000) / (환율(1333.14) - 요율(9.1%)) = 41.26usd
            if (!isNaN(numericFinalPayExpense) && usdExInfo) {
                const payRateAsRatio = usdExInfo.payRate / 100;
                const newLastForPrice = numericFinalPayExpense / (usdExInfo.payExr - (usdExInfo.payExr * payRateAsRatio));
                setLastForPrice(Number(newLastForPrice.toFixed(2)));
            }
        } else {
            setLastForPrice(null);
        }
    }

    useEffect(() => {
        calculateLastForPrice();
    }, [payWay, currency, usdExrInfo, finalPayExpense]);

    const [cartTermsPopup, setCartTermsPopup] = useState(false);
    const [orderTermsPopup, setOrderTermsPopup] = useState(false);
    const [offlineTermsPopup, setOfflineTermsPopup] = useState(false);
    const [transferTermsPopup, setTransferTermsPopup] = useState(false);
    const [domesticTermsPopup, setDomesticTermsPopup] = useState(false);
    const [deliveryTermsPopup, setDeliveryTermsPopup] = useState(false);

    const closeCartTermsPopup = (() => {
        setCartTermsPopup(false);
    })
    const closeOrderTermsPopup = (() => {
        setOrderTermsPopup(false);
    })
    const closeOfflineTermsPopup = (() => {
        setOfflineTermsPopup(false);
    })
    const closeTransferTermsPopup = (() => {
        setTransferTermsPopup(false);
    })
    const closeDomesticTermsPopup = (() => {
        setDomesticTermsPopup(false);
    })
    const closeDeliveryTermsPopup = (() => {
        setDeliveryTermsPopup(false);
    })

    const convertToLastChatDate = ((regDate) => {
        const regTime = new Date(regDate);
        const currentTime = new Date();
        const timeDifference = currentTime - regTime;

        // 날짜 차이를 분으로 변환
        const minutesDiff = Math.floor(timeDifference / 60000);
        const hoursDiff = Math.floor(minutesDiff / 60);
        const daysDiff = Math.floor(hoursDiff / 24);

        // 날짜 차이가 1일 미만일 때
        if (daysDiff < 1) {
            if (minutesDiff < 1) return "방금";
            if (minutesDiff < 60) return `${minutesDiff}분 전`;
            return `${hoursDiff}시간 전`;
        } else if (daysDiff === 1) {
            return "어제";
        } else if (daysDiff === 2) {
            return "2일 전";
        } else if (daysDiff < 7) {
            return `${daysDiff}일 전`;
        } else {
            // 7일 이상 차이날 때는 월-일 형식으로 반환
            return `${(regTime.getMonth() + 1).toString().padStart(2, '0')}-${regTime.getDate().toString().padStart(2, '0')}`;
        }
    })

    return (
        <div className="AdminOrderDtModiPg">
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="Receipt-title text-center"><h3 className="font-t-title">주문상세</h3></div>
                    <div className="font-t-cont" style={{display: "block", padding: "5px 15px 5px 55px"}}>
                        <div style={{width: "100%", borderRadius: "7px", marginRight: "20px"}}>
                            <div style={{height: "auto", display: "flex", paddingLeft: "70px"}}>
                                {/*여기서부터 수취인정보 컴포넌트*/}
                                <div style={{width: "850px", height: "auto"}}>
                                    {/*나중에 장바구니 클릭해서 오면 장바구니 문구, 구매대행이면 구매대행문구...*/}
                                    {orderdtList && (
                                        <div className="gbn-border-b" style={{marginTop: "40px", paddingBottom: "10px"}}>
                                            <h5 className="font-b font-c-default c-def m-r-10">Order.no&nbsp;{orderdtList?.orderNumber}</h5>
                                        </div>)}
                                    {/*수취인정보*/}
                                    <div style={{marginTop: "20px"}}
                                         className={`accordion-header ${recieverInfoAccordion ? 'expanded' : ''}`}
                                         onClick={recieverInfoAccordionFn}>
                                        <i className="accordion-icon"></i>
                                        <span>수취인정보</span>
                                    </div>
                                    <div className={`accordion-body ${recieverInfoAccordion ? 'expanded' : ''}`}>
                                        <div className="recInfoRowContainer" style={{height: "60px"}}>
                                            <div className="recInfoRowTitle" style={{color: "dimgray"}}><span
                                                style={{color: "red", fontSize: "18px", marginRight: "5px"}}>*</span>배송용도
                                            </div>
                                            <div className="recInfoRowContent" style={{paddingLeft: "30px"}}>
                                                <label>
                                                    <input className="radioButton" type="radio" name="purposeDelivery"
                                                           value="U0" onChange={handleUseType}
                                                           checked={useType === "U0"}/>
                                                    <span style={{
                                                        verticalAlign: "middle",
                                                        marginLeft: "5px",
                                                        fontSize: "15px",
                                                        color: "dimgrey"
                                                    }}>선물</span>
                                                </label>
                                                <label style={{marginLeft: "15px"}}>
                                                    <input className="radioButton" type="radio" name="purposeDelivery"
                                                           value="U1"
                                                           onChange={handleUseType} checked={useType === "U1"}/>
                                                    <span style={{
                                                        verticalAlign: "middle",
                                                        marginLeft: "5px",
                                                        fontSize: "15px",
                                                        color: "dimgrey"
                                                    }}>상품</span>
                                                </label>
                                                <label style={{marginLeft: "15px"}}>
                                                    <input className="radioButton" type="radio" name="purposeDelivery"
                                                           value="U2"
                                                           onChange={handleUseType} checked={useType === "U2"}/>
                                                    <span style={{
                                                        verticalAlign: "middle",
                                                        marginLeft: "5px",
                                                        fontSize: "15px",
                                                        color: "dimgrey"
                                                    }}>샘플</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="recInfoRowContainer" style={{height: "auto"}}>
                                            <div className="recInfoRowTitle" style={{color: "dimgray"}}><span
                                                style={{color: "red", fontSize: "18px", marginRight: "5px"}}>*</span>배송사선택
                                            </div>
                                            <div className="recInfoRowContent"
                                                 style={{paddingLeft: "30px", paddingTop: "8px"}}>
                                                <div ref={(el) => {
                                                        shipcoDropdownRef.current = el;
                                                        selectedDeliveryChoiceOptionRef.current = el;
                                                    }}
                                                    tabIndex="0"
                                                    onKeyDown={shipcoHandleKeyDown}
                                                    onFocus={shipcoHandleOnFocus}
                                                    style={{height: "50px", width: "50%", position: "relative"}}>
                                                    <button className="selectParentBt"
                                                            style={{width: "100%", height: "100%"}}
                                                            onClick={() => setShipcoIsOpen(!shipcoIsOpen)}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "14px",
                                                            fontWeight: "lighter"
                                                        }}>{selectedDeliveryChoiceOption}</p>
                                                        <span style={{
                                                            position: "absolute",
                                                            right: "5px",
                                                            top: "0",
                                                            height: "100%",
                                                            width: "12%"
                                                        }}>

                                                                    <i style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "100%",
                                                                        color: "darkgray"
                                                                    }}><BiCaretDown/></i>

                                                                </span>
                                                    </button>
                                                    {shipcoIsOpen ?
                                                        <div style={{
                                                            width: "295px",
                                                            height: "auto",
                                                            boxShadow: "0px 0px 10px lightgray",
                                                            padding: "0",
                                                            position: "absolute",
                                                            left: "2px",
                                                            top: "55px",
                                                            zIndex: 3,
                                                            backgroundColor: "white",
                                                            borderRadius: "6px"
                                                        }}>
                                                            {deliveryChoice.map((option, index) => (
                                                                <div className="selectChildBt" key={index}
                                                                     onClick={() => shipcoHandleSelect(option, index)}
                                                                     style={{
                                                                         display: "flex",
                                                                         padding: "10px",
                                                                         justifyContent: "center",
                                                                         alignItems: "center",
                                                                         cursor: "pointer",
                                                                         fontSize: "14px",
                                                                         borderRadius: "4px",
                                                                         zIndex: 99,
                                                                     }}>
                                                                    <p style={{
                                                                        margin: "0",
                                                                        fontSize: "14px"
                                                                    }}>{option}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                {deliveryChoiceError ?
                                                    <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                        <p className="JoinErrMsg">해외배송사를 선택해주세요</p>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div style={{display: "flex"}}>
                                            <div className="recInfoRowTitle" style={{color: "dimgray"}}><span
                                                style={{color: "red", fontSize: "18px", marginRight: "5px"}}>*</span>국가
                                            </div>
                                            <div className="recInfoRowContent" style={{paddingLeft: "30px", paddingTop: "8px"}}>

                                                {/*국가 커스텀 셀렉트new*/}
                                                <div ref={countryRef} className="ht-50 wd-80pc c-point position-relative" onClick={toggleCountryShow}>
                                                    <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                                                        {selectedCountry.value ? (
                                                            <div className="wd-15pc text-center align-center-c">
                                                                <div className={`m-l-6 font-s-20 fi fi-${selectedCountry.value.toLowerCase()}`} />
                                                            </div>
                                                        ) : (
                                                            <div className="wd-15pc text-center align-center-c p-l-5">
                                                                <div className="wd-29 ht-22 text-center font-s-12 m-0-a country-select-flag">?</div>
                                                            </div>
                                                        )}
                                                        <input className="country-select-in-input wd-100pc ht-100pc c-point font-s-14 p-l-15"
                                                               type="text" placeholder={t(`modSmallMenu3-1`)} autoComplete="off"
                                                               value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '') || (selectedCountry.value ? selectedCountry.label : '')}
                                                               onChange={handleCountrySearch} />
                                                        <i className="wd-10pc text-center align-center-c font-s-20 p-b-2 font-c-darkgrey">
                                                            <BiCaretDown />
                                                        </i>
                                                    </div>
                                                    {countryShow && (
                                                        <div className="country-select-data wd-100pc position-absolute m-t-5">
                                                            {filteredCountryList.map((country, index) => (
                                                                <div className="selectChildBt display-flex c-point p-t-10 p-b-10 p-l-20" key={index}
                                                                     onClick={() => handleCountryChange(country)}>
                                                                    <div className={`font-s-17 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                                                    <p className="font-s-15">{country.label}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                                {/*여기까지 커스텀 국가셀렉트*/}
                                                {countryError ?
                                                    <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                        <p className="JoinErrMsg">국가를 선택해주세요</p>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        {selectedCountry.value === "ID" ?
                                            <div style={{height: "auto", display: "flex"}}>
                                                <div className="recInfoRowTitle"
                                                     style={{color: "dimgray", paddingTop: "25px"}}>
                                                    <span style={{
                                                        color: "red",
                                                        fontSize: "18px",
                                                        marginRight: "5px"
                                                    }}>*</span>Tax ID
                                                </div>
                                                <div style={{
                                                    paddingLeft: "30px",
                                                    width: "70%",
                                                    height: "auto",
                                                    margin: "auto",
                                                    marginTop: "10px",
                                                    display: "block"
                                                }}>
                                                    <div style={{display: "flex"}}>
                                                        <div className="acc-input-container acc-input-icon"
                                                             style={{height: "100%", width: "87%"}}>
                                                            <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                                <i style={{
                                                                    fontSize: "28px",
                                                                    margin: "0px 0px 5px 5px"
                                                                }}><HiReceiptTax/></i>
                                                            </div>
                                                            <div className="acc-input-form">
                                                                <input className="acc-input-inform" type="text"
                                                                       placeholder=" "
                                                                       style={{
                                                                           width: "98%",
                                                                           height: "100%",
                                                                           paddingLeft: "10px"
                                                                       }}
                                                                       maxLength={50} value={taxid}
                                                                       onChange={validTextTaxid}
                                                                       ref={taxidRef}/>
                                                                <label className="inputLabel"> Tax ID</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {taxidError ?
                                                        <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                            <p className="JoinErrMsg">인도네시아 Tax ID를 입력해주세요</p>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        <div style={{height: "auto", display: "flex"}}>
                                            <div className="recInfoRowTitle"
                                                 style={{color: "dimgray", paddingTop: "25px"}}><span
                                                style={{color: "red", fontSize: "18px", marginRight: "5px"}}>*</span>수취인성함
                                            </div>
                                            <div style={{
                                                paddingLeft: "30px",
                                                width: "75%",
                                                height: "auto",
                                                margin: "auto",
                                                marginTop: "10px",
                                                display: "block"
                                            }}>
                                                <div style={{display: "flex"}}>
                                                    <div className="acc-input-container acc-input-icon"
                                                         style={{height: "100%", width: "80%"}}>
                                                        <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                            <i style={{
                                                                fontSize: "28px",
                                                                margin: "0px 0px 5px 5px"
                                                            }}><BsFillPersonCheckFill/></i>
                                                        </div>
                                                        <div className="acc-input-form">
                                                            <input className="acc-input-inform" type="text"
                                                                   placeholder=" "
                                                                   style={{
                                                                       width: "98%",
                                                                       height: "100%",
                                                                       paddingLeft: "10px"
                                                                   }}
                                                                   maxLength={25} value={recname} onChange={nameValid}
                                                                   ref={recnameRef}/>
                                                            <label className="inputLabel">수취인성함</label>
                                                        </div>
                                                    </div>
                                                    {selectedCountry.value !== "KR" ?
                                                        <div style={{display: "flex", alignItems: "end"}}>
                                                            <span style={{
                                                                display: "flex",
                                                                alignItems: "end",
                                                                marginLeft: "5px",
                                                                color: "orangered"
                                                            }}>{nameInputCount}</span>
                                                            <span style={{
                                                                display: "flex",
                                                                alignItems: "end",
                                                                color: "dimgrey"
                                                            }}>/25</span>
                                                        </div>
                                                        :
                                                        null
                                                        }
                                                </div>
                                                {selectedCountry.value !== "KR" && recnameError ?
                                                    <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                        <p className="JoinErrMsg">영문 2~25자를 입력해주세요</p>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="recInfoRowContainer" style={{height: "auto"}}>
                                            <div className="recInfoRowTitle" style={{color: "dimgray"}}><span
                                                style={{color: "red", fontSize: "18px", marginRight: "5px"}}>*</span>연락처
                                            </div>
                                            <div style={{
                                                paddingLeft: "30px",
                                                width: "75%",
                                                height: "auto",
                                                margin: "auto",
                                                marginTop: "5px",
                                                display: "block"
                                            }}>
                                                <div style={{display: "flex"}}>
                                                    <div className="acc-input-container acc-input-icon"
                                                         style={{height: "100%", width: "80%"}}>
                                                        <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                            <i style={{
                                                                fontSize: "28px",
                                                                margin: "0px 0px 5px 5px"
                                                            }}><MdPhoneIphone/></i>
                                                        </div>
                                                        <div className="acc-input-form">
                                                            <input className="acc-input-inform" type="text"
                                                                   placeholder=" "
                                                                   style={{
                                                                       width: "98%",
                                                                       height: "100%",
                                                                       paddingLeft: "10px"
                                                                   }}
                                                                   maxLength={15} value={telnum} onChange={telnumValid}
                                                                   ref={telnumRef}/>
                                                            <label className="inputLabel">연락처</label>
                                                        </div>
                                                    </div>

                                                    {selectedCountry.value !== "KR" ?
                                                        <div style={{display: "flex", alignItems: "end"}}>
                                                            <span style={{
                                                                display: "flex",
                                                                alignItems: "end",
                                                                marginLeft: "5px",
                                                                color: "orangered"
                                                            }}>{telnumInputCount}</span>
                                                            <span style={{
                                                                display: "flex",
                                                                alignItems: "end",
                                                                color: "dimgrey"
                                                            }}>/15</span>
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                </div>

                                                {selectedCountry.value !== "KR" && telnumError ?
                                                    <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                        <p className="JoinErrMsg">숫자 또는 "-" 5~15자를 입력해주세요</p>
                                                    </div>
                                                    :
                                                    null
                                                }

                                            </div>
                                        </div>

                                        {selectedCountry.value !== "KR" ?
                                            <div className="recInfoRowContainer" style={{height: "auto"}}>
                                                <div className="recInfoRowTitle" style={{color: "dimgray"}}><span
                                                    style={{
                                                        color: "red",
                                                        fontSize: "18px",
                                                        marginRight: "5px"
                                                    }}>*</span>우편번호
                                                </div>
                                                <div style={{
                                                    paddingLeft: "30px",
                                                    width: "75%",
                                                    height: "auto",
                                                    margin: "auto",
                                                    marginTop: "5px",
                                                    display: "block"
                                                }}>
                                                    <div style={{display: "flex"}}>
                                                        <div className="acc-input-container acc-input-icon"
                                                             style={{height: "100%", width: "80%"}}>
                                                            <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                                <i style={{
                                                                    fontSize: "28px",
                                                                    margin: "0px 0px 5px 5px"
                                                                }}><BsMailbox2/></i>
                                                            </div>
                                                            <div className="acc-input-form">
                                                                <input className="acc-input-inform" type="text"
                                                                       placeholder=" "
                                                                       style={{
                                                                           width: "98%",
                                                                           height: "100%",
                                                                           paddingLeft: "10px"
                                                                       }}
                                                                       maxLength={10} value={zipcode}
                                                                       onChange={zipcodeValid}
                                                                       ref={zipcodeRef}/>
                                                                <label className="inputLabel">우편번호</label>
                                                            </div>
                                                        </div>
                                                        <span style={{
                                                            display: "flex",
                                                            alignItems: "end",
                                                            marginLeft: "5px",
                                                            color: "orangered"
                                                        }}>{zipcodeInputCount}</span><span
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "end",
                                                            color: "dimgrey"
                                                        }}>/10</span>
                                                    </div>
                                                    {zipcodeError ?
                                                        <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                            <p className="JoinErrMsg">영문 또는 숫자 또는 "-" 3~10자를 입력해주세요</p>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                                            :
                                                            null
                                        }

                                        <div className="recInfoRowContainer" style={{height: "auto"}}>
                                            <div className="recInfoRowTitle" style={{color: "dimgray"}}><span
                                                style={{color: "red", fontSize: "18px", marginRight: "5px"}}>*</span>
                                                {selectedCountry.value !== "KR" ? "주소1" : "주소"}
                                            </div>
                                            <div style={{
                                                paddingLeft: "30px",
                                                width: "75%",
                                                height: "auto",
                                                margin: "auto",
                                                marginTop: "5px",
                                                display: "block"
                                            }}>
                                                <div style={{display: "flex"}}>
                                                    <div className="acc-input-container acc-input-icon"
                                                         style={{height: "100%", width: "80%"}}>
                                                        <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                            <i style={{
                                                                fontSize: "28px",
                                                                margin: "0px 0px 5px 5px"
                                                            }}><TbAddressBook/></i>
                                                        </div>
                                                        <div className="acc-input-form">
                                                            <input className="acc-input-inform" type="text"
                                                                   placeholder=" "
                                                                   style={{
                                                                       width: "98%",
                                                                       height: "100%",
                                                                       paddingLeft: "10px"
                                                                   }}
                                                                   maxLength={address1Length} value={address1}
                                                                   onChange={address1Valid}
                                                                   ref={address1Ref}/>
                                                            <label className="inputLabel">주소1</label>
                                                        </div>
                                                    </div>

                                                    {selectedCountry.value !== "KR" ?
                                                        <div style={{display: "flex", alignItems: "end"}}>
                                                            <span style={{
                                                                display: "flex",
                                                                alignItems: "end",
                                                                marginLeft: "5px",
                                                                color: "orangered"
                                                            }}>{address1InputCount}</span>
                                                            <span style={{
                                                                display: "flex",
                                                                alignItems: "end",
                                                                color: "dimgrey"
                                                            }}>{`/${address1Length}`}</span>
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                </div>

                                                {selectedCountry.value !== "KR" && address1Error ?
                                                    <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                        <p className="JoinErrMsg">{`영문 또는 "-" 또는 "/" 또는 "," 2~${address1Length}자를 입력해주세요`}</p>
                                                    </div>
                                                    :
                                                    null
                                                }

                                            </div>
                                        </div>

                                        {selectedCountry.value !== "KR" ?
                                            <div className="recInfoRowContainer" style={{height: "auto"}}>
                                                <div className="recInfoRowTitle" style={{color: "dimgray"}}>주소2</div>
                                                <div style={{
                                                    paddingLeft: "30px",
                                                    width: "75%",
                                                    height: "auto",
                                                    margin: "auto",
                                                    marginTop: "5px",
                                                    display: "block"
                                                }}>
                                                    <div style={{display: "flex"}}>
                                                        <div className="acc-input-container acc-input-icon"
                                                             style={{height: "100%", width: "80%"}}>
                                                            <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                                <i style={{
                                                                    fontSize: "28px",
                                                                    margin: "0px 0px 5px 5px"
                                                                }}><TbAddressBook/></i>
                                                            </div>
                                                            <div className="acc-input-form">
                                                                <input className="acc-input-inform" type="text"
                                                                       placeholder=" "
                                                                       style={{
                                                                           width: "98%",
                                                                           height: "100%",
                                                                           paddingLeft: "10px"
                                                                       }}
                                                                       maxLength={address2Length} value={address2}
                                                                       onChange={address2Valid}/>
                                                                <label className="inputLabel">주소2</label>
                                                            </div>
                                                        </div>
                                                        <span style={{
                                                            display: "flex",
                                                            alignItems: "end",
                                                            marginLeft: "5px",
                                                            color: "orangered"
                                                        }}>{address2InputCount}</span><span
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "end",
                                                            color: "dimgrey"
                                                        }}>{`/${address2Length}`}</span>
                                                    </div>

                                                    {address2Error ?
                                                        <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                            <p className="JoinErrMsg">{`영문 또는 "-" 또는 "/" 또는 "," 2~${address2Length}자를 입력해주세요`}</p>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                        {selectedCountry.value !== "KR" ?
                                            <div className="recInfoRowContainer" style={{height: "auto"}}>
                                                <div className="recInfoRowTitle" style={{color: "dimgray"}}>주소3</div>
                                                <div style={{
                                                    paddingLeft: "30px",
                                                    width: "75%",
                                                    height: "auto",
                                                    margin: "auto",
                                                    marginTop: "5px",
                                                    display: "block"
                                                }}>
                                                    <div style={{display: "flex"}}>
                                                        <div className="acc-input-container acc-input-icon"
                                                             style={{height: "100%", width: "80%"}}>
                                                            <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                                <i style={{
                                                                    fontSize: "28px",
                                                                    margin: "0px 0px 5px 5px"
                                                                }}><TbAddressBook/></i>
                                                            </div>
                                                            <div className="acc-input-form">
                                                                <input className="acc-input-inform" type="text"
                                                                       placeholder=" "
                                                                       style={{
                                                                           width: "98%",
                                                                           height: "100%",
                                                                           paddingLeft: "10px"
                                                                       }}
                                                                       maxLength={address3Length} value={address3}
                                                                       onChange={address3Valid}/>
                                                                <label className="inputLabel">주소3</label>
                                                            </div>
                                                        </div>
                                                        <span style={{
                                                            display: "flex",
                                                            alignItems: "end",
                                                            marginLeft: "5px",
                                                            color: "orangered"
                                                        }}>{address3InputCount}</span><span
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "end",
                                                            color: "dimgrey"
                                                        }}>{`/${address3Length}`}</span>
                                                    </div>

                                                    {address3Error ?
                                                        <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                            <p className="JoinErrMsg">{`영문 또는 "-" 또는 "/" 또는 "," 2~${address3Length}자를 입력해주세요`}</p>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                        {selectedCountry.value !== "KR" ?
                                            <div className="recInfoRowContainer" style={{height: "auto"}}>
                                                <div className="recInfoRowTitle" style={{color: "dimgray"}}><span
                                                    style={{
                                                        color: "red",
                                                        fontSize: "18px",
                                                        marginRight: "5px"
                                                    }}>*</span>도시명
                                                </div>
                                                <div style={{
                                                    paddingLeft: "30px",
                                                    width: "75%",
                                                    height: "auto",
                                                    margin: "auto",
                                                    marginTop: "5px",
                                                    display: "block"
                                                }}>
                                                    <div style={{display: "flex"}}>
                                                        <div className="acc-input-container acc-input-icon"
                                                             style={{height: "100%", width: "80%"}}>
                                                            <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                                <i style={{
                                                                    fontSize: "28px",
                                                                    margin: "0px 0px 5px 5px"
                                                                }}><TbAddressBook/></i>
                                                            </div>
                                                            <div className="acc-input-form">
                                                                <input className="acc-input-inform" type="text"
                                                                       placeholder=" "
                                                                       style={{
                                                                           width: "98%",
                                                                           height: "100%",
                                                                           paddingLeft: "10px"
                                                                       }}
                                                                       maxLength={cityLength} value={city}
                                                                       onChange={cityValid}
                                                                       ref={cityRef}/>
                                                                <label className="inputLabel">도시명</label>
                                                            </div>
                                                        </div>
                                                        <span style={{
                                                            display: "flex",
                                                            alignItems: "end",
                                                            marginLeft: "5px",
                                                            color: "orangered"
                                                        }}>{cityInputCount}</span><span
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "end",
                                                            color: "dimgrey"
                                                        }}>{`/${cityLength}`}</span>
                                                    </div>

                                                    {cityError ?
                                                        <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                            <p className="JoinErrMsg">{`영문 또는 "-" 또는 "/" 또는 "," 2~${cityLength}자를 입력해주세요`}</p>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    <div style={{height: "20px"}}></div>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div>
                                        <h5 style={{color: "dimgrey", marginTop: "100px"}}>장바구니 서비스</h5>
                                    </div>
                                    {/*장바구니 폼*/}
                                    <div className={`accordion-header ${cartOrderAccordion ? 'expanded' : ''}`}
                                        style={{position: "relative", overflow: "auto", display: "flex"}}
                                        onClick={cartOrderAccordionFn}>
                                        <i className="accordion-icon"></i>
                                        <span>장바구니 Form</span>
                                    </div>
                                    {/*div안에 있는 컨텐츠가 보여지는 조건*/}
                                    <div
                                        className={`accordion-body ${cartOrderAccordion ? 'expanded' : ''}`}>
                                        {/*여기서부터 DB에서 받은 폼*/}
                                        {dbCartProductHandle.sort((a,b) => a.seqnum - b.seqnum).map((cart, index) => (
                                            <div key={index}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    borderBottom: "solid",
                                                    borderWidth: "thin 1px",
                                                    borderColor: "lightgray"
                                                }}>

                                                    <div style={{width: "20%", padding: "10px"}}>
                                                        <div className="user-center" style={{marginTop: "10px"}}>
                                                            <div className="user-center" style={{
                                                                width: "100px",
                                                                height: "100px",
                                                                border: "solid",
                                                                borderWidth: "thin 1px",
                                                                borderColor: "#EAEAEA",
                                                                borderRadius: "6px"
                                                            }}>
                                                                <img style={{
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    opacity: "0.4"
                                                                }} alt="cart" src={detailCart}/>
                                                            </div>
                                                        </div>
                                                        <div className="user-center" style={{marginTop: "10px"}}>
                                                            <p style={{margin: "0", fontSize: "18px"}}>{index + 1}.</p>
                                                        </div>
                                                    </div>
                                                    {/*폼 전체*/}
                                                    <div style={{width: "65%"}}>
                                                        {/*한 로우*/}
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>쇼핑몰ID
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={cart.name || ''}
                                                                       onChange={(e) => handleDbCartProductInput(index, 'name', e.target.value)}
                                                                       maxLength={200}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbCartItemsRefs.current[index]) {
                                                                        dbCartItemsRefs.current[index].name = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>쇼핑몰PW
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                                position: "relative"
                                                            }}>
                                                                <input type={inputMallDbPwEncryption[index] ? "password" : "text"}
                                                                    className="orderFormInput" value={cart.option || ''}
                                                                    onChange={(e) => handleDbCartProductInput(index, 'option', e.target.value)}
                                                                    maxLength={50}
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "40px",
                                                                        borderRadius: "4px",
                                                                        borderWidth: "thin 1px",
                                                                        padding: "0 6px 0 6px",
                                                                        overflow: "auto"
                                                                    }} ref={(ref) => {
                                                                    if (dbCartItemsRefs.current[index]) {
                                                                        dbCartItemsRefs.current[index].option = ref;
                                                                    }
                                                                }}/>
                                                                {
                                                                    !inputMallDbPwEncryption[index] ?
                                                                        <i style={{
                                                                            fontSize: "25px",
                                                                            position: "absolute",
                                                                            top: "10px",
                                                                            right: "15px",
                                                                            margin: "auto",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            marginTop: "4px",
                                                                            opacity: "0.6",
                                                                            cursor: "pointer"
                                                                        }}
                                                                           onClick={() => toggleMallDbPassword(index)}><AiOutlineEye/></i>
                                                                        :
                                                                        inputMallDbPwEncryption[index] ?
                                                                            <i style={{
                                                                                fontSize: "25px",
                                                                                position: "absolute",
                                                                                top: "10px",
                                                                                right: "15px",
                                                                                margin: "auto",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: "4px",
                                                                                opacity: "0.6",
                                                                                cursor: "pointer"
                                                                            }}
                                                                               onClick={() => toggleMallDbPassword(index)}><AiOutlineEyeInvisible/></i>
                                                                            :
                                                                            null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>장바구니수량
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={cart.quantity || ''}
                                                                       onChange={(e) => dbCartQuantityNumberInput(index, 'quantity', e.target.value)}
                                                                       maxLength={5}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbCartItemsRefs.current[index]) {
                                                                        dbCartItemsRefs.current[index].quantity = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>장바구니금액
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                                height: "auto"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={cart.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                       onChange={(e) => dbCartPriceNumberInput(index, 'price', e.target.value)}
                                                                       maxLength={12}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbCartItemsRefs.current[index]) {
                                                                        dbCartItemsRefs.current[index].price = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>쇼핑몰URL
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={cart.url}
                                                                       onChange={(e) => handleDbCartProductInput(index, 'url', e.target.value)}
                                                                       maxLength={2083}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbCartItemsRefs.current[index]) {
                                                                        dbCartItemsRefs.current[index].url = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wd-15pc display-flex flex-column just-cont-center align-center font-s-16 font-b gap20">
                                                        {cart?.prodSt === "IS00" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>입고대기</p>
                                                        ) : cart?.prodSt === "IS01" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제대기(상품)</p>
                                                        ) : cart?.prodSt === "IS02" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제중(상품)</p>
                                                        ) : cart?.prodSt === "IS03" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FFB378",
                                                                fontSize: "15px"
                                                            }}>결제완료(상품)</p>
                                                        ) : cart?.prodSt === "IS04" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8600",
                                                                fontSize: "15px"
                                                            }}>구매완료</p>
                                                        ) : cart?.prodSt === "IS05" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#6EDA60",
                                                                fontSize: "15px"
                                                            }}>센터도착</p>
                                                        ) : cart?.prodSt === "IS06" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>패킹중</p>
                                                        ) : cart?.prodSt === "IS07" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제대기(배송)</p>
                                                        ) : cart?.prodSt === "IS08" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제중(배송)</p>
                                                        ) : cart?.prodSt === "IS09" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FFB378",
                                                                fontSize: "15px"
                                                            }}>결제완료(배송)</p>
                                                        ) : cart?.prodSt === "IS10" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#979696",
                                                                fontSize: "15px"
                                                            }}>출고완료</p>
                                                        ) : cart?.prodSt === "IS11" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8F62",
                                                                fontSize: "15px"
                                                            }}>반송중</p>
                                                        ) : cart?.prodSt === "IS12" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8F62",
                                                                fontSize: "15px"
                                                            }}>신청취소</p>
                                                        ) : null}
                                                        <div>
                                                            {cart.prodSt === "IS01" ?
                                                                <button className="OrderPgBt2" onClick={() => deleteDbCartProduct(index)}>{t(`modAddBt3`)}</button>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {/*여기서부터 순수 장바구니 항목 자체*/}
                                        {newCartFormSt ?
                                            <div>
                                                {cartProductHandle.map((cart, index) => (
                                                    <div key={index}>
                                                        <div className="orderPgServiceHeader">
                                                            <div style={{
                                                                width: "43.5%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                fontSize: "15px",
                                                                fontWeight: "bolder",
                                                                paddingLeft: "15px",
                                                                color: "dimgrey"
                                                            }}>
                                                                {index + 1}.
                                                            </div>
                                                            <div style={{
                                                                width: "31%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <button className="OrderPgBt2"
                                                                        onClick={() => addCartProduct(index)}>상품추가
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{marginLeft: "5px"}}
                                                                        onClick={() => copyCartProduct(index)}>상품복사
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{marginLeft: "5px"}}
                                                                        onClick={() => deleteCartProduct(index)}>상품삭제
                                                                </button>
                                                            </div>
                                                            <div style={{
                                                                width: "25.5%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <input style={{display: "none"}} type="file" name="file"
                                                                       accept=".xlsx" ref={excelFileSelectRefCart}
                                                                       onChange={cartExcelUpload}/>
                                                                <button className="OrderPgBt2"
                                                                        style={{width: "90px"}}
                                                                        onClick={() => excelFileSelectRefCart.current.click()}>대량업로드
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{width: "100px", marginLeft: "5px"}}
                                                                        onClick={cartExcelDown}>장바구니양식
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>쇼핑몰ID
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={cart.name || ''}
                                                                           onChange={(e) => handleCartProductInput(index, 'name', e.target.value)}
                                                                           maxLength={200}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (cartItemsRefs.current[index]) {
                                                                            cartItemsRefs.current[index].name = ref;
                                                                        }
                                                                    }}/>
                                                                </div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>쇼핑몰PW
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%",
                                                                    position: "relative"
                                                                }}>
                                                                    <input
                                                                        type={inputMallPwEncryption[index] ? "password" : "text"}
                                                                        className="orderFormInput"
                                                                        value={cart.option || ''}
                                                                        onChange={(e) => handleCartProductInput(index, 'option', e.target.value)}
                                                                        maxLength={50}
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "40px",
                                                                            borderRadius: "4px",
                                                                            borderWidth: "thin 1px",
                                                                            padding: "0 6px 0 6px",
                                                                            overflow: "auto"
                                                                        }} ref={(ref) => {
                                                                        if (cartItemsRefs.current[index]) {
                                                                            cartItemsRefs.current[index].option = ref;
                                                                        }
                                                                    }}/>
                                                                    {
                                                                        !inputMallPwEncryption[index] ?
                                                                            <i style={{
                                                                                fontSize: "25px",
                                                                                position: "absolute",
                                                                                top: "10px",
                                                                                right: "15px",
                                                                                margin: "auto",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: "4px",
                                                                                opacity: "0.6",
                                                                                cursor: "pointer"
                                                                            }}
                                                                               onClick={() => toggleMallPassword(index)}><AiOutlineEye/></i>
                                                                            :
                                                                            inputMallPwEncryption[index] ?
                                                                                <i style={{
                                                                                    fontSize: "25px",
                                                                                    position: "absolute",
                                                                                    top: "10px",
                                                                                    right: "15px",
                                                                                    margin: "auto",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    marginTop: "4px",
                                                                                    opacity: "0.6",
                                                                                    cursor: "pointer"
                                                                                }}
                                                                                   onClick={() => toggleMallPassword(index)}><AiOutlineEyeInvisible/></i>
                                                                                :
                                                                                null
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>장바구니수량
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={cart.quantity || ''}
                                                                           onChange={(e) => cartQuantityNumberInput(index, 'quantity', e.target.value)}
                                                                           maxLength={5}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (cartItemsRefs.current[index]) {
                                                                            cartItemsRefs.current[index].quantity = ref;
                                                                        }
                                                                    }}/>
                                                                </div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                {/*<div style={{height:"50px", display:"flex"}}>*/}
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>장바구니금액
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%",
                                                                    height: "auto"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={(cart.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                           onChange={(e) => cartPriceNumberInput(index, 'price', e.target.value)}
                                                                           maxLength={12}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (cartItemsRefs.current[index]) {
                                                                            cartItemsRefs.current[index].price = ref;
                                                                        }
                                                                    }}/>
                                                                </div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>쇼핑몰URL
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={cart.url || ''}
                                                                           onChange={(e) => handleCartProductInput(index, 'url', e.target.value)}
                                                                           maxLength={2083}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (cartItemsRefs.current[index]) {
                                                                            cartItemsRefs.current[index].url = ref;
                                                                        }
                                                                    }}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {newCartBtShow && cartOrderAccordion && cartProductHandle.length === 0 ?
                                        <div style={{
                                            height: "100px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <div style={{
                                                height: "100px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <button className="OrderPgBt2" style={{width: "80px", height: "40px"}}
                                                        onClick={newCartForm}>상품추가
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    <div>
                                        <h5 style={{color: "dimgrey", marginTop: "100px"}}>구매대행 서비스</h5>
                                    </div>
                                    {/*구매대행 폼*/}
                                    <div
                                        className={`accordion-header ${orderAccordion ? 'expanded' : ''}`}
                                        style={{position: "relative", overflow: "auto", display: "flex"}}
                                        onClick={orderAccordionFn}>
                                        <i className="accordion-icon"></i>
                                        <span>구매대행 Form</span>
                                    </div>
                                    <div
                                        className={`accordion-body ${orderAccordion ? 'expanded' : ''}`}>
                                        {/*여기서부터 DB에서 받은 폼*/}
                                        {dbOrderProductHandle.sort((a,b) => a.seqnum - b.seqnum).map((order, index) => (
                                            <div key={index}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    borderBottom: "solid",
                                                    borderWidth: "thin 1px",
                                                    borderColor: "lightgray"
                                                }}>

                                                    <div style={{width: "20%", padding: "10px"}}>
                                                        <div className="user-center" style={{marginTop: "10px"}}>
                                                            <div className="user-center" style={{
                                                                width: "100px",
                                                                height: "100px",
                                                                border: "solid",
                                                                borderWidth: "thin 1px",
                                                                borderColor: "#EAEAEA",
                                                                borderRadius: "6px"
                                                            }}>
                                                                <img style={{
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    opacity: "0.4"
                                                                }} alt="cart" src={detailOrder}/>
                                                            </div>
                                                        </div>
                                                        <div className="user-center" style={{marginTop: "10px"}}>
                                                            <p style={{margin: "0", fontSize: "18px"}}>{index + 1}.</p>
                                                        </div>
                                                    </div>
                                                    {/*폼 전체*/}
                                                    <div style={{width: "65%"}}>
                                                        {/*한 로우*/}
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>상품명
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={order.name || ''}
                                                                       onChange={(e) => handleDbOrderProductInput(index, 'name', e.target.value)}
                                                                       maxLength={200}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbOrderItemsRefs.current[index]) {
                                                                        dbOrderItemsRefs.current[index].name = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>옵션
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={order.option || ''}
                                                                       onChange={(e) => handleDbOrderProductInput(index, 'option', e.target.value)}
                                                                       maxLength={50}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>수량
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={order.quantity || ''}
                                                                       onChange={(e) => dbOrderQuantityNumberInput(index, 'quantity', e.target.value)}
                                                                       maxLength={5}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbOrderItemsRefs.current[index]) {
                                                                        dbOrderItemsRefs.current[index].quantity = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>상품금액
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                                height: "auto"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={order.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                       onChange={(e) => dbOrderPriceNumberInput(index, 'price', e.target.value)}
                                                                       maxLength={12}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbOrderItemsRefs.current[index]) {
                                                                        dbOrderItemsRefs.current[index].price = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>쇼핑몰배송비
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                                height: "auto"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={order.mallShipPrice ? order.mallShipPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}
                                                                       onChange={(e) => dbOrderMallpricericeNumberInput(index, 'mallShipPrice', e.target.value)}
                                                                       maxLength={12}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>상품URL
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={order.url}
                                                                       onChange={(e) => handleDbOrderProductInput(index, 'url', e.target.value)}
                                                                       maxLength={2083}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbOrderItemsRefs.current[index]) {
                                                                        dbOrderItemsRefs.current[index].url = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wd-15pc display-flex flex-column just-cont-center align-center font-s-16 font-b gap20">
                                                        {order?.prodSt === "IS00" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>입고대기</p>
                                                        ) : order?.prodSt === "IS01" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제대기(상품)</p>
                                                        ) : order?.prodSt === "IS02" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제중(상품)</p>
                                                        ) : order?.prodSt === "IS03" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FFB378",
                                                                fontSize: "15px"
                                                            }}>결제완료(상품)</p>
                                                        ) : order?.prodSt === "IS04" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8600",
                                                                fontSize: "15px"
                                                            }}>구매완료</p>
                                                        ) : order?.prodSt === "IS05" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#6EDA60",
                                                                fontSize: "15px"
                                                            }}>센터도착</p>
                                                        ) : order?.prodSt === "IS06" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>패킹중</p>
                                                        ) : order?.prodSt === "IS07" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제대기(배송)</p>
                                                        ) : order?.prodSt === "IS08" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제중(배송)</p>
                                                        ) : order?.prodSt === "IS09" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FFB378",
                                                                fontSize: "15px"
                                                            }}>결제완료(배송)</p>
                                                        ) : order?.prodSt === "IS10" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#979696",
                                                                fontSize: "15px"
                                                            }}>출고완료</p>
                                                        ) : order?.prodSt === "IS11" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8F62",
                                                                fontSize: "15px"
                                                            }}>반송중</p>
                                                        ) : order?.prodSt === "IS12" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8F62",
                                                                fontSize: "15px"
                                                            }}>신청취소</p>
                                                        ) : null}
                                                        <div>
                                                            {order.prodSt === "IS01" ?
                                                                <button className="OrderPgBt2" onClick={() => deleteDbOrderProduct(index)}>{t(`modAddBt3`)}</button>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {/*여기서부터 순수 구매대행 항목 자체*/}
                                        {newOrderFormSt ?
                                            <div>
                                                {
                                                    orderProductHandle.map((order, index) => (
                                                        <div key={index}>
                                                            <div className="orderPgServiceHeader">
                                                                <div style={{
                                                                    width: "43.5%",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    fontSize: "15px",
                                                                    fontWeight: "bolder",
                                                                    paddingLeft: "15px",
                                                                    color: "dimgrey"
                                                                }}>
                                                                    {index + 1}.
                                                                </div>
                                                                <div style={{
                                                                    width: "31%",
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }}>
                                                                    <button className="OrderPgBt2"
                                                                            onClick={addOrderProduct}>상품추가
                                                                    </button>
                                                                    <button className="OrderPgBt2"
                                                                            style={{marginLeft: "5px"}}
                                                                            onClick={() => copyOrderProduct(index)}>상품복사
                                                                    </button>
                                                                    <button className="OrderPgBt2"
                                                                            style={{marginLeft: "5px"}}
                                                                            onClick={() => deleteOrderProduct(index)}>상품삭제
                                                                    </button>
                                                                </div>
                                                                <div style={{
                                                                    width: "25.5%",
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }}>
                                                                    <input style={{display: "none"}} type="file"
                                                                           name="file"
                                                                           accept=".xlsx"
                                                                           ref={excelFileSelectRefOrder}
                                                                           onChange={orderExcelUpload}/>
                                                                    <button className="OrderPgBt2"
                                                                            style={{width: "90px"}}
                                                                            onClick={() => excelFileSelectRefOrder.current.click()}>대량업로드
                                                                    </button>
                                                                    <button className="OrderPgBt2"
                                                                            style={{width: "100px", marginLeft: "5px"}}
                                                                            onClick={orderExcelDown}>구매대행양식
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div style={{height: "50px", display: "flex"}}>
                                                                    <div style={{
                                                                        color: "dimgrey",
                                                                        width: "25%",
                                                                        backgroundColor: "#FAFAFA",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        paddingLeft: "20px",
                                                                        fontSize: "15px"
                                                                    }}>상품명
                                                                    </div>
                                                                    <div style={{
                                                                        padding: "0 10px 0 10px",
                                                                        margin: "0",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        width: "75%"
                                                                    }}>
                                                                        <input type="text" className="orderFormInput"
                                                                               value={order.name || ''}
                                                                               onChange={(e) => handleOrderProductInput(index, 'name', e.target.value)}
                                                                               maxLength={200}
                                                                               style={{
                                                                                   width: "100%",
                                                                                   height: "40px",
                                                                                   borderRadius: "4px",
                                                                                   borderWidth: "thin 1px",
                                                                                   padding: "0 6px 0 6px"
                                                                               }} ref={(ref) => {
                                                                            if (orderItemsRefs.current[index]) {
                                                                                orderItemsRefs.current[index].name = ref;
                                                                            }
                                                                        }}/>
                                                                    </div>
                                                                </div>
                                                                <div style={{height: "50px", display: "flex"}}>
                                                                    <div style={{
                                                                        color: "dimgrey",
                                                                        width: "25%",
                                                                        backgroundColor: "#FAFAFA",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        paddingLeft: "20px",
                                                                        fontSize: "15px"
                                                                    }}>옵션
                                                                    </div>
                                                                    <div style={{
                                                                        padding: "0 10px 0 10px",
                                                                        margin: "0",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        width: "75%"
                                                                    }}>
                                                                        <input type="text" className="orderFormInput"
                                                                               value={order.option || ''}
                                                                               onChange={(e) => handleOrderProductInput(index, 'option', e.target.value)}
                                                                               maxLength={50}
                                                                               style={{
                                                                                   width: "100%",
                                                                                   height: "40px",
                                                                                   borderRadius: "4px",
                                                                                   borderWidth: "thin 1px",
                                                                                   padding: "0 6px 0 6px",
                                                                                   overflow: "auto"
                                                                               }}/>
                                                                    </div>
                                                                </div>
                                                                <div style={{height: "50px", display: "flex"}}>
                                                                    <div style={{
                                                                        color: "dimgrey",
                                                                        width: "25%",
                                                                        backgroundColor: "#FAFAFA",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        paddingLeft: "20px",
                                                                        fontSize: "15px"
                                                                    }}>수량
                                                                    </div>
                                                                    <div style={{
                                                                        padding: "0 10px 0 10px",
                                                                        margin: "0",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        width: "75%"
                                                                    }}>
                                                                        <input type="text" className="orderFormInput"
                                                                               value={order.quantity || ''}
                                                                               onChange={(e) => orderQuantityNumberInput(index, 'quantity', e.target.value)}
                                                                               maxLength={5}
                                                                               style={{
                                                                                   width: "100%",
                                                                                   height: "40px",
                                                                                   borderRadius: "4px",
                                                                                   borderWidth: "thin 1px",
                                                                                   padding: "0 6px 0 6px"
                                                                               }} ref={(ref) => {
                                                                            if (orderItemsRefs.current[index]) {
                                                                                orderItemsRefs.current[index].quantity = ref;
                                                                            }
                                                                        }}/>
                                                                    </div>
                                                                </div>
                                                                <div style={{height: "50px", display: "flex"}}>
                                                                    <div style={{
                                                                        color: "dimgrey",
                                                                        width: "25%",
                                                                        backgroundColor: "#FAFAFA",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        paddingLeft: "20px",
                                                                        fontSize: "15px"
                                                                    }}>상품금액
                                                                    </div>
                                                                    {/*<div style={{padding:"0 10px 0 10px",margin:"auto", display:"block", alignItems:"center", width:"85%", height:"auto"}}>*/}
                                                                    <div style={{
                                                                        padding: "0 10px 0 10px",
                                                                        margin: "0",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        width: "75%",
                                                                        height: "auto"
                                                                    }}>
                                                                        <input type="text" className="orderFormInput"
                                                                               value={(order.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                               onChange={(e) => orderPriceNumberInput(index, 'price', e.target.value)}
                                                                               maxLength={12}
                                                                               style={{
                                                                                   width: "100%",
                                                                                   height: "40px",
                                                                                   borderRadius: "4px",
                                                                                   borderWidth: "thin 1px",
                                                                                   padding: "0 6px 0 6px"
                                                                               }} ref={(ref) => {
                                                                            if (orderItemsRefs.current[index]) {
                                                                                orderItemsRefs.current[index].price = ref;
                                                                            }
                                                                        }}/>
                                                                    </div>
                                                                </div>
                                                                <div style={{height: "50px", display: "flex"}}>
                                                                    <div style={{
                                                                        color: "dimgrey",
                                                                        width: "25%",
                                                                        backgroundColor: "#FAFAFA",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        paddingLeft: "20px",
                                                                        fontSize: "15px"
                                                                    }}>쇼핑몰배송비
                                                                    </div>
                                                                    <div style={{
                                                                        padding: "0 10px 0 10px",
                                                                        margin: "0",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        width: "75%",
                                                                        height: "auto"
                                                                    }}>
                                                                        <input type="text" className="orderFormInput"
                                                                               value={(order.mallShipPrice)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                               onChange={(e) => orderMallpricericeNumberInput(index, 'mallShipPrice', e.target.value)}
                                                                               maxLength={6}
                                                                               style={{
                                                                                   width: "100%",
                                                                                   height: "40px",
                                                                                   borderRadius: "4px",
                                                                                   borderWidth: "thin 1px",
                                                                                   padding: "0 6px 0 6px"
                                                                               }}/></div>
                                                                </div>
                                                                <div style={{height: "50px", display: "flex"}}>
                                                                    <div style={{
                                                                        color: "dimgrey",
                                                                        width: "25%",
                                                                        backgroundColor: "#FAFAFA",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        paddingLeft: "20px",
                                                                        fontSize: "15px"
                                                                    }}>상품URL
                                                                    </div>
                                                                    <div style={{
                                                                        padding: "0 10px 0 10px",
                                                                        margin: "0",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        width: "75%"
                                                                    }}>
                                                                        <input type="text" className="orderFormInput"
                                                                               value={order.url || ''}
                                                                               onChange={(e) => handleOrderProductInput(index, 'url', e.target.value)}
                                                                               maxLength={2083}
                                                                               style={{
                                                                                   width: "100%",
                                                                                   height: "40px",
                                                                                   borderRadius: "4px",
                                                                                   borderWidth: "thin 1px",
                                                                                   padding: "0 6px 0 6px"
                                                                               }} ref={(ref) => {
                                                                            if (orderItemsRefs.current[index]) {
                                                                                orderItemsRefs.current[index].url = ref;
                                                                            }
                                                                        }}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {newOrderBtShow && orderAccordion && orderProductHandle.length === 0 ?
                                        <div style={{
                                            height: "100px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <button className="OrderPgBt2" style={{width: "80px", height: "40px"}}
                                                    onClick={newOrderForm}>상품추가
                                            </button>
                                        </div>
                                        :
                                        null
                                    }
                                    <div>
                                        <h5 style={{color: "dimgrey", marginTop: "100px"}}>오프라인 서비스</h5>
                                    </div>
                                    {/*오프라인 구매대행 폼*/}
                                    <div
                                        className={`accordion-header ${offlineAccordion ? 'expanded' : ''}`}
                                        style={{position: "relative", overflow: "auto", display: "flex"}}
                                        onClick={offlineAccordionFn}>
                                        <i className="accordion-icon"></i>
                                        <span>오프라인 구매대행 Form</span>
                                    </div>
                                    <div
                                        className={`accordion-body ${offlineAccordion ? 'expanded' : ''}`}>
                                        {/*여기서부터 DB에서 받은 폼*/}
                                        {dbOfflineProductHandle.sort((a,b) => a.seqnum - b.seqnum).map((offline, index) => (
                                            <div key={index}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    borderBottom: "solid",
                                                    borderWidth: "thin 1px",
                                                    borderColor: "lightgray"
                                                }}>

                                                    <div style={{width: "20%", padding: "10px"}}>
                                                        <div className="user-center" style={{marginTop: "10px"}}>
                                                            <div className="user-center" style={{
                                                                width: "100px",
                                                                height: "100px",
                                                                border: "solid",
                                                                borderWidth: "thin 1px",
                                                                borderColor: "#EAEAEA",
                                                                borderRadius: "6px"
                                                            }}>
                                                                <img style={{
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    opacity: "0.4"
                                                                }} alt="cart" src={detailOffline}/>
                                                            </div>
                                                        </div>
                                                        <div className="user-center" style={{marginTop: "10px"}}>
                                                            <p style={{margin: "0", fontSize: "18px"}}>{index + 1}.</p>
                                                        </div>
                                                    </div>
                                                    {/*폼 전체*/}
                                                    <div style={{width: "65%"}}>
                                                        {/*한 로우*/}
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>상품명
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={offline.name || ''}
                                                                       onChange={(e) => handleDbOfflineProductInput(index, 'name', e.target.value)}
                                                                       maxLength={200}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbOfflineItemsRefs.current[index]) {
                                                                        dbOfflineItemsRefs.current[index].name = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>

                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>수량
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={offline.quantity || ''}
                                                                       onChange={(e) => dbOfflineQuantityNumberInput(index, 'quantity', e.target.value)}
                                                                       maxLength={5}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbOfflineItemsRefs.current[index]) {
                                                                        dbOfflineItemsRefs.current[index].quantity = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>상품금액
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                                height: "auto"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={offline.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                       onChange={(e) => dbOfflinePriceNumberInput(index, 'price', e.target.value)}
                                                                       maxLength={12}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbOfflineItemsRefs.current[index]) {
                                                                        dbOfflineItemsRefs.current[index].price = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>수수료
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                                height: "auto"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={offline.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                       onChange={(e) => dbOfflineFeeNumberInput(index, 'fee', e.target.value)}
                                                                       maxLength={6}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbOfflineItemsRefs.current[index]) {
                                                                        dbOfflineItemsRefs.current[index].fee = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wd-15pc display-flex flex-column just-cont-center align-center font-s-16 font-b gap20">
                                                        {offline?.prodSt === "IS00" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>입고대기</p>
                                                        ) : offline?.prodSt === "IS01" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제대기(상품)</p>
                                                        ) : offline?.prodSt === "IS02" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제중(상품)</p>
                                                        ) : offline?.prodSt === "IS03" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FFB378",
                                                                fontSize: "15px"
                                                            }}>결제완료(상품)</p>
                                                        ) : offline?.prodSt === "IS04" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8600",
                                                                fontSize: "15px"
                                                            }}>구매완료</p>
                                                        ) : offline?.prodSt === "IS05" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#6EDA60",
                                                                fontSize: "15px"
                                                            }}>센터도착</p>
                                                        ) : offline?.prodSt === "IS06" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>패킹중</p>
                                                        ) : offline?.prodSt === "IS07" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제대기(배송)</p>
                                                        ) : offline?.prodSt === "IS08" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제중(배송)</p>
                                                        ) : offline?.prodSt === "IS09" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FFB378",
                                                                fontSize: "15px"
                                                            }}>결제완료(배송)</p>
                                                        ) : offline?.prodSt === "IS10" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#979696",
                                                                fontSize: "15px"
                                                            }}>출고완료</p>
                                                        ) : offline?.prodSt === "IS11" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8F62",
                                                                fontSize: "15px"
                                                            }}>반송중</p>
                                                        ) : offline?.prodSt === "IS12" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8F62",
                                                                fontSize: "15px"
                                                            }}>신청취소</p>
                                                        ) : null}
                                                        <div>
                                                            {offline.prodSt === "IS01" ?
                                                                <button className="OrderPgBt2" onClick={() => deleteDbOfflineProduct(index)}>{t(`modAddBt3`)}</button>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {/*여기서부터 순수 오프라인 구매대행 항목 자체*/}
                                        {newOfflineFormSt ?
                                            <div>
                                                {offlineProductHandle.map((offline, index) => (
                                                    <div key={index}>
                                                        <div className="orderPgServiceHeader">
                                                            <div style={{
                                                                width: "43.5%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                fontSize: "15px",
                                                                fontWeight: "bolder",
                                                                paddingLeft: "15px",
                                                                color: "dimgrey"
                                                            }}>
                                                                {index + 1}.
                                                            </div>
                                                            <div style={{
                                                                width: "31%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <button className="OrderPgBt2"
                                                                        onClick={addOfflineProduct}>상품추가
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{marginLeft: "5px"}}
                                                                        onClick={() => copyOfflineProduct(index)}>상품복사
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{marginLeft: "5px"}}
                                                                        onClick={() => deleteOfflineProduct(index)}>상품삭제
                                                                </button>
                                                            </div>
                                                            <div style={{
                                                                width: "25.5%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <input style={{display: "none"}} type="file" name="file"
                                                                       accept=".xlsx" ref={excelFileSelectRefOffline}
                                                                       onChange={offlineExcelUpload}/>
                                                                <button className="OrderPgBt2"
                                                                        style={{width: "90px"}}
                                                                        onClick={() => excelFileSelectRefOffline.current.click()}>대량업로드
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{width: "100px", marginLeft: "5px"}}
                                                                        onClick={offlineExcelDown}>오프라인양식
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>상품명
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={offline.name || ''}
                                                                           onChange={(e) => handleOfflineProductInput(index, 'name', e.target.value)}
                                                                           maxLength={200}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (offlineItemsRefs.current[index]) {
                                                                            offlineItemsRefs.current[index].name = ref;
                                                                        }
                                                                    }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>수량
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={offline.quantity || ''}
                                                                           onChange={(e) => offlineQuantityNumberInput(index, 'quantity', e.target.value)}
                                                                           maxLength={5}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (offlineItemsRefs.current[index]) {
                                                                            offlineItemsRefs.current[index].quantity = ref;
                                                                        }
                                                                    }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>상품금액
                                                                </div>
                                                                {/*<div style={{padding:"0 10px 0 10px",margin:"auto", display:"block", alignItems:"center", width:"85%", height:"auto"}}>*/}
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%",
                                                                    height: "auto"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={(offline.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                           onChange={(e) => offlinePriceNumberInput(index, 'price', e.target.value)}
                                                                           maxLength={12}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (offlineItemsRefs.current[index]) {
                                                                            offlineItemsRefs.current[index].price = ref;
                                                                        }
                                                                    }}/>
                                                                </div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>수수료
                                                                </div>
                                                                {/*<div style={{padding:"0 10px 0 10px",margin:"auto", display:"block", alignItems:"center", width:"85%", height:"auto"}}>*/}
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%",
                                                                    height: "auto"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={(offline.fee)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                           onChange={(e) => offlineFeeNumberInput(index, 'fee', e.target.value)}
                                                                           maxLength={6}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} placeholder="편의점 5,000원 외 모두 15,000원"
                                                                           ref={(ref) => {
                                                                               if (offlineItemsRefs.current[index]) {
                                                                                   offlineItemsRefs.current[index].fee = ref;
                                                                               }
                                                                           }}/>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {newOfflineBtShow && offlineAccordion && offlineProductHandle.length === 0 ?
                                        <div style={{
                                            height: "100px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <button className="OrderPgBt2" style={{width: "80px", height: "40px"}}
                                                    onClick={newOfflineForm}>상품추가
                                            </button>
                                        </div>
                                        :
                                        null
                                    }
                                    <div>
                                        <h5 style={{color: "dimgrey", marginTop: "100px"}}>이체대행 서비스</h5>
                                    </div>
                                    {/*이체대행 폼*/}
                                    <div
                                        className={`accordion-header ${transferAccordion ? 'expanded' : ''}`}
                                        style={{position: "relative", overflow: "auto", display: "flex"}}
                                        onClick={transferAccordionFn}>
                                        <i className="accordion-icon"></i>
                                        <span>이체대행 Form</span>
                                    </div>
                                    <div
                                        className={`accordion-body ${transferAccordion ? 'expanded' : ''}`}>
                                        {/*여기서부터 DB에서 받은 폼*/}
                                        {dbTransferProductHandle.sort((a,b) => a.seqnum - b.seqnum).map((transfer, index) => (
                                            <div key={index}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    borderBottom: "solid",
                                                    borderWidth: "thin 1px",
                                                    borderColor: "lightgray"
                                                }}>
                                                    <div style={{width: "20%", padding: "10px"}}>
                                                        <div className="user-center" style={{marginTop: "10px"}}>
                                                            <div className="user-center" style={{
                                                                width: "100px",
                                                                height: "100px",
                                                                border: "solid",
                                                                borderWidth: "thin 1px",
                                                                borderColor: "#EAEAEA",
                                                                borderRadius: "6px"
                                                            }}>
                                                                <img style={{
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    opacity: "0.4"
                                                                }} alt="cart" src={detailTransfer}/>
                                                            </div>
                                                        </div>
                                                        <div className="user-center" style={{marginTop: "10px"}}>
                                                            <p style={{margin: "0", fontSize: "18px"}}>{index + 1}.</p>
                                                        </div>
                                                    </div>
                                                    {/*폼 전체*/}
                                                    <div style={{width: "65%"}}>
                                                        <div style={{height: "10px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                                height: "auto"
                                                            }}>
                                                            </div>
                                                        </div>
                                                        {/*한 로우*/}
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>계좌정보
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={transfer.name || ''}
                                                                       onChange={(e) => handleDbTransferProductInput(index, 'name', e.target.value)}
                                                                       maxLength={200}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbTransferItemsRefs.current[index]) {
                                                                        dbTransferItemsRefs.current[index].name = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>이체금액
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                                height: "auto"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={transfer.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                       onChange={(e) => dbTransferPriceNumberInput(index, 'price', e.target.value)}
                                                                       maxLength={12}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbTransferItemsRefs.current[index]) {
                                                                        dbTransferItemsRefs.current[index].price = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>수수료(7%)
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                                height: "auto"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={transfer.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                       onChange={(e) => dbTransferFeeNumberInput(index, 'fee', e.target.value)}
                                                                       maxLength={12}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbTransferItemsRefs.current[index]) {
                                                                        dbTransferItemsRefs.current[index].fee = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "10px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                                height: "auto"
                                                            }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wd-15pc display-flex flex-column just-cont-center align-center font-s-16 font-b gap20">
                                                        {transfer?.prodSt === "IS00" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>입고대기</p>
                                                        ) : transfer?.prodSt === "IS01" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제대기(상품)</p>
                                                        ) : transfer?.prodSt === "IS02" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제중(상품)</p>
                                                        ) : transfer?.prodSt === "IS03" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FFB378",
                                                                fontSize: "15px"
                                                            }}>결제완료(상품)</p>
                                                        ) : transfer?.prodSt === "IS04" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8600",
                                                                fontSize: "15px"
                                                            }}>구매완료</p>
                                                        ) : transfer?.prodSt === "IS05" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#6EDA60",
                                                                fontSize: "15px"
                                                            }}>센터도착</p>
                                                        ) : transfer?.prodSt === "IS06" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>패킹중</p>
                                                        ) : transfer?.prodSt === "IS07" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제대기(배송)</p>
                                                        ) : transfer?.prodSt === "IS08" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제중(배송)</p>
                                                        ) : transfer?.prodSt === "IS09" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FFB378",
                                                                fontSize: "15px"
                                                            }}>결제완료(배송)</p>
                                                        ) : transfer?.prodSt === "IS10" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#979696",
                                                                fontSize: "15px"
                                                            }}>출고완료</p>
                                                        ) : transfer?.prodSt === "IS11" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8F62",
                                                                fontSize: "15px"
                                                            }}>반송중</p>
                                                        ) : transfer?.prodSt === "IS12" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8F62",
                                                                fontSize: "15px"
                                                            }}>신청취소</p>
                                                        ) : null}
                                                        <div>
                                                            {transfer.prodSt === "IS01" ?
                                                                <button className="OrderPgBt2" onClick={() => deleteDbTransferProduct(index)}>{t(`modAddBt3`)}</button>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {/*여기서부터 순수 이체대행 항목 자체*/}
                                        {newTransferFormSt ?
                                            <div>
                                                {transferProductHandle.map((transfer, index) => (
                                                    <div key={index}>
                                                        <div className="orderPgServiceHeader">
                                                            <div style={{
                                                                width: "43.5%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                fontSize: "15px",
                                                                fontWeight: "bolder",
                                                                paddingLeft: "15px",
                                                                color: "dimgrey"
                                                            }}>
                                                                {index + 1}.
                                                            </div>
                                                            <div style={{
                                                                width: "31%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <button className="OrderPgBt2"
                                                                        onClick={addTransferProduct}>상품추가
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{marginLeft: "5px"}}
                                                                        onClick={() => copyTransferProduct(index)}>상품복사
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{marginLeft: "5px"}}
                                                                        onClick={() => deleteTransferProduct(index)}>상품삭제
                                                                </button>
                                                            </div>
                                                            <div style={{
                                                                width: "25.5%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <input style={{display: "none"}} type="file" name="file"
                                                                       accept=".xlsx" ref={excelFileSelectRefTransfer}
                                                                       onChange={transferExcelUpload}/>
                                                                <button className="OrderPgBt2"
                                                                        style={{width: "90px"}}
                                                                        onClick={() => excelFileSelectRefTransfer.current.click()}>대량업로드
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{width: "100px", marginLeft: "5px"}}
                                                                        onClick={transferExcelDown}>이체대행양식
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>이체계좌정보
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={transfer.name || ''}
                                                                           onChange={(e) => handleTransferProductInput(index, 'name', e.target.value)}
                                                                           maxLength={200}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (transferItemsRefs.current[index]) {
                                                                            transferItemsRefs.current[index].name = ref;
                                                                        }
                                                                    }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>이체금액
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%",
                                                                    height: "auto"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={(transfer.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                           onChange={(e) => transferPriceNumberInput(index, 'price', e.target.value)}
                                                                           maxLength={12}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (transferItemsRefs.current[index]) {
                                                                            transferItemsRefs.current[index].price = ref;
                                                                        }
                                                                    }}/>
                                                                </div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>수수료(7%)
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%",
                                                                    height: "auto"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={(transfer.fee)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                           onChange={(e) => transferFeeNumberInput(index, 'fee', e.target.value)}
                                                                           maxLength={12}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (transferItemsRefs.current[index]) {
                                                                            transferItemsRefs.current[index].fee = ref;
                                                                        }
                                                                    }}/>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {newTransferBtShow && transferAccordion && transferProductHandle.length === 0 ?
                                        <div style={{
                                            height: "100px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <button className="OrderPgBt2" style={{width: "80px", height: "40px"}}
                                                    onClick={newTransferForm}>상품추가
                                            </button>
                                        </div>
                                        :
                                        null
                                    }
                                    <div>
                                        <h5 style={{color: "dimgrey", marginTop: "100px"}}>국내배송 서비스</h5>
                                    </div>
                                    {/*국내배송(구매대행) 폼*/}
                                    <div
                                        className={`accordion-header ${domesticAccordion ? 'expanded' : ''}`}
                                        style={{position: "relative", overflow: "auto", display: "flex"}}
                                        onClick={domesticAccordionFn}>
                                        <i className="accordion-icon"></i>
                                        <span>국내배송 구매대행 Form</span>
                                    </div>
                                    <div
                                        className={`accordion-body ${domesticAccordion ? 'expanded' : ''}`}>
                                        {/*여기서부터 DB에서 받은 폼*/}
                                        {dbDomesticProductHandle.sort((a,b) => a.seqnum - b.seqnum).map((domestic, index) => (
                                            <div key={index}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    borderBottom: "solid",
                                                    borderWidth: "thin 1px",
                                                    borderColor: "lightgray"
                                                }}>
                                                    <div style={{width: "20%", padding: "10px"}}>
                                                        <div className="user-center" style={{marginTop: "10px"}}>
                                                            <div className="user-center" style={{
                                                                width: "100px",
                                                                height: "100px",
                                                                border: "solid",
                                                                borderWidth: "thin 1px",
                                                                borderColor: "#EAEAEA",
                                                                borderRadius: "6px"
                                                            }}>
                                                                <img style={{
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    opacity: "0.4"
                                                                }} alt="cart" src={detailDomestic}/>
                                                            </div>
                                                        </div>
                                                        <div className="user-center" style={{marginTop: "10px"}}>
                                                            <p style={{margin: "0", fontSize: "18px"}}>{index + 1}.</p>
                                                        </div>
                                                    </div>
                                                    {/*폼 전체*/}
                                                    <div style={{width: "65%"}}>
                                                        {/*한 로우*/}
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>상품명
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={domestic.name || ''}
                                                                       onChange={(e) => handleDbDomesticProductInput(index, 'name', e.target.value)}
                                                                       maxLength={200}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbDomesticItemsRefs.current[index]) {
                                                                        dbDomesticItemsRefs.current[index].name = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>옵션
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={domestic.option || ''}
                                                                       onChange={(e) => handleDbDomesticProductInput(index, 'option', e.target.value)}
                                                                       maxLength={50}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>수량
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={domestic.quantity || ''}
                                                                       onChange={(e) => dbDomesticQuantityNumberInput(index, 'quantity', e.target.value)}
                                                                       maxLength={5}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbDomesticItemsRefs.current[index]) {
                                                                        dbDomesticItemsRefs.current[index].quantity = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>상품금액
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                                height: "auto"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={domestic.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                       onChange={(e) => dbDomesticPriceNumberInput(index, 'price', e.target.value)}
                                                                       maxLength={12}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbDomesticItemsRefs.current[index]) {
                                                                        dbDomesticItemsRefs.current[index].price = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>쇼핑몰배송비
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                                height: "auto"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={domestic.mallShipPrice ? domestic.mallShipPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}
                                                                       onChange={(e) => dbDomesticMallpriceNumberInput(index, 'mallShipPrice', e.target.value)}
                                                                       maxLength={6}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>수수료(7%)
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                                height: "auto"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={domestic.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                       onChange={(e) => dbDomesticFeeNumberInput(index, 'mallShipPrice', e.target.value)}
                                                                       maxLength={12}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbDomesticItemsRefs.current[index]) {
                                                                        dbDomesticItemsRefs.current[index].fee = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>상품URL
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={domestic.url}
                                                                       onChange={(e) => handleDbDomesticProductInput(index, 'url', e.target.value)}
                                                                       maxLength={2083}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbDomesticItemsRefs.current[index]) {
                                                                        dbDomesticItemsRefs.current[index].url = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wd-15pc display-flex flex-column just-cont-center align-center font-s-16 font-b gap20">
                                                        {domestic?.prodSt === "IS00" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>입고대기</p>
                                                        ) : domestic?.prodSt === "IS01" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제대기(상품)</p>
                                                        ) : domestic?.prodSt === "IS02" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제중(상품)</p>
                                                        ) : domestic?.prodSt === "IS03" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FFB378",
                                                                fontSize: "15px"
                                                            }}>결제완료(상품)</p>
                                                        ) : domestic?.prodSt === "IS04" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8600",
                                                                fontSize: "15px"
                                                            }}>구매완료</p>
                                                        ) : domestic?.prodSt === "IS05" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#6EDA60",
                                                                fontSize: "15px"
                                                            }}>센터도착</p>
                                                        ) : domestic?.prodSt === "IS06" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>패킹중</p>
                                                        ) : domestic?.prodSt === "IS07" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제대기(배송)</p>
                                                        ) : domestic?.prodSt === "IS08" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제중(배송)</p>
                                                        ) : domestic?.prodSt === "IS09" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FFB378",
                                                                fontSize: "15px"
                                                            }}>결제완료(배송)</p>
                                                        ) : domestic?.prodSt === "IS10" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#979696",
                                                                fontSize: "15px"
                                                            }}>출고완료</p>
                                                        ) : domestic?.prodSt === "IS11" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8F62",
                                                                fontSize: "15px"
                                                            }}>반송중</p>
                                                        ) : domestic?.prodSt === "IS12" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8F62",
                                                                fontSize: "15px"
                                                            }}>신청취소</p>
                                                        ) : null}
                                                        <div>
                                                            {domestic.prodSt === "IS01" ?
                                                                <button className="OrderPgBt2" onClick={() => deleteDbDomesticProduct(index)}>{t(`modAddBt3`)}</button>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {/*여기서부터 순수 국내배송 구매대행 항목 자체*/}
                                        {newDomesticFormSt ?
                                            <div>
                                                {domesticProductHandle.map((domestic, index) => (
                                                    <div key={index}>
                                                        <div className="orderPgServiceHeader">
                                                            <div style={{
                                                                width: "43.5%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                fontSize: "15px",
                                                                fontWeight: "bolder",
                                                                paddingLeft: "15px",
                                                                color: "dimgrey"
                                                            }}>
                                                                {index + 1}.
                                                            </div>
                                                            <div style={{
                                                                width: "31%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <button className="OrderPgBt2"
                                                                        onClick={addDomesticProduct}>상품추가
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{marginLeft: "5px"}}
                                                                        onClick={() => copyDomesticProduct(index)}>상품복사
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{marginLeft: "5px"}}
                                                                        onClick={() => deleteDomesticProduct(index)}>상품삭제
                                                                </button>
                                                            </div>
                                                            <div style={{
                                                                width: "25.5%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <input style={{display: "none"}} type="file" name="file"
                                                                       accept=".xlsx" ref={excelFileSelectRefDomestic}
                                                                       onChange={domesticExcelUpload}/>
                                                                <button className="OrderPgBt2"
                                                                        style={{width: "90px"}}
                                                                        onClick={() => excelFileSelectRefDomestic.current.click()}>대량업로드
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{width: "100px", marginLeft: "5px"}}
                                                                        onClick={domesticExcelDown}>국내구매양식
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>상품명
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={domestic.name || ''}
                                                                           onChange={(e) => handleDomesticProductInput(index, 'name', e.target.value)}
                                                                           maxLength={200}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (domesticItemsRefs.current[index]) {
                                                                            domesticItemsRefs.current[index].name = ref;
                                                                        }
                                                                    }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>옵션
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={domestic.option || ''}
                                                                           onChange={(e) => handleDomesticProductInput(index, 'option', e.target.value)}
                                                                           maxLength={50}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px",
                                                                               overflow: "auto"
                                                                           }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>수량
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={domestic.quantity || ''}
                                                                           onChange={(e) => domesticQuantityNumberInput(index, 'quantity', e.target.value)}
                                                                           maxLength={5}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (domesticItemsRefs.current[index]) {
                                                                            domesticItemsRefs.current[index].quantity = ref;
                                                                        }
                                                                    }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>상품금액
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%",
                                                                    height: "auto"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={(domestic.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                           onChange={(e) => domesticPriceNumberInput(index, 'price', e.target.value)}
                                                                           maxLength={12}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (domesticItemsRefs.current[index]) {
                                                                            domesticItemsRefs.current[index].price = ref;
                                                                        }
                                                                    }}/>
                                                                </div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>쇼핑몰배송비
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%",
                                                                    height: "auto"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={(domestic.mallShipPrice)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                           onChange={(e) => domesticMallpriceNumberInput(index, 'mallShipPrice', e.target.value)}
                                                                           maxLength={6}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }}/></div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>수수료(7%)
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%",
                                                                    height: "auto"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={(domestic.fee)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                                           onChange={(e) => domesticFeeNumberInput(index, 'fee', e.target.value)}
                                                                           maxLength={12}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (domesticItemsRefs.current[index]) {
                                                                            domesticItemsRefs.current[index].fee = ref;
                                                                        }
                                                                    }}/>
                                                                </div>
                                                            </div>
                                                            <div style={{height: "50px", display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>상품URL
                                                                </div>
                                                                <div style={{
                                                                    padding: "0 10px 0 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={domestic.url || ''}
                                                                           onChange={(e) => handleDomesticProductInput(index, 'url', e.target.value)}
                                                                           maxLength={2083}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (domesticItemsRefs.current[index]) {
                                                                            domesticItemsRefs.current[index].url = ref;
                                                                        }
                                                                    }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {newDomesticBtShow && domesticAccordion && domesticProductHandle.length === 0 ?
                                        <div style={{
                                            height: "100px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <button className="OrderPgBt2" style={{width: "80px", height: "40px"}}
                                                    onClick={newDomesticForm}>상품추가
                                            </button>
                                        </div>
                                        :
                                        null
                                    }
                                    <div>
                                        <h5 style={{color: "dimgrey", marginTop: "100px"}}>배송대행 서비스</h5>
                                    </div>
                                    {/*배송대행 폼*/}
                                    <div
                                        className={`accordion-header ${deliveryAccordion ? 'expanded' : ''}`}
                                        style={{position: "relative", overflow: "auto", display: "flex"}}
                                        onClick={deliveryAccordionFn}>
                                        <i className="accordion-icon"></i>
                                        <span>배송대행 Form</span>
                                    </div>
                                    <div
                                        className={`accordion-body ${deliveryAccordion ? 'expanded' : ''}`}>
                                        {/*여기서부터 DB에서 받은 폼*/}
                                        {dbDeliveryProductHandle.sort((a,b) => a.seqnum - b.seqnum).map((delivery, index) => (
                                            <div key={index}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    borderBottom: "solid",
                                                    borderWidth: "thin 1px",
                                                    borderColor: "lightgray"
                                                }}>
                                                    <div style={{width: "20%", padding: "10px"}}>
                                                        <div className="user-center" style={{marginTop: "10px"}}>
                                                            <div className="user-center" style={{
                                                                width: "100px",
                                                                height: "100px",
                                                                border: "solid",
                                                                borderWidth: "thin 1px",
                                                                borderColor: "#EAEAEA",
                                                                borderRadius: "6px"
                                                            }}>
                                                                <img style={{
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    opacity: "0.4"
                                                                }} alt="cart" src={detailDelivery}/>
                                                            </div>
                                                        </div>
                                                        <div className="user-center" style={{marginTop: "10px"}}>
                                                            <p style={{margin: "0", fontSize: "18px"}}>{index + 1}.</p>
                                                        </div>
                                                    </div>
                                                    {/*폼 전체*/}
                                                    <div style={{width: "65%"}}>
                                                        {/*한 로우*/}
                                                        <div style={{height: "55px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>택배사
                                                            </div>

                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                            }}>

                                                                {/*국내배송 셀렉트*/}
                                                                <div style={{width: "100%", height: "40px"}}>
                                                                    <div
                                                                        ref={(el) => dbDomesticRefs.current[index] = el}
                                                                        style={{
                                                                            height: "100%",
                                                                            width: "100%",
                                                                            position: "relative"
                                                                        }}>
                                                                        <button className="selectParentBt"
                                                                                style={{width: "100%", height: "100%"}}
                                                                                onClick={() => toggleDbDomesticShow(index)}>
                                                                            <p style={{
                                                                                margin: "0",
                                                                                color: "dimgray"
                                                                            }}>{delivery.domShipco === '' ? "국내배송사" : delivery.domShipco}</p>
                                                                            <span style={{
                                                                                position: "absolute",
                                                                                left: "80%",
                                                                                top: "0",
                                                                                height: "100%",
                                                                                width: "20%"
                                                                            }}>
                                                                                <i style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    height: "100%",
                                                                                    color: "darkgray"
                                                                                }}><BiCaretDown/></i>
                                                                            </span>
                                                                        </button>
                                                                        {dbDomesticShow[index] ?
                                                                            <div style={{
                                                                                position: "absolute",
                                                                                top: "55px",
                                                                                width: "100%",
                                                                                border: "solid",
                                                                                borderWidth: "thin 1px",
                                                                                borderColor: "lightgray",
                                                                                zIndex: 2,
                                                                            }}>
                                                                                {domesticDeliveryChoice.map((domestic, i) => (
                                                                                    <div className="selectChildBt"
                                                                                         key={i} value={domestic}
                                                                                         onClick={() => handleDbDeliveryDomesticChange(index, domestic)}
                                                                                         style={{
                                                                                             display: "flex",
                                                                                             padding: "10px",
                                                                                             justifyContent: "center",
                                                                                             alignItems: "center",
                                                                                             cursor: "pointer",
                                                                                             fontSize: "14px",
                                                                                         }}>{domestic}</div>
                                                                                ))}
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px",
                                                            }}>운송장번호
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%",
                                                                position: "relative"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={delivery.inWaynum || ''}
                                                                       onChange={(e) => dbDeliveryWaynumNumberInput(index, 'inwaynum', e.target.value)}
                                                                       maxLength={20}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }}/>
                                                                <img
                                                                    onClick={() => dbSelectedDeliveryTracking(delivery.domShipco, delivery.inWaynum)}
                                                                    style={{
                                                                        width: "25px",
                                                                        height: "25px",
                                                                        position: "absolute",
                                                                        right: "15px",
                                                                        opacity: "0.6",
                                                                        cursor: "pointer"
                                                                    }}
                                                                    alt="serch" src={searchTrack}/>
                                                            </div>
                                                        </div>
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>물품명
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={delivery.name || ''}
                                                                       onChange={(e) => handleDbDeliveryProductInput(index, 'name', e.target.value)}
                                                                       maxLength={200}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px",
                                                                           fontSize: "13px"
                                                                       }} ref={(ref) => {
                                                                    if (dbOrderItemsRefs.current[index]) {
                                                                        dbOrderItemsRefs.current[index].name = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        {/*DB배송대행옵션*/}
                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>옵션
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={delivery.option || ''}
                                                                       onChange={(e) => handleDbDeliveryProductInput(index, 'option', e.target.value)}
                                                                       maxLength={200}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px",
                                                                           fontSize: "13px"
                                                                       }}/>
                                                            </div>
                                                        </div>

                                                        <div style={{height: "50px", display: "flex"}}>
                                                            <div style={{
                                                                color: "dimgrey",
                                                                width: "30%",
                                                                backgroundColor: "#FAFAFA",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "20px",
                                                                fontSize: "15px"
                                                            }}>총 수량
                                                            </div>
                                                            <div style={{
                                                                padding: "0 10px 0 10px",
                                                                margin: "0",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "70%"
                                                            }}>
                                                                <input type="text" className="orderFormInput"
                                                                       value={delivery.quantity || ''}
                                                                       onChange={(e) => dbDeliveryQuantityNumberInput(index, 'quantity', e.target.value)}
                                                                       maxLength={5}
                                                                       style={{
                                                                           width: "100%",
                                                                           height: "40px",
                                                                           borderRadius: "4px",
                                                                           borderWidth: "thin 1px",
                                                                           padding: "0 6px 0 6px"
                                                                       }} ref={(ref) => {
                                                                    if (dbOrderItemsRefs.current[index]) {
                                                                        dbOrderItemsRefs.current[index].quantity = ref;
                                                                    }
                                                                }}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wd-15pc display-flex flex-column just-cont-center align-center font-s-16 font-b gap20">
                                                        {delivery?.prodSt === "IS00" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>입고대기</p>
                                                        ) : delivery?.prodSt === "IS01" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제대기(상품)</p>
                                                        ) : delivery?.prodSt === "IS02" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제중(상품)</p>
                                                        ) : delivery?.prodSt === "IS03" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FFB378",
                                                                fontSize: "15px"
                                                            }}>결제완료(상품)</p>
                                                        ) : delivery?.prodSt === "IS04" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8600",
                                                                fontSize: "15px"
                                                            }}>구매완료</p>
                                                        ) : delivery?.prodSt === "IS05" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#6EDA60",
                                                                fontSize: "15px"
                                                            }}>센터도착</p>
                                                        ) : delivery?.prodSt === "IS06" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>패킹중</p>
                                                        ) : delivery?.prodSt === "IS07" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제대기(배송)</p>
                                                        ) : delivery?.prodSt === "IS08" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제중(배송)</p>
                                                        ) : delivery?.prodSt === "IS09" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FFB378",
                                                                fontSize: "15px"
                                                            }}>결제완료(배송)</p>
                                                        ) : delivery?.prodSt === "IS10" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#979696",
                                                                fontSize: "15px"
                                                            }}>출고완료</p>
                                                        ) : delivery?.prodSt === "IS11" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8F62",
                                                                fontSize: "15px"
                                                            }}>반송중</p>
                                                        ) : delivery?.prodSt === "IS12" ? (
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#FF8F62",
                                                                fontSize: "15px"
                                                            }}>신청취소</p>
                                                        ) : null}
                                                        <div>
                                                            {delivery.prodSt === "IS01" ?
                                                                <button className="OrderPgBt2" onClick={() => deleteDbDeliveryProduct(index)}>{t(`modAddBt3`)}</button>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {/*여기서부터 순수 배송대행 항목 자체*/}
                                        {newDeliveryFormSt ?
                                            <div>
                                                {deliveryProductHandle.map((delivery, index) => (
                                                    <div key={index}>
                                                        <div className="orderPgServiceHeader">
                                                            <div style={{
                                                                width: "43.5%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                fontSize: "17px",
                                                                fontWeight: "bolder",
                                                                paddingLeft: "15px",
                                                                color: "dimgrey"
                                                            }}>
                                                                {index + 1}.
                                                            </div>
                                                            <div style={{
                                                                width: "31%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <button className="OrderPgBt2"
                                                                        onClick={addDeliveryProduct}>물품추가
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{marginLeft: "5px"}}
                                                                        onClick={() => copyDeliveryProduct(index)}>물품복사
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{marginLeft: "5px"}}
                                                                        onClick={() => deleteDeliveryProduct(index)}>물품삭제
                                                                </button>
                                                            </div>
                                                            <div style={{
                                                                width: "25.5%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <input style={{display: "none"}} type="file" name="file"
                                                                       accept=".xlsx" ref={excelFileSelectRefDelivery}
                                                                       onChange={deliveryExcelUpload}/>
                                                                <button className="OrderPgBt2"
                                                                        style={{width: "90px"}}
                                                                        onClick={() => excelFileSelectRefDelivery.current.click()}>대량업로드
                                                                </button>
                                                                <button className="OrderPgBt2"
                                                                        style={{width: "100px", marginLeft: "5px"}}
                                                                        onClick={deliveryExcelDown}>배송대행양식
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>운송장번호
                                                                </div>
                                                                <div style={{
                                                                    padding: "15px 10px 10px 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%"
                                                                }}>

                                                                    <div style={{
                                                                        width: "35%",
                                                                        height: "50px",
                                                                        display: "flex",
                                                                        alignItems: "center"
                                                                    }}>

                                                                        {/*국내배송 셀렉트*/}
                                                                        <div style={{width: "100%", height: "100%"}}>
                                                                            <div
                                                                                ref={(el) => domesticRefs.current[index] = el}
                                                                                style={{
                                                                                    height: "100%",
                                                                                    width: "100%",
                                                                                    position: "relative"
                                                                                }}>
                                                                                <button className="selectParentBt"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                            height: "100%"
                                                                                        }}
                                                                                        onClick={() => toggleDomesticShow(index)}>
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        color: "dimgray"
                                                                                    }}>{delivery.domShipco === '' ? "국내배송사" : delivery.domShipco}</p>
                                                                                    <span style={{
                                                                                        position: "absolute",
                                                                                        left: "80%",
                                                                                        top: "0",
                                                                                        height: "100%",
                                                                                        width: "20%"
                                                                                    }}>

                                                                <i style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    height: "100%",
                                                                    color: "darkgray"
                                                                }}><BiCaretDown/></i>

                                                            </span>
                                                                                </button>
                                                                                {domesticShow[index] ?
                                                                                    <div style={{
                                                                                        position: "absolute",
                                                                                        top: "55px",
                                                                                        width: "100%",
                                                                                        border: "solid",
                                                                                        borderWidth: "thin 1px",
                                                                                        borderColor: "lightgray",
                                                                                        zIndex: 2,
                                                                                    }}>
                                                                                        {domesticDeliveryChoice.map((domestic, i) => (
                                                                                            <div
                                                                                                className="selectChildBt"
                                                                                                key={i} value={domestic}
                                                                                                onClick={() => handleDomesticChange(index, domestic)}
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    padding: "10px",
                                                                                                    justifyContent: "center",
                                                                                                    alignItems: "center",
                                                                                                    cursor: "pointer",
                                                                                                    fontSize: "14px",
                                                                                                }}>{domestic}</div>
                                                                                        ))}
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <input type="text" className="orderFormInput"
                                                                           value={delivery.inWaynum || ''}
                                                                           onChange={(e) => deliveryWaynumNumberInput(index, 'inWaynum', e.target.value)}
                                                                           maxLength={22}
                                                                           style={{
                                                                               width: "65%",
                                                                               height: "50px",
                                                                               borderRadius: "4px",
                                                                               padding: "0 6px 0 6px",
                                                                               overflow: "auto",
                                                                               marginLeft: "5px"
                                                                           }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div style={{display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>물품명
                                                                </div>
                                                                <div style={{
                                                                    padding: "5px 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={delivery.name || ''}
                                                                           onChange={(e) => handleDeliveryProductInput(index, 'name', e.target.value)}
                                                                           maxLength={200}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (deliveryItemsRefs.current[index]) {
                                                                            deliveryItemsRefs.current[index].name = ref;
                                                                        }
                                                                    }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/*배송대행옵션*/}
                                                            <div style={{display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>옵션
                                                                </div>
                                                                <div style={{
                                                                    padding: "5px 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={delivery.option || ''}
                                                                           onChange={(e) => handleDeliveryProductInput(index, 'option', e.target.value)}
                                                                           maxLength={200}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div style={{display: "flex"}}>
                                                                <div style={{
                                                                    color: "dimgrey",
                                                                    width: "25%",
                                                                    backgroundColor: "#FAFAFA",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "20px",
                                                                    fontSize: "15px"
                                                                }}>총 수량
                                                                </div>
                                                                <div style={{
                                                                    padding: "5px 10px 10px 10px",
                                                                    margin: "0",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: "75%"
                                                                }}>
                                                                    <input type="text" className="orderFormInput"
                                                                           value={delivery.quantity || ''}
                                                                           onChange={(e) => deliveryQuantityNumberInput(index, 'quantity', e.target.value)}
                                                                           maxLength={5}
                                                                           style={{
                                                                               width: "100%",
                                                                               height: "40px",
                                                                               borderRadius: "4px",
                                                                               borderWidth: "thin 1px",
                                                                               padding: "0 6px 0 6px"
                                                                           }} ref={(ref) => {
                                                                        if (deliveryItemsRefs.current[index]) {
                                                                            deliveryItemsRefs.current[index].quantity = ref;
                                                                        }
                                                                    }}
                                                                    />
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {newDeliveryBtShow && deliveryAccordion && deliveryProductHandle.length === 0 ?
                                        <div style={{
                                            height: "100px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <button className="OrderPgBt2" style={{width: "80px", height: "40px"}}
                                                    onClick={newDeliveryForm}>물품추가
                                            </button>
                                        </div>
                                        :
                                        null
                                    }
                                    <div>
                                        <h5 style={{color: "dimgrey", marginTop: "80px"}}>배송대행 인보이스</h5>
                                    </div>
                                    <div className={`accordion-header ${invoiceAccordion ? 'expanded' : ''}`}
                                         onClick={invoiceAccordionFn}>
                                        <i className="accordion-icon"></i>
                                        <span>인보이스 Form</span>
                                    </div>
                                    <div className={`accordion-body ${invoiceAccordion ? 'expanded' : ''}`}>
                                        <div style={{height: "auto", display: "flex", color: "dimgrey"}}>

                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>인보이스첨부
                                            </div>

                                            <div style={{width: "auto"}}>
                                                <div className="fileUploadFrame" style={{
                                                    marginLeft: "20px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    height: "80px"
                                                }}>
                                                    <input className="fileUploadName" value={fileName} style={{
                                                        height: "50%",
                                                        borderWidth: "thin 1px",
                                                        borderStyle: "solid",
                                                        borderRadius: "3px",
                                                        color: "dimgrey",
                                                        borderColor: "#EAEAEA",
                                                        padding: "0 10px",
                                                        fontSize: "13px"
                                                    }}
                                                           onClick={() => document.getElementById('deliveryInvoice').click()}
                                                           readOnly/>
                                                    <label htmlFor="deliveryInvoice" style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: "80px",
                                                        height: "50%",
                                                        borderStyle: "solid",
                                                        borderWidth: "1px",
                                                        borderColor: "#EAEAEA",
                                                        borderRadius: "4px",
                                                        color: "darkgray",
                                                        fontSize: "14px"
                                                    }}>파일선택</label>
                                                    <input type="file" id="deliveryInvoice" onChange={photoChangeHandler}/>
                                                    {fileUrl ?
                                                        <div style={{marginLeft: "5px"}}>
                                                            <img onClick={invoiceDownload} style={{
                                                                width: "25px",
                                                                height: "25px",
                                                                opacity: "0.8",
                                                                cursor: "pointer"
                                                            }} alt="download" src={commonDown}/>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div>
                                        <h5 style={{color: "dimgrey", marginTop: "120px"}}>요청사항 설정</h5>
                                    </div>
                                    <div className={`accordion-header ${requestConfigAccordion ? 'expanded' : ''}`}
                                         onClick={requestConfigAccordionFn}>
                                        <i className="accordion-icon"></i>
                                        <span>요청내용 Form</span>
                                    </div>
                                    <div className={`accordion-body ${requestConfigAccordion ? 'expanded' : ''}`}>
                                        <div style={{height: "auto", display: "flex", color: "dimgrey"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>옵션선택
                                            </div>

                                            <div style={{width: "75%", marginLeft: "20px"}}>
                                                <div style={{
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "17px",
                                                    marginTop: "10px"
                                                }}>
                                                    <input id="tagRemove" type="checkbox" checked={tagRemove}
                                                           onChange={tagRemoveCheck}
                                                           style={{
                                                               width: "19px",
                                                               height: "19px",
                                                               marginRight: "10px",
                                                               cursor: "pointer"
                                                           }}/>
                                                    <label htmlFor="tagRemove" style={{cursor: "pointer"}}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "16px",
                                                            fontWeight: "lighter",
                                                            color: "#7B7B7B"
                                                        }}>텍제거</p>
                                                    </label>
                                                </div>

                                                {tagRemove ?
                                                    <div className="JoinErrorMsg">
                                                        <p className="JoinErrMsg">텍제거 시 일체 교환/반품/환불이 불가합니다</p>
                                                    </div>
                                                    :
                                                    null
                                                }

                                                <div style={{
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "17px",
                                                    cursor: "pointer"
                                                }}>
                                                    <input id="shoesBoxRemove" type="checkbox" checked={shoesBoxRemove}
                                                           onChange={shoesBoxRemoveCheck}
                                                           style={{
                                                               width: "19px",
                                                               height: "19px",
                                                               marginRight: "10px",
                                                               cursor: "pointer"
                                                           }}/>
                                                    <label htmlFor="shoesBoxRemove" style={{cursor: "pointer"}}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "16px",
                                                            fontWeight: "lighter",
                                                            color: "#7B7B7B"
                                                        }}>신발박스제거</p>
                                                    </label>
                                                </div>
                                                <div style={{
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "17px",
                                                    cursor: "pointer"
                                                }}>
                                                    <input id="hangerRemove" type="checkbox" checked={hangerRemove}
                                                           onChange={hangerRemoveCheck}
                                                           style={{
                                                               width: "19px",
                                                               height: "19px",
                                                               marginRight: "10px",
                                                               cursor: "pointer"
                                                           }}/>
                                                    <label htmlFor="hangerRemove" style={{cursor: "pointer"}}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "16px",
                                                            fontWeight: "lighter",
                                                            color: "#7B7B7B"
                                                        }}>옷걸이제거</p>
                                                    </label>
                                                </div>
                                                <div style={{
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "17px",
                                                    cursor: "pointer"
                                                }}>
                                                    <input id="compressionPack" type="checkbox"
                                                           checked={compressionPack}
                                                           onChange={compressionCheck}
                                                           style={{
                                                               width: "19px",
                                                               height: "19px",
                                                               marginRight: "10px",
                                                               cursor: "pointer"
                                                           }}/>
                                                    <label htmlFor="compressionPack" style={{cursor: "pointer"}}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "16px",
                                                            fontWeight: "lighter",
                                                            color: "#7B7B7B"
                                                        }}>압축팩서비스</p>
                                                    </label>
                                                </div>

                                                {compressionPack ?
                                                    <div className="JoinErrorMsg">
                                                        <p className="JoinErrMsg">압축팩(중) + 10,000원 | 압축팩(대) +
                                                            20,000원</p>
                                                    </div>
                                                    :
                                                    null
                                                }

                                                <div style={{
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "17px",
                                                    cursor: "pointer"
                                                }}>
                                                    <input id="productBoxRemove" type="checkbox"
                                                           checked={productBoxRemove}
                                                           onChange={prodBoxRemoveCheck}
                                                           style={{
                                                               width: "19px",
                                                               height: "19px",
                                                               marginRight: "10px",
                                                               cursor: "pointer"
                                                           }}/>
                                                    <label htmlFor="productBoxRemove" style={{cursor: "pointer"}}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "16px",
                                                            fontWeight: "lighter",
                                                            color: "#7B7B7B"
                                                        }}>부피 큰 제품박스만 제거</p>
                                                    </label>
                                                </div>

                                                {productBoxRemove ?
                                                    <div className="JoinErrorMsg">
                                                        <p className="JoinErrMsg" style={{color:"#FF8339"}}>제거 후 파손 시 일체 배상이 불가합니다</p>
                                                    </div>
                                                    :
                                                    null
                                                }

                                                <div style={{
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "17px",
                                                    cursor: "pointer"
                                                }}>
                                                    <input id="packingHold" type="checkbox" checked={packingHold}
                                                           onChange={packingHoldCheck}
                                                           style={{
                                                               width: "19px",
                                                               height: "19px",
                                                               marginRight: "10px",
                                                               cursor: "pointer"
                                                           }}/>
                                                    <label htmlFor="packingHold" style={{cursor: "pointer"}}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "16px",
                                                            fontWeight: "lighter",
                                                            color: "#7B7B7B"
                                                        }}>요청 시까지 패킹 보류</p>
                                                    </label>
                                                </div>
                                                {packingHold ?
                                                    <div className="JoinErrorMsg">
                                                        <p className="JoinErrMsg">마지막 주문 후 체크를 해제해주셔야 포장이 재개됩니다</p>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                <div style={{
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "17px",
                                                    cursor: "pointer"
                                                }}>
                                                    <input id="deliveryDepositPay" type="checkbox"
                                                           checked={deliveryDepositPay}
                                                           onChange={deliDepositPayCheck}
                                                           style={{
                                                               width: "19px",
                                                               height: "19px",
                                                               marginRight: "10px",
                                                               cursor: "pointer"
                                                           }}/>
                                                    <label htmlFor="deliveryDepositPay" style={{cursor: "pointer"}}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "16px",
                                                            fontWeight: "lighter",
                                                            color: "#7B7B7B"
                                                        }}>해외배송료 자동 예치금결제</p>
                                                    </label>
                                                </div>
                                                {deliveryDepositPay ?
                                                    <div className="JoinErrorMsg">
                                                        <p className="JoinErrMsg" style={{color:"#FF8339"}}>예치금 잔액이 부족하면 진행되지 않습니다</p>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                <div style={{
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "17px",
                                                    cursor: "pointer"
                                                }}>
                                                    <input id="deliveryMessengerGuide" type="checkbox"
                                                           checked={deliveryMessengerGuide}
                                                           onChange={deliMsgGuideCheck}
                                                           style={{
                                                               width: "19px",
                                                               height: "19px",
                                                               marginRight: "10px",
                                                               cursor: "pointer"
                                                           }}/>
                                                    <label htmlFor="deliveryMessengerGuide" style={{cursor: "pointer"}}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "16px",
                                                            fontWeight: "lighter",
                                                            color: "#7B7B7B"
                                                        }}>해외배송비 메신저안내</p>
                                                    </label>
                                                </div>
                                                {deliveryMessengerGuide ?
                                                    <div className="JoinErrorMsg">
                                                        <p className="JoinErrMsg" style={{color:"#FF8339"}}>작성 후 주문번호와 함께 해당 메신저로 요청해주셔야 진행됩니다</p>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div style={{height: "70px", display: "flex", color: "dimgrey"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>상세요청
                                            </div>
                                            <div style={{width: "75%", margin: "12px 10px 0 15px"}}>
                                                <input type="text" className="orderFormInput" maxLength={200}
                                                       value={detailRequest}
                                                       onChange={detailRequestInput}
                                                       style={{
                                                           width: "100%",
                                                           height: "45px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*여기서부터 스크롤 시 따라다니는 결제정보 스티키*/}
                                <div style={{
                                    width: "400px",
                                    paddingLeft: "20px",
                                    marginTop: "78px",
                                    height: "2220px",
                                    display: "flex"
                                }}
                                     id="orderPaySticky">
                                    <div style={{
                                        borderStyle: "solid",
                                        height: "auto",
                                        borderRadius: "8px",
                                        padding: "0 20px 0 20px",
                                        borderWidth: "thin 1px",
                                        borderColor: "#EAEAEA"
                                    }}>
                                        <div style={{
                                            height: "60px",
                                            fontSize: "22px",
                                            fontWeight: "bold",
                                            display: "flex",
                                            alignItems: "center"
                                        }}>결제정보
                                        </div>
                                        <div style={{
                                            height: "50px",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "18px",
                                            borderBottom: "solid",
                                            color: "rgba(52,52,50,0.82)",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            fontWeight: "bolder",
                                            margin: "20px 0 10px 0"
                                        }}>상품
                                        </div>
                                        <div style={{
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "gray",
                                            justifyContent: "space-between"
                                        }}>
                                            <div>상품수량</div>
                                            <div>{finalQuantity}개</div>
                                        </div>
                                        <div style={{
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "gray",
                                            justifyContent: "space-between"
                                        }}>
                                            <div>상품금액</div>
                                            <div>{finalProdExpense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</div>
                                        </div>
                                        <div style={{
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "gray",
                                            justifyContent: "space-between"
                                        }}>
                                            <div>쇼핑몰배송비</div>
                                            <div>{finalMallShipPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</div>
                                        </div>
                                        <div style={{
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "gray",
                                            justifyContent: "space-between"
                                        }}>
                                            <div>수수료</div>
                                            <div>{finalFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</div>
                                        </div>
                                        <div style={{
                                            height: "50px",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "17px",
                                            borderBottom: "solid",
                                            color: "rgba(52,52,50,0.82)",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            fontWeight: "bolder",
                                            margin: "20px 0 10px 0"
                                        }}>해외배송비
                                        </div>
                                        <div style={{
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "gray",
                                            justifyContent: "space-between"
                                        }}>
                                            <div>총 해외배송비</div>
                                            <div>{finalOverseasShipExpense}원</div>
                                        </div>
                                        <div style={{
                                            height: "50px",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "17px",
                                            borderBottom: "solid",
                                            color: "rgba(52,52,50,0.82)",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            fontWeight: "bolder",
                                            margin: "20px 0 10px 0"
                                        }}>기타
                                        </div>
                                        <div style={{
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "gray",
                                            justifyContent: "space-between"
                                        }}>
                                            <div>총 기타비용</div>
                                            <div>{finalEtcExpense.toLocaleString('en-US')}원</div>
                                        </div>
                                        <div style={{
                                            height: "50px",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "17px",
                                            borderBottom: "solid",
                                            color: "rgba(52,52,50,0.82)",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            fontWeight: "bolder",
                                            margin: "20px 0 10px 0"
                                        }}>쿠폰&적립금 사용
                                        </div>
                                        <div style={{
                                            width: "380px",
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "gray",
                                            justifyContent: "space-between"
                                        }}>
                                            <div>적립금(<span
                                                style={{color: "orange"}}>{memberInfo && memberInfo.point > 0 ? (dbPoint ? dbPoint.toLocaleString('en-US') : 0) :
                                                <span style={{color: "dimgrey"}}>0</span>}</span>)
                                            </div>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <input type="text" className="usePointInput" style={{
                                                    width: "100px",
                                                    height: "35px",
                                                    borderRadius: "4px",
                                                    borderWidth: "thin 1px",
                                                    marginLeft: "60px",
                                                    padding: "0 6px 0 6px"
                                                }} value={pointSale.toLocaleString('en-US')}
                                                       onChange={(e)=> handlePointSale(e.target.value)}/>
                                                <button className="ReviewPgWriteBtn" style={{
                                                    width: "70px",
                                                    height: "35px",
                                                    fontSize: "12px",
                                                    fontWeight: "bold",
                                                    borderWidth: "thin 1px",
                                                    borderRadius: "4px",
                                                    marginLeft: "5px"
                                                }} onClick={allPointSale}>전액사용
                                                    <div className="dot"></div>
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "gray",
                                            justifyContent: "space-between"
                                        }}>
                                            <div>예치금(<span
                                                style={{color: "orange"}}>{memberInfo && memberInfo.deposit > 0 ? (dbDeposit ? dbDeposit.toLocaleString('en-US') : 0) :
                                                <span style={{color: "dimgrey"}}>0</span>}</span>)
                                            </div>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <input type="text" className="usePointInput" style={{
                                                    width: "100px",
                                                    height: "35px",
                                                    borderRadius: "4px",
                                                    borderWidth: "thin 1px",
                                                    marginLeft: "60px",
                                                    padding: "0 6px 0 6px"
                                                }} value={depositSale.toLocaleString('en-US')}
                                                       onChange={(e)=> handleDepositSale(e.target.value)}/>
                                                <button className="ReviewPgWriteBtn" style={{
                                                    width: "70px",
                                                    height: "35px",
                                                    fontSize: "12px",
                                                    fontWeight: "bold",
                                                    borderWidth: "thin 1px",
                                                    borderRadius: "4px",
                                                    marginLeft: "5px"
                                                }} onClick={allDepositSale}>전액사용
                                                    <div className="dot"></div>
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "gray",
                                            justifyContent: "space-between"
                                        }}>
                                            <div>쿠폰(<span
                                                style={{color: "orange"}}>{dbCoupon}</span>)
                                            </div>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <input type="text" className="usePointInput" style={{
                                                    width: "100px",
                                                    height: "35px",
                                                    borderRadius: "4px",
                                                    borderWidth: "thin 1px",
                                                    marginLeft: "60px",
                                                    padding: "0 6px 0 6px"
                                                }} value={couponSale.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                       onChange={(e)=> handleDepositSale(e.target.value)}/>
                                                {couponSale === '' ?
                                                    <button className="ReviewPgWriteBtn" style={{
                                                        width: "70px",
                                                        height: "35px",
                                                        fontSize: "12px",
                                                        fontWeight: "bold",
                                                        borderWidth: "thin 1px",
                                                        borderRadius: "4px",
                                                        marginLeft: "5px"
                                                    }} onClick={showUseCoupon}>쿠폰적용
                                                        <div className="dot"></div>
                                                    </button>
                                                    :
                                                    <button className="ReviewPgWriteBtn" style={{
                                                        width: "70px",
                                                        height: "35px",
                                                        fontSize: "12px",
                                                        fontWeight: "bold",
                                                        borderWidth: "thin 1px",
                                                        borderRadius: "4px",
                                                        marginLeft: "5px"
                                                    }} onClick={closeUseCoupon}>적용취소
                                                        <div className="dot"></div>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                        <div style={{
                                            height: "50px",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "19px",
                                            borderStyle: "solid",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            fontWeight: "bold",
                                            marginTop: "20px",
                                            justifyContent: "space-between"
                                        }}>
                                            <div>최종결제금액</div>
                                            <div style={{color: "darkorange"}}
                                            >{finalPayExpense} 원
                                            </div>
                                        </div>
                                        {isOrderPay ?
                                            <div>
                                                <div style={{
                                                    height: "50px",
                                                    fontSize: "20px",
                                                    fontWeight: "bold",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: "50px",
                                                    borderBottom: "solid",
                                                    borderWidth: "thin 1px",
                                                    borderColor: "lightgray"
                                                }}>결제수단
                                                </div>
                                                <div style={{height: "auto"}}>
                                                    <div style={{width: "100%", marginTop: "20px"}}>

                                                        {/*비토리지페이 row*/}
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <label
                                                                className={payWay === "PM06" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                                onClick={() => handlePayWayChange("PM06")}>
                                                                <input className="radioButton" type="radio"
                                                                       name="paymentMethod"
                                                                       value="PM06"
                                                                       style={{borderStyle: "solid", display: "none"}}/>
                                                                <div style={{
                                                                    height: "70%",
                                                                    display: "flex",
                                                                    overflow: "hidden",
                                                                    justifyContent: "center",
                                                                    padding: "16px"
                                                                }}><img alt="" src={btoragePay}/></div>
                                                                <div style={{
                                                                    height: "30%",
                                                                    textAlign: "center",
                                                                    fontSize: "15px"
                                                                }}>
                                                                    <p style={{
                                                                        fontSize: "13px",
                                                                        color: "black"
                                                                    }}>BtoragePay</p>
                                                                </div>
                                                            </label>
                                                            <label
                                                                className={payWay === "PM07" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                                onClick={() => handlePayWayChange("PM07")}>
                                                                <input className="radioButton" type="radio"
                                                                       name="paymentMethod"
                                                                       value="PM07"
                                                                       style={{borderStyle: "solid", display: "none"}}/>
                                                                <div style={{
                                                                    height: "70%",
                                                                    display: "flex",
                                                                    overflow: "hidden",
                                                                    justifyContent: "center",
                                                                    padding: "16px"
                                                                }}><img alt="" src={creditCard}/></div>
                                                                <div style={{
                                                                    height: "30%",
                                                                    textAlign: "center",
                                                                    fontSize: "15px"
                                                                }}>
                                                                    <p style={{
                                                                        fontSize: "13px",
                                                                        color: "black"
                                                                    }}>CreditCard</p>
                                                                </div>
                                                            </label>
                                                            <label
                                                                className={payWay === "PM08" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                                onClick={() => handlePayWayChange("PM08")}>
                                                                <input className="radioButton" type="radio"
                                                                       name="paymentMethod"
                                                                       value="PM08"
                                                                       style={{borderStyle: "solid", display: "none"}}/>
                                                                <div style={{
                                                                    height: "70%",
                                                                    display: "flex",
                                                                    overflow: "hidden",
                                                                    justifyContent: "center",
                                                                    alignItems: "flex-end",
                                                                    padding: "16px"
                                                                }}><img style={{width: "60px", height: "30px"}} alt=""
                                                                        src={stripe}/></div>
                                                                <div style={{
                                                                    height: "30%",
                                                                    textAlign: "center",
                                                                    fontSize: "15px"
                                                                }}>
                                                                    <p style={{
                                                                        fontSize: "13px",
                                                                        color: "black"
                                                                    }}>Stripe</p>
                                                                </div>
                                                            </label>
                                                        </div>

                                                        {/*페이팔 row*/}
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <label
                                                                className={payWay === "PM01" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                                onClick={() => handlePayWayChange("PM01")}>
                                                                <input className="radioButton" type="radio"
                                                                       name="paymentMethod"
                                                                       value="PM01"
                                                                       style={{borderStyle: "solid", display: "none"}}/>
                                                                <div style={{
                                                                    height: "70%",
                                                                    display: "flex",
                                                                    overflow: "hidden",
                                                                    justifyContent: "center",
                                                                    padding: "16px"
                                                                }}><img alt="" src={PaypalLogo}/></div>
                                                                <div style={{
                                                                    height: "30%",
                                                                    textAlign: "center",
                                                                    fontSize: "15px"
                                                                }}>
                                                                    <p style={{
                                                                        fontSize: "13px",
                                                                        color: "black"
                                                                    }}>Paypal</p>
                                                                </div>
                                                            </label>
                                                            <label
                                                                className={payWay === "PM02" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                                onClick={() => handlePayWayChange("PM02")}>
                                                                <input className="radioButton" type="radio"
                                                                       name="paymentMethod"
                                                                       value="PM02"
                                                                       style={{borderStyle: "solid", display: "none"}}/>
                                                                <div style={{
                                                                    height: "70%",
                                                                    display: "flex",
                                                                    overflow: "hidden",
                                                                    justifyContent: "center",
                                                                    padding: "16px"
                                                                }}><img alt="" src={WechatpayLogo}/></div>
                                                                <div style={{
                                                                    height: "30%",
                                                                    textAlign: "center",
                                                                    fontSize: "15px"
                                                                }}>
                                                                    <p style={{
                                                                        fontSize: "13px",
                                                                        color: "black"
                                                                    }}>WechatPay</p>
                                                                </div>
                                                            </label>
                                                            <label
                                                                className={payWay === "PM03" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                                onClick={() => handlePayWayChange("PM03")}>
                                                                <input className="radioButton" type="radio"
                                                                       name="paymentMethod"
                                                                       value="PM03"
                                                                       style={{borderStyle: "solid", display: "none"}}/>
                                                                <div style={{
                                                                    height: "70%",
                                                                    display: "flex",
                                                                    overflow: "hidden",
                                                                    justifyContent: "center",
                                                                    padding: "16px"
                                                                }}><img alt="" src={AlipayLogo}/></div>
                                                                <div style={{
                                                                    height: "30%",
                                                                    textAlign: "center",
                                                                    fontSize: "15px"
                                                                }}>
                                                                    <p style={{
                                                                        fontSize: "13px",
                                                                        color: "black"
                                                                    }}>AliPay</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        {/*무통장 row*/}
                                                        <div style={{width: "100%"}}>
                                                            <div style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "space-between"
                                                            }}>
                                                                <label
                                                                    className={payWay === "PM00" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                                    onClick={() => handlePayWayChange("PM00")}>
                                                                    <input className="radioButton" type="radio"
                                                                           name="paymentMethod"
                                                                           value="PM00"
                                                                           style={{
                                                                               borderStyle: "solid",
                                                                               display: "none"
                                                                           }}/>
                                                                    <div style={{
                                                                        height: "70%",
                                                                        display: "flex",
                                                                        overflow: "hidden",
                                                                        justifyContent: "center",
                                                                        padding: "18px"
                                                                    }}>
                                                                        <img alt="" src={NoBank}/>
                                                                    </div>
                                                                    <div style={{
                                                                        height: "30%",
                                                                        textAlign: "center",
                                                                        fontSize: "15px"
                                                                    }}>
                                                                        <p style={{
                                                                            fontSize: "13px",
                                                                            color: "black"
                                                                        }}>{t(`modNoBank`)}</p>
                                                                    </div>
                                                                </label>
                                                                <label
                                                                    className={payWay === "PM05" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                                    onClick={() => handlePayWayChange("PM05")}>
                                                                    <input className="radioButton" type="radio"
                                                                           name="paymentMethod"
                                                                           value="PM05"
                                                                           style={{
                                                                               borderStyle: "solid",
                                                                               display: "none"
                                                                           }}/>
                                                                    <div style={{
                                                                        height: "70%",
                                                                        display: "flex",
                                                                        overflow: "hidden",
                                                                        justifyContent: "center",
                                                                        padding: "16px"
                                                                    }}>
                                                                        <img alt="" src={DepositPay}/>
                                                                    </div>
                                                                    <div style={{
                                                                        height: "30%",
                                                                        textAlign: "center",
                                                                        fontSize: "15px"
                                                                    }}>
                                                                        <p style={{
                                                                            fontSize: "13px",
                                                                            color: "black"
                                                                        }}>{t(`modDep`)}</p>
                                                                    </div>
                                                                </label>
                                                                <label
                                                                    className={payWay === "PM04" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                                    onClick={() => handlePayWayChange("PM04")}>
                                                                    <input className="radioButton" type="radio"
                                                                           name="paymentMethod"
                                                                           value="PM04"
                                                                           style={{
                                                                               borderStyle: "solid",
                                                                               display: "none"
                                                                           }}/>
                                                                    <div style={{
                                                                        height: "70%",
                                                                        display: "flex",
                                                                        overflow: "hidden",
                                                                        justifyContent: "center",
                                                                        padding: "16px"
                                                                    }}>
                                                                        <img alt="" src={PointPay}/>
                                                                    </div>
                                                                    <div style={{
                                                                        height: "30%",
                                                                        textAlign: "center",
                                                                        fontSize: "15px"
                                                                    }}>
                                                                        <p style={{
                                                                            fontSize: "13px",
                                                                            color: "black"
                                                                        }}>{t(`modPt`)}</p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {payWay === "PM01" ?
                                                        <div style={{
                                                            fontSize: "19px",
                                                            marginTop: "20px",
                                                            paddingLeft: "5px",
                                                            paddingRight: "5px"
                                                        }}>
                                                            <div style={{
                                                                width: "100%",
                                                                height: "50px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                border: "solid",
                                                                borderLeft: "none",
                                                                borderRight: "none",
                                                                borderColor: "lightgray",
                                                                borderWidth: "thin 1px",
                                                                fontWeight: "bold",
                                                                justifyContent: "space-between"
                                                            }}>
                                                                <p style={{
                                                                    margin: 0,
                                                                    fontSize: "18px",
                                                                    color: "black",
                                                                    fontWeight: "bold"
                                                                }}>Exchange Rate</p>
                                                                <div className="user-center"
                                                                     style={{color: "darkorange"}}>{lastForPrice}

                                                                    <div ref={dollarRef} style={{
                                                                        height: "30px",
                                                                        width: "50px",
                                                                        position: "relative",
                                                                        marginLeft: "10px"
                                                                    }}>
                                                                        <button className="selectParentBt"
                                                                                style={{width: "100%", height: "100%"}}
                                                                                onClick={toggleDollarShow}>
                                                                            <p style={{
                                                                                margin: "0",
                                                                                fontSize: "12px"
                                                                            }}>{currency}</p>
                                                                        </button>
                                                                        {dollarShow ?
                                                                            <div style={{
                                                                                width: "100%",
                                                                                border: "solid",
                                                                                borderWidth: "thin 1px",
                                                                                borderColor: "lightgray",
                                                                                zIndex: 1,
                                                                                marginTop: "5px",
                                                                                overflowY: "auto",
                                                                            }}>
                                                                                {dollarList.map((dollar, index) => (
                                                                                    <div className="selectChildBt"
                                                                                         key={index}
                                                                                         onClick={() => selectChangeDollar(dollar)}
                                                                                         style={{
                                                                                             display: "flex",
                                                                                             padding: "10px",
                                                                                             justifyContent: "center",
                                                                                             alignItems: "center",
                                                                                             cursor: "pointer",
                                                                                             fontSize: "12px",
                                                                                             zIndex: 99,
                                                                                         }}>{dollar}</div>
                                                                                ))}
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        payWay === "PM03" ?
                                                            <div>
                                                                <div style={{
                                                                    fontSize: "19px",
                                                                    marginTop: "15px",
                                                                    paddingLeft: "5px",
                                                                    paddingRight: "5px",
                                                                    marginBottom: "30px",
                                                                    width: "100%",
                                                                }}>
                                                                    <div style={{
                                                                        height: "50px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        border: "solid",
                                                                        borderLeft: "none",
                                                                        borderRight: "none",
                                                                        borderColor: "lightgray",
                                                                        borderWidth: "thin 1px",
                                                                        fontWeight: "bold",
                                                                        justifyContent: "space-between"
                                                                    }}>
                                                                        <p style={{
                                                                            margin: 0,
                                                                            fontSize: "16px",
                                                                            color: "black",
                                                                            fontWeight: "bold"
                                                                        }}>Exchange Rate</p>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignItems: "center"
                                                                        }}>
                                                                            <p style={{
                                                                                margin: "0",
                                                                                fontSize: "17px",
                                                                                fontWeight: "bold",
                                                                                color: "darkorange"
                                                                            }}>{lastForPrice}</p>
                                                                            <span>
                                                                <div className="user-center"
                                                                     style={{margin: "2px 0 0 5px"}}>
                                                                    <p style={{
                                                                        margin: "0",
                                                                        fontSize: "13px",
                                                                        fontWeight: "lighter"
                                                                    }}>{currency}</p>
                                                                </div>
                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{
                                                                    borderStyle: "solid",
                                                                    borderWidth: "thin 1px",
                                                                    borderColor: "#EAEAEA",
                                                                    height: "50%",
                                                                    marginTop: "15px",
                                                                    borderRadius: "8px",
                                                                    color: "dimgrey"
                                                                }}>
                                                                    <div style={{height: "40%"}}>
                                                                        <div style={{
                                                                            height: "33%",
                                                                            padding: "20px 0 5px 25px"
                                                                        }}>- 입금계좌 :
                                                                            13165072023
                                                                        </div>
                                                                        <div style={{
                                                                            height: "33%",
                                                                            padding: "0 0 15px 25px"
                                                                        }}>- 예금주 :
                                                                            IMEUNWOO
                                                                        </div>

                                                                    </div>
                                                                    <div style={{height: "60%", paddingBottom: "15px"}}>
                                                                        <div style={{
                                                                            height: "180px",
                                                                            width: "50%",
                                                                            display: "flex",
                                                                            overflow: "hidden",
                                                                            justifyContent: "center",
                                                                            margin: "auto"
                                                                        }}><img alt="" src={AliQrCode}/></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            payWay === "PM02" ?
                                                                <div>

                                                                    <div style={{
                                                                        fontSize: "19px",
                                                                        marginTop: "15px",
                                                                        paddingLeft: "5px",
                                                                        paddingRight: "5px",
                                                                        marginBottom: "30px",
                                                                        width: "100%",
                                                                    }}>
                                                                        <div style={{
                                                                            height: "50px",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            border: "solid",
                                                                            borderLeft: "none",
                                                                            borderRight: "none",
                                                                            borderColor: "lightgray",
                                                                            borderWidth: "thin 1px",
                                                                            fontWeight: "bold",
                                                                            justifyContent: "space-between"
                                                                        }}>
                                                                            <p style={{
                                                                                margin: 0,
                                                                                fontSize: "16px",
                                                                                color: "black",
                                                                                fontWeight: "bold"
                                                                            }}>Exchange Rate</p>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <p style={{
                                                                                    margin: "0",
                                                                                    fontSize: "17px",
                                                                                    fontWeight: "bold",
                                                                                    color: "darkorange"
                                                                                }}>{lastForPrice}</p>
                                                                                <span>
                                                                <div className="user-center"
                                                                     style={{margin: "2px 0 0 5px"}}>
                                                                    <p style={{
                                                                        margin: "0",
                                                                        fontSize: "13px",
                                                                        fontWeight: "lighter"
                                                                    }}>{currency}</p>
                                                                </div>
                                                            </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div style={{
                                                                        borderStyle: "solid",
                                                                        borderWidth: "thin 1px",
                                                                        borderColor: "#EAEAEA",
                                                                        height: "50%",
                                                                        marginTop: "15px",
                                                                        borderRadius: "8px",
                                                                        color: "dimgrey"
                                                                    }}>
                                                                        <div style={{height: "40%"}}>
                                                                            <div style={{
                                                                                height: "33%",
                                                                                padding: "20px 0 5px 25px"
                                                                            }}>- Weixin ID
                                                                                :
                                                                                lfamily-help
                                                                            </div>
                                                                            <div style={{
                                                                                height: "33%",
                                                                                padding: "0 0 15px 25px"
                                                                            }}>- 예금주 :
                                                                                IMEUNWOO
                                                                            </div>

                                                                        </div>
                                                                        <div style={{
                                                                            height: "60%",
                                                                            paddingBottom: "10px"
                                                                        }}>
                                                                            <div style={{
                                                                                height: "200px",
                                                                                width: "50%",
                                                                                display: "flex",
                                                                                overflow: "hidden",
                                                                                justifyContent: "center",
                                                                                margin: "auto"
                                                                            }}><img alt="" src={wechatQrCode}/></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                payWay === "PM00" ?
                                                                    <div style={{
                                                                        borderStyle: "solid",
                                                                        borderWidth: "thin 1px",
                                                                        borderColor: "#EAEAEA",
                                                                        height: "50%",
                                                                        marginTop: "15px",
                                                                        borderRadius: "8px"
                                                                    }}>
                                                                        <div style={{
                                                                            height: "auto",
                                                                            margin: "20px 11px 20px 11px",
                                                                            color: "dimgrey"
                                                                        }}>
                                                                            <h6>● 무통장 입금정보</h6>
                                                                            <div>- 신한 140-014-047039 비토리지 주식회사</div>
                                                                            <h6 style={{marginTop: "20px"}}>●
                                                                                해외송금정보</h6>
                                                                            <div>- name : Btorage corp</div>
                                                                            <div>- account info : 140-014-047039
                                                                                shinhanBank
                                                                            </div>
                                                                            <div>- tell : 82-10-3338-9354</div>
                                                                            <div>- mail : lfamily11@naver.com</div>
                                                                            <div>- address : 1F, 32-15, Yeongok-gil
                                                                                42beon-gil, Gonjiam-eup,
                                                                                Gwangju-si, Gyeonggi-do
                                                                            </div>
                                                                            <div>- zipCode : 12722</div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                    }
                                                </div>

                                                {payMethodError &&
                                                    <p className="JoinErrMsg">&nbsp;결제수단을 선택해주세요</p>
                                                }

                                                {pointPayError &&
                                                    <p className="JoinErrMsg">&nbsp;적립금or예치금결제로 선택해주세요</p>
                                                }

                                                {depositSelectErr &&
                                                    <p className="JoinErrMsg">&nbsp;결제수단을 적립금결제로 선택해주세요</p>
                                                }

                                                {pointSelectErr &&
                                                    <p className="JoinErrMsg">&nbsp;결제수단을 예치금결제로 선택해주세요</p>
                                                }

                                                {etcPayError &&
                                                    <p className="JoinErrMsg">&nbsp;다른 결제수단을 선택해주세요</p>
                                                }

                                                {discountError &&
                                                    <p className="JoinErrMsg">&nbsp;할인금액이 최종결제금액보다 높습니다</p>
                                                }

                                            </div>
                                            :
                                            null
                                        }


                                        {
                                            (
                                                (hasBothCartAndDbCart && hasCartProduct) ||
                                                (hasBothOrderAndDbOrder && hasOrderProduct) ||
                                                (hasBothOfflineAndDbOffline && hasOfflineProduct) ||
                                                (hasBothTransferAndDbTransfer && hasTransferProduct) ||
                                                (hasBothDomesticAndDbDomestic && hasDomesticProduct) ||
                                                (hasBothDeliveryAndDbDelivery && hasDeliveryProduct)
                                            ) ?
                                                <div style={{
                                                    border: "solid",
                                                    marginTop: "30px",
                                                    minHeight: "50px",
                                                    borderWidth: "thin 1px",
                                                    borderColor: "#EAEAEA",
                                                    borderRadius: "4px",
                                                    padding: "15px 20px"
                                                }}>
                                                    {/*전체동의*/}
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        borderBottom: "solid",
                                                        borderWidth: "thin 1px",
                                                        borderColor: "#EAEAEA",
                                                        minHeight: "50px",
                                                        marginBottom: "15px"
                                                    }}>
                                                        <input id="AllAgreeCheckBox" type="checkbox" style={{
                                                            width: "18px",
                                                            height: "18px",
                                                            cursor: "pointer"
                                                        }}
                                                               checked={allAgreeCheck} onChange={allAgreeHandle}/>
                                                        <label htmlFor="AllAgreeCheckBox"><span style={{
                                                            color: "dimgray",
                                                            fontSize: "15px",
                                                            marginLeft: "10px",
                                                            cursor: "pointer"
                                                        }}>{t(`joinAgAll`)}</span></label>
                                                    </div>

                                                    {hasBothCartAndDbCart && hasCartProduct ?
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            minHeight: "30px",
                                                            marginTop: "10px"
                                                        }}>
                                                            <div style={{
                                                                width: "8%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <input id="cartOrderAgree" type="checkbox" style={{
                                                                    width: "18px",
                                                                    height: "18px",
                                                                    cursor: "pointer"
                                                                }}
                                                                       checked={cartServiceAgreeCheck}
                                                                       onChange={handleAgreeChange(setCartServiceAgreeCheck)}/>
                                                            </div>
                                                            <div style={{width: "92%"}}>
                                                                <label htmlFor="cartOrderAgree"><span style={{
                                                                    color: "#818181",
                                                                    fontSize: "15px",
                                                                    cursor: "pointer"
                                                                }}>{t(`modPiAgCart`)}</span>
                                                                    <span style={{
                                                                        color: "#FF7C48",
                                                                        fontSize: "14px",
                                                                        marginLeft: "3px"
                                                                    }}>({t(`essential`)})</span>
                                                                </label>
                                                            </div>
                                                            <span onClick={() => setCartTermsPopup(true)} style={{
                                                                cursor: "pointer",
                                                                width: "10%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "flex-end"
                                                            }}>
                                                                <img style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    opacity: "0.4"
                                                                }} src={termsView} alt="view"/>
                                                            </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {hasBothOrderAndDbOrder && hasOrderProduct ?
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            minHeight: "30px",
                                                            marginTop: "10px"
                                                        }}>
                                                            <div style={{
                                                                width: "8%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <input id="orderUseAgree" type="checkbox" style={{
                                                                    width: "18px",
                                                                    height: "18px",
                                                                    cursor: "pointer"
                                                                }}
                                                                       checked={orderServiceAgreeCheck}
                                                                       onChange={handleAgreeChange(setOrderServiceAgreeCheck)}/>
                                                            </div>
                                                            <div style={{width: "92%"}}>
                                                                <label htmlFor="orderUseAgree"><span style={{
                                                                    color: "#818181",
                                                                    fontSize: "15px",
                                                                    cursor: "pointer"
                                                                }}>{t(`modPiAgOrder`)}</span>
                                                                    <span style={{
                                                                        color: "#FF7C48",
                                                                        fontSize: "14px",
                                                                        marginLeft: "3px"
                                                                    }}>({t(`essential`)})</span>
                                                                </label>
                                                            </div>
                                                            <span onClick={() => setOrderTermsPopup(true)} style={{
                                                                cursor: "pointer",
                                                                width: "10%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "flex-end"
                                                            }}>
                                                                <img style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    opacity: "0.4"
                                                                }} src={termsView} alt="view"/>
                                                            </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {hasBothOfflineAndDbOffline && hasOfflineProduct ?
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            minHeight: "30px",
                                                            marginTop: "10px"
                                                        }}>
                                                            <div style={{
                                                                width: "8%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <input id="offlineUseAgree" type="checkbox" style={{
                                                                    width: "18px",
                                                                    height: "18px",
                                                                    cursor: "pointer"
                                                                }}
                                                                       checked={offlineServiceAgreeCheck}
                                                                       onChange={handleAgreeChange(setOfflineServiceAgreeCheck)}/>
                                                            </div>
                                                            <div style={{width: "92%"}}>
                                                                <label htmlFor="offlineUseAgree"><span style={{
                                                                    color: "#818181",
                                                                    fontSize: "15px",
                                                                    cursor: "pointer"
                                                                }}>{t(`modPiAgOff`)}</span>
                                                                    <span style={{
                                                                        color: "#FF7C48",
                                                                        fontSize: "14px",
                                                                        marginLeft: "3px"
                                                                    }}>({t(`essential`)})</span>
                                                                </label>
                                                            </div>
                                                            <span onClick={() => setOfflineTermsPopup(true)} style={{
                                                                cursor: "pointer",
                                                                width: "10%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "flex-end"
                                                            }}>
                                                                <img style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    opacity: "0.4"
                                                                }} src={termsView} alt="view"/>
                                                            </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {hasBothTransferAndDbTransfer && hasTransferProduct ?
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            minHeight: "30px",
                                                            marginTop: "10px"
                                                        }}>
                                                            <div style={{
                                                                width: "8%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <input id="transferUseAgree" type="checkbox" style={{
                                                                    width: "18px",
                                                                    height: "18px",
                                                                    cursor: "pointer"
                                                                }}
                                                                       checked={transferServiceAgreeCheck}
                                                                       onChange={handleAgreeChange(setTransferServiceAgreeCheck)}/>
                                                            </div>
                                                            <div style={{width: "92%"}}>
                                                                <label htmlFor="transferUseAgree"><span style={{
                                                                    color: "#818181",
                                                                    fontSize: "15px",
                                                                    cursor: "pointer"
                                                                }}>{t(`modPiAgTr`)}</span>
                                                                    <span style={{
                                                                        color: "#FF7C48",
                                                                        fontSize: "14px",
                                                                        marginLeft: "3px"
                                                                    }}>({t(`essential`)})</span>
                                                                </label>
                                                            </div>
                                                            <span onClick={() => setTransferTermsPopup(true)} style={{
                                                                cursor: "pointer",
                                                                width: "10%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "flex-end"
                                                            }}>
                                                                <img style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    opacity: "0.4"
                                                                }} src={termsView} alt="view"/>
                                                            </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {hasBothDomesticAndDbDomestic && hasDomesticProduct ?
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            minHeight: "30px",
                                                            marginTop: "10px"
                                                        }}>
                                                            <div style={{
                                                                width: "8%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <input id="domesticUseAgree" type="checkbox" style={{
                                                                    width: "18px",
                                                                    height: "18px",
                                                                    cursor: "pointer"
                                                                }}
                                                                       checked={domesticServiceAgreeCheck}
                                                                       onChange={handleAgreeChange(setDomesticServiceAgreeCheck)}/>
                                                            </div>
                                                            <div style={{width: "92%"}}>
                                                                <label htmlFor="domesticUseAgree"><span style={{
                                                                    color: "#818181",
                                                                    fontSize: "15px",
                                                                    cursor: "pointer"
                                                                }}>{t(`modPiAgDm`)}</span>
                                                                    <span style={{
                                                                        color: "#FF7C48",
                                                                        fontSize: "14px",
                                                                        marginLeft: "3px"
                                                                    }}>({t(`essential`)})</span>
                                                                </label>
                                                            </div>
                                                            <span onClick={() => setDomesticTermsPopup(true)} style={{
                                                                cursor: "pointer",
                                                                width: "10%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "flex-end"
                                                            }}>
                                                                <img style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    opacity: "0.4"
                                                                }} src={termsView} alt="view"/>
                                                            </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {hasBothDeliveryAndDbDelivery && hasDeliveryProduct ?
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            minHeight: "30px",
                                                            marginTop: "10px"
                                                        }}>
                                                            <div style={{
                                                                width: "8%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <input id="deliveryUseAgree" type="checkbox" style={{
                                                                    width: "18px",
                                                                    height: "18px",
                                                                    cursor: "pointer"
                                                                }}
                                                                       checked={deliveryServiceAgreeCheck}
                                                                       onChange={handleAgreeChange(setDeliveryServiceAgreeCheck)}/>
                                                            </div>
                                                            <div style={{width: "92%"}}>
                                                                <label htmlFor="deliveryUseAgree"><span style={{
                                                                    color: "#818181",
                                                                    fontSize: "15px",
                                                                    cursor: "pointer"
                                                                }}>{t(`modPiAgDv`)}</span>
                                                                    <span style={{
                                                                        color: "#FF7C48",
                                                                        fontSize: "14px",
                                                                        marginLeft: "3px"
                                                                    }}>({t(`essential`)})</span>
                                                                </label>
                                                            </div>
                                                            <span onClick={() => setDeliveryTermsPopup(true)} style={{
                                                                cursor: "pointer",
                                                                width: "10%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "flex-end"
                                                            }}>
                                                                <img style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    opacity: "0.4"
                                                                }} src={termsView} alt="view"/>
                                                            </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                :
                                                null
                                        }

                                        <div className="JoinErrorMsg" style={{marginBottom: "20px"}}>
                                            {agreeCheckBoxError &&
                                                <p className="JoinErrMsg">&nbsp;이용약관을 동의해주세요</p>}
                                        </div>

                                        <div className="bookFrameFooter" style={{display: "block"}}>
                                            <style>
                                                {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                                            </style>
                                            {/*{showPayNow &&*/}
                                            { isOrderPay &&
                                                <button className="colorFullBt user-center" style={{
                                                    width: "100%",
                                                    height: "50px",
                                                    textAlign: "center",
                                                    fontSize: "20px",
                                                }} onClick={() => handleSubmit("pay")}
                                                        disabled={isPayBtDisabled}>
                                                    {isPayBtLoading ? (
                                                        <div className="spinner"></div>
                                                    ) : (
                                                        <div>Pay</div>
                                                    )}
                                                </button>
                                            }

                                            <button className="orderDeleteBt user-center" style={{
                                                width: "100%",
                                                height: "50px",
                                                textAlign: "center",
                                                fontSize: "20px",
                                                margin: "10px 0 10px 0",
                                                borderRadius: "6px"
                                            }} onClick={() => handleSubmit("save")}
                                                    disabled={isSaveBtDisabled}>
                                                {isSaveBtLoading ? (
                                                    <div className="spinner"></div>
                                                ) : (
                                                    <div>Save</div>
                                                )}
                                            </button>
                                            {
                                                checkProductTypes() ?
                                                    <button className="orderSaveBt" style={{
                                                        width: "100%",
                                                        height: "50px",
                                                        textAlign: "center",
                                                        fontSize: "18px",
                                                        fontWeight: "lighter"
                                                    }} onClick={deleteOrderTrueFn}>Delete</button>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {payinfoList.length > 0 ?
                                <div style={{width: "1290px", marginLeft: "70px"}}>
                                    <div>
                                        <h5 style={{color: "dimgrey", marginTop: "120px"}}>결제목록</h5>
                                    </div>
                                    <div className={`accordion-header ${payListAccordion ? 'expanded' : ''}`}
                                         onClick={payListAccordionFn}>
                                        <i className="accordion-icon"></i>
                                        <span>Payment Form</span>
                                    </div>
                                    <div className={`accordion-body ${payListAccordion ? 'expanded' : ''}`}>
                                        {/*결제양식 헤더*/}
                                        <div style={{
                                            height: "50px",
                                            display: "flex",
                                            color: "dimgrey",
                                            borderBottom: "solid",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray"
                                        }}>
                                            <div style={{
                                                width: "5%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                {/*결제리스트 all checkBox*/}
                                                <input type="checkbox" style={{width: "19px", height: "19px"}}
                                                       checked={allSelectedPaylist}
                                                       onChange={e => handleAllPaylistCheckboxChange(e.target.checked)}/>
                                            </div>
                                            <div style={{
                                                width: "10%",
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}><span>일시</span></div>
                                            <div style={{
                                                width: "15%",
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}><span>상품비용</span></div>
                                            <div style={{
                                                width: "44%",
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}><span>해외배송비</span></div>
                                            <div style={{
                                                width: "10%",
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}><span>기타비용</span></div>
                                            <div style={{
                                                width: "8%",
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}><span>결제상태</span></div>
                                            <div style={{width: "8%"}}></div>
                                        </div>
                                        {/*결제리스트 바디*/}
                                        {sortedPayinfoList.map((pay, index) => (
                                            // 결제구분이 1인 데이터만 모두 표기
                                            <div key={index} style={{
                                                height: "auto",
                                                display: "flex",
                                                color: "dimgrey",
                                                borderBottom: "solid",
                                                borderWidth: "thin 1px",
                                                borderColor: "lightgray"
                                            }}>
                                                <div style={{
                                                    width: "5%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "20px 0 20px 0"
                                                }}>
                                                    {/*결제리스트 개별 체크박스*/}
                                                    {
                                                        pay.payStatus === "PS0" && pay.payType === "PT1" ?
                                                            <input type="checkbox" style={{
                                                                width: "19px",
                                                                height: "19px"
                                                            }}
                                                                   checked={selectedPayInfos[pay.seqNum] || false}
                                                                   onChange={e => handlePaylistCheckboxChange(pay.seqNum, e.target.checked)}/>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div style={{
                                                    width: "10%",
                                                    fontSize: "15px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                                    <span>{pay.regDate ? `${pay.regDate.substring(0, 4)}-${pay.regDate.substring(4, 6)}-${pay.regDate.substring(6, 8)}` : ''}</span>
                                                </div>
                                                <div style={{
                                                    width: "15%",
                                                    fontSize: "15px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                                    <span>{pay.ttProdExpense && (pay.ttProdExpense)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                                </div>
                                                <div style={{
                                                    width: "44%",
                                                    fontSize: "15px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "10px 0 10px 0"
                                                }}>
                                                    {
                                                        pay.payType === "PT1" ?
                                                            <div style={{width: "100%", paddingLeft: "50px"}}>
                                                                {
                                                                    pay.ttFedexPrice > 0 &&
                                                                    <div style={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        margin: "10px 0"
                                                                    }}><span>
                                                                        {pay.payStatus === "PS0" && pay.ttFedexPrice > 0 ?
                                                                            <input
                                                                                className="radioButton"
                                                                                type="radio"
                                                                                value="FedEx"
                                                                                checked={selectedOverseasShippingCost[pay.seqNum] === 'FedEx'}
                                                                                onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                                                style={{
                                                                                    borderStyle: "solid",
                                                                                    width: "15px",
                                                                                    height: "15px",
                                                                                    display: "none"
                                                                                }}/>
                                                                            :
                                                                            null
                                                                        }
                                                                    </span>
                                                                        {pay.ttFedexPrice > 0 ?
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                width: "100%"
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    alignItems: "center",
                                                                                    border: "solid",
                                                                                    borderWidth: selectedOverseasShippingCost[pay.seqNum] === 'FedEx' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "2px" : "thin 1px",
                                                                                    borderColor: selectedOverseasShippingCost[pay.seqNum] === 'FedEx' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "orange" : "lightgray",
                                                                                    borderRadius: "15px",
                                                                                    padding: "15px",
                                                                                    cursor: "pointer"
                                                                                }}
                                                                                     onClick={() => handleRadioChange(pay.seqNum, 'FedEx')}>
                                                                                    <img style={{
                                                                                        width: "70px",
                                                                                        height: "45px",
                                                                                        padding: "5px",
                                                                                        objectFit: "contain"
                                                                                    }} alt="fedex" src={FedexLogo}/>
                                                                                    <span style={{
                                                                                        color: pay.payStatus === "PS0" || pay.payStatus === "PS1" ? "orange" : "dimgrey",
                                                                                        fontWeight: "bold",
                                                                                    }}>
                                                                    {pay.ttFedexPrice.toLocaleString('en-US')}
                                                                </span>
                                                                                </div>
                                                                                <div className="font-t-cont" style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    marginLeft: "10px"
                                                                                }}>
                                                                                    <a style={{fontSize: "15px"}}>FedEx</a>
                                                                                    <a style={{fontSize: "13px"}}>{t(`modPlFedex`)}</a>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }

                                                                    </div>
                                                                }

                                                                {
                                                                    pay.ttDhlPrice > 0 &&
                                                                    <div style={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        margin: "10px 0"
                                                                    }}><span>
                                                                        {pay.payStatus === "PS0" && pay.ttDhlPrice > 0 ?
                                                                            <input
                                                                                className="radioButton"
                                                                                type="radio"
                                                                                value="DHL"
                                                                                checked={selectedOverseasShippingCost[pay.seqNum] === 'DHL'}
                                                                                onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                                                style={{
                                                                                    borderStyle: "solid",
                                                                                    width: "15px",
                                                                                    height: "15px",
                                                                                    display: "none"
                                                                                }}/>
                                                                            :
                                                                            null
                                                                        }
                                                                    </span>
                                                                        {pay.ttDhlPrice > 0 ?
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                width: "100%"
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    alignItems: "center",
                                                                                    border: "solid",
                                                                                    borderWidth: selectedOverseasShippingCost[pay.seqNum] === 'DHL' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "2px" : "thin 1px",
                                                                                    borderColor: selectedOverseasShippingCost[pay.seqNum] === 'DHL' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "orange" : "lightgray",
                                                                                    borderRadius: "15px",
                                                                                    padding: "15px",
                                                                                    cursor: "pointer"
                                                                                }} onClick={() => handleRadioChange(pay.seqNum, 'DHL')}>
                                                                                    <img style={{
                                                                                        width: "70px",
                                                                                        height: "45px",
                                                                                        padding: "5px",
                                                                                        objectFit: "contain"
                                                                                    }} alt="dhl" src={DhlLogo}/>
                                                                                    <span style={{
                                                                                        color: pay.payStatus === "PS0" || pay.payStatus === "PS1" ? "orange" : "dimgrey",
                                                                                        fontWeight: "bold",
                                                                                    }}>
                                                                    {pay.ttDhlPrice.toLocaleString('en-US')}
                                                                </span>
                                                                                </div>
                                                                                <div className="font-t-cont" style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    marginLeft: "10px"
                                                                                }}>
                                                                                    <a style={{fontSize: "15px"}}>DHL</a>
                                                                                    <a style={{fontSize: "13px"}}>{t(`modDhl`)}</a>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                }

                                                                {
                                                                    pay.ttEmsPrice > 0 &&
                                                                    <div style={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        margin: "10px 0"
                                                                    }}><span>
                                                                        {pay.payStatus === "PS0" && pay.ttEmsPrice > 0 ?
                                                                            <input
                                                                                className="radioButton"
                                                                                type="radio"
                                                                                value="EMS"
                                                                                checked={selectedOverseasShippingCost[pay.seqNum] === 'EMS'}
                                                                                onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                                                style={{
                                                                                    borderStyle: "solid",
                                                                                    width: "15px",
                                                                                    height: "15px",
                                                                                    display: "none"
                                                                                }}/>
                                                                            :
                                                                            null
                                                                        }
                                                                    </span>
                                                                        {pay.ttEmsPrice > 0 ?
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                width: "100%"
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    alignItems: "center",
                                                                                    border: "solid",
                                                                                    borderWidth: selectedOverseasShippingCost[pay.seqNum] === 'EMS' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "2px" : "thin 1px",
                                                                                    borderColor: selectedOverseasShippingCost[pay.seqNum] === 'EMS' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "orange" : "lightgray",
                                                                                    borderRadius: "15px",
                                                                                    padding: "15px",
                                                                                    cursor: "pointer"
                                                                                }} onClick={() => handleRadioChange(pay.seqNum, 'EMS')}>
                                                                                    <img style={{
                                                                                        width: "70px",
                                                                                        height: "45px",
                                                                                        padding: "5px",
                                                                                        objectFit: "contain"
                                                                                    }} alt="ems" src={EmsLogo}/>
                                                                                    <span style={{
                                                                                        color: pay.payStatus === "PS0" || pay.payStatus === "PS1" ? "orange" : "dimgrey",
                                                                                        fontWeight: "bold",
                                                                                    }}>
                                                                    {pay.ttEmsPrice.toLocaleString('en-US')}
                                                                </span>
                                                                                </div>
                                                                                <div className="font-t-cont" style={{display:"flex", flexDirection:"column", marginLeft:"10px"}}>
                                                                                    <a style={{fontSize: "15px"}}>EMS</a>
                                                                                    <a style={{fontSize: "13px"}}>{t(`modEms`)}</a>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    pay.ttUpsPrice > 0 &&
                                                                    <div style={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        margin: "10px 0"
                                                                    }}><span>
                                                                        {pay.payStatus === "PS0" && pay.ttUpsPrice > 0 ?
                                                                            <input
                                                                                className="radioButton"
                                                                                type="radio"
                                                                                value="UPS"
                                                                                checked={selectedOverseasShippingCost[pay.seqNum] === 'UPS'}
                                                                                onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                                                style={{
                                                                                    borderStyle: "solid",
                                                                                    width: "15px",
                                                                                    height: "15px",
                                                                                    display: "none"
                                                                                }}/>
                                                                            :
                                                                            null
                                                                        }
                                                                    </span>
                                                                        {pay.ttUpsPrice > 0 ?
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                width: "100%"
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    alignItems: "center",
                                                                                    border: "solid",
                                                                                    borderWidth: selectedOverseasShippingCost[pay.seqNum] === 'UPS' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "2px" : "thin 1px",
                                                                                    borderColor: selectedOverseasShippingCost[pay.seqNum] === 'UPS' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "orange" : "lightgray",
                                                                                    borderRadius: "15px",
                                                                                    padding: "15px",
                                                                                    cursor: "pointer",
                                                                                }} onClick={() => handleRadioChange(pay.seqNum, 'UPS')}>
                                                                                    <img style={{
                                                                                        width: "70px",
                                                                                        height: "45px",
                                                                                        padding: "5px",
                                                                                        objectFit:"contain"
                                                                                    }} alt="ups" src={upsLogo}/>
                                                                                    <span style={{
                                                                                        color: pay.payStatus === "PS0" || pay.payStatus === "PS1" ? "orange" : "dimgrey",
                                                                                        fontWeight: "bold",
                                                                                    }}>
                                                                    {pay.ttUpsPrice.toLocaleString('en-US')}
                                                                </span>
                                                                                </div>
                                                                                <div className="font-t-cont" style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    marginLeft: "10px"
                                                                                }}>
                                                                                    <a style={{fontSize: "15px"}}>UPS</a>
                                                                                    <a style={{fontSize: "13px"}}>{t(`modUps`)}</a>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                }

                                                                {
                                                                    pay.ttKPackPrice > 0 &&
                                                                    <div style={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        margin: "10px 0"
                                                                    }}><span>
                                                                        {pay.payStatus === "PS0" && pay.ttKPackPrice > 0 ?
                                                                            <input
                                                                                className="radioButton"
                                                                                type="radio"
                                                                                value="K-Packet"
                                                                                checked={selectedOverseasShippingCost[pay.seqNum] === 'K-Packet'}
                                                                                onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                                                style={{
                                                                                    borderStyle: "solid",
                                                                                    width: "15px",
                                                                                    height: "15px",
                                                                                    display: "none"
                                                                                }}/>
                                                                            :
                                                                            null
                                                                        }
                                                                    </span>
                                                                        {pay.ttKPackPrice > 0 ?
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                width: "100%"
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    alignItems: "center",
                                                                                    border: "solid",
                                                                                    borderWidth: selectedOverseasShippingCost[pay.seqNum] === 'K-Packet' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "2px" : "thin 1px",
                                                                                    borderColor: selectedOverseasShippingCost[pay.seqNum] === 'K-Packet' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "orange" : "lightgray",
                                                                                    borderRadius: "15px",
                                                                                    padding: "15px",
                                                                                    cursor: "pointer"
                                                                                }} onClick={() => handleRadioChange(pay.seqNum, 'K-Packet')}>
                                                                                    <img style={{
                                                                                        width: "70px",
                                                                                        height: "45px",
                                                                                        padding: "5px",
                                                                                        objectFit:"contain"
                                                                                    }} alt="kPack" src={kPacketLogo}/>
                                                                                    <span style={{
                                                                                        color: pay.payStatus === "PS0" || pay.payStatus === "PS1" ? "orange" : "dimgrey",
                                                                                        fontWeight: "bold",
                                                                                    }}>
                                                                    {pay.ttKPackPrice.toLocaleString('en-US')}
                                                                </span>
                                                                                </div>
                                                                                <div className="font-t-cont" style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    marginLeft: "10px"
                                                                                }}>

                                                                                    <a style={{fontSize: "15px"}}>K-Packet</a>
                                                                                    <a style={{fontSize: "13px"}}>{t(`modKpack`)}</a>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    pay.ttAirSPrice > 0 &&
                                                                    <div style={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        margin: "10px 0"
                                                                    }}><span>
                                                                        {pay.payStatus === "PS0" && pay.ttAirSPrice > 0 ?
                                                                            <input
                                                                                className="radioButton"
                                                                                type="radio"
                                                                                value="Small-Packet"
                                                                                checked={selectedOverseasShippingCost[pay.seqNum] === 'Small-Packet'}
                                                                                onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                                                style={{
                                                                                    borderStyle: "solid",
                                                                                    width: "15px",
                                                                                    height: "15px",
                                                                                    display: "none"
                                                                                }}/>
                                                                            :
                                                                            null
                                                                        }
                                                                    </span>
                                                                        {pay.ttAirSPrice > 0 ?
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                width: "100%"
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    alignItems: "center",
                                                                                    border: "solid",
                                                                                    borderWidth: selectedOverseasShippingCost[pay.seqNum] === 'Small-Packet' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "2px" : "thin 1px",
                                                                                    borderColor: selectedOverseasShippingCost[pay.seqNum] === 'Small-Packet' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "orange" : "lightgray",
                                                                                    borderRadius: "15px",
                                                                                    padding: "15px",
                                                                                    cursor: "pointer"
                                                                                }}
                                                                                     onClick={() => handleRadioChange(pay.seqNum, 'Small-Packet')}>
                                                                                    <img style={{
                                                                                        width: "70px",
                                                                                        height: "45px",
                                                                                        padding: "5px",
                                                                                        objectFit: "contain"
                                                                                    }} alt="Small-Packet" src={smallPacketLogo}/>
                                                                                    <span style={{
                                                                                        color: pay.payStatus === "PS0" || pay.payStatus === "PS1" ? "orange" : "dimgrey",
                                                                                        fontWeight: "bold",
                                                                                    }}>
                                                                    {pay.ttAirSPrice.toLocaleString('en-US')}
                                                                </span>
                                                                                </div>
                                                                                <div className="font-t-cont" style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    alignItems: "center",
                                                                                    marginLeft: "10px"
                                                                                }}>
                                                                                    <a style={{fontSize: "16px"}}>Small-Packet</a>
                                                                                    <a style={{fontSize: "13px"}}>{t(`modAirS`)}</a>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                }

                                                                {
                                                                    pay.ttSeaPrice > 0 &&
                                                                    <div style={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        margin: "10px 0"
                                                                    }}><span>
                                                                        {pay.payStatus === "PS0" && pay.ttSeaPrice > 0 ?
                                                                            <input
                                                                                className="radioButton"
                                                                                type="radio"
                                                                                value="Sea"
                                                                                checked={selectedOverseasShippingCost[pay.seqNum] === 'Sea'}
                                                                                onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                                                style={{
                                                                                    borderStyle: "solid",
                                                                                    width: "15px",
                                                                                    height: "15px",
                                                                                    display: "none"
                                                                                }}/>
                                                                            :
                                                                            null
                                                                        }
                                                                    </span>
                                                                        {pay.ttSeaPrice > 0 ?
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                width: "100%"
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    alignItems: "center",
                                                                                    border: "solid",
                                                                                    borderWidth: selectedOverseasShippingCost[pay.seqNum] === 'Sea' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "2px" : "thin 1px",
                                                                                    borderColor: selectedOverseasShippingCost[pay.seqNum] === 'Sea' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "orange" : "lightgray",
                                                                                    borderRadius: "15px",
                                                                                    padding: "15px",
                                                                                    cursor: "pointer",
                                                                                }} onClick={() => handleRadioChange(pay.seqNum, 'Sea')}>
                                                                                    <div className="user-center">
                                                                                        <img style={{
                                                                                            width: "70px",
                                                                                            height: "45px",
                                                                                            padding: "5px",
                                                                                            objectFit: "contain"
                                                                                        }}
                                                                                             alt="sea" src={SeaShipLogo}/>
                                                                                    </div>
                                                                                    <span style={{
                                                                                        color: pay.payStatus === "PS0" || pay.payStatus === "PS1" ? "orange" : "dimgrey",
                                                                                        fontWeight: "bold",
                                                                                    }}>
                                                                    {pay.ttSeaPrice.toLocaleString('en-US')}
                                                                </span>
                                                                                </div>
                                                                                <div className="font-t-cont" style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    marginLeft: "10px"
                                                                                }}>
                                                                                    <a style={{fontSize: "15px"}}>Sea Parcel</a>
                                                                                    <a style={{fontSize: "13px"}}>{t(`modSea`)}</a>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                }

                                                                {
                                                                    pay.ttDomPrice > 0 &&
                                                                    <div style={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        margin: "10px 0"
                                                                    }}><span>
                                                                        {pay.payStatus === "PS0" && pay.ttDomPrice > 0 ?
                                                                            <input
                                                                                className="radioButton"
                                                                                type="radio"
                                                                                value="Dom"
                                                                                checked={selectedOverseasShippingCost[pay.seqNum] === 'Dom'}
                                                                                onChange={(e) => handleRadioChange(pay.seqNum, e.target.value)}
                                                                                style={{
                                                                                    borderStyle: "solid",
                                                                                    width: "15px",
                                                                                    height: "15px",
                                                                                    display: "none"
                                                                                }}/>
                                                                            :
                                                                            null
                                                                        }
                                                                    </span>
                                                                        {pay.ttDomPrice > 0 ?
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                width: "100%"
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    alignItems: "center",
                                                                                    border: "solid",
                                                                                    borderWidth: selectedOverseasShippingCost[pay.seqNum] === 'Dom' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "2px" : "thin 1px",
                                                                                    borderColor: selectedOverseasShippingCost[pay.seqNum] === 'Dom' && (pay.payStatus === "PS0" || pay.payStatus === "PS1") ? "orange" : "lightgray",
                                                                                    borderRadius: "15px",
                                                                                    padding: "15px",
                                                                                    cursor: "pointer",
                                                                                }} onClick={() => handleRadioChange(pay.seqNum, 'Dom')}>
                                                                                    <div className="user-center">
                                                                                        <img style={{
                                                                                            width: "70px",
                                                                                            height: "45px",
                                                                                            padding: "5px",
                                                                                            objectFit:"contain"
                                                                                        }}
                                                                                             alt="sea" src={domLogo}/>
                                                                                    </div>
                                                                                    <span style={{
                                                                                        color: pay.payStatus === "PS0" || pay.payStatus === "PS1" ? "orange" : "dimgrey",
                                                                                        fontWeight: "bold",
                                                                                    }}>
                                                                    {pay.ttDomPrice.toLocaleString('en-US')}
                                                                </span>
                                                                                </div>
                                                                                <div className="font-t-cont" style={{display:"flex", flexDirection:"column", marginLeft:"10px"}}>
                                                                                    <a style={{fontSize:"15px"}}>Domestic delivery</a>
                                                                                    <a style={{fontSize:"13px"}}>{t(`modDm`)}</a>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }

                                                                    </div>
                                                                }
                                                            </div>
                                                            :
                                                            0
                                                    }
                                                </div>
                                                <div style={{
                                                    width: "10%",
                                                    fontSize: "15px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                                    <span>{(pay?.ttEtcFee + pay?.ttEtcPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                                </div>
                                                <div style={{
                                                    width: "8%",
                                                    fontSize: "15px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                                    {pay.payStatus === "PS0" ?
                                                        <p style={{
                                                            margin: "0",
                                                            color: "#98B0D4",
                                                            fontSize: "15px"
                                                        }}>결제대기</p>
                                                        :
                                                        pay.payStatus === "PS1" ?
                                                            <p style={{
                                                                margin: "0",
                                                                color: "#98B0D4",
                                                                fontSize: "15px"
                                                            }}>결제중</p>
                                                            :
                                                            pay.payStatus === "PS2" ?
                                                                <p style={{
                                                                    margin: "0",
                                                                    color: "#FFB378",
                                                                    fontSize: "15px"
                                                                }}>결제완료</p>
                                                                :
                                                                pay.payStatus === "PS3" ?
                                                                    <p style={{
                                                                        margin: "0",
                                                                        color: "#979696",
                                                                        fontSize: "15px"
                                                                    }}>출고완료</p>
                                                                    :
                                                                    pay.payStatus === "PS4" ?
                                                                        <p style={{
                                                                            margin: "0",
                                                                            color: "#FF8F62",
                                                                            fontSize: "15px"
                                                                        }}>반송중</p>
                                                                        :
                                                                        pay.payStatus === "PS5" ?
                                                                            <p style={{
                                                                                margin: "0",
                                                                                color: "#FF8F62",
                                                                                fontSize: "15px"
                                                                            }}>취소</p>
                                                                            :
                                                                            null
                                                    }
                                                </div>
                                                <div
                                                    style={{
                                                        width: "8%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        margin: "10px 0 10px 0"
                                                    }}>
                                                    <button className="orderItemHandleBt" style={{
                                                        width: "50px",
                                                        height: "30px",
                                                        marginLeft: "0"
                                                    }} onClick={() =>
                                                        pay.payClass === '0' && pay.payType === "PT0" ?
                                                            showDetail('prod', pay.seqNum) :
                                                            pay.payType === "PT1" ?
                                                                showDetail('delivery', pay.seqNum) :
                                                                pay.payType === "PT2" ?
                                                                    showDetail('etc', pay.seqNum) :
                                                                    pay.payClass === '1' && pay.payType === "PT0" ?
                                                                        showDetail('adminProd', pay.seqNum) :
                                                                        null
                                                    }>상세
                                                    </button>
                                                    {pay.payStatus === "PS0" ?
                                                        <button className="orderDeleteBt" style={{
                                                            width: "50px",
                                                            height: "28px",
                                                            textAlign: "center",
                                                            padding: "0",
                                                            marginTop: "3px",
                                                            fontSize: "14px",
                                                            color: "white"
                                                        }} onClick={() => {
                                                            setSelectedPayinfo(pay);
                                                            deletePayMsgTrueFn();
                                                        }}>삭제</button>
                                                        : null}

                                                </div>
                                            </div>

                                        ))}
                                    </div>

                                    <div style={{
                                        height: "50px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "end"
                                    }}>
                                        <button className="orderItemHandleBt"
                                                style={{width: "70px", height: "35px", fontSize: "13px"}}
                                                onClick={openModal}>배송등록
                                        </button>
                                    </div>
                                </div>
                                :
                                null
                            }
                            {/*실시간 메신저 챗*/}
                            <div style={{
                                height: "700px",
                                border: "solid",
                                width: "1290px",
                                marginTop: "100px",
                                borderWidth: "thin 1px",
                                borderColor: "#EAEAEA",
                                borderRadius: "6px",
                                display: "flex",
                                position: "relative",
                                marginLeft: "70px"
                            }}>
                                {/*주문서별 채팅 div*/}
                                <div style={{
                                    width: "30%",
                                    borderRight: "solid",
                                    borderWidth: "thin 1px",
                                    borderColor: "#EAEAEA"
                                }}>
                                    <div style={{
                                        borderBottom: "solid",
                                        borderWidth: "thin 1px",
                                        borderColor: "#EAEAEA",
                                        height: "60px",
                                        fontSize: "17px",
                                        fontWeight: "bold",
                                        display: "flex",
                                        alignItems: "center",
                                        paddingLeft: "20px",
                                        boxShadow: "0 1px 4px -2px #EAEAEA"
                                    }}>1:1 실시간채팅
                                    </div>
                                    {chatMessages && chatMessages.length > 0 ?
                                        <div>
                                            <button className="chatListBt"
                                                    style={{
                                                        height: "80px",
                                                        width: "100%",
                                                        display: "flex",
                                                        border: "none"
                                                    }}>
                                                <div style={{
                                                    borderWidth: "thin 1px",
                                                    borderColor: "rgba(0, 0, 0, 0.1)",
                                                    height: "60px",
                                                    width: "60px",
                                                    marginRight: "5px",
                                                    borderRadius: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    overflow: "hidden",
                                                    margin: "auto"
                                                }}><img style={{
                                                    width: "120%",
                                                    height: "100%",
                                                    display: "block",
                                                    margin: "auto"
                                                }} alt=""
                                                        src={userProfileImg}/></div>

                                                <div style={{
                                                    height: "100%",
                                                    width: "80%",
                                                    display: "block",
                                                    padding: "16px 0 16px 5px"
                                                }}
                                                     onClick={chatFrameHandle}>
                                                    <div style={{
                                                        height: "50%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between"
                                                    }}>
                                                    <div style={{display: "flex", flexDirection:"row", alignItems: "center"}}>
                                                    <p style={{
                                                        height: "50%",
                                                        margin: "0",
                                                        fontSize: "15px",
                                                        textAlign: "left",
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}>{memberInfo.userName}
                                                        {unreadCount > 0 && chatMessages[0].memberDTO.role === '0' ?
                                                            <span style={{
                                                                borderRadius: "100%",
                                                                width: "auto",
                                                                height: "16px",
                                                                backgroundColor: "#FF6324",
                                                                color: "white",
                                                                fontSize: "11px",
                                                                marginLeft: "5px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                padding: "0 5px"
                                                            }}>{unreadCount}</span>
                                                            :
                                                            null
                                                        }
                                                    </p>
                                                    </div>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "11px",
                                                            color: "darkgray",
                                                            paddingRight:"25px"
                                                        }}>{convertToLastChatDate(chatMessages[0].regTime)}</p>
                                                    </div>
                                                    <span style={{
                                                        width: "280px",
                                                        height: "50%",
                                                        fontSize: "13px",
                                                        textAlign: "left",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        color: "dimgrey",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap"
                                                    }}>
                                        {(lastChatKey && lastChatKey.endsWith("png") ||
                                            lastChatKey && lastChatKey.endsWith("PNG") ||
                                            lastChatKey && lastChatKey.endsWith("jpg") ||
                                            lastChatKey && lastChatKey.endsWith("JPG") ||
                                            lastChatKey && lastChatKey.endsWith("jpeg") ||
                                            lastChatKey && lastChatKey.endsWith("JPEG")) ?
                                            <span style={{font: "18px"}}>[사진]</span> :
                                            lastChatKey && lastChatKey.startsWith("chatFile") ?
                                                <span style={{font: "18px"}}>[파일]</span> : lastChatKey}
                                    </span>
                                                </div>
                                            </button>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                {/* 채팅 컨테이너 div*/}
                                <div style={{width: "70%"}} ref={chatRef}>
                                    {chatShow ?
                                        <div>
                                            <div style={{
                                                height: "60px",
                                                borderBottom: "solid",
                                                borderWidth: "thin 1px",
                                                borderColor: "lightgray",
                                                boxShadow: "0 1px 4px -2px dimgray",
                                                textAlign: "left",
                                                display: "flex",
                                            }}>
                                                {/* 채팅헤더 div*/}
                                                <div style={{
                                                    borderWidth: "thin 1px",
                                                    borderColor: "rgba(0, 0, 0, 0.1)",
                                                    height: "60px",
                                                    width: "60px",
                                                    borderRadius: "100%",
                                                    marginLeft: "8px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    overflow: "hidden",
                                                    padding: "5px"
                                                }}><img style={{
                                                    width: "25px",
                                                    height: "25px",
                                                    display: "block",
                                                    margin: "auto"
                                                }} alt="" src={BtorageLogo}/></div>
                                                <span style={{
                                                    width: "30%",
                                                    height: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "18px",
                                                    color: "5b5b5b",
                                                    marginLeft: "-5px"
                                                }}>Btorage</span>
                                            </div>
                                            <div style={{height: "640px"}}>
                                                {/* 채팅바디 */}
                                                <div style={{
                                                    height: "85%",
                                                    overflowY: "scroll",
                                                    padding: "20px",
                                                    display: "flex",
                                                    flexDirection: "column-reverse"
                                                }} ref={messagesEndRef}>
                                                    {chatMessages && chatMessages.map((message, index) => (
                                                        <div key={index}>
                                                            {(isFirstMessageOfDay(message, chatMessages[index + 1]) &&
                                                                <div style={{
                                                                    width: "800px",
                                                                    height: "60px",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center"
                                                                }}>
                                                                    <p style={{
                                                                        fontSize: "11px",
                                                                        margin: "0"
                                                                    }}>{formatDate(message.regDate)}</p>
                                                                </div>
                                                            )}
                                                            <div style={{
                                                                display: "flex",
                                                                justifyContent: message.memberDTO && message.memberDTO.role === '0' ? 'flex-start' : 'flex-end'
                                                            }}>
                                                                {message.memberDTO && message.memberDTO.role === '0' ?
                                                                    // user profile
                                                                    <div style={{
                                                                        display: "flex",
                                                                        position: "relative",
                                                                        marginTop: "50px"
                                                                    }}>
                                                                        <div style={{
                                                                            marginRight: "5px",
                                                                            position: "absolute",
                                                                            top: "-20px",
                                                                            left: "0",
                                                                            borderRadius: "100%",
                                                                            borderWidth: "thin 1px",
                                                                            backgroundColor: message.chatKey.startsWith("chatFile") ? "white" : "rgba(255,171,77,0.24)",
                                                                            height: "60px",
                                                                            width: "60px",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            overflow: "hidden"
                                                                        }}>
                                                                            <img style={{
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                display: "block",
                                                                                margin: "auto"
                                                                            }} alt="" src={userProfileImg}/></div>
                                                                        <p style={{
                                                                            marginRight: "10px",
                                                                            position: "absolute",
                                                                            top: "-20px",
                                                                            left: "70px"
                                                                        }}>{message.memberDTO.userName}</p>
                                                                        {message.chatKey.startsWith("chatFile") ?
                                                                            // 채팅메시지 프레임
                                                                            <div
                                                                                onClick={!message.removeFl ? () => clickFileDetail(message) : null}
                                                                                style={{margin: "5px 0 0 70px", cursor: "pointer"}}>
                                                                                {isImage(message.chatKey) ?
                                                                                    <div>
                                                                                        {!message.removeFl ?
                                                                                            // 사용자이미지
                                                                                            <p style={{
                                                                                                maxWidth: "500px",
                                                                                                width: "fit-content",
                                                                                                wordBreak: "keep-all",
                                                                                                textAlign: "left",
                                                                                            }}>
                                                                                                <img src={message.fileUrl} alt="chatKey"
                                                                                                     style={{
                                                                                                        maxWidth: "500px",
                                                                                                        maxHeight: "700px",
                                                                                                        borderRadius: "6px",}}
                                                                                                />
                                                                                            </p>
                                                                                            :
                                                                                            <div style={{
                                                                                                padding: "10px",
                                                                                                textAlign: "center"
                                                                                            }}>
                                                                                                <img style={{
                                                                                                    width: "40px",
                                                                                                    height: "40px",
                                                                                                    opacity: "0.5"
                                                                                                }} src={trash} alt="remove"/>
                                                                                                <p style={{fontSize: "11px", marginTop: "5px",}}>기한만료</p>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    // 사용자동영상
                                                                                    isVideo(message.chatKey) ?
                                                                                        <div>
                                                                                            {!message.removeFl ?
                                                                                                <video width="350"
                                                                                                       height="210"
                                                                                                       controls
                                                                                                       style={{margin: "0"}}>
                                                                                                    <source
                                                                                                        src={message.fileUrl}
                                                                                                        type="video/mp4"/>
                                                                                                </video>
                                                                                                :
                                                                                                <div style={{
                                                                                                    padding: "10px",
                                                                                                    textAlign: "center"
                                                                                                }}>
                                                                                                    <img style={{
                                                                                                        width: "40px",
                                                                                                        height: "40px",
                                                                                                        opacity: "0.5"
                                                                                                    }} src={trash}
                                                                                                         alt="remove"/>
                                                                                                    <p style={{
                                                                                                        margin: "0",
                                                                                                        fontSize: "11px",
                                                                                                        marginTop: "5px",
                                                                                                        color: "darkgray"
                                                                                                    }}>({t('deadline')})</p>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        :
                                                                                        // 이외 파일들
                                                                                        <div>
                                                                                            {!message.removeFl ?
                                                                                                <div style={{
                                                                                                    width: "150px",
                                                                                                    height: "130px",
                                                                                                    border: "solid",
                                                                                                    backgroundColor: "white",
                                                                                                    borderWidth: "thin 1px",
                                                                                                    borderColor: "#EAEAEA",
                                                                                                    borderRadius: "6px"
                                                                                                }}>
                                                                                                    <div style={{
                                                                                                        height: "60%",
                                                                                                        display: "flex",
                                                                                                        justifyContent: "center",
                                                                                                        alignItems: "center",
                                                                                                        overflow: "hidden"
                                                                                                    }}>
                                                                                                        <img style={{
                                                                                                            width: "50%",
                                                                                                            height: "90%",
                                                                                                            paddingTop: "6px"
                                                                                                        }}
                                                                                                             src={chatFileImg}
                                                                                                             alt="fileImg"/>
                                                                                                    </div>
                                                                                                    <div className="font-t-cont" style={{
                                                                                                        height: "40%",
                                                                                                    }}>
                                                                                                        <div className="user-center wd-100pc m-l-5">
                                                                                                            <div className="m-a-chatKey-fileNm-text wd-140">
                                                                                                                {message.fileNm}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <p style={{
                                                                                                            width: "100%",
                                                                                                            height: "50%",
                                                                                                            display: "flex",
                                                                                                            justifyContent: "center",
                                                                                                            margin: "0",
                                                                                                            color: "dimgrey",
                                                                                                            fontSize: "12px",
                                                                                                            paddingBottom: "5px"
                                                                                                        }}> ~ {oneMonthFromNow(message.deadline)}{t('modChatAvailableDate')}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <div style={{
                                                                                                    padding: "10px",
                                                                                                    textAlign: "center"
                                                                                                }}>
                                                                                                    <img style={{
                                                                                                        width: "40px",
                                                                                                        height: "40px",
                                                                                                        opacity: "0.5"
                                                                                                    }} src={trash}
                                                                                                         alt="remove"/>
                                                                                                    <p style={{
                                                                                                        margin: "0",
                                                                                                        fontSize: "11px",
                                                                                                        marginTop: "5px",
                                                                                                        color: "darkgray"
                                                                                                    }}>({t('deadline')})</p>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div style={{
                                                                                position: 'relative',
                                                                                border: 'solid',
                                                                                borderWidth: 'thin 1px',
                                                                                borderColor: 'lightgray',
                                                                                borderRadius: '6px',
                                                                                textAlign: 'left',
                                                                                marginTop: '10px',
                                                                                marginLeft: '70px',
                                                                                padding: '5px',
                                                                                backgroundColor: '#fff'
                                                                            }}>
                                                                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                        <a style={{color: "orangered"}}
                                                                                           target="blank"
                                                                                           href={decoratedHref}
                                                                                           key={key}>
                                                                                            {decoratedText}
                                                                                        </a>
                                                                                    )}
                                                                                >
                                                                                    <p style={{
                                                                                        margin: "8px",
                                                                                        maxWidth: "500px",
                                                                                        width: "fit-content",
                                                                                        wordBreak: "break-word",
                                                                                        textAlign: "left",
                                                                                        whiteSpace: "pre-wrap"
                                                                                        // 사용자 텍스트채팅
                                                                                    }}>{DOMPurify.sanitize(message.chatKey)}</p>
                                                                                </Linkify>
                                                                            </div>
                                                                        }
                                                                        <div style={{
                                                                            position: 'absolute',
                                                                            width: '0',
                                                                            height: '0',
                                                                            border: '4px solid transparent',
                                                                            borderBottomColor: '#fff',
                                                                            borderRightColor: '#fff',
                                                                            transform: 'rotate(135deg)',
                                                                            left: '-4px',
                                                                            top: '11px',
                                                                        }}></div>
                                                                        {!message.removeFl ?
                                                                            <div style={{marginLeft: "5px"}}>
                                                                                <p style={{
                                                                                    display: "flex",
                                                                                    alignItems: "end",
                                                                                    height: "100%",
                                                                                    width: "100px",
                                                                                    fontSize: "10px",
                                                                                    margin: "0"
                                                                                }}>{formatTime(message.regTime)}
                                                                                </p>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                    :
                                                                    // 관리자채팅
                                                                    <div style={{
                                                                        borderRadius: "6px",
                                                                        marginTop: "10px",
                                                                        display: "flex",
                                                                    }}>
                                                                        {!message.removeFl ?
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "flex-end",
                                                                                justifyContent: "flex-end",
                                                                                fontSize: "12px",
                                                                                flexDirection: "row",
                                                                                marginRight: "5px"
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    marginRight: "5px"
                                                                                }}>
                                                                                    {message && message.unreadCount > 0 ?
                                                                                        <p style={{
                                                                                            margin: "0",
                                                                                            marginRight: "5px",
                                                                                            color: "darkorange",
                                                                                            fontSize: "10px",
                                                                                            fontWeight: "bold",
                                                                                            height: "100%",
                                                                                        }}>1</p>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    <button style={{
                                                                                        border: "none",
                                                                                        padding: "0",
                                                                                        fontSize: "11px",
                                                                                        color: "CornflowerBlue"
                                                                                    }}
                                                                                            onClick={() => openModifyModal(message)}>수정
                                                                                    </button>
                                                                                    <button style={{
                                                                                        border: "none",
                                                                                        padding: "0",
                                                                                        fontSize: "11px",
                                                                                        color: "darkorange",
                                                                                        marginLeft: "3px"
                                                                                    }}
                                                                                            onClick={() => openDeleteModal(message)}>삭제
                                                                                    </button>
                                                                                </div>
                                                                                <div>
                                                                                    <p style={{
                                                                                        display: "flex",
                                                                                        // alignItems: "end",
                                                                                        height: "100%",
                                                                                        fontSize: "10px",
                                                                                        margin: "0"
                                                                                    }}>{formatTime(message.regTime)}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        {/*관리자 채팅메시지 프레임*/}
                                                                        {message.chatKey.startsWith("chatFile") ?
                                                                            <div
                                                                                onClick={!message.removeFl ? () => clickFileDetail(message) : null}
                                                                                style={{cursor: "pointer"}}>
                                                                                {isImage(message.chatKey) ?
                                                                                    // 관리자이미지
                                                                                    <div>
                                                                                        {!message.removeFl ?
                                                                                            <div style={{
                                                                                                maxWidth: "500px",
                                                                                                width: "fit-content"
                                                                                            }}>
                                                                                                <img
                                                                                                    style={{
                                                                                                        maxWidth: "500px",
                                                                                                        maxHeight: "700px",
                                                                                                        borderRadius: "6px"
                                                                                                    }}
                                                                                                    src={message.fileUrl}
                                                                                                    alt="chatKey"/>
                                                                                            </div>
                                                                                            :
                                                                                            <div style={{
                                                                                                padding: "10px",
                                                                                                textAlign: "center"
                                                                                            }}>
                                                                                                <img style={{
                                                                                                    width: "40px",
                                                                                                    height: "40px",
                                                                                                    opacity: "0.5"
                                                                                                }} src={trash}
                                                                                                     alt="remove"/>
                                                                                                <p style={{
                                                                                                    margin: "0",
                                                                                                    fontSize: "11px",
                                                                                                    marginTop: "5px",
                                                                                                    color: "darkgray"
                                                                                                }}>({t('deadline')})</p>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    // 관리자동영상
                                                                                    isVideo(message.chatKey) ?
                                                                                        <div>
                                                                                            {!message.removeFl ?
                                                                                                <div style={{
                                                                                                    padding: "0",
                                                                                                    margin: "0"
                                                                                                }}>
                                                                                                    <video width="300"
                                                                                                           height="260"
                                                                                                           controls>
                                                                                                        <source
                                                                                                            src={message.fileUrl}
                                                                                                            type="video/mp4"/>
                                                                                                    </video>
                                                                                                </div>
                                                                                                :
                                                                                                <div style={{
                                                                                                    padding: "10px",
                                                                                                    textAlign: "center"
                                                                                                }}>
                                                                                                    <img style={{
                                                                                                        width: "40px",
                                                                                                        height: "40px",
                                                                                                        opacity: "0.5"
                                                                                                    }} src={trash}
                                                                                                         alt="remove"/>
                                                                                                    <p style={{
                                                                                                        margin: "0",
                                                                                                        fontSize: "11px",
                                                                                                        marginTop: "5px",
                                                                                                        color: "darkgray"
                                                                                                    }}>({t('deadline')})</p>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        :
                                                                                        // 외 파일들
                                                                                        <div>
                                                                                            {!message.removeFl ?
                                                                                                <div style={{
                                                                                                    width: "150px",
                                                                                                    height: "130px",
                                                                                                    border: "solid",
                                                                                                    backgroundColor: "white",
                                                                                                    borderWidth: "thin 1px",
                                                                                                    borderColor: "#EAEAEA",
                                                                                                    borderRadius: "6px",
                                                                                                }}>
                                                                                                    <div style={{
                                                                                                        height: "60%",
                                                                                                        display: "flex",
                                                                                                        justifyContent: "center",
                                                                                                        alignItems: "center",
                                                                                                        overflow: "hidden"
                                                                                                    }}>
                                                                                                        <img style={{
                                                                                                            width: "50%",
                                                                                                            height: "90%",
                                                                                                            paddingTop: "6px"
                                                                                                        }}
                                                                                                             src={chatFileImg}
                                                                                                             alt="fileImg"/>
                                                                                                    </div>
                                                                                                    <div className="font-t-cont" style={{
                                                                                                        height: "40%",
                                                                                                    }}>
                                                                                                        <div className="user-center wd-100pc m-l-5">
                                                                                                            <div className="m-a-chatKey-fileNm-text wd-140">
                                                                                                                {message.fileNm}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <p style={{
                                                                                                            width: "100%",
                                                                                                            height: "50%",
                                                                                                            display: "flex",
                                                                                                            justifyContent: "center",
                                                                                                            margin: "0",
                                                                                                            color: "dimgrey",
                                                                                                            fontSize: "12px",
                                                                                                            paddingBottom: "5px"
                                                                                                        }}> ~ {oneMonthFromNow(message.deadline)}{t(`modChatAvailableDate`)}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <div style={{
                                                                                                    padding: "10px",
                                                                                                    textAlign: "center"
                                                                                                }}>
                                                                                                    <img style={{
                                                                                                        width: "40px",
                                                                                                        height: "40px",
                                                                                                        opacity: "0.5"
                                                                                                    }} src={trash}
                                                                                                         alt="remove"/>
                                                                                                    <p style={{
                                                                                                        margin: "0",
                                                                                                        fontSize: "11px",
                                                                                                        marginTop: "5px",
                                                                                                        color: "darkgray"
                                                                                                    }}>({t('deadline')})</p>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div style={{
                                                                                backgroundColor: "#FFAA4D",
                                                                                margin: "0",
                                                                                borderRadius: "15px",
                                                                            }}>
                                                                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                        <a style={{color: "orangered"}}
                                                                                           target="blank"
                                                                                           href={decoratedHref}
                                                                                           key={key}>
                                                                                            {decoratedText}
                                                                                        </a>
                                                                                    )}
                                                                                >
                                                                                    <p style={{
                                                                                        maxWidth: "500px",
                                                                                        width: "fit-content",
                                                                                        wordBreak: "break-word",
                                                                                        whiteSpace: "pre-wrap",
                                                                                        margin: "0",
                                                                                        padding: "8px",
                                                                                        color: "white",
                                                                                        fontSize: "14px",
                                                                                        fontWeight: "lighter"
                                                                                        // 관리자 텍스트메시지
                                                                                    }}>{DOMPurify.sanitize(message.chatKey)}</p>
                                                                                </Linkify>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {/* 채팅인풋 */}
                                                <div style={{
                                                    position: "absolute",
                                                    width: "70%",
                                                    backgroundColor: "white",
                                                    zIndex: "3",
                                                    minHeight: "10%",
                                                    padding: "10px",
                                                    bottom: "0",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "flex-end",
                                                }}>
                                                    <div className="user-input-container">
                                                        <textarea ref={chatInputRef} placeholder="메시지를 입력해주세요" spellCheck="false" value={message}
                                                            className="user-input"
                                                            style={{
                                                                width: "100%",
                                                                color: "darkgray",
                                                                resize: "none",
                                                                height: "35px",
                                                                maxHeight: "300px",
                                                                overflow: "auto",
                                                                outline: "none",
                                                                padding: "10px 10px 0 10px",
                                                                fontSize: "14px",
                                                            }}
                                                            onChange={handleChatInput}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' && !e.shiftKey) {
                                                                    e.preventDefault();
                                                                    sendMessage(message);
                                                                    setMessage('');
                                                                    chatInputRef.current.style.height = 'auto';
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="OrdChat"
                                                         style={{
                                                             width: "100%",
                                                             height: "30%",
                                                             display: "flex",
                                                             alignItems: "center",
                                                             marginTop: "5px"
                                                         }}>
                                                        <div style={{width: "40%", display: "flex"}}>
                                                            <button style={{
                                                                border: "none",
                                                                width: "35px",
                                                                height: "30px",
                                                                display: "flex",
                                                                overflow: "hidden",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }} onClick={() => setShowEmoji(!showEmoji)}>
                                                                <img alt="" src={chatEmotion}
                                                                     style={{width: "25px", height: "25px"}}/>
                                                            </button>
                                                            {showEmoji ?
                                                                <div style={{position: "relative"}}>
                                                                    <div style={{
                                                                        position: "absolute",
                                                                        bottom: "2px",
                                                                        right: "50px"
                                                                    }}>
                                                                        <Picker
                                                                            onEmojiSelect={handleEmojiClick}
                                                                            previewPosition="none"
                                                                            searchPosition="none"
                                                                            skinTonePosition="none"
                                                                            locale="kr"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            {/*chatShow 채팅데이터 인풋*/}
                                                            <div>
                                                                <input
                                                                    type="file"
                                                                    ref={fileInputRef}
                                                                    style={{display: "none"}}
                                                                    onChange={handleFileUpload}
                                                                />
                                                                <button style={{
                                                                    border: "none",
                                                                    width: "35px",
                                                                    height: "30px",
                                                                    display: "flex",
                                                                    overflow: "hidden",
                                                                    justifyContent: "center",
                                                                    alignItems: "center"
                                                                }} onClick={triggerFileInput}>
                                                                    <img alt="" src={chatFile}
                                                                         style={{width: "25px", height: "25px"}}/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div style={{textAlign: "right", width: "60%"}}>
                                                            {typeof message === 'string' && message.trim() !== '' ?
                                                                <button className="orderItemHandleBt"
                                                                        style={{
                                                                            width: "60px",
                                                                            height: "35px",
                                                                            fontSize: "12px"
                                                                        }}
                                                                        onClick={() => {
                                                                            sendMessage(message);
                                                                            setMessage('');
                                                                        }}>전송</button>
                                                                :
                                                                <button className="ordChatBt" style={{
                                                                    width: "60px",
                                                                    height: "35px",
                                                                    fontSize: "12px",
                                                                    borderRadius: "6px",
                                                                    backgroundColor: "rgba(211,213,205,0.25)",
                                                                    border: "none",
                                                                    color: "dimgrey"
                                                                }} disabled>전송</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div style={{height: "100%"}}>
                                            <div style={{
                                                display: "flex",
                                                margin: "auto",
                                                height: "75%",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                position: "relative",
                                                overflowY: "scroll",
                                                padding: "20px",
                                                flexDirection: "column-reverse"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "22px",
                                                    color: "lightgray",
                                                    marginTop: "20px"
                                                }}>Please message me :)</p>
                                                <div style={{
                                                    overflow: "hidden",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}><img alt="" src={chatStart}
                                                        style={{width: "60px", height: "60px", opacity: "0.4"}}/></div>
                                            </div>
                                            {/*chat input*/}
                                            <div style={{
                                                position: "absolute",
                                                width: "70%",
                                                backgroundColor: "white",
                                                zIndex: "3",
                                                minHeight: "10%",
                                                padding: "10px",
                                                bottom: "0",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-end",
                                            }}>
                                                <div className="user-input-container">
                                <textarea
                                    ref={chatInputRef}
                                    placeholder="메시지를 입력해주세요"
                                    spellCheck="false"
                                    value={message}
                                    className="user-input"
                                    style={{
                                        width: "100%",
                                        color: "darkgray",
                                        resize: "none",
                                        height: "35px",
                                        maxHeight: "300px",
                                        overflow: "auto",
                                        outline: "none",
                                        padding: "10px 10px 0 10px",
                                        fontSize: "14px",
                                    }}
                                    onChange={handleChatInput}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            e.preventDefault();
                                            sendMessage(message);
                                            setMessage('');
                                            chatInputRef.current.style.height = 'auto';
                                        }
                                    }}
                                />
                                                </div>
                                                <div className="OrdChat"
                                                     style={{
                                                         width: "100%",
                                                         height: "30%",
                                                         display: "flex",
                                                         alignItems: "center",
                                                         marginTop: "5px"
                                                     }}>
                                                    <div style={{width: "40%", display: "flex"}}>
                                                        <button style={{
                                                            border: "none",
                                                            width: "35px",
                                                            height: "30px",
                                                            display: "flex",
                                                            overflow: "hidden",
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }} onClick={() => setShowEmoji(!showEmoji)}>
                                                            <img alt="" src={chatEmotion}
                                                                 style={{width: "25px", height: "25px"}}/>
                                                        </button>
                                                        {showEmoji ?
                                                            <div style={{position: "relative"}}>
                                                                <div style={{
                                                                    position: "absolute",
                                                                    bottom: "2px",
                                                                    right: "50px"
                                                                }}>
                                                                    <Picker
                                                                        onEmojiSelect={handleEmojiClick}
                                                                        previewPosition="none"
                                                                        searchPosition="none"
                                                                        skinTonePosition="none"
                                                                        locale="kr"
                                                                    />
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {/*chatShow 채팅데이터 인풋*/}
                                                        <div>
                                                            <input
                                                                type="file"
                                                                ref={fileInputRef}
                                                                style={{display: "none"}}
                                                                onChange={handleFileUpload}
                                                            />
                                                            <button style={{
                                                                border: "none",
                                                                width: "35px",
                                                                height: "30px",
                                                                display: "flex",
                                                                overflow: "hidden",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }} onClick={triggerFileInput}>
                                                                <img alt="" src={chatFile}
                                                                     style={{width: "25px", height: "25px"}}/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div style={{textAlign: "right", width: "60%"}}>
                                                        {typeof message === 'string' && message.trim() !== '' ?
                                                            <button className="orderItemHandleBt"
                                                                    style={{
                                                                        width: "60px",
                                                                        height: "35px",
                                                                        fontSize: "12px"
                                                                    }}
                                                                    onClick={() => {
                                                                        sendMessage(message);
                                                                        setMessage('');
                                                                    }}>전송</button>
                                                            :
                                                            <button className="ordChatBt" style={{
                                                                width: "60px",
                                                                height: "35px",
                                                                fontSize: "12px",
                                                                borderRadius: "6px",
                                                                backgroundColor: "rgba(211,213,205,0.25)",
                                                                border: "none",
                                                                color: "dimgrey"
                                                            }} disabled>전송</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                // recmodiSt ?
                                //     <RecmodiModal recmodiFalseFn={recmodiFalseFn} recmodiCloseFn={recmodiCloseFn}
                                //                   recinfoSeqnum={recinfoList.seqnum} setAddressUpdated={setAddressUpdated}
                                //                   setUpdatedRecipientInfo={setUpdatedRecipientInfo}/>
                                //     :
                                //     confirmModalShowSt ?
                                //         <MsgConfirmModal confirmModalShowSt={confirmModalShowSt}
                                //                          confirmModalFalseFn={confirmModalFalseFn} recmodiModalMsg={recmodiModalMsg}/>
                                //         :
                                deleteOrderModalShowSt ?
                                    <MsgSelectModal deleteOrderModalShowSt={deleteOrderModalShowSt}
                                                    deleteOrderFalseFn={deleteOrderFalseFn} deleteOrder={deleteOrder}
                                                    deleteOrderMsg1={deleteOrderMsg1}
                                                    deleteOrderMsg2={deleteOrderMsg2}/>
                                    :
                                    showChatFile ?
                                        <ChatFileUpload showChatFileClose={showChatFileClose}
                                                        selectedFile={selectedFile}
                                                        selectedFileUrl={selectedFileUrl} saveChatFile={saveChatFile}/>
                                        :
                                        chatFileModifySt ?
                                            <ChatFileUpload chatFileModifySt={chatFileModifySt}
                                                            chatFileModifyFalseFn={chatFileModifyFalseFn}
                                                            chatFileModify={chatFileModify}
                                                            messageToModify={messageToModify}/>
                                            :
                                            chatModifySt ?
                                                <ChatModifyModal chatModifyFalseFn={chatModifyFalseFn}
                                                                 messageToModify={messageToModify}
                                                                 chatFileModify={chatFileModify}/>
                                                :
                                                detailState.prod ?
                                                    <ProdPriceDetail paySeqnum={detailState.paySeqnum}
                                                                     closeDetail={closeDetail}/>
                                                    :
                                                    detailState.delivery ?
                                                        <DeliveryPriceDetail paySeqnum={detailState.paySeqnum}
                                                                             closeDetail={closeDetail}
                                                                             updatePayinfoList={updatePayinfoList}/>
                                                        :
                                                        detailState.etc ?
                                                            <EtcPriceDetail paySeqnum={detailState.paySeqnum}
                                                                            closeDetail={closeDetail}/>
                                                            :
                                                            detailState.adminProd ?
                                                                <ProdPriceAdminAddDetail
                                                                    paySeqnum={detailState.paySeqnum}
                                                                    closeDetail={closeDetail}/>
                                                                :
                                                                useCouponSt ?
                                                                        <CouponUse useCouponSt={useCouponSt}
                                                                                   closeUseCoupon={closeUseCoupon}
                                                                                   couponUseFn={couponUseFn} memberInfo={memberInfo}/>
                                                                    :
                                                                    paypalPopupSt ?
                                                                        <PaypalModal paypalPopupSt={paypalPopupSt}
                                                                                     paypalPopupClose={paypalPopupClose}
                                                                                     currency={currency}
                                                                                     finalPayExpense={finalPayExpense}
                                                                                     lastForPrice={lastForPrice}
                                                                                     paypalOrderProcess={paypalOrderProcess}/>
                                                                        :
                                                                        deletePaySt ?
                                                                            <MsgSelectModal deletePaySt={deletePaySt}
                                                                                            deletePayMsgFalseFn={deletePayMsgFalseFn}
                                                                                            deletePayMsg={deletePayMsg}
                                                                                            deletePay={deletePay}/>
                                                                            :
                                                                            chatDeleteSt ?
                                                                                <MsgSelectModal
                                                                                    chatDeleteSt={chatDeleteSt}
                                                                                    chatDeleteFalseFn={chatDeleteFalseFn}
                                                                                    chatDeleteMsg1={chatDeleteMsg1}
                                                                                    chatDeleteMsg2={chatDeleteMsg2}
                                                                                    messageToModify={messageToModify}
                                                                                    deleteChat={deleteChat}/>
                                                                                :
                                                                                selectedClickSt ?
                                                                                    <ChatFileDown
                                                                                        selectedClickSt={selectedClickSt}
                                                                                        clickFileClose={clickFileClose}
                                                                                        selectedClickFile={selectedClickFile}
                                                                                        downloadFile={downloadFile}/>
                                                                                    :
                                                                                    dontTrackingShipcoSt ?
                                                                                        <MsgCheckModal
                                                                                            dontTrackingShipcoSt={dontTrackingShipcoSt}
                                                                                            closeDontTrackingShipco={closeDontTrackingShipco}/>
                                                                                        :
                                                                                        cartTermsPopup ?
                                                                                            <CommonTermsPopup
                                                                                                cartTermsPopup={cartTermsPopup}
                                                                                                closeCartTermsPopup={closeCartTermsPopup}/>
                                                                                            :
                                                                                            orderTermsPopup ?
                                                                                                <CommonTermsPopup
                                                                                                    orderTermsPopup={orderTermsPopup}
                                                                                                    closeOrderTermsPopup={closeOrderTermsPopup}/>
                                                                                                :
                                                                                                offlineTermsPopup ?
                                                                                                    <CommonTermsPopup
                                                                                                        offlineTermsPopup={offlineTermsPopup}
                                                                                                        closeOfflineTermsPopup={closeOfflineTermsPopup}/>
                                                                                                    :
                                                                                                    transferTermsPopup ?
                                                                                                        <CommonTermsPopup
                                                                                                            transferTermsPopup={transferTermsPopup}
                                                                                                            closeTransferTermsPopup={closeTransferTermsPopup}/>
                                                                                                        :
                                                                                                        domesticTermsPopup ?
                                                                                                            <CommonTermsPopup
                                                                                                                domesticTermsPopup={domesticTermsPopup}
                                                                                                                closeDomesticTermsPopup={closeDomesticTermsPopup}/>
                                                                                                            :
                                                                                                            deliveryTermsPopup ?
                                                                                                                <CommonTermsPopup
                                                                                                                    deliveryTermsPopup={deliveryTermsPopup}
                                                                                                                    closeDeliveryTermsPopup={closeDeliveryTermsPopup}/>
                                                                                                                :
                                                                                                                biggerCpPriceSt ?
                                                                                                                    <MsgCheckModal
                                                                                                                        biggerCpPriceSt={biggerCpPriceSt}
                                                                                                                        closeBiggerCpPriceSt={closeBiggerCpPriceSt}/>
                                                                                                                    :
                                                                                                                    fileSizeOverSt ?
                                                                                                                        <MsgCheckModal
                                                                                                                            fileSizeOverSt={fileSizeOverSt}
                                                                                                                            closeFileSizeOverSt={closeFileSizeOverSt}/>
                                                                                                                        :
                                                                                                                        downloadFileComplSt ?
                                                                                                                            <MsgCheckModal downloadFileComplSt={downloadFileComplSt} closeDownloadFileCompl={closeDownloadFileCompl}/>
                                                                                                                            :
                                                                                                                            readyingPopupSt ?
                                                                                                                                <ReadyingPopup readyingPopupSt={readyingPopupSt} closeReadyingPopup={closeReadyingPopup}/>
                                                                                                                                :
                                                                                                                                formUploadErrSt ?
                                                                                                                                    <MsgCheckModal
                                                                                                                                        formUploadErrSt={formUploadErrSt}
                                                                                                                                        closeFormUploadErrSt={closeFormUploadErrSt}/>
                                                                                                                                    :
                                                                                                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <TopBtn/>
        </div>
    )
}

export default AdminOrderDtModiPg
